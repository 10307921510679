import React, { useEffect, useMemo, useRef, createRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { loadSkillsets } from '../../../functions/service_functions.js'
import { categories } from '../data.js'

const AllServices = () => {



    const [skillsets, setSkillsets] = useState([])
    const [categoriesList, setCategoriesList] = useState(categories)
    const [catRefs, setCatRefs] = useState(null)

    useMemo(() => {
        loadSkillsets().then(skills => {
            setSkillsets(skills)
        }).then(() => {
            const refs = categoriesList.reduce((cat, value) => {
                cat[value.id] = createRef();
                return cat;
            }, {});
            setCatRefs(refs)
        })

        return () => {
            setSkillsets([])
        }
    }, [])

    useEffect(() => {

    }, [skillsets])

    const scrollTo = (ref) => {
        if (ref && ref.current /* + other conditions */) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }

    const handleOnScroll = (myRef) => {
        (async () => {
        })().then(() => {
            scrollTo(myRef)
        })
    }

    return (
        <div className='w-full h-full flex justify-center overflow-y-hidden'>
            <div className='lg:w-[70%] w-full h-auto flex items-start justify-start'>
                <div className='w-[30%] relative hidden lg:flex flex-col space-y-2 justify-start items-start'>
                    <div className='w-[20%] fixed top-[12vh] lg:flex flex-col space-y-2 justify-start items-start'>
                        {
                            categoriesList.map((cat, indx) => {
                                return <div onClick={() => handleOnScroll(catRefs ? catRefs[cat.id] : null)} className='text-md font-medium cursor-pointer hover:text-blue-500 hover:underline' key={indx}>{cat.title}</div>
                            })
                        }
                    </div>
                </div>
                <div className='lg:w-[70%] w-full pb-20 h-full px-4 flex flex-col space-y-20 justify-start items-start overflow-y-auto scrollbar'>
                    {
                        categoriesList.map((cat, indx) => {
                            return <ShowCategoryItems key={indx} catRef={catRefs ? catRefs[cat.id] : null} category={cat.value} data={skillsets} />
                        })
                    }
                    <div className='w-full h-[200px]' />
                </div>

            </div>
        </div>
    )
}

export default AllServices

const ShowCategoryItems = ({ category, data, catRef }) => {
    const category_items = data.filter(i => i.category.value === category)




    return category_items.length > 0 && (
        <div ref={catRef} className='flex flex-col space-y-4'>
            <div className='font-medium text-left text-lg capitalize'>{category.split("_").join(" ")}</div>
            <div className='grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-4 space-y-2'>
                {
                    category_items.map((item, i) => {
                        return (
                            <Link to={`/services/list/${item.id}`} className='space-y-2 bg-white shadow-md ring-gray-400 ring-1' key={i}>
                                <div className='aspect-video w-auto'>
                                    <img src={item.images} className="w-full h-full object-cover " />
                                </div>
                                <div>{item.title}</div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}