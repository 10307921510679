import { ChevronDownIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import { useAlert } from '../../../../../../../../Components/UtilityComponents/ErrorContext'
import CheckerBox from '../../../../../../DashboardComponents/CheckerBox'
import JobSupplies from '../JobSupplies'
import RatesForm from '../RatesForm'
import TaskDetails from '../TaskDetails'

const default_currency = { currency: { id: 2, title: 'GBP', value: 'pound', symbol: '£' }, rates: "10" }

const SkillItem = ({ services, id, title, description, image, details, avg_rates, supplies, userSelectedSkills, setUserSelectedSkills, removeSelectedSkill, updateSelectedSkill }) => {
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(false)
    const [_rates, setRates] = useState(null)
    const [_supplies, setSupplies] = useState([])
    const [agreed, setAgreed] = useState(false)
    const [data, setData] = useState(null)

    const alert = useAlert()

    useEffect(() => {
        setRates(default_currency)
        if (userSelectedSkills.length > 0) {
            loadUp().then(() => {
                //
            })
        }
    }, [])

    useEffect(() => {

    }, [userSelectedSkills])

    useEffect(() => {

        setData({
            id,
            supplies: _supplies ? _supplies : [],
            rates: _rates ? _rates : default_currency,
            agreed,
            selected,
            image,
            description
        })
    }, [_supplies, id, agreed, selected, _rates])

    const loadUp = async () => {

        const this_skill = await userSelectedSkills.filter(i => i.id === id)[0]
        setRates(this_skill?.rates)
        setSupplies(this_skill?.supplies)
        setSelected(this_skill?.selected)
        setAgreed(this_skill?.agreed)
    }


    const handleSave = () => {
        if (!agreed) {
            alert.setalert({type:'error', body:'you must agree to be able to meet your customer\'s expectations.'})
            return
        }

        if (!selected) {
            // Error select this service first
            return
        }
        const tmp = userSelectedSkills
        console.log('...saving')
        tmp.forEach((sk, indx) => {
            if (data.id == sk.id && data.id) {
                console.log(data)
                tmp[indx] = data
            }
        })
        services.saveSkillsets(tmp).then((res) => {
            setUserSelectedSkills(res)
        }).then(() => {
            alert.setalert({type:"success",body:"saved!", title:'saved' })
            setOpen(false)
        })
    }

    const handleCancel = () => {
        const tmp = userSelectedSkills
        const tmp2 = []
        console.log('...canceling')
        tmp.forEach((sk, indx) => {
            if (data.id != sk.id && data.id) {
                tmp2.push(sk)
            }
        })
        setRates(null)
        setSupplies([])
        setSelected(false)
        setAgreed(false)
        setData(null)
        setOpen(false)
        services.saveSkillsets(tmp2).then((res) => {
            setUserSelectedSkills(res)
        })
        console.log(tmp2)
    }

    const handleToggleService = async (value) => {
        await setSelected(value)

        if (value === true) {
            setOpen(true)
            services.updateSkillsets([...userSelectedSkills, { ...data, selected: value }])
            setUserSelectedSkills([...userSelectedSkills, { ...data, selected: value }])
        } else {
            setOpen(false)
            setUserSelectedSkills(removeSelectedSkill(id))
            services.removeSkillSet(id).then((res) => console.log('THEN: ', res))
        }
    }

    return (
        <div className='w-full'>
            <div className='flex justify-between items-start bg-white p-5 px-10 border-b-[.5px] border-black/10'>
                <div className='flex items-center space-x-8'>
                    <CheckerBox value={selected} setValue={handleToggleService} />
                    {/* {JSON.stringify(selected)} */}
                    <div className='space-y-1'>
                        <div className='text-lg font-semibold'>{title}</div>
                        <div className='text-sm font-light text-gray-500'>{description}</div>
                    </div>
                </div>
                { selected  && <div onClick={() => setOpen(!open)}>
                    <ChevronDownIcon className='guide-icon' />
                </div>}
            </div>
            <SkillsetDetails
                save={handleSave}
                cancel={handleCancel}
                agreed={agreed}
                setAgreed={setAgreed}
                details={details}
                rates={_rates}
                avg_rates={avg_rates}
                supplies={supplies}
                open={open}
                _supplies={_supplies}
                setRates={setRates}
                setSupplies={setSupplies}
                selectedRates={_rates} />
        </div>
    )
}

const SkillsetDetails = ({ details, rates, avg_rates, supplies, open, setRates, setSupplies, _supplies, selectedRates, agreed, setAgreed, save, cancel }) => {
    return (
        <div className={`w-full ease_transition overflow-clip h-auto ${open ? 'h-auto' : 'h-0'} bg-[#E9E9FF]`}>
            <TaskDetails details={details} agreed={agreed} setAgreed={setAgreed} />
            <RatesForm avg_rates={avg_rates} rates={rates ? rates : default_currency} setRates={setRates} selectedRates={selectedRates} />
            {supplies && <JobSupplies selectedSupplies={_supplies} supplies={supplies} setSupplies={setSupplies} />}
            <div className='w-full flex justify-end py-10 px-5'>
                <div className='w-auto flex items-start space-x-3'>
                    <div onClick={() => cancel()} className='px-10 py-2 text-white bg-red-400 font-semibold rounded-md cursor-pointer'>Cancel</div>
                    <div onClick={() => save()} className='px-10 py-2 text-white bg-primary-500 font-semibold rounded-md cursor-pointer'>Save</div>
                </div>
            </div>
        </div>
    )
}

export default SkillItem