import { MinusIcon, PlusIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'

const CheckerBox = ({ value, setValue, disabled = false }) => {

    return (
        <div onClick={() => setValue(!value)} className={`w-6 h-6 flex justify-center aspect-square items-center rounded ${disabled? 'cursor-not-allowed':'cursor-pointer'}  ${ disabled? 'border-2 border-gray-500 bg-gray-200': value ? "bg-primary-500" : "border-2 border-primary-500 bg-white"}`}>
            {
                value ?
                    <MinusIcon className="guide_icon w-4 h-4 text-white" />
                    :
                    <PlusIcon className="guide_icon w-4 h-4 text-primary-500" />
            }

        </div>
    )
}

export default CheckerBox