import { XIcon } from '@heroicons/react/outline';
import React from 'react';
import NavBar from '../../Components/NavBar';
import BackDrop from '../../assets/city_backdrop.svg'

import Footer from '../../Components/Footer';
import { Outlet } from 'react-router';


const Home = () => {
  return (
    <div className='inset-0 w-full h-auto '>
      <div className='fixed -z-10 w-full h-[100vh] flex items-end'>
        <img src={BackDrop} alt="back_drop_image" className='w-full h-auto' />
      </div>
      <div className="w-full h-auto overflow-hidden">
        <div className='w-full '>
          <NavBar />
        </div>

        <div className='w-full h-auto pt-28 overflow-y-auto mx-auto flex flex-col items-center'>
          <Outlet />
        </div>

      </div>
      <Footer/>
    </div>
  )
}


export default Home