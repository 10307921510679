import React from 'react'
import FormChecker from '../../../../../../DashboardComponents/FormComponents/FormChecker'

const TaskDetails = ({ details, agreed, setAgreed }) => {

    const handleAgreement = (value) => {
        setAgreed(value)
    }

    return (
        <div className='px-3 md:px-10 py-5'>
            <div className='py-5 border-b border-[#D6D6FF] px-5 my-2 text-lg font-bold'>Expectations</div>
            <div className=' leading-7 px-0 md:px-10 text-sm md:text-md'>
                <div className='pb-3 font-medium'>{details.description}</div>
                {
                    Object.keys(details).map(function(key, i) {
                        if(key !== 'description'){
                            return (
                            <div key={i} className='grid grid-cols-6 gap-4'>
                                <span className=' col-span-1 font-semibold'>{key}</span>
                                <div className=' col-span-5'>{details[key]}</div>
                            </div>
                            )
                        }
                    })
                }
            </div>
            <div className='py-4'>
                <FormChecker selected={agreed} setSelected={handleAgreement} text="I agree that i am able to fulfill these expectations." />
            </div>
        </div>
    )
}

export default TaskDetails