import React, { useEffect, useState } from 'react'
import CheckerBox from '../../../../../DashboardComponents/CheckerBox'



const vehicle_types = [
    {
        id: 'None',
        title: "I don't own a Vehicle"
    },
    {
        id: 'suv',
        title: "Minivan / SUV"
    },
    {
        id: 'truck',
        title: "Truck"
    },
    {
        id: 'car',
        title: "Car"
    }
]


const Vehicle = ({ services }) => {
    const [vehicles, setVehicles] = useState([vehicle_types[0]])
    const [ownVehichle, setOwnVehicle] = useState(false)



    useEffect(() => {
        console.log(services.vehicles,"OWN VEHICLES")
        if(services.vehicles.some(v => v.id !== vehicle_types[0].id)){
            setVehicles(services.vehicles)
            services.updateVehicles(services.vehicles)
        }
    },[])

    useEffect(() => {
    },[ownVehichle])


    useEffect(() => {
        services.updateVehicles(vehicles)
    },[vehicles]) 


    return (
        <div>
            <div className='flex flex-col space-y-10'>
                {
                    vehicle_types.map((n, i) => {
                        return <VehicleType key={i} item={n} selected={vehicles} setSelected={setVehicles} ownVehichle={ownVehichle} setOwnVehicle={setOwnVehicle} />
                    })
                }
            </div>
        </div>
    )
}

const VehicleType = ({ item, selected, setSelected, ownVehichle, setOwnVehicle }) => {

    const [checkNotOwnVehicle , setCheckNotOwnVehicle] = useState(false)
    useEffect(() => {
        setCheckNotOwnVehicle(selected[0]?.id === vehicle_types[0].id && selected?.length === 1)
    },[selected])

    const selectValue =async (value) => {

        if(item.id === 'None' && checkNotOwnVehicle){
            setSelected([])
            setOwnVehicle(true)
            return
        }

        if(item.id === 'None'){
            setSelected([
                vehicle_types[0]
            ])
            setOwnVehicle(false)
            return
        }

        if(value){
            setSelected([...selected, item])
        }else{
            const newVehicles = await handleRemove(item.id)
            setSelected(newVehicles)
        }
    }

    const handleRemove =async (id) => {
        const tmp =[]
        await selected.map((n) => {
            if(n.id !== id){
                tmp.push(n)
            }
        })
        console.log(tmp)
        return tmp
    }

    const handleSelectvalue =(e) => {
        if(item.id !== "None"){
            if(selected.some(i => i.id === 'None')) return
        } 
        selectValue(e)
    }

    return (
        <div className='flex items-center space-x-4'>
            <CheckerBox disabled={item.id !== "None"? (selected.some(i => i.id === 'None')): (selected.some(i => i.id !== 'None'))} value={selected.some(i => i.id === item.id)} setValue={handleSelectvalue} />
            <div>
                <div>{item.title}</div>
            </div>
        </div>
    )
}



export default Vehicle