import { BadgeCheckIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import NavChatImage from '../../../assets/Guide_landing_page.svg'
import DashboardSliderOver from '../../Dashboard/DashboardComponents/DashboardSliderOver'
import HousingSearch from '../HousingSearch'
import VideosPage from '../VideosPage'

const IntroductionSection = () => {

    const [openSlider, setOpenSlider] = useState(false)

    return (
        <div className='flex flex-col w-full items-center min-h-[90vh]'>

            <div className='w-full lg:w-[70%]  flex items-start'>
                <div className='w-full 2xl:w-auto pt-32 flex flex-col items-center xl:items-start'>
                    <div className='w-auto pb-5'>
                        <div className='flex  w-full justify-start items-center space-x-4 bg-[#D3D3FF] rounded-full p-1 '>
                            <div className='bg-[#FEAA25] w-10 h-10 rounded-full flex justify-center items-center'>
                                <BadgeCheckIcon className='guide-icon w-5 h-5 text-white' />
                            </div>
                            <div>
                                <span className='text-gray-400 font-medium text-sm lg:text-lg pr-10'>#1 Editor’s Choice App Of 2022</span>
                            </div>
                        </div>
                    </div>
                    <div className='w-[80%] lg:pl-6 flex flex-col space-y-4'>
                        <div className='w-full justify-start'>
                            <div className='text-center lg:text-left text-2xl lg:text-4xl font-extrabold text-textdark leading-10 '>Guide is how you put your business on the map</div>
                        </div>
                        <div className='w-full lg:w-[50%]'>
                            <div className='text-center text-lg font-light text-textdark lg:text-left'>
                                increase productivity with and easy
                                to use dashboard to help you manage
                                your business
                            </div>
                        </div>
                        <div className='flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-4 pt-5 lg:items-center'>
                            <Link to="/signup" className='w-full lg:w-auto py-4 px-10 bg-primary-500 text-white font-bold rounded-full hover:cursor-pointer'>Sign Up</Link>
                            <div onClick={() => setOpenSlider(!openSlider)} className='text-lg text-primary-500 font-medium hover:cursor-pointer hover:underline'>Watch Demo Video</div>
                        </div>
                    </div>
                </div>
                <div className='hidden 2xl:flex justify-center items-center'>
                    <img src={NavChatImage} alt="back_drop_image" className='w-full h-auto' />
                </div>
            </div>
            {/* <div className='w-full flex flex-col items-center mt-[2vh] bg-purple-100 py-10 mb-10'>
                <div className='text-xl font-bold text-primary-800 py-4 text-left px-2 mb-5'>For Instance, You can find your new home on Guide</div>
                <div className='max-w-7xl w-full'><HousingSearch /></div>
            </div> */}

            <DashboardSliderOver open={openSlider} setOpen={setOpenSlider} width={5}>
                <VideosPage />
            </DashboardSliderOver>
        </div>
    )
}

export default IntroductionSection