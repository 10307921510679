import React from 'react'

const FormProgressBar = ({progress=50}) => {
  return (
    <div className='w-full bg-[#D9D9D9] h-2 overflow-hidden flex justify-start items-center'>
        <div style={{ width: `${progress}%`}} className='h-full ease_transition bg-primary-500'></div>
    </div>
  )
}

export default FormProgressBar