import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'
import RootReducer from './store';
import {persistStore} from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import sw from './serviceWorkerDev'
import ReactGA from "react-ga4";
import { REACT_GA_ID } from './assets/url';
import * as Sentry from "@sentry/react";
import { onBackgroundMessage } from "firebase/messaging/sw";



Sentry.init({
  dsn: "https://ba2b0b16c7346285a06ede0cd69eab57@o4506248201109504.ingest.sentry.io/4506248205107200",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/www.guideutility\.co/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: process.env.REACT_APP_MODE === 'DEV'? 0.1 : 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const store = createStore( RootReducer , applyMiddleware(thunk))
const persist = persistStore(store)
//Initialize GA4
ReactGA.initialize(REACT_GA_ID);

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persist}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
sw(onBackgroundMessage);
reportWebVitals(SendAnalytics); 
