import React from 'react'
import { useHousingForm } from '../../../../../Components/UtilityComponents/HousingFormContext'
import FormPageNavigation from '../DashboardFormWrapper/FormPageNavigation'
import SaveAndExit from '../DashboardFormWrapper/SaveAndExit'
import TitleAndDescription from '../DashboardFormWrapper/TitleAndDescription'
import FormProgressBar from '../FormProgressBar'

const HousingDashboardFormWrapper = ({ children }, props) => {

    const housing = useHousingForm()

    return (
        <div className='w-full h-[100vh] pt-5 px-1 md:px-5 relative'>
            {housing.currentPage.id !== 0 && <FormProgressBar progress={housing.currentPage.progress} />}
            <div className='w-full  overflow-y-auto px-2 max-h-[90%] scrollbar'>
                <div className='w-full flex flex-col space-y-4 md:spacey-0 md:flex-row justify-between items-start my-5'>
                    <TitleAndDescription services={housing} />
                    {housing.currentPage.id !== 0 && <SaveAndExit services={housing} />}
                </div>
                {children}
            </div>
            <div className='absolute bottom-10 w-full px-10'>
                {housing.currentPage.id !== 0 && <FormPageNavigation services={housing} by="housing" />}
            </div>
        </div>
    )
}

export default HousingDashboardFormWrapper