import { ChevronDownIcon, ChevronRightIcon, ChevronUpIcon, CogIcon, ColorSwatchIcon, DownloadIcon, HomeIcon, LightBulbIcon, LoginIcon, MenuAlt1Icon, NewspaperIcon, OfficeBuildingIcon, TableIcon, UserAddIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Transition } from '@headlessui/react'
import Logo from '../../assets/logo.svg'
import DashboardSliderOver from '../../Pages/Dashboard/DashboardComponents/DashboardSliderOver'
import { useAuth } from '../UtilityComponents/AuthProvider'



const subMenuItems = [
    {
        title: 'Apartments',
        desc: 'Browse Guide for an apartment to rent.',
        icon: OfficeBuildingIcon,
        more: {
            title: 'Find an Apartments on Guide',
            body: 'You are now a few clicks away from finding a comfortable, affordable apartment. Search for the exact type of apartment you want, where you want it, and how you want it. Guide makes it easy.',
            buttons: [
                {
                    btn: "Browse Apartments",
                    link: 'https://housing.guideutility.co',
                }
            ]

        }
    },
    // {
    //     title: 'Timetables',
    //     desc: 'The NEU Excel sheet struggles are real. we know, and now we make it easy.',
    //     icon: TableIcon,
    //     more: {
    //         title: 'Design your NEU Timetables',
    //         body: 'Generate your NEU timetable with just a few clicks. Yes, indeed Guide is all about making your everyday life easier. lets start with creating your timetable ',
    //         buttons: [
    //             {
    //                 btn: "Go To Timetable",
    //                 link: '/dashboard/timetable',
    //             }
    //         ]

    //     }
    // },
    {
        title: 'Businesses',
        desc: 'Find hundreds of local businesses in your area.',
        icon: ColorSwatchIcon,
        more: {
            title: 'Local Business',
            body: 'Find local businesses and securely pay for services/products with fast delivery',
            buttons: [
                {
                    btn: "Create Business",
                    link: '/dashboard/business'
                },
                {
                    btn: "Find Business",
                    link: '/business',
                }
            ]

        }
    },
    // {
    //     title: 'Student Blogs',
    //     desc: 'Join fellow students who blog on Guide.',
    //     icon: NewspaperIcon,
    //     more: {
    //         title: 'Read and share thoughts, tips and tricks.',
    //         body: 'student blogging provides transformative learning opportunities for students by creating a platform for research, investigation, higher level thinking, decision-making, and reflection. On Guide you can read and write blogs starting right now.',
    //         buttons: [
    //             {
    //                 btn: "Start Blogging",
    //                 link: '/dashboard/timetable'
    //             },
    //             {
    //                 btn: "Read Students Blogs",
    //                 link: '/blog',
    //             }
    //         ]

    //     }
    // }
]

const NavBar = () => {

    const [openSlider, setOpenSlider] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)
    const auth = useAuth()


    const menuItems = [
        {
            title: 'Home',
            link: '/',
            style: false,
            icon: HomeIcon
        },
        {
            title: 'Services',
            style: false,
            icon: LightBulbIcon,
            dropmenu: ServicesMenu
        },
        // {
        //     title: 'Students Blog',
        //     link: '/blog',
        //     style: false,
        //     icon: NewspaperIcon
        // },
        {
            title: 'App',
            link: 'https://bit.ly/3rtM5WZ',
            style: 'link',
            icon: DownloadIcon
        },
        // {
        //     title: 'FAQs',
        //     link: '/faqs',
        //     style: false,
        // icon: HomeIcon 
        // },
        {
            title: 'Sign In',
            link: '/login',
            style: 'bold',
            icon: LoginIcon
        },
        {
            title: 'Sign Up',
            link: '/signup',
            style: 'button',
            icon: UserAddIcon
        },
    ]


    useEffect(() => {

        return () => {
            setOpenSlider(false)
            setOpenMenu(false)
        }
    }, [])



    const MenuList = () => {
        const location = useLocation()
        const actieURL = location.pathname
        const loggedIn = auth.user && auth?.user.user

        const [subMenu, setSubMenu] = useState(null)

        return (
            <div className='flex flex-col space-y-4 px-5 relative h-full' >
                <div className='flex items-center'>
                    <img src={Logo} alt="Logo" className='w-20 lg:w-32 h-20  lg:h-32' />
                </div>
                <div className='flex flex-col space-y-10 px-5'>

                    {
                        menuItems.map((item, indx) => {
                            if (item.dropmenu) {
                                return (
                                    <div  >
                                        <div onClick={() => setOpenMenu(!openMenu)} className={`flex items-center space-x-2 hover:text-primary-500 cursor-pointer  hover:bg-primary-300 py-4 px-4 text-xl ${item.link === actieURL ? 'bg-primary-300 text-primary-500' : ''} rounded-lg`}>
                                            <item.icon className='w-5 h-5' />
                                            <div key={indx} className='font-bold hover:text-primary-500'><span>{item.title}</span></div>
                                            {openMenu ? <ChevronUpIcon className='w-5 h-5' /> : <ChevronDownIcon className='w-5 h-5' />}
                                        </div>
                                        <div>
                                            {
                                                openMenu &&
                                                subMenuItems.map((b, i) => {
                                                    if (b.more.buttons.length > 0) {
                                                        return (
                                                            <div onClick={() => setSubMenu(subMenu !== b.title && b.title)} key={i} className="pl-10">
                                                                <div className='flex px-5 py-4 space-x-3'>
                                                                    <b.icon className='w-5 h-5' />
                                                                    <span>{b.title}</span>
                                                                    {subMenu === b.title ? <ChevronUpIcon className='w-5 h-5' /> : <ChevronDownIcon className='w-5 h-5' />}
                                                                </div>
                                                                {
                                                                    subMenu === b.title &&
                                                                    b.more.buttons.map((more, mi) => {
                                                                        if (JSON.stringify(subMenuItems[0].more.buttons[0]) === JSON.stringify(more)) {
                                                                            return <a key={i} href={more.link} target='_blank' rel="noreferrer noopener" className=' hover:underline hover:text-primary-500 pl-10 flex px-5 py-4 space-x-3' >
                                                                                <ChevronRightIcon className='w-5 h-5' />
                                                                                <span>{more.btn}</span>
                                                                            </a>
                                                                        }
                                                                        return (
                                                                            <Link onClick={() => setOpenSlider(false)} to={more.link} key={mi} className=' hover:underline hover:text-primary-500 pl-10 flex px-5 py-4 space-x-3'>
                                                                                <ChevronRightIcon className='w-5 h-5' />
                                                                                <span>{more.btn}</span>
                                                                            </Link>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div key={i} className='flex px-5 py-4 space-x-3'>
                                                                <b.icon className='w-5 h-5' />
                                                                <span>{b.title}</span>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            } else {

                                if (item.style === 'button') {
                                    return !auth.user && <div className={`flex items-center space-x-2 hover:text-primary-500 cursor-pointer  hover:bg-primary-300 py-4 px-4 text-xl ${item.link === actieURL ? 'bg-primary-300 text-primary-500' : ''} rounded-lg`}>
                                        <item.icon className='w-5 h-5' /><Link onClick={() => setOpenSlider(false)} key={indx} to='/signup' className='font-bold hover:text-primary-500'><span>Sign Up</span></Link>
                                    </div>
                                } else if (item.style === 'bold') {
                                    return !auth.user && <div className={`flex items-center space-x-2 hover:text-primary-500 cursor-pointer  hover:bg-primary-300 py-4 px-4 text-xl ${item.link === actieURL ? 'bg-primary-300 text-primary-500' : ''} rounded-lg`}>
                                        <item.icon className='w-5 h-5' /> <Link onClick={() => setOpenSlider(false)} key={indx} to='/login' className='font-bold hover:text-primary-500 cursor-pointer'>Sign In</Link>
                                    </div>
                                } else if (item.style == 'link') {
                                    return <div className={`flex items-center space-x-2 hover:text-primary-500 cursor-pointer  hover:bg-primary-300 py-4 px-4 text-xl ${item.link === actieURL ? 'bg-primary-300 text-primary-500' : ''} rounded-lg`}>
                                        <item.icon className='w-5 h-5' />
                                        <a onClick={() => setOpenSlider(false)} key={indx} className='font-bold ' href="https://bit.ly/3rtM5WZ">{item.title}</a>
                                    </div>
                                } else {
                                    return (
                                        <div className={`flex items-center space-x-2 hover:text-primary-500 cursor-pointer  hover:bg-primary-300 py-4 px-4 text-xl ${item.link === actieURL ? 'bg-primary-300 text-primary-500' : ''} rounded-lg`}>
                                            <item.icon className='w-5 h-5' />
                                            <Link onClick={() => setOpenSlider(false)} to={item.link} key={indx} className='font-medium '>{item.title}</Link>
                                        </div>
                                    )
                                }
                            }

                        })
                    }
                </div>
                {loggedIn &&
                    <Link to="/dashboard" className='w-full flex items-center space-x-4 absolute bottom-0 bg-primary-200 p-5 rounded-xl'>
                        <div className='ring-2 ring-primary-500/30 rounded-full'><img src={auth.user?.user.photo_url} className="w-10 h-10 rounded-full" /></div>
                        <div className='text-md font-bold text-primary-800'>{auth.user?.user.display_name}</div>
                    </Link>}
            </div>
        )
    }

    return (
        <div className='flex w-full justify-between fixed top-0 z-20 bg-white items-center shadow-lg max-h-24 py-2 px-5 md:px-10 lg:px-20'>
            <div className='flex items-center'>
                <img src={Logo} alt="Logo" className='w-20 lg:w-32 h-20  lg:h-32' />
            </div>
            <div className='hidden sm:flex items-center ml-auto space-x-4 relative'>
                <div className='flex items-center space-x-6 text-lg font-bold text-primary-800'>
                    {
                        menuItems.map((item, indx) => {
                            if (item.dropmenu) {
                                return <div key={indx} onClick={() => setOpenMenu(!openMenu)} className=' font-normal flex items-center space-x-2 hover:text-primary-500 cursor-pointer'>
                                    <div key={indx}>{item.title}</div>
                                    {openMenu ? <ChevronUpIcon className='w-5 h-5' /> : <ChevronDownIcon className='w-5 h-5' />}
                                </div>
                            } else {

                                if (item.style === 'button') {
                                    return !auth.user && <Link key={indx} to='/signup' className='py-4 px-10 bg-primary-500 text-white font-bold rounded-full'><span>Sign Up</span></Link>

                                } else if (item.style === 'bold') {
                                    return !auth.user && <Link key={indx} to='/login' className='font-bold hover:text-primary-500 cursor-pointer'>Sign In</Link>
                                } else if (item.style == 'link') {
                                    return <a key={indx} className='font-medium hover:text-primary-500 cursor-pointer' href="https://bit.ly/3rtM5WZ">{item.title}</a>
                                } else {
                                    return (
                                        <Link to={item.link} key={indx} className='font-medium hover:text-primary-500 cursor-pointer'>{item.title}</Link>
                                    )
                                }
                            }
                        })
                    }
                    {(auth.user && auth?.user.user) && <Link to="/dashboard" className='flex items-center space-x-4 p-2 bg-primary-300 border-2 border-primary-200 rounded-full pl-4'>
                        <div className='text-md text-primary-800'>{auth.user?.user.display_name}</div>
                        <div><img src={auth.user?.user.photo_url} className="w-10 h-10 rounded-full" /></div>
                    </Link>}
                </div>
                <ServicesMenu open={openMenu} setOpen={setOpenMenu} />

            </div>
            <div onClick={() => setOpenSlider(true)} className='block w-10 h-10 sm:hidden cursor-pointer'>
                <MenuAlt1Icon />
                <DashboardSliderOver open={openSlider} setOpen={setOpenSlider} width={7} >
                    <MenuList />
                </DashboardSliderOver>
            </div>
        </div>
    )
}

const ServicesMenu = ({ setOpen, open }) => {
    const [menu, setMenu] = useState(null)



    return (
        <Transition
            show={open}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            as="div"
            className="ease_transition fixed lg:absolute z-10 right-0 lg:right-10 top-20 lg:top-14 p-5 lg:rounded-2xl bg-[#efefef] shadow-xl w-full lg:w-[54rem] font-normal"
        >
            {/* <div className={`ease_transition absolute z-10 right-10 top-14 p-5 rounded-2xl bg-[#efefef] shadow-xl w-[54rem] font-normal`}> */}
            <div className={`w-full grid ${menu ? 'grid-cols-2' : 'grid-cols-1'} gap-2 divide-x-2`}>
                <div className='flex flex-col justify-start'>
                    {
                        subMenuItems.map((m, i) => {
                            return (
                                <div onClick={() => setMenu(m)} key={i} className='flex w-full justify-start items-center space-x-4 rounded-xl hover:bg-[#e6e6e6] p-4 cursor-pointer'>
                                    <div className='h-14 w-14 aspect-square flex justify-center items-center rounded-full bg-primary-500'>
                                        <m.icon className='guide-icon text-white' />
                                    </div>
                                    <div className='text-left'>
                                        <div className='text-lg font-medium'>{m.title}</div>
                                        <div className='text-sm text-gray-500'>{m.desc} </div>
                                    </div>
                                </div>
                            )
                        }, [])
                    }


                </div>
                {
                    menu &&
                    <div className='w-full h-full text-left space-y-4 px-4'>
                        <div className='pb-2 h-[20%]'>
                            <div className='font-bold pb-1 uppercase'>{menu?.more.title}</div>
                            <div className='w-10 h-1 bg-primary-500' />
                        </div>
                        <div className='relative h-[75%]'>
                            <p className='flex flex-wrap w-full'>{menu?.more.body}</p>
                            <div className={`w-full grid grid-cols-${menu.more.buttons.length} gap-2 items-center absolute bottom-0`}>
                                {
                                    menu.more.buttons.map((more, i) => {
                                        if (JSON.stringify(subMenuItems[0].more.buttons[0]) === JSON.stringify(more)) {
                                            return <a key={i} href={more.link} target='_blank' rel="noreferrer noopener" className=' p-2 w-auto uppercase bg-primary-500 text-white rounded-md text-center' >{more.btn}</a>
                                        }
                                        return <Link key={i} to={more.link} onClick={() => setOpen(false)} className='  p-2 w-auto uppercase bg-primary-500 text-white rounded-md text-center'>{more.btn}</Link>
                                    })
                                }
                            </div>
                        </div>

                    </div>
                }
            </div>
            {/* </div> */}
        </Transition>
    )
}

export default NavBar