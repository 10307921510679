import { SAVE_ORDER, CLEAR_ORDER } from "../../actionTypes/orderActionTypes"

const _state = null

export const orderReducer = (state = _state, action) => {
    switch (action.type) {
        case SAVE_ORDER:
            return { ...state, type: action.type, payload: action.payload, error: action.error }
        case CLEAR_ORDER:
            return { ...state, type: action.type, payload: action.payload, error: action.error }
        default:
            return state
    }
}