import { Dialog } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'

const DeleteConfirmationDialog = ({ open, setOpen, callback, setLoading, title='', item_type='' }) => {

    console.log(open)
    const [yes, setYes] = useState('')

    const handleConfirmation =() => {
        setLoading(true)
        if(yes.toLowerCase() === 'yes'){
            callback()
            setOpen(!open)
        }
    }

    const handleCancel =() => {
        setOpen(!open)
        setLoading(false)
    }


    const canDelete = yes.toLowerCase() === 'yes'

    return open && (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            className="fixed z-50 inset-0 p-4 pt-[25vh] overflow-y-auto"
        >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75" />
            <div className='relative bg-white max-w-xl mx-auto rounded-xl shadow-2xl ring-1 ring-black/5 divide-y divide-gray-100'>
                <div className='flex flex-col items-start space-y-2 h-32 bg-red-100 rounded-t-xl px-4 py-2 text-red-700'>
                    <div className='flex items-center space-x-2 py-2 '>
                        <ExclamationIcon className='w-10 h-10' />
                        <div className='font-semibold text-lg'>Delete your {title} {item_type}?</div>
                    </div>
                    <div className='text-sm font-medium '>Doing this will permanently delete your {title} {item_type} and everything in it.</div>
                </div>
                <div className='w-full bg-white p-4 rounded-b-xl'>
                        <div className='text-sm font-medium italic '>Warning: <span className='font-bold'>this action cannot be undone.</span></div>
                        <div className='py-5 flex flex-col space-y-2'>
                            <div>confirm you want to delete this {item_type} by typing "Yes" in the prompt below:</div>
                            <input onChange={(e) => setYes(e.target.value)} placeholder='Yes' text={yes} className='w-[60%] rounded-md p-2 outline-2 border-2 border-black/10 ' />
                        </div>
                        <div className='w-full pt-10 flex items-center space-x-4 justify-end'>
                            <div onClick={() => handleCancel() } className={`py-2 px-4  rounded-md bg-gray-300 text-gray-600 hover:font-bold cursor-pointer`}>Cancel</div>
                            <div onClick={() => handleConfirmation()} className={`py-2 px-4 ${canDelete?'bg-red-500 hover:bg-red-700 hover:font-bold':'bg-red-300 cursor-not-allowed'} rounded-md cursor-pointer text-white`}>Delete</div>
                        </div>
                </div>
            </div>

        </Dialog>
    )
}

export default DeleteConfirmationDialog