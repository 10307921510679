const API_LAYER_EXCHAGE_RATES_API = "ywf0mPV4FNddpSekxfiZoAIrxIYvqLpK"

var myHeaders = new Headers();
myHeaders.append("apikey", API_LAYER_EXCHAGE_RATES_API);

var requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: myHeaders
};

export const exchangeCurrencies = async ({from, to, amount}) => {
    return await fetch(`https://api.apilayer.com/exchangerates_data/convert?to=${to}&from=${from}&amount=${amount}`, requestOptions)
        .then(response => response.json())
        .then(result => result)
        .catch(error => console.log('error', error));
}
