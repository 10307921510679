import React, { useEffect, useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ImageSlider_v2 from '../imageSlider_v2';


const ImageSlider = ({ images, v2 = false }) => {

    return (

        <div className='w-full flex aspect-video'>
            {
                v2 ?
                    <ImageSlider_v2 images={images} />
                    :

                    <Carousel
                        showStatus={false}
                        showArrows={true}
                        showThumbs={false}
                    >
                        {
                            images.map((img, indx) => {
                                return (<img key={indx} src={img} className="w-full h-[300px] object-contain" alt="guide apartment images" />)
                            })
                        }
                    </Carousel>
            }
        </div>
    )
}


export default ImageSlider