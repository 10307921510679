import React, { useEffect, useState } from 'react'
import OpenHours from '../../../../DashboardComponents/OpenHours'

const OpenHoursForm = ({business}) => {
    // const [openHours, setOpenHours] = useState(null)
  return (
    <div className='w-full h-fit mb-[100px]'>
        <OpenHours openHours={business.openHours} setOpenHours={business.updateOpenHours} />
    </div>
  )
}

export default OpenHoursForm