import React, { useEffect, useState } from 'react'
import ApartmentCard from './ApartmentCard'
import ApartmentFilter from './ApartmentFilter'
import { loadMoreThirdPartyApartments, loadThirdPartyApartments, load_apartments } from './functions'
import NoData from '../../assets/no_content.svg'
import DashboardSliderOver from '../Dashboard/DashboardComponents/DashboardSliderOver'
import { AdjustmentsIcon } from '@heroicons/react/outline'
import Loader from '../../assets/spinner.svg'

const ApartmentsView = () => {
  const [apartments, setApartments] = useState([])
  const [laodingMore, setLoadingMore] = useState(false)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState(null)
  const [openFilter, setOpenFilter] = useState(false)

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLoading(true)
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      })
      load_apartments({
        option: 'Apartments',
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        radius: 1000
      }).then(res => {
        setApartments(res?.v)
        setLoading(false)
        return res?.v
      }).then(async (apt) => {
        setLoadingMore(true)
        await loadThirdPartyApartments().then(async res => {
          setApartments([...res, ...apt])
        })
      }).finally(() => {
        setLoading(false)
        setLoadingMore(false)
      })
    })
  }, [])

  const handleLoadMore = async () => {
    setLoadingMore(true)
    const _page = page + 1
    await loadMoreThirdPartyApartments(_page).then((res) => {
      console.log(res)
      setApartments([...apartments, ...res])
      setPage(_page)
    }).finally(() => {
      setLoadingMore(false)
    })
  }



  useEffect(() => {
    setApartments(apartments)
  }, [apartments, loading])


  return (
    <div className='w-full h-[100vh] overflow-y-auto scrollbar flex justify-center relative'>
      <div className='w-full md:w-[80%] 2xl:w-[60%] '>
        {
          !loading ?
            apartments?.length > 0 ?
              apartments?.map((apartment, indx) => {
                return <ApartmentCard apartment={apartment} key={indx} />
              })
              :
              <div className='w-full md:w-[80%] 2xl:w-[50%] pt-[200px] flex flex-col justify-center items-center'>
                <div className=''>
                  <img src={NoData} className="w-auto" alt="guide no items image" />
                </div>
                <div className='flex flex-col items-center '>
                  <div className='font-semibold text-gray-400 py-2'>No Apartments Found</div>
                  <div className='font-light text-gray-400 py-2'>broaden your search parameters and filter</div>
                </div>
              </div>
            :
            <div className='w-full md:w-[80%] 2xl:w-[50%] flex justify-center items-center pt-[200px]'>
              <img src={Loader} className='w-20 h-20 animate-spin stroke-1' />
            </div>
        }
        {(!loading && apartments?.length > 0) && <div className='py-10'>
          <div onClick={() => handleLoadMore()} className=' cursor-pointer w-auto py-5 mx-5 my-10 flex justify-center items-center bg-primary-500 rounded '>
            {laodingMore && <img src={Loader} className='w-4 h-4 animate-spin' />}
            {!laodingMore && <div className='font-medium text-lg text-white'>Load More</div>}
          </div>
        </div>}
      </div>
      {!openFilter && <div onClick={() => setOpenFilter(true)} className=' w-20 h-20 flex 2xl:hidden justify-center items-center rounded-l-full bg-primary-500 fixed right-0 top-[20%]'>
        <AdjustmentsIcon className='text-white guide-icon' />
      </div>}
      <div className='w-[20%] h-full my-10 relative hidden 2xl:flex '>
        <ApartmentFilter location={location} setFilter={setApartments} setLoading={setLoading} />
      </div>
      <DashboardSliderOver open={openFilter} setOpen={setOpenFilter} width={5}>
        <ApartmentFilter location={location} setFilter={setApartments} setLoading={setLoading} loading={loading} setOpenFilter={setOpenFilter} full={true} />
      </DashboardSliderOver>
    </div>
  )
}

export default ApartmentsView