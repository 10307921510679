import React, { useEffect, useState } from 'react'
import SkillItem from './SkillItem'
// import { skillsets } from './data'
import { loadSkillsets } from '../../../../../../../functions/service_functions.js'

const SkillSetsForm = ({ services }) => {

    const [userSelectedSkills, setUserSelectedSkills] = useState(services.stored_services?.skillsets)
    const [skillsets, setSkillsets] = useState(null)

    const removeSelectedSkill = (id) => {
        const tmp = []
        userSelectedSkills?.map((skill, _) => {
            if (skill.id !== id && skill !== {}) {
                tmp.push(skill)
            }
        })

        setUserSelectedSkills(tmp)
        return tmp
    }

    const updateSelectedSkill = (id, data) => {
        const tmp = []
        userSelectedSkills?.map((skill, _) => {
            if (skill.id !== id) {
                tmp.push(skill)
            } else {
                tmp.push(data)
            }
        })

        setUserSelectedSkills(tmp)
        return tmp
    }

    useEffect(() => {
        setUserSelectedSkills(services.stored_services?.skillsets)
        loadSkillsets().then(skills => {
            setSkillsets(skills)
        })
    }, [])



    return (
        <div className='flex h-auto items-start justify-start w-full'>
            <div className='py-5 w-full '>
                <div>
                    <div className='text-sm max-w-lg px-3'>Now it’s time to select your skillsets, these are the skills client’s will find you by, if you have a skill in mind which isn;t on this list, sugges it using the suggest section. </div>
                    <div className='w-full mt-10'>
                        {
                                skillsets ?
                                    skillsets?.map((skill, indx) => {
                                        return <SkillItem services={services} key={indx} id={skill.id} title={skill.title} image={skill.images} description={skill.description} details={skill.details} avg_rates={skill.rates} supplies={skill.supplies} setUserSelectedSkills={setUserSelectedSkills} updateSelectedSkill={updateSelectedSkill} userSelectedSkills={userSelectedSkills} removeSelectedSkill={removeSelectedSkill} />
                                    })
                                    :
                                    [0, 0, 0, 0,0,0].map((_,i) => {
                                        return (
                                            <div key={i} className='h-24 w-full bg-gray-300 animate-pulse border-b-2 border-primary-100'/>
                                        )
                                    })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SkillSetsForm