import React, { useEffect, useState } from "react";
import DashboardWrapper from "../../DashboardComponents/DashboardWrapper";
import { useNavigate, useParams } from "react-router";
import { connect } from "react-redux";
import HomePage from "../../../Home/HomePage";
import { load_business } from "../../../../functions/business_functions";
import { PLUGIN_SERVER_URL } from "../../../../assets/url";

const PluginView = ({ business }) => {
  const { name, business_id } = useParams();
  const navigate = useNavigate();

  const [currentBusiness, setCurrentBusiness] = useState(null)
  const [homPage, setHomePage] = useState(null)
  const [_business, setBusiness] = useState(null)

  useEffect(() => {

    setCurrentBusiness(business?.plugin?.filter((i) => i.name === name))

  if (!business.plugin?.some((i) => i.name === name && i.active === true)) {
    navigate("/dashboard/business");
  }
  load_business(business_id).then((e) => {
    const b = JSON.parse(e)
    setBusiness(b)
    setCurrentBusiness(b?.plugin?.filter((i) => i.name === name))
  })


}, []);

useEffect(() => {
  console.log(currentBusiness, business?.plugin)
  setHomePage(currentBusiness?.length > 0
    ? `${name}/home`
    : null)
}, [_business, currentBusiness])


const locations = _business?.location?.addresses

let lat, lon = 0

if (locations !== undefined) {
  lat = locations[0]?.latlon?.lat
  lon = locations[0]?.latlon?.lon
}


let url = `${PLUGIN_SERVER_URL}/${homPage}?business_id=${business_id}&uid=${_business?.uid}`

if (lat && lon) {
  url += `&lat=${lat}&lon=${lon}`
}

console.log(business_id, homPage, currentBusiness, lat, lon)
return (
  <DashboardWrapper>
    {(homPage) && (
      <div className="w-full h-[90vh] lg:flex-1 flex">
        <iframe
          src={url}
          height={"100%"}
          className="w-full h-full flex flex-1"
        />
      </div>
    )}
  </DashboardWrapper>
);
};

const mapStateToProps = (state) => {
  return {
    user: state.user?.data,
    business: state.business.payload,
  };
};

export default connect(mapStateToProps)(PluginView);
