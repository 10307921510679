import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate } from "react-router";
import { useAuth } from '../AuthProvider';

function RequireAuth({ children, user }) {
    let location = useLocation();
    let navigate = useNavigate()

    useEffect(() => {
        if (user && !user.email_verified) {
            return navigate("/verify_email", { state: { from: location }, replace: true })

        } else if (user?.role === 'unassigned') {
            navigate("/dashboard/account_setup", { state: { from: location }, replace: true })
        }

    }, [user?.role])


    if (!user) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.

        return <Navigate to="/login" state={{ from: location }} replace />;
    }



    return children;
}

const mapStateToProps = (state) => {
    return {
        user: state.user?.data?.user
    }
}

export default connect(mapStateToProps, null)(RequireAuth) 