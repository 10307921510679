import React, { useEffect } from 'react'
import { useHousingSearch } from '../../Components/UtilityComponents/HousingSearchContext'
import HousingSearch from '../Home/HousingSearch'

const ApartmentsSearchPage = () => {
    const housingSearch = useHousingSearch()
    useEffect(() => {
        housingSearch.Search()
    }, [])



    return (
        <div className='w-full flex min-h-[68vh] justify-center'>
            <div className='max-w-6xl'>
                <HousingSearch limit={0} grid={3} />
            </div>

        </div>
    )
}

export default ApartmentsSearchPage