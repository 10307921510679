import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DashboardFormField from "../../../../DashboardComponents/DashboardFormField";
import TipsCard from "../../../../DashboardComponents/FormComponents/TipsCard";
import DashboardPhoneFormField from "../../../../DashboardComponents/DashboardPhoneFormField";
import { countries_call_codes } from "../../../Main/SubScreens/Settings/Forms/data";

const PhoneForm = ({ business }) => {
  const [phone, setPhone] = useState("");
  const [dial, setDial] = useState(countries_call_codes[0]);

  useEffect(() => {
    if (!Array.isArray(business.stored_business?.phone)) {
      setPhone(business.stored_business?.phone?.number);
      setDial(business.stored_business?.phone?.dial);
    }
  }, []);

  useEffect(() => {
    business.updatePhone({number: phone, dial});
  }, [phone, dial]);

  return (
    <div className="flex h-auto items-start justify-start w-full">
      <div className="py-5 min-h-[60vh] w-full md:w-[60%] ">
        <div>
          <div className="max-w-lg">
            <DashboardPhoneFormField
              rows={10}
              name="phone"
              placeholder="00 000 000 00"
              dial={dial}
              setDial={setDial}
              maxLength={10}
              text={phone}
              setText={setPhone}
            />
          </div>
        </div>
      </div>
      <div className="hidden md:w-[40%] h-full md:flex justify-start">
        {/* <TipsCard /> */}
      </div>
    </div>
  );
};

export default PhoneForm;
