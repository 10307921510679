import React, { useEffect, useState } from "react";
import LocationForm from "../Forms/LocationForm";
import DraftForm from "../Forms/DraftForm";
import { useBusinessForm } from "../../../../../Components/UtilityComponents/BusinessFormContext";
import { Businesspages as pages } from "../../Main/SubScreens/Settings/Forms/data";
import TitleForm from "../Forms/TitleForm";
import ImagesForm from "../Forms/ImagesForm";
import CategoryForm from "../Forms/CategoryForm";
import PhoneForm from "../Forms/PhoneForm";
import WebsiteForm from "../Forms/WebsiteForm";
import FacilitiesForm from "../Forms/FacilitiesForm";
import DescriptionForm from "../Forms/DescriptionForm";
import ReviewForm from "../Forms/ReviewForm";
import OpenHoursForm from "../Forms/OpenHoursForm";

const AddBusiness = () => {
  const business = useBusinessForm();
  const [page, setPage] = useState(null);

  useEffect(() => {
    setPage(business.currentPage);
  }, [business.currentPage]);

  return (
    <div className="h-full w-full">
      <ActiveForm page={page} business={business} />
    </div>
  );
};

const ActiveForm = ({ page, business }) => {
  if (!page) return null;
  switch (page.title) {
    case "location":
      return <LocationForm business={business} />;
    case "title":
      return <TitleForm business={business} />;
    case "category":
      return <CategoryForm business={business} />;
    case "phone":
      return <PhoneForm business={business} />;
    case "website":
      return <WebsiteForm business={business} />;
    case "open_hours":
      return <OpenHoursForm business={business} />;
    case "facilities":
      return <FacilitiesForm business={business} />;
    case "description":
      return <DescriptionForm business={business} />;
    case "images":
      return <ImagesForm business={business} />;
    case "review":
      return <ReviewForm business={business} />;
    default:
      return <DraftForm />;
  }
};

export default AddBusiness;
