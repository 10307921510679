import { MapIcon } from '@heroicons/react/outline'
import React from 'react'

const FeatureCrad = ({ icon, title, caption, color_dark, color_light }) => {
    return (
        <div className='flex items-start space-x-6 max-w-5xl justify-center my-5'>
            <div className='w-16 h-16 aspect-square rounded-full flex justify-center items-center' style={{ backgroundColor: color_light }}>
                {icon}
            </div>
            <div className='w-[70%]'>
                <div className={`font-semibold text-md lg:text-lg pb-5`} style={{ color: color_dark }}>{title} </div>
                <div className='font-light text-textdark text-sm lg:text-lg pb-5'>{caption}</div>
            </div>
        </div>
    )
}

export default FeatureCrad