import {
  ExclamationCircleIcon,
  ExclamationIcon,
  MinusIcon,
  PlusIcon,
  SearchIcon,
  XIcon,
} from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  clear_timetable,
  store_timetable,
} from "../../../../../../store/actions/timetableActions";
import {
  generateTimeTable,
  handleSelectCourse,
  queryTimeTable,
  saveTimetableData,
} from "../../functions";
import Loader from "../../../../../../assets/spinner.svg";
import NoItems from "../../../../../../assets/not_found.png";
import ViewTimetable from "../ViewTimetable";
import { useSuzi } from "../../../../../../Components/Assistant";
import DashboardFormField from "../../../../DashboardComponents/DashboardFormField";
import { DashboardFormDropDownList } from "../../../../DashboardComponents/DashboardFormFieldDropList";

const EditTimetable = (props) => {
  const { user_timetable, storeTimetable, current_user } = props;
  const [timetable, setTimetable] = useState([]);
  const [search, setSearch] = useState("");
  const [loadingGenerateTT, setLoadingGenerateTT] = useState(false);
  const [generatedTable, setGeneratedTable] = useState(null);
  const [courses, setCourses] = useState(null);
  const [clash, setClash] = useState(false);
  const [showingEdit, setShowingEdit] = useState(true);
  const [showAddCourse, setShowAddCourse] = useState(false);

  useEffect(() => {
    const stored_user_table = user_timetable?.data;
    if (stored_user_table) {
      handleSelectCourse(stored_user_table).then((t) => {
        setTimetable(t);
      });
    }
  }, []);

  const suzi = useSuzi();

  useEffect(() => {
    setClash(timetable?.some((i) => i.clash === true));
  }, [timetable]);

  const searchTT = async () => {
    const courselist = await queryTimeTable(search);
    setCourses(courselist);
  };

  const addToTimeTable = async (course) => {
    handleSelectCourse([...timetable, course]).then(async (res) => {
      setTimetable(res);
    });
  };

  const removeFromTimeTable = async (course) => {
    if (timetable.length <= 1) {
    }

    const res = await timetable?.filter((c, indx) => {
      if (c.title !== course?.title) {
        return c;
      }
    });
    if (res.length <= 0) {
      storeTimetable([]);
      saveTimetableData({
        uid: current_user?.user.uid,
        timetable: JSON.stringify([]),
      });
      return setTimetable([]);
    }
    await handleSelectCourse(res).then(async (resp) => {
      setTimetable(resp);
    });
  };

  const handleGenerateTimeTable = async () => {
    setLoadingGenerateTT(true);
    await generateTimeTable(timetable)
      .then(async (generatedTT) => {
        setGeneratedTable(generatedTT);
        storeTimetable(generatedTT);
        saveTimetableData({
          uid: current_user?.user.uid,
          timetable: JSON.stringify(generatedTT),
        });
      })
      .catch((err) => console.error("GENERATE TABLE ERROR: ", err))
      .finally(async () => {
        setLoadingGenerateTT(false);
        suzi.addMessage({
          text: (
            <a
              target="_blank"
              href="https://www.buymeacoffee.com/guideutility"
              rel="noreferrer noopener"
              className="flex  max-w-md p-4 rounded-xl space-y-4 flex-col bg-primary-500 text-white  justify-start items-center w-full"
            >
              <div className=" text-sm text-left">
                Hey there, <br /> <br /> appreciate the work we are doing at
                Guide?. you can show your support in helping us keep our servers
                running so we can keep Guide 100% free, while working on more
                awesome tools for you.{" "}
              </div>
              <div className="flex items-center space-x-4 max-h-14 rounded-full px-4 pr-0 bg-white">
                <div className="font-bold text-primary-500">Buy us Coffee</div>
                <div className=" w-16 aspect-square rounded-full bg-white shadow-lg p-2 ring-2 ring-black/10">
                  <img
                    src="https://blogiestools.com/wp-content/uploads/2021/02/Buy-me-a-coffee-review.jpg"
                    className="w-full h-full object-cover rounded-full"
                  />
                </div>
              </div>
            </a>
          ),
          timeout: 10000,
        });
      });
  };

  const handleViewTimetable = () => {
    return setShowingEdit(false);
  };

  const SearchedCourseItem = ({ course, indx, inTable }) => {
    return (
      <div
        className={`flex  ${
          indx < courses.length - 1 && "border-b-[1px] border-white"
        } items-center justify-between py-2 px04`}
      >
        <div>{course.title}</div>
        <div
          onClick={() =>
            !inTable ? addToTimeTable(course) : removeFromTimeTable(course)
          }
          className="cursor-pointer"
        >
          {!inTable ? (
            <PlusIcon className="guide-icon w-5 h-5" />
          ) : (
            <MinusIcon className="guide-icon w-5 h-5" />
          )}
        </div>
      </div>
    );
  };

  const AddCourse = () => {
    const _days = [
      {
        id: 0,
        title: "MONDAY",
        value: "monday",
      },
      {
        id: 1,
        title: "TUESDAY",
        value: "tuesday",
      },
      {
        id: 2,
        title: "WEDNESDAY",
        value: "wednesday",
      },
      {
        id: 3,
        title: "THURSDAY",
        value: "thursday",
      },
      {
        id: 4,
        title: "FRIDAY",
        value: "friday",
      },
      {
        id: 5,
        title: "SATURDAY",
        value: "saturday",
      },
      {
        id: 6,
        title: "SUNDAY",
        value: "sunday",
      },
    ];

    const time = async (end = false) => {
      const all = [];
      await [...Array(10)].map((_, i) => {
        const hour = 9 + i;
        all.push({
          id: i,
          title: `${hour.toString().padStart(2, "0")}:${end ? "50" : "00"}`,
          value: `${hour.toString().padStart(2, "0")}:${end ? "50" : "00"}`,
        });
      });
      return all;
    };

    const [course, setCourse] = useState("");
    const [venue, setVenue] = useState("");
    const [days, setDays] = useState(_days[0]);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [startDays, setStartDays] = useState(null);
    const [endDays, setEndDays] = useState(null);

    useEffect(() => {
      (async () => {
        const allStart = await time();
        const allEnd = await time(true);
        setStartDays(allStart);
        setEndDays(allEnd);
        setStart(allStart[0]);
        setEnd(allEnd[0]);
      })();
    }, []);

    useEffect(() => {}, [start, end, startDays, endDays]);

    return (
      <div>
        <div>
          <DashboardFormField
            name="Course"
            setText={setCourse}
            text={course}
            placeholder="eg. COM 101"
          />
        </div>
        <div>
          <DashboardFormField
            name="Venue"
            setText={setVenue}
            text={venue}
            placeholder="eg. Hall 1"
          />
        </div>
        <div>
          <DashboardFormDropDownList
            list={_days}
            name="Week Day"
            setSelectedInterval={setDays}
            selectedInterval={days}
          />
        </div>
        <div>
          <DashboardFormDropDownList
            list={startDays}
            name="Start Time"
            setSelectedInterval={setStart}
            selectedInterval={start}
          />
        </div>
        <div>
          <DashboardFormDropDownList
            list={endDays}
            name="End Time"
            setSelectedInterval={setEnd}
            selectedInterval={end}
          />
        </div>
        <div
          onClick={() => {
            setTimetable([
              ...timetable,
              {
                day: days.title,
                time: `${start.value}-${end.value}`,
                title: course,
                venue: venue,
                new: true,
              },
            ]);
            setShowAddCourse(!showAddCourse);
          }}
          className="w-full py-4 flex rounded-xl cursor-pointer justify-center my-5 bg-primary-500 text-white"
        >
          Add To Timetable
        </div>
      </div>
    );
  };

  const SelectedCoursesItem = ({ course }) => {
    return (
      <div
        className={`mx-4 my-2 flex justify-between rounded-lg items-center ${
          course.clash ? "bg-red-300 animate-pulse" : "bg-primary-300"
        }`}
      >
        <div className="px-2 pl-4">{course.title}</div>
        <div
          className={`p-4 ${
            course.clash ? "bg-red-500" : "bg-primary-500"
          } rounded-r-lg`}
          onClick={() => removeFromTimeTable(course)}
        >
          <MinusIcon className="guide-icon w-5 h-5 text-white" />
        </div>
      </div>
    );
  };

  return showingEdit ? (
    <div className="pt-3 relative h-full">
      <div className="flex flex-row items-center justify-start space-x-2">
        <div className="w-full z-20 py-3 rounded-xl bg-primary-300 px-5 h-14 flex items-center space-x-2 relative">
          <XIcon
            onClick={() => {
              setCourses(null);
              showAddCourse && setShowAddCourse(false);
            }}
            className="guide-icon w-5 h-5 text-primary-500"
          />
          <input
            onChange={(e) => setSearch(e.target.value)}
            className="bg-transparent outline-none w-[94%]"
            placeholder="Find Courses..."
          />
          <div
            onClick={() => searchTT()}
            className="p-2 bg-primary-500 rounded-xl cursor-pointer"
          >
            <SearchIcon className="guide-icon w-5 h-5 text-white ml-auto" />
          </div>
        </div>
        <div>
          <div
            onClick={() => setShowAddCourse(!showAddCourse)}
            className=" h-12 flex justify-center items-center aspect-square bg-primary-500 rounded-xl cursor-pointer"
          >
            <PlusIcon className="guide-icon w-7 h-7 text-white" />
          </div>
        </div>
      </div>
      {showAddCourse ? (
        <AddCourse />
      ) : courses ? (
        <div className="w-full overflow-y-auto scrollbar z-10 h-full absolute inset-0 flex justify-center bg-black/70">
          <div className="w-[90%] absolute top-20 px-4 rounded-lg bg-primary-300">
            {courses?.length > 0 ? (
              courses?.map((course, indx) => {
                const inTable =
                  timetable?.length > 0 &&
                  timetable?.some((i) => i?.title === course.title);
                return (
                  <SearchedCourseItem
                    course={course}
                    inTable={inTable}
                    indx={indx}
                    key={indx}
                  />
                );
              })
            ) : (
              <div className="flex items-center space-x-4">
                <ExclamationCircleIcon className="guide-icon w-5 h-5 text-primary-500" />
                <div className="text-md font-medium text-primary-500">
                  No Search Results
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="mt-10">
          {timetable.length > 0 ? (
            <div className="font-medium text-lg my-4">Selected Courses</div>
          ) : (
            <div className="flex flex-col w-full justify-center items-center">
              <img src={NoItems} className="w-[50%] object-cover" />
              <div className="font-medium text-lg py-2">
                No Courses Selected
              </div>
              <div>Select Your courses and generate your timetable</div>
            </div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 items-center">
            {timetable?.map((item, indx) => {
              return <SelectedCoursesItem course={item} key={indx} />;
            })}
          </div>
          {!generatedTable && timetable?.length > 0 && !clash && (
            <div
              onClick={() => handleGenerateTimeTable()}
              className="w-full py-4 flex rounded-xl cursor-pointer justify-center my-5 bg-primary-500 text-white"
            >
              {loadingGenerateTT ? (
                <img src={Loader} className="w-4 h-4 animate-spin" />
              ) : (
                "Generate TimeTable"
              )}
            </div>
          )}

          {generatedTable && (
            <div
              onClick={() => handleViewTimetable()}
              className="w-full py-4 flex rounded-xl cursor-pointer justify-center my-5 bg-primary-800 text-white"
            >
              View TimeTable
            </div>
          )}

          {clash && (
            <div className="flex space-x-2 items-center text-red-400 mt-10">
              <ExclamationIcon className="guide-icon w-8 h-8" />
              <div className="text-sm">
                there are lecture time clashes in your time table, resolve
                clashes to continue.
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    <ViewTimetable table={generatedTable} />
  );
};

const mapStateToProps = (state) => {
  return {
    user_timetable: state.timetable,
    current_user: state.user?.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeTimetable: (table) => dispatch(store_timetable(table)),
    clearTimeTable: () => dispatch(clear_timetable()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTimetable);
