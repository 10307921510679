import React, { useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

let containerStyle = {
  width: "100%",
  height: "400px",
};

const MapUI = ({ position, setPosition, container = null, address = null }) => {
  const [map, setMap] = React.useState(null);
  const [coords, setCoords] = React.useState({ lat: 0, lng: 0 });

  const onLoad = React.useCallback(function callback(map) {
    navigator.geolocation.getCurrentPosition(function (position) {
      const bounds = new window.google.maps.LatLngBounds({
        lat: parseFloat(position.coords.latitude),
        lng: parseFloat(position.coords.longitude),
      });
      map.fitBounds(bounds);
      setMap(map);
    });
  }, []);

  useEffect(() => {
    setCoords(position);
  }, [position, map]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (container) {
    containerStyle = container;
  }

  return (
    <div className={`${!container && "max-w-lg"} relative h-auto`}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={coords}
        zoom={10}
        // onCenterChanged={() => updateCenter(map?.getCenter().toJSON())}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker
          animation={"DROP"}
          position={coords}
          onDragEnd={(e) => setPosition({ ...e.latLng.toJSON(), address })}
          draggable={true}
        />
      </GoogleMap>
    </div>
  );
};

export default React.memo(MapUI);
