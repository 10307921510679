import { CursorClickIcon, EyeIcon, HeartIcon, ShareIcon, ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import { BlogImpressions, BlogReactions } from '../../../../Blog/data'
import NoImages from '../../../../../assets/Group 590.svg'
import { getBlogDescriptionText } from '../../../../Blog/BlogView'


const RecentBlogPosts = ({ uid, setEditData, recents, setRecents, loadRecentBlogs }) => {

    const [active, setActive] = useState('recent')
    useEffect(() => {
        loadRecentBlogs()
    }, [])

    useEffect(() => {
        console.log(recents)
    }, [recents])



    return (
        <div className='px-5 space-y-3 w-full'>
            {
                recents ?
                    recents.length > 0 ?
                        <div className='space-y-4 bg-white w-full rounded-xl p-5'>
                            <div className='text-xl text-left font-semibold'>Recent Posts</div>
                            <div className='w-full'>
                                <div className='flex items-center space-x-4'>
                                    <div onClick={() => loadRecentBlogs('recent')} className={`cursor-pointer font-semibold hover:underline ${active === 'recent' ? 'text-primary-500' : 'text-primary-500/50'}`}>Recent</div>
                                    <div onClick={() => loadRecentBlogs('popular')} className={`cursor-pointer font-semibold hover:underline ${active === 'popular' ? 'text-primary-500' : 'text-primary-500/50'}`}>Most Popular</div>
                                </div>
                                <div className="space-y-4 divide-y w-full">
                                    {
                                        recents?.map((blog, indx) => {
                                            console.log(blog,'}}}}}}}}}}}}}}')
                                            if (!blog.draftID) {
                                                return <RecentPostCard key={indx} data={blog} image={blog.cover} title={blog.title} desc={getBlogDescriptionText(blog.post.find(i => i.type === 'paragraph')?.value).slice(0, 100)} setEditData={setEditData} />
                                            } else {
                                                return <DraftedPostCard key={indx} data={blog} image={Array.isArray(blog.cover) ? blog.cover[0]?.src : ''} title={blog.title} desc={blog.post?.length > 0 ? getBlogDescriptionText(blog.post.find(i => i.type === 'paragraph')?.value).slice(0, 100) : '...'} setEditData={setEditData} />
                                            }
                                        })

                                    }
                                </div>
                            </div>
                            <div className='w-full h-32 block md:hidden' />
                        </div>
                        :
                        <NoRecents />
                    :
                    [0, 0, 0, 0].map((_, key) => {
                        return <RecentPostCardLoading key={key} />
                    })
            }

        </div>
    )
}

export default RecentBlogPosts

const RecentPostCard = ({ image, title, desc, setEditData, data }) => {

    return (
        <div onClick={() => setEditData(data)} className='w-full flex md:max-h-52 flex-col md:flex-row hover:bg-gray-200 cursor-pointer h-auto bg-white p-2 rounded-lg'>
            <div className='p-2 aspect-square w-full md:w-[40%]'>
                <img src={image} className="w-full aspect-square rounded-lg h-full object-center object-cover" />
            </div>
            <div className='flex flex-1 flex-col items-center text-left'>
                <div className='space-y-2 px-5 w-full py-4'>
                    <div className='text-base text-left font-semibold'>{title.length > 25 ? title.slice(0, 25) + '...' : title}</div>
                    <div className='text-sm text-left '>{desc}...</div>
                </div>
                <div className='flex w-full justify-around py-4 border-t-2 border-gray-100 items-center'>
                    <div className='flex items-center space-x-2'>
                        <ThumbUpIcon className='w-5 h-5' />
                        <span>{data.reactions ? data.reactions[BlogReactions.LIKE_POST] ?? 0 : 0}</span>
                    </div>
                    <div className='flex items-center space-x-2'>
                        <ThumbDownIcon className='w-5 h-5' />
                        <span>{data.reactions ? data.reactions[BlogReactions.DISLIKE_POST] ?? 0 : 0}</span>
                    </div>
                    <div className='flex items-center space-x-2'>
                        <ShareIcon className='w-5 h-5' />
                        <span>{data.reactions ? data.reactions[BlogReactions.SHARE_POST] ?? 0 : 0}</span>
                    </div>
                    <div className='flex items-center space-x-2'>
                        <EyeIcon className='w-5 h-5' />
                        <span>{data.impressions ? data.impressions[BlogImpressions.POST_VIEW] ?? 0 : 0}</span>
                    </div>
                    <div className='flex items-center space-x-2'>
                        <CursorClickIcon className='w-5 h-5' />
                        <span>{data.impressions ? data.impressions[BlogImpressions.POST_CLICKS] ?? 0 : 0}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const DraftedPostCard = ({ image, title, desc, setEditData, data }) => {

    return (
        <div onClick={() => setEditData(data)} className='w-full max-h-52 flex hover:bg-gray-200 cursor-pointer h-auto bg-white p-2 rounded-lg'>
            <div className='p-2 aspect-square w-[40%]'>
                <img src={image} className="w-full aspect-square rounded-lg h-full object-center object-cover" />
            </div>
            <div className='flex flex-1 flex-col items-start text-left'>
                <div className='space-y-2 px-5 py-4'>
                    <div className='text-lg font-semibold'>{title.length > 25 ? title.slice(0, 25) + '...' : title}</div>
                    <div className='py-1 px-2 w-fit rounded-lg bg-gray-500 text-white text-sm'>drafted</div>
                    <div className='text-base '>{desc}...</div>
                </div>
            </div>
        </div>
    )
}

const RecentPostCardLoading = () => {
    return (
        <div className='w-full flex h-auto max-h-52 bg-white p-2 rounded-lg'>
            <div className='p-2 aspect-square w-[40%]'>
                <div className="w-full rounded-lg h-full bg-gray-200 animate-pulse object-center object-cover" />
            </div>
            <div className='flex flex-1 flex-col items-start text-left'>
                <div className='space-y-2 px-5 py-4'>
                    <div className='text-lg font-semibold bg-gray-200 animate-pulse w-[40%] h-3 rounded' />
                    <div className='w-full space-y-1'>
                        <div className='text-base bg-gray-200 animate-pulse w-[80%] h-3 rounded' />
                        <div className='text-base bg-gray-200 animate-pulse w-[80%] h-3 rounded' />
                        <div className='text-base bg-gray-200 animate-pulse w-[80%] h-3 rounded' />
                    </div>
                </div>
                <div className='flex w-full justify-around py-4 border-t-2 border-gray-100 items-center'>
                    <div className='flex items-center space-x-2'>
                        <div className='w-5 h-5 bg-gray-200 animate-pulse rounded' />
                        <div className='h-4 w-[10%] bg-gray-200 animate-pulse rounded' />
                    </div>
                    <div className='flex items-center space-x-2'>
                        <div className='w-5 h-5 bg-gray-200 animate-pulse rounded' />
                        <div className='h-4 w-[10%] bg-gray-200 animate-pulse rounded' />
                    </div>
                    <div className='flex items-center space-x-2'>
                        <div className='w-5 h-5 bg-gray-200 animate-pulse rounded' />
                        <div className='h-4 w-[10%] bg-gray-200 animate-pulse rounded' />
                    </div>
                    <div className='flex items-center space-x-2'>
                        <div className='w-5 h-5 bg-gray-200 animate-pulse rounded' />
                        <div className='h-4 w-[10%] bg-gray-200 animate-pulse rounded' />
                    </div>
                </div>
            </div>
        </div>
    )
}

const NoRecents = () => {
    return (
        <div className='bg-white p-5 py-24 rounded-lg'>
            <div className=' flex flex-col w-full items-center justify-center space-y-3 pb-5'>
                <img src={NoImages} className=" w-24 h-24" alt='guide no reviews notice image' />
                <div>
                    <div className='text-lg font-medium text-gray-500'>No Recent Posts</div>
                    <div className='text-xs text-gray-400'>your recent posts show here</div>
                </div>
            </div>
        </div>
    )
}