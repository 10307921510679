import React from 'react'

const DropDownMenu = ({list}) => {
  return (
    <div className='bg-transparent w-[200px] shadow-lg ring-2 ring-black/10 rounded-2xl divide-y-2 absolute -left-[150px]'>
        {
            list.map((item, index) => {
                return <div onClick={() => !item.disabled && item.action() } key={index} className={`bg-white cursor-pointer ${item.disabled? 'bg-gray-100' : 'hover:bg-primary-100'} w-auto px-2 sm: px-4 py-1 sm:py-2 text-center ${item.color? item.color :'text-gray-400'} ${index === 0 ? 'rounded-t-2xl': index === (list.length - 1) ? 'rounded-b-2xl': '' } `}>{item.title}</div>
            })
        }
    </div>
  )
}

export default DropDownMenu