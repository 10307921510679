import { CheckCircleIcon, SearchIcon, TagIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { loadSkillsets } from '../../functions/service_functions.js'
import FindHelpers from './FindHelpers'
import ServiceSearch from './ServiceProvidersList/ServiceSearch/index.js'

const ServicesPage = () => {

    const [skillsets, setSkillsets] = useState([])

    useEffect(() => {

        let mounted = true

        if (mounted) {
            loadSkillsets().then(skills => {
                setSkillsets(skills)
            })
        }

        return () => {
            setSkillsets([])
            mounted = false
        }
    }, [])

    useEffect(() => {

    }, [skillsets])

    return (
        <div className='w-full h-auto overflow-y-auto scrollbar'>
            <ServiceSearch categories={skillsets} />
            <div className='flex flex-col w-full justify-center items-center py-10'>
                <div className='text-2xl font-bold py-10'>Popular in your area</div>
                <div className='grid space-y-4 grid-cols-1 md:grid-cols-3 md:space-y-0 w-full px-2 md:px-0 md:w-[70%] gap-10'>
                    {
                        skillsets?.length > 0 ?
                            skillsets.slice(0, 6).map((skill, indx) => {
                                return <CategoriesCard key={indx} skill={skill} />
                            })
                            :
                            [0, 0, 0].map((_, i) => {
                                return <CategoriesLoadingCard key={i} />
                            })
                    }
                </div>
                {skillsets && <Link to="/services/list/all" className='text-blue-500 text-center mt-10 w-full underline'>see all...</Link>}

            </div>
            <FindHelpers />
            <div className='w-full h-52' />
        </div>
    )
}


const CategoriesCard = ({ skill }) => {
    return (
        <Link to={`/services/list/${skill.id}`} className='w-auto hover:scale-110 cursor-pointer ease_transition'>
            <img src={skill.images} className='w-full aspect-video object-cover' />
            <div>
                <div className='pt-5 text-lg font-semibold'>{skill.title}</div>
                <div className='flex items-center space-x-4 justify-center pt-5'>
                    <TagIcon className='w-3 h-4 stroke-1' />
                    <div>Avg. Rates:  {skill?.rates?.currency ? skill?.rates.currency : '$'}{skill?.rates?.average_rates}/hr</div>
                </div>
            </div>
        </Link>
    )
}

const CategoriesLoadingCard = ({ skill }) => {
    return (
        <div className='w-auto cursor-pointer ease_transition space-y-2'>
            <div className='w-full aspect-video object-cover bg-gray-300 animate-pulse' />
            <div>
                <div className='pt-5 w-auto bg-gray-300 animate-pulse' />
                <div className='flex items-center space-x-4 justify-center pt-5'>
                    <div className='w-3 h-4 stroke-1 bg-gray-300 animate-pulse' />
                    <div className='w-32 h-2 bg-gray-300 animate-pulse ' />
                </div>
            </div>
        </div>
    )
}




export default ServicesPage