import { LocationMarkerIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ApartmentSearchBar from '../../../Components/ApartmentSearchBar'
import ImageSlider_v2 from '../../../Components/imageSlider_v2'
import ImpressionClickTrackerHOC from '../../../Components/ImpressionClickTrackerHOC'
import Loader, { ContainedLoader } from '../../../Components/Loader'
import { useHousingSearch } from '../../../Components/UtilityComponents/HousingSearchContext'
import { apartment_impression } from '../../../functions/housing_functions'
import { updateGroupImpressions } from '../../../functions/service_functions.js'

const HousingSearch = ({ limit = 8, grid = 4, show_search = false }) => {

    const housingSearch = useHousingSearch()
    const navigate = useNavigate()

    useEffect(() => {
        housingSearch.setLimit(limit)
    }, [])

    const setLimit = async () => housingSearch.setLimit(0)

    const goToSearch = async () => {
        setLimit().then(() => {
            navigate('/apartments/search')
        })
    }

    const handleImpression = async (doc, type = 'view') => {

        const data = {
            id: doc.id,
            type: type
        }
        if (doc.groupID) await updateGroupImpressions({ id: doc.groupID, type })
        await apartment_impression(data)
    }

    return (
        <div className='w-auto'>
            <div className='w-full flex items-center justify-center'>
                <ApartmentSearchBar housingSearch={housingSearch} />
            </div>
            {
                housingSearch.loading ?
                    <div className='py-[10vh] w-full flex items-center justify-center'>
                        <ContainedLoader />
                    </div>
                    :
                    <div className={`grid sm:px-5 gap-2 px-2 md:px-10 lg:px-0 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 ${grid === 4 && 'xl:grid-cols-4'} md:gap-5 my-16`}>
                        {housingSearch.results ?
                            housingSearch.results.length > 0 ?
                                housingSearch.results?.map((data, i) => {
                                    return <HouseCardType2 data={data} key={i} handleImpression={handleImpression} />
                                })
                                :
                                <div className='p-2 px-4 rounded-full col-span-4 mx-auto bg-white font-medium text-primary-800 max-w-lg'>No results. Try adjusting your search or clear the filters.</div>
                            : null
                        }
                    </div>
            }
            {(show_search && !(housingSearch.size > limit)) &&
                <div className='p-2 px-4 rounded-full col-span-4 mx-auto bg-white font-medium text-primary-800 max-w-lg'>Select a region to search for an apartment.</div>

            }
            {(housingSearch.size > limit && limit !== 0) && <div onClick={goToSearch} className='bg-primary-500 text-white font-semibold p-2 rounded-full'>see all {housingSearch.size} results</div>}
        </div>
    )
}

export default HousingSearch

const HouseCardType1 = ({ data, handleImpression }) => {

    const [mouseIn, setMouseIn] = useState(false)

    return (
        <ImpressionClickTrackerHOC onImpression={() => handleImpression(data)}>

            <Link onClick={() => handleImpression(data, 'click')} to={`/apartments/${data.id}`} className='hover:underline cursor-pointer'>
                <div onMouseEnter={() => setMouseIn(!mouseIn)} onMouseLeave={() => setMouseIn(!mouseIn)} className='max-w-sm cursor-pointer mx-auto h-auto rounded-xl aspect-square relative overflow-hidden'>
                    <img src={data.images[0]}
                        className={`w-full ease_transition h-full object-cover object-center rounded-xl ${mouseIn && 'scale-125'}`} />
                    <div className='absolute inset-0 top-0 w-full h-full from-black/70 bg-gradient-to-t rounded-xl'>
                        <div className='h-full w-full flex flex-col justify-between'>
                            <div></div>
                            <div className='p-5 text-white font-medium text-sm text-left'>
                                <div>{data?.title?.translations.en_us}</div>
                                <div className='flex items-center space-x-2'>
                                    <LocationMarkerIcon className='w-4 h-4 text-primary-300' />
                                    <div>{data?.address?.area}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </ImpressionClickTrackerHOC>
    )
}

const HouseCardType2 = ({ data, handleImpression }) => {

    const [mouseIn, setMouseIn] = useState(false)

    return (
        <ImpressionClickTrackerHOC onImpression={() => handleImpression(data)}>
            <div onMouseEnter={() => setMouseIn(!mouseIn)} onMouseLeave={() => setMouseIn(!mouseIn)} className='max-w-sm ring-1 mx-auto bg-white ring-black/5 pb-4 h-auto rounded-xl aspect-square relative overflow-hidden'>
                <div className='w-full h-[70%] overflow-hidden'>
                    {/* <img src={data.images[0]}
                    className={`w-full ease_transition object-cover object-center h-full rounded-b-none rounded-xl ${mouseIn && 'scale-125'}`} /> */}
                    <ImageSlider_v2 images={data.images} cover={true} />
                </div>
                <div className='flex-1 w-full flex flex-col justify-between'>

                    <div className='p-5 h-full bg-white rounded-b-xl text-gray-500 font-medium text-base text-left'>
                        <Link onClick={() => handleImpression(data, 'click')} to={`/apartments/${data.id}`} className='hover:underline cursor-pointer'>{data?.title?.translations.en_us}</Link>
                        <div className='flex items-center space-x-2'>
                            <LocationMarkerIcon className='w-4 h-4 text-gray-600' />
                            <div>{data?.address?.area}</div>
                        </div>
                    </div>
                </div>
            </div>
        </ImpressionClickTrackerHOC>
    )
}