import { Dialog } from '@headlessui/react'
import React from 'react'


const sizeToWidth = (width) => {
    switch (width) {
        case 4:
            return 'max-w-xs'
        case 5:
            return 'max-w-sm'
        case 6:
            return 'max-w-md'
        case 7:
            return 'max-w-lg'
        case 8:
            return 'max-w-xl'
        case 9:
            return 'max-w-2xl'
        case 10:
            return 'max-w-3xl'
        case 11:
            return 'max-w-4xl'
        case 12:
            return 'max-w-5xl'
        case 13:
            return 'max-w-6xl'
        case 14:
            return 'max-w-7xl'
        case 100:
            return 'max-w-full'
    }
}

const Modal = ({ children, open, setOpen, width = 11, showBackground = 'bg-white shadow-2xl rounded-xl ring-1 ring-black/5', topPad = 20, hideOnMobile=false }) => {
    return (
        <Dialog
            as='div'
            open={open}
            onClose={setOpen}
            className={`fixed z-50 inset-0 px-4 w-full overflow-y-auto ${hideOnMobile?'hidden lg:flex':""}`}
            style={{marginTop: `${topPad}vh`}}
        >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75" />
            <div className={`relative w-full  ${showBackground && showBackground} ${sizeToWidth(width)} mx-auto `}>
                {children}
            </div>
        </Dialog>
    )
}

export default Modal