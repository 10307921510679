import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useAccounts } from "../../../../Components/UtilityComponents/AccountType";
import { useAuth } from "../../../../Components/UtilityComponents/AuthProvider";
import { addUserAccountGroup } from "../../../../functions/authFunctions";
import { store_user_data } from "../../../../store/actions/authActions";
import AccountTypes from "./AccountTypes";
import Loader from "../../../../assets/spinner.svg";
import { useSuzi } from "../../../../Components/Assistant";
import { useAlert } from "../../../../Components/UtilityComponents/ErrorContext";
import { Dialog } from "@headlessui/react";

const SelectAccountType = ({ storeUser, user, open, setOpen }) => {
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(null);

  const auth = useAuth();
  const alert = useAlert();
  const suzi = useSuzi();
  const navigate = useNavigate();
  const location = useLocation();
  const acccount_type = useAccounts();

  useEffect(() => {
    // console.log(account)
    return () => {
      // setAccount(null)
    };
  }, [account, location.pathname]);

  const handleAddAccount = () => {
    setLoading(true);
    const data = {
      uid: auth.user.uid ? auth.user.uid : auth.user.user.uid,
      account_type: account.type,
      impressions: [],
      socials: [],
      created_at: new Date(),
      reviews: [],
      last_login: new Date(),
    };
    addUserAccountGroup(data)
      .then((response) => {
        acccount_type.updateActiveAccount(response);
        return response;
      })
      .then((response) => {
        auth.setUser({ ...auth.user, role: response.role });

        if (account.type === "service_provider") {
          navigate("/dashboard/services", {
            state: { from: location },
            replace: true,
          });
        } else if (account.type === "student") {
          navigate("/dashboard/timetable", {
            state: { from: location },
            replace: true,
          });
        }
        setOpen(false);
      })
      .catch((e) => {
        console.log(e);
        alert.setalert({
          title: "Opps!",
          body: "something went wrong, try again...",
          type: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return open ? (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="fixed z-50 inset-0 flex justify-center items-center p-4 overflow-y-auto"
    >
      <Dialog.Overlay className="fixed inset-0 bg-gray-500/75" />

      <div className="relative w-full flex justify-center items-center md:w-full lg:w-[60%] h-[95%] lg:h-[85%] ">
        <div className=" w-40 h-20 rounded-lg bg-primary-500 absolute -top-10 -left-10 "></div>

        <div className="w-full fixed md:relative pt-10 h-[100vh] md:h-full md:m-10 flex z-10 flex-col md:rounded-3xl shadow-2xl bg-[#e8e8e8] overflow-y-auto scrollbar">
          <div className="w-full flex flex-col px-5 md:px-32 items-start space-y-4">
            <div className="text-left mt-2 text-lg md:text-2xl">
              SELECT ACCOUNT TYPE
            </div>
            <div className="h-1 w-10 bg-primary-500"></div>
          </div>

          <div className="w-full flex justify-center h-auto md:h-[80%]">
            <AccountTypes
              setAccount={setAccount}
              user_account={user.user?.groupIDs}
            />
          </div>

          <div className=" w-full flex  justify-center">
            {account && (
              <div
                onClick={() => handleAddAccount()}
                className="py-2 bg-primary-500 px-4 font-semibold rounded-full shadow-md cursor-pointer hover:bg-primary-800 text-white"
              >
                {loading ? (
                  <img src={Loader} className="w-4 h-4 animate-spin" />
                ) : (
                  "Continue"
                )}
              </div>
            )}
          </div>
          {/* <div onClick={() => navigate('/dashboard/services')} className='py-2 bg-primary-500 px-4 font-semibold rounded-full shadow-md cursor-pointer hover:bg-primary-800 text-white'>Go To Dash</div> */}

          <div className="py-5 text-sm text-center px-5 md:px-20">
            <span>
              You can only have <strong>one of each account type</strong>.{" "}
            </span>
          </div>
        </div>

        <div className=" w-44 h-44 rounded-lg bg-primary-500  absolute -bottom-10 -right-10 "></div>
      </div>
    </Dialog>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    user: state.user?.data,
    user_account: state.accounts?.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeUser: (data) => dispatch(store_user_data(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectAccountType);
