import { useState } from 'react'
import { Switch } from '@headlessui/react'

const Checker = ({activeState, setActiveState}) => {

  return (
    <div className="py-5">
      <Switch
        checked={activeState}
        onChange={setActiveState}
        className={`${activeState ? 'bg-teal-900' : 'bg-gray-300'}
          relative inline-flex items-center flex-shrink-0 h-[25px] w-[49px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`${activeState ? 'translate-x-6' : 'translate-x-0'}
            pointer-events-none inline-block h-[20px] w-[20px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
        />
      </Switch>
    </div>
  )
}

export default Checker
