import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { store_services } from '../../../store/actions/serviceActions';
import { pages } from '../../../Pages/Dashboard/Screens/Main/SubScreens/Settings/Forms/data';
import { deleteService, deleteServiceImages, getCoordHash, loadServices, saveService, uploadServiceImages } from '../../../functions/service_functions.js';

const page = {
    id: 0,
    title: 'draft',
    progress: 0,
    pageTitle: 'Finish your listing to start taking jobs',
    pageDescription: 'you can always edit your listing after you publish it'
}

const inital_user = {
    services: null,
    images: null,
    location: null,
    skillsets: null,
    description: null,
    review: null,
    currentPage: null,
    last_saved: null,
    progress: null,
    stored_services: null,
    acceptedAgreement: null,
    goToNextPage: () => null,
    goToPrevPage: () => null,
    saveService: () => null,
    updateService: () => null,
    updateServices: () => null,
    updateImages: () => null,
    updateLocation: () => null,
    updateSkillsets: () => null,
    updateDescription: () => null,
    updateReview: () => null,
    updatelastSaved: () => null,
    storeService: () => null,
    saveSkillsets: () => null,
    removeSkillSet: () => null,
    goToDraft: () => null,
    goToPage: () => null,
    clearServices: () => null,
    publishServices: () => null,
    serviceDelete: () => null,
    setAcceptedAgreement: () => null

}

const ServiceFormContext = React.createContext(inital_user)

function AuthProvider({ children, storeService, stored_services, user }) {

    const GROUP_ID = user?.user.groupIDs?.filter((gid) => gid.type === "service_provider")[0]?.id

    useEffect(() => {
        let mounted = true
        if (mounted) {
            if (!stored_services?.length > 0) {
                loadServices(GROUP_ID).then(res => {
                    if (!res.message) {
                        storeService(res)
                    } else {
                        clearServices()
                    }
                })
            }
        }
        return () => {
            mounted = false
        }
    }, [])

    let [services, setServices] = React.useState(stored_services?.services ? stored_services.services : []);
    let [images, setImages] = React.useState(stored_services?.images ? stored_services.images : []);
    let [location, setLocation] = React.useState(stored_services?.location ? stored_services.location : []);
    let [skillsets, setSkillsets] = React.useState(stored_services?.skillsets ? stored_services.skillsets : []);
    let [description, setDescription] = React.useState(stored_services?.description ? stored_services.description : []);
    let [review, setReview] = React.useState(stored_services?.review ? stored_services.review : []);
    let [vehicles, setVehicles] = React.useState(stored_services?.vehicles ? stored_services.vehicles : []);
    let [currentPage, setCurrentPage] = React.useState(stored_services?.currentPage ? stored_services.currentPage : pages[0]);
    let [last_saved, setLastSaved] = React.useState(stored_services?.last_saved ? stored_services.last_saved : new Date());
    let [progress, setProgress] = React.useState(stored_services?.progress ? stored_services.progress : 0);
    let [acceptedAgreement, setAcceptedAgreement] = React.useState(stored_services?.review.length > 0 ? stored_services.review[0]?.agreed : false);

    const updateServices = (value) => setServices(value)
    const updateImages = (value) => setImages(value)
    const updateLocation = (value) => setLocation(value)
    const updateSkillsets = (value) => setSkillsets(value)
    const updateDescription = (value) => setDescription(value)
    const updateReview = (value) => setReview(value)
    const updateVehicles = (value) => setVehicles(value)
    const updatelastSaved = (value) => setLastSaved(value)

    const goToNextPage = async (pageIndex) => {
        if (pageIndex < pages.length) {
            // updatePage
            await saveProgress()
            await setCurrentPage(pages[pageIndex])
        }
        // save progress
    }

    const goToPrevPage = async (pageIndex) => {
        if (pageIndex >= 0) {
            // updatePage
            await saveProgress()
            await setCurrentPage(pages[pageIndex])
        }
        // save progress
    }

    const goToDraft = async () => {
        saveProgress().then(async () => {

            setTimeout(async () => {
                await storeService({
                    services,
                    images,
                    location,
                    skillsets,
                    description,
                    vehicles,
                    review,
                    last_saved: new Date(),
                    currentPage: page
                })
            }, 500)
            await setCurrentPage(page)
        })
    }

    const goToPage = async (page) => {
        await setCurrentPage(page)
    }

    const saveSkillsets = async (data) => {


        storeService({
            services,
            currentPage,
            images,
            location,
            skillsets: data,
            description,
            vehicles,
            review,
            last_saved: new Date()
        })

        updateSkillsets(data)

        return data

    }

    const removeSkillSet = async (id) => {
        let foundItem = []
        await stored_services.skillsets.forEach((ss, indx) => {
            if (ss.id !== id) {
                foundItem.push(ss)
            }
        })
        storeService({ ...stored_services, skillsets: foundItem })
        setSkillsets(foundItem)
        return stored_services
    }

    const clearServices = () => {
        storeService(
            {
                services: [],
                currentPage: pages[0],
                images: [],
                location: [],
                skillsets: [],
                description: [],
                vehicles: [],
                review: [],
                last_saved: null
            }
        )

        updateServices([])
        updateImages([])
        updateLocation([])
        updateSkillsets([])
        updateDescription([])
        updateReview([])
        updateVehicles([])
        updatelastSaved([])
        return
    }

    const saveProgress = async () => {
        return (async () => {
            await storeService({
                services,
                currentPage,
                images,
                location,
                skillsets,
                description,
                review,
                vehicles,
                last_saved: new Date()
            })
        })().then(() => stored_services)
    }



    const publishServices = async () => {
        const coord = {
            name: stored_services.location?.address,
            position: {
                lon: stored_services.location?.latlon.lng,
                lat: stored_services.location?.latlon.lat
            }
        }

        const _user = {
            uid: user?.user.uid,
            display_name: user?.user.display_name,
            photoURL: user?.user.photo_url,
            email: user?.user.email,
            phone: user?.user.phone_number,
            paymentId: user?.user?.payment_details?.account_id
        }
        if (!_user.paymentId) {
            return { type: 'error', title: 'No Withdrawal Setup', body: 'setup your withdrawal information to continue' }
        }

        if (_user.phone.length <= 0) {
            return { type: 'error', title: 'No Contact Information', body: 'setup your \"Phone Number\" in the \"Profile Settings\", and make sure this number has an active whatsapp account. ' }
        }

        return await saveProgress().then(async (services) => {
            return await uploadServiceImages(services.images, GROUP_ID).then(async (images) => {
                await getCoordHash(coord).then(_pos => {
                    saveService({ ...services, location, pos: _pos, images, active: true, group_id: GROUP_ID, user: _user, timestamp: new Date() })
                })
                return images
            }).then((r) => {
                storeService({ ...stored_services, images: r })
                return {type:'success',title:'Service Uploaded',body: "Your service has been uploaded"}
        }).catch(e => {return {type:'error',title:'Network error',body: "Something went wrong, try again"}})
        })
    }

    const serviceDelete = async (id) => {
        await deleteServiceImages(stored_services.images).then(async () => {
            await deleteService(id).then(() => {
                clearServices()
            })
        })
    }


    let value = {
        services,
        currentPage,
        images,
        location,
        skillsets,
        description,
        review,
        vehicles,
        last_saved,
        progress,
        stored_services,
        acceptedAgreement,
        setAcceptedAgreement,
        goToNextPage,
        goToPrevPage,
        updateServices,
        updateImages,
        updateLocation,
        updateSkillsets,
        updateDescription,
        updateReview,
        updateVehicles,
        updatelastSaved,
        saveProgress,
        saveSkillsets,
        removeSkillSet,
        goToDraft,
        goToPage,
        clearServices,
        publishServices,
        serviceDelete
    };

    return <ServiceFormContext.Provider value={value}>{children}</ServiceFormContext.Provider>;
}

export const useServiceForm = () => {
    return React.useContext(ServiceFormContext);
}

const mapStateToProps = (state) => {
    return {
        stored_services: state.services.payload,
        user: state.user?.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        storeService: (data) => dispatch(store_services(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider)