import React, { useEffect, useState } from 'react'
import DashboardFormWrapper from '../../DashboardComponents/FormComponents/DashboardFormWrapper'
import DashboardSliderOver from '../../DashboardComponents/DashboardSliderOver'
import DashboardWrapper from '../../DashboardComponents/DashboardWrapper'
import ServiceFormContext from '../../../../Components/UtilityComponents/ServiceFormContext'
import { ChartBarIcon, CursorClickIcon, EyeIcon, MapIcon, PhoneIcon } from '@heroicons/react/outline'
import { useOutletContext } from 'react-router';
import WelcomeCard from '../../Screens/Main/Cards/WelcomeCard';
import InfoCard from '../../Screens/Main/Cards/InfoCard';
import SocialsViewCard from '../../Screens/Main/Cards/SocialsViewCard';
import DashboardReviewCard from '../../Screens/Main/Cards/DashboardReviewCard';
import PhotosGridCard from '../../../../Components/PhotosGridCard';
import WelcomeSVG from '../../../../assets/ttdash.svg';
import { connect } from 'react-redux'
import { abbreviateNumber, loadServices } from '../../../../functions/service_functions.js'
import WhatsappIcon from '../../../../assets/WhatsappIcon'
import { AccountTypes } from '../SelectAccountTypePage/accounts'
import { store_business } from '../../../../store/actions/businessActions'
import GenericDashboardFormWrapper from '../../DashboardComponents/FormComponents/GenericDashboardFormWrapper'
import AddBusiness from './AddBusiness'
import { load_business } from '../../../../functions/business_functions'
import { useBusinessForm } from '../../../../Components/UtilityComponents/BusinessFormContext'
import { load_test_impressions } from '../../../../functions'
import ShowGraph from '../../DashboardComponents/VisualizeData/ShowGraph'


export const intervals = [
    { id: 1, value: '30 seconds', unavailable: false },
    { id: 2, value: '30 minutes', unavailable: false },
    { id: 3, value: 'hour', unavailable: false },
    { id: 4, value: 'day', unavailable: true },
    { id: 5, value: 'month', unavailable: false },
]

const Business = ({ business, storeBusiness, current_user }) => {

    const [openModal, setOpenModal] = useState(false)
    const [impression, setImpression] = useState(0)
    const [phone, setPhone] = useState(0)
    const [clicks, setClicks] = useState(0)
    const [mapClicks, setMapClicks] = useState(0)
    const [insta, setInsta] = useState(0)


    const getImages = (images) => {
        const tmp = []
        images?.map((img) => {
            if (img.src) {
                tmp.push(img.src)
            } else {
                tmp.push(img)
            }
        })
        return tmp
    }

    let { user } = useOutletContext()
    let user_name = user?.display_name?.split(' ')
    if (user_name) {
        user_name = user_name && user_name?.length > 1 ? user_name[1] : user_name[0]
    } else {
        user_name = 'User'
    }


    const GROUP_ID = current_user?.user.groupIDs?.filter((gid) => gid.type === AccountTypes.BUSINESS_OWNER)[0]?.id

    const businessForm = useBusinessForm()

    useEffect(() => {

        let mounted = true

        if (mounted) {
            loadServices(GROUP_ID).then((_res) => {
                const res = JSON.parse(res)
                console.log(res)
                if (!res.message) {
                    // storeBusiness(res)
                    setImpression(res.impressions.view | 0)
                    setClicks(res.impressions.click | 0)
                    setPhone(res.impressions.phone | 0)
                    setMapClicks(res.impressions.whatsapp | 0)
                    setInsta(res.impressions.insta | 0)
                } else {
                    // storeBusiness(null)
                }
            })
            // .finally(async () => {
            //     await load_business(GROUP_ID).then(async (resp) => {
            //         const result = JSON.parse(resp)
            //         result['images'] = result.images.map(i => i.imageURL)
            //         console.log(result)
            //         const datum = {
            //             review: result.review,
            //             category: result.category,
            //             facilities: result.facilities,
            //             phone: result.phone,
            //             openHours: result.openHours,
            //             website: result.website,
            //             location: result.location,
            //             description: result.description,
            //             images: result.images,
            //             title: result.title,
            //             last_saved: new Date(),
            //         }
            //         if (result.plugin) {
            //             datum['plugin'] = result.plugin
            //         } else {
            //             datum['plugin'] = []
            //         }

            //         await storeBusiness(datum)
            //     }).then(() => console.log(">>", business))
            // })
        }

        return () => {
            mounted = false
            businessForm.clearBusiness()
            console.log('BUSINESS CLEARED')
        }
    }, [])



    useEffect(() => {

    }, [impression, business])


    const createSerciceAction = () => {
        setOpenModal(true)
    }

    const editImages = () => {
        setOpenModal(true)
    }



    return GROUP_ID ? (
        <DashboardWrapper title="Business">
            <div className='flex w-auto xl:min-w-[65%] m-5 mx-3 space-y-5 flex-col'>
                <WelcomeCard
                    user={user_name}
                    action_text="Setup Your Account"
                    action={createSerciceAction}
                    desc="The name of the business is 'money making', roll up your sleeves, let's get to work. Customers are waiting."
                    bg="bg-blue-200"
                    image={WelcomeSVG}
                />
                {/* <div className='flex space-y-2 flex-col md:flex-row space-x-0 md:space-x-4 md:space-y-0'>
                    <InfoCard link="#" caption="Pending Jobs" num={0} desc="these are jobs you haven't accepted yet" primary_color="bg-darkblue_1" secondary_color="bg-darkblue_2" />
                    <InfoCard link="#" caption="Jobs In Progress" num={0} desc="jobs you are currently working on" primary_color="bg-lightblue_1" secondary_color="bg-lightblue_2" />
                    <InfoCard link="#" caption="Closed Jobs" num={0} desc="cancelled or completed jobs" primary_color="bg-lightred_1" secondary_color="bg-lightred_2" />
                </div> */}
                {/* <div onClick={() => setOpenModal(true)} className='p-2 px-4 cursor-pointer rounded bg-primary-500 text-white'>Services</div> */}
                <div className='h-[1px] w-auto m-2 bg-gray-300' />
                {/* <div className='sub-title max-h-10'>Performance & Metrics</div> */}
                <div className='w-full h-fit'>
                    <div className=' max-h-[50vh]] my-10'>
                        <ShowGraph GROUP_ID={GROUP_ID} setClicks={setClicks} setViews={setImpression} setPhone={setPhone} setMapViews={setMapClicks} />
                    </div>

                    <SocialsViewCard title={{ head: 'Analytics Summary', sub: 'your business account performance' }} showList={false} list={intervals}  >
                        <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6'>
                            <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                                <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center font-medium text-gray-600'>
                                    {abbreviateNumber(impression)}
                                </div>
                                <div className='flex flex-col items-start justify-center text-textcolor'>
                                    <div className='font-bold text-sm xl:text-normal flex space-x-2 items-center'>
                                        <ChartBarIcon className='guide-icon w-5 h-5 text-textcolor' />
                                        <span>Impressions</span>
                                    </div>
                                    <span className='text-xs xl:text-small'>The estimated number of times your service was displayed onscreen.</span>
                                </div>
                            </div>
                            <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                                <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center'>
                                    {abbreviateNumber(clicks)}
                                </div>
                                <div className='flex flex-col items-start justify-center text-textcolor'>
                                    <span className='font-bold text-sm xl:text-normal flex space-x-2 items-center'>
                                        <CursorClickIcon className='guide-icon w-5 h-5 text-textcolor' />
                                        <span>Vists</span>
                                    </span>
                                    <span className='text-xs xl:text-small text-left'>The estimated number of times your business page was visited.</span>
                                </div>
                            </div>
                        </div>
                    </SocialsViewCard>
                </div>
                <div>
                    <SocialsViewCard showList={false} title={{ head: 'User Engagement', sub: 'See how users engaged your business page.' }} list={intervals} >
                        <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4'>
                            <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                                <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center'>
                                    {abbreviateNumber(mapClicks)}
                                </div>
                                <div className='flex flex-col items-start justify-center text-textcolor'>
                                    <span className='font-bold text-sm xl:text-normal flex space-x-2 items-center'>
                                        <MapIcon className='guide-icon w-4 h-4 text-textcolor' />
                                        <span>Map Views</span>
                                    </span>
                                    <span className='text-xs xl:text-small text-left'>The estimated number of times business location was opened on the map.</span>
                                </div>
                            </div>
                            <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                                <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center'>
                                    {abbreviateNumber(phone)}
                                </div>
                                <div className='flex flex-col items-start justify-center text-textcolor'>
                                    <span className='font-bold text-sm xl:text-normal flex space-x-2 items-center'>
                                        <PhoneIcon className='guide-icon w-5 h-5 text-textcolor' />
                                        <span>Phone</span>
                                    </span>
                                    <span className='text-xs xl:text-small text-left'>The estimated number of times your phone contact was clicked.</span>
                                </div>
                            </div>
                            {/* <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                                <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center'>
                                    {abbreviateNumber(insta)}
                                </div>
                                <div className='flex flex-col items-start justify-center text-textcolor'>
                                    <span className='font-bold text-sm xl:text-normal flex space-x-2 items-center'>
                                        <PhoneIcon className='guide-icon w-5 h-5 text-textcolor' />
                                        <span>Instagram</span>
                                    </span>
                                    <span className='text-xs xl:text-small text-left'>The estimated number of instagram contact clicks.</span>
                                </div>
                            </div> */}
                        </div>
                    </SocialsViewCard>
                </div>
                <div className='h-0 lg:h-[100px] w-auto m-2' />
            </div>
            <div className='w-full lg:max-w-[35%]'>
                <div className='flex bg-secondary-white mx-3 rounded-xl'>
                    <DashboardReviewCard group={GROUP_ID} />
                </div>
                <div>
                    <PhotosGridCard doAction={editImages} imgs={getImages(business?.images?.slice(0, 6))} />
                    <div className="w-full h-[200px] flex lg:hidden" />
                </div>
            </div>

            <DashboardSliderOver bg={'#F0f0f0'} open={openModal} setOpen={setOpenModal} width={7}>
                <GenericDashboardFormWrapper>
                    <AddBusiness />
                </GenericDashboardFormWrapper>
            </DashboardSliderOver>
        </DashboardWrapper>
    ) :
        (
            <DashboardWrapper title="Services">
                <div className='w-full flex flex-col space-y-4 justify-center items-center py-10'>
                    <div>You need a BUSINESS ACCOUNT.</div>
                    <div className='p-2 bg-primary-500 text-white rounded-lg cursor-pointer'>See how to create a business account</div>
                </div>
            </DashboardWrapper>
        )
}

const mapStateToProps = (state) => {
    return {
        business: state.business.payload,
        current_user: state.user?.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        storeBusiness: (data) => dispatch(store_business(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Business)