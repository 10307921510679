import React from 'react'
import { useNavigate, useParams } from 'react-router'

const AppRedirect = () => {

    const { resource, id } = useParams()
    const nav = useNavigate()

    if (resource && id) {
        const url = `guideapp://${resource}/${id}`
        window.location.assign(url)

        setTimeout(() => {
            window.location.assign(`https://hub.guideutility.co/${id}`)
        }, 2500)
    } else {
        window.location.assign("https://bit.ly/3rtM5WZ")
    }

    return (
        <div className="w-full text-left">
            Please wait ...
        </div>
    )
}

export default AppRedirect