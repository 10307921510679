import React from "react";
import NavBar from "../../../Components/NavBar";

const PrivacyPolicy = () => {
  return (
    <div className="w-full h-full space-y-2 text-left overflow-y-auto  text-gray-400 text-lg font-sans scrollbar pt-28">
      <NavBar />
      <div className="w-full px-10">
        <h1 className="text-4xl text-primary-500 font-bold py-10 underline">
          Privacy Policy for Guide
        </h1>

        <div className="my-10">
          <p>
            At Guide-Utility, accessible from www.guideutility.co, one of our
            main priorities is the privacy of our visitors. This Privacy Policy
            document contains types of information that is collected and
            recorded by Guide-Utility and how we use it.
          </p>

          <p>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </p>

          <p>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in Guide-Utility. This policy is not
            applicable to any information collected offline or via channels
            other than this website. Our Privacy Policy was created with the
            help of the{" "}
            <a href="https://www.privacypolicygenerator.info/">
              Free Privacy Policy Generator
            </a>
            .
          </p>
        </div>

        <div className="my-10">
          <h2 className="text-2xl font-medium text-gray-700 py-5">Consent</h2>

          <p>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </p>
        </div>
        <div className="my-10">
          <h2 className="text-2xl font-medium text-gray-700 py-5">
            Information we collect
          </h2>

          <p>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </p>
          <p>
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </p>
          <p>
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </p>
        </div>
        <div className="my-10">
          <h2 className="text-2xl font-medium text-gray-700 py-5">
            How we use your information
          </h2>

          <p>
            We use the information we collect in various ways, including to:
          </p>

          <ul>
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our website</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes
            </li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
          </ul>
        </div>

        <div className="my-10">
          <h2 className="text-2xl font-medium text-gray-700 py-5">Log Files</h2>

          <p>
            Guide-Utility follows a standard procedure of using log files. These
            files log visitors when they visit websites. All hosting companies
            do this and a part of hosting services' analytics. The information
            collected by log files include internet protocol (IP) addresses,
            browser type, Internet Service Provider (ISP), date and time stamp,
            referring/exit pages, and possibly the number of clicks. These are
            not linked to any information that is personally identifiable. The
            purpose of the information is for analyzing trends, administering
            the site, tracking users' movement on the website, and gathering
            demographic information.
          </p>

          <h2 className="text-2xl font-medium text-gray-700 py-5">
            Cookies and Web Beacons
          </h2>

          <p>
            Like any other website, Guide-Utility uses 'cookies'. These cookies
            are used to store information including visitors' preferences, and
            the pages on the website that the visitor accessed or visited. The
            information is used to optimize the users' experience by customizing
            our web page content based on visitors' browser type and/or other
            information.
          </p>
        </div>

        <div className="my-10">
          <h2 className="text-2xl font-medium text-gray-700 py-5">
            Advertising Partners Privacy Policies
          </h2>

          <p>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of Guide-Utility.
          </p>

          <p>
            Third-party ad servers or ad networks uses technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on Guide-Utility,
            which are sent directly to users' browser. They automatically
            receive your IP address when this occurs. These technologies are
            used to measure the effectiveness of their advertising campaigns
            and/or to personalize the advertising content that you see on
            websites that you visit.
          </p>

          <p>
            Note that Guide-Utility has no access to or control over these
            cookies that are used by third-party advertisers.
          </p>
        </div>

        <div className="my-10">
          <h2 className="text-2xl font-medium text-gray-700 py-5">
            Third Party Privacy Policies
          </h2>

          <p>
            Guide-Utility's Privacy Policy does not apply to other advertisers
            or websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options.{" "}
          </p>

          <p>
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites.
          </p>
        </div>
        {/* <div className="my-10">
          <h2 className="text-2xl font-medium text-gray-700 py-5">
            CCPA Privacy Rights (Do Not Sell My Personal Information)
          </h2>

          <p>
            Under the CCPA, among other rights, California consumers have the
            right to:
          </p>
          <p>
            Request that a business that collects a consumer's personal data
            disclose the categories and specific pieces of personal data that a
            business has collected about consumers.
          </p>
          <p>
            Request that a business delete any personal data about the consumer
            that a business has collected.
          </p>
          <p>
            Request that a business that sells a consumer's personal data, not
            sell the consumer's personal data.
          </p>
          <p>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>
        </div> */}
        <div className="my-10">
          <h2 className="text-2xl font-medium text-gray-700 py-5">
            Your rights to your data.
          </h2>
          <ol>
            <li>
              <span className="text-xl mb-10 font-bold">
                Requests to Access, Rectify or Erase.
              </span>
              <br/>
              <br/>
              <ol>
                <li>
                  <span className="font-bold">Access Request</span>
                  <p>
                    As permitted under law and applicable data privacy
                    regulations, you have the right to ask us whether we hold
                    any Personal Data relating to you and, if we do, to be
                    provided with a copy of that Personal Data in electronic
                    form, unless you want to receive it in another way (for
                    example, a paper copy). In addition, you can ask us for
                    information on how we use your Personal Data, who we share
                    it with, how long we keep it, where it is stored, and other
                    information to help you understand how we use it.
                  </p>
                </li>
                <br />
                <li>
                  <span className="font-bold">Rectification Request</span>
                  <p>
                    You have the right to ask us to correct your Personal Data
                    (including by means of providing a supplementary statement)
                    if it is inaccurate, or update outdated or incomplete
                    Personal Data without undue delay. Where we cannot correct
                    the Personal Data, we include a note on our files regarding
                    your request to correct your Personal Data.
                  </p>
                </li>
                <br />
                <li>
                  <span className="font-bold">Erasure Request</span>
                  <p>
                    You have the right to ask us to erase your Personal Data if:
                  </p>
                  <ol className=" list-decimal">
                    <li>
                      Your Personal Data are no longer necessary for the
                      purpose(s) they were collected for.
                    </li>
                    <li>Your Personal Data have been unlawfully processed.</li>
                    <li>
                      Your Personal Data must be erased to comply with a
                      regulation.
                    </li>
                    <li>
                      You withdraw your consent for the processing of the
                      Personal Data (and if this is the only basis on which we
                      are processing your Personal Data).
                    </li>
                    <li>
                      You object to the possession, provided there are no
                      overriding legitimate grounds for continued processing, or
                    </li>
                    <li>
                      You object to processing for direct marketing purposes.
                    </li>
                  </ol>
                </li>
                <br />
              </ol>
              <br />

              <p>
                If an erasure request is received, we will also take reasonable
                steps to inform other data controllers processing the data so
                they can seek to erase links to or copies of your Personal Data.
                We may refuse to act on your request to erase your Personal Data
                if the processing of your Personal Data is necessary:
                <ol className="list-decimal">
                  <li>
                    To exercise our right of freedom of expression and
                    information.
                  </li>
                  <li>To comply with the NDPR and relevant Nigerian laws.</li>
                  <li>
                    For the performance of a task carried out in the public
                    interest or to exercise some official authority vested in
                    us.
                  </li>
                  <li>To establish, exercise or defend legal claims.</li>
                </ol>
              </p>
              <br />
              <p>
                In these cases, we can restrict the processing instead of
                erasing your Personal Data if requested to do so by you.
              </p>
            </li>
            <br />
            <li>
              <span className="font-bold">Requests to Object:</span> You have
              the right to object at any time to the processing of your Personal
              Data if we process it based on our legitimate interests. This
              includes any so-called “profiling”. Our privacy notice informs you
              when we rely on legitimate interests to process your Personal
              Data. In these cases, we will stop processing your Personal Data
              unless we can demonstrate compelling legitimate reasons for
              continuing the processing. We may reject your request if the
              processing of your Personal Data is needed to establish, exercise
              or defend legal claims. You have the right to object at any time
              if we process your Personal Data for direct marketing purposes.
              You may also object at any time to profiling supporting our direct
              marketing. In such cases, we will stop processing your Personal
              Data when we receive your objection.
            </li>
            <br />
            <li>
              <span className="font-bold">Requests to Restrict:</span> You have
              the right to ask us to restrict the processing of your Personal
              Data if:
              <ul>
                <li>
                  You contest the accuracy of your Personal Data and we are in
                  the process of verifying the Personal Data we hold.
                </li>
                <li>
                  The processing is unlawful and you do not want us to erase
                  your Personal Data.
                </li>
                <li>
                  We no longer need your Personal Data for the original
                  purpose(s) of processing, but you need them to establish,
                  exercise or defend legal claims and you do not want us to
                  delete the Personal Data as a result, or
                </li>
                <li>
                  You have objected to processing carried out because of our
                  legitimate interests while we verify if our legitimate grounds
                  override yours.
                </li>
              </ul>
              <br />
              <p>
                If processing is restricted, we may process your Personal Data
                (excepting for storage purposes), only:
                <ul className=" list-decimal">
                  <li>If you have given us your consent.</li>
                  <li>
                    For establishing, exercising or defending legal claims.
                  </li>
                  <li>
                    For protecting the rights of another natural or legal
                    person, or
                  </li>
                  <li>
                    For reasons of important public interest as defined under
                    the NDPR and relevant Nigerian laws.
                  </li>
                </ul>
              </p>
              <p>
                Once processing is restricted following your request, we will
                inform you before we lift the restriction.
              </p>
            </li>
            <br />
            <li>
              <span className="font-bold">Requests for Portability:</span> If
              our processing is performed by computer and is necessary to fulfil
              a contract with you, or is based on your consent, you have the
              right to:
              <ul>
                <li>
                  Receive any Personal Data you have provided to us in a
                  structured, commonly used and machine-readable electronic
                  format.
                </li>
                <li>
                  Send your Personal Data to another organization or have us do
                  so for you if it is technically feasible for us to do so.
                </li>
              </ul>
              <p>
                If your request relates to a set of Personal Data that also
                concerns other individuals, we may restrict the transfer to only
                the portion relevant to you, unless you establish that you have
                also gotten their consent.
              </p>
              <p>
                Even if you request the portability of your Personal Data, you
                retain your right to also request their erasure.
              </p>
            </li>
            <br />
            <li>
              <span className="font-bold">
                Requests to Object to Automated Decisions:
              </span>{" "}
              Generally, you have the right to object to any legal decision
              concerning you or which otherwise significantly affects you if
              this is based solely on the automated processing of your Personal
              Data. This includes automated decisions based on profiling. In
              such instance, you may undertake a legal process to prevent or
              advance your rights.
              <p>
                Otherwise, we may refuse your request regarding such automated
                decisions where:
                <ul className=" list-decimal">
                  <li>
                    Necessary to enter into a contract with you, or for the
                    performance of your contract with us.
                  </li>
                  <li>Permitted by regulations, or</li>
                  <li>Based on your explicit consent.</li>
                </ul>
              </p>
              <p>
                Our actions on the automated processing involving your sensitive
                Personal Data (where you have given your explicit consent or the
                processing is necessary for reasons of substantial public
                interest), are in complete compliance with the NDPR and relevant
                laws.
              </p>
            </li>
          </ol>
        </div>
        {/* <div className="my-10">
          <h2 className="text-2xl font-medium text-gray-700 py-5">
            GDPR Data Protection Rights
          </h2>

          <p>
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following:
          </p>
          <p>
            The right to access – You have the right to request copies of your
            personal data. We may charge you a small fee for this service.
          </p>
          <p>
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request that we complete the information you believe is
            incomplete.
          </p>
          <p>
            The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions.
          </p>
          <p>
            The right to restrict processing – You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.
          </p>
          <p>
            The right to object to processing – You have the right to object to
            our processing of your personal data, under certain conditions.
          </p>
          <p>
            The right to data portability – You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
          </p>
          <p>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>
        </div> */}
        <div className="my-10">
          <h2 className="text-2xl font-medium text-gray-700 py-5">
            Children's Information
          </h2>

          <p>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </p>

          <p>
            Guide-Utility does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
