import { CashIcon, CurrencyPoundIcon, GlobeIcon, HashtagIcon, HomeIcon, MapIcon, PlusIcon, SortDescendingIcon, StarIcon, StatusOnlineIcon, TruckIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import ArmsChairIcon from '../../../../assets/ArmsChairIcon'
import BedIcon from '../../../../assets/BedIcon'
import { useSuzi } from '../../../../Components/Assistant'
import { ThumbSlider } from '../../../ApartmentsView/ApartmentFilter/FilterComponents/ThumSlider'
import { TwoThumbsRangeSlider } from '../../../ApartmentsView/ApartmentFilter/FilterComponents/TwoThumbSlider'
import BlockSelector from '../../../ApartmentsView/ApartmentFilter/FilterComponents/BlockSelectors'
import { sterilizeInt } from '../../../ApartmentsView/functions'



const currencyList = [
    {
        title: "$",
        name: "usd"
    },
    {
        title: "£",
        name: "pound"
    },
    {
        title: "₺",
        name: "lira"
    },
    {
        title: "€",
        name: "euro"
    }
]


const vehiclesList = [
    "Any",
    {
        title: "Minivan / SUV",
        name: "suv"
    },
    {
        title: "Car",
        name: "car"
    },
    {
        title: "Truck",
        name: "truck"
    }
]



const ServiceListFilter = ({ doFilter, location, service, setFilter, full = false, setLoading, setOpenFilter }) => {
    const suzi = useSuzi()

    const [currencySelector, setCurrencySelector] = useState([currencyList[2]])
    const [ratingSelector, setRatingSelector] = useState(["Any"])
    const [sortBySelector, setSortBySelector] = useState(["Distance"])
    const [vehicleSelector, setVehicleSelector] = useState(['Any'])
    const [radius, setRadius] = useState([5])
    const [priceRange, setPriceRange] = useState([50, 350])



    const resetFilters = () => {
        setVehicleSelector(['Any'])
        setSortBySelector(["Distance"])
        setRatingSelector(['Any'])
        setRadius([5])
        setPriceRange([50, 350])
    }

    const handleOnFilter = () => {
        setLoading(true)
        if (radius[0] <= 0) {
            suzi.addMessage({
                text: "Hey..., I can't filter items for you over a 0 meter distance now can I?, Distance must be greater than 0",
                timeout: 3000
            })
            return
        }
        const data = {
            radius: radius[0] * 1000,
            longitude: location.longitude,
            latitude: location.latitude,
            filters: {
                rating: ratingSelector,
                price: {
                    min: priceRange[0],
                    max: priceRange[1],
                    currency: currencySelector
                },
                service_type:service,
                vehicles: vehicleSelector,
                // sortBy: sortBySelector,
            }
        }


        doFilter(data).then((res) => {
            console.log(res)
            setFilter(res?.v)
            setOpenFilter(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div className={`fixed ${full ? 'w-auto pr-5' : 'w-[20%]'}  p-5 pr-0 border-2 `}>
            <div className='p-5 py-2 text-lg font-medium text-gray-500'>Filter Your Results</div>
            <div className='h-[60vh]  overflow-y-auto scrollbar-sm'>

                <BlockSelector
                    icon={<SortDescendingIcon className="guide-icon text-black stroke-1" />}
                    items={["Distance", "Price", "Rating"]}
                    title="Sort By"
                    multiSelect={true}
                    selected={sortBySelector}
                    setSelector={setSortBySelector}

                />

                <BlockSelector
                    icon={<StarIcon className="guide-icon text-black stroke-1" />}
                    items={["Any", 1, 2, 3, 4, 5]}
                    title="Ratings"
                    multiSelect={true}
                    selected={ratingSelector}
                    setSelector={setRatingSelector}

                />

                <div className='w-full p-5 py-8'>
                    <div className='w-full flex items-center space-x-4 pb-6'>
                        <MapIcon className="guide-icon stroke-1" />
                        <div className=''>Distance / Km</div>
                    </div>
                    <ThumbSlider values={radius} setValues={setRadius} />
                </div>

                <div className='w-full p-5 py-8'>
                    <div className='w-full flex items-center space-x-4 pb-6'>
                        <CashIcon className="guide-icon stroke-1" />
                        <div className='flex'>Price <div className='text-sm px-2 text-gray-400'>(£/month)</div></div>
                    </div>
                    <TwoThumbsRangeSlider values={priceRange} setValues={setPriceRange} MIN={5} MAX={1000} />
                </div>

                {/* <BlockSelector
                    icon={<TruckIcon className="guide-icon text-black stroke-1" />}
                    items={["Any"]}
                    title="Service"
                    multiSelect={true}
                    selected={vehicleSelector}
                    setSelector={setVehicleSelector}

                /> */}


                <BlockSelector
                    icon={<CurrencyPoundIcon className="guide-icon text-black stroke-1" />}
                    items={currencyList}
                    title="Currency"
                    multiSelect={true}
                    selected={currencySelector}
                    setSelector={setCurrencySelector}

                />



                <BlockSelector
                    icon={<TruckIcon className="guide-icon text-black stroke-1" />}
                    items={vehiclesList}
                    title="Vehicles"
                    multiSelect={true}
                    selected={vehicleSelector}
                    setSelector={setVehicleSelector}

                />







            </div>
            <div className='flex items-center'>
                <div onClick={() => handleOnFilter()} className='cursor-pointer w-auto text-center flex-1 ml-0 mx-3 py-3 my-4 font-bold text-white rounded-md bg-primary-500'>
                    <div>Search</div>
                </div>
                <div onClick={() => resetFilters()} className='cursor-pointer w-auto text-center flex-1 ml-0 mx-3 py-3 my-4 font-bold text-white rounded-md bg-red-500'>
                    <div>Reset Filter</div>
                </div>
            </div>
        </div>
    )
}


export default ServiceListFilter