import React from 'react'
import { ArrowCircleRightIcon } from '@heroicons/react/outline'
import { useSuzi } from '../../../../../../Components/Assistant';


const WelcomeCard = (props) => {

    const suzi = useSuzi()

    const { user, action, action_text, desc, image, bg="bg-secondary-pink" } = props
    return (
        <div className={`rounded-xl p-10 ${bg} relative`}>
            <div className='flex w-full flex-col xl:w-[60%] items-start text-left space-y-4'>
                <span className='text-xl text-textprimary'>Hi <span className='font-bold'>{user}</span></span>
                <span className='text-textcolor lg:max-w-xl w-full'>{desc}</span>
                <div onClick={() => action()} className=' cursor-pointer justify-center w-full xl:w-fit flex space-x-2 items-center p-3 m-2 ml-0 bg-primary-500 rounded-xl'>
                    <span className='text-very_light_textcolor font-bold'>{action_text}</span>
                    <ArrowCircleRightIcon className='w-6 h-6 text-very_light_textcolor' />
                </div>
            </div>
            <div className='absolute -top-16 right-0 w-1/2 hidden xl:flex'>
                <img src={image} className="w-full h-full " />
            </div>
        </div>
    )
}

export default WelcomeCard