import { DotsCircleHorizontalIcon } from '@heroicons/react/outline'
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import React from 'react'

const JobShelf = ({ children, title }) => {
    return (
        <div className=' max-w-md w-full h-full space-y-3'>
            <div className='flex justify-between items-center px-4'>
                <div className='text-lg font-medium text-gray-400'>
                    {title}
                </div>
                <div>
                    <DotsHorizontalIcon className='guide-icon text-gray-400' />
                </div>
            </div>
            <div className='w-full max-h-[75vh] h-full bg-gray-300 rounded-3xl p-4 space-y-4 overflow-y-auto scrollbar-sm'>
                {children}
            </div>
        </div>
    )
}

export default JobShelf