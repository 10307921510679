import { CLEAR_BLOG, SAVE_BLOG } from "../../actionTypes/blogActionTypes"

export const save_blog_draft = (data) => {
    return (dispatch) => {
        dispatch({
            type: SAVE_BLOG,
            payload: data,
            error: null
        })
    }
}

export const clear_blog_draft = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_BLOG,
            payload: null,
            error: null
        })
    }
}