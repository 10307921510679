import moment from 'moment'
import React from 'react'
import { numberWithCommas } from '../../../../../../functions'
import { UpdatesStatusTypes } from '../data'

const Jobcard = ({ selectJob, job, getJobItem, swap = null }) => {


    const selectJobItem = () => {
        getJobItem(job)
        selectJob(true)
    }

    const today = new Date()

    const startTime = moment(job.task_duration.endTime)
    const currentTime = moment(today.toISOString())
    const daysLeft = startTime.diff(currentTime, 'days')
    const hoursLeft = startTime.diff(currentTime, 'hours')

    const moneyLength = 100/(String(job.task_info.payment.amount).length)
    const moneySize = moneyLength <= 15 ? 15 : moneyLength

    const getStatus =() => {
        switch(job.status){
            case UpdatesStatusTypes.COMPLETED:
                return {
                    status:"completed",
                    color: 'bg-green-500'
                }
            case UpdatesStatusTypes.CANCELED:
                return {
                    status:"cancelled",
                    color: 'bg-red-500'
                }
            case UpdatesStatusTypes.PENDING:
                return {
                    status:"pending...",
                    color: 'bg-orange-500'
                }
            default:
                return null

        }
    }

    const current_status = getStatus()

    return (
        <div onClick={() => selectJobItem()} className='ease_transition divide-y-2 bg-white rounded-2xl p-4 cursor-pointer hover:scale-[1.01]'>
            <div className='flex justify-between items-center py-2'>
                <div className='flex items-center space-x-5'>
                    <img src={job.customer.photoURL}
                        className='w-14 aspect-square rounded-full object-cover' />
                    <div className='flex flex-col text-left'>
                        <div className='font-bold text-gray-500 text-base'>{job.customer.display_name}</div>
                        <div className='font-light text-gray-500 text-sm'>client</div>
                    </div>
                </div>
                <div className=' font-medium text-gray-500' style={{ fontSize: moneySize }}>
                    {job.task_info.payment.currency.symbol} {numberWithCommas(parseFloat(job.task_info.payment.amount).toFixed(2))}
                </div>
            </div>
            <div className='flex flex-col w-full space-y-4'>
                <div className='text-left space-y-2 text-gray-400 py-2'>
                    <div className='flex items-center space-x-4'>
                        {
                            job.services.map((item, indx) => {
                                return <div key={indx} className='text-sm text-white bg-primary-500 rounded-full py-1 px-2 font-medium'>{item.id.split("_").join(" ")}</div>
                            })
                        }
                    </div>
                    <div className=''>{job.task_info?.specifications?.length > 100? job.task_info?.specifications?.slice(0,100)+'...' : job.task_info?.specifications }</div>
                </div>
                <div className='flex items-center justify-start text-xs'>
                    <div className={`py-1 px-2 ${current_status? current_status.color : 'bg-red-400'} text-white rounded-full`}>{ current_status? current_status.status : daysLeft > 0? daysLeft+" days left" : hoursLeft+" hours left" } </div>
                </div>
            </div>
        </div>
    )
}

export default Jobcard