import { EyeIcon, EyeOffIcon, LinkIcon } from '@heroicons/react/outline';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import DashboardRichTextField from '../DashboardRichTextField';


const DashboardBlogFormField = (props) => {

    const [showpassword, setShowPassword] = React.useState(false)


    let { name, type = "text", disabled = false, setText, text, placeholder = "type your text here...", multiline = false, password = false, rows = 3, maxLength = 200 } = props;
    placeholder = password ? '********' : placeholder


    return (
        <div className={` ${disabled && 'bg-gray-100'} w-auto py-2 px-4 m-2 my-5 border-2 border-gray-300 rounded-xl flex bg-white flex-col text-sm text-textcolor`}>
            <div className='flex justify-between items-center text-textcolor'>
                <span className='font-medium text-md '>{name}</span>
                <span onClick={() => setShowPassword(!showpassword)} className='w-5 h-5 flex items-center justify-center '>
                    {password && (!showpassword ? <EyeIcon className='w-[90%] h-[90%]' /> : <EyeOffIcon className='w-[90%] h-[90%]' />)}
                </span>
            </div>

            <span className='w-full'>
                <DashboardRichTextField text={text} setText={setText} />
            </span>

        </div>
    )
}

export default DashboardBlogFormField