import React from 'react'
import SCardSection from '../SCardSection';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';



const load_stripe = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);





/* 
    Handles all strip related Functions to be
    used throughout our application
*/

const init = {

}



const SPaymentProcessor = ({ children, reference, callback, currency, total }) => {

    return (
        <div className='w-full h-full flex justify-center items-center'>
            <Elements stripe={load_stripe}>
                {children}
                <SCardSection amount={total} currency={currency} reference={reference} callback={callback} load_stripe={loadStripe} />
            </Elements>
        </div>
    )
}



export default SPaymentProcessor