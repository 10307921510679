import { Transition } from "@headlessui/react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { ChatImageThumbnails } from "../ChatSenderBubble";

const ChatRecieverBubble = ({ message, setSlider }) => {
  const [show, setShow] = useState(false);
  const [time, setTime] = useState("");

  useEffect(() => {
    (async () => {
      setShow(true);
      const date = new Date(message.time);
      setTime(
        `${String(date.getHours()).padStart(2, "0")}:${String(
          date.getMinutes()
        ).padStart(2, "0")} ${date.getHours() > 12 ? "PM" : "AM"}`
      );
    })();
  }, []);

  return (
    <>
      <Transition
        show={show}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full flex flex-col items-end space-x-4" >
          <div className="w-full py-2 px-3 flex items-start justify-end space-x-4">
            <div className="py-2 text-sm lowercase">{time}</div>
            <div className="bg-primary-300 max-w-[60%] shadow-md rounded-2xl rounded-tr-none text-right">
              <div className="p-3">{message.message}</div>
            </div>
          </div>
          {message.images && <ChatImageThumbnails setSlider={setSlider} images={message.images} />}
        </div>
      </Transition>
    </>
  );
};

export default ChatRecieverBubble;
