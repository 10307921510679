import { XIcon } from '@heroicons/react/outline'
import React from 'react'
import Modal from '../Modal'
import { useAlert } from '../UtilityComponents/ErrorContext'

const ShareModal = ({ type, message, url, callback, open, setOpen }) => {

    const alert = useAlert()

    const onCopyLink = () => {
        navigator.clipboard.writeText(url).then(() => {
            alert.setalert({ type: 'success', title: 'Done!', body: 'Copied!' })
        }).then(async () => {
           await callback()
        }).finally(() => setOpen(!open))
    }

    const getShareLink = (channel) => {
        switch (channel) {
            case 'facebook':
                return `https://www.facebook.com/sharer/sharer.php?u=${url}&t=${message}`
            case 'twitter':
                return `https://twitter.com/intent/tweet?text=${message} here ${url}`
            case 'whatsapp':
                return `https://wa.me/?text=${message} here ${url}`
            case 'telegram':
                return `https://telegram.me/share/url?url=${url}&text=${message}`
            default:
                return null
        }
    }

    const handleShareSocial = async (channel) => {
        const share_link = getShareLink(channel)
        window.open(share_link, '', `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600`)
        await callback()
    }

    return (
        <Modal open={open} setOpen={setOpen} width={6} showBackground={false}>
            <div className='max-w-md bg-white shadow-2xl rounded-3xl ring-1 ring-black/5 p-5 px-0 md:px-5 space-y-4 divide-y'>
                <div className='flex items-center justify-between px-5 py-2'>
                    <div className='text-xl font-bold'>Share {type}</div>
                    <div onClick={() => setOpen(!open)} className='w-5 h-5 flex justify-center items-center cursor-pointer hover:scale-150 ease_transition rounded-full bg-gray-300'>
                        <XIcon className='w-3 h-3' />
                    </div>
                </div>
                <div className='w-full space-y-5 py-2 px-5'>
                    <div>Share this link via</div>
                    <div className='flex items-center space-x-3 md:space-x-5'>
                        <div onClick={() => handleShareSocial('whatsapp')} target="_blank" className='h-10 md:w-14 w-10 md:h-14 aspect-square rounded-full flex justify-center items-center border-[1px] cursor-pointer'>
                            <img src="https://cdn-icons-png.flaticon.com/512/124/124034.png?w=360" className='w-full h-full rounded-full object-center object-contain' />
                        </div>

                        <div onClick={() => handleShareSocial('facebook')} target="_blank" className='h-10 md:w-14 w-10 md:h-14 aspect-square rounded-full flex justify-center items-center border-[1px] cursor-pointer'>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/768px-Facebook_Logo_%282019%29.png" className='w-full h-full rounded-full object-center object-contain' />
                        </div>

                        <div onClick={() => handleShareSocial('twitter')} target="_blank" className='h-10 md:w-14 w-10 md:h-14 aspect-square rounded-full flex justify-center items-center border-[1px] cursor-pointer'>
                            <img src="https://cdn-icons-png.flaticon.com/512/124/124021.png" className='w-full h-full rounded-full object-center object-contain' />
                        </div>


                        <div onClick={() => handleShareSocial('telegram')} target="_blank" className='h-10 md:w-14 w-10 md:h-14 aspect-square rounded-full flex justify-center items-center border-[1px] cursor-pointer'>
                            <img src="https://www.freepnglogos.com/uploads/telegram-png/telegram-social-media-icons-12.png" className='w-full h-full rounded-full object-center object-contain' />
                        </div>
                    </div>
                    <div className='space-y-3'>
                        <div>Or copy link</div>
                        <div className='flex items-center space-x-4 border-primary-800/60 rounded-lg border-2 p-1'>
                            <div className='w-[60%] overflow-x-clip text-ellipsis'>{url}</div>
                            <div onClick={onCopyLink} className='py-2 px-4 flex-1 cursor-pointer bg-primary-500 text-white rounded-md text-center font-semibold'>Copy</div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ShareModal