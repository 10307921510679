import { LightBulbIcon } from '@heroicons/react/outline'
import React from 'react'

const TipsCard = () => {
  return (
    <div className='shadow-md max-w-sm rounded-lg bg-white py-10 flex flex-col items-center my-10 px-6 space-y-3'>
        <div className='flex items-center w-full space-x-4'>
            <LightBulbIcon className='guide-icon' />
            <div className='font-semibold'>Help tenants find your apartment.</div>
        </div>
        <div>
            <ul className=' px-5'>
                <li className='list-disc'><div className='text-sm'>more precise locations help your apartment show up in more relevant searches.</div></li>
            </ul>
            
        </div>
    </div>
  )
}

export default TipsCard