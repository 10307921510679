import { del_fetch, get_fetch, post_fetch } from "../../Pages/Dashboard/Screens/Timetable/functions"
import { AUTH_SERVER_URL, CHAT_SERVER_URL, GEO_SERVER_URL } from "../../assets/url";
import { deleteImages, uploadImage } from "../../utils/functions";
import { async } from "@firebase/util";



export const saveService = async (data) => {

    return await post_fetch(`api/user/add_services`, data)
}


export const updateServiceImpressions = async (data) => {
    return await post_fetch(`api/user/services/impressions`, data)
}

export const updateGroupImpressions = async (data) => {
    return await post_fetch(`api/user/groups/impressions`, data)
}

export const fetchUserGroup = async (id) => {
    return await get_fetch(`api/user/groups?id=${id}`)
}

export const deleteService = async (id) => {
    return await del_fetch(`api/user/services/${id}`)
}

export const loadServices = async (gid) => {
    const services = await get_fetch(`api/user/services/${gid}`)
    return services
}


export const loadReviews = async (data) => {
    const { gid, size = null, from = null, with_meta = false } = data
    let url = `api/user/groups/reviews?gid=${gid}`
    if (size != null) url += `&count=${size}`
    if (from != null) url += `&from=${from}`
    if (with_meta != null) url += `&with_meta=${with_meta}`
    return await get_fetch(url)
}

export const loadUserReview = async (id) => {
    return await get_fetch(`/api/user/groups/user_reviews?id=${id}`)
}

export const loadReviewsStars = async (data) => {
    const { gid } = data
    let url = `api/user/groups/reviews_stars?gid=${gid}`
    return await get_fetch(url)
}

export const loadAllServices = async () => {
    const services = await get_fetch(`api/user/all_services`)
    return services
}

export const getCoordHash = (data) => {
    return new Promise(async (resolve, reject) => {
        return await fetch(`${GEO_SERVER_URL}/get_hash`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data
            })
        }).then(async response => {
            const res = await response.json()
            resolve(res)
        }).catch(err => reject(err))
    })
}

export const loadSkillsets = async () => {
    return await get_fetch('api/user/skillset')
}

export const save_thumbnail = async (form) => {
    return await post_fetch(`image/resize_image`, form, CHAT_SERVER_URL, { data_type: 'form', content_type: null, credentials: 'omit' })
}

export const uploadServiceImages = async (images, path = "services") => {
    console.log('IMAGESS', images)
    if (images?.length > 0) {
        const tmp = await images.map((image) => {
            console.log(image)
            if (image.file) {
                console.log('FILE NAME => ', image.file)
                return uploadImage(image.file, `${path}/${image.file.name}`).then((src) => {
                    return src
                })
            } else {
                return image
            }

        })

        return Promise.all(tmp)
    } else {
        return images
    }
}


export const deleteServiceImages = async (images) => {
    try{
        if (images?.length > 0) {
            const tmp = await images.map((image) => {
                if (!image.src) {
                    return deleteImages(image)
                }
    
            })
    
            return Promise.all(tmp)
        }
    }catch(e){
        console.error('ERROR -< IMAGES => ', e)
    }
}

export const getServiceImageUrls = (images) => {
    return images.forEach(image => {
        return { src: image }
    })
}

export const post_service_ratings = async (data) => {
    return await post_fetch('api/user/services/ratings', data)
}

export function abbreviateNumber(value) {
    var newValue = value;
    if (value >= 1000) {
        var suffixes = ["", "k", "m", "b", "t"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = '';
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
            var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
}

export const loadServicesById = async (data) => {
    return await post_fetch('api/user/services/get_nearby', data)
}


export const create_service_order = async (data) => {
    return await post_fetch('api/user/task', data)
}

export const place_service_order = async (data) => {
    return await post_fetch('api/user/create_task_order', data)
}

export const get_service_orders = async (uid) => {
    return await get_fetch(`api/user/task?uid=${uid}`)
}

export const update_service_order_status = async (data) => {
    return await post_fetch('api/user/update_task', data)
}

export const load_banks = async () => {
    return await get_fetch('api/payment/banks')
}


export const add_wallet = async (data) => {
    return await post_fetch('api/payment/banks', data)
}

export const add_transfer_recipient = async (data) => {
    return await post_fetch('api/transfer/add_recipient', data)
}

export const make_transfer = async (data) => {
    return await post_fetch('api/transfer', data)
}


export const update_tranx_history = async (data) => {
    return await post_fetch('api/transaction/history', data)
}

export const get_tranx_history = async (limit, uid) => {
    return await get_fetch(`api/transaction/history?limit=${limit}&uid=${uid}`)
}
