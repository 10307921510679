import { XIcon } from '@heroicons/react/outline'
import React from 'react'
import { useNavigate } from 'react-router'


const AuthTemplate = ({ children, title, sub_title, vector_img, action }) => {

    const navigate = useNavigate()

    const handleBack = () => {
        try {
            action()
        } catch (e) {
        } finally {
            navigate('/')
        }
    }

    return (
        <div className="w-full h-full inset-0 bg-white flex flex-row">
            <div onClick={() => handleBack()} className='absolute top-10 left-10 cursor-pointer'>
                <XIcon className='guide-icon text-gray-400 h-10 w-20 stroke-1' />
            </div>
            <div className="w-full lg:w-[70%] h-full flex justify-center items-center">
                <div className='w-[70%] h-auto'>
                    {children}
                </div>
            </div>
            <div className="hidden lg:flex  lg:flex-1 bg-primary-500 h-full flex-col justify-center items-center">
                <div className="max-w-[70%] text-left space-y-4">
                    <div className="text-white font-bold text-4xl">{title}</div>
                    <div className="text-white font-light text-xl">{sub_title}</div>
                </div>
                <div className="w-[70%] aspect-auto">
                    <img src={vector_img} alt="OnlineShopping illustration" className="w-full h-auto" />
                </div>
            </div>

        </div>
    )
}

export default AuthTemplate