import { STORE_MESSAGING, UPDATE_MESSAGING } from '../../actionTypes/messagingActionTypes'


// hashmap.size() returns the # of elements in the hashmap
// hashmap.get(<key>) returns the value of the element of the given key
// hashmap.has(<key>) checks to see if the hashmap contains the key that is passed as an argument
// hashmap.set(<key>, <value>) accepts 2 arguments and creates a new element to the hashmap
// hashmap.delete(<key>) deletes the key/value pair that matches the key that is passed in as an argument
// hashmap.clear() clears all elements from the hashmap

const _state = {
    type: null,
    error: null,
    payload: new Map()
};

_state.payload.set()

export const messagingReducer = (state = _state, action) => {
    switch (action.type) {
        case STORE_MESSAGING:
            return { ...state, type: action.type, payload: action.payload, error: action.error }
        case UPDATE_MESSAGING:
            return { ...state, type: action.type, payload: action.payload, error: action.error }
        default:
            return state
    }
}