import React, { useState } from 'react'
import ReactPlayer from 'react-player'

const videos = [
    {
        url: 'https://youtu.be/XkuIfo7TP5o',
        title: 'HOW TO USE THE GUIDE NEU TIMETABLE GENERATOR'
    },
    {
        url: 'https://youtu.be/u-Oo_XEJiW8',
        title: 'HOW TO USE WRITE A BLOG POST ON GUIDE'
    }
]

const VideosPage = () => {

    const [playingVideo, setPlayingVideo] = useState(videos[0])

    return (
        <div className='w-full flex flex-col'>
            <div className='w-full flex flex-col justify-center items-center bg-black'>
                <ReactPlayer
                    style={{ width: '100%' }}
                    controls={true}
                    url={playingVideo.url} />
                <div className='p-5 px-0 text-white'>{playingVideo.title}</div>
            </div>
            <VideosList setPlayingVideo={setPlayingVideo} />
        </div>
    )
}

const VideosList = ({setPlayingVideo}) => {
    return (
        <div className='w-full divide-y pt-10'>
            {
                videos.map((video, indx) => {
                    return (<div onClick={() => setPlayingVideo(video)} className='py-10 px-2 cursor-pointer hover:bg-gray-200'>{indx + 1}.  {video.title} </div>)
                })
            }

        </div>
    )
}

export default VideosPage