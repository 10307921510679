import React from 'react'
import { useNavigate } from 'react-router'

const InfoCard = (props) => {
    const navigate = useNavigate()
    const { primary_color, secondary_color, caption = "Caption of text", desc = "Description of the caption of text", num = 3, link = null } = props
    return (
        <div onClick={() => link && navigate(link)} className={`w-full cursor-pointer md:w-full ${primary_color} flex h-18 mx-auto p-4 rounded-xl`}>
            <div className={`text-very_light_textcolor font-bold ${secondary_color} aspect-square w-16 h-16 rounded-xl flex items-center justify-center`}>{num}</div>
            <div className='flex flex-col items-start justify-center px-2 text-left text-very_light_textcolor'>
                <span className='font-bold text-sm xl:text-normal'>{caption}</span>
                <span className='text-xs xl:text-small'>{desc}</span>
            </div>
        </div>
    )
}

export default InfoCard