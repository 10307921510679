import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon, ChatIcon, CurrencyEuroIcon, ExclamationCircleIcon, LightningBoltIcon, PhoneIcon, ShareIcon, TruckIcon, UserGroupIcon, ViewGridIcon } from '@heroicons/react/outline'
import { StarIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router';
import Logo from '../../../assets/logo.svg';



const ServiceProviderProfile = ({ profile, user }) => {

    const [data, setData] = useState(null)

    const navigate = useNavigate()

    useEffect(() => {
        console.log({ ...profile, user })
        setData({ ...profile, user })
    }, [])

    return data && (
        <div className='w-full h-auto md:h-full flex flex-col justify-center items-center '>
            <div className='py-5 fixed top-0  w-full max-h-20 md:max-h-32 flex items-center justify-between px-10 border-b-2 bg-white '>
                <img src={Logo} className='w-20 lg:w-32 h-20  lg:h-32' />
                <div onClick={() => navigate(-1)} className='flex space-x-2 items-center hover:underline cursor-pointer'>
                    <ArrowNarrowLeftIcon className='w-5 h-5' />
                    <div>Go Back</div>
                </div>
            </div>
            <div className=' max-w-screen-xl pt-[50px] md:pt-[150px] w-full md:h-full space-x-4 rounded-lg flex flex-col md:flex-row items-start'>
                <div className='w-full md:w-[30%] flex flex-col space-y-1 justify-center items-center  rounded-xl py-10' >

                    <div className='w-full flex flex-col items-center justify-center h-auto py-5'>
                        <img src={data.user.photo_url} alt="guide profile image" className=' ring-4 ring-primary-500/50 w-[60%] h-auto rounded-lg' />
                    </div>

                    <div className='flex flex-col  justify-center pb-5 items-center'>
                        <div className='flex flex-col items-center'>
                            <div className='text-lg font-semibold'>{data.user.display_name}</div>
                            <div className='text-xs text-gray-400'>Gonyeli, Nicosia</div>
                        </div>

                        <div className='flex mt-10 items-center space-x-3 text-sm  font-medium'>
                            <div className='flex items-center space-x-3 text-sm p-2 px-4 rounded-md text-white bg-primary-500 font-medium'>
                                <StarIcon className='guide-icon w-5 h-5' />
                                <div>Add to Favourites</div>
                            </div>
                            <div className='p-1 px-2 rounded-md text-gray-700 bg-gray-400'>
                                <ChatIcon className='guide-icon w-6 h-6' />
                            </div>
                        </div>
                        <div className='grid grid-cols-3 w-full mt-10'>
                            <div className='flex flex-col items-center justify-center'>
                                <div className='text-2xl font-medium'>5</div>
                                <div className='text-xs text-gray-500'>Skills</div>
                            </div>
                            <div className='flex flex-col items-center justify-center'>
                                <div className='text-2xl font-medium flex items-center space-x-2'>
                                    <span>4.5</span>
                                    <StarIcon className='guide-icon w-5 h-5 text-yellow-500' />
                                </div>
                                <div className='text-xs text-gray-500'>Ratings</div>
                            </div>
                            <div className='flex flex-col items-center justify-center'>
                                <div className='text-2xl font-medium flex items-center space-x-2'>
                                    <span>3</span>
                                    <span className='font-light text-lg'>km</span>
                                </div>
                                <div className='text-xs text-gray-500'>Away</div>
                            </div>
                        </div>

                    </div>




                    {/* <div className=' flex items-center justify-center space-x-10 w-full space-y-2'>
                        <div className='flex flex-col items-center justify-center'>
                            <ShareIcon className='w-5 h-5' />
                            <div className='text-xs text-gray-500'>Share</div>
                        </div>
                        <div className='flex flex-col items-center justify-center'>
                            <ExclamationCircleIcon className='w-5 h-5' />
                            <div className='text-xs text-gray-500'>Report</div>
                        </div>
                    </div> */}

                </div>
                <div className='flex-1 flex flex-col w-full md:w-[69%] rounded-xl overflow-y-auto scrollbar h-full px-2 md:p-2 '>
                    <div className='w-full h-56 sm:h-64 md:h-[30%] p-2'>
                        {
                            data.images.length > 0 ? <ServiceImages images={data.images} />
                                :
                                <NoServiceImages />

                        }
                    </div>
                    <div className='mt-14 text-left divide-y-2'>
                        <div className='py-5 text-lg font-semibold'>About {data.user.display_name}</div>
                        <div className='py-3 text-justify px-10'>
                            {data.description.length > 0 ? data.description : <span className='text-gray-400'>Describe your business to your clients</span>}
                            {data.description.length > 0 ? <div className='text-sm font-bold my-3 text-gray-500'>see more</div> : null}
                        </div>
                    </div>

                    <div className='mt-14 text-left divide-y-2'>
                        <div className='py-5 text-lg font-semibold'>Services</div>
                        <div className='py-3 grid grid-cols-1 justify-center md:grid-cols-3 md:gap-10  md:px-10'>
                            {
                                data.skillsets.map((skill, indx) => {
                                    return <SkillSet key={indx} skill={skill} />
                                })
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

const SkillSet = ({ skill }) => {

    return skill.id ? (
        <div className='w-full h-auto justify-center flex flex-col rounded-lg items-center py-5 bg-white shadow-md'>
            <div className='w-[80%] aspect-square  rounded-lg bg-white mb-5'>
                <img src={skill.image}
                    className='w-full h-full rounded-xl object-cover' />
            </div>
            <div className='flex flex-col items-center space-y-2'>
                <div className='font-bold capitalize'>{skill.id?.split('_').join(" ")}</div>
                <div className='text-sm text-gray-400 font-medium'>{skill.rates.currency.symbol} {skill.rates.rates} / per hour</div>
                <div className='flex items-center md:space-x-4 pt-5'>
                    <TruckIcon className='w-5 h-5' />
                    <TruckIcon className='w-5 h-5' />
                    <TruckIcon className='w-5 h-5' />
                    <TruckIcon className='w-5 h-5' />
                </div>
            </div>
        </div>
    ) : null
}

const ServiceImages = ({ images }) => {


    return (
        <div className='h-full flex flex-col'>
            <div className='flex justify-between text-xs font-medium py-4 text-gray-500'>
                <div> - {images.length} images</div>
                <div>
                    <div className='flex items-center space-x-2 text-primary-500'>
                        <span>See all</span>
                        <ArrowNarrowRightIcon className='w-3 h-3' />
                    </div>
                </div>
            </div>
            <div className='hidden h-[95%] w-full md:flex space-x-2 '>
                {
                    images.slice(0, 3).map((img, indx) => {
                        return (
                            <div key={indx} className=' h-full flex justify-center items-center rounded-lg' style={{ width: indx === 0 ? "40%" : indx === 1 ? "35%" : "25%" }} >
                                <img src={img.src? img.src : img} className={`h-full w-full object-cover rounded-lg`} />
                            </div>
                        )
                    })
                }
            </div>
            <div className='h-[95%] w-full flex justify-center md:hidden '>
                <div className='  h-full w-[90%] flex justify-center items-center rounded-lg' >
                    <img src={images[0].src? images[0].src : images[0]} className={`h-full w-full object-cover rounded-lg`} />
                </div>
            </div>
        </div>
    )
}

const NoServiceImages = () => {

    const images = [
        "https://affinityhealthclinic.ca/wp-content/uploads/2017/07/Image-Placeholder.jpg",
        "https://affinityhealthclinic.ca/wp-content/uploads/2017/07/Image-Placeholder.jpg",
        "https://affinityhealthclinic.ca/wp-content/uploads/2017/07/Image-Placeholder.jpg",
    ]

    return (
        <div className='h-full flex flex-col'>

            <div className='hidden h-[95%] w-full md:flex space-x-2 '>
                {
                    images.slice(0, 3).map((img, indx) => {
                        return (
                            <div key={indx} className='  h-full flex justify-center items-center rounded-lg' style={{ width: indx === 0 ? "40%" : indx === 1 ? "35%" : "25%" }} >
                                <img src={img.src? img.src : img} className={`h-full w-full object-cover rounded-lg`} />
                            </div>
                        )
                    })
                }
            </div>
            <div className='h-[95%] w-full flex justify-center md:hidden '>
                <div className='  h-full w-[90%] flex justify-center items-center rounded-lg' >
                    <img src={images[0].src? images[0].src : images[0]} className={`h-full w-full object-cover rounded-lg`} />
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        profile: state.services.payload,
        user: state.user?.data.user
    }
}

export default connect(mapStateToProps, null)(ServiceProviderProfile)