import React, { useEffect, useState } from 'react'
import FormChecker from '../../../../DashboardComponents/FormComponents/FormChecker'
import { reviewData } from '../../../Main/SubScreens/Settings/Forms/data'
import { useBusinessForm } from '../../../../../../Components/UtilityComponents/BusinessFormContext'

const ReviewForm = ({ business }) => {
  const [reviewMessages, setReviewMessages] = useState([])

  useEffect(() => {
  }, [reviewMessages])

  return (
    <div>
      <div className='flex flex-col space-y-10 pb-[100px]'>
        <div className='flex space-x-10 items-start'>

          <div className='w-5 h-5 aspect-square rounded-md mt-2 bg-primary-500'></div>
          <div>
            <div>
              <div className='text-lg font-semibold'>Your Services</div>
              <div className='text-sm'>These are the business you will be able to render on Guide. if needed you can go back and make changes as you see fit before publishing</div>
            </div>
            <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 mt-5 lg:grid-cols-3 xl:grid-cols-4'>
            </div>
          </div>

        </div>

        <div>
          <div className='space-y-10'>
            {
              reviewData.map((review, indx) => {
                return <ReviewMessage key={indx} review={review} index={indx} reviewMessages={reviewMessages} setReviewMessages={setReviewMessages} checker={review.condition} />
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const ReviewMessage = ({ review, checker = null, index, reviewMessages, setReviewMessages }) => {

  const [agreed, setAgreed] = useState(false)
  const business = useBusinessForm() 


  useEffect(() => {
    setAgreed(business.review[0]?.agreed)
  }, [])

  useEffect(() => {
    if (checker) {

      if (!reviewMessages.some((rm, indx) => rm.id === review.id)) {
        setReviewMessages([...reviewMessages, {
          id: review.id,
          title: review.condition.text,
          agreed
        }])
      } else {
        const tmp = reviewMessages 
        reviewMessages.map((reviewItem, i) => {
          if (reviewItem.id === review.id) {
            tmp[i] = {
              id: review.id,
              title: review.condition.text,
              agreed
            }
          }
        })

        setReviewMessages(tmp)
        business.updateReview(tmp)
        business.setAcceptedAgreement(agreed)
      }
    }
  }, [agreed])

  useEffect(()=>{
    console.log(reviewMessages,'----------')
  },[reviewMessages, agreed])

  return (
    <div className='w-full flex items-start  space-x-10'>
      <div className='w-5 h-5 rounded-md mt-2 bg-primary-500'></div>
      <div className='flex flex-col space-y-2'>
        <div className='text-lg font-semibold'>{review.title}</div>
        <div className='text-sm'>{review.description}</div>
        <div>
          {checker && <FormChecker selected={agreed} setSelected={setAgreed} text={checker.text} />}
        </div>
      </div>
    </div>
  )
}

export default ReviewForm