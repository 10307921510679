import { ExclamationCircleIcon, PencilIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SelectImages from '../../../../../../Components/SelectImages'
import DashboardFormField from '../../../../DashboardComponents/DashboardFormField'
import { DashboardFormDropDownList } from '../../../../DashboardComponents/DashboardFormFieldDropList'
import { blogCategories } from '../data'

const SetupBlog = ({ title, draftID, id, deleteBlogPost, setTitle, cover_photo, setCoverPhoto, category, setcategory, setupHasError, setAddress, address }) => {

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            const { coords } = position
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.latitude},${coords.longitude}&key=${process.env.REACT_APP_GOOGLEMAPS_API}`).then(async (res) => {
                const response = await res.json()

                setAddress({
                    coords,
                    address: response.plus_code?.compound_code
                })
            })
        })
    }, [])

    return (
        <div className='w-full h-full py-5 space-y-5'>
            <div className='space-y-2 px-5'>
                <div className='-pb-3 text-sm text-gray-400'>Where you are publishing from.</div>
                {
                    address ?
                        <div className='text-lg'>Publishing from {address?.address}</div>
                        :
                        <div className='w-[60%] h-4 bg-gray-200 animate-pulse' />
                }
            </div>
            <div className=''>
                <div className='px-5 -pb-3 text-sm text-gray-400'>Give your Blog a fitting Title.</div>
                <DashboardFormField name="Blog Title" text={title} setText={setTitle} />
                {
                    (setupHasError && title === '') &&
                    <div className='px-5 -pb-3 text-xs text-red-500 flex items-center space-x-4'>
                        <ExclamationCircleIcon className='w-3 h-3' />
                        <span>A blog title is required</span>
                    </div>}
            </div>
            <div className=''>
                <div className='px-5 -pb-3 text-sm text-gray-400'>Select a category for this post.</div>
                <DashboardFormDropDownList name="Blog Category" selectedInterval={category} setSelectedInterval={setcategory} list={blogCategories} />
            </div>
            <div>
                <div className='px-5 -pb-3 text-sm text-gray-400'>Add a cover photo for your post.</div>
                <div className='px-5'>
                    {
                        Array.isArray(cover_photo) ?
                            <SelectImages images={cover_photo} setImages={setCoverPhoto} single={true} />
                            :
                            <div className='w-full md:max-w-lg md:w-auto flex flex-col justify-center items-center bg-[#D9D9D9] rounded-xl aspect-video overflow-hidden relative '>
                                <img src={cover_photo}
                                    className='w-full h-full object-cover object-center aspect-video' />
                                <div className='flex items-center space-x-2 absolute top-3 right-3'>
                                    <div onClick={() => setCoverPhoto([])} className='p-2 bg-white shadow-md rounded-full cursor-pointer hover:bg-green-400 hover:text-white'>
                                        <PencilIcon className='guide-icon w-5 h-5 relative overflow-hidden' />
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                {
                    (setupHasError && cover_photo.length === 0) &&
                    <div className='px-5 -pb-3 text-xs text-red-500 flex items-center space-x-4'>
                        <ExclamationCircleIcon className='w-3 h-3' />
                        <span>A cover photo is required</span>
                    </div>}

            </div>
            {(id || draftID) &&
                <div className='py-10 px-5 space-x-4 flex items-center'>
                    <div onClick={() => deleteBlogPost(true)} className='cursor-pointer text-base font-semibold text-red-500 hover:underline'>Delete Post</div>
                    {!draftID && <Link to={`/blog/${id}`} className='cursor-pointer text-base font-semibold text-primary-500 hover:underline'>View Post</Link>}
                </div>}

        </div>
    )
}

export default SetupBlog