import React, { useEffect, useState } from 'react'
import DashboardFormField from '../../../../DashboardComponents/DashboardFormField'
import Loader from '../../../../../../assets/spinner.svg'
import { PencilAltIcon } from '@heroicons/react/solid'
import { BlogBlockTypes } from '..'

const SubTitleBlock = ({ id, addSubTitle, subtitle, setSubTitle, cancelBlock, saveDraft }) => {
    const [editing, setEditing] = useState(true)
    const [loading, setLoading] = useState(false)


    const saveSubTitle = () => {
        setLoading(true)
        setTimeout(() => {
            setEditing(!editing)
            setLoading(false)
            addSubTitle(id, 'subtitle', subtitle)
            saveDraft()
        }, 2000)
    }

    useEffect(() => {
        if (subtitle) {
            setEditing(false)
            console.log(subtitle)
        }
    }, [])

    const setText = (value) => {
        setSubTitle(id, value, BlogBlockTypes.SUBTITLE_BLOCK)
    }

    return (
        <div>
            {
                editing ?
                    <div>
                        <DashboardFormField text={subtitle} setText={setText} />
                        <div className='flex items-center justify-end space-x-4 px-5'>
                            <div onClick={() => cancelBlock(id)} className='py-2 px-4 bg-red-500 text-white font-medium rounded-md cursor-pointer'>Cancel </div>
                            <div onClick={() => saveSubTitle()} className={`py-2 px-4 ${loading ? 'bg-gray-300' : 'bg-primary-500'} text-white font-medium rounded-md cursor-pointer flex items-center space-x-2`}>
                                {loading && <img src={Loader} className='w-4 h-4 animate-spin' />}
                                <span>Save</span>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='p-2 md:px-5 py-1'>
                        <div onClick={() => setEditing(!editing)} className='p-2 md:px-5 ease_transition rounded-xl border-dashed border-primary-500 border-opacity-0 hover:border-opacity-50 border-4'>
                            <div className='text-2xl py-2 md:py-4 font-bold text-justify leading-relaxed text-gray-500'>{subtitle}</div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default SubTitleBlock