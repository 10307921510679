import { AUTH_SERVER_URL } from "../../../assets/url"

const authURL = AUTH_SERVER_URL


export const load_apartments = async (data) => {
    return new Promise(async (resolve, reject) => {
        await fetch(`${authURL}/api/user/get_houses`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Access-Control-Allow-Credentials': true,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: {
                    option: data.option,
                    latitude: data.longitude,
                    longitude: data.latitude,
                    radius: data.radius,
                }
            })
        }).then(async res => {
            const response = await res.json()

            if (response.description) {
                reject(response)
            }
            resolve(response)
        }).catch(err => reject(err))
    })
}

export const loadThirdPartyApartments = () => {
    return new Promise(async (resolve, reject) => {
        await fetch(`${authURL}/api/user/get_third_party_houses `, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Access-Control-Allow-Credentials': true,
                'Content-Type': 'application/json'
            }
        }).then(async res => {
            const response = await res.json()
            if (response.description) {
                reject(JSON.parse(response))
            }
            resolve(JSON.parse(response))
        }).catch(err => reject(err))
    })
}

export const loadMoreThirdPartyApartments = (page) => {
    return new Promise(async (resolve, reject) => {
        await fetch(`${authURL}/api/user/load_more_third_party_houses?page=${page} `, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Access-Control-Allow-Credentials': true,
                'Content-Type': 'application/json'
            }
        }).then(async res => {
            const response = await res.json()

            if (response.description) {
                reject(response)
            }
            resolve(JSON.parse(response))
        }).catch(err => reject(err))
    })
}


export const currencySymbol = (currency) => {
    const optn = ['pounds', 'euro', 'usd', 'lira','naira']
    const counter = [];
    optn.map((val) => {

        switch (val) {
            case 'pounds':
                if (currency.pounds) {
                    counter.push('£');
                }
                break
            case 'euro':
                if (currency.euro) {
                    counter.push('€');
                }
                break
            case 'usd':
                if (currency.usd) {
                    counter.push('$');
                }
                break
            case 'lira':
                if (currency.lira) {
                    counter.push('₺');
                }
                break
            case 'naira':
                if (currency.naira) {
                    counter.push('₦');
                }
                break
            default:
                counter.push('NAN')
                break
        }

    })
    if (counter.length > 1) {
        return '£'
    } else {
        return counter[0]
    }

}

export const listOfAcceptedCurrencies = (currency, sym) => {
    const cur = ['pounds', 'euro', 'usd', 'lira']
    const acc_cur = []

    cur.forEach(c => {
        if (currency[c] == true && currency[c] !== currencySymbol(currency)) {
            if (c == 'pound') {
                acc_cur.push('£')
            }
            if (c == 'lira') {
                acc_cur.push('₺')
            }
            if (c == 'usd') {
                acc_cur.push('$')
            }
            if (c == 'euro') {
                acc_cur.push('€')
            }
        }
    })

    return acc_cur
}

export const sterilizeInt = (value) => {

    if (Array.isArray(value)) {
        const tmp = []
        value.forEach(val => {
            if (val.includes("+")) {
                tmp.push(parseInt(val.split("+")[0]))
            } else if (val.length < 2) {
                tmp.push(parseInt(val))
            } else if (val.length > 2) {
                tmp.push(val)
            }
        });
        return tmp
    } else {
        if (value.includes("+")) {
            return parseInt(value.split("+")[0])
        } else if (value.length < 2) {
            return parseInt(value)
        } else if (value.length > 2) {
            return value
        }
    }
}

export const doFilter = (data) => {
    return new Promise(async (resolve, reject) => {
        return await fetch(`${authURL}/api/user/get_filtered_houses`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: data
            })
        }).then(async response => {
            const res = await response.json()
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}