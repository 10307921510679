import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "../../../Components/UtilityComponents/AuthProvider";
import {
  load_user,
  login,
  printErrorMessages,
} from "../../../functions/authFunctions";
import { connect } from "react-redux";
import {
  remove_user_data,
  store_user_data,
} from "../../../store/actions/authActions";
import { useSuzi } from "../../../Components/Assistant";
import WorkFromHome from "../../../assets/online_shopping.svg";
import AuthTemplate from "..";
import { Link } from "react-router-dom";
import DashboardFormField from "../../Dashboard/DashboardComponents/DashboardFormField";
import CheckerBox from "../../Dashboard/DashboardComponents/CheckerBox";
import Loader from "../../../assets/spinner.svg";
import { useAlert } from "../../../Components/UtilityComponents/ErrorContext";
import { useAccounts } from "../../../Components/UtilityComponents/AccountType";

const LoginPage = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const suzi = useSuzi();
  const _alert = useAlert();
  const account = useAccounts();

  const from = location.state?.from?.pathname || "/";

  function mobiletriggerLogin(platform) {
    // Trigger login and send back login token when user is loggin in from mobile app
    console.log(window?.isNative)
    switch (window.isNative) {
      case "android":
        window.ReactNativeWebView.postMessage(JSON.stringify(platform));
        break;

      case "ios":
        window.webkit.messageHandlers.reactNative.postMessage(
          JSON.stringify(platform)
        );
        break;

      default:
        window[platform]();
    }
  }

  useEffect(() => {
    return () => {
      setEmail("");
      setPassword("");
      setLoading(false);
    };
  }, []);

  document.addEventListener("message", function (event) {}, false);

  const signout = () =>
    auth.signout(() => {
      console.log(
        "The call back function where the user state is removed from storage"
      );
    });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
        handleLogin()
    }
  };

  const iCantLogin = () => {
    suzi.addMessage({
      text: (
        <div>
          Hey there, <br />
          please note that Guide is still in Beta. This means that we are still
          developing the platform, and so, if you catch any errors or glitches,
          please inform us immediately and we will fix it. For more information
          see the links below.
          <div className="flex flex-col py-2 space-y-2">
            <a
              href="https://www.lifewire.com/what-is-beta-software-2625812"
              target="_blank"
              className="w-auto py-2 text-center rounded-lg cursor-pointer hover:bg-gray-100 bg-white text-primary-500 font-bold"
            >
              What is a Beta Software?
            </a>
          </div>
        </div>
      ),
      timeout: 10000,
    });
  };

  const handleLogin = async (data, from) => {
    setLoading(true);
    if (email.length <= 0 || password.length <= 0) {
      setLoading(false);
      return;
    }

    return await login(email, password)
      .then((res) => {
        if (!res.access_token && res.description) {
          signout();
        }

        if (window.isNative) {
          mobiletriggerLogin(res);
          // setLoading(false)
          return;
        }

        return res;
      })
      .then(async (res) => {
        const user = await auth.getuser(res.sub);
        await props.storeUser(user);
        return user;
      })
      .then((user) => {
        console.log(user);
        auth.signin({ uid: user.user?.uid, role: user.user?.role });
      })
      .then(() => {
        if (from) {
          navigate(from, { replace: true });
        }
        setLoading(false);
      })
      .catch((e) => {
        try {
          mobiletriggerLogin({ error: e });
        } catch (e) {
          console.error("NOT ON MOBILE");
        }
        printErrorMessages(e, _alert);
        signout();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AuthTemplate
      title="Making Your Dreams Reality"
      sub_title="growth happens faster when the jobs gets easier"
      vector_img={WorkFromHome}
      action={() =>
        mobiletriggerLogin({
          back: "CLOSE MODAL",
        })
      }
    >
      {from.includes("/dashboard") && !auth.byUser && (
        <div className="p-2 border-[1px] border-red-500 rounded-lg ">
          <h3 className=" font-medium text-center text-red-500">
            Your Session Expired, Please login again
          </h3>
        </div>
      )}
      <div className="w-full h-auto">
        <div className=" font-light p-5 text-xl md:text-5xl text-primary-500">
          Login
        </div>
        <DashboardFormField
          name="Email"
          type="email"
          placeholder="johndoe@mail.com"
          text={email}
          setText={setEmail}
          onKeyPress={handleKeyPress}
        />
        <DashboardFormField
          name="Password"
          password={true}
          placeholder="**********"
          text={password}
          setText={setPassword}
          onKeyPress={handleKeyPress}
        />
        <div className=" px-12 flex flex-row space-x-4 items-center">
          <CheckerBox />
          <div className="text-gray-400 text-xs sm:text-md ">
            Remember Password
          </div>
        </div>

        <div
          onClick={() => handleLogin()}
          className={`w-auto h-14 cursor-pointer flex justify-center items-center rounded-xl  mx-2 my-4 space-x-2  ${
            loading ? "bg-gray-300" : "bg-primary-500 hover:bg-purple-600"
          }`}
        >
          {loading && <img src={Loader} className="w-4 h-4 animate-spin" />}
          <span className="font-medium text-white cursor-pointer">Login</span>
        </div>
        <div className="  w-full bg-red justify-center items-center text-center mt-5">
          <div className=" text-xs sm:text-md justify-center flex">
            don't have an account yet?
          </div>
          <div className=" text-xs sm:text-md justify-center flex">
            <Link
              to="/signup"
              className="px-2 font-semibold cursor-pointer text-primary-500"
            >
              sign up
            </Link>
          </div>
        </div>
        <div className="  w-full bg-red justify-center items-center text-center mt-5">
          <div className=" text-xs sm:text-md justify-center flex">
            {" "}
            <Link
              to="/reset_password"
              className="px-2 font-semibold cursor-pointer text-primary-500"
            >
              reset password
            </Link>
          </div>
        </div>

        <div
          onClick={() => iCantLogin()}
          className="font-bold hover:underline cursor-pointer w-full bg-red justify-center items-center text-center mt-5"
        >
          <div className=" text-xs sm:text-md justify-center underline flex">
            Beta v1.02 Note
          </div>
        </div>
      </div>
    </AuthTemplate>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user?.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeUser: (data) => dispatch(store_user_data(data)),
    removeUser: () => dispatch(remove_user_data()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
