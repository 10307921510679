import React from 'react'
import CheckerBox from '../../CheckerBox'

const FormChecker = ({ text, selected, setSelected, subtext = null }) => {
    return (
        <div className="w-auto px-5 flex flex-row space-x-4 items-start my-4">
            <CheckerBox value={selected} setValue={setSelected} />
            {
                subtext ?
                    <div className='text-left flex flex-col space-y-1'>
                        <div className="text-gray-700 text-base font-medium">{text}</div>
                        <div className="text-gray-700 text-xs sm:text-md ">{subtext}</div>
                    </div>
                    :

                    <div className="text-gray-700 text-sm sm:text-md ">{text}</div>
            }
        </div>
    )
}

export default FormChecker