import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DashboardFormField from "../../../../DashboardComponents/DashboardFormField";
import TipsCard from "../../../../DashboardComponents/FormComponents/TipsCard";
import { BusinessCategories } from "../../../../../../functions/categories";
import { ChevronRightIcon, XIcon } from "@heroicons/react/outline";
import { useAlert } from "../../../../../../Components/UtilityComponents/ErrorContext";

const CategoryForm = ({ business }) => {
  const [category, setCategory] = useState("");
  const [subCats, setSubCats] = useState([]);
  const [activeBusinessCategories, setActiveBusinessCategories] = useState([])

  const categories = new BusinessCategories()
  const alert = useAlert()

  useEffect(() => {
    if (business.stored_business?.category) {
      parseForRender(business.stored_business?.category);
    }
    setCategory("")
  }, []);

  useEffect(() => {
    business.updateCategory(parseCategoryForUpload());
  }, [category, subCats, activeBusinessCategories]);

  const parseCategoryForUpload = () => {
    if (activeBusinessCategories?.length <= 0) return []
    return activeBusinessCategories?.map((category) => {
      const data = {
        category: category.category,
        sub_category: category.sub_category.category
      }
      if (category.selected_services) {
        data['selected_services'] = category.selected_services
      }
      console.log(data)
      return data
    })
  }

  const parseForRender = (_categories) => {

    if (categories?.length <= 0) return []
    setActiveBusinessCategories(_categories.map((category) => {
      const db_data = categories.match(category.sub_category)[0]
      const data = {
        category: category.category,
        sub_category: {
          category: category.sub_category
        }
      }

      if(db_data.data !== undefined){
        data["sub_category"]["sub_categories"] = db_data.data
      }

      if (category.selected_services) {
        data['selected_services'] = category.selected_services
      }
      return data
    }))
  }

  const select_category = (data) => {
    if (activeBusinessCategories?.length >= 3) {
      setCategory("")
      return alert.setalert({
        type: 'error',
        title: "Max Categories Reached!",
        body: "you can add no more than 3 categories."
      })
    }
    const cat = {
      category: data.category,
      sub_category: {
        category: data.sub_category
      }
    }
    if (data.data !== undefined) {
      cat["sub_category"]["sub_categories"] = data.data
    }
    setActiveBusinessCategories([cat, ...activeBusinessCategories])
    setCategory("")
  }

  const remove_selected_category = (indx) => {
    setActiveBusinessCategories(activeBusinessCategories.filter((_, i) => i !== indx))
  }

  const addServicesTocategory = (service, index) => {
    setActiveBusinessCategories(activeBusinessCategories.map((category, i) => {
      const obj = category
      if (index === i) {
        if (obj.selected_services) {
          if (obj.selected_services.includes(service)) {
            obj.selected_services = obj.selected_services.filter(s => s !== service)
          } else {
            obj.selected_services.push(service)
          }
        } else {
          obj['selected_services'] = [service]
        }
      }
      setSubCats([...subCats, obj.selected_services])
      return obj
    }))
  }

  return (
    <div className="flex h-auto items-start justify-start w-full">
      <div className="py-5 w-full md:w-[60%] ">
        <div>
          <div className="max-w-lg">
            <DashboardFormField
              rows={10}
              name="category"
              placeholder="search for your business type"
              maxLength={100}
              text={category}
              setText={setCategory}
            />
          </div>
          <div className="max-w-lg flex justify-end px-5">
            {/* {category.length} / <b> 100</b> */}
          </div>
          <div className=" ml-2 mr-2 md:mr-5 pb-[100px] lg:pb-0">
            <div className="ease_transition -left-3 top-16 max-h-[40vh] w-full bg-white p-3 divide-y-2 overflow-y-scroll scrollbar" style={{ height: category?.length <= 0 && 0, opacity: category?.length <= 0 && 0 }} >
              {
                categories.search(category)?.map((res, i) => {
                  return (
                    <div onClick={() => select_category(res)} className="flex flex-row items-center space-x-2 hover:bg-primary-300 cursor-pointer py-2" key={i}>
                      <div className="text-gray-500">{res.category}</div>
                      <div>-</div>
                      <div> {res.sub_category} </div>
                    </div>
                  )
                })
              }
            </div>
            {
              activeBusinessCategories?.length > 0 &&
              activeBusinessCategories?.map((c, i) => {
                return (
                  <CategoryItemCard
                    key={i}
                    data={c}
                    removeIndex={remove_selected_category}
                    addServicesTocategory={addServicesTocategory}
                    index={i} />
                )
              })
            }
          </div>
        </div>
      </div>
      <div className="hidden md:w-[40%] h-full md:flex justify-start">
        {/* <TipsCard /> */}
      </div>
    </div>
  );
};

export default CategoryForm;


const CategoryItemCard = ({ data, removeIndex, index, addServicesTocategory }) => {

  const [limitVisible, setLimitVisible] = useState(true)
  const sub_categories = data.sub_category?.sub_categories ? limitVisible ? data.sub_category.sub_categories.slice(0, 6) : data.sub_category.sub_categories : null
  return (
    <div className="w-full flex flex-col my-4 bg-white border-2 p-5 rounded-xl">
      <div className="w-full flex flex-col lg:flex-row justify-start lg:justify-between">
        <div className="w-full flex flex-row items-center space-x-2">
          <div className="text-sm lg:text-xl font-medium">{data.category}</div>
          <div><ChevronRightIcon className="w-5 h-5" /></div>
          <div className="text-xs lg:text-sm font-medium text-gray-500">{data.sub_category?.category}</div>
        </div>
        <div onClick={() => removeIndex(index)} className="text-sm text-red-500 cursor-pointer">remove</div>
      </div>

      {sub_categories &&

        <div className="space-y-4 my-5">
          <div className="mb-5">
            <h3 className="font-medium ">Services</h3>
            <p className="text-xs font-light text-gray-500">specify the services you offer to help us get you better leads.</p>
          </div>
          <div className="flex flex-wrap flex-row">
            {sub_categories?.map((item, i) => {
              return <ServiceToggleItem active={data.selected_services?.includes(item)} key={i} item={item} addServicesTocategory={addServicesTocategory} category_index={index} />
            })}
          </div>
          {data.sub_category?.sub_categories.length > 6 && <span onClick={() => setLimitVisible(!limitVisible)} className="text-xs font-medium underline cursor-pointer">{limitVisible ? "Show more" : "hide"} ({data.sub_category.sub_categories.length - 6})</span>}
        </div>}
    </div>
  )
}

const ServiceToggleItem = ({ active = false, item, addServicesTocategory, category_index }) => {

  return (
    <div onClick={() => addServicesTocategory(item, category_index)} className={`p-2 flex flex-row mb-2 mr-2 w-auto space-x-2 cursor-pointer items-center border-2 rounded-full ${active ? 'bg-primary-500 text-white' : 'bg-primary-300 text-black/90'} `}>
      <XIcon className={`w-5 h-5 ${active ? 'rotate-0' : 'rotate-45'} ease_transition`} />
      <p className="text-sm max-w-sm">{item}</p>
    </div>
  )
}
