import React from 'react'
import { DropDownList_SM } from '../../../../DashboardComponents/DropDownList'

const SocialsViewCard = ({children, showList, list, title }) => {
  return (
    <div className='p-1 flex-col items-start justify-center w-auto rounded-xl bg-secondary-white flex  relative'>
        { title && <div className='flex flex-col items-start px-5 pt-3'>
            <span className='text-md text-textcolor font-medium'>{title.head}</span>
            <span className='text-xs text-light_textcolor'>{title.sub}</span>
        </div>}
        <div className='space-x-2 flex items-center p-5'>
            {children}
        </div>
        { showList && <DropDownList_SM  list={list} />}
    </div>
  )
}

export default SocialsViewCard