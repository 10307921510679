import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import React from "react";

const ImageSlider_v2 = ({ images, cover = false }) => {
  const [position, setPosition] = React.useState(0);

  const moveSlide = (direction) => {
    const len = images.length - 1;

    if (direction < 0) {
      if (Math.abs(position) <= 0) {
        setPosition(-1 * (100 * len));
      } else {
        setPosition(position + 100);
      }
    } else {
      if (Math.abs(position) / (len * 100) === 1) {
        setPosition(0);
      } else {
        setPosition(position - 100);
      }
    }
  };

  return (
    <div className="relative w-full bg-black/80 h-full">
      <div className="h-screen w-auto">
        <div className="w-full h-full flex items-center overflow-x-hidden">
          {images.map((image, indx) => {
            return (
              <div
                key={indx}
                style={{
                  transform: `translateX(${position}%)`,
                  flex: 1,
                  flexGrow: 0,
                  flexShrink: 0,
                  flexBasis: "100%",
                  height: "100%",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: null,
                }}
                className="h-[100%] ease_transition flex justify-center items-center rounded-lg"
              >
                <img
                  loading="lazy"
                  src={image}
                  className={`w-full rounded-lg h-full object-center ${cover ? "object-cover" : " object-contain"
                    }`}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Dots length={images.length} index={Math.abs(position / 100)} />
      <div className="w-full text-primary-500 md:text-white absolute top-[45%] flex justify-between items-center">
        {images.length > 1 && (
          <div className="cursor-pointer" onClick={() => moveSlide(-1)}>
            <ChevronLeftIcon className="w-7 h-7 md:w-10 md:h-10" />
          </div>
        )}
        {images.length > 1 && (
          <div className="cursor-pointer" onClick={() => moveSlide(1)}>
            <ChevronRightIcon className="w-7 h-7 md:w-10 md:h-10" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageSlider_v2;

const Dots = ({ length, index }) => {
  return (
    <div className="position flex items-center space-x-1 absolute bottom-5 justify-center w-full">
      {[...Array(length)].map((_, indx) => {
        return (
          <div
            key={indx}
            className={`w-[0.35rem] h-[0.35rem] rounded-full ${index === indx ? "bg-primary-500" : "bg-primary-200"
              }`}
          />
        );
      })}
    </div>
  );
};
