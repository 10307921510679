import { StarIcon } from '@heroicons/react/outline'
import React from 'react'



const RatingBars = ({ ratings }) => {
    const fives = ratings?.fives
    const fours = ratings?.fours
    const threes = ratings?.threes
    const twos = ratings?.twos
    const ones = ratings?.ones
    const sum = ones + twos + threes + fours + fives

    const fivesum = fives === 0? 0 : fives / sum
    const foursum = fours === 0? 0 : fours / sum
    const threesum = threes === 0? 0 : threes / sum
    const twosum = twos === 0? 0 : twos / sum
    const onesum = ones === 0? 0 : ones / sum

    return ratings ? (
        <div className='flex flex-1'>
            <div className='flex w-full flex-col space-y-1 pb-3'>
                <div className='flex items-center space-x-2'>
                    <div className='w-10 text-light_textcolor font-medium flex justify-center items-center text-lg space-x-1'><span>5</span> <StarIcon className='w-5 h-5 text-light_textcolor' /></div>
                    <div className='min-h-[2px] bg-green-400' style={{ width: ((fivesum) * 100) + '%' }} />
                    <span className='text-xs text-light_textcolor'>{((fivesum) * 100) | 0}%</span>
                </div>
                <div className='flex items-center space-x-2'>
                    <div className='w-10 text-light_textcolor font-medium flex justify-center items-center text-lg space-x-1'><span>4</span> <StarIcon className='w-5 h-5 text-light_textcolor' /></div>
                    <div className='min-h-[2px]  bg-yellow-400' style={{ width: ((foursum) * 100) + '%' }} />
                    <span className='text-xs text-light_textcolor'>{((foursum) * 100) | 0}%</span>
                </div>
                <div className='flex items-center space-x-2'>
                    <div className='w-10 text-light_textcolor font-medium flex justify-center items-center text-lg space-x-1'><span>3</span> <StarIcon className='w-5 h-5 text-light_textcolor' /></div>
                    <div className='min-h-[2px]  bg-orange-400' style={{ width: ((threesum) * 100) + '%' }} />
                    <span className='text-xs text-light_textcolor'>{((threesum) * 100) | 0} %</span>
                </div>
                <div className='flex items-center space-x-2'>
                    <div className='w-10 text-light_textcolor font-medium flex justify-center items-center text-lg space-x-1'><span>2</span> <StarIcon className='w-5 h-5 text-light_textcolor' /></div>
                    <div className='min-h-[2px]  bg-red-400' style={{ width: ((twosum) * 100) + '%' }} />
                    <span className='text-xs text-light_textcolor'>{((twosum) * 100) | 0}%</span>
                </div>
                <div className='flex items-center space-x-2'>
                    <div className='w-10 text-light_textcolor font-medium flex justify-center items-center text-lg space-x-1'><span>1</span> <StarIcon className='w-5 h-5 text-light_textcolor' /></div>
                    <div className='min-h-[2px]  bg-red-400' style={{ width: ((onesum) * 100) + '%' }} />
                    <span className='text-xs text-light_textcolor'>{((onesum) * 100) | 0}%</span>
                </div>
            </div>

        </div>
    ) : (
        <div className='flex flex-1'>
            <div className='flex w-full flex-col space-y-1 pb-3'>
                <div className='flex items-center space-x-2'>
                    <div className='w-10 text-light_textcolor font-medium flex justify-center items-center text-lg space-x-1'><span>5</span> <StarIcon className='w-5 h-5 text-light_textcolor' /></div>
                    <div className='min-h-[2px] animate-pulse bg-gray-200' style={{ width: 100 + '%' }} />
                </div>
                <div className='flex items-center space-x-2'>
                    <div className='w-10 text-light_textcolor font-medium flex justify-center items-center text-lg space-x-1'><span>4</span> <StarIcon className='w-5 h-5 text-light_textcolor' /></div>
                    <div className='min-h-[2px] animate-pulse bg-gray-200' style={{ width: 100 + '%' }} />
                </div>
                <div className='flex items-center space-x-2'>
                    <div className='w-10 text-light_textcolor font-medium flex justify-center items-center text-lg space-x-1'><span>3</span> <StarIcon className='w-5 h-5 text-light_textcolor' /></div>
                    <div className='min-h-[2px] animate-pulse bg-gray-200' style={{ width: 100 + '%' }} />
                </div>
                <div className='flex items-center space-x-2'>
                    <div className='w-10 text-light_textcolor font-medium flex justify-center items-center text-lg space-x-1'><span>2</span> <StarIcon className='w-5 h-5 text-light_textcolor' /></div>
                    <div className='min-h-[2px] animate-pulse bg-gray-200' style={{ width: 100 + '%' }} />
                </div>
                <div className='flex items-center space-x-2'>
                    <div className='w-10 text-light_textcolor font-medium flex justify-center items-center text-lg space-x-1'><span>1</span> <StarIcon className='w-5 h-5 text-light_textcolor' /></div>
                    <div className='min-h-[2px] animate-pulse bg-gray-200' style={{ width: 100 + '%' }} />
                </div>
            </div>

        </div>
    )
}

export default RatingBars