import { ChevronDownIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import DashboardFormField from '../../../../../../DashboardComponents/DashboardFormField'
import { DashboardFormDropDownList } from '../../../../../../DashboardComponents/DashboardFormFieldDropList'
import TaskDetails from '../TaskDetails'

export const currencies = [
    {
        id: 0,
        title: 'USD',
        value: 'usd',
        symbol: '$'
    },
    {
        id: 1,
        title: 'EUR',
        value: 'euro',
        symbol: '€'
    },
    {
        id: 2,
        title: 'GBP',
        value: 'pound',
        symbol: '£'
    },
    {
        id: 3,
        title: 'TRY',
        value: 'lira',
        symbol: '₺'
    },
    {
        id: 4,
        title: 'NGN',
        value: 'naira',
        symbol: '₦'
    }
]

const RatesForm = ({ avg_rates, rates, setRates, selectedRates }) => {

    const setCurrency = (value) => {
        setRates({ ...rates, currency: value })
    }

    const setAmount = (value) => {
        setRates({ ...rates, rates: value })
    }

    return (
        <div className='px-3 md:px-10 py-5'>

            <div className='py-5 border-b border-[#D6D6FF] px-5 my-2 text-lg font-bold'>Your Task Rates</div>
            <div className='py-4 flex flex-col md:flex-row items-center space-x-24'>
                <div className='flex flex-col flex-1'>
                    <DashboardFormDropDownList name="Prefered Currency" selectedInterval={rates.currency} setSelectedInterval={setCurrency} list={currencies} />
                    <div className='text-sm px-5 -mt-3'>Currency you wish to charge your clients in</div>
                </div>
                <div className='flex-1 md:flex-2 py-8 md:py-0 md:max-w-xs'>
                    <span className=''>
                        most simillar service providers
                        accept local currencies
                    </span>
                </div>
            </div>
            <div>
                <div className='py-4 flex flex-col md:flex-row items-center space-x-24'>
                    <div className='flex flex-col flex-1'>
                        <div className='w-auto py-2 px-4 m-2 my-5 border-2 border-gray-300 rounded-xl flex bg-white flex-col text-sm text-textcolor'>
                            <div className='flex justify-between items-center text-textcolor'>
                                <span className='font-medium text-md '>Hourly Rate *</span>
                            </div>
                            <div className='w-full flex justify-between items-start overflow-hidden'>
                                <div className='flex items-center space-x-2'>
                                    <span>{rates.currency.symbol}</span>
                                    <input onChange={(e) => setAmount(e.target.value)} placeholder={`0.00`} value={`${rates.rates}`} className='pt-1 bg-none h-full outline-none w-auto' />
                                </div>
                                <div>per hour</div>
                            </div>
                        </div>
                        <div className='text-sm px-5 -mt-3'>Amount you charge clients for every hour, multiplied by your agreed hours. </div>
                    </div>
                    <div className='flex-1 md:flex-2 py-8 md:py-0 md:max-w-xs'>
                        <span className=''>
                            most simillar service providers
                            with your experience level charge
                            <span className='text-primary-500 font-semibold'> {avg_rates?.currency ? avg_rates?.currency : '$'}{avg_rates.average_rates}/hour</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RatesForm