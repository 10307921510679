import React, { useEffect, useState } from 'react'
import DashboardFormWrapper from '../../DashboardComponents/FormComponents/DashboardFormWrapper'
import DashboardSliderOver from '../../DashboardComponents/DashboardSliderOver'
import DashboardWrapper from '../../DashboardComponents/DashboardWrapper'
import AddServices from './AddServices'
import ServiceFormContext from '../../../../Components/UtilityComponents/ServiceFormContext'
import { ChartBarIcon, CursorClickIcon, EyeIcon, PhoneIcon } from '@heroicons/react/outline'
import { useOutletContext } from 'react-router';
import WelcomeCard from '../../Screens/Main/Cards/WelcomeCard';
import InfoCard from '../../Screens/Main/Cards/InfoCard';
import SocialsViewCard from '../../Screens/Main/Cards/SocialsViewCard';
import DashboardReviewCard from '../../Screens/Main/Cards/DashboardReviewCard';
import PhotosGridCard from '../../../../Components/PhotosGridCard';
import WelcomeSVG from '../../../../assets/ttdash.svg';
import { connect } from 'react-redux'
import { abbreviateNumber, loadServices } from '../../../../functions/service_functions.js'
import { store_services } from '../../../../store/actions/serviceActions'
import WhatsappIcon from '../../../../assets/WhatsappIcon'
import { useJobContext } from '../../../../Components/UtilityComponents/JobsContext'
import { UpdatesStatusTypes } from '../Jobs/JobsMenu/data'


export const intervals = [
    { id: 1, value: '30 seconds', unavailable: false },
    { id: 2, value: '30 minutes', unavailable: false },
    { id: 3, value: 'hour', unavailable: false },
    { id: 4, value: 'day', unavailable: true },
    { id: 5, value: 'month', unavailable: false },
]

const Services = ({ services, storeService, current_user }) => {

    const [openModal, setOpenModal] = useState(false)
    const [impression, setImpression] = useState(0)
    const [phone, setPhone] = useState(0)
    const [clicks, setClicks] = useState(0)
    const [whastapp, setWhatsapp] = useState(0)
    const [insta, setInsta] = useState(0)


    const jobs = useJobContext()

    const getImages = (images) => {
        const tmp = []
        images?.map((img) => {
            if (img.src) {
                tmp.push(img.src)
            } else {
                tmp.push(img)
            }
        })
        return tmp
    }

    let { user } = useOutletContext()
    let user_name = user?.display_name?.split(' ')
    if (user_name) {
        user_name = user_name && user_name?.length > 1 ? user_name[1] : user_name[0]
    } else {
        user_name = 'User'
    }


    const GROUP_ID = current_user?.user.groupIDs?.filter((gid) => gid.type === "service_provider")[0]?.id

    useEffect(() => {

        let mounted = true

        if (mounted) {
            loadServices(GROUP_ID).then((res) => {
                if (!res.message) {
                    storeService(res)
                    setImpression(res.impressions.view | 0)
                    setClicks(res.impressions.click | 0)
                    setPhone(res.impressions.phone | 0)
                    setWhatsapp(res.impressions.whatsapp | 0)
                    setInsta(res.impressions.insta | 0)
                } else {
                    storeService(null)
                }
            })
        }

        return () => {
            mounted = false
        }
    }, [])

    useEffect(() => { }, [impression])

    const createSerciceAction = () => {
        setOpenModal(true)
    }

    const editImages = () => {
        setOpenModal(true)
    }

    const cancelledJobs = jobs.groupByStatus[UpdatesStatusTypes.CANCELED] ? jobs.groupByStatus[UpdatesStatusTypes.CANCELED] : []
    const completedJobs = jobs.groupByStatus[UpdatesStatusTypes.COMPLETED] ? jobs.groupByStatus[UpdatesStatusTypes.COMPLETED] : []

    const userJobs = {
        pendingJobs: jobs.groupByStatus[UpdatesStatusTypes.PENDING] ?? [],
        closedJobs: [...completedJobs, ...cancelledJobs] ?? [],
        ongoingJobs: jobs.groupByStatus[UpdatesStatusTypes.STARTED] ?? []
    }

    return GROUP_ID ? (
        <DashboardWrapper title="Services">
            <div className='flex w-auto xl:min-w-[65%] m-5 mx-3 space-y-5 flex-col'>
                <WelcomeCard
                    user={user_name}
                    action_text="Setup Your Account"
                    action={createSerciceAction}
                    desc="The name of the business is 'money making', roll up your sleeves, let's get to work. Customers are waiting."
                    bg="bg-blue-200"
                    image={WelcomeSVG}
                />
                <div className='flex space-y-2 flex-col md:flex-row space-x-0 md:space-x-4 md:space-y-0'>
                    <InfoCard link="/dashboard/jobs" caption="Pending Jobs" num={userJobs?.pendingJobs?.filter(i => i.customer?.uid !== user.uid).length} desc="these are jobs you haven't accepted yet" primary_color="bg-darkblue_1" secondary_color="bg-darkblue_2" />
                    <InfoCard link="/dashboard/jobs" caption="Jobs In Progress" num={userJobs?.ongoingJobs?.filter(i => i.customer?.uid !== user.uid).length} desc="jobs you are currently working on" primary_color="bg-lightblue_1" secondary_color="bg-lightblue_2" />
                    <InfoCard link="/dashboard/jobs" caption="Closed Jobs" num={userJobs?.closedJobs?.filter(i => i.customer?.uid !== user.uid).length} desc="cancelled or completed jobs" primary_color="bg-lightred_1" secondary_color="bg-lightred_2" />
                </div>
                {/* <div onClick={() => setOpenModal(true)} className='p-2 px-4 cursor-pointer rounded bg-primary-500 text-white'>Services</div> */}
                <div className='h-[1px] w-auto m-2 bg-gray-300' />
                <div className='sub-title max-h-10'>Performance & Metrics</div>
                <div className='w-full h-fit'>
                    <SocialsViewCard title={{ head: 'Analytics', sub: 'your services account performance' }} showList={false} list={intervals}  >
                        <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6'>
                            <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                                <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center font-medium text-gray-600'>
                                    {abbreviateNumber(impression)}
                                </div>
                                <div className='flex flex-col items-start justify-center text-textcolor'>
                                    <div className='font-bold text-sm xl:text-normal flex space-x-2 items-center'>
                                        <ChartBarIcon className='guide-icon w-5 h-5 text-textcolor' />
                                        <span>Impressions</span>
                                    </div>
                                    <span className='text-xs xl:text-small'>The estimated number of times your service was displayed onscreen.</span>
                                </div>
                            </div>
                            <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                                <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center'>
                                    {abbreviateNumber(clicks)}
                                </div>
                                <div className='flex flex-col items-start justify-center text-textcolor'>
                                    <span className='font-bold text-sm xl:text-normal flex space-x-2 items-center'>
                                        <CursorClickIcon className='guide-icon w-5 h-5 text-textcolor' />
                                        <span>Clicks</span>
                                    </span>
                                    <span className='text-xs xl:text-small text-left'>The estimated number of times your service was clicked.</span>
                                </div>
                            </div>
                        </div>
                    </SocialsViewCard>
                </div>
                <div>
                    <SocialsViewCard showList={false} title={{ head: 'Socials', sub: 'social metrics' }} list={intervals} >
                        <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4'>
                            <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                                <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center'>
                                    {abbreviateNumber(whastapp)}
                                </div>
                                <div className='flex flex-col items-start justify-center text-textcolor'>
                                    <span className='font-bold text-sm xl:text-normal flex space-x-2 items-center'>
                                        <WhatsappIcon className='guide-icon w-4 h-4 text-textcolor' />
                                        <span>Whatsapp</span>
                                    </span>
                                    <span className='text-xs xl:text-small text-left'>The estimated number of times your whatsapp contact was clicked.</span>
                                </div>
                            </div>
                            <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                                <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center'>
                                    {abbreviateNumber(phone)}
                                </div>
                                <div className='flex flex-col items-start justify-center text-textcolor'>
                                    <span className='font-bold text-sm xl:text-normal flex space-x-2 items-center'>
                                        <PhoneIcon className='guide-icon w-5 h-5 text-textcolor' />
                                        <span>Phone</span>
                                    </span>
                                    <span className='text-xs xl:text-small text-left'>The estimated number of times your phone contact was clicked.</span>
                                </div>
                            </div>
                            <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                                <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center'>
                                    {abbreviateNumber(insta)}
                                </div>
                                <div className='flex flex-col items-start justify-center text-textcolor'>
                                    <span className='font-bold text-sm xl:text-normal flex space-x-2 items-center'>
                                        <PhoneIcon className='guide-icon w-5 h-5 text-textcolor' />
                                        <span>Instagram</span>
                                    </span>
                                    <span className='text-xs xl:text-small text-left'>The estimated number of instagram contact clicks.</span>
                                </div>
                            </div>
                        </div>
                    </SocialsViewCard>
                </div>
                <div className='h-0 lg:h-[100px] w-auto m-2' />
            </div>
            <div className='w-full lg:max-w-[35%]'>
                <div className='flex bg-secondary-white mx-3 rounded-xl'>
                    <DashboardReviewCard group={GROUP_ID} />
                </div>
                <div>
                    <PhotosGridCard doAction={editImages} imgs={getImages(services?.images?.slice(0, 6))} />
                    <div className="w-full h-[200px] flex lg:hidden" />
                </div>
            </div>

            <DashboardSliderOver bg={'#F0f0f0'} open={openModal} setOpen={setOpenModal} width={7}>
                <ServiceFormContext>
                    <DashboardFormWrapper >
                        <AddServices />
                    </DashboardFormWrapper>
                </ServiceFormContext>
            </DashboardSliderOver>
        </DashboardWrapper>
    ) :
        (
            <DashboardWrapper title="Services">
                <div className='w-full flex flex-col space-y-4 justify-center items-center py-10'>
                    <div>You need a SERVICE ACCOUNT  to create a service.</div>
                    <div className='p-2 bg-primary-500 text-white rounded-lg cursor-pointer'>See how to create a service account</div>
                </div>
            </DashboardWrapper>
        )
}

const mapStateToProps = (state) => {
    return {
        services: state.services.payload,
        current_user: state.user?.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        storeService: (data) => dispatch(store_services(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Services)