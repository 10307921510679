import React, { useEffect, useState } from 'react'
import ttwelcome from '../../../../../../assets/ttdash.svg'
import { useAuth } from '../../../../../../Components/UtilityComponents/AuthProvider'
import moment from 'moment'

const TimeTableWelcomeCard = ({ user, openSlider }) => {

    const [date, setDate] = useState(null)

    const d = new Date()

    useEffect(() => {

        setDate(d)
    }, [d.getMinutes()])

    const greeting = (indx) => {
        const hour = d.getHours();
        let result = ''

        if(indx == 0){
            const welcomeTypes = ['Good morning', 'Good afternoon', 'Good evening'];
            let welcomeText = '';
    
            if (hour < 12) welcomeText = welcomeTypes[0];
            else if (hour < 18) welcomeText = welcomeTypes[1];
            else welcomeText = welcomeTypes[2];
            result = welcomeText
        }else{
            const comment = ['Have a fruitfull day ahead', 'I hope your day has been amaizing.', 'I hope you had a great day today.'];
            let commentText = '';
    
            if (hour < 12) commentText = comment[0];
            else if (hour < 18) commentText = comment[1];
            else commentText = comment[2];
            result = commentText

        }



        return result
    }

    return (
        <div className='rounded-xl p-10 bg-secondary-lightpurple relative'>
            <div className='flex w-full flex-col xl:w-[60%] items-start text-left space-y-4'>
                <div>
                    <div className='text-2xl text-white'>{greeting(0)} , {user?.display_name.split(' ')[0]}</div>
                    <div className='text-white'>{greeting(1)}</div>
                </div>
                <div className='text-white text-5xl font-extralight'>
                    {moment(date).format('LT')}
                </div>
                <div className='w-full flex'>
                    <div onClick={() => openSlider(true)} className='py-2 px-4 bg-white rounded-lg cursor-pointer text-primary-500 font-semibold'>Write a blog post</div>
                </div>
            </div>
            <div className='absolute -top-14 right-0 w-1/2 hidden xl:flex'>
                <img src={ttwelcome} className="w-full h-full " />
            </div>
        </div>
    )
}


export default TimeTableWelcomeCard