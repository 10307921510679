import React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { useEffect } from 'react'
import { useState } from 'react'

const localizer = momentLocalizer(moment)


const JobsCalendarView = ({ data, openModal, uid, setJobForm }) => {

    const [events, setEvents] = useState([])

    useEffect(() => {
        const evnts = data?.reduce((prev, nxt) => {
            if (nxt.customer.uid !== uid) {
                prev.push({
                    id: nxt.id,
                    title: `${nxt.status}: ${nxt.customer.display_name} - ${nxt.services.map((n, i) => n.id.split('_').join(" ") + ' ')}`,
                    start: new Date(nxt.task_duration.startTime),
                    end: new Date(nxt.task_duration.endTime),
                    data: nxt,
                    resourceId: 0
                })
            }
            return prev
        }, [])
        setEvents(evnts)
    }, [data])

    useEffect(() => {

    }, [events])

    const handleSelectJob = (event) => {
        openModal(true)
        setJobForm(event.data)
    }

    return (
        <div className='h-[80vh] pt-10'>

            <Calendar
                localizer={localizer}
                events={events ? events : []}
                startAccessor="start"
                onSelectEvent={handleSelectJob}
                endAccessor="end"
                style={{ height: '100%' }}
            />
        </div>
    )
}

export default JobsCalendarView