import React from "react";
import FormPageNavigation from "../DashboardFormWrapper/FormPageNavigation";
import SaveAndExit from "../DashboardFormWrapper/SaveAndExit";
import TitleAndDescription from "../DashboardFormWrapper/TitleAndDescription";
import FormProgressBar from "../FormProgressBar";
import { useEffect } from "react";
import { useBusinessForm } from "../../../../../Components/UtilityComponents/BusinessFormContext";

const GenericDashboardFormWrapper = ({ children }, props) => {

  const form = useBusinessForm()

  return form.currentPage ? (
    <div className="w-full h-[100vh] pt-5 px-1 md:px-5 relative">
      {form.currentPage?.id !== 0 && (
        <FormProgressBar progress={form.currentPage.progress} />
      )}
      <div className="w-full  overflow-y-auto px-2 max-h-[90%] scrollbar">
        <div className="w-full flex flex-col space-y-4 md:spacey-0 md:flex-row justify-between items-start my-5">
          <TitleAndDescription services={form} />
          {form.currentPage.id !== 0 && <SaveAndExit services={form} />}
        </div>
        {children}
      </div>
      <div className="absolute bottom-10 w-full px-10">
        {form.currentPage.id !== 0 && (
          <FormPageNavigation services={form} by="business" />
        )}
      </div>
    </div>
  ) : (
    <div>NO FORM</div>
  );
};

export default GenericDashboardFormWrapper;
