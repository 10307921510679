import React from 'react'
import BillingContext from '../../../../../../../../Components/UtilityComponents/BillingContext'
import StartPayment from './StartPayment'

const Billings = () => {
    return (
        <BillingContext>
            <div>
                <StartPayment />
            </div>
        </BillingContext>
    )
}

export default Billings