import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { add_transfer_recipient, add_wallet, load_banks } from '../../../../../../../../functions/service_functions.js'
import DashboardFormField from '../../../../../../DashboardComponents/DashboardFormField/index.js'
import { DashboardFormDropDownList, DashboardPayoutOptionsDropList } from '../../../../../../DashboardComponents/DashboardFormFieldDropList'
import Loader from '../../../../../../../../assets/spinner.svg'
import { useAlert } from '../../../../../../../../Components/UtilityComponents/ErrorContext/index.js'
import { updateUser, update_user_pending_balance } from '../../../../../../../../functions/authFunctions/index.js'
import { useAuth } from '../../../../../../../../Components/UtilityComponents/AuthProvider/index.js'
import { Stages } from '../Billings/UploadID/stages.js'
import AccountView from './AccountView/index.js'
import payout_options from '../../../../../../../../assets/payout_options.json'

const WithdrawalAccount = () => {


    const [banks, setBanks] = useState(null)
    const [bank, setBank] = useState(null)
    const [accountNumber, setAccountNumber] = useState('')
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState(false)
    const [businessName, setBusinessName] = useState('')
    const [accountDetails, setAccountDetails] = useState(null)
    const [track, setTrack] = useState(null)
    const [payoutOption, setPayoutOption] = useState(null)

    const alert = useAlert()
    const auth = useAuth()

    useEffect(() => {

        let mounted = true

        if (mounted) {
            setLoading(true)
            setPayoutOption(payout_options[0])
            auth.getuser(auth.user?.uid ? auth.user?.uid : auth.user?.user?.uid).then((res) => {
                console.log('===> USER:', res)
                const _user = res

                if (res.user.business_account_activation_cleared_stage !== Stages.ACTIVATED_ACCOUNT) {
                    return setTrack('unverified_account')
                }
                load_banks().then(res => {

                    const bank_list = res[3].reduce((prev, nxt) => {
                        prev.push({
                            id: nxt.id,
                            title: nxt.name,
                            value: nxt.name,
                            code: nxt.code,
                            type: nxt.type
                        })
                        return prev
                    }, [])
                    
                    setBanks(bank_list)
                    if (!_user?.user?.payment_details) {
                        setBank(bank_list[0])
                        setTrack('show_form')
                    } else {
                        const details = _user?.user?.payment_details?.payment_account
                        console.log(details, bank_list[0])
                        setAccountDetails(_user?.user?.payment_details)
                        setPayoutOption(details.payout_schedule)
                        setBusinessName(details.account_name)
                        setDescription(details.description)
                        const f = details.details?.bank_name ? bank_list.find(i => String(details.details.bank_name) === String(i.title)): null
                        setBank(f)
                        setTrack('show_account')
                        setAccountNumber(details.account_number)
                        // setTrack('show_form')

                    }
                })

            }).finally(() => {
                setLoading(false)
            })
        }
        return () => {
            mounted = false;
        }
    }, [])

    useEffect(() => {
        console.log(accountDetails)
    }, [accountDetails])

    useEffect(() => {
        if (track !== 'show_form' || typeof bank === 'object' ) return
        load_banks().then(res => {
            const bank_list = res[3].reduce((prev, nxt) => {
                prev.push({
                    id: nxt.id,
                    title: nxt.name,
                    value: nxt.name,
                    code: nxt.code
                })
                return prev
            }, [])

            setBanks(bank_list)
            setBank(bank_list[0])
        })
    }, [track])

    const handle_submit = () => {

        if (!bank?.code || businessName.trim() === '' || accountNumber.trim() === '') {
            if (typeof parseInt(accountNumber.trim()) !== 'number') {
                return alert.setalert({ type: 'error', body: 'Invalid Bank account Number', title: "Invalid Input" })
            }
            return alert.setalert({ type: 'error', body: 'Invalid input, please check your input fields', title: "Invalid Input" })
        }

        setLoading(true)

        const data = {
            account_name: businessName,
            bank_code: bank.code,
            account_number: accountNumber,
            description: description || ""
        }

        add_transfer_recipient(data).then((res) => {
            const result = typeof res === 'string' ? JSON.parse(res) : res
            if (result[0] !== 201) {
                return alert.setalert({ type: 'error', body: result[2], title: "Invalid Bank Details" })
            }
            const subaccount = result[3]
            console.log(result)
            const togo = {
                uid: auth.user?.uid ? auth.user.uid : auth.user.user.uid,
                payment_details: {
                    account_id: subaccount.subaccount_code,
                    ref_id: subaccount.id,
                    perc: subaccount.percentage_charge,
                    currency: subaccount.currency,
                    businessName: subaccount.business_name,
                    isVerifed: subaccount.is_verified,
                    pending_balance: accountDetails?.pending_balance || 0,
                    balance: accountDetails?.balance || 0,
                    payment_account: {
                        payout_schedule: payoutOption,
                        account_number: data.account_number,
                        bank: bank.title,
                        account_name: businessName,
                        description: description || "",
                        ...subaccount
                    }
                }
            }
            updateUser(togo).then(async (res) => {
                setAccountDetails(togo.payment_details)
                setTrack('show_account')
                return alert.setalert({ type: 'success', body: "Guide wallet created", title: "account created" })
            })
        }).then(() => {
            update_user_pending_balance(auth.user?.uid ? auth.user?.uid : auth.user?.user?.uid)
        }).finally(() => {
            setLoading(false)
        })
    }

    switch (track) {
        case "show_form":
            return (
                <div className='w-full flex flex-col p-5'>
                    <div className='space-y-4 px-5'>
                        <div className='text-2xl max-w-xs'>Setup your withdrawal Account</div>
                        <div className='text-sm text-light_textcolor max-w-2xl'>this is the account you get paid into on Guide. This must be one of the AFRICAN bank accounts available in the list. Guide does not have support for non-african accounts at the moment.</div>
                    </div>
                    <DashboardFormField placeholder="Aki Cleaners" name="Account Name" text={businessName} setText={setBusinessName} />

                    <div className='flex w-full flex-col lg:flex-row lg:space-x-2 lg:items-center lg:justify-between'>
                        <DashboardFormDropDownList name="Select Bank" selectedInterval={bank} setSelectedInterval={setBank} list={banks} />
                    </div>
                    <DashboardFormField placeholder="0XXXXXXXXXX" name="Account Number" text={accountNumber} setText={setAccountNumber} />

                    <div className='flex w-full flex-col lg:flex-row lg:space-x-2 lg:items-center lg:justify-between'>
                        <DashboardPayoutOptionsDropList name="Payout option" selectedInterval={payoutOption} setSelectedInterval={setPayoutOption} list={payout_options} />
                    </div>

                    <DashboardFormField placeholder="describe your business in a few words." name="Description" multiline={true} text={description} setText={setDescription} />

                    <div className='flex w-full flex-col lg:flex-row lg:space-x-2 lg:items-center lg:justify-between'>

                        <div onClick={() => !loading && handle_submit()} className={`h-12 cursor-pointer flex justify-center w-full items-center rounded-xl  mx-2 my-4 space-x-2  ${loading ? 'bg-gray-300' : 'bg-green-600 hover:bg-green-500'}`}>
                            {loading && <img src={Loader} className='w-4 h-4 animate-spin' />}
                            <span className="font-medium text-white cursor-pointer">Save</span>
                        </div>
                    </div>
                </div>
            )
        case "show_account":
            return (
                <div className='w-full p-5'>
                    <AccountView account={accountDetails} uid={auth.user?.uid ? auth.user?.uid : auth.user?.user?.uid} edit={setTrack} />
                </div>
            )
        case "unverified_account":
            return (
                <div className='w-full h-full flex flex-col space-y-4 justify-center items-center'>
                    <img src="https://w7.pngwing.com/pngs/879/254/png-transparent-computer-icons-identity-verification-service-the-iconfactory-social-icons-miscellaneous-logo-internet.png"
                        className='w-24 h-auto object-cover' />
                    <div className='text-lg font-medium text-center'>Account Not Verified</div>
                    <div className='max-w-md text-sm text-gray-500 text-center'>You cannot add a withdrawal account yet, until you have verified your account. see the <strong>Verify Account</strong> settings menu</div>
                </div>
            )
        default:
            return (<div className='w-full h-full flex flex-col space-y-4 justify-center items-center'>
                <img src={Loader} className='w-10 h-10 animate-spin' />
                <div className='text-gray-400 text-lg'>loading...</div>
            </div>)

    }

}

export default WithdrawalAccount