import React, { useEffect, useState } from 'react'
import DashboardFormField from '../../../../../DashboardComponents/DashboardFormField'
import FormChecker from '../../../../../DashboardComponents/FormComponents/FormChecker'
import MapUI from '../../../../../DashboardComponents/FormComponents/MapUI'
import SearchAddressModal from '../../../../../DashboardComponents/FormComponents/MapUI/SearchAddressModal'
import TipsCard from '../../../../../DashboardComponents/FormComponents/TipsCard'

const LocationForm = ({ services }) => {

    const [search, setSearch] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [position, setPosition] = useState(null)
    const [address, setAddress] = useState([])
    const [hideOnListing, setHideOnListing] = useState(false)


    useEffect(() => {
        if (!Array.isArray(services.stored_services?.location)) {
            setAddress(services.stored_services?.location)
            setPosition(services.stored_services?.location?.latlon)
            setSearch(services.stored_services?.location?.address)
            setHideOnListing(services.stored_services?.location?.hide_on_listing)
        }
    }, [])


    useEffect(() => {
        setIsOpen(isOpen)
    }, [isOpen])

    useEffect(() => {
        if(position){
            services.updateLocation({
                ...address,
                latlon: position,
                hide_on_listing: hideOnListing
            })
        }
    }, [address, position, hideOnListing])


    const searchPlaces = async (addr) => {
        setSearch(addr.address)
        setPosition(addr.latlon)
        setAddress(addr)
    }

    const closeOpened = (e) => {
        setIsOpen(e)
    }

    return (
        <div className='flex h-auto items-start justify-start w-full'>
            <SearchAddressModal home={null} handleAction={searchPlaces} address={search} open={isOpen} setOpen={closeOpened} />
            <div className='py-5 w-full md:w-[60%]'>
                <div>
                    <div className='text-sm max-w-lg px-3'>For a more percise location Information, you can zoom in and move the Map pin around to set exactly your location. </div>
                    <div onClick={() => setIsOpen(true)} className='max-w-lg'>
                        <DashboardFormField name="Location" placeholder="enter your address" text={search} setText={setSearch} />
                    </div>
                </div>
                <MapUI setPosition={setPosition} position={position} />
                <FormChecker selected={hideOnListing} setSelected={setHideOnListing} text="Hide Property address on listing" />
            </div>
            <div className='hidden md:w-[40%] h-full md:flex justify-start'>
                <TipsCard />
            </div>
        </div>
    )
}

export default LocationForm