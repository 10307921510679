import { BookOpenIcon, EmojiHappyIcon, SearchIcon } from '@heroicons/react/outline'
import React from 'react'
import Group from '../../../assets/Group 650.svg'
import FeatureCrad from '../Components/FeatureCard'

const AppFeaturesSection = () => {
    return (
        <div className='w-full items-center flex flex-col'>
            <div className='w-full flex lg:w-[70%] '>
                <div className='hidden 2xl:flex justify-center items-center'>
                    <img src={Group} alt="back_drop_image" className='w-full h-auto' />
                </div>
                <div className='text-center 2xl:text-left'>
                    <div >
                        <div className='font-bold text-bl text-2xl text-dark_textcolor'>Why Put Your Business On Guide</div>
                        <div className='font-light text-textdark text-lg pb-5'>Here are 3 reasons why Guide is perfect for your business. </div>
                    </div>
                    <div className='text-left'>
                        <FeatureCrad
                            icon={<SearchIcon className='w-8 h-8 stroke-1' style={{ color: '#FA404B' }} />}
                            title="Start Selling In Your Neighbourhood."
                            caption="Guide reccomends your business to people in your neighborhood using your location, reach more customers in your area"
                            color_dark="#FA404B"
                            color_light="#FFECEF"
                        />
                        <FeatureCrad
                            icon={<BookOpenIcon className='w-8 h-8 stroke-1' style={{ color: '#5684FF' }} />}
                            title="Tailored To Make Your Business Successful."
                            caption="Guide actively makes sure your business gets seen by people who needs your services, make more sales when you put your business on Guide."
                            color_dark="#5684FF"
                            color_light="#E3E3FF"
                        />
                        <FeatureCrad
                            icon={<EmojiHappyIcon className='w-8 h-8 stroke-1' style={{ color: '#429861' }} />}
                            title="Make Money With Your Skills"
                            caption="Get paid by customers for your skills, open an business account and start rendering your services "
                            color_dark="#429861"
                            color_light="#DAFFE8"
                        />
                    </div>

                </div>
            </div>
            <div className='flex flex-col 2xl:flex-row lg:w-[70%] w-full justify-center 2xl:space-x-10 my-20 items-center text-center 2xl:text-left'>
                <div className='w-full 2xl:w-[40%] 2xl:p-5 py-10 border-b-2 2xl:border-r-2 2xl:border-b-0 border-gray-400'>
                    <div className='font-bold text-textdark text-2xl'>Designed with simplicity, Guide Dashboard is simple to use.</div>
                </div>
                <div className='w-full 2xl:w-[40%] py-10 2xl:p-5'>
                    <div className='font-light text-textdark text-lg'>You need something done, do it on Guide, you can get started in just a few clicks, thats all it takes..</div>
                </div>
            </div>
            
            <div >
                <div className='font-bold text-bl text-2xl text-dark_textcolor'>Guide Business Dashboard Features</div>                        
            </div>
            <div className='text-left flex flex-col 2xl:flex-row 2xl:items-center items-start my-20'>
                
                <FeatureCrad
                    icon={<SearchIcon className='w-8 h-8 stroke-1' style={{ color: '#FA404B' }} />}
                    title="Business Metrics Insight"
                    caption="Get realtime metrics on your business, eg Impressions, Clicks, Customers Statistics"
                    color_dark="#FA404B"
                    color_light="#FFECEF"
                />
                <FeatureCrad
                    icon={<BookOpenIcon className='w-8 h-8 stroke-1' style={{ color: '#5684FF' }} />}
                    title="Performance Reccomendation"
                    caption="Get helpfull recommendations on how to make your business more successful and advice on expansion opportunities."
                    color_dark="#5684FF"
                    color_light="#E3E3FF"
                />
                <FeatureCrad
                    icon={<EmojiHappyIcon className='w-8 h-8 stroke-1' style={{ color: '#429861' }} />}
                    title="Business Loan Request Assistance"
                    caption="Get assistance to secure SME Loans with more success and better terms with a verifiable business performance report"
                    color_dark="#429861"
                    color_light="#DAFFE8"
                />
            </div>
        </div>
    )
}

export default AppFeaturesSection