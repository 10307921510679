import React, { useEffect } from 'react'

const TitleAndDescription = ({services}) => {

    useEffect(() => {

    },[services.currentPage])

  return (
    <div className='space-y-4'>
        <div className='text-2xl max-w-xs'>{services.currentPage.pageTitle}</div>
        <div className='text-sm text-light_textcolor max-w-sm'>{services.currentPage.pageDescription}</div>
    </div>
  )
}

export default TitleAndDescription