import { MinusIcon, PlusIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'

const Accodion = () => {
    return (
        <div>
            <TabItem close={false} />
            <TabItem close={true} />
            <TabItem close={true} />
            <TabItem close={true} />
        </div>
    )
}

export const TabItem = ({ children, title, setOpen, open }) => {


    return (
        <div className='ease_transition w-full h-auto text-left my-0'>
            <div className={`flex items-start justify-between`}>
                <div className='w-full'>
                    <div className='flex w-full justify-between items-center h-20 lg:h-10 bg-primary-300 px-5 py-5 lg:py-10 lg:px-10 shadow-sm border-b-2 border-gray-300'>
                        <div className='w-[80%] font-bold text-sm lg:text-lg text-dark_textcolor'>{title}</div>

                        <div onClick={() => setOpen()}>
                            {
                                open ?
                                    <MinusIcon className='w-5 h-5 cursor-pointer' />
                                    :
                                    <PlusIcon className='w-5 h-5 cursor-pointer' />
                            }
                        </div>
                    </div>
                    {
                        open && 
                        <div className='w-full py-0 px-10 lg:px-20  bg-primary-200 text-justify font-light text-textdark text-sm lg:text-base 2xl:text-md  pb-5'>
                            {children}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Accodion