import {
  PencilIcon,
  PlusIcon,
  TrashIcon,
  UploadIcon,
} from "@heroicons/react/outline";
import React, { useEffect, useRef, useState } from "react";
import TipsCard from "../../../../../Dashboard/DashboardComponents/FormComponents/TipsCard";

const ImagesForm = ({ business }) => {
  const [search, setSearch] = useState("");
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (business.stored_business) {
      setImages(business.stored_business?.images);
    }
  }, []);

  useEffect(() => {
    business.updateImages(images);
  }, [images]);

  const changeImage = (index, data) => {
    const newImages = [];
    images.map((img, indx) => {
      if (indx === index) {
        newImages.push(data);
      } else {
        newImages.push(img);
      }
    });
    setImages(newImages);
  };

  const deleteImage = (index) => {
    const newImages = [];
    images.map((img, indx) => {
      if (indx !== index) {
        newImages.push(img);
      }
    });
    setImages(newImages);
  };

  return (
    <div className="flex scrollbar items-start justify-start w-full ">
      <div className="py-5 w-full md:w-[60%] ">
        <div>
          <div className="text-sm max-w-lg px-3">
            Take and upload images, use your phone or any camera devices. Don;t
            forget to take photos that highlight important features of your
            apartment.{" "}
          </div>
          <div className="w-full md:max-w-lg my-5">
            {images?.length <= 0 && (
              <ImagesEmptyView
                currentFiles={images}
                setCurrentFiles={setImages}
              />
            )}
            {images?.length > 0 && (
              <div className="h-auto">
                <ImageView
                  image={images[0]}
                  img={images[0]}
                  index={0}
                  changeImage={changeImage}
                  deleteImage={deleteImage}
                />
                <div className=" grid gap-2 mt-2 grid-cols-1 sm:grid-cols-2 overflow-y-auto h-[60%] pb-[100px]">
                  {images?.length > 1 &&
                    images.map((img, indx) => {
                      if (indx > 0) {
                        return (
                          <SmallerImageView
                            key={indx}
                            img={img}
                            index={indx}
                            changeImage={changeImage}
                            deleteImage={deleteImage}
                          />
                        );
                      }
                    })}
                  <SmallerImagesEmptyView
                    currentFiles={images}
                    setCurrentFiles={setImages}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="hidden md:w-[40%] h-full md:flex justify-start">
        {/* <TipsCard /> */}
      </div>
    </div>
  );
};

const ImagesEmptyView = ({ currentFiles, setCurrentFiles }) => {
  const imgRef = useRef(null);

  const onDragEnter = () => {
    imgRef.current.classList.remove("bg-[#D9D9D9]");
    imgRef.current.classList.add("bg-[#CBC9C9]");
  };

  const onDragLeave = () => {
    imgRef.current.classList.remove("bg-[#CBC9C9]");
    imgRef.current.classList.add("bg-[#D9D9D9]");
  };

  const onDrop = () => {
    imgRef.current.classList.remove("bg-[#CBC9C9]");
    imgRef.current.classList.add("bg-[#D9D9D9]");
  };

  const onDropFiles = (e) => {
    const newFiles = e.target.files;

    const updatedFiles = [...currentFiles];
    Array.from(newFiles).map((f, i) => {
      updatedFiles.push({ src: URL.createObjectURL(f), file: f });
    });
    setCurrentFiles(updatedFiles);
  };

  return (
    <div
      ref={imgRef}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      className="w-full md:max-w-lg md:w-auto flex flex-col justify-center items-center bg-[#D9D9D9] border-2 border-dashed rounded-xl aspect-video relative border-[#8F8F8F] "
    >
      <div className=" flex justify-center items-center py-2 px-10 bg-red-500 hover:bg-red-700 cursor-pointer rounded-md text-white space-x-2">
        <UploadIcon className="guide-icon" />
        <div>Click To Upload</div>
      </div>
      <div className="mt-4">Or drag in an image</div>
      <input
        type="file"
        accept="image/png, image/gif, image/jpeg"
        className=" absolute top-0 w-full h-full opacity-0"
        onChange={onDropFiles}
        multiple
      />
    </div>
  );
};

const ImageView = ({ img, index, changeImage, deleteImage }) => {
  const imageSize = img.file?.size / 1024 < 1000 || 0;

  const editImage = (e) => {
    const newFile = e.target.files[0];
    changeImage(index, { src: URL.createObjectURL(newFile), file: newFile });
  };

  const handleDeleteImage = () => {
    deleteImage(index);
  };

  return (
    <div
      className={`w-full md:max-w-lg md:w-auto flex flex-col justify-center items-center border-2 bg-[#D9D9D9] rounded-xl aspect-video overflow-hidden relative ${
        !imageSize ? "border-red-400" : "border-[#D9D9D9]"
      } `}
    >
        <div className="text-xs font-semibold text-green-600 bg-green-300 p-1 rounded-full absolute top-2 left-2">thumbnail</div>
      <img
        src={img.src ? img.src : img}
        className="w-full h-full aspect-video object-cover"
      />
      <div className="flex items-center space-x-2 absolute top-3 right-3">
        <div className="p-2 bg-white shadow-md rounded-full cursor-pointer hover:bg-green-400 hover:text-white">
          <PencilIcon className="guide-icon w-5 h-5 relative overflow-hidden" />
          <input
            accept="image/png, image/gif, image/jpeg"
            type="file"
            className="absolute top-0 w-full h-full opacity-0"
            onChange={editImage}
          />{" "}
        </div>
        <div
          onClick={() => handleDeleteImage()}
          className="p-2 bg-white shadow-md rounded-full cursor-pointer hover:bg-red-400 hover:text-white"
        >
          <TrashIcon className="guide-icon w-5 h-5 relative overflow-hidden" />
        </div>
      </div>

      {!imageSize && (
        <div className="text-xs font-semibold text-red-600 bg-red-300 p-1 rounded-full absolute bottom-5 mx-auto">
          thumbnail must be below 1MB -{" "}
          {parseFloat(img.file?.size / 1024 / 1024).toFixed(1)} MB
        </div>
      )}
    </div>
  );
};

const SmallerImageView = ({ img, index, changeImage, deleteImage }) => {
  const editImage = (e) => {
    const newFile = e.target.files[0];
    changeImage(index, { src: URL.createObjectURL(newFile), file: newFile });
  };

  const handleDeleteImage = () => {
    deleteImage(index);
  };

  return (
    <div className="w-auto flex flex-col justify-center items-center bg-[#D9D9D9] rounded-xl aspect-video overflow-hidden relative ">
      <img
        src={img.src ? img.src : img}
        className="w-full h-full aspect-video object-cover object-center"
      />
      <div className="flex items-center space-x-2 absolute top-3 right-3">
        <div className="p-2 bg-white shadow-md rounded-full cursor-pointer hover:bg-green-400 hover:text-white relative overflow-hidden">
          <PencilIcon className="guide-icon w-5 h-5 " />
          <input
            accept="image/png, image/gif, image/jpeg"
            type="file"
            className="absolute top-0 w-full h-full opacity-0"
            onChange={editImage}
          />{" "}
        </div>
        <div
          onClick={() => handleDeleteImage()}
          className="p-2 bg-white shadow-md rounded-full cursor-pointer hover:bg-red-400 hover:text-white"
        >
          <TrashIcon className="guide-icon w-5 h-5" />
        </div>
      </div>
    </div>
  );
};

const SmallerImagesEmptyView = ({ currentFiles, setCurrentFiles }) => {
  const imgRef = useRef(null);

  const onDragEnter = () => {
    imgRef.current.classList.remove("bg-[#D9D9D9]");
    imgRef.current.classList.add("bg-[#CBC9C9]");
  };

  const onDragLeave = () => {
    imgRef.current.classList.remove("bg-[#CBC9C9]");
    imgRef.current.classList.add("bg-[#D9D9D9]");
  };

  const onDrop = () => {
    imgRef.current.classList.remove("bg-[#CBC9C9]");
    imgRef.current.classList.add("bg-[#D9D9D9]");
  };

  const SelectFile = (e) => {
    const newFile = e.target.files[0];
    setCurrentFiles([
      ...currentFiles,
      { src: URL.createObjectURL(newFile), file: newFile },
    ]);
  };

  return (
    <div
      ref={imgRef}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      className="w-auto flex hover:bg-[#D9D9D9]/50 cursor-pointer flex-col justify-center items-center bg-[#D9D9D9] border-2 border-dashed rounded-xl aspect-video border-[#8F8F8F] relative"
    >
      <PlusIcon className="guide-icon" />
      <div className="mt-4">Add More</div>
      <input
        type="file"
        accept="image/png, image/gif, image/jpeg"
        className="absolute top-0 w-full h-full opacity-0"
        onChange={SelectFile}
      />
    </div>
  );
};

export default ImagesForm;
