import { CalendarIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import DateAndTimePicker from '../../DateTimePicker'
import NumberField from '../../NumberField'
import { useJobContext } from '../../UtilityComponents/JobsContext'
import { useOrder } from '../../UtilityComponents/PlaceOrderContext'

const GetTaskDateAndTime = () => {

    const order = useOrder()
    const today = new Date()
    const jobs = useJobContext()
    


    const [schedules, setSchedules] = useState(null)
    const [startTime, setStartTime] = useState(today)
    const [endTime, setEndTime] = useState(today)
    const [hours, setHours] = useState(1)
    const [price, setPrice] = useState(0)
    const [jobList, setJobList] = useState([])

    useEffect(() => {
        if (order.selectedDateAndTime?.startTime) {
            setStartTime(order.selectedDateAndTime.startTime)
            setEndTime(order.selectedDateAndTime.endTime)
        }
       loadUp()
        setPrice(order.totalCost)
        return () => {

        }
    }, [])

    const loadUp =async () => {
        await jobs.loadJobs(order.actors.seller.uid)
    }

    useEffect(() => {
        setJobList(jobs.allJobs)
    },[jobs.allJobs])

    useEffect(() => {
        setEndTime(new Date(startTime.valueOf()))
    },[startTime])

    useEffect(() => {
        endTime.setHours(startTime.getHours() + hours)
        order.setSelectedDateAndTime({
            startTime,
            endTime,
            hours
        })
        hours !== 0  && order.setTotalCost(price * hours)
    },[startTime, endTime, hours])


    const handleDisableStartDate =({activeStartDate, date, view }) => {
        const bookedDays = jobList?.some(j => {
            const trasitStart = new Date(j.task_duration.startTime)
            const trasitEnd = new Date(j.task_duration.endTime)
            // trasitEnd.setDate(trasitEnd.getDate() - 1)
            // trasitStart.setDate(trasitStart.getDate() - 1)
            return date >= trasitStart  && date <= trasitEnd
        })

        return bookedDays || (date < today)
    }

    const handleDisableEndDate =({activeStartDate, date, view }) => {
        const bookedDays = jobList?.some(j => {
            const trasitStart = new Date(j.task_duration.startTime)
            const trasitEnd = new Date(j.task_duration.endTime)
            // trasitEnd.setDate(trasitEnd.getDate() - 1)
            // trasitStart.setDate(trasitStart.getDate() - 1)
            return date >= trasitStart  && date <= trasitEnd
        })
        let condition = date < today
        if(startTime){
            condition = date < startTime
        }

        condition = condition || bookedDays

        return condition 
    }


    return (
        <div>
            <div className='flex items-start space-x-4  mx-5 p-5 rounded-xl'>
                <div className='flex flex-col text-left text-gray-500'>
                    <span className='font-medium'>Choose a convineient time</span>
                    <span className='text-sm '>specify the date and time you want this service. </span>
                    <div className='flex flex-col space-y-2 items-start justify-between md:space-x-2'>
                        <div className='pt-3'>
                            <DateAndTimePicker time={startTime} setTime={setStartTime} allDates={schedules} handleDsiabledDates={handleDisableStartDate} />
                        </div>
                        {/* <span> to </span> */}
                        <div className='pt-3'>
                            <div>How many hours will this Job take?</div>
                            <NumberField title="" setActiveState={setHours} activeState={hours} none_zero={true} />
                            {/* <DateAndTimePicker time={endTime} setTime={setEndTime} allDates={schedules} handleDsiabledDates={handleDisableEndDate} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetTaskDateAndTime