import { STORE_BUSINESS, UPDATE_BUSINESS } from "../../actionTypes/businessActionTypes"


export const store_business = (data) => {
    return (dispatch) => {
        dispatch({
            type: STORE_BUSINESS,
            payload: data,
            error: null
        })
    }
}

export const update_business = (data) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_BUSINESS,
            payload: data,
            error: null
        })
    }
}