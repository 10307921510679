import { UPDATE_ACCOUNT } from "../../actionTypes/acountActionTypes"

export const update_accounts = (data) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_ACCOUNT,
            payload: data,
            error: null
        })
    }
}

