import { ChatIcon, DotsHorizontalIcon, LocationMarkerIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import DropDownMenu from '../../../../../Components/DropDownMenu'
import ImageSlider from '../../../../../Components/ImageSlider'
import Modal from '../../../../../Components/Modal'
import { useChat } from '../../../../../Components/UtilityComponents/ChatsContext'
import { update_service_order_status } from '../../../../../functions/service_functions.js'
import CancelOrder from '../CancelOrder'
import { UpdatesStatusTypes } from '../JobsMenu/data'
import JobUpdates from '../JobUpdates'
import RequestReview from '../RequestReview'
import RequestExtension from '../RequestExtension'
import Loader from '../../../../../assets/spinner.svg'
import RequestCompletion from '../RequestCompletion'
import ImageSlider_v2 from '../../../../../Components/imageSlider_v2'

const Jobdetails = ({ job , swap}) => {

    const [openImageSlider, setOpenImageSlider] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)
    const [loadingAction, setLoadingAction] = useState(false)
    const [reviewView, setReviewView] = useState(null)
    const [extrensionView, setExtrensionView] = useState(null)
    const [cancelOrderView, setCancelOrderView] = useState(null)
    const [requestCompletion, setOpenRequestCompletion] = useState(null)
    const chat = useChat()

    const [jobData, setJobData] = useState(null)

    useEffect(() => {
        setJobData(job)
    }, [])

    const task_updates = jobData?.task_updates.sort((a, b) => new Date(a?.updated_at) < new Date(b?.updated_at) ? 1 : -1)

    const sortByTimeDesc = async (data) => {
        return await data?.sort((a, b) => new Date(a?.updated_at) < new Date(b?.updated_at) ? 1 : -1)
    }

    const actionList = [
        {
            id: 1,
            title: "Request Review",
            action: () => setReviewView({}),
            disabled: jobData?.status !== UpdatesStatusTypes.COMPLETED
        },
        {
            id: 2,
            title: "Request Extension",
            action: () => setExtrensionView({}),
            disabled: ([UpdatesStatusTypes.CANCELED, UpdatesStatusTypes.COMPLETED].includes(jobData?.status))
        },
        {
            id: 3,
            title: "Cancel Order",
            color: 'text-red-500',
            action: () => setCancelOrderView({}),
            disabled: ([UpdatesStatusTypes.PENDING, UpdatesStatusTypes.COMPLETED].includes(jobData?.status))

        }
    ]

    const thisUser = {
        photoURL: jobData?.customer.photoURL,
        uid: jobData?.customer.uid,
        author: jobData?.customer.display_name
    }

    const sendMessage = () => {
        chat.startChat(thisUser)
    }

    const updateJob = async (status, extra = null) => {
        setLoadingAction(status)
        const data = {
            status: status,
            tasks: jobData?.task_updates,
            doc: jobData?.id,
            customer: jobData?.customer,
            seller: jobData?.seller,
            time: new Date()
        }

        if (extra !== null) {
            data['data'] = extra
        }

        return await update_service_order_status(data).then(async (res) => {
            setJobData({
                ...jobData,
                status: extra ? jobData.status : status,
                task_updates: await sortByTimeDesc([
                    JSON.parse(res),
                    ...jobData.task_updates
                ])
            })
            return res
        }).then(() => {
            const moreData = {
                sender: data.seller,
                reciever: data.customer,
                message: {
                    task_id: data.doc,
                    type: data.status,
                    data: extra ?? null,
                    service_id: jobData.task_info.service_id
                },
                type: data.status
            }
            if ([UpdatesStatusTypes.REQUESTING_REVIEW, UpdatesStatusTypes.REQUESTING_EXTENSION, UpdatesStatusTypes.REQUESTING_COMPLETED].includes(status)) {
                moreData['message'] = {
                    ...moreData.message,
                    seller: data.seller,
                    customer: data.customer,
                    task_duration: jobData.task_duration
                }
            }
            chat.postTaskUpdate(moreData)
            if(status === UpdatesStatusTypes.STARTED){
                swap( jobData.created_at, UpdatesStatusTypes.STARTED )
            }

            if(status === UpdatesStatusTypes.CANCELED || status === UpdatesStatusTypes.COMPLETED){
                swap(jobData.created_at, status)
            }

        }).finally(() => {
            setLoadingAction(false)
        })
    }

    return jobData ? (
        <div className='px-10 space-y-4 overflow-y-auto h-full scrollbar'>
            <div className='flex items-center justify-between'>
                <div className='flex items-center space-x-5'>
                    <div onClick={() => sendMessage()} className='w-14 aspect-square relative cursor-pointer'>
                        <img src={jobData.customer.photoURL} className='w-full aspect-square rounded-full object-cover' />
                        <div className=' absolute bottom-0 right-0 p-1 flex justify-center bg-white items-center rounded-full '>
                            <ChatIcon className='w-4 h-4' />
                        </div>
                    </div>
                    <div className='flex flex-col text-left'>
                        <div className='font-bold text-gray-500 text-base'>{jobData.customer.display_name}</div>
                        <div className='font-light text-gray-500 text-sm'>client</div>
                    </div>
                </div>
                <div className='flex items-center space-x-4'>
                    {
                        jobData.status === UpdatesStatusTypes.PENDING &&
                        <div className='flex items-center space-x-4'>
                            <div onClick={() => updateJob(UpdatesStatusTypes.STARTED)} className='py-2 px-4 bg-green-500 text-white font-medium rounded-md cursor-pointer flex items-center space-x-2'>
                                {(loadingAction === UpdatesStatusTypes.STARTED) && <img src={Loader} className='w-4 h-4 animate-spin' />}
                                <span>Accept</span>
                            </div>
                            <div onClick={() => updateJob(UpdatesStatusTypes.REJECTED)} className='py-2 px-4 bg-red-500 text-white font-medium rounded-md cursor-pointer flex items-center space-x-2'>
                                {(loadingAction === UpdatesStatusTypes.REJECTED) && <img src={Loader} className='w-4 h-4 animate-spin' />}
                                <span>Decline</span>
                            </div>
                        </div>
                    }
                    {
                        jobData.status === UpdatesStatusTypes.STARTED &&
                        <div className='flex items-center space-x-4'>
                            <div onClick={() => setOpenRequestCompletion({})} className='py-2 px-4 bg-green-500 text-white font-medium rounded-md cursor-pointer flex items-center space-x-2'>
                                <span>Mark Completed</span>
                            </div>
                        </div>
                    }
                    <div className='relative'>
                        <div>
                            {
                                jobData.status === UpdatesStatusTypes.CANCELED ?
                                    <span className='text-gray-300 text-lg font-medium italic'>CANCELLED</span>
                                    :
                                    <DotsHorizontalIcon onClick={() => setOpenMenu(!openMenu)} className='guide-icon' />

                            }
                        </div>
                        {(openMenu && !(jobData.status === UpdatesStatusTypes.CANCELED)) && <div><DropDownMenu list={actionList} /></div>}
                    </div>
                </div>
            </div>
            <div className='px-3 pt-10 space-y-4'>
                <div className='flex items-center space-x-4 text-gray-500 hover:underline cursor-pointer'>
                    <LocationMarkerIcon className='w-5 h-5' />
                    <div>{jobData.task_info.location.address}</div>
                </div>
                <div className='flex items-center space-x-4'>
                    {
                        jobData.services.map((item, indx) => {
                            return <div key={indx} className='text-sm text-white bg-primary-500 rounded-full py-1 px-2 font-medium'>{item.id.split("_").join(" ")}</div>
                        })
                    }
                </div>
                <div className='space-y-2 text-gray-500'>
                    <div className='text-lg font-bold'>Specifications</div>
                    <div>{jobData.task_info.specifications}</div>
                    <div className='flex items-center space-x-4 pt-5'>
                        {
                            jobData.task_info.images?.slice(0, 3).map((img, indx) => {
                                return <img onClick={() => setOpenImageSlider(true)} src={img} key={indx} className="w-24 object-cover aspect-square rounded-xl shadow-md cursor-pointer" />
                            })
                        }
                        {
                            jobData.task_info.images?.length > 3 &&
                            <div onClick={() => setOpenImageSlider(true)} className="w-24  aspect-square rounded-xl shadow-md text-2xl text-white bg-gray-600 cursor-pointer flex justify-center items-center">
                                + {jobData.task_info.images.length - 3}
                            </div>
                        }
                    </div>
                </div>
                <div className='space-y-4 pt-10'>
                    <div className='text-lg font-medium'>Task Updates</div>
                    {
                        task_updates?.map((task, index) => {
                            return <JobUpdates job={task} info={{ ...jobData.task_info, ...jobData.task_duration, id: jobData.id, created_at: jobData.created_at }} key={index} />
                        })
                    }
                </div>

            </div>
            {openImageSlider &&
                <Modal open={openImageSlider} setOpen={setOpenImageSlider} topPad={10}>
                    <div className='h-[40%]'>
                        <ImageSlider_v2 images={jobData.task_info.images} />
                    </div>
                </Modal>}
            {reviewView && <RequestReview setOpen={setReviewView} handleAction={updateJob} />}
            {requestCompletion && <RequestCompletion setOpen={setOpenRequestCompletion} handleAction={updateJob} />}
            {extrensionView && <RequestExtension setOpen={setExtrensionView} handleAction={updateJob} />}
            {cancelOrderView && <CancelOrder setOpen={setCancelOrderView} handleAction={updateJob} />}
        </div>
    ) : null
}

export default Jobdetails