import { ChatAltIcon, TruckIcon, UserIcon } from '@heroicons/react/outline'
import { LocationMarkerIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FiveStar from '../../../../Components/FiveStar'
import ImpressionClickTrackerHOC from '../../../../Components/ImpressionClickTrackerHOC'
import { loadReviewsStars, updateServiceImpressions } from '../../../../functions/service_functions.js'
import { RatingItem } from '../../ServiceProviderProfile'

const ServiceProviderItem = ({ service, category }) => {

    const [rating, setRating] = useState(0)
    const [skill, setSkill] = useState(null)

    useEffect(() => {
        loadReviewsStars({ gid: service.id }).then((score) => {
            setRating(score.score)
        }).then(() => {
            setSkill(service.skillsets.filter(i => category.includes(i.id))[0])
        })
    }, [])

    useEffect(() => {
        service.skillsets.filter(i => {
            console.log(category.includes(i.id))
        })
    }, [skill])

    const handleImpression = (type = 'view') => {
        updateServiceImpressions({
            id: service.id,
            type: type
        })
    }

    const messageToSeller = `Hello, i'm contacting you from Guide Services, i need to get your help through your ${category} service `


    return (
        <ImpressionClickTrackerHOC onImpression={handleImpression}>
            <div className='w-full text-left space-y-4 ring-black/10 ring-1 bg-white rounded-xl shadow-lg '>
                <div className='w-full p-2 px-0 md:p-5'>
                    <div className='flex flex-col md:flex-row items-center md:items-start space-x-4 w-full'>
                        <div className='w-20 h-20'>
                            <img src={service.user.photoURL} className='w-full h-full rounded-full object-cover' />
                        </div>
                        <div className='flex justify-between items-start pt-3 w-[90%]'>
                            <div className='w-full '>
                                <div className='flex justify-between'>
                                    <div className='justify-between flex flex-col md:items-start '>
                                        <div className=' text-lg md:text-3xl font-semibold'>{service.user.display_name}</div>
                                        <FiveStar value={rating | 0} count={service.ratings?.length | 0} />
                                    </div>
                                    <div className='flex w-32 items-end'>
                                        <div className='text-2xl md:text-3xl font-extrabold'>{skill?.rates.currency.symbol} {skill?.rates.rates}</div>
                                        <div className='text-lg font-light text-gray-500'> / hr</div>
                                    </div>
                                </div>
                                <div className='py-4 space-x-4 flex items-center flex-wrap'>
                                    {
                                        service.skillsets.map((skill, indx) => {
                                            return <div className='text-xs font-light w-fit bg-primary-300 text-primary-500 rounded-full py-1 px-2' key={indx}>{skill.id.split('_').join(" ")}</div>
                                        })
                                    }
                                </div>
                                {
                                    service.location.hide_on_listing ?
                                        <div className='flex items-center space-x-4 text-sm'>
                                            <LocationMarkerIcon className='w-5 h-5' />
                                            <div>{service.location.address}</div>
                                        </div> : null
                                }
                                <div className='flex flex-col space-y-2 my-4'>
                                    <span className='text-lg font-semibold'>How can i help?</span>
                                    <span className='px-2'>{service.description}</span>
                                </div>
                                {service?.ratings?.length > 0 &&
                                    <div className=' p-3 md:p-5 bg-primary-300 w-full rounded-lg my-4'>
                                        <div className='px-5 font-semibold'>Customer Reviews ({service.ratings?.filter(rate => rate.reviewBody.length > 0).length})</div>
                                        <div className='py-3 w-full text-justify px-10 divide-y-2'>
                                            {
                                                service?.ratings && service?.ratings.sort((a, b) => a.datePublished < b.datePublished ? 1 : -1).slice(0, 2).map((rating, index) => {
                                                    return rating.reviewBody.length > 0 && <RatingItem key={index} rating={rating} />
                                                })
                                            }
                                        </div>
                                    </div>}
                                {
                                    (service.vehicles?.length > 0 && service.vehicles[0].id !== 'None') &&
                                    <div className='flex items-center space-x-2'>
                                        <TruckIcon className='w-5 h-5' />
                                        <span className='font-bold'>Vehicles :  {
                                            service.vehicles.map((n, i) => `${n.title}${(i + 1) !== service.vehicles?.length ? ', ' : ''}`)
                                        } </span>
                                    </div>}
                            </div>

                        </div>
                    </div>

                    <div className='my-5 flex justify-around w-full border-t-[1px] py-5'>
                        {/* <div className='flex space-x-2 items-center'>
                            <ChatAltIcon className='w-5 h-5' />
                            <span className='text-sm md:text-base'>Send Message</span>
                        </div> */}

                        <Link onClick={() => handleImpression('click')} to={`/services/${service.id}`} className='flex space-x-2 items-center'>
                            <UserIcon className='w-5 h-5' />
                            <span className='text-sm md:text-base'>View Profile</span>
                        </Link>

                        <a href={`https://wa.me/${service.user?.phone}?text=${messageToSeller}?lang=en`} onClick={() => handleImpression('whatsapp')} className='flex space-x-2 items-center py-2 px-5 rounded-full bg-primary-500 text-white font-bold'>
                            <span>Hire</span>
                        </a>
                    </div>
                </div>
            </div>
        </ImpressionClickTrackerHOC>
    )
}

export default ServiceProviderItem