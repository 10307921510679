import * as React from "react";
import "./style.css";
import OpenHourItem from "./OpenHourItem";

export const defaultHours = {
  0: {
    day: "Monday",
    open: false,
    openTime: {
      minutes: 0,
      hours: 8,
      period: "AM",
    },
    closeTime: {
      minutes: 0,
      hours: 10,
      period: "PM",
    },
  },
  1: {
    day: "Tuesday",
    open: false,
    openTime: {
      minutes: 0,
      hours: 8,
      period: "AM",
    },
    closeTime: {
      minutes: 0,
      hours: 10,
      period: "PM",
    },
  },
  2: {
    day: "Wednesday",
    open: false,
    openTime: {
      minutes: 0,
      hours: 8,
      period: "AM",
    },
    closeTime: {
      minutes: 0,
      hours: 10,
      period: "PM",
    },
  },
  3: {
    day: "Thursday",
    open: false,
    openTime: {
      minutes: 0,
      hours: 8,
      period: "AM",
    },
    closeTime: {
      minutes: 0,
      hours: 10,
      period: "PM",
    },
  },
  4: {
    day: "Friday",
    open: false,
    openTime: {
      minutes: 0,
      hours: 8,
      period: "AM",
    },
    closeTime: {
      minutes: 0,
      hours: 10,
      period: "PM",
    },
  },
  5: {
    day: "Saturday",
    open: false,
    openTime: {
      minutes: 0,
      hours: 8,
      period: "AM",
    },
    closeTime: {
      minutes: 0,
      hours: 10,
      period: "PM",
    },
  },
  6: {
    day: "Sunday",
    open: false,
    openTime: {
      minutes: 0,
      hours: 8,
      period: "AM",
    },
    closeTime: {
      minutes: 0,
      hours: 10,
      period: "PM",
    },
  },
};

const OpenHours = ({ setOpenHours, openHours }) => {
  let timeObject = {};

  if (!openHours || typeof openHours === "string" || openHours == [] || Object.keys(openHours)?.some((i) => openHours[i] == undefined)
  ) {
    timeObject = defaultHours;
  } else {
    timeObject[0] = openHours[0];
    timeObject[1] = openHours[1];
    timeObject[2] = openHours[2];
    timeObject[3] = openHours[3];
    timeObject[4] = openHours[4];
    timeObject[5] = openHours[5];
    timeObject[6] = openHours[6];
  }

  const updateHours = async (indx, value) => {


      let selectedObject = timeObject[indx];
      
      selectedObject = value;
      console.log(timeObject, selectedObject);
      timeObject[indx] = selectedObject;
    setOpenHours(timeObject);
  };

  return (
    <div className="open_hours mb-5 text-textcolor">
      {/* {JSON.stringify(openHours)} -- */}
      <OpenHourItem
        _day="Monday"
        dayIndex={0}
        getHour={updateHours}
        openHours={timeObject[0]}
      />
      <OpenHourItem
        _day="Tuesday"
        dayIndex={1}
        getHour={updateHours}
        openHours={timeObject[1]}
      />
      <OpenHourItem
        _day="Wednesday"
        dayIndex={2}
        getHour={updateHours}
        openHours={timeObject[2]}
      />
      <OpenHourItem
        _day="Thursday"
        dayIndex={3}
        getHour={updateHours}
        openHours={timeObject[3]}
      />
      <OpenHourItem
        _day="Friday"
        dayIndex={4}
        getHour={updateHours}
        openHours={timeObject[4]}
      />
      <OpenHourItem
        _day="Saturday"
        dayIndex={5}
        getHour={updateHours}
        openHours={timeObject[5]}
      />
      <OpenHourItem
        _day="Sunday"
        dayIndex={6}
        getHour={updateHours}
        openHours={timeObject[6]}
      />
    </div>
  );
};

export default OpenHours;
