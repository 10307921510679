import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  authProvider,
  load_user,
  sign_out_user,
} from "../../../functions/authFunctions";
import { pages } from "../../../Pages/Dashboard/Screens/Main/SubScreens/Settings/Forms/data";
import {
  remove_user_data,
  store_user_data,
} from "../../../store/actions/authActions";
import { store_services } from "../../../store/actions/serviceActions";
import { useChat } from "../ChatsContext";
import { store_business } from "../../../store/actions/businessActions";
import { useAlert } from "../ErrorContext";

const inital_user = {
  user: null,
  byUser: false,
  isMobileLoggin: false,
  signin: () => null,
  signout: (by_user) => null,
  getuser: (id) => null,
  setUser: (user) => null,
};

const AuthContext = React.createContext(inital_user);

function AuthProvider({ children, current_user, removeUser, storeService, storeBusiness }) {
  const chatRef = useChat();
  const alert = useAlert()

  const remove_user = async () => {
    return await removeUser();
  };

  let [user, setUser] = React.useState(current_user ? current_user : null);
  let [byUser, setByUser] = React.useState(true);
  const [isMobileLoggin, setIsMobileLoggin] = React.useState(false)


  useEffect(() => {
    window.onMessage = function (data) {
      if (data === "HELLO AFRIKA") {
        setIsMobileLoggin(true)
      }
    }
  }, [window])


  let signin = (newUser, callback) => {
    return authProvider.signin(() => {
      setUser(newUser);
      callback && callback();
      chatRef.connect(newUser.uid)
    }).then(() => {
      alert.setalert({
        type:'success',
        title:"Logged In",
        body:"Logged In Successfully",
      })
    });
  };

  let signout = (by_user, callback) => {

    return authProvider.signout(async () => {
      setUser(null);
      if (!by_user) {
        setByUser(false);
      }
      await sign_out_user()
        .then(() => remove_user())
        .then(() => {
          callback && callback();
          storeService({
            services: [],
            currentPage: null,
            images: [],
            location: [],
            skillsets: [],
            description: [],
            vehicles: [],
            review: [],
            last_saved: null,
          });

          storeBusiness({
            review: [],
            category: [],
            facilities: [],
            phone: [],
            openHours: [],
            website: [],
            location: [],
            description: "",
            images: [],
            title: [],
            plugin: [],
            last_saved: new Date(),
          })
        })
        .then(() => chatRef.disconnect())
        .then(() => {
            // alert.setalert({
            //   type:'success',
            //   title:"logged out",
            //   body:"logged out successfully",
            // })
        }).catch(() => {
          alert.setalert({
            type:'error',
            title:"logout failed",
            body:"logot was  unsuccessful, check your connection",
          })
        });
    });
  };

  let getuser = (id, callback) => {
    return authProvider.getuser(async () => {
      return await load_user(id)
        .then((res) => {
          if (res.description) {
            signout();
          }
          return res;
        })
        .then((res) => {
          callback && callback();
          return res;
        })
        .catch((err) => {
          console.error("ERROR: ", err);
          signout(false);
        });
    });
  };

  

  let value = { user, byUser, isMobileLoggin, signin, signout, getuser, setUser };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return React.useContext(AuthContext);
};

const mapStateToProps = (state) => {
  return {
    current_user: state.user?.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeUser: (data) => dispatch(store_user_data(data)),
    removeUser: () => dispatch(remove_user_data()),
    storeService: (data) => dispatch(store_services(data)),
    storeBusiness: (data) => dispatch(store_business(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);

