export const bedrooms = [
    {
        id: 1,
        title: '1 room',
        value: 1
    },
    {
        id: 2,
        title: '2 rooms',
        value: 2
    },
    {
        id: 3,
        title: '3 rooms',
        value: 3
    },
    {
        id: 4,
        title: '4 rooms',
        value: 4
    },
    {
        id: 5,
        title: '5 rooms',
        value: 5
    },
    {
        id: 6,
        title: '6 rooms',
        value: 6
    },
    {
        id: 7,
        title: '7 rooms',
        value: 7
    },
    {
        id: 8,
        title: '8 rooms',
        value: 8
    },
    {
        id: 9,
        title: '9 rooms',
        value: 9
    },
    {
        id: 10,
        title: '10 rooms',
        value: 10
    }
]

export const bathrooms = [
    {
        id: 1,
        title: '1 bathroom',
        value: 1
    },
    {
        id: 2,
        title: '2 bathroom',
        value: 2
    },
    {
        id: 3,
        title: '3 bathroom',
        value: 3
    },
    {
        id: 4,
        title: '4 bathroom',
        value: 4
    },
    {
        id: 5,
        title: '5 bathroom',
        value: 5
    },
    {
        id: 6,
        title: '6 bathroom',
        value: 6
    },
    {
        id: 7,
        title: '7 bathroom',
        value: 7
    },
    {
        id: 8,
        title: '8 bathroom',
        value: 8
    },
    {
        id: 9,
        title: '9 bathroom',
        value: 9
    },
    {
        id: 10,
        title: '10 bathroom',
        value: 10
    }
]

export const available_bedrooms = [
    {
        id: 1,
        title: '1 room',
        value: 1
    },
    {
        id: 2,
        title: '2 rooms',
        value: 2
    },
    {
        id: 3,
        title: '3 rooms',
        value: 3
    },
    {
        id: 4,
        title: '4 rooms',
        value: 4
    },
    {
        id: 5,
        title: '5 rooms',
        value: 5
    },
    {
        id: 6,
        title: '6 rooms',
        value: 6
    },
    {
        id: 7,
        title: '7 rooms',
        value: 7
    },
    {
        id: 8,
        title: '8 rooms',
        value: 8
    },
    {
        id: 9,
        title: '9 rooms',
        value: 9
    },
    {
        id: 10,
        title: '10 rooms',
        value: 10
    }
]

export const people_in_rooms = [
    {
        id: 1,
        title: 'Nobody',
        value: 0
    },
    {
        id: 2,
        title: '1 Person',
        value: 1
    },
    {
        id: 3,
        title: '2 People',
        value: 2
    },
    {
        id: 4,
        title: '3 People',
        value: 3
    },
    {
        id: 5,
        title: '4 People',
        value: 4
    },
    {
        id: 6,
        title: '5 People',
        value: 5
    },
    {
        id: 7,
        title: '6 People',
        value: 6
    },
    {
        id: 8,
        title: '7 People',
        value: 7
    },
    {
        id: 9,
        title: '8 People',
        value: 8
    },
    {
        id: 10,
        title: '9 People',
        value: 9
    },
    {
        id: 11,
        title: '10 People',
        value: 10
    }
]

export const lease_duration = [
    {
        id: 1,
        title: '1 month',
        value: 1
    },
    {
        id: 2,
        title: '2 months',
        value: 2
    },
    {
        id: 3,
        title: '3 months',
        value: 3
    },
    {
        id: 4,
        title: '4 months',
        value: 4
    },
    {
        id: 5,
        title: '5 months',
        value: 5
    },
    {
        id: 6,
        title: '6 months',
        value: 6
    },
    {
        id: 7,
        title: '7 months',
        value: 7
    },
    {
        id: 8,
        title: '8 months',
        value: 8
    },
    {
        id: 9,
        title: '9 months',
        value: 9
    },
    {
        id: 10,
        title: '10 months',
        value: 10
    }
]

export const apartment_type = [

    {
        id: 1,
        title: 'Studio',
        value: 'studio',
        description:'A self-contained flat where all of the amenities are in one space.'
    },
    {
        id: 2,
        title: 'Hall',
        value: 'hall',
        description:'Shared living in a managed building with onsite teams and some shared amenities.'
    },
    {
        id:3,
        title: 'House',
        value: 'house',
        description:'Shared living with a group of housemates.'
    },
    {
        id:4,
        title: 'Apartment',
        value: 'apartment',
        description:'Similar to a house share, normally with one to three bedrooms.'
    },
    {
        id:5,
        title: 'Dormitory',
        value: 'dormitory',
        description:'studio type living spaces for students.'
    }

]

export const bills_included = [
    {
        id: 1,
        title: 'Gas',
        value: 'gas'
    },
    {
        id: 2,
        title: 'Electricity',
        value: 'electricity'
    },
    {
        id: 3,
        title: 'Internet',
        value: 'internet'
    },
    {
        id: 4,
        title: 'Water',
        value: 'water'
    },

    
]