import { EyeIcon, EyeOffIcon, SelectorIcon } from "@heroicons/react/outline";
import React, { useEffect } from "react";
import { countries_call_codes } from "../../Screens/Main/SubScreens/Settings/Forms/data";

const DashboardPhoneFormField = (props) => {
  const [showpassword, setShowPassword] = React.useState(false);
  const [countryFilter, setCountryFilter] = React.useState("");
  const [openList, setOpenList] = React.useState(false);

  let {
    name,
    type = "text",
    disabled = false,
    setText,
    text,
    setDial,
    dial,
    placeholder = "type your text here...",
    multiline = false,
    password = false,
    rows = 3,
    maxLength = 200,
  } = props;

  const removeSpaces = (text) => text.replace(/\s+/g, "");
  const phoneNumberFormat = (text) => {
    text = removeSpaces(text)
    text = text.replace(/^(\d{2})(\d{4})(\d{2})(\d{2})$/, "$1 $2 $3 $4");
    return text;
}


  return (
    <div
      className={` ${
        disabled && "bg-gray-100"
      } w-auto  m-2 my-5 border-2 relative border-gray-300 h-14 items-center space-x-4 rounded-xl flex bg-white flex-row text-sm text-textcolor`}
    >
      <div onClick={() => setOpenList(!openList)} className="w-[30%] flex items-center justify-center rounded-l-xl h-full bg-[#D9D9D9]">
        <span>{dial?.dial_code ?? "+0"}</span>
        <SelectorIcon className="w-5 h-5" />
      </div>

      <span className="w-full">
        {!multiline && (
          <input
            disabled={disabled}
            onChange={(e) => setText(removeSpaces(e.target.value))}
            maxLength={maxLength}
            placeholder={placeholder}
            value={phoneNumberFormat(text)}
            inputMode="tel"
            className="  bg-none outline-none w-full"
          />
        )}
      </span>
      <div style={{
        height:  !openList && 0,
        opacity: !openList && 0
      }} className="absolute ease_transition -left-3 top-16 max-h-[30vh] w-full bg-white p-3 divide-y-2 overflow-y-scroll scrollbar">
        <input onChange={(e) => setCountryFilter(e.target.value)} className="w-full h-12 py-1 px-2 outline-none" placeholder="search country..." />
        {countries_call_codes.filter(i => (`${i.dial_code} ${i.name}`).toLowerCase().includes(countryFilter.toLowerCase()  )).map((country, indx) => {
          return <div onClick={() => {
            setDial(country)
            setOpenList(!openList)
            }} className="flex items-center space-x-4 hover:bg-primary-300 cursor-pointer py-2">
            <div>{country.name}</div>
            <div>({country.dial_code})</div>
          </div>;
        })}
      </div>
    </div>
  );
};

export default DashboardPhoneFormField;
