import { CheckIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../Components/UtilityComponents/AuthProvider'
import { updateBuisnessUserProfileProgress, verify_payment } from '../../../functions/authFunctions'
import { printDocument } from '../../Dashboard/Screens/Timetable/Screens/ViewTimetable'
import Loader from '../../../assets/spinner.svg'
import { Link } from 'react-router-dom'
import { Stages } from '../../Dashboard/Screens/Main/SubScreens/Settings/Forms/Billings/UploadID/stages'
import { useOrder } from '../../../Components/UtilityComponents/PlaceOrderContext'
import { useAlert } from '../../../Components/UtilityComponents/ErrorContext'
import { currencies } from '../../Dashboard/Screens/Services/AddServices/Forms/SkillSetsForm/RatesForm'
import { numberWithCommas } from '../../../functions'
import moment from 'moment'

const FinishPayments = () => {

    const [verification, setVerification] = useState(null)
    const [vid, setVID] = useState('')
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(false)
    const [currency, setCurrency] = useState(currencies[3])

    const auth = useAuth()
    const { user } = auth.user
    const order = useOrder()
    const alert = useAlert()
    const payment_for_account_verification = order.serviceID === null

    useEffect(() => {
        if (window.location.href.includes('?')) {
            const path = window.location.href.split('reference=')[1]
            verify(path)
            setVID(path)
        }

    }, [])

    useEffect(() => {
        // console.log('ORDR DESCRIPTION: ', order.serviceID)
    }, [order.serviceID])

    useEffect(() => {
        if (vid) {
            if (verification?.status === 'success') {
                setPageLoading(true)
                if (payment_for_account_verification) return
                updateBuisnessUserProfileProgress({
                    uid: user.uid,
                    progress: Stages.UPLOAD_ID,
                    payment_id: vid
                }).then((res) => {
                    setPageLoading(false)
                })
            }
        }
    }, [vid])

    useEffect(() => {
        console.log(verification)
        if (verification?.status === 'success') {
            order.place_order(vid, verification?.status).then((respose) => {
                alert.setalert(respose)
            })
            setCurrency(currencies.filter(c => c.title.toLowerCase() === verification.currency.toLowerCase())[0])
        }
    }, [verification])

    useEffect(() => { }, [currency])

    const verify = async (path) => {
        return await verify_payment(path).then((res) => {
            setVerification(res[3])
        })
    }

    return !pageLoading ? (
        <div className='w-full h-full flex justify-center items-center'>
            {
                verification &&
                <div className='max-w-md w-full' id="payment_slip">
                    <div className='w-full flex flex-col items-center h-auto space-y-5 '>
                        <div className='w-20 h-20'>
                            <img className='w-full h-full rounded-full object-cover' src={user.photo_url} alt='guide user profile' />
                        </div>
                        <div className='w-full h-auto rounded-md shadow-lg p-5 bg-primary-500 pt-10 text-white space-y-4'>
                            <div className='text-4xl font-semibold'>{currency?.symbol} {numberWithCommas((parseFloat(verification.amount) / 100).toFixed(2))}</div>
                            <div className='flex flex-col space-y-2 pt-10'>
                                <span className='text-sm'>paid by</span>
                                <span className='text-xl font-medium'>{user?.display_name}</span>
                                <span className='text-sm'>for Account Activation</span>
                            </div>
                            <div className='divide-y-2 bg-white p-2 rounded-md mt-5 text-gray-500 text-left'>
                                <div className='flex items-center space-x-4 py-5 px-5'>
                                    <div className='w-10 h-10 justify-center items-center flex bg-green-500 rounded-full'>
                                        <CheckIcon className='w-5 h-5 text-white' />
                                    </div>
                                    <div className=''>
                                        <div className='font-medium'>PAID {currency?.symbol} {numberWithCommas((parseFloat(verification.amount) / 100).toFixed(2))}</div>
                                        <div className='text-sm'>{moment(verification.paidAt).format('llll')}</div>
                                    </div>
                                </div>
                                <div className='space-y-1 py-5 px-5'>
                                    <div className='font-medium'>Transactoion ID</div>
                                    <div>{vid}</div>
                                </div>
                            </div>
                            <div onClick={() => printDocument("payment_slip", setLoading)} className='w-full bg-green-500 text-white flex justify-center items-center py-4 font-bold cursor-pointer hover:bg-green-700 rounded-md'>
                                {loading ? <img src={Loader} className='w-4 h-4 animate-spin' /> : 'SAVE RECIEPT'}
                            </div>
                        </div>
                        <Link to="/dashboard/services" className='text-gray-500'>back to dashboard</Link>

                    </div>

                </div>
            }
        </div>
    ) : (
        <div className='w-full h-full flex flex-col justify-center items-center'>
            <img src={Loader} className="w-20 h-20 animate-spin" />
            <div>verifying payment...</div>
        </div>
    )
}

export default FinishPayments