import React from 'react'
import { Link } from 'react-router-dom'
import NeighboursImage from '../../../assets/neigbours.svg'

const HousingSection = () => {
    return (
        <div className='max-w-5xl m-2 lg:w-[70%] justify-center flex items-center py-28'>
            <div className='w-full 2xl:w-[70%] text-center flex flex-col items-center 2xl:items-start 2xl:text-left 2xl:pr-20 space-y-4'>
                <div className='font-bold text-2xl text-dark_textcolor'>Chat With Customers</div>
                <div className='font-light text-textdark text-lg pb-5'>Talk with customers and get reservations, orders, deals and additional information, serve your customers how they like to be served</div>
                <Link to="/signup" className='w-full lg:w-fit py-4 px-10 bg-primary-500 text-white font-bold rounded-full hover:cursor-pointer'>Sign Up</Link>
            </div>
            <div className='hidden 2xl:flex justify-center items-center'>
                <img src={NeighboursImage} alt="back_drop_image" className='w-full h-auto' />
            </div>
        </div>
    )
}

export default HousingSection