import { upload } from "@testing-library/user-event/dist/upload";
import React, { createContext, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";
import { CHAT_SERVER_URL } from "../../../assets/url";
import { uploadFCMToken } from "../../../functions/authFunctions";
import { load_chat_messages } from "../../../functions/chats_functions";
import { requestPermission } from "../../../utils";
import { getMessagingToken } from "../../../utils/functions/get_token_firebase";
import { useSuzi } from "../../Assistant";
import ChatWidget from "../../ChatWidget";
import { useAuth } from "../AuthProvider";
import { PromptLoginRequired } from "../PromptLoginRequired";
import NotificationManager, {
  NOTIFICATION_CHANNELS,
} from "../../../functions/notification_manager";

const notifications = new NotificationManager();
const notifications_channel = notifications;

const socket = io(CHAT_SERVER_URL,
    {
        withCredentials: true
    });

const _init = {
  online: null,
  room: null,
  socket,
  recipient: null,
  sender: null,
  messaging: null,
  onlineUsers: null,
  notification: null,
  notifications_channel: notifications,
  hasUnreadMessage: false,
  setOnlineUsers: () => null,
  setMessaging: () => null,
  setRoom: () => null,
  setReciepient: () => null,
  startChat: (reciever, handshake) => null,
  joinChat: (data) => null,
  sendMessage: (data) => null,
  postTaskUpdate: (data) => null,
  showNotification: () => null,
  setNotification: (data) => null,
  setHasUnreadMessage: (data) => null,
  disconnect: () => null,
  connect: (uid) => null,
  enter_room: (data) => null,
  left_room: (data) => null,
};

const ChatContextProvider = createContext(_init);

const ChatContext = ({ children, currentUser }) => {
  const handshake_status = {
    COMPLETE: "complete",
    NULL: null,
  };

  const user = currentUser;

  const [online, setOnline] = useState(null);
  const [io, setIO] = useState(socket);
  const [room, setRoom] = useState(null);
  const [recipient, setReciepient] = useState(null);
  const [sender, setSender] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [chatMessages, setChatMessages] = useState(null);
  const [messaging, setMessaging] = useState({});
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [notification, setNotification] = useState(null);
  const [hasUnreadMessage, setHasUnreadMessage] = useState(false);

  const suzi = useSuzi();

  useEffect(() => {
    setSender(user);

    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      if (user && "uid" in user) {
        notifications
          .requestPermission()
          .then(() => {
            getMessagingToken().then((token) => {
              uploadFCMToken({
                uid: user.uid,
                device: "browser",
                token,
              }).then((res) => {
                console.warn("TOKEN STUFF : ", res);
              });
            });
          })
          .then(() => {
            fetch(`${CHAT_SERVER_URL}/get_chat_list/${user.uid}`, {
              method: "GET",
            }).then(async (result) => {
              const chats = await result.json();
              setHasUnreadMessage(chats?.some((user) => user.counter > 0));
            });
          });
      }
    }

    socket.on("connect", async () => {
      console.log(socket.id); // x8WIv7-mJelg7on_ALbx
      setOnline(socket.id);
      connect(user?.uid);
    });

    return function socketCleanup() {
      socket.off("connect");
      socket.off("get_loaded_messages");
      socket.off("new_message_notification");
      socket.off("disconnect");
      socket.off("receive_message");
      socket.off("request_exists");
    };
  }, [user]);

  useEffect(() => {
    if (showChat === true) {
    } else {
      notifications.resumeUserNotificationChannel();
    }
  }, [sender, showChat, recipient]);

  const joinChat = async (data) => {
    return await startChat(data, handshake_status.COMPLETE);
  };

  const acceptInvite = (data) => {
    joinChat(data).then(() => {
      suzi.popMessage();
    });
  };

  const showNotification = (
    sender,
    header,
    body,
    icon = null,
    room = null,
    data = null
  ) => {
    var options = {
      title: sender,
      subtitle: header,
      duration: 10000, //optional, default: 5000,
      message: body,
      theme: "darkblue",
      colorTop: "green",
      backgroundTop: "green", //optional, background color of top container.
      backgroundBottom: "darkgreen",
      native: true,
      onClick: async () => {
        if (data) {
          await setSender(data.sender);
          notifications.muteUserNotificationChannel(data.to).then(() => {
            startChat(data.reciever, handshake_status.COMPLETE, data.sender);
          });
        }
      }, 
    };

    if (icon) {
      options["icon"] = icon;
    }
    notifications.showNotification(
      options,
      NOTIFICATION_CHANNELS.chats,
      data.to
    );
  };

  useEffect(() => {
    socket.on("get_loaded_messages", (messages) => {
      console.log(messages);
      console.log("loaded_msg");
    });

    socket.on("send_notification", async (data) => {
      console.log("NEW MESSAGE", data);
      const incoming = data.chat;
      const sender_uid = incoming.sender.uid === user.uid;
      const chat_sender = sender_uid ? incoming.reciever : incoming.sender;
      const chat_reciever = !sender_uid ? incoming.reciever : incoming.sender;
      if (chat_reciever.uid === user.uid) {
        setHasUnreadMessage(true);
        await showNotification(
          chat_sender.author,
          "Chat Message",
          incoming.lastmessage,
          chat_sender.photoURL,
          incoming.room,
          { ...data.chat, to: chat_sender.uid }
        );
      }
    });

    socket.on("new_message_notification", (resp) => {
      if (resp.reciever.uid === user.uid && resp.handshake !== "complete") {
        suzi.addMessage({
          text: (
            <div>
              <div>
                You have recieved a chat request from {resp?.sender.author}
              </div>
              <div className="flex items-center py-2 w-full space-x-4">
                <div
                  onClick={() => acceptInvite(resp.sender)}
                  className="px-4 cursor-pointer py-2 rounded-md bg-green-500 text-white font-medium hover:bg-green-300"
                >
                  Accept
                </div>
                <div className="px-4 cursor-pointer py-2 rounded-md bg-red-500 text-white font-medium hover:bg-red-300">
                  Reject
                </div>
              </div>
            </div>
          ),
          timeout: 3000,
        });
      }
    });

    socket.on("request_exists", (resp) => {
      if (resp.uid === user.uid) {
        suzi.addMessage({
          text: `You already have a pending request.`,
          timeout: 3000,
        });
      }
    });

    socket.on("get-online-users", (users) => {
      setOnlineUsers(users);
    });

    socket.on("disconnect", () => {
      console.log("DISCONNECTED ", socket.connected); // x8WIv7-mJelg7on_ALbx
      setOnline(null);
    });
    setIO(socket);
  }, [socket]);

  useEffect(() => {
    // console.log(messaging)
  }, [messaging]);

  const startChat = async (reciever, handshake = null, sentBy = null) => {
    const _sender = sentBy
      ? {
        photo_url:
          "photo_url" in sentBy
            ? sentBy.photo_url
            : "photoURL" in sentBy
              ? sentBy.photoURL
              : null,
        uid: sentBy.uid,
        display_name:
          "author" in sentBy
            ? sentBy.author
            : "display_name" in sentBy
              ? sentBy.display_name
              : null,
      }
      : sender;

    try {
      if (_sender && reciever) {
        const room_id =
          _sender?.uid > reciever?.uid
            ? `${_sender?.uid}-${reciever?.uid}`
            : `${reciever?.uid}-${_sender?.uid}`;
        const room = room_id;
        setRoom(room);
        const data = {
          room,
          sender: {
            photoURL: _sender?.photo_url,
            uid: _sender?.uid,
            author: _sender?.display_name,
          },
          handshake,
          reciever,
        };

        socket.emit("join_room", data);
        return await load_chat_messages(room)
          .then((res) => {
            setChatMessages(res);
            console.log("-------++++--pppp=--+---");
            setReciepient(reciever);
            setShowChat(true);
            return true;
          })
          .finally(() => {
            return false;
          });
      }
      setShowChat(true);
    } catch (e) {
      console.log(e);
    }
  };

  const joinRoom = async (room, sender, reciever, handshake = null) => {
    const data = {
      room,
      sender: {
        photoURL: sender?.photo_url,
        uid: sender?.uid,
        author: sender?.display_name,
      },
      handshake,
      reciever,
    };

    return await socket.emit("join_room", data);
  };

  const sendMessage = async (data) => {
    const { room, sender, message, files = null } = data;

    if (message !== "" && sender) {
      const messageData = {
        room: room,
        author: sender?.display_name ? sender?.display_name : sender.author,
        uid: sender?.uid,
        message: message,
        id: socket.id,
        time: new Date(Date.now()).toUTCString(),
      };

      console.log(files);

      if (files && files.length > 0) {
        messageData["files"] = files;
      }

      await socket.emit("send_message", messageData, (status) => {
      });
      return messageData;
    }
  };

  const disconnect = () => socket.disconnect();
  const connect = (uid) => {
    socket.connect();
    console.log("CONNECTING SOCKET USER =====> ", uid);
    socket.emit("add_user", uid);
  };

  const postTaskUpdate = async (data) => {
    const { sender, reciever, message, type } = data;

    const room_id =
      sender?.uid > reciever?.uid
        ? `${sender?.uid}-${reciever?.uid}`
        : `${reciever?.uid}-${sender?.uid}`;
    joinRoom(room_id, sender, reciever).then(async () => {
      if (message !== "" && sender) {
        const messageData = {
          room: room_id,
          author: sender?.display_name,
          uid: sender?.uid,
          message: message,
          id: socket.id,
          type,
          time: new Date(Date.now()).toUTCString(),
        };

        await socket.emit("send_message", messageData);
        return messageData;
      }
    });
  };

  const enter_room = async (data) => {
    await socket.emit("in_room", data);
  };

  const left_room = async (data) => {
    await socket.emit("left_room", data);
  };

  const value = {
    online,
    socket: io,
    room,
    sender,
    connect,
    recipient,
    messaging,
    onlineUsers,
    notification,
    notifications_channel,
    hasUnreadMessage,
    setOnlineUsers,
    setMessaging,
    setRoom,
    setReciepient,
    startChat,
    joinChat,
    sendMessage,
    postTaskUpdate,
    showNotification,
    setNotification,
    setHasUnreadMessage,
    disconnect,
    enter_room,
    left_room,
  };

  return (
    <ChatContextProvider.Provider value={value}>
      {children}
      <div className="fixed bottom-10 text-sm z-50 right-10">
        {/* {online && <div onClick={startChat} className='p-2 bg-red-200 cursor-pointer'>User {online} Connected</div>} */}
        {showChat && !sender && <PromptLoginRequired />}
        {showChat && sender && recipient && (
          <ChatWidget
            show={setShowChat}
            socket={socket}
            sender={sender}
            room={room}
            recipient={recipient}
            online={online}
            chatMessages={chatMessages}
            sendMessage={sendMessage}
          />
        )}
      </div>
    </ChatContextProvider.Provider>
  );
};

export const useChat = () => useContext(ChatContextProvider);

const mapStateToProps = (state) => {
  return {
    currentUser: state.user?.data?.user,
    messaging: state.messaging?.payload,
  };
};
export default connect(mapStateToProps)(ChatContext);
