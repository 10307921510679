import { Transition } from '@headlessui/react'
import React, { useState } from 'react'
import Checker from '../../../../../../DashboardComponents/Checker'
import DashboardFormField from '../../../../../../DashboardComponents/DashboardFormField'
import { DashboardFormDropDownList } from '../../../../../../DashboardComponents/DashboardFormFieldDropList'
import OpenHours from '../../../../../../DashboardComponents/OpenHours'
import ToggleFeature from '../../../../../../DashboardComponents/ToggleFeature'
import {ReactComponent as Loader } from '../../../../../../../../assets/spinner.svg'

const BusinessForm = () => {

    const parent_category_list = [
        {
            id: 1,
            title: 'Market1',
            value: 'market1'
        },
        {
            id: 1,
            title: 'Market2',
            value: 'market2'
        },
        {
            id: 1,
            title: 'Market3',
            value: 'market3'
        }
    ]

    const sub_category_list = [
        {
            id: 1,
            title: 'Store',
            value: 'store'
        },
        {
            id: 1,
            title: 'Hair Drier',
            value: 'hair_drier'
        },
        {
            id: 1,
            title: 'Fish Market',
            value: 'fish_market'
        }
    ]
    const [businessName, setBusinessName] = useState('')
    const [p_cat, setPCat] = useState(parent_category_list[0])
    const [s_cat, setSCat] = useState(sub_category_list[0])
    const [open_hours, setOpenHours] = useState(null)
    const [loading, setLoading] = useState(false)
    const [alwaysOpen, setAlwaysOpen] = useState(false)
    const [accepts_credit_card, setAcceptsCreditCard] = useState(false)

    const handleOnSave = () => { 
        setLoading(true)
        setTimeout(() => setLoading(false), 3000)
    }

    return (
        <div className='w-full px-2 md:px-10 py-5'>
            <div className='w-full mb-20'>
                <div className='text-sm font-light text-light_textcolor px-5 py-2'>Details</div>
                <DashboardFormField placeholder="Johson Johnson Medicine Store" name="Business Name" text={businessName} setText={setBusinessName} />
                <div className='flex w-full flex-col lg:flex-row lg:space-x-2 lg:items-center lg:justify-between'>
                    <DashboardFormDropDownList name="Parent Category" selectedInterval={p_cat} setSelectedInterval={setPCat} list={parent_category_list} />
                    <DashboardFormDropDownList name="Sub Category" selectedInterval={s_cat} setSelectedInterval={setSCat} list={sub_category_list} />
                </div>
                <DashboardFormField multiline={true} placeholder="write a business description..." name="Business Descripton" text={businessName} setText={setBusinessName} />
            </div>
            <div className='w-full mb-20'>
                <div className='text-sm font-light text-light_textcolor px-5 py-2'>Contact</div>
                <DashboardFormField placeholder="+00 000 000 00" name="Telephone Number" text={businessName} setText={setBusinessName} />
                <DashboardFormField placeholder="@insta_handle" name="Instagram" text={businessName} setText={setBusinessName} />
                <DashboardFormField placeholder="@facebook_handle" name="Facebook" text={businessName} setText={setBusinessName} />
                <DashboardFormField placeholder="www.yourwebsite.com" name="Website" text={businessName} setText={setBusinessName} />
            </div>

            <div className='w-full mb-20 px-1 sm:px-5'>
                <div className='text-sm font-light text-light_textcolor py-2 flex justify-between items-center'>
                    <span>Open Now</span>
                    <span><Checker setActiveState={setAlwaysOpen} activeState={alwaysOpen} /></span>
                </div>
                <Transition
                    show={!alwaysOpen}
                    enter="ease_transition"
                    enterFrom='opacity-0 h-0'
                    enterTo='opacity-100 h-auto'
                    leave="ease_transition"
                    leaveFrom='opacity-100 h-auto'
                    leaveTo='opacity-0 h-0'
                >
                    <OpenHours openHours={open_hours} setOpenHours={setOpenHours} />
                </Transition>
            </div>

            <div className='w-full mb-20 mx-2 md:mx-4'>
                <div className='text-sm font-light text-light_textcolor px-5 py-2'>Features</div>
                <ToggleFeature title="Accepts credit Card" setFeature={setAcceptsCreditCard} feature={accepts_credit_card} />
                <ToggleFeature title="Accepts Cash" setFeature={setAcceptsCreditCard} feature={accepts_credit_card} />
                <ToggleFeature title="Does Delivery" setFeature={setAcceptsCreditCard} feature={accepts_credit_card} />
                <ToggleFeature title="Happy Hour" setFeature={setAcceptsCreditCard} feature={accepts_credit_card} />
                <ToggleFeature title="Live Music" setFeature={setAcceptsCreditCard} feature={accepts_credit_card} />
                <ToggleFeature title="Not A Chain" setFeature={setAcceptsCreditCard} feature={accepts_credit_card} />
                <ToggleFeature title="Outdoor Sitting" setFeature={setAcceptsCreditCard} feature={accepts_credit_card} />
                <ToggleFeature title="Parking" setFeature={setAcceptsCreditCard} feature={accepts_credit_card} />
                <ToggleFeature title="Takeouts" setFeature={setAcceptsCreditCard} feature={accepts_credit_card} />
                <ToggleFeature title="Has Wi-Fi" setFeature={setAcceptsCreditCard} feature={accepts_credit_card} />
            </div>
            <div onClick={() => handleOnSave()} className={`w-auto h-12 cursor-pointer flex justify-center items-center rounded-xl  mx-2 my-4 space-x-2  ${loading ? 'bg-gray-300':'bg-green-600 hover:bg-green-500'}`}>
                {loading && <Loader className='w-4 h-4 animate-spin' />}
                <span className="font-medium text-white cursor-pointer">Save</span>
            </div>
        </div>
    )
}

export default BusinessForm