import { SAVE_BLOG, CLEAR_BLOG } from "../../actionTypes/blogActionTypes"

const _state = {
    action: CLEAR_BLOG,
    data: [],
    error: null
}

export const blogReducer = (state = _state, action) => {
    switch (action.type) {
        case SAVE_BLOG:
            return { ...state, type: action.type, data: action.payload, error: action.error }
        case CLEAR_BLOG:
            return { ...state, type: action.type, data: action.payload, error: action.error }
        default:
            return state
    }
}