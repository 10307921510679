import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { createContext } from 'react'
import { connect } from 'react-redux'
import { load_user_apartments } from '../../../functions/housing_functions'

const init = {
    apartmentList: null,
    apartments: null,
    openSlider: false,
    setOpenSlider: () => null,
    setApartmentList: () => null,
    setApartments: () => null,
    loadUp: () => null
}

const HousingContextProvider = createContext(init)

const HousingContext = ({ children, userx }) => {
    const { user } = userx
    const [openSlider, setOpenSlider] = useState(false)
    const [apartments, setApartments] = useState(null)
    const [apartmentList, setApartmentList] = useState(null)

    useEffect(() => {
        loadUp()
    }, [])

    useEffect(() => {
        console.log(openSlider, '====>')

    },[openSlider, apartmentList, apartments])

    const loadUp = async () => {
        load_user_apartments(user.uid).then((houses) => {
            setApartments(JSON.parse(houses))
            setApartmentList(JSON.parse(houses))
        })
    }

    const values = {
        apartmentList,
        apartments,
        openSlider,
        setOpenSlider,
        setApartmentList,
        setApartments,
        loadUp
    }

    return (
        <HousingContextProvider.Provider value={values}>
            {children}
        </HousingContextProvider.Provider>
    )
}

export const useHousing = () => useContext(HousingContextProvider)

const mapStateToProps = (state) => {
    return {
        userx: state.user?.data
    }
}


export default connect(mapStateToProps)(HousingContext)