import * as React from 'react';
import './style.css';
import moment from 'moment';
import TimePicker from '../../DashboardComponents/Timepicker'
import Checker from '../Checker';


const OpenHourItem = ({_day, dayIndex, getHour, openHours}) => {

    const _openTime = {
        minutes: 0,
        hours:0,
        period: 'AM'
    }

    const _closeTime = {
        minutes: 0,
        hours:0,
        period:'PM'
    }
    const _open = false;

    let {openTime, closeTime, open, day} = openHours ? openHours : { openTime: _openTime, closeTime: _closeTime, open: _open, day: _day };
    
   const toggleOpen = (open) => {
        let value = Object.assign({}, openHours, {});
        value['open'] = open;
        getHour(dayIndex, value)
   }

   const selectTime =(option, obj) => {
        let value = Object.assign({}, openHours, {});
        if(option == "openTime"){
            value['openTime'] = obj;
        }else{
            value['closeTime'] = obj;
        }
        getHour(dayIndex, value)
   }

    return(
        <div className='flex md:justify-start flex-1 my-2 rounded-xl justify-center py-2 md:py-0 '>
            <div className={`flex text-sm md:text-base w-auto justify-start ${open ? 'flex-col md:flex-row':'flex-row'}  items-center space-x-0 md:space-x-5`} >
                <div className='flex items-center space-x-5'>
                    <span className='w-24 sm:w-32 font-medium capitalize'>{day}</span>
                    <Checker title={''} activeState={open} setActiveState={toggleOpen} />
                </div>
                <div className='flex items-center justify-start space-x-1 md:space-x-5'>
                    {open ? <span className='open-time'><TimePicker setTime={selectTime} option="openTime" time={openTime} /> </span>:<span></span> }
                    <span>{open? "to": "Closed"}</span>
                    {open ? <span className='open-time'><TimePicker setTime={selectTime} option="closeTime" time={closeTime} /></span>:<span></span>}
                </div>
            </div>
        </div>
        )
}
export default OpenHourItem