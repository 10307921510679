import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import React from 'react'

const ReviewItem = () => {
    return (
        <div>
            <div className='space-y-4'>
                <div className='font-semibold text-bl text-xl text-dark_textcolor'>EasyTo Use & Manage.</div>
                <div className='font-light text-textdark text-sm lg:text-md pb-5'>
                    “I love the ease in using Guide Dashboard, this services have
                    helped me tremedeously in managing my businesses and hiring help
                    with running the business, not to mention more clients
                    patronizing my business on a daily, The service is simply Amaizing.”.
                </div>
            </div>
            <div>
                <ReviewUserCard />
            </div>
            <div className='w-[90%] xl:w-full flex justify-end max-w-2xl my-5'>
                <div className='flex items-center justify-center'>
                    <div className='w-16 h-10 aspect-square flex items-center justify-center '>
                        <div className='w-10 h-10 rounded-full flex items-center justify-center  border-[1px] border-[#C4C4C4]'><ChevronLeftIcon className='text-primary-500 w-5 h-5' /></div>
                    </div>
                    <div className='w-[1px] h-[70%] bg-[#C4C4C4] text-[rgba(0,0,0,0)]'>|</div>
                    <div className='w-16 h-10 aspect-square flex items-center justify-center'>
                        <div className='w-10 h-10 rounded-full flex items-center justify-center  border-[1px] border-[#C4C4C4]'><ChevronRightIcon className='text-primary-500 w-5 h-5' /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ReviewUserCard = () => {
    return (
        <div className='flex justify-center space-x-4 items-center mt-10'>
            <div className='h-20 w-20 aspect-square'>
                <img
                    src="https://img.freepik.com/free-photo/half-profile-image-beautiful-young-woman-with-bob-hairdo-posing-gazing-with-eyes-full-reproach-suspicion-human-facial-expressions-emotions-reaction-feelings_343059-4660.jpg?w=2000"
                    className="w-full h-full rounded-full object-cover" alt="review_user_image" />
            </div>
            <div className='text-left'>
                <div className='font-semibold text-bl text-md text-dark_textcolor'>EasyTo Use & Manage.</div>
                <div className='font-light text-textdark text-sm'>Manager Of Galis Restaurant</div>
            </div>
        </div>
    )
}

export default ReviewItem