import React, { useEffect, useState } from "react";
import FormChecker from "../../../../DashboardComponents/FormComponents/FormChecker";
import MapUI from "../../../../DashboardComponents/FormComponents/MapUI";
import SearchAddressModal from "../../../../DashboardComponents/FormComponents/MapUI/SearchAddressModal";
import TipsCard from "../../../../DashboardComponents/FormComponents/TipsCard";
import DashboardFormField from "../../../../DashboardComponents/DashboardFormField";
import { XIcon } from "@heroicons/react/outline";
import { useAlert } from "../../../../../../Components/UtilityComponents/ErrorContext";

const LocationForm = ({ business }) => {
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState(null);
  const [allPositions, setAllPositions] = useState([]);
  const [address, setAddress] = useState([]);
  const [hideOnListing, setHideOnListing] = useState(false);

  useEffect(() => {
    let timeout = null;
    if (!Array.isArray(business.stored_business?.location)) {
      if (!business.stored_business?.location?.addresses) return;
      setAllPositions(
        business.stored_business?.location.addresses.map((a) => ({
          position: a.latlon,
          address: a.address,
        }))
      );
      setAddress(business.stored_business?.location.addresses);
      timeout = setTimeout(() => {
        setPosition({
          position: business.stored_business?.location.addresses[0].latlon,
          address: business.stored_business?.location.addresses[0].address,
        });
      }, 100);
    }

    return () => {
      if (timeout) {
        timeout = clearTimeout(timeout);
      }
    };
  }, []);

  const alert = useAlert();

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  const removeLocation = (addr) => {
    setAddress([...address.filter((i) => addr !== i.address)]);
    setAllPositions([...allPositions.filter((i) => addr !== i.address)]);
  };

  useEffect(() => {
    if (position) {
      business.updateLocation({
        addresses: parseAddresses(address),
        hide_on_listing: hideOnListing,
      });
    }
  }, [address, position, hideOnListing]);

  useEffect(() => {
    console.log(position)
  }, [])

  const parseAddresses = (addresses) => {
    return addresses.map((add, indx) => {
      const lng = add.latlon.lng ? add.latlon.lng : add.latlon.lon

      return {
        ...add,
        latlon: { lat: add.latlon.lat, lon: lng }
      }
    })
  }

  const searchPlaces = async (addr) => {
    setSearch("");
    setPosition({
      position: { lat: addr.latlon.lat, lon: addr.latlon.lng },
      address: addr.address,
    });
    if (!address.some((add) => add.address === addr.address)) {
      setAddress([...address, addr]);
      setAllPositions([
        ...allPositions,
        { latlon: { lat: addr.latlon.lat, lon: addr.latlon.lng }, address: addr.address },
      ]);
    } else {
      alert.setalert({
        type: "error",
        title: "Duplicate!",
        body: "Duplicate locations are not allowed",
      });
    }
  };

  const updateIndexPosition = (data) => {
    const { lat, lng, lon } = data;
    setAllPositions([
      ...allPositions.map((p) => {
        if (p.address === data.address) {
          return {
            ...p,
            latlon: { lat, lon: lng ? lng : lon },
          };
        } else {
          return p;
        }
      }),
    ]);
    setAddress([
      ...address.map((a) => {
        if (a.address === data.address) {
          return {
            ...a,
            latlon: { lat, lon: lng ? lng : lon },
          };
        } else {
          return a;
        }
      }),
    ]);
    setPosition({ position: { lat, lon: lng ? lng : lon }, address: data.address });
  };

  const closeOpened = (e) => {
    setIsOpen(e);
  };

  return (
    <div className="flex h-auto items-start justify-start w-full">
      <SearchAddressModal
        home={null}
        handleAction={searchPlaces}
        address={search}
        open={isOpen}
        setOpen={closeOpened}
      />
      <div className="py-5 w-full md:w-[60%]">
        <div>
          <div className="text-sm max-w-lg px-3">
            For a more percise location Information, you can zoom in and move
            the Map pin around to set exactly your location.
          </div>
          <div onClick={() => setIsOpen(true)} className="max-w-lg">
            <DashboardFormField
              name="Location"
              placeholder="enter your address"
              text={search}
              setText={setSearch}
            />
          </div>
          <div>
            <div className="max-w-xl divide-y-2 mb-5 bg-white space-y-2">
              {address.map((loc, i) => {
                return (
                  <div
                    key={i}
                    className="flex justify-between items-center px-3 py-2"
                  >
                    <div
                      className=" cursor-pointer"
                      onClick={() => {
                        setPosition({
                          position: { lat: loc.latlon.lat, lon: loc.latlon.lng },
                          address: loc.address,
                        })
                      }
                      }
                    >
                      <div className="font-bold">{loc.city}</div>
                      <div className="inline text-ellipsis overflow-clip">
                        {loc.address}
                      </div>
                    </div>
                    <div
                      onClick={() => removeLocation(loc.address)}
                      className="cursor-pointer"
                    >
                      <XIcon className="w-5 h-5" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <MapUI
          setPosition={updateIndexPosition}
          position={{ lat: position?.position.lat, lng: position?.position.lon }}
          address={position?.address}
        />
        <FormChecker
          selected={hideOnListing}
          setSelected={setHideOnListing}
          text="Hide Business address on listings"
        />
        <div className="w-full h-[200px]" />
      </div>
      <div className="hidden md:w-[40%] h-full md:flex justify-start">
        {/* <TipsCard /> */}
      </div>
    </div>
  );
};

export default LocationForm;
