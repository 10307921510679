import categories from '../../assets/categories.json'


class BusinessCategories {
    constructor() {
        this.categories = categories
    }

    getCategories(cat) {

    }

    getSubCategories(query) {
        let result = null
        for (var category of Object.keys(this.categories)) {
            const filter = this.categories[category].filter((n) => n.category.includes(query))
            if (filter.length > 0) {
                result = { category, sub_category: filter[0] }
                break
            }
        }

        return result
    }

    search(query){
        if (query.length <= 0) return []
        let result = []
        for (var category of Object.keys(this.categories)) {
            let filter = this.categories[category].map((n) => {
                if(n.category.toLowerCase().includes(String(query).toLowerCase())) return {category, sub_category: n.category, data: n.sub_categories}
            })
            filter = filter.filter(n => n !== undefined)
            if (filter.length > 0) {
                result.push(...filter)
            }
        }
        return result
    }

    match(query){
        if (query.length <= 0) return []
        let result = []
        for (var category of Object.keys(this.categories)) {
            let filter = this.categories[category].map((n) => {
                if(n.category.includes(String(query))) return {category, sub_category: n.category, data: n.sub_categories}
            })
            filter = filter.filter(n => n !== undefined)
            if (filter.length > 0) {
                result.push(...filter)
            }
        }
        return result
    }
}

export { BusinessCategories }