import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import FormChecker from '../../../../DashboardComponents/FormComponents/FormChecker'
import TipsCard from '../../../../DashboardComponents/FormComponents/TipsCard'
import facility from '../../../../../../assets/amenities.json'

const acceptedCurrency = {
    euro: false,
    lira: false,
    pounds: false,
    usd: false
}



export const bill = {
    gas: false,
    water: false,
    internet: false,
    electricity: false
}

const FacilitiesForm = ({ business }) => {
    const [facilities, setFacilities] = useState(null)
    const [securityFac, setSecFacilities] = useState(null)
    const [bills, setBills] = useState(null)
    const [racker, setRack] = useState(null)

    useEffect(() => {

        if (business?.facilities && !Array.isArray(business?.facilities) && business?.facilities.facilities !== null) {
            console.log(business.facilities? business.facilities: facilities )
            
            setFacilities(business.facilities.facilities? business.facilities.facilities: facilities )
            setSecFacilities(business.facilities.securityFacilities? business.facilities.securityFacilities: securityFac )
            setBills(business.facilities.bills? business.facilities.bills: bills )

        } else {
            setFacilities(facility.facility)
            setSecFacilities(facility.securityFacilities)
            setBills(bill)
            setRack([])
        }

    }, [])

    useEffect(() => {

        business.updateFacilities({
            facilities,
            securityFacilities: securityFac,
            bills
        })
    }, [racker])


    return (
        <div className='w-full flex items-start'>
            <div className='py-2 w-full lg:w-[60%] grid grid-cols-1 pb-32 lg:pb-0 md:grid-cols-2'>
                <div>
                    <div className='text-lg font-semibold text-gray-400 py-4'>Facilities</div>
                    <div>
                        {
                            Object.keys(facilities ?? {}).map((i, indx) => {
                                return <Item key={indx} item={i} facilities={facilities} setFacilities={setFacilities} setTmp={setRack} />
                            })
                        }
                    </div>
                </div>
                <div className='space-y-4'>
                    <div>
                        <div className='text-lg font-semibold text-gray-400 py-4'>Safety Facilities</div>
                        <div>
                            {
                                Object.keys(securityFac ?? {}).map((i, indx) => {
                                    return <Item key={indx} item={i} facilities={securityFac} setFacilities={setSecFacilities} setTmp={setRack} />
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='hidden lg:block'>
                {/* <TipsCard /> */}
            </div>
        </div>
    )
}

export default FacilitiesForm

const Item = ({ item, facilities, setFacilities, setTmp }) => {
    const [check, setCheck] = useState(false)

    useEffect(() => {
        setCheck(facilities[item])
    }, [])

    useEffect(() => {
        const fac = facilities
        fac[item] = check
        setFacilities(fac)
    }, [check])

    const change = (value) => {
        setCheck(value)
        setTmp({ item: item })
    }

    return (
        <div>
            <FormChecker selected={check} setSelected={change} text={(<div className='capitalize'>{item.split('_').join(' ')}</div>)} />
        </div>
    )
}