import addNotification from "react-push-notification";
export const NOTIFICATION_CHANNELS = {
  chats: 0,
};

class NotificationManager {
  channels = [
    {
      id: 0,
      name: "chat",
      paused: false,
    },
  ];

  users_channels = [];

  constructor() {
    this.requestPermission();
  }

  async requestPermission() {
    console.log("Requesting permission...");
    if (Notification) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        }
      });
    }
    return;
  }

  async muteNotification(id) {
    this.channels[id]["paused"] = true;
  }

  async resumeNotification(id) {
    this.channels[id]["paused"] = false;
  }

  async muteUserNotificationChannel(uid) {
    this.users_channels = uid;
    if (this.users_channels !== uid) {
      this.muteUserNotificationChannel(uid);
    }
    console.log(this.users_channels, "``````````````````````````````;;");
  }

  async resumeUserNotificationChannel() {
    this.users_channels = null;
  }

  showNotification(data, channelID, uid) {
    if (this.channels[channelID].paused) return;
    if (this.users_channels === uid) return;

    var options = {
      theme: "darkblue",
      colorTop: "green",
      backgroundTop: "green", //optional, background color of top container.
      backgroundBottom: "darkgreen",
      native: true,
      ...data,
    };
    console.log("BLOCKED LIST", this.users_channels, "===>", uid);
    addNotification(options);
  }
}

export default NotificationManager;
