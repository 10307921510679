import React, { useEffect, useState } from 'react'
import { useHousingForm } from '../../../../../../Components/UtilityComponents/HousingFormContext'
import FormChecker from '../../../../DashboardComponents/FormComponents/FormChecker'
import { reviewData } from '../../../Main/SubScreens/Settings/Forms/data'

const ReviewForm = ({ housing }) => {
    const [reviewMessages, setReviewMessages] = useState([])

    useEffect(() => {

    }, [reviewMessages])

    return (
        <div>
            <div className='flex flex-col space-y-10 pb-[100px]'>
                <div>
                    <div className='space-y-10'>
                        {
                            reviewData.map((review, indx) => {
                                return <ReviewMessage key={indx} review={review} index={indx} reviewMessages={reviewMessages} setReviewMessages={setReviewMessages} checker={review.condition} />
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const ReviewMessage = ({ review, checker = null, index, reviewMessages, setReviewMessages }) => {

    const [agreed, setAgreed] = useState(false)
    const housing = useHousingForm()


    useEffect(() => {
        setAgreed(housing.review[0]?.agreed)
    }, [])

    useEffect(() => {
        if (checker) {

            if (!reviewMessages.some((rm, indx) => rm.id === review.id)) {
                setReviewMessages([...reviewMessages, {
                    id: review.id,
                    title: review.condition.text,
                    agreed
                }])
            } else {
                const tmp = reviewMessages
                reviewMessages.map((reviewItem, i) => {
                    if (reviewItem.id === review.id) {
                        tmp[i] = {
                            id: review.id,
                            title: review.condition.text,
                            agreed
                        }
                    }
                })

                setReviewMessages(tmp)
                housing.setAcceptedAgreement(agreed)
                housing.updateReview({ terms: agreed })
            }
        }
    }, [agreed])

    useEffect(() => {
    }, [reviewMessages, agreed]) 

    return (
        <div className='w-full flex items-start  space-x-10'>
            <div className='w-5 h-5 rounded-md mt-2 bg-primary-500'></div>
            <div className='flex flex-col space-y-2'>
                <div className='text-lg font-semibold'>{review.title}</div>
                <div className='text-sm'>{review.description}</div>
                <div>
                    {checker && <FormChecker selected={agreed} setSelected={setAgreed} text={checker.text} />}
                </div>
            </div>
        </div>
    )
}

export default ReviewForm