import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { get_service_orders } from '../../../functions/service_functions.js/index.js';
import { useAuth } from '../AuthProvider/index.js';

const initOrder = {
    groupByStatus: {},
    allJobs: [],
    setGroupByStatus: () => null,
    loadJobs: () => null,
    setAllJobs: () => null
}

const JobsContextProvider = createContext(initOrder)

const JobsContext = ({ children }) => {
    const auth = useAuth()

    const [groupByStatus, setGroupByStatus] = useState({})
    const [allJobs, setAllJobs] = useState(null)

    useEffect(() => {
        let mounted = true

        if (mounted) {
            loadJobs()
        }

        return () => {
            mounted = false
        }
    }, [])


    useEffect(() => {
        console.log('STATUSES: ', groupByStatus)
    }, [groupByStatus])


    const loadJobs = (uid) => {
        const id = uid ? uid : auth?.user?.uid ? auth?.user?.uid : auth?.user?.user?.uid
        get_service_orders(id).then(jobs => {
            const _allJobs = JSON.parse(jobs)
            setAllJobs(_allJobs)
            const statusGroups = _allJobs.reduce((group, job) => {
                const { status } = job;
                group[status] = group[status] ?? [];
                group[status].push(job);
                return group;
            }, {});

            return statusGroups
        }).then((result) => {
            setGroupByStatus(result)
        })
    }


    const value = {
        groupByStatus,
        allJobs,
        setAllJobs,
        setGroupByStatus,
        loadJobs
    }
    return (
        <JobsContextProvider.Provider value={value}>
            {children}
        </JobsContextProvider.Provider>
    );

}

export const useJobContext = () => useContext(JobsContextProvider)

export default JobsContext 