import React from 'react'

const DashboardFormLabeledRight = ({title, required = false, detail = '', text, setText, placeholder, label, maxLength = undefined}) => {
    return (
        <div className='flex w-full md:w-auto flex-col flex-1'>
            <div className='w-full py-2 px-4 border-2 border-gray-300 rounded-xl flex bg-white flex-col text-sm text-textcolor'>
                <div className='flex justify-between items-center text-textcolor'>
                    <span className='font-medium text-md '>{title} {required && '*'}</span>
                </div>
                <div className='w-full flex justify-between items-start overflow-hidden'>
                    <div className='flex items-center space-x-2'>
                        <input onChange={(e) => setText(e.target.value)} maxLength={maxLength} placeholder={placeholder} value={text} className='pt-1 bg-none h-full outline-none w-auto' />
                    </div>
                    <div>{label}</div>
                </div>
            </div>
            <div className='text-sm px-5 -mt-3'>{detail} </div>
        </div>
    )
}

export default DashboardFormLabeledRight