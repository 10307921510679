import React, { useEffect, useState } from "react";
import DashboardFormField from "../../../../DashboardComponents/DashboardFormField";
import TipsCard from "../../../../DashboardComponents/FormComponents/TipsCard";

const DescriptionForm = ({ business }) => {
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (business.stored_business?.description) {
      if(typeof business.stored_business?.description === 'string'){
        setDescription(business.stored_business?.description);
      }else{
        setDescription('')
      }
    }
  }, []);

  useEffect(() => {
    business.updateDescription(description);
  }, [description]);

  return (
    <div className="flex h-auto items-start justify-start w-full">
      <div className="py-5 w-full md:w-[60%] ">
        <div>
          <div className="text-sm max-w-lg px-3">
            The first rule of business is presesntation. Tell you clients about
            yourself, your experiences and advantages you bring over other
            simillar business.{" "}
          </div>
          <div className="max-w-lg">
            <DashboardFormField
              rows={10}
              multiline={true}
              name="Description"
              placeholder="describe your business in a few words"
              maxLength={false}
              text={description}
              setText={setDescription}
            />
          </div>
          <div className="max-w-lg flex justify-end px-5">
            {description?.split(" ")?.length} / <b> words </b>
          </div>
        </div>
      </div>
      <div className="hidden md:w-[40%] h-full md:flex justify-start">
        {/* <TipsCard /> */}
      </div>
    </div>
  );
};

export default DescriptionForm;
