import React from 'react'
import Ratings from 'react-ratings-declarative/build/ratings'

const RatingBubble = (props) => {
    const {by, at, comment, rating} = props
  return (
    <div className='space-y-2 p-5 px-7 w-full text-sm text-textcolor rounded-xl bg-gray-100 text-xs'>
    <div className='flex justify-between items-center text-left'>
        <div className='flex items-center space-x-2'>by {` ${by} `} {`${at}`}</div>
        <span className='flex justify-center space-x-0 sm:space-x-2 items-center'>
        <Ratings 
            rating={rating}
            widgetDimensions="15px"
            widgetSpacings="1px"
            widgetRatedColors="#F2C94C"
        >
            <Ratings.Widget />
            <Ratings.Widget />
            <Ratings.Widget />
            <Ratings.Widget />
            <Ratings.Widget />
        </Ratings>
        <span className='hidden sm:flex'>({rating})</span>
        </span>
    </div>
    <div className='text-left'>
    {"“"+comment+"”"}
    </div>
</div>
  )
}

export default RatingBubble

export const LoadingRatingBubble = () => {
return (
  <div className='space-y-2 p-5 px-7 w-full text-textcolor rounded-xl bg-gray-100'>
  <div className='flex justify-between items-center text-left'>
      <div className='flex items-center h-4 w-[60%] bg-gray-200 animate-pulse space-x-2 rounded-md'/> 
      <span className='flex justify-center space-x-0 sm:space-x-2 items-center'>
      <Ratings 
          rating={0}
          widgetDimensions="15px"
          widgetSpacings="1px"
          widgetRatedColors="#F2C94C"
      >
          <Ratings.Widget />
          <Ratings.Widget />
          <Ratings.Widget />
          <Ratings.Widget />
          <Ratings.Widget />
      </Ratings>
      <span className='hidden sm:flex h-4 w-4 bg-gray-200 animate-pulse rounded-md'/>
      </span>
  </div>
  <div className='text-left  h-4 w-[80%] bg-gray-200 animate-pulse rounded-md'/>
  <div className='text-left  h-4 w-[70%] bg-gray-200 animate-pulse rounded-md'/>
</div>
)
}

