import {
  ChatIcon,
  MailIcon,
  ShareIcon,
  ThumbDownIcon,
  ThumbUpIcon,
} from "@heroicons/react/outline";
import {
  ThumbDownIcon as ThumbDownIconSolid,
  ThumbUpIcon as ThumbUpIconSolid,
} from "@heroicons/react/solid";
import moment from "moment";
import React, { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { PromptLoginRequired } from "../../../Components/UtilityComponents/PromptLoginRequired";
import {
  fetch_blog,
  fetch_blog_reactions,
  fetch_simillar_blogs,
  update_blog_reaction,
} from "../../../functions/blog_functions";
import { BlogBlockTypes } from "../../Dashboard/Screens/Timetable/EditBlogPost";
import { BlogReactions } from "../data";
import Loader from "../../../assets/spinner.svg";
import { abbreviateNumber } from "../../../functions/service_functions.js";
import { Link } from "react-router-dom";
import YouTubeVideoView from "../../../Components/YouTubeVideoView";
import ShareModal from "../../../Components/ShareModal";
import { APP_URL } from "../../../assets/url";
import { PrintHTML } from "../../Dashboard/Screens/Timetable/EditBlogPost/BlogParagraph";
// import Metatags from '../../../Components/Metatags'

const BlogView = ({ user, distanceFromTopOfScreen }) => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [similarBlogs, setSimillarBlog] = useState(null);
  const [atTopOfScreen, setAtTopOfScreen] = useState(0);
  const [loadingReaction, setLoadingReaction] = useState(false);
  const [likeStatus, setLikeStatus] = useState({ count: 0, liked: false });
  const [dislikeStatus, setDislikeStatus] = useState({
    count: 0,
    disliked: false,
  });
  const [logginRequired, setLoginRequired] = useState(false);
  const [openShare, setOpenShare] = useState(false);

  useEffect(() => {
    fetch_blog(id)
      .then((res) => {
        setBlog(res);
        console.log(res);
        return res;
      })
      .then((resp) => {
        fetch_simillar_blogs(resp.category, 3).then((response) => {
          setSimillarBlog(response);
        });
        return resp;
      })
      .then((resu) => {
        fetch_blog_reactions(resu.id).then((reactn) => {
          console.log(reactn, "===<>", reactn[0].type);

          const any_disliked = reactn.filter(
            (i) =>
              i.uid === user?.user.uid && i.type === BlogReactions.DISLIKE_POST
          );

          if (any_disliked.length > 0) {
            setDislikeStatus({
              count: resu.reactions[BlogReactions.DISLIKE_POST] ?? 0,
              disliked: any_disliked.length > 0,
              id: any_disliked[0].id,
            });
          }
          const any_liked = reactn.filter(
            (i) =>
              i.uid === user?.user.uid && i.type === BlogReactions.LIKE_POST
          );
          if (any_liked.length > 0) {
            setLikeStatus({
              count: resu.reactions[BlogReactions.LIKE_POST] ?? 0,
              liked: any_liked.length > 0,
              id: any_liked[0].id,
            });
          }
        });
      });
  }, [id]);

  useEffect(() => {
    setAtTopOfScreen(distanceFromTopOfScreen);
  }, [distanceFromTopOfScreen]);

  const handleReaction = (type) => {
    setLoadingReaction(type);
    if (!user?.user.uid && type !== BlogReactions.SHARE_POST) {
      return setLoginRequired(true);
    }
    const data = {
      id: blog.id,
      type: type,
      other: {
        type: type,
        created_at: new Date().toUTCString(),
      },
    };

    if (type === BlogReactions.LIKE_POST) {
      if (likeStatus.liked === false) {
        data["other"] = {
          uid: user?.user.uid,
          type: type,
          created_at: new Date().toUTCString(),
        };
      } else {
        data["other"] = {
          id: likeStatus.id,
          undo: true,
        };
      }
    }

    if (type === BlogReactions.DISLIKE_POST) {
      if (!dislikeStatus.disliked) {
        data["other"] = {
          uid: user?.user.uid,
          type: type,
          created_at: new Date().toUTCString(),
        };
      } else {
        data["other"] = {
          id: dislikeStatus.id,
          undo: true,
        };
      }
    }

    console.log(data, type, likeStatus);

    update_blog_reaction(data)
      .then((reaction_id) => {
        if (type === BlogReactions.LIKE_POST) {
          if (dislikeStatus.disliked) {
            handleReaction(BlogReactions.DISLIKE_POST);
          }
          if (!likeStatus.liked) {
            setLikeStatus({
              count: likeStatus.count + 1,
              liked: true,
              id: reaction_id,
            });
          } else {
            setLikeStatus({
              count: likeStatus.count - 1,
              liked: false,
            });
          }
        } else if (type === BlogReactions.DISLIKE_POST) {
          if (likeStatus.liked) {
            handleReaction(BlogReactions.LIKE_POST);
          }
          if (!dislikeStatus.disliked) {
            setDislikeStatus({
              count: dislikeStatus.count + 1,
              disliked: true,
              id: reaction_id,
            });
          } else {
            setDislikeStatus({
              count: dislikeStatus.count - 1,
              disliked: false,
            });
          }
        }
      })
      .finally(() => {
        setLoadingReaction(false);
      });
  };

  useEffect(() => {}, [atTopOfScreen]);

  const sharePost = async () => {
    await handleReaction(BlogReactions.SHARE_POST);
  };

  const read_time =
    blog?.post.filter((i) => i.type === BlogBlockTypes.PARAGRAPH_BLOCK)
      .length ?? 0;
  const paragraphs_and_images =
    blog?.post.filter((i) =>
      [BlogBlockTypes.PARAGRAPH_BLOCK, BlogBlockTypes.IMAGE_BLOCK].includes(
        i.type
      )
    ).length ?? 0;

  return blog ? (
    <div className="w-full flex flex-col items-center overflow-y-auto scrollbar h-full">
      {/* <Metatags 
                    title={blog.title} 
                    description={getBlogDescriptionText(blog.post[0].value).slice(0, 50)} 
                    page={'Guide Student Blog'} 
                    image={blog.cover} 
                    author={blog.user.name} 
                    url={`${APP_URL}/blog/${id}`}
                    /> */}

      <div className="flex w-full space-y-5 py-5 flex-col justify-center items-center text-center">
        <div className="text-3xl lg:text-5xl font-extrabold">{blog.title}</div>
        <div className="flex flex-col md:flex-row items-center md:space-x-2 text-sm md:text-lg font-medium text-gray-400">
          <div>{blog.user.name}</div>
          <div>·</div>
          <div>{moment(blog.created_at).format("lll")}</div>
          <div>·</div>
          <div>{read_time} mins read</div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center py-5 space-y-3 w-full px-2 md:px-5 lg:px-0 lg:w-[80%]">
        <img
          src={blog.cover}
          className="w-full h-auto rounded-lg object-cover object-center"
        />
        {logginRequired && <PromptLoginRequired />}
        <div className="flex w-full flex-col lg:flex-row items-center lg:items-start justify-around">
          <div className="w-full lg:w-24 relative">
            <div
              className={`px-5 lg:px-0 ease_transition flex flex-row lg:flex-col py-4 lg:py-10 ${
                atTopOfScreen > 1200
                  ? "md:fixed md:left-[calc(10% + 2.5rem)] lg:top-24"
                  : "relative"
              } ${
                atTopOfScreen > 400 * paragraphs_and_images
                  ? "opacity-0"
                  : "opacity-100"
              } space-x-5 lg:space-x-0 lg:space-y-5 justify-start lg:justify-center items-center`}
            >
              <div
                onClick={() => handleReaction(BlogReactions.LIKE_POST)}
                className={`w-10 lg:w-20 h-10 lg:h-20 rounded-full cursor-pointer hover:text-black/50 text-back ${
                  loadingReaction === BlogReactions.LIKE_POST && "animate-pulse"
                } bg-primary-200 flex flex-col space-y-0 justify-center items-center`}
              >
                {likeStatus.liked ? (
                  <ThumbUpIconSolid className="w-5 md:w-7 h-5 md:h-7" />
                ) : (
                  <ThumbUpIcon className="w-7 h-7" />
                )}
                <span className="text-xs lg:text-base font-medium">
                  {abbreviateNumber(likeStatus.count)}
                </span>
              </div>

              <div
                onClick={() => handleReaction(BlogReactions.DISLIKE_POST)}
                className={`w-10 lg:w-20 h-10 lg:h-20 rounded-full cursor-pointer hover:text-black/50 text-back bg-primary-200 ${
                  loadingReaction === BlogReactions.DISLIKE_POST &&
                  "animate-pulse"
                } flex flex-col space-y-0 justify-center items-center`}
              >
                {dislikeStatus.disliked ? (
                  <ThumbDownIconSolid className="w-5 md:w-7 h-5 md:h-7" />
                ) : (
                  <ThumbDownIcon className="w-7 h-7" />
                )}
                <span className="text-xs lg:text-base font-medium">
                  {abbreviateNumber(dislikeStatus.count)}
                </span>
              </div>

              <div
                onClick={() => setOpenShare(true)}
                className="w-10 lg:w-20 h-10 lg:h-20 rounded-full text-black cursor-pointer hover:text-black/50 bg-primary-200 flex justify-center items-center"
              >
                <ShareIcon className="w-5 md:w-7 h-5 md:h-7" />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-[80%] flex flex-col text-xl py-4 lg:py-10 font-medium items-center space-y-10 text-left leading-relaxed">
            {blog.post.map((block, indx) => {
              return <BlogPostItem block={block} key={indx} />;
            })}
          </div>
        </div>
        <div className="py-10 w-full">
          <div>What did you think of this article</div>
          <div
            className={`flex flex-row py-10 space-x-5 justify-center items-center`}
          >
            <div
              onClick={() => handleReaction(BlogReactions.LIKE_POST)}
              className={`w-10 h-10 rounded-full hover:bg-primary-500/50 cursor-pointer text-white bg-primary-500 ${
                loadingReaction === BlogReactions.LIKE_POST && "animate-pulse"
              } flex flex-col space-y-0 justify-center items-center`}
            >
              {likeStatus.liked ? (
                <ThumbUpIconSolid className="w-5 h-5" />
              ) : (
                <ThumbUpIcon className="w-5 h-5" />
              )}
            </div>

            <div
              onClick={() => handleReaction(BlogReactions.DISLIKE_POST)}
              className={`w-10 h-10 rounded-full hover:bg-primary-500/50 ${
                loadingReaction === BlogReactions.DISLIKE_POST &&
                "animate-pulse"
              } cursor-pointer text-white bg-primary-500 flex flex-col space-y-0 justify-center items-center`}
            >
              {dislikeStatus.disliked ? (
                <ThumbDownIconSolid className="w-5 h-5" />
              ) : (
                <ThumbDownIcon className="w-5 h-5" />
              )}
            </div>

            {/* <div className='w-auto px-4 h-10 rounded-full space-x-2 text-white bg-primary-500 flex justify-center items-center'>
                            <ChatIcon className="w-5 h-5" />
                            <span>3.4k</span>
                        </div> */}
          </div>
        </div>
        <div className="w-full">
          <div className="flex flex-col justify-center w-full items-center space-y-4 p-5 bg-primary-500/30 rounded-xl">
            <img
              src={blog.user.photo}
              className="w-14 h-14 rounded-full object-cover object-center"
            />
            <div className="flex flex-col space-y-2">
              <div className="text-xl font-medium">{blog.user.name}</div>
              <div className="text-sm font-medium">author</div>
            </div>
            <div>
              {/* <div className='w-10 h-10 rounded-full text-primary-500 flex flex-col space-y-0 justify-center items-center'>
                                <MailIcon className="w-7 h-7" />
                            </div> */}
            </div>
          </div>
        </div>
        <div className="pt-10 space-y-5 border-t-gray-200 border-t-2 w-full">
          <div className="flex justify-between items-center">
            <div className="text-xl md:text-2xl font-bold text-primary-800">
              Further Reading
            </div>
            <Link
              to="/blog"
              className="text-xl font-medium hover:underline cursor-pointer text-orange-500"
            >
              more articles
            </Link>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {similarBlogs
              ? similarBlogs.map((read, i) => {
                  return (
                    <FurtherReadingItem
                      key={i}
                      id={read.id}
                      image={read.cover}
                      title={read.title}
                      mins={
                        read.post.filter(
                          (i) => i.type === BlogBlockTypes.PARAGRAPH_BLOCK
                        ).length
                      }
                    />
                  );
                })
              : [0, 0, 0].map((_, i) => {
                  return <FurtherReadingItemLoading key={i} />;
                })}
          </div>
        </div>
      </div>
      <ShareModal
        callback={sharePost}
        type="Blog Post"
        open={openShare}
        setOpen={setOpenShare}
        url={`${APP_URL}/blog/${id}`}
        message={`Read ${blog.title} by ${blog.user.name} on Guide Students Blog`}
      />
    </div>
  ) : (
    <LoadingPage />
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user?.data,
  };
};

export default connect(mapStateToProps, null)(BlogView);

const BlogPostItem = ({ block }) => {
  switch (block.type) {
    case BlogBlockTypes.IMAGE_BLOCK:
      return (
        <section className="w-full">
          <img
            src={block.value}
            className="w-full h-auto rounded-lg object-cover object-center"
          />
        </section>
      );

    case BlogBlockTypes.YOUTUBE_VIDEO_BLOCK:
      return (
        <section className="w-full">
          <YouTubeVideoView src={block.value} />
        </section>
      );

    case BlogBlockTypes.PARAGRAPH_BLOCK:
      return (
        <p className="w-full px-5 md:px-0">
          {typeof block.value === "string" ? (
            block.value
          ) : (
            <PrintHTML text={block.value} />
          )}
        </p>
      );
    case BlogBlockTypes.SUBTITLE_BLOCK:
      return <h3 className="w-full text-3xl font-semibold">{block.value}</h3>;
    case BlogBlockTypes.TIPS_BLOCK:
      return (
        <section className="w-full text-2xl font-semibold p-5 rounded-lg bg-primary-300 text-primary-500">
          {block.value}
        </section>
      );
    default:
      return null;
  }
};

const FurtherReadingItemLoading = () => {
  return (
    <div className="flex w-full flex-col space-y-3 bg-white p-5 rounded-lg">
      <div className="w-full aspect-video rounded-lg bg-gray-200 animate-pulse" />
      <div className="w-[60%] h-4 rounded bg-gray-200 animate-pulse" />
      <div className="w-[30%] h-3 rounded bg-gray-200 animate-pulse" />
    </div>
  );
};

const FurtherReadingItem = ({ image, title, mins, id }) => {
  return (
    <Link
      to={`/blog/${id}`}
      className="flex w-full flex-col space-y-3 text-left bg-white p-5 rounded-lg"
    >
      <img
        src={image}
        className="w-full object-cover object-center aspect-video rounded-lg"
        alt={title.split(" ").join("_")}
      />
      <div className="text-2xl font-semibold">{title}</div>
      <div className="font-medium text-gray-400">{mins} mins read</div>
    </Link>
  );
};

export const LoadingParagraphSection = ({ lines }) => {
  return (
    <div className="flex flex-col items-start w-full space-y-4">
      {[...Array(lines)].map((_, i) => {
        return (
          <div
            key={i}
            className="w-[70%] h-3 rounded bg-gray-200 animate-pulse"
          />
        );
      })}
    </div>
  );
};

export const LoadingImageSection = () => {
  return (
    <div className="w-full h-auto aspect-video bg-gray-200 animate-pulse rounded-lg" />
  );
};

const LoadingPage = () => {
  return (
    <div className="w-full flex flex-col items-center overflow-y-auto scrollbar h-full">
      <div className="flex w-full space-y-5 py-5 flex-col justify-center items-center text-center">
        <div className="w-[90%] h-7 rounded-md bg-gray-200 animate-pulse" />
        <div className="flex w-[50%] flex-col md:flex-row items-center md:space-x-2 text-sm md:text-lg font-medium text-gray-400">
          <div className="w-[30%] h-3 rounded-md bg-gray-200 animate-pulse" />
          <div>·</div>
          <div className="w-[30%] h-3 rounded-md bg-gray-200 animate-pulse" />
          <div>·</div>
          <div className="w-[30%] h-3 rounded-md bg-gray-200 animate-pulse" />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center py-5 space-y-4 w-full px-2 md:px-5 lg:px-0 lg:w-[80%]">
        <LoadingImageSection />
        <LoadingParagraphSection lines={5} />
        <LoadingParagraphSection lines={5} />
        <LoadingParagraphSection lines={5} />
      </div>
    </div>
  );
};

export const getBlogDescriptionText = (obj) => {
    
  if (!obj) return "........";

  if (typeof obj === "string") {
    return obj;
  } else if (Array.isArray(obj.block)) {
    return obj.blocks[0]?.text;
  } else if (obj[0]?.file) {
    return "image";
  } else if (obj.blocks[0]?.text) {
    return obj.blocks[0].text;
  }
  return ".......";
};
