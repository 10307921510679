import React, { useEffect, useMemo } from 'react';
import { Navigate, useLocation, useNavigate } from "react-router";
import LoginNotice from '../../LoginNotice';
import { useAuth } from '../AuthProvider';

function PromptLoginRequired({ children }) {
  let auth = useAuth();
  let location = useLocation();

  useEffect(() => {
   
  },[])

  const show = (location.pathname !== '/login' && location.pathname !== '/signup') && !auth.user;

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    return show && <LoginNotice location={location} />;
  } 

  

  return children;
}

export { PromptLoginRequired } 