import React from 'react';
import { EyeIcon } from '@heroicons/react/outline'
import { useOutletContext } from 'react-router';
import WelcomeCard from './Cards/WelcomeCard';
import InfoCard from './Cards/InfoCard';
import DashboardWrapper from '../../DashboardComponents/DashboardWrapper';
import SocialsViewCard from './Cards/SocialsViewCard';
import DashboardReviewCard from './Cards/DashboardReviewCard';
import PhotosGridCard from '../../../../Components/PhotosGridCard';
import  WelcomeSVG from '../../../../assets/1-01.svg';


const intervals = [
  { id: 1, value: '30 seconds', unavailable: false },
  { id: 2, value: '30 minutes', unavailable: false },
  { id: 3, value: 'hour', unavailable: false },
  { id: 4, value: 'day', unavailable: true },
  { id: 5, value: 'month', unavailable: false },
]

const imgs = [
  'https://cf.bstatic.com/xdata/images/hotel/max1024x768/267262611.jpg?k=c5202738fb9f021dcd0d0daa060d88d39eb60e27afd35f20b5d6b117d2849d88&o=&hp=1',
  'https://q-xx.bstatic.com/xdata/images/hotel/max1024x768/306933569.jpg?k=726d2bfed904896d6b36e9beca59fa7306d0bb01cb0b4544774a4278859e0a89&o=',
  'https://dmn-dallas-news-prod.cdn.arcpublishing.com/resizer/dn9JmbyHvw0DTUWlpE8V9Z0K4lU=/1660x934/smart/filters:no_upscale()/cloudfront-us-east-1.images.arcpublishing.com/dmn/S4ZX2DV7BZDW5HW54OMMISE4Y4.jpg',
  'https://cdn10.phillymag.com/wp-content/uploads/sites/3/2020/10/biggest-philly-apartments-station-at-willow-grove-model-apartment-petrucci-residential.jpg',
  'https://rentpath-res.cloudinary.com/$img_current/t_3x2_jpg_xl/1016f9abc443af88070c77c4257810b6',
  'https://phillyyimby.com/wp-content/uploads/2021/06/The_Station_At_Willow_Grove_03_Petrucci_Residential-1024x683.jpg'
]

const Main = () => {

  let {user} = useOutletContext()
   user = user?.display_name.split(' ')
   if(user){
     user = user && user?.length > 1? user[1] : user[0]
   }else{
     user = 'User'
   }
   
  return (
    <DashboardWrapper title="Dashboard">
        <div className='flex w-auto xl:min-w-[65%] m-5 mx-3 space-y-5 flex-col'>
          <WelcomeCard 
            user={user} 
            action_text="See how it works"  
            action={() => null} 
            desc="Respond to your guests, see how many people are looking at your business, drive sales and make money"
            image={WelcomeSVG}
            />
          <div className='flex space-y-2 flex-col md:flex-row md:space-y-0'>
          <InfoCard primary_color="bg-darkblue_1" secondary_color="bg-darkblue_2" />
          <InfoCard primary_color="bg-lightblue_1" secondary_color="bg-lightblue_2" />
          <InfoCard primary_color="bg-lightred_1" secondary_color="bg-lightred_2" />
          </div>
          <div className='h-[1px] w-auto m-2 bg-gray-300'/>
          <div className='sub-title'>Performance & Metrics</div>
          <div className='w-full h-fit'>
            <SocialsViewCard showList={true} list={intervals}  >
              <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center'>
                  <EyeIcon className='guide-icon text-textcolor' />
              </div>
              <div className='flex flex-col items-start justify-center text-textcolor'>        
                  <span className='font-bold text-sm xl:text-normal'>Caption of text</span>
                  <span className='text-xs xl:text-small'>Description of the caption of text</span>
              </div>
            </SocialsViewCard>
          </div>
          <div>
            <SocialsViewCard showList={true} title={{head: 'Socials', sub:'social metrics'}} list={intervals} >
              <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4'>
                <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                  <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center'>
                      <EyeIcon className='guide-icon text-textcolor' />
                  </div>
                  <div className='flex flex-col items-start justify-center text-textcolor'>        
                      <span className='font-bold text-sm xl:text-normal'>Caption of text</span>
                      <span className='text-xs xl:text-small text-left'>Description of the caption of text</span>
                  </div>
                </div>
                <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                  <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center'>
                      <EyeIcon className='guide-icon text-textcolor' />
                  </div>
                  <div className='flex flex-col items-start justify-center text-textcolor'>        
                      <span className='font-bold text-sm xl:text-normal'>Caption of text</span>
                      <span className='text-xs xl:text-small text-left'>Description of the caption of text</span>
                  </div>
                </div>
                <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                  <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center'>
                      <EyeIcon className='guide-icon text-textcolor' />
                  </div>
                  <div className='flex flex-col items-start justify-center text-textcolor'>        
                      <span className='font-bold text-sm xl:text-normal'>Caption of text</span>
                      <span className='text-xs xl:text-small text-left'>Description of the caption of text</span>
                  </div>
                </div>
              </div>
            </SocialsViewCard>
          </div>
          <div className='h-0 lg:h-[100px] w-auto m-2'/>
        </div>
        <div className='w-full lg:max-w-[35%]'>
            <div className='flex bg-secondary-white mx-3 rounded-xl'>
                <DashboardReviewCard />
            </div>
            <div>
                <PhotosGridCard imgs={imgs} />
            </div>
        </div>
    </DashboardWrapper>
  )
}

export default Main