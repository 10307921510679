import { ChevronRightIcon } from '@heroicons/react/outline'
import moment from 'moment'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import ImpressionClickTrackerHOC from '../../../Components/ImpressionClickTrackerHOC'
import { fetch_nearby_blog_posts, update_blog_impression } from '../../../functions/blog_functions'
import { updateGroupImpressions } from '../../../functions/service_functions.js'
import { BlogBlockTypes } from '../../Dashboard/Screens/Timetable/EditBlogPost'
import { getBlogDescriptionText, LoadingImageSection, LoadingParagraphSection } from '../BlogView'
import { BlogImpressions } from '../data'

const BlogList = () => {

    const [location, setLocation] = useState(null)
    const [loading, setLoading] = useState(false)
    const [blogs, setBlogs] = useState(false)

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setLoading(true)
            setLocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            })
            fetch_nearby_blog_posts({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                radius: 10000
            }).then((res) => {
                // console.log(res)
                setBlogs(res.v)
            })
        })
    }, [])
    return (
        <div className='flex flex-col space-y-10 w-full md:px-[5%] px-5 items-center'>
            <div className='w-full space-y-3 py-5 text-left px-5'>
                <div className='text-5xl font-bold text-primary-500'>Students Blog</div>
                <div className='text-xl font-medium text-gray-400'>Read and share your thoughts with fellow students on Guide.</div>
            </div>
            <div className='flex flex-col space-y-4 w-full'>
                {
                    blogs ?
                        blogs.map((blog, i) => {
                            return <BlogItemCard blog={blog} key={i} />
                        })
                        :
                        [...Array(5)].map(() => {
                            return <LoadingCard />
                        })
                }

            </div>
        </div>
    )
}

export default BlogList


const LoadingCard = () => {
    return (
        <div className='w-full flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 items-center h-auto p-5 '>
            <div className='w-full lg:w-[30%]'>
                <LoadingImageSection />
            </div>
            <div className='w-full lg:w-[67%] flex flex-col space-y-4 justify-start items-start'>
                <div className='w-[80%] h-5 bg-gray-200 animate-pulse rounded' />
                <div className='w-[80%] h-5 bg-gray-200 animate-pulse rounded' />
                <LoadingParagraphSection lines={4} />
                <div className='w-24 h-3 bg-gray-200 animate-pulse rounded' />
            </div>
        </div>
    )
}


const BlogItemCard = ({ blog }) => {

    const navigate = useNavigate()

    const handleImpression = async (type = 'view', ptype = BlogImpressions.POST_VIEW) => {
        return await updateGroupImpressions({
            id: blog.group_id,
            type: type
        }).then(async () => {
            await handleUpdatePostMetric(ptype)
        })
    }

    const handleUpdatePostMetric = async (type) => {
        const data = {
            id: blog.id,
            type: type
        }

        return await update_blog_impression(data)
    }

    const handleNaviagteToPost = async () => {
        await handleImpression('click', BlogImpressions.POST_CLICKS)
        navigate(`/blog/${blog.id}`)
    }

    return (
        <ImpressionClickTrackerHOC onImpression={handleImpression} >
            <div className='w-full flex text-left flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 items-center h-auto p-5 '>
                <div className='w-full lg:w-[30%]'>
                    <img src={blog.cover} className="w-full h-auto aspect-video rounded-lg object-center object-cover" />
                </div>
                <div className='w-full lg:w-[67%] flex flex-col space-y-4 justify-start items-start'>
                    <div className='text-4xl font-bold text-primary-800' >{blog.title}</div>
                    <div className='text-xl font-medium'>{getBlogDescriptionText(blog.post[0].value).slice(0, 200)}...</div>
                    <div className='text-xl font-medium flex items-center space-x-4'>
                        <div className='text-primary-500'>{blog.post.filter(i => i.type === BlogBlockTypes.PARAGRAPH_BLOCK).length} min read</div>
                        <div>·</div>
                        <div className='text-gray-400'>{moment(blog.created_at).format('ll')}</div>
                    </div>
                    <div onClick={handleNaviagteToPost} className='text-primary-500 w-full justify-end text-lg font-semibold flex items-center space-x-2 cursor-pointer'>
                        <div>Read Post</div>
                        <ChevronRightIcon className='w-7 h-7 stroke-2' />
                    </div>
                </div>
            </div>
        </ImpressionClickTrackerHOC>
    )
}