import { CLEAR_ORDER, SAVE_ORDER } from "../../actionTypes/orderActionTypes"

export const save_order = (data) => {
    return (dispatch) => {
        dispatch({
            type: SAVE_ORDER,
            payload: data,
            error: null
        })
    }
}

export const clear_order = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ORDER,
            payload: null,
            error: null
        })
    }
}