export const categories = [
    {
        id: 0,
        title: 'Cooks and chefs',
        value: 'cooks_and_chefs',
    },
    {
        id: 1,
        title: 'Photography',
        value: 'photography',
    },
    {
        id: 2,
        title: 'Repair and technical support',
        value: 'repair_and_technical_support',
    },
    {
        id: 3,
        title: 'Writing, translation, and transcription',
        value: 'writing_translation_and_transcription',
    },
    {
        id: 4,
        title: "Personal",
        value: "personal"
    },
    {
        id: 5,
        title: "Design and web",
        value: "design_and_web"
    },
    {
        id: 6,
        title: "Crafts",
        value: "crafts"
    },
    {
        id:7,
        title:'Events',
        value:"events"
    },
    {
        id:8,
        title:'Business',
        value:"business"
    },
    {
        id:9,
        title:'Pets',
        value:"pets"
    },
    {
        id:10,
        title:'Wellness',
        value:"wellness"
    },
    {
        id: 11,
        title: "Home Improvement",
        value: "home_improvement"
    },
    {
        id: 12,
        title: "Legal",
        value: "legal"
    },
    {
        id:13,
        title:"Lessons",
        value:"lessons"
    },
    {
        id:14,
        title:"Lifting and moving",
        value:"lifting_and_moving"
    }
]