import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useOutletContext } from 'react-router'
import { useBlogDrafts } from '../../../../Components/UtilityComponents/BlogContext'
import { load_user_blogs_posts } from '../../../../functions/blog_functions'
import { clear_timetable, store_timetable } from '../../../../store/actions/timetableActions'
import DashboardGroupMetricsView from '../../DashboardComponents/DashboardGroupMetricsView'
import DashboardSliderOver from '../../DashboardComponents/DashboardSliderOver'
import DashboardWrapper from '../../DashboardComponents/DashboardWrapper'
import DailyViewTimetable from './Cards/DailyViewTimetable'
import DaysLeftCard from './Cards/DaysLeftCard'
import NoTimeTableCard from './Cards/NoTimeTableCard'
import WeatherCard from './Cards/WeatherCard'
import TimeTableWelcomeCard from './Cards/WelcomeCard'
import Blog from './EditBlogPost/Blog'
import { getTimetableData } from './functions'
import RecentBlogPosts from './RecentBlogPosts'
import EditTimetable from './Screens/EditTimetable'

const Timetable = ({ timetable, current_user, storeTimetable }) => {


    const [slideFunc, setSlideFunc] = useState(false)
    const [blogSlideFunc, setBlogSlideFunc] = useState(false)
    const [timetableData, setTimetableData] = useState(null)
    const [editData, setEditData] = useState(null)
    const [recents, setRecents] = useState(null)
    const [groupInfo, setGroupInfo] = useState(null)

    let { user } = useOutletContext()

    const inUse = user?.groupIDs?.some(i => i.type === 'student')
    const GROUP_ID = user?.groupIDs?.filter(i => i.type === 'student')[0]
    const blogs = useBlogDrafts()

    useEffect(() => {
        let mounted = true

        if (mounted) {
            getTimetableData(current_user?.user.uid).then((res) => {
                storeTimetable(res)
            }).catch((err) => {
                storeTimetable(timetable?.data)
            })
        }

        return () => {
            mounted = false
        }
    }, [])

    useEffect(() => {
        setRecents(blogs.blogs)
    }, [blogs.blogs,blogs.savingDraft, blogSlideFunc])

    useEffect(() => {
        setTimetableData(timetable?.data)
    }, [timetable?.data])

    const updateEditState = async (data) => await setEditData(data)

    const handleSetEdit = (value) => {
        updateEditState(value).then(() => {
            setBlogSlideFunc(!blogSlideFunc)
        })
    }

    const handleNewBlog = () => {
        updateEditState(null).then(() => {
            setBlogSlideFunc(!blogSlideFunc)
        })
    }

    const loadRecentBlogs = (active = 'recent') => {
        const res = blogs.blogs
        if (active === 'recent') {
            setRecents(res)
        } else {
            setRecents(
                res.sort((a, b) => a.impressions?.VIEWS > b.impressions?.VIEWS ? 1 : -1)
            )
        }
    }

    return (
        <DashboardWrapper title="Student Dashboard">
            {
                inUse ?
                    <div className='flex w-auto xl:min-w-[65%] m-5 mx-3 space-y-5 flex-col'>
                        <div className='w-full mb-10'>
                            <TimeTableWelcomeCard
                                user={user}
                                openSlider={handleNewBlog}
                            />
                        </div>
                        <div className='h-[1px] w-auto m-2 bg-gray-300' />
                        <div className='w-full'>
                            <DashboardGroupMetricsView gid={GROUP_ID.id} type="Blog posts" />
                        </div>
                        {
                            !timetable?.data ?
                                <NoTimeTableCard openEdit={setSlideFunc} />
                                :
                                <DailyViewTimetable table={timetableData} />

                        }
                    </div>
                    :
                    <div className='w-full flex flex-col space-y-4 justify-center items-center py-10'>
                        <div>You need a STUDENT ACCOUNT to use the timetable tool.</div>
                        <div className='p-2 bg-primary-500 text-white rounded-lg cursor-pointer'>See how to create a student account</div>
                    </div>
            }
            <div className='w-full space-y-5 lg:max-w-[35%]'>
                <WeatherCard />
                <RecentBlogPosts loadRecentBlogs={loadRecentBlogs} uid={current_user?.user.uid} setEditData={handleSetEdit} recents={recents} setRecents={setRecents} />
            </div>
            <DashboardSliderOver open={slideFunc} setOpen={setSlideFunc} width={2}>
                <EditTimetable setViewTT={setSlideFunc} />
            </DashboardSliderOver>
            <DashboardSliderOver bg={'#f0f0f0'} open={blogSlideFunc} setOpen={setBlogSlideFunc} width={7}>
                <Blog gid={GROUP_ID} editData={editData} setCloseModal={setBlogSlideFunc} reloadRecents={loadRecentBlogs} />
            </DashboardSliderOver>
        </DashboardWrapper>
    )
}

const mapStateToProps = (state) => {
    return {
        timetable: state.timetable,
        current_user: state.user?.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        storeTimetable: (table) => dispatch(store_timetable(table)),
        clearTimeTable: () => dispatch(clear_timetable())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Timetable)