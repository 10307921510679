import React, { useEffect } from 'react'
import moment from 'moment'

const SaveAndExit = ({ services }) => {
    useEffect(() => {

    }, [services.last_saved])

    const saveAndExsit = () => {
        services.goToDraft().then(() => {
            services.updatelastSaved(new Date()).then(() => {
                services.saveProgress()
            })
        })
    }

    return (
        <div className='fixed -top-3 right-5 flex md:relative flex-col justify-end items-end'>
            <div onClick={() => saveAndExsit()} className='font-medium text-sm md:text-base cursor-pointer hover:text-primary-500'>Save & Exit</div>
            {services.last_saved && <div className='font-light text-xs md:text-sm text-light_textcolor'>last saved {moment(services.last_saved).fromNow()}</div>}
        </div>
    )
}

export default SaveAndExit