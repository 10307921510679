import jwtDecode from "jwt-decode";
import { AUTH_SERVER_URL, GEO_SERVER_URL } from "../../assets/url";
import {
  del_fetch,
  get_fetch,
  post_fetch,
} from "../../Pages/Dashboard/Screens/Timetable/functions";
import bcrypt from "bcryptjs";
import { deleteService, deleteServiceImages } from "../service_functions.js";
import { delete_apartments, load_user_apartments } from "../housing_functions";

const authURL = AUTH_SERVER_URL;
const geoURL = GEO_SERVER_URL;

const authProvider = {
  isAuthenticated: false,
  async signin(callback) {
    authProvider.isAuthenticated = true;
    return callback();
  },
  signout(callback) {
    authProvider.isAuthenticated = false;
    callback();
  },
  getuser(callback) {
    return callback();
  },
};

export { authProvider };

export const login = async (email, password) => {
  return new Promise(async (resolve, reject) => {
    const contraband = ["UNAUTHORIZED"];
    const pass = encrypt(password);
    if (pass.code) {
      return reject(pass);
    }
    await fetch(`${authURL}/api/auth/email_sign_in`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Access-Control-Allow-Credentials": true,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password: pass,
        extra: await get_client_ip(),
      }),
    })
      .then(async (response) => {
        console.log(response.status, 'EOORR');

        if (response.status !== 200) {
          return reject({ error: response.statusText, code: response.status })
        }

        if (contraband.includes(response.statusText)) {
          return reject(response.statusText);
        }

        const res = await response.json();

        if (res.description || res.message) {
          reject(res);
        }

        resolve({
          ...jwtDecode(res?.access_token),
          access_token: res?.access_token,
        });
      })
      .catch((err) => reject(err));
  });
};

export const signup = async (data) => {
  return new Promise(async (resolve, reject) => {
    const email = email_verify(data.email);
    const password = encrypt(data.password);
    if (email.code) {
      return reject(email);
    }
    if (password.code) {
      return reject(password);
    }
    await fetch(`${authURL}/api/auth/email_signup`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Access-Control-Allow-Credentials": true,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
        email: email,
        password: password,
        extra: await get_client_ip(),
        role: "unassigned", // ********** assigned / unassigned ***********//
      }),
    })
      .then(async (response) => {
        const res = await response.json();
        if (res.description || res.message) {
          reject(res);
        }
        resolve(jwtDecode(res?.access_token));
      })
      .catch((err) => reject(err));
  });
};

export const get_user_identification = async (userid) => {
  return await get_fetch(`api/identification/get_identification_details?uid=${userid}`)
};

export const load_user = async (userid) => {
  return new Promise(async (resolve, reject) => {
    await fetch(`${authURL}/api/user?uid=${userid}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Access-Control-Allow-Credentials": true,
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const response = await res.json();

        if (response.description) {
          reject(response);
        }
        resolve(response);
      })
      .catch((err) => reject(err));
  });
};

export const sign_out_user = async () => {
  return new Promise(async (resolve, reject) => {
    await fetch(`${authURL}/api/user/signout`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        extra: await get_client_ip(),
      }),
    })
      .then(async (res) => {
        const response = await res.json();

        if (response.description) {
          reject(response);
        }
        resolve(response);
      })
      .catch((err) => reject(err));
  });
};

export const get_user = async () => {
  return new Promise(async (resolve, reject) => {
    await load_user()
      .then(async (response) => {
        const res = await response.json();
        return res;
      })
      .then(async (res) => {
        const response = await res.json();

        if (response.description) {
          reject(response);
        }
        resolve(response);
      })
      .catch((err) => reject(err));
  });
};

export const updateUser = (data) => {
  return new Promise(async (resolve, reject) => {
    await post_fetch("api/user/update", data)
      .then((res) => {
        const response = res;

        if (response.description) {
          reject(response);
        }
        resolve(response);
      })
      .catch((err) => reject(err));
  });
};

export const update_user_pending_balance = (uid) => {
  return new Promise(async (resolve, reject) => {
    await get_fetch(`api/users/refresh_pending_balance?uid=${uid}`)
      .then((res) => {
        const response = res;

        if (response.description) {
          reject(response);
        }
        resolve(response);
      })
      .catch((err) => reject(err));
  });
};

export const resetPassword = (email) => {
  return new Promise(async (resolve, reject) => {
    await get_fetch(`/api/user/reset_password?email=${email}`)
      .then((res) => {
        const response = res;

        if (response.description) {
          reject(response);
        }
        resolve(response);
      })
      .catch((err) => reject(err));
  });
};

export const saveNewPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    const password = encrypt(data.password);
    if (password.code) {
      return reject(password);
    }
    data["password"] = password;
    await post_fetch(`api/user/set_new_password`, data)
      .then((res) => {
        const response = res;

        if (response.description) {
          reject(response);
        }
        resolve(response);
      })
      .catch((err) => reject(err));
  });
};

export const encrypt = (data) => {
  const password_regex = new RegExp(
    "^(?=.*[0-9])" +
    "(?=.*[a-z])(?=.*[A-Z])" +
    "(?=.*[@#$%^&+=_.])" +
    "(?=\\S+).{8,}"
  );

  try {
    if (password_regex.test(data)) {
      const secret = `$2a$10$${process.env.REACT_APP_SECRET}`;
      const hash = bcrypt.hashSync(data, secret);
      return hash;
    } else {
      return {
        code: "bad-password-format",
        msg: (
          <div>
            Check your password, make sure: It contains{" "}
            <ul>
              <li>at least 8 characters and at most 20 characters,</li>{" "}
              <li> at least one digit, </li>{" "}
              <li>at least one upper case alphabet ,</li>
              <li> at least one lower case alphabet,</li>
              <li> at least one special character (example !@#$%&*()-+=^_)</li>
              <li> and doesn’t contain any white space.</li>
            </ul>
          </div>
        ),
      };
    }
  } catch (ex) {
    console.log("ERROR HASHING PASSSWORD ", ex);
    return ex;
  }
};

export const email_verify = (email) => {
  const email_regex = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+\\=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );
  try {
    if (email_regex.test(email)) {
      return email;
    } else {
      return {
        code: "bad-email-format",
        msg: "Email is not formatted Correctly",
      };
    }
  } catch (ex) {
    console.log("EMAIL VERIFICATION ERROR: ", ex);
    return ex;
  }
};

export const printErrorMessages = (e, alert) => {
  console.log(e, 'EOORR');
  if (e.code === 429) {
    alert.setalert({
      type: "error",
      title: "Error!",
      body: "Too many login attempts, wait an hour and try again",
    });
    return
  }
  if (e.message === "Failed to fetch") {
    alert.setalert({
      type: "error",
      title: "Loggin Failed",
      body: "Connection Error, please try again.",
    });
  }
  if (e.description && e.description === "INVALID PASSWORD") {
    alert.setalert({
      type: "error",
      title: "Invalid Credentials",
      body: "The credentials you entered are invalid. please try again.",
    });
  }
  if (e.description && e.description.includes("Invalid display name")) {
    alert.setalert({
      type: "error",
      title: "Invalid Credentials",
      body: "The name you entered is not a valid name invalid. please try again.",
    });
  }

  if (e.description && e.description.includes("EMAIL_EXISTS")) {
    alert.setalert({
      type: "error",
      title: "Conflict",
      body: "An account had already been created using this email. try signing in",
    });
  }

  if (e === "UNAUTHORIZED") {
    alert.setalert({
      type: "error",
      title: "Invalid Credentials",
      body: "The email or password you entered is invalid. please try again.",
    });
  }

  if (e.status && e.status === "Success") {
    alert.setalert({
      type: "success",
      title: e.status,
      body: `${e.message}, login to continue.`,
    });
  }

  if (e.code && e.code === "bad-password-format") {
    alert.setalert({
      type: "warning",
      title: "Badly Formatted Password",
      body: e.msg,
    });
  }

  if (e === "Successfully sent email") {
    alert.setalert({
      type: "success",
      title: "Success!",
      body: "you password reset link has been sent, please check your inbox.",
    });
  }
};

export const addUserAccountGroup = async (data) => {
  return await post_fetch("api/user/groups", data);
};

export const deleteUserAccountGroup = async (gid, uid) => {
  return await del_fetch(`api/user/groups/delete/${gid}/${uid}`);
};

export const cleanUpServicesForAccountDeletion = async (
  images,
  group_id,
  uid,
  callback
) => {
  console.log("images", images);
  await deleteServiceImages(images).then(async (a) => {
    console.log("A:", a);
    await deleteService(group_id).then(async (b) => {
      console.log("B:", b);

      await deleteUserAccountGroup(group_id, uid)
        .then((c) => {
          console.log("C:", c);
          // clear local services copy
        })
        .catch((e) => console.error("DELETE ACCOUNT GROUP ERROR: ", e))
        .finally(() => {
          callback();
        });
    });
  });
  return "DONE!";
};

export const deleteUserTimetable = async (id) => {
  return await del_fetch(`api/neu/timetable/delete/${id}`);
};

export const deleteAllUserApartments = async (uid) => {
  return await load_user_apartments(uid).then(async (apartments) => {
    const apt = JSON.parse(apartments);
    return await apt.forEach(async (apartment) => {
      await delete_apartments(apartment.doc);
    });
  });
};

export const cleanupForHousingAgentAccount = async (
  group_id,
  uid,
  callback
) => {
  return await deleteAllUserApartments(uid).then(async () => {
    return await deleteUserAccountGroup(group_id, uid)
      .then((c) => {
        return {
          type: "success",
          body: "student account permanently deleted",
          title: "account deleted",
        };
      })
      .catch((e) => console.error("DELETE HOUSING AGENT GROUP ERROR: ", e))
      .finally(() => {
        callback();
      });
  });
};

export const cleanupForBusinessAccount = async (group_id, uid, callback) => {
  try {
    return await deleteUserAccountGroup(group_id, uid).then(() => callback()); ////////////////////////////////
  } catch (e) {
    console.error("ERROR => ", e);
    return null;
  }
};

export const cleanupForTimetableDelete = async (group_id, uid, callback) => {
  await deleteUserTimetable(uid).then(async () => {
    await deleteUserAccountGroup(group_id, uid)
      .then((c) => {
        return {
          type: "success",
          body: "student account permanently deleted",
          title: "account deleted",
        };
      })
      .catch((e) => console.error("DELETE ACCOUNT GROUP ERROR: ", e))
      .finally(() => {
        callback();
      });
  });
};

export const makePayment = async (data) => {
  return await post_fetch("api/user/payment", data);
};

export const verify_payment = async (ref) => {
  return await get_fetch("api/user/payment?ref=" + ref);
};

export const extract_ID_information = async (data) => {
  const req = await fetch(
    `${AUTH_SERVER_URL}/api/user/identification/extract_features`,
    { method: "POST", credentials: "include", body: data }
  );

  return await req.json();
};

export const updateBuisnessUserProfile = async (data) => {
  return await post_fetch("api/user/identification/update_user", data);
};

export const updateBuisnessUserProfileProgress = async (data) => {
  return await post_fetch("api/user/identification/update_progress", data);
};

export const sendScheduledVideoCallConfirmationEmail = async (data) => {
  return await post_fetch("api/verification/bookings", data);
};

export const getAllCallScheduleds = async () => {
  return await get_fetch("api/verification/bookings");
};

export const get_log_data = async () => {
  return await get_fetch("api/admin/logs");
};
export const get_client_ip = async () => {
  const data = await fetch("https://ipapi.co/json/");
  return await data.json();
};

export const uploadFCMToken = async (data) => {
  return await post_fetch("api/user/identification/upload_fcm_token", data);
};
