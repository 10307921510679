import React, { useEffect, useState } from 'react'
import DashboardFormField from '../../../../DashboardComponents/DashboardFormField'
import Loader from '../../../../../../assets/spinner.svg'
import { BlogBlockTypes } from '..'
import YouTubeVideoView from '../../../../../../Components/YouTubeVideoView'


const YoutubeVideoBlock = ({ id, setParagraph, text, cancelBlock, saveDraft, start_at = 1 }) => {

    const [editing, setEditing] = useState(true)
    const [loading, setLoading] = useState(false)


    const saveParagraph = async () => {
        setLoading(true)
        await setTimeout(() => {
            setEditing(!editing)
            setLoading(false)
            saveDraft()
        }, 2000)
    }

    useEffect(() => {
        if (text) {
            setParagraph(text)
            setEditing(false)
            console.log(text)
        }
    }, [])

    const setText = (value) => {
        setParagraph(id, value, BlogBlockTypes.YOUTUBE_VIDEO_BLOCK)
    }

    const cancel = () => {
        setEditing(false)
        cancelBlock(id)
    }

    return (
        <div>
            {
                editing ?
                    <div>
                        <DashboardFormField name="YouTube Video Link" multiline={true} text={text} setText={setText} />
                        <div className='flex items-center justify-end space-x-4 py-2 px-5'>
                            <div onClick={() => cancel()} className='py-2 px-4 bg-red-500 text-white font-medium rounded-md cursor-pointer'>Cancel </div>
                            <div onClick={() => saveParagraph()} className={`py-2 px-4 ${loading ? 'bg-gray-300' : 'bg-primary-500'} text-white font-medium rounded-md cursor-pointer flex items-center space-x-2`}>
                                {loading && <img src={Loader} className='w-4 h-4 animate-spin' />}
                                <span>Save</span>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='p-2 md:p-5 py-0'>
                        <div onClick={() => setEditing(!editing)} className='p-2 md:p-5 py-1 ease_transition rounded-xl border-dashed border-primary-500 border-opacity-0 hover:border-opacity-50 border-4'>
                            <YouTubeVideoView src={text} />
                        </div>
                    </div>
            }
        </div>
    )
}

export default YoutubeVideoBlock