import React from 'react'
import { ViewMode } from './data'

const JobsMenu = ({active, setActiveView}) => {
    return (
        <div className='flex flex-col space-y-0 relative'>
            <div className='w-full relative z-10 flex items-center space-x-4 -mb-[0.15rem]'>
                <div onClick={() =>  setActiveView(ViewMode.BOARD)} className={`py-2 px-4 cursor-pointer border-b-4 text-lg font-medium ${active === ViewMode.BOARD ? 'text-primary-500 border-primary-500': 'text-gray-500 border-transparent'} `}>
                    Board View
                </div>
                <div onClick={() =>  setActiveView(ViewMode.CALENDAR)} className={`py-2 px-4 cursor-pointer border-b-4 text-lg font-medium ${active === ViewMode.CALENDAR ? 'text-primary-500 border-primary-500': 'text-gray-500 border-transparent'} `}>
                    Calendar View
                </div>
            </div>
            <div className='w-full h-[2px] bg-gray-200 relative' />
        </div>
    )
}

export default JobsMenu