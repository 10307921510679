import { CheckIcon } from '@heroicons/react/outline'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import draftImage from '../../../../../../assets/Hirerring.svg'
import { connect } from 'react-redux'
import { useHousingForm } from '../../../../../../Components/UtilityComponents/HousingFormContext'
import { Housingpages as pages } from '../../../Main/SubScreens/Settings/Forms/data'
import { AccountTypes } from '../../../SelectAccountTypePage/accounts'
import HousingPreviewPublish from '../PreviewPublish'


const HousingDraftForm = ({current_user, groupID}) => {

    const housing = useHousingForm()

    const house_id = housing.id
    
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        
    }, [loading])

    return !loading ? (
        <div className='w-full h-full relative'>
            <div className='flex items-center'>
                <div className='flex flex-col h-auto pt-10 pl-5 space-y-5 items-start justify-start w-full'>
                    {
                        pages.map((page, index) => {
                            return <DraftItem key={index} page={page} housing={housing} />
                        })
                    }
                </div>
                <img src={draftImage} alt="guide draft image" className="hidden fixed lg:flex w-[30%] right-[3vw] top-[28vh] mt-10 h-auto" />
            </div>
            <HousingPreviewPublish groupID={groupID} housing={housing} loading={loading} setLoading={setLoading} house_id={house_id} />
            <div className='flex sm:hidden w-full h-[200px]' />
        </div>
    ) : (
        <div className='w-full  h-[70vh] flex justify-center items-center'>
            <div>
                <img src="https://storage.googleapis.com/iframe_content/first%20time%20buyers/first-time-buyer-report_dashboard_optimised/images/loader.gif"
                className='w-20 h-20' />
                <span className='text-gray-400'>publishing...</span>
            </div>
        </div>
    )
}

const DraftItem = ({ page, housing }) => {
    const { stored_housing } = housing

    let [not_edited, setNotEdited] = useState(false)


    useEffect(() => {
        let data = stored_housing ? stored_housing[page.title]:[]
        if (Array.isArray(data) && data.length <= 0) {
            setNotEdited(true)
        }
        if (page.title === 'description' && data === '') {
            setNotEdited(true)
        }
    }, [stored_housing])




    return (
        <div onClick={() => housing.goToPage(page)} className=' cursor-pointer flex space-x-4 items-start '>
            <div>
                {
                    not_edited ?
                        <ExclamationCircleIcon className='guide-icon w-6 h-6 text-primary-500' />
                        :
                        <CheckIcon className='guide-icon w-6 h-6 text-primary-500' />
                }
            </div>
            <div >
                <div className='capitalize font-semibold underline'>{page.title.split('_').join(' ')}</div>
                {not_edited && <div className='p-3 hover:bg-primary-800 bg-primary-500 rounded-lg text-white text-xs m-4'>add {page.title.split('_').join(' ')} </div>}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      current_user: state.user?.data
    }
  }
  
  
  export default connect(mapStateToProps, null)(HousingDraftForm)