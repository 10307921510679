import React from 'react'
import Checker from '../Checker'

const ToggleFeature = ({title, feature, setFeature}) => {
  return (
    <div className='flex justify-start space-x-20 items-center text-textcolor'>
        <span className='w-40'>{title}</span>
        <span><Checker activeState={feature} setActiveState={setFeature} /></span>
    </div>
  )
}

export default ToggleFeature