import { CheckCircleIcon } from "@heroicons/react/outline";
import { UsersIcon } from "@heroicons/react/solid";
import React from "react";

const PollsView = ({ question="Poll Question", options = 0, votes = 0 }) => {
  return (
    <div className="w-full rounded-xl p-5 bg-primary-300 space-y-2 shadow-lg">
      <div className="text-base md:text-lg font-semibold text-primary-800">
        {question}
      </div>
      <div className="flex text-gray-500 items-center space-x-4">
        <div className="flex text-xs font-medium items-center space-x-2">
          <UsersIcon className="w-4 h-4" />
          <div>{votes} votes</div>
        </div>
        <div className="flex text-xs font-medium items-center space-x-2">
          <CheckCircleIcon className="w-4 h-4" />
          <div>{options} options</div>
        </div>
      </div>
    </div>
  );
};

export default PollsView;
