import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AuthTemplate from '..'
import WorkFromHome from '../../../assets/Site-constructor.svg'
import CheckerBox from '../../Dashboard/DashboardComponents/CheckerBox'
import DashboardFormField from '../../Dashboard/DashboardComponents/DashboardFormField'
import Loader from '../../../assets/spinner.svg'
import { printErrorMessages, resetPassword } from '../../../functions/authFunctions'
import { useAlert } from '../../../Components/UtilityComponents/ErrorContext'



const PasswordReset = () => {

    const alert = useAlert()


    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const handleResetPassword = () => {
        setLoading(true)
        resetPassword(email).then((link) => {
            printErrorMessages(link, alert)

        }).catch(err => {
            printErrorMessages(err, alert)
        })
            .finally(() => {
                setLoading(false)
            })
    }


    return (
        <AuthTemplate
            title="Making Your Dreams Reality"
            sub_title="growth happens faster when the jobs gets easier"
            vector_img={WorkFromHome}>
            <div className="w-full h-auto">
                <div className=" font-light p-5 text-xl md:text-5xl text-md  text-primary-500">Reset Password.</div>
                <div className=" font-light p-5 text-md  text-textcolor">Enter your email address associated with this account, and an link will be sent to you via email.</div>
                <DashboardFormField name="Email" type="email" placeholder="johndoe@mail.com" text={email} setText={setEmail} />

                <div onClick={() => handleResetPassword()} className={`w-auto h-14 cursor-pointer flex justify-center items-center rounded-xl  mx-2 my-4 space-x-2  ${loading ? 'bg-gray-300' : 'bg-primary-500 hover:bg-purple-600'}`}>
                    {loading && <img src={Loader} className='w-4 h-4 animate-spin' />}
                    <span className="font-medium text-white cursor-pointer">Continue</span>
                </div>
                <div className="  w-full bg-red justify-center items-center text-center mt-5">
                    <div className=" text-xs sm:text-md justify-center flex">don't have an account yet? <Link to="/signup" className="px-2 font-semibold cursor-pointer text-primary-500">sign up</Link> here.</div>
                </div>
            </div>


        </AuthTemplate>
    )
}

export default PasswordReset