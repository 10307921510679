import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useJobContext } from '../../../../Components/UtilityComponents/JobsContext'
import DashboardSliderOver from '../../DashboardComponents/DashboardSliderOver'
import DashboardWrapper from '../../DashboardComponents/DashboardWrapper'
import Jobdetails from './JobDetails'
import JobsCalendarView from './JobsCalendarView'
import JobsMenu from './JobsMenu'
import { UpdatesStatusTypes, ViewMode } from './JobsMenu/data'
import Jobcard from './JobsMenu/JobCard'
import JobShelf from './JobsMenu/JobShelf'

const Jobs = ({ user }) => {
    const jobs = useJobContext()

    const [pendingList, setPendingList] = useState([])
    const [startedList, setStartedList] = useState([])
    const [finishedList, setFinishedList] = useState([])
    const [jobForm, setJobForm] = useState(null)

    useEffect(() => {

        if (jobs.groupByStatus) {
            setPendingList(jobs.groupByStatus[UpdatesStatusTypes.PENDING] ?? [])
            setStartedList(jobs.groupByStatus[UpdatesStatusTypes.STARTED] ?? [])
            const cancelledJobs = jobs.groupByStatus[UpdatesStatusTypes.CANCELED] ? jobs.groupByStatus[UpdatesStatusTypes.CANCELED] : []
            const completedJobs = jobs.groupByStatus[UpdatesStatusTypes.COMPLETED] ? jobs.groupByStatus[UpdatesStatusTypes.COMPLETED] : []
            setFinishedList([...completedJobs, ...cancelledJobs] ?? [])
        } else {
            jobs.loadJobs()
        }

    }, [jobs.groupByStatus])

    useEffect(() => {

    }, [startedList, finishedList])

    useEffect(() => {
        // console.log(pendingList, startedList, finishedList)
    }, [pendingList, startedList, finishedList])

    const removeCard =(id, origin) => {
        if (origin === UpdatesStatusTypes.PENDING) {
            const cards = pendingList.filter(i => i.created_at !== id)
            setPendingList(cards)
        }

        if (origin === UpdatesStatusTypes.STARTED) {
            const cards = startedList.filter(i => i.created_at !== id)
            setStartedList(cards)
        }
    }

    const swapCards = (id, dest) => {
        console.log(id)
        if (dest === UpdatesStatusTypes.STARTED) {
            const card = pendingList.filter(i => i.created_at === id)[0]
            card['status'] = UpdatesStatusTypes.STARTED
            setStartedList([...startedList, card])
            removeCard(id, UpdatesStatusTypes.PENDING)
        }

        if (dest === UpdatesStatusTypes.COMPLETED) {
            const card = startedList.filter(i => i.created_at === id)[0]
            card['status'] = UpdatesStatusTypes.COMPLETED
            setFinishedList([...finishedList, card])
            removeCard(id, UpdatesStatusTypes.STARTED)

        }

        if (dest === UpdatesStatusTypes.CANCELED) {
            const card = startedList.filter(i => i.created_at === id)[0]
            card['status'] = UpdatesStatusTypes.CANCELED
            setFinishedList([...finishedList, card])
            removeCard(id, UpdatesStatusTypes.STARTED)

        }

        
    }

    const [activeView, setActiveView] = useState(ViewMode.BOARD)
    const [openView, setOpenView] = useState(false)

    return (
        <DashboardWrapper title="Jobs">
            <div className='hidden lg:flex px-8 w-full h-[85vh] flex-col'>
                <JobsMenu active={activeView} setActiveView={setActiveView} />
                {
                    activeView === ViewMode.BOARD ?
                        <div className='w-full h-full grid grid-cols-3 gap-x-5 py-5'>
                            <JobShelf title={`Pending Jobs (${pendingList.filter(i => i.customer.uid !== user.uid)?.length ?? 0})`} >
                                {
                                    pendingList?.map((job, indx) => {
                                        return job.customer.uid !== user.uid && <Jobcard key={indx} job={job} selectJob={setOpenView} getJobItem={setJobForm} />
                                    })
                                }
                            </JobShelf>
                            <JobShelf title={`Jobs in Progress (${startedList.filter(i => i.customer.uid !== user.uid)?.length ?? 0})`} >
                                {
                                    startedList?.map((job, indx) => {
                                        return job.customer.uid !== user.uid && <Jobcard key={indx} job={job} selectJob={setOpenView} getJobItem={setJobForm} />
                                    })
                                }
                            </JobShelf>
                            <JobShelf title={`Ended Jobs (${finishedList.filter(i => i.customer.uid !== user.uid)?.length ?? 0})`} >
                                {
                                    finishedList?.map((job, indx) => {
                                        return job.customer.uid !== user.uid && <Jobcard key={indx} job={job} selectJob={setOpenView} getJobItem={setJobForm} />
                                    })
                                }
                            </JobShelf>
                        </div>
                        :
                        <div>
                            <JobsCalendarView data={[...pendingList, ...startedList, ...finishedList]} openModal={setOpenView} uid={user.uid} setJobForm={setJobForm} />
                        </div>
                }
            </div>
            <div className='flex lg:hidden w-full h-full text-gray-400 justify-center pt-[25vh] items-center'>
                <span>switch over to a desktop screen to access this page</span>
            </div>
            <DashboardSliderOver open={openView} setOpen={setOpenView} width={7}>
                {jobForm && <Jobdetails job={jobForm} swap={swapCards}  />}
            </DashboardSliderOver>
        </DashboardWrapper>
    )
}



const mapStateToProps = (state) => {
    return {
        user: state.user?.data?.user
    }
}



export default connect(mapStateToProps, null)(Jobs)