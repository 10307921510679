/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

const DashboardSliderOver = ({ children, width = 3, open = false, setOpen, bg = null, noPadX = false }) => {

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-30 inset-0 overflow-hidden" onClose={setOpen}>
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className={`pointer-events-auto relative w-screen max-w-${width.toString()}xl`}>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-500"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-500"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                                        <button
                                            type="button"
                                            className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">Close panel</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div style={{ backgroundColor: bg ? bg : '#fff' }} className={`flex h-full flex-col overflow-y-hidden  pb-6 shadow-xl`}>
                                    <div className="px-0 sm:px-0">
                                        {/* <Dialog.Title className="text-lg font-medium text-gray-900"> 
                        <div className='w-full h-full px-4 sm:px-6 py-6 bg-white text-very_light_textcolor flex flex-col justify-center items-start'>
                            <span className='font-bold text-textcolor text-xl'>Panel Title</span>
                            <span className='font-light text-md text-light_textcolor'>some panel descripton goes here</span>
                        </div>
                    </Dialog.Title> */}
                                    </div>
                                    <div className={`relative ${noPadX?'mt-0':'mt-6'} flex-1 ${noPadX ? 'px-0' : 'px-4'} sm:px-6`}>
                                        {/* Replace with your content */}
                                        <div className={`only:absolute inset-0 ${noPadX ? 'px-0' : 'px-4'} sm:px-6`}>
                                            {/* <div className="h-full border-2 border-dashed border-gray-200" aria-hidden="true" > */}
                                            {children}
                                            {/* </div> */}
                                        </div>
                                        {/* /End replace */}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}


export default DashboardSliderOver