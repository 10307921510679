import { CalendarIcon, ChevronDownIcon, ClipboardCheckIcon, ClipboardIcon, ClipboardListIcon, StarIcon } from '@heroicons/react/outline'
import { CheckCircleIcon, ClipboardCopyIcon, ExclamationCircleIcon, StarIcon as SolidStarIcon } from '@heroicons/react/solid';
import moment from 'moment';
import React, { useState } from 'react'
import { useAlert } from '../../../../../Components/UtilityComponents/ErrorContext';
import { numberWithCommas } from '../../../../../functions';
import { UpdatesStatusTypes } from '../JobsMenu/data';

const JobUpdates = ({ job, info }) => {

    const [open, setOpen] = useState(false);
    const alert = useAlert()

    const copyID = () => {
        navigator.clipboard.writeText(info.id).then(() => {
            alert.setalert({
                type: 'success',
                body: 'Copied!',
                title: 'Copied'
            })
        })
    }


    const updateStat = () => {
        if (!job) return null
        switch (job.status) {
            case UpdatesStatusTypes.PENDING:
                return {
                    title: "Order Created",
                    moreInfo: (
                        <div className=' text-gray-500 space-y-4'>
                            <div className='text-lg space-x-4 flex items-center '>
                                <span className='font-medium'>Order #:</span>
                                <span>{info.id}</span>
                                <div onClick={() => copyID()} className="cursor-pointer">
                                    <ClipboardCopyIcon className='w-5 h-5' />
                                </div>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Date Created:</span>
                                <span>{moment(info.created_at).format('llll')}</span>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Start Date:</span>
                                <span>{moment(info.startTime).format('llll')}</span>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Finish Date:</span>
                                <span>{moment(info.endTime).format('llll')}</span>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Amount Paid:</span>
                                <span>{info.payment.currency.symbol} {numberWithCommas(info.payment.amount.toFixed(2))}</span>
                            </div>
                        </div>
                    ),
                    icon: ClipboardIcon
                }
            case UpdatesStatusTypes.STARTED:
                return {
                    title: "Started Task",
                    icon: ClipboardListIcon
                }
            case UpdatesStatusTypes.COMPLETED:
                return {
                    title: "Completed Task",
                    icon: ClipboardCheckIcon,
                    moreInfo: (
                        <div className=' text-gray-500 space-y-4'>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Time Completed:</span>
                                <span>{moment(job.updated_at).format('llll')}</span>
                            </div>
                        </div>
                    ),
                }
            case UpdatesStatusTypes.REQUESTING_EXTENSION:
                return {
                    title: "Requested an extension on the deadline",
                    icon: CalendarIcon,
                    moreInfo: (
                        <div className=' text-gray-500 space-y-4'>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Time:</span>
                                <span>{moment(job.updated_at).format('llll')}</span>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Days Requested:</span>
                                <span>{job.other.days}</span>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Reasons for extension:</span>
                                <span>{job.other.reason}</span>
                            </div>
                        </div>
                    ), 
                }
            case UpdatesStatusTypes.REQUESTING_REVIEW:
                return {
                    title: "Requested a review",
                    icon: StarIcon,
                    moreInfo: (
                        <div className=' text-gray-500 space-y-4'>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Time:</span>
                                <span>{moment(job.updated_at).format('llll')}</span>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Message:</span>
                                <span>{job.other.message}</span>
                            </div>
                        </div>
                    ),
                }

            case UpdatesStatusTypes.REQUESTING_COMPLETED:
                return {
                    title: "You submitted your task for customer's approval",
                    icon: ClipboardCheckIcon,
                    moreInfo: (
                        <div className=' text-gray-500 space-y-4'>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Time:</span>
                                <span>{moment(job.updated_at).format('llll')}</span>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Message:</span>
                                <span>{job.other.message}</span>
                            </div>
                        </div>
                    ),
                }

            case UpdatesStatusTypes.CANCELED:
                return {
                    title: "Order cancelled",
                    icon: ExclamationCircleIcon,
                    color: 'text-red-500',
                    moreInfo: (
                        <div className=' text-gray-500 space-y-4'>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Time:</span>
                                <span>{moment(job.updated_at).format('llll')}</span>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Message:</span>
                                <span>{job.other.message}</span>
                            </div>
                        </div>
                    ),
                }

            case UpdatesStatusTypes.EXTENSION_REJECTED:
                return {
                    title: "Request for extension was denied",
                    icon: ExclamationCircleIcon,
                    color: 'text-red-500'
                }

            case UpdatesStatusTypes.EXTENSION_APPROVED:
                return {
                    title: "Request for an extension was approved",
                    icon: CheckCircleIcon,
                    color: 'text-green-500',
                    moreInfo: (
                        <div className=' text-gray-500 space-y-4'>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Time:</span>
                                <span>{moment(job.updated_at).format('llll')}</span>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Old Finish Date:</span>
                                <span>{(moment(info.old_endTime).format('llll'))}</span>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>New Finish Date:</span>
                                <span>{(moment(info.endTime).format('llll'))}</span>
                            </div>
                        </div>
                    )
                }

            case UpdatesStatusTypes.GIVEN_REVIEW:
                return {
                    title: `${job.other.author.display_name} gave you a ${job.other.rating} star rating`,
                    icon: SolidStarIcon,
                    color: 'text-yellow-500',
                    moreInfo: (
                        <div className=' text-gray-500 space-y-4'>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Time:</span>
                                <span>{moment(job.updated_at).format('llll')}</span>
                            </div>
                            <div className='flex items-center space-x-4'>
                                <span className='font-medium'>Review:</span>
                                <span>{job.other.reviewBody}</span>
                            </div>
                        </div>
                    )
                }
            default:
                return null
        }
    }

    const data = updateStat()

    return data ? (
        <JobDropItem setOpen={setOpen} open={open} data={data} >
            {
                data.moreInfo && data.moreInfo
            }
        </JobDropItem>
    ) : null
}

export default JobUpdates

const JobDropItem = ({ children, setOpen, data, open }) => {
    return (
        <div>
            <div className={`w-full h-24 flex items-center space-x-4 px-5 ${data.color && data.color} border-b-2 border-gray-300`}>
                <div>
                    <data.icon className='guide-icon stroke-1' />
                </div>
                <div className='w-[95%] flex justify-between items-center space-y-4'>
                    <div className='text-base'>{data.title}</div>
                    {data.moreInfo &&
                        <div onClick={() => setOpen(!open)} className='p-4'>
                            <ChevronDownIcon className='guide-icon text-gray-500' />
                        </div>}
                </div>
            </div>
            <div className={`ease_transition w-full ${open ? 'flex' : 'hidden'} bg-primary-300 p-4 overflow-y-hidden`}>
                {children}
            </div>
        </div>
    )
}