import { MailIcon, PhotographIcon } from "@heroicons/react/outline";
import { DocumentAddIcon } from "@heroicons/react/solid";
import React, { useEffect } from "react";
import { useAlert } from "../../UtilityComponents/ErrorContext";

const ChatWidgetInput = ({
  onSend,
  socket,
  room,
  user,
  message,
  setMessage,
  files,
  setFiles,
}) => {
  useEffect(() => {}, [message]);

  const MAX_FILE_SIZE = 2000;
  const alert = useAlert()

  const _handleKeyDown = (e) => {
    if (message.trim() === "" && !files.length > 0) return;
    if (e.key === "Enter") {
      e.preventDefault();
      onSend();
    }
  };

  const _handleSendMessage = (e) => {
    if (message.trim() === "") return;
    onSend();
  };

  const communicatedTypingStatus = () => {
    socket.emit("user_typing", {
      userID: user.uid,
      typing: true,
      room: room.room,
    });
  };

  const uncommunicatedTypingStatus = () => {
    socket.emit("user_typing", {
      userID: user.uid,
      typing: false,
      room: room.room,
    });
  };

  const SelectFile = async (e) => {
    return await Promise.all(
      [...e.target.files].map((file) => ({
        file: file,
        mimeType: file.type,
        name: file.name,
      }))
    ).then((files) => {
      const fileSize = files.reduce((a, b) => (a += b.file.size), 0);

      if (fileSize / 1024 > (MAX_FILE_SIZE - 200)) {
        return alert.setalert({
          title: "Limit Exceeded",
          body: "max file size has exceeded limit",
          type: "error",
        });
      } else {
        setFiles(files);
      }
    });
    // setCurrentFiles([...currentFiles, { src: URL.createObjectURL(newFile), file: newFile }])
  };

  return (
    <div className="flex items-center space-x-2 py-2 px-5 rounded-b-lg">
      <div className="flex items-center space-x-2 w-[20%]">
        <div className="w-7 relative h-7 flex  justify-center items-center">
          <PhotographIcon className="w-5 h-5" />
          <input
            type="file"
            className="absolute top-0 cursor-pointer w-full h-full opacity-0"
            onChange={SelectFile}
            accept="image/png, image/gif, image/jpeg"
            multiple
          />
        </div>
        <div className="w-7 h-7 flex justify-center items-center">
          <DocumentAddIcon className="w-5 h-5" />
        </div>
      </div>
      <div className="w-[70%]">
        <textarea
          onFocus={communicatedTypingStatus}
          onBlur={uncommunicatedTypingStatus}
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          onKeyDown={_handleKeyDown}
          className="w-full max-h-full h-10 whitespace-pre-wrap py-2 px-4 outline-none"
          placeholder="type message..."
        />
      </div>
      <div
        onClick={_handleSendMessage}
        className="w-[10%] cursor-pointer aspect-square flex justify-center items-center"
      >
        <MailIcon className="w-5 h-5" />
      </div>
    </div>
  );
};

export default ChatWidgetInput;
