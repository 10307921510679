import { CheckCircleIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import { account_types } from '../accounts'

const AccountItem = ({ account, index, selected, setSelected, user_account }) => {
    const { logo, title, memo, type } = account
    const active = selected?.index === index

    const inUse = user_account?.some(i => i.type === type)

    return (
        <div className='w-auto flex justify-center items-center max-w-xs md:m-2'>
            <div onClick={() => !inUse && setSelected({ index, type })} className={
                    inUse?
                    `ease_transition w-[70%] bg-gray-400 text-gray-500':'bg-[#f6f6f6] aspect-square relative flex flex-col justify-center items-center rounded-3xl shadow-lg cursor-not-allowed p-5`
                    :
                    `ease_transition w-[70%] ${active && !inUse? 'bg-primary-500 text-white':'bg-[#f6f6f6]'} aspect-square relative flex flex-col justify-center items-center rounded-3xl shadow-lg cursor-pointer p-5 hover:text-white hover:bg-primary-500 `
                    }>
                <CheckCircleIcon className={` ${active && !inUse ? 'block' : 'hidden'} w-5 h-5 text-white absolute  top-5 right-5`} />
                {logo}
                <div className='text-md font-semibold pt-5'>{title}</div>
                <div className='text-xs font-normal w-[80%] text-center pt-1'>{memo}</div>
            </div>
        </div>
    )
}


const AccountTypes = ({setAccount, user_account}) => {

    const [selected, setSelected] = useState()

    useEffect(() => {
        setAccount(selected)
    },[selected])

    return (
        <div className='grid gap-5 md:gap-10 text-textcolor grid-cols-1 md:grid-cols-2 p-5 md:p-10 h-full justify-center items-center'>
            {
                account_types.map((account, indx) => {
                    return <AccountItem key={indx} account={account} index={indx} selected={selected} setSelected={setSelected} user_account={user_account} />
                })
            }
        </div>
    )
}

export default AccountTypes