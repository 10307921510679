import DateAndTimePicker from '../../../../Components/DateTimePicker'
import SwitchIcon from '../../../../assets/switch-horizontal.svg'

const DashbaordSelectDateRange = ({ startTime, setStartTime, endTime, setEndTime, starttitle = '', endtitle = '', disabled = false, handleDisableStartDate = ({date}) => date < new Date(), handleDisableEndDate = ({date}) => date < new Date() }) => {


    return (
        <div>
            <div className='flex items-start md:space-x-4 w-full rounded-xl'>
                <div className='flex w-full flex-col md:flex-row space-y-4 md:space-y-0 items-center justify-between md:space-x-4'>
                    <div className={`w-full py-2 px-4 border-2 border-gray-300 rounded-xl flex ${disabled ? 'bg-gray-100' : 'bg-white'} flex-col text-sm text-textcolor`}>
                        <div className='flex justify-between items-center text-textcolor'>
                            <span className='font-medium text-md '>{starttitle} </span>
                        </div>
                        <div className='w-full flex justify-between items-start overflow-hidden'>
                            <div className='flex w-full items-center space-x-2'>
                                <DateAndTimePicker  disabled={disabled} time={startTime} setTime={setStartTime} handleDsiabledDates={handleDisableStartDate} />
                            </div>
                        </div>
                    </div>
                    <span> <img src={SwitchIcon} className="w-5 md:w-14 h-5 md:h-14" /> </span>
                    <div className={`w-full py-2 px-4 border-2 border-gray-300 rounded-xl flex ${disabled ? 'bg-gray-100' : 'bg-white'} flex-col text-sm text-textcolor`}>
                        <div className='flex justify-between items-center text-textcolor'>
                            <span className='font-medium text-md '>{endtitle}</span>
                        </div>
                        <div className='w-full flex justify-between items-start overflow-hidden'>
                            <div className='flex items-center space-x-2'>
                                <DateAndTimePicker disabled={disabled} time={endTime} setTime={setEndTime} handleDsiabledDates={handleDisableEndDate} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DashbaordSelectDateRange