import { InformationCircleIcon, PencilIcon, ShieldExclamationIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import DashboardFormField from '../../../../../DashboardComponents/DashboardFormField'
import { DashboardFormDropDownList } from '../../../../../DashboardComponents/DashboardFormFieldDropList'
import { ReactComponent as Loader } from '../../../../../../../assets/spinner.svg'
import { loadCountries, loadCountryFromName, loadStates } from './data'
import { uploadImage } from '../../../../../../../utils/functions'
import { connect } from 'react-redux'
import { cleanUpServicesForAccountDeletion, cleanupForBusinessAccount, cleanupForHousingAgentAccount, cleanupForTimetableDelete, get_user, updateUser } from '../../../../../../../functions/authFunctions'
import { useAuth } from '../../../../../../../Components/UtilityComponents/AuthProvider'
import { store_user_data } from '../../../../../../../store/actions/authActions'
import { useSuzi } from '../../../../../../../Components/Assistant'
import DeleteConfirmationDialog from '../../../../../../../Components/Alerts/DeleteConfirmationDialog'
import { AccountTypes } from '../../../../SelectAccountTypePage/accounts'
import { deleteBusiness, load_business } from '../../../../../../../functions/business_functions'
import { deleteServiceImages } from '../../../../../../../functions/service_functions.js'
import { store_services } from '../../../../../../../store/actions/serviceActions'
import { store_timetable } from '../../../../../../../store/actions/timetableActions'
import { store_housing } from '../../../../../../../store/actions/housingActions'
import { store_business } from '../../../../../../../store/actions/businessActions'




const ProfileForm = (props) => {

    const { user,
        setUser,
        storeUser,
        storeService,
        storeTimetable,
        storeHousing,
        storeBusiness,
        stored_services
    } = props
    const auth = useAuth()
    const suzi = useSuzi()

    const [password, setPassword] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [city, setCity] = useState('')
    const [zip, setZip] = useState('')
    const [loading, setLoading] = useState(false)
    const [countries, setCountries] = useState(null)
    const [states, setStates] = useState(null)
    const [state, setState] = useState(null)
    const [country, setCountry] = useState(null)
    const [image, setImage] = useState(null)
    const [upLoadingImage, setUploadingImage] = useState(0)
    const [loadingImage, setLoadingImage] = useState(0)
    const [imageURL, setImageURL] = useState('')
    const [openDelete, setOpenDelete] = useState(false)

    useEffect(() => {
        (async () => {
            await setCountries(loadCountries())
            const con = loadCountries()[0]
            await setCountry(con)
            await setState(loadStates(con)[0])
        })()

        if (user?.user) {
            loadProfile(user.user)
        }
    }, [])


    function map(number, inMin, inMax, outMin, outMax) {
        return parseInt((number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin);
    }

    useEffect(() => {
        setState(loadStates(country)[0])
        setStates(loadStates(country))
    }, [country])

    const loadProfile = async (user) => {
        const loadedCountry = await loadCountryFromName(user.country, user.state)
        setName(user.display_name)
        setEmail(user.email)
        setPhone(user.phone_number)
        setCity(user.city)
        setImageURL(user.photo_url)
        setImage(user.photo_url)
        setCountry(loadedCountry?.country)
        setState(loadedCountry?.state)
        setZip(user.zip_code)
    }

    const images = stored_services ? stored_services?.images : [];


    useEffect(() => {
        setLoadingImage(map(upLoadingImage, 0, 100, 100, 0))
    }, [upLoadingImage])


    const handleOnSave = () => {
        setLoading(true)
        const data = {
            uid: user?.user.uid,
            country: country?.value,
            city: city,
            state: state?.value,
            photo_url: imageURL,
            display_name: name,
            email: email,
            phone_number: phone,
            photo_url: imageURL,
            zip_code: zip
        }
        updateUser(data).then((res) => {
            setUser({ user: { ...user.user, ...res } })
        }).then(() => {
            suzi.addMessage({
                text: "Your Profile has been updated!",
                timeout: 3000
            })
        }).finally(() => {
            setTimeout(() => setLoading(false), 1000)
        })
    }

    const handleFileSelected = (e) => {
        const [file] = Array.from(e.target.files)
        setImage(URL.createObjectURL(file))
        uploadImage(file, `users/${user.user.uid}`, setUploadingImage, setImageURL)
    }

    const handleDeleteAccount = async () => {
        setLoading(true)
        const account_groups = user?.user?.groupIDs
        const uid = user?.user.uid
        account_groups.map((group) => {
            if (group.type === AccountTypes.BUSINESS_OWNER) {
                load_business(group.id).then((res) => {
                    const data = JSON.parse(res)
                    if (!res.error) {
                        try {
                            data["images"] = data.images.map((i) => i.imageURL);
                            deleteBusiness(data.id).then(() => {
                                deleteServiceImages([...data.images, data.thumbnail])
                            })
                        } catch (e) {
                            console.error('ERROR: ', e)
                        }
                    }
                }).catch((e) => {
                    console.error('ERROR: ', e)
                })
                cleanupForBusinessAccount(group.id, uid, () => {
                    storeBusiness(null)
                }).then(async (res) => {
                    const userData = await auth.getuser(uid);
                    storeUser(userData);
                    setLoading(false);
                });

            }

            if (group.type === AccountTypes.STUDENT) {
                cleanupForTimetableDelete(group.id, uid, () => {
                    storeTimetable([]);
                }).then(async () => {
                    const userData = await auth.getuser(uid);
                    storeUser(userData);
                    setLoading(false);
                });
            }

            if (group.type === AccountTypes.SERVICE_PROVIDER) {
                cleanUpServicesForAccountDeletion(images, group.id, uid, () => {
                    storeService([]);
                }).then(async () => {
                    const userData = await auth.getuser(uid);
                    storeUser(userData);
                    setLoading(false);
                });
            }

            if (group.type === AccountTypes.HOUSING_AGENT) {
                cleanupForHousingAgentAccount(group.id, uid, () => {
                  storeHousing(null);
                }).then(async (res) => {
                  console.log(res);
                  const userData = await auth.getuser(uid);
                  storeUser(userData);
                  setLoading(false);
                });
              }

        })
    }



    return (
        <div className='w-full px-2 md:px-10 py-5'>
            <div className='flex flex-col w-full mb-20 items-center'>
                <div className="flex items-center space-x-6 my-10">
                    <div className="shrink-0 relative h-16 w-16 rounded-full overflow-hidden">
                        <img className="h-16 w-16  object-cover rounded-full" src={image ? image : "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"} alt="Current profile photo" />
                        {loadingImage > 0 && loadingImage < 100 && < div style={{
                            transform: `translateX(-${parseInt(loadingImage)}%)`
                        }} className={`  absolute w-full h-full bg-black/50 left-0 top-0`}></div>}
                    </div>
                    <label className="block">
                        <span className="sr-only">Choose profile photo</span>
                        <input onChange={(e) => handleFileSelected(e)} type="file" className="block w-full text-sm text-slate-500
                            file:mr-4 file:py-2 file:px-4
                            file:rounded-full file:border-0
                            file:text-sm file:font-semibold
                            file:bg-violet-50 file:text-violet-700
                            hover:file:bg-violet-100
                            "/>
                    </label>
                </div>
                <div className='w-full flex flex-col'>
                    <DashboardFormField placeholder="First Middle Last" name="Full Name" text={name} setText={setName} />
                    <DashboardFormField placeholder="youremail@mail.com" name="Email" type='email' text={email} setText={setEmail} />
                    <DashboardFormField placeholder="+00 000 000 0000" name="Phone Number" type='phone' text={phone} setText={setPhone} />
                    <div className='flex w-full flex-col lg:flex-row lg:space-x-2 lg:items-center lg:justify-between'>
                        <DashboardFormField placeholder="City" name="City" text={city} setText={setCity} />
                        {/* <DashboardFormDropDownList name="City" selectedInterval={null} setSelectedInterval={null} list={[]} /> */}
                        <DashboardFormDropDownList name="State" selectedInterval={state} setSelectedInterval={setState} list={states} />
                    </div>
                    <div className='flex w-full flex-col lg:flex-row lg:space-x-2 lg:items-center lg:justify-between'>
                        {/* <DashboardFormDropDownList name="Zip Code" selectedInterval={null} setSelectedInterval={null} list={[]} /> */}
                        <DashboardFormField placeholder="110011" name="Zip Code" text={zip} setText={setZip} />
                        {countries && <DashboardFormDropDownList name="Country" selectedInterval={country} setSelectedInterval={setCountry} list={countries} />}
                    </div>
                    {/* <DashboardFormField name="Password" type='password' text={password} setText={setPassword} password={true} /> */}
                    <div onClick={() => handleOnSave()} className={`w-auto h-12 cursor-pointer flex justify-center items-center rounded-xl  mx-2 my-4 space-x-2  ${loading ? 'bg-gray-300' : 'bg-green-600 hover:bg-green-500'}`}>
                        {loading && <Loader className='w-4 h-4 animate-spin' />}
                        <span className="font-medium text-white cursor-pointer">Save</span>
                    </div>
                </div>
            </div>
            <div>
                <div className='text-xl font-bold text-textcolor'>
                    <div className=' justify-start flex flex-row'>
                        <InformationCircleIcon className='w-7 h-7 text-textcolor' />
                        <span className='px-2'>Delete Account</span>
                    </div>
                </div>
                <div className='p-5 px-2'>
                    <div className='text-textcolor'>
                        <bold className="font-extrabold text-red-500">DANGER !!</bold> {':'} <span>This action will delete all Guide accounts and destroy all data on your Guide profile. Please note that <b><i>This action cannot be reversed.</i></b> </span>
                    </div>
                    <div onClick={() => setOpenDelete(true)} className={`w-64 h-12 cursor-pointer flex justify-center items-center rounded-xl my-4 space-x-2  ${loading ? 'bg-gray-300' : 'bg-red-600 hover:bg-red-500'}`}>
                        {loading && <Loader className='w-4 h-4 animate-spin' />}
                        <span className="font-medium text-white cursor-pointer">{`${loading? "Deleting...":"Delete Profile"}`}</span>
                    </div>
                </div>
            </div>
            <DeleteConfirmationDialog
                item_type="Profile"
                title=""
                open={openDelete}
                setOpen={setOpenDelete}
                callback={handleDeleteAccount}
                setLoading={() => null}
            />
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user?.data,
        stored_services: state.services.payload
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (data) => dispatch(store_user_data(data)),
        storeUser: (data) => dispatch(store_user_data(data)),
        storeService: (data) => dispatch(store_services(data)),
        storeTimetable: (table) => dispatch(store_timetable(table)),
        storeHousing: (housing) => dispatch(store_housing(housing)),
        storeBusiness: (business) => dispatch(store_business(business)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm)