import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DashboardFormField from "../../../../DashboardComponents/DashboardFormField";
import TipsCard from "../../../../DashboardComponents/FormComponents/TipsCard";
import { useAlert } from "../../../../../../Components/UtilityComponents/ErrorContext";
import FormChecker from "../../../../DashboardComponents/FormComponents/FormChecker";

const WebsiteForm = ({ business }) => {
  const [website, setWebsite] = useState("");
  const [hasNoWebsite, sethasNoWebsite] = useState(false);

  const alert = useAlert()

  useEffect(() => {
    if (business.stored_business?.website) {
      setWebsite(business.stored_business?.website?.website ?? "");
      sethasNoWebsite(business.stored_business?.website?.hasNoWebsite);
    }
  }, []);

  useEffect(() => {
    if (hasNoWebsite) {
      validateURL(website) && business.updateWebsite({
        website,
        hasNoWebsite: false
      });
    } else {
      business.updateWebsite({
        website: null,
        hasNoWebsite: true
      });
    }
  }, [website, hasNoWebsite]);

  const testValidateURL = (url) => {
    if (!validateURL(url) && url.length > 0) {
      alert.setalert({
        type: "error",
        title: "Invalid Url",
        body: "please enter a valid url"
      })
    }
  }

  const validateURL = (url) => /^www\.[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(url);

  return (
    <div className="flex h-auto items-start justify-start w-full">
      <div className="py-5 w-full md:w-[60%] ">
        <div>
          <div className="max-w-lg">
            <DashboardFormField
              rows={10}
              name="website"
              onBlur={() => testValidateURL(website)}
              placeholder="www.yourwebsite.com"
              maxLength={100}
              text={website}
              setText={setWebsite}
            />
          </div>
          <div className="max-w-lg flex justify-start">
            <FormChecker
              selected={hasNoWebsite}
              setSelected={sethasNoWebsite}
              text="I do not have a website"
            />
          </div>
        </div>
        {/* {validateURL(website) && <iframe src={website} width={400} height={500} />} */}
      </div>
      <div className="hidden md:w-[40%] h-full md:flex justify-start">
        {/* <TipsCard /> */}
      </div>
    </div>
  );
};

export default WebsiteForm;
