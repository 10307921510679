import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Ratings from 'react-ratings-declarative';
import RatingBars from '../../../../../../Components/RatingBars'
import RatingBubble, { LoadingRatingBubble } from '../../../../../../Components/RatingBars/RatingBubble';
import { abbreviateNumber, loadReviews, loadReviewsStars, loadServices } from '../../../../../../functions/service_functions.js';
import NOReviews from '../../../../../../assets/Group 597.svg'
import { Link } from 'react-router-dom';

function map(number, inMin, inMax, outMin, outMax) {
    return parseInt((number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin);
}

const DashboardReviewCard = ({ group, _data = null }) => {



    const [data, setData] = useState(null)
    const [allData, setAllData] = useState(null)
    const [total, setTotal] = useState(null)

    useEffect(() => {
        if (_data) {
            setData(_data.data)
            setAllData(_data.data)
            setTotal(_data.total)
            return
        }
        loadReviews({ gid: group, with_meta: true, size: 2 }).then((res) => {
            setTotal(res.hits.total.value)
            const review = res.hits.hits.map(r => r._source)
            setAllData(review)
            return review
        }).then((resp) => {
            setData(resp?.sort((a, b) => a > b ? 1 : -1).slice(0, 2))
        })

        return () => {
            setAllData(null)
            setData(null)
        }
    }, [_data])

    useEffect(() => {
    }, [data, allData, total])




    return (
        <div className='w-full h-fit py-5 px-10'>
            <RenderReviewBars gid={group} total={total} />
            <div className='w-auto h-[1px] bg-gray-200' />
            <div className='mt-2 text-sm text-textcolor'>
                <div className='flex justify-between items-center '>
                    <span className='font-medium'>last 2 reviews</span>
                    <Link to={`/dashboard/reviews/${group}`}><span>see all</span></Link>

                </div>

                <div className='py-5 space-y-2'>
                    {
                        data ?
                            data?.length > 0 ?
                                data?.map((rating, index) => {
                                    return rating.reviewBody?.length > 0 &&
                                        <RatingBubble
                                            key={index}
                                            by={rating.author?.display_name}
                                            at={moment(rating.datePublished).fromNow()}
                                            rating={rating.ratingValue}
                                            comment={rating.reviewBody} />
                                })
                                :
                                <div className=' flex flex-col items-center justify-center space-y-3'>
                                    <img src={NOReviews} className=" w-24 h-24" alt='guide no reviews notice image' />
                                    <div className='text-gray-400'>
                                        <div className='text-lg font-medium text-gray-500'>No Reviews Yet</div>
                                        <div className='text-xs text-gray-400'>Your customers’ review show up here</div>
                                    </div>
                                </div>
                            :
                            <div className='space-y-2'>
                                <LoadingRatingBubble />
                                <LoadingRatingBubble />
                            </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default DashboardReviewCard

const RenderReviewBars = ({ gid, total = 0 }) => {

    const [stars, setStars] = useState(null)

    useEffect(() => {
        loadReviewsStars({ gid }).then((res) => {
            setStars(res)
        })

        return () => {
            setStars(null)
        }
    }, [])

    useEffect(() => { }, [stars])


    return (
        <div className='flex flex-col w-[100%] mx-auto space-y-2'>
            <div className='flex justify-start items-center space-x-5'>
                <span className='text-3xl font-medium text-textcolor ml-1'>{abbreviateNumber(parseFloat(stars?.score | 0).toFixed(1))}</span>
                <span>
                    <Ratings
                        rating={stars?.score | 0}
                        widgetDimensions="25px"
                        widgetSpacings="2px"
                        widgetRatedColors="#F2C94C"
                    >
                        <Ratings.Widget />
                        <Ratings.Widget />
                        <Ratings.Widget />
                        <Ratings.Widget />
                        <Ratings.Widget />
                    </Ratings>
                </span>
            </div>
            <div className='w-full py-2 text-left text-sm'>from {total | 0} ratings</div>
            <RatingBars ratings={stars} />
        </div>
    )
}
