import { CursorClickIcon, DotsHorizontalIcon, EyeIcon, ShareIcon, ThumbDownIcon, ThumbUpIcon } from '@heroicons/react/outline'
import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import NoImages from '../../../../../assets/Group 590.svg'
import { useHousing } from '../../../../../Components/UtilityComponents/HousingContext'
import { useHousingForm } from '../../../../../Components/UtilityComponents/HousingFormContext'
import { numberWithCommas } from '../../../../../functions'
import { ApartmentImpressions } from '../../../../ApartmentsView/data'

const RecentApartments = ({ apartments }) => {

    const [apartmts, setApartmnts] = useState(null)

    useEffect(() => {
        setApartmnts(apartments?.sort((a, b) => new Date(a.created_at) < new Date(b.created_at) ? 1 : -1).slice(0, 3))
        console.log(apartments)
        return () => {

        }
    }, [apartments])


    return (
        <div className=' px-4'>
            {
                apartmts && apartmts.length > 0 ?
                    <div className='space-y-4 bg-white p-5 rounded-lg'>
                        <div className='text-xl font-bold text-left flex items-center justify-between w-full'>
                            <div>Recent Properties</div>
                            <Link to='/dashboard/housing/list' className='text-sm font-light cursor-pointer text-gray-400 hover:underline'>see all</Link>
                        </div>
                        {
                            apartmts.map((data, indx) => {
                                return <ApartmentListView key={indx} data={{ ...data.new_doc, id: data.doc, adverts: data.adverts, impressions: data.impressions }} />
                            })
                        }
                    </div>
                    :
                    <NoRecents />
            }
        </div>
    )
}

export default RecentApartments

const ApartmentListView = ({ data }) => {
    const housing = useHousingForm()
    const house = useHousing()
    const { setOpenSlider } = house

    const advertising = data.adverts?.status

    const loadForEdit = () => {
        const id = data.id

        console.log('ID:', id)
        housing.loadApartmentForEdit({ ...data, id, advert_status: data.adverts }).then(() => {
            setOpenSlider(true)
        })
    }
    return (
        <div className='flex w-full items-start space-x-4 justify-between'>
            <div className='w-[20%] aspect-square'>
                <img
                    src={data.images[0]}
                    className='w-full h-full rounded-lg object-cover'
                />
            </div>
            <div className='w-[70%] flex flex-col items-start text-left'>
                <div className={`text-xs font-semibold ${(!advertising || advertising !== 'active') ? 'bg-red-500' : 'bg-green-500'} text-white rounded-md py-1 px-2`}>{!advertising ? 'Not Advertised' : advertising !== 'active'? advertising : 'Advertising'}</div>
                <div className='text-lg font-bold'>{data.title}</div>
                <div className='text-sm font-light'>{data.location?.city}, {data.location?.state} / {data.location?.country}</div>
                <div className='text-gray-400 flex items-center space-y-2'>
                    <div className='text-lg font-extrabold'>{data.pricing?.currency.symbol} {numberWithCommas(parseFloat(data.pricing?.rent).toFixed(2))}</div>
                    <div className='text-sm font-light'>/month</div>
                </div>
                <div className='flex w-full justify-around py-2 mt-2 border-t-2 border-gray-200 items-center'>
                    <div className='flex items-center space-x-2'>
                        <EyeIcon className='w-5 h-5' />
                        <span>{data.impressions ? data.impressions.view ?? 0 : 0}</span>
                    </div>
                    <div className='flex items-center space-x-2'>
                        <CursorClickIcon className='w-5 h-5' />
                        <span>{data.impressions ? data.impressions.click ?? 0 : 0}</span>
                    </div>
                </div>
            </div>
            <div>
                <DotsHorizontalIcon onClick={loadForEdit} className='w-5 h-5 cursor-pointer text-gray-400' />
            </div>
        </div >
    )
}

const NoRecents = () => {
    return (
        <div className='bg-white p-5 py-24 rounded-lg'>
            <div className=' flex flex-col w-full items-center justify-center space-y-3 pb-5'>
                <img src={NoImages} className=" w-24 h-24" alt='guide no reviews notice image' />
                <div>
                    <div className='text-lg font-medium text-gray-500'>No Recent Apartments</div>
                    <div className='text-xs text-gray-400'>your recent apartments show here</div>
                </div>
            </div>
        </div>
    )
}