import { _storage } from "..";
// import lqip from "lqip-modern"

const {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  storage,
} = _storage;

export const uploadImage = (file, path, getProgress = null, getURL = null) => {
  return new Promise((resolve, reject) => {
    const storage = getStorage();
    const storageRef = ref(storage, `${path}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    return uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        getProgress && getProgress(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        return getDownloadURL(uploadTask.snapshot.ref).then(
          async (downloadURL) => {
            getURL && getURL(downloadURL)
            return resolve(downloadURL);
          }
        );
      }
    );
  });
};

export const deleteImages = async (src) => {
  const store = getStorage();
  // Create a reference to the file to delete
  const desertRef = ref(store, src);

  // Delete the file
  return await storage()
    .refFromURL(desertRef)
    .delete()
    .then((res) => {})
    .catch((e) => console.error("ERROR:", e));
};

export function fileSizeformatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
