import React, { useEffect, useState } from 'react'
import { CourseCard } from '../../../Cards/DailyViewTimetable'

const rowTitles = () => {
    const rows_h = []
    const arr = [...new Array(10)]
    let AP = 'AM'
    arr.map((_, indx) => {
        if ((indx + 9) > 12) {
            AP = 'PM'
        }
        rows_h.push(`${indx + 9} ${AP}`)
    })

    return rows_h
}

const TimetableView = ({ ttable }) => {

    const headers = ['Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat']
    const _headers = ['MONDAY', 'TUESDAY', 'Wednesday', 'THURSDAY', 'FRIDAY', 'Saturday']


    useEffect(() => {

    }, [ttable])



    return (
        <div  className='h-full w-full overflow-y-auto scrollbar'>
            {
                _headers.map((header, _indx) => {

                    const t = ttable?.filter((i) => i.day.toLowerCase().includes(header.toLowerCase()))

                    return (
                        <div key={_indx}>
                            <div id="timetable" className='py-5 uppercase font-semibold'>{t?.some(i => i.day.toLowerCase().includes(header.toLowerCase())) && header}</div>
                            {
                                t?.map((course, indx) => {

                                    return (
                                        <div key={indx} className={` pb-0`}>

                                            <CourseCard key={indx} title={course.title} venue={course.venue} time={course.time} />
                                        </div>
                                    )

                                })
                            }
                        </div>
                    )

                })
            }
        </div>
    )
}

export default TimetableView