import React from 'react'
import { useHousing } from '../../../../../Components/UtilityComponents/HousingContext'
import DashboardSliderOver from '../../../DashboardComponents/DashboardSliderOver'
import DashboardWrapper from '../../../DashboardComponents/DashboardWrapper'
import HousingDashboardFormWrapper from '../../../DashboardComponents/FormComponents/HousingDashboardFormWrapper'
import ApartmentList from '../ApartmentList'
import DashboardApartmentsFilterBar from '../DashboardApartmentsFilterBar'
import ShowActiveHousingForm from '../Forms/ShowActiveHousingForm'

const DashboardApartmentsList = () => {

    const houses = useHousing()
    const { setApartmentList, apartmentList, apartments, setOpenSlider, openSlider, loadUp } = houses


    return (
        <DashboardWrapper title="Housing">
            <div className='flex flex-col w-full'>
                <DashboardApartmentsFilterBar setList={setApartmentList} list={apartmentList} apartment={apartments} />
                <ApartmentList setOpenSlider={setOpenSlider} apartments={apartments} apartmentList={apartmentList} setApartmentList={setApartmentList} loadUp={loadUp} />
            </div>
            <DashboardSliderOver bg={'#F0f0f0'} open={openSlider} setOpen={setOpenSlider} width={7} >
                <HousingDashboardFormWrapper>
                    <ShowActiveHousingForm />
                </HousingDashboardFormWrapper>
            </DashboardSliderOver>
        </DashboardWrapper>
    )
}

export default DashboardApartmentsList