import { ArrowRightIcon, LockClosedIcon, SearchIcon } from '@heroicons/react/outline'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { highlightText } from '../../../../../functions'
import { get_log_data } from '../../../../../functions/authFunctions'

const LogView = () => {
    const [logs, setLogs] = useState(null)
    const [error, setError] = useState(null)
    const [search, setSearch] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        get_log_data().then((res) => {
            setLogs(res)
        }).catch((e) => {
            console.log('ERROR LOG :', e)
            setError(e)
        })
    }, [])

    function getHighlightedText(text, highlight) {
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <span> {parts.map((part, i) =>
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold', color: 'black', backgroundColor: 'white' } : {}}>
                {part}
            </span>)
        } </span>;
    }

    return (!error && logs) ? (
        <div className='w-full h-full flex flex-col justify-center items-center'>
            <div className='p-1 px-[2%] w-[80%] flex items-center space-x-2 rounded-full rounded-b-none border-2 border-b-0 border-primary-800 bg-primary-300 '>
                <SearchIcon className='w-6 h-6 text-primary-800' />
                <input className='p-2 w-[90%] text-lg outline-none bg-primary-300' onChange={(e) => setSearch(e.target.value)} placeholder="search ..." />
            </div>
            <code id="codec" className=' space-y-2 rounded-t-none scrollbar-h-sm whitespace-nowrap overflow-x-auto p-10 text-left rounded-2xl bg-dark-800 text-white w-[80%] h-[90%]'>
                {logs?.filter(i => search.length > 0 ? i.includes(search) : i).map((i, k) => {
                    return (
                        <div className='flex items-center space-x-2'>
                            <div className='w-10'><ArrowRightIcon className='w-5 h-5' /></div>
                            <span key={k} className="whitespace-nowrap">{getHighlightedText(i, search)}</span>
                        </div>
                    )
                })}
            </code>
        </div>
    ) : (
        <div className='w-full h-full bg-primary-100 flex justify-center items-center'>
            <div className='flex flex-col justify-center space-y-3 items-center'>
                <LockClosedIcon className='w-20 h-20' />
                <div className='text-3xl font-bold'>Access Denied</div>
                <div className='text-lg text-gray-400'>Sorry, You do not have sufficent privileges to view this resource</div>
                <div onClick={() => navigate(-1)} className='text-white cursor-pointer font-semibold bg-primary-500 rounded-lg p-5 py-2'>Go Back</div>
            </div>
        </div>
    )
}

export default LogView