import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAccounts } from "../../../../Components/UtilityComponents/AccountType";
import { AccountTypes, account_types } from "../SelectAccountTypePage/accounts";
import {
  cleanupForBusinessAccount,
  cleanupForHousingAgentAccount,
  cleanupForTimetableDelete,
  cleanUpServicesForAccountDeletion,
  deleteUserAccountGroup,
} from "../../../../functions/authFunctions";
import Loader from "../../../../assets/spinner.svg";
import { store_services } from "../../../../store/actions/serviceActions";
import { useAuth } from "../../../../Components/UtilityComponents/AuthProvider";
import { store_user_data } from "../../../../store/actions/authActions";
import { store_timetable } from "../../../../store/actions/timetableActions";
import DeleteConfirmationDialog from "../../../../Components/Alerts/DeleteConfirmationDialog";
import SelectAccountTypePage from "../SelectAccountTypePage";
import { store_business } from "../../../../store/actions/businessActions";
import { store_housing } from "../../../../store/actions/housingActions";
import {
  deleteBusiness,
  load_business,
} from "../../../../functions/business_functions";
import { deleteServiceImages } from "../../../../functions/service_functions.js";
import NoItems from '../../../../assets/Group 590.svg'

const AccountGroups = ({
  user,
  storeService,
  stored_services,
  storeUser,
  storeTimetable,
  storeHousing,
  storeBusiness,
}) => {
  const active_account = useAccounts();

  const [accounts, setAccounts] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // console.log(active_account.activeAccount, user)
    setAccounts(user?.user?.groupIDs);

    return () => {
      setAccounts([]);
    };
  }, [user]);

  useEffect(() => {
    console.log("Accounts", accounts);
  }, [accounts]);

  console.log(accounts);

  return (
    <div className="w-full p-5">
      <div className="w-full">
        <div className="flex justify-between items-center">
          <div className="w-auto flex flex-col items-start space-y-4">
            <div className="text-left text-lg md:text-2xl">USER ACCOUNTS</div>
            <div className="h-1 w-10 bg-primary-500"></div>
          </div>
          <div
            onClick={() => setOpen(true)}
            className="flex py-2 px-4 bg-primary-500 text-white text-sm rounded-md cursor-pointer space-x-2 items-center"
          >
            <PlusIcon className="w-5 h-5" />
            <div>Add Account</div>
          </div>
        </div>
        <div className="w-full h-auto divide-y-2 py-10">
          {accounts?.length > 0 ? (
            accounts.map((data, indx) => {
              return (
                <AccountCard
                  key={indx}
                  data={data}
                  active={true}
                  uid={user?.user.uid}
                  storeService={storeService}
                  stored_services={stored_services}
                  storeUser={storeUser}
                  storeTimetable={storeTimetable}
                  storeHousing={storeHousing}
                  storeBusiness={storeBusiness}
                />
              );
            })
          ) : (
            <div className="text-textcolor flex justify-center flex-col items-center">
              <div>
                <img src={NoItems} className="w-24 aspect-square" />
              </div>
              <div className="space-y-1 flex flex-col">
                <span className="text-lg font-bold">No User Account Dashboards.</span>
                <span className="text-sm text-center">
                  Your Guide account dashboards show up here. click on{" "}
                  <b>"Add Account"</b> button above to add a new account dashboard.
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      <SelectAccountTypePage open={open} setOpen={setOpen} />
    </div>
  );
};

const AccountCard = ({
  data,
  active,
  uid,
  storeService,
  stored_services,
  storeUser,
  storeTimetable,
  storeHousing,
  storeBusiness,
}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [_callback, setCallback] = useState(() => null);

  const images = stored_services ? stored_services?.images : [];

  const auth = useAuth();

  useEffect(() => {}, [loading]);

  const handleDelete = () => {
    if (data.type === AccountTypes.SERVICE_PROVIDER) {
      cleanUpServicesForAccountDeletion(images, data.id, uid, () => {
        storeService([]);
      }).then(async () => {
        const userData = await auth.getuser(uid);
        storeUser(userData);
        setLoading(false);
      });
    }

    if (data.type === AccountTypes.STUDENT) {
      cleanupForTimetableDelete(data.id, uid, () => {
        storeTimetable([]);
      }).then(async () => {
        const userData = await auth.getuser(uid);
        storeUser(userData);
        setLoading(false);
      });
    }

    if (data.type === AccountTypes.BUSINESS_OWNER) {
      load_business(data.id)
        .then((res) => {
          const data = JSON.parse(res);
          if (!res.error) {
            try {
              data["images"] = data.images.map((i) => i.imageURL);
              deleteBusiness(data.id).then(() => {
                deleteServiceImages([...data.images, data.thumbnail]);
              });
            } catch (e) {
              console.error("ERROR: ", e);
            }
          }
        })
        .catch((e) => {
          console.error("ERROR: ", e);
        });
      cleanupForBusinessAccount(data.id, uid, () => {
        storeBusiness(null);
      }).then(async (res) => {
        const userData = await auth.getuser(uid);
        storeUser(userData);
        setLoading(false);
      });
    }

    if (data.type === AccountTypes.HOUSING_AGENT) {
      cleanupForHousingAgentAccount(data.id, uid, () => {
        storeHousing(null);
      }).then(async (res) => {
        console.log(res);
        const userData = await auth.getuser(uid);
        storeUser(userData);
        setLoading(false);
      });
    }
  };

  const getURL = (account) => {
    switch (account) {
      case AccountTypes.SERVICE_PROVIDER:
        return "/dashboard/services";
      case AccountTypes.STUDENT:
        return "/dashboard/timetable";
      case AccountTypes.HOUSING_AGENT:
        return "/dashboard/housing";
      case AccountTypes.BUSINESS_OWNER:
        return "/dashboard/business";
      default:
        return "/dashboard";
    }
  };

  const accnt = account_types.filter((i) => i.type === data.type)[0];
  return accnt ? (
    <div className="w-full flex justify-between text-textcolor py-5 items-center">
      <Link to={getURL(accnt.type)} className="flex space-x-5 items-center">
        <div className="w-20 h-20  flex justify-center items-center">
          {accnt.logo}
        </div>
        <div className="text-left">
          <div className="font-medium">{accnt.title}</div>
          <div className="text-xs text-gray-400">{accnt.memo}</div>
        </div>
      </Link>
      {!loading ? (
        <div
          onClick={() => setOpen(true)}
          className="flex items-center space-x-2 text-red-500 cursor-pointer"
        >
          <TrashIcon className="w-5 h-5" />
          <div className="text-sm text-semibold">delete</div>
        </div>
      ) : (
        <img src={Loader} className="w-5 h-5 animate-spin" />
      )}
      <DeleteConfirmationDialog
        open={open}
        setOpen={setOpen}
        callback={handleDelete}
        setLoading={setLoading}
        title={accnt.title}
        item_type="Account"
      />
    </div>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    user: state.user?.data,
    stored_services: state.services.payload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeUser: (data) => dispatch(store_user_data(data)),
    storeService: (data) => dispatch(store_services(data)),
    storeTimetable: (table) => dispatch(store_timetable(table)),
    storeHousing: (housing) => dispatch(store_housing(housing)),
    storeBusiness: (business) => dispatch(store_business(business)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountGroups);
