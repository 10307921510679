import React from 'react'
import RatingStars from '../../../Components/RatingStars'
import ReviewItem from '../Components/ReviewItem'

const ClientReviewSection = () => {
    return (
        <div className='w-full flex justify-center my-32'>
            <div className='w-full lg:w-[50%] flex flex-col items-center space-y-4'>
                <div className='font-bold text-lg lg:text-xl 2xl:text-4xl text-dark_textcolor'>Meet Your Clients Half-way, In Satisfying Your Clients Needs.</div>
                <div className='font-light text-textdark text-md lg:text-lg pb-5'>Hear what your clients are saying about Guide App and Utilities. </div>
                <RatingStars className="w-6 h-6" />
                <ReviewItem />
            </div>
        </div>
    )
}

export default ClientReviewSection