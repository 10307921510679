import { CheckIcon, MinusCircleIcon } from '@heroicons/react/outline'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

const initList = [
    'A valid email address',
    'A valid phone number',
    'A valid residence Permit',
    'A valid passport ID'
]

const RequirementsForm = ({housing}) => {

    const [requirements, setRequirements] = useState([])
    const [requirement, setRequirement] = useState('')

    useEffect(() => {
        setRequirements(initList)
        if(housing.requirements?.length > 0){
            setRequirements(housing.requirements)
        }
    }, [])

    useEffect(() => {
        housing.updateRequirements(requirements)
     }, [requirements])

    const removeItemFromlist = (index) => {
        setRequirements(
            requirements.filter((_, indx) => index !== indx)
        )
    }

    const addRequirement =() => {
        setRequirements([...requirements, requirement])
        setRequirement('')
    }

    return (
        <div className="space-y-3 pt-[6vh]">
            <div className='text-lg font-light py-5'>Aspiring tenants must provide the following</div>
            {
                requirements?.map((text, index) => {
                    return <RequiremnetItem key={index} index={index} text={text} removeItem={removeItemFromlist} />
                })
            }
            <div className='py-10'>
                <div className='flex py-2 flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-3'>
                    <input onChange={(e) => setRequirement(e.target.value)} value={requirement} placeholder="e.g A valid passport ID" className="px-4 py-2 w-full md:w-auto rounded-md bg-white ring-2 ring-black/10" />
                    <div onClick={addRequirement} className='py-2 px-4 w-full md:w-auto cursor-pointer rounded-md bg-primary-500 text-white font-semibold'>
                        Add Requirement
                    </div>
                </div>
                <div className='text-sm text-gray-400'>Word Count ({requirement.length}/100)</div>
            </div>

        </div>
    )
}

export default RequirementsForm

const RequiremnetItem = ({ index, text, removeItem }) => {
    return (
        <div className='items-center grid grid-cols-4 max-w-lg text-sm md:text-base'>
            <div className='col-span-3 cursor-pointer flex space-x-4 items-start '>
                <CheckIcon className='guide-icon w-6 h-6 text-primary-500' />
                <div className='capitalize font-semibold underline'>{text}</div>
            </div>
            <div onClick={() => removeItem(index)} className='col-span-1'>
                <MinusCircleIcon className='w-5 h-5 cursor-pointer text-red-400' />
            </div>

        </div>
    )
}