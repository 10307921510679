import React, { useEffect, useState } from "react";
import Loader from "../../../../../../assets/spinner.svg";
import { PencilAltIcon } from "@heroicons/react/solid";
import { BlogBlockTypes } from "..";
import PollInputs from "../../../../../../Components/PollInputs";
import PollsView from "../../../../../../Components/PollsView";

const testAnswers = [
  {
    id: 1,
    text: "Agree",
    message: "Thank your for your feedback!. ",
  },
  {
    id: 2,
    text: "Disagree",
    message: "",
  },
  {
    id: 3,
    text: "Neutral",
    message: "",
  },
];

const PollBlock = ({ id, addPoll, poll, setPoll, cancelBlock, saveDraft }) => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [pollAnswer, setPollAnswers] = useState(testAnswers);
  const [votes, setVotes] = useState(0);


  useEffect(() => {
    setPollAnswers(poll.pollAnswer ?? [])
    setVotes(poll.votes ?? 0)
    setTitle(poll.question ?? "")
    setEditing(poll.question ? false : true)
  }, [])

  const savePoll = () => {
    setLoading(true);
    setTimeout(() => {
      setEditing(!editing);
      setLoading(false);
      addPoll(id, BlogBlockTypes.POLL_BLOCK, poll);
      saveDraft();
    }, 2000);
  };

  useEffect(() => {
    setPoll(
      id,
      {
        question: title,
        pollAnswer,
        votes,
      },
      BlogBlockTypes.POLL_BLOCK
    );
  }, [title, pollAnswer]);

  const setText = (value) => {
    setPoll(id, BlogBlockTypes.POLL_BLOCK, value);
  };

  return (
    <div>
      {editing ? (
        <div>
          <PollInputs
            setTitle={setTitle}
            title={title}
            setPollAnswers={setPollAnswers}
            pollAnswer={pollAnswer}
          />
          <div className="flex items-center justify-end space-x-4 px-5">
            <div
              onClick={() => cancelBlock(id)}
              className="py-2 px-4 bg-red-500 text-white font-medium rounded-md cursor-pointer"
            >
              Cancel{" "}
            </div>
            <div
              onClick={() => savePoll()}
              className={`py-2 px-4 ${loading ? "bg-gray-300" : "bg-primary-500"
                } text-white font-medium rounded-md cursor-pointer flex items-center space-x-2`}
            >
              {loading && <img src={Loader} className="w-4 h-4 animate-spin" />}
              <span>Save</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-2 md:px-5 py-1">
          <div
            onClick={() => setEditing(!editing)}
            className="p-2 md:px-5 ease_transition rounded-xl border-dashed border-primary-500 border-opacity-0 hover:border-opacity-50 border-4"
          >
            <PollsView
              options={pollAnswer.length}
              question={title}
              votes={votes}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PollBlock;
