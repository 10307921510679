import { EyeIcon, PencilIcon } from '@heroicons/react/outline'
import React from 'react'

const NoLectureDayCard = ({openTimetable}) => {

        const handleOpen=(type)=>{
            openTimetable(type)
        }

  return (
    <div className='flex items-center space-x-4'>
        <div onClick={() => handleOpen('edit')} className='p-10 cursor-pointer rounded-lg flex-1 flex flex-col justify-center items-center space-y-2 bg-white text-primary-500'>
            <PencilIcon className='guide-icon w-5 h-5'/>
            <div>Edit</div>
        </div>
        <div onClick={() => handleOpen('view')} className='p-10 cursor-pointer flex-1 flex flex-col justify-center items-center rounded-lg space-y-2 bg-white text-primary-500'>
            <EyeIcon className='guide-icon w-5 h-5'/>
            <div>View</div>
        </div>
    </div>
  )
}

export default NoLectureDayCard