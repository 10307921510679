import { Transition } from "@headlessui/react";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const ChatSenderBubble = ({ message, setSlider }) => {
  const [show, setShow] = useState(false);
  const [time, setTime] = useState("");

  useEffect(() => {
    (async () => {
      setShow(true);
      const date = await new Date(message.time);
      setTime(
        `${String(date.getHours()).padStart(2, "0")}:${String(
          date.getMinutes()
        ).padStart(2, "0")} ${date.getHours() > 12 ? "PM" : "AM"}`
      );
    })();
  }, []);

  return (
    <>
      <Transition
        show={show}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full flex flex-col items-start space-x-4">
          <div className="w-full  py-2 px-3 flex items-start justify-start space-x-4">
            <div className="bg-primary-500 max-w-[60%] shadow-md rounded-2xl rounded-tl-none text-white justify-end text-left">
              <div className="p-3">{message.message}</div>
            </div>
            <div className="py-2 text-sm lowercase">{time}</div>
          </div>
            {message.images && (
              <ChatImageThumbnails
                setSlider={setSlider}
                images={message.images}
              />
            )}
        </div>
      </Transition>
    </>
  );
};

export default ChatSenderBubble;

export const ChatImageThumbnails = ({ images, setSlider }) => {
  const i = images.length > 4 ? images.slice(0, 4) : images;
  const views = images.map((i) => i.original);

  const border = (n) =>
    i.length == 1
      ? "rounded-2xl w-52 h-52"
      : i.length == 2
      ? n == 0
        ? "rounded-l-2xl w-44 h-52"
        : "rounded-r-2xl w-44 h-52"
      : i.length == 3
      ? n == 0
        ? "rounded-l-2xl w-32 h-52"
        : n == 2
        ? "rounded-r-2xl w-32 h-52"
        : "w-32 h-52"
      : i.length == 4
      ? n == 0
        ? "rounded-l-2xl w-24 h-52 "
        : n == 3
        ? "rounded-r-2xl w-24 h-52"
        : "w-24 h-52"
      : "w-24 h-52";
  return (
    <div
      onClick={() => setSlider(views)}
      className={`w-fit h-fit grid  relative ${
        images.length > 1 ? `grid-cols-${i.length} gap-1` : "grid-cols-1"
      }`}
    >
      {i.map((img, n) => {
        return (
          <div
            key={n}
            className={`ease_transition cursor-pointer overflow-hidden ${border(
              n
            )}`}
          >
            <img
              src={img.thumbnail}
              className="w-full hover:scale-105 ease_transition h-full object-cover"
            />
          </div>
        );
      })}
      {images.length > i.length && (
        <div className="w-10 h-10  absolute bottom-0 right-0 text-xl font-medium text-white p-3 rounded-full justify-center items-center flex">
          +{images.length - i.length}
        </div>
      )}
    </div>
  );
};
