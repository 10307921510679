import { BanIcon, ChartBarIcon, LightBulbIcon, MenuAlt2Icon, PhotographIcon, VideoCameraIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import BlogParagraph from './BlogParagraph'
import ImageBlock from './ImageBlock'
import PollBlock from './PollBlock'
import SubTitleBlock from './SubTitleBlock'
import TitleBlock from './TitleBlock'
import YoutubeVideoBlock from './YoutubeVideoBlock'

export const BlogBlockTypes = {
    IMAGE_BLOCK: 'images',
    PARAGRAPH_BLOCK: 'paragraph',
    TITLE_BLOCK: 'title',
    SUBTITLE_BLOCK: 'subtitle',
    TIPS_BLOCK: 'tips',
    YOUTUBE_VIDEO_BLOCK:'youtube_video',
    POLL_BLOCK:"poll"
}

const EditBlogPost = ({ post, setPost, saveDraft }) => {


    const handleBlock = (id, type, value) => {
        console.log(value)
        const tmp = []

        post.map((p, index) => {
            if (p.id === id) {
                tmp.push({ ...p, type, value })
            } else {
                tmp.push(p)
            }
        })
        setPost(tmp.sort((a, b) => a.id > b.id ? 1 : -1))
    }

    const addBlock = (type) => {
        setPost([...post, { id: new Date().toUTCString(), type, value: '' }])
    }

    const setValue = (id, value, type) => {
        const tmp = []

        post.map((p) => {
            if (p.id === id) {
                tmp.push({ id, type, value })
            } else {
                tmp.push(p)
            }
        })
        setPost(tmp.sort((a, b) => a.id > b.id ? 1 : -1))
    }

    const cancelBlock = (id) => {
        const tmp = []
        post.map((p, index) => {
            if (p.id !== id) {
                tmp.push(p)
            }
        })
        setPost(tmp)
    }



    return (
        <div className='w-full h-full '>
            <div className='w-full max-h-[95vh] p-2 md:p-4 overflow-y-auto scrollbar space-y-1 rounded-lg bg-white'>

                {
                    post.length > 0 ?
                        post.map((para, indx) => {
                            if (para.type === BlogBlockTypes.IMAGE_BLOCK) {
                                return <ImageBlock saveDraft={saveDraft} key={indx} id={para.id} url={para.value} setUrl={setValue} addImage={handleBlock} cancelBlock={cancelBlock} />
                            }

                            if (para.type === BlogBlockTypes.PARAGRAPH_BLOCK) {
                                return <BlogParagraph saveDraft={saveDraft} key={indx} id={para.id} text={para.value} setParagraph={setValue} addParagraph={handleBlock} cancelBlock={cancelBlock} />
                            }

                            if (para.type === BlogBlockTypes.YOUTUBE_VIDEO_BLOCK) {
                                return <YoutubeVideoBlock saveDraft={saveDraft} key={indx} id={para.id} text={para.value} setParagraph={setValue} start_at={1} addParagraph={handleBlock} cancelBlock={cancelBlock}  />
                            }

                            if (para.type === BlogBlockTypes.TITLE_BLOCK) {
                                return <TitleBlock saveDraft={saveDraft} key={indx} id={para.id} title={para.value} setTitle={setValue} addTitle={handleBlock} cancelBlock={cancelBlock} />
                            }
                            if (para.type === BlogBlockTypes.SUBTITLE_BLOCK) {
                                return <SubTitleBlock saveDraft={saveDraft} key={indx} id={para.id} subtitle={para.value} setSubTitle={setValue} addSubTitle={handleBlock} cancelBlock={cancelBlock} />
                            }

                            if (para.type === BlogBlockTypes.POLL_BLOCK) {
                                return <PollBlock saveDraft={saveDraft} key={indx} id={para.id} poll={para.value} setPoll={setValue} addPoll={handleBlock} cancelBlock={cancelBlock} />
                            }
                            
                            return null 
                        })
                        :
                        <div className='w-full h-32 rounded-2xl border-dashed border-2 bg-primary-100 border-primary-500 flex justify-center items-center my-4'>
                            <div className='text-sm md:text-base text-center font-medium text-primary-500'>Add blog blocks to build your post.</div>
                        </div>
                }
                <div className='w-full flex pt-10 justify-center'>
                    <ActionButtons addBlock={addBlock} />
                </div>
            </div>
        </div>
    )
}

export default EditBlogPost

const ActionButtons = ({ addBlock }) => {
    return (
        <div className='flex items-center p-5 py-2 max-w-4xl space-x-2 md:space-x-4 w-full justify-around bg-primary-500/30 border-2 border-primary-500 text-sm font-bold text-center rounded-full'>
            <div onClick={() => addBlock(BlogBlockTypes.PARAGRAPH_BLOCK)} className='px-4 py-2 flex items-center space-x-0 md:space-x-2 rounded-lg bg-violet-500 cursor-pointer hover:bg-violet-700 text-white font-semibold'>
                <MenuAlt2Icon className='w-5 h-5 text-white' />
                <span className='hidden md:block'>Add Paragraph</span>
            </div>
            <div onClick={() => addBlock(BlogBlockTypes.SUBTITLE_BLOCK)} className='px-4 py-2 flex items-center space-x-0 md:space-x-2 rounded-lg bg-violet-500 cursor-pointer hover:bg-violet-700 text-white font-semibold'>
                <LightBulbIcon className='w-5 h-5 text-white' />
                <span className='hidden md:block'>Add Sub Title</span>
            </div>
            <div onClick={() => addBlock(BlogBlockTypes.IMAGE_BLOCK)} className='px-4 py-2 flex items-center space-x-0 md:space-x-2 rounded-lg bg-violet-500 cursor-pointer hover:bg-violet-700 text-white font-semibold'>
                <PhotographIcon className='w-5 h-5 text-white' />
                <span className='hidden md:block'>Add Image</span>
            </div>

            <div onClick={() => addBlock(BlogBlockTypes.YOUTUBE_VIDEO_BLOCK)} className='px-4 py-2 flex items-center space-x-0 md:space-x-2 rounded-lg bg-violet-500 cursor-pointer hover:bg-violet-700 text-white font-semibold'>
                <VideoCameraIcon className='w-5 h-5 text-white' />
                <span className='hidden md:block'>Add Youtube Video</span>
            </div>

            <div onClick={() => addBlock(BlogBlockTypes.POLL_BLOCK)} className='px-4 py-2 flex items-center space-x-0 md:space-x-2 rounded-lg bg-violet-500 cursor-pointer hover:bg-violet-700 text-white font-semibold'>
                <ChartBarIcon className='w-5 h-5 text-white' />
                <span className='hidden md:block'>Add Poll</span>
            </div>
        </div>
    )
}