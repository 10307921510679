import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../../Components/UtilityComponents/AuthProvider";
import { useSuzi } from "../../../../../Components/Assistant";
import { useAlert } from "../../../../../Components/UtilityComponents/ErrorContext";
import DeleteConfirmationDialog from "../../../../../Components/Alerts/DeleteConfirmationDialog";
import { Businesspages as pages } from "../../Main/SubScreens/Settings/Forms/data";
import { HUB_URL } from "../../../../../assets/url";

const PreviewBusinessProfile = ({
  business,
  loading,
  setLoading,
  deleting,
  setDeleteing,
  business_id,
}) => {
  const [preview, setPreview] = useState(null);
  const [url, setUrl] = useState(null);
  const [image, setImage] = useState(null);
  const [open, setOpen] = useState(false);

  const auth = useAuth();
  const alert = useAlert();

  useEffect(() => {
    setPreview(business);
    if (business.stored_business?.images?.length > 0) {
      setImage(
        business.stored_business.images[0].src
          ? business.stored_business.images[0].src
          : business.stored_business.images[0]
      );
    } else {
      setImage(
        "https://media.istockphoto.com/vectors/linear-sketch-of-an-interior-hand-drawn-vector-illustration-of-a-vector-id674555508?k=20&m=674555508&s=612x612&w=0&h=zaIn7zfr917HcVQEOB4sordnlArT-lAJGgi6NltR0bo="
      );
    }
  }, [business]);

  useEffect(() => { }, [loading, url]);

  const handlePublish = () => {
    const { stored_business } = business;
    let cheksout = true;
    pages.forEach((page) => {
      let data = stored_business ? stored_business[page.title] : [];
      if (Array.isArray(data) && data.length <= 0) {
        alert.setalert({
          title: "Incomplete Setup",
          body: "you must provide all information to be able to publish.",
          type: "error",
        });
        cheksout = false;
        return false;
      }
      if (page.title === "description" && data === "") {
        alert.setalert({
          title: "Incomplete Setup",
          body: "you must provide all information to be able to publish.",
          type: "error",
        });
        cheksout = false;
        return false;
      }
      return true;
    });

    if (cheksout === false) return;

    setLoading(true);
    business
      .publishBusiness(business_id)
      .then((res) => {
        if (!res || res === undefined) {
          alert.setalert({
            type: "error",
            body: "Something went wrong, try again.",
            title: "Network Error",
          });
          return;
        }

        if (res?.type && res?.type === "error") {
          alert.setalert(res);
          setLoading(false);
          return;
        }
        setLoading(false);
        setUrl(`/business/${business_id}`);
        alert.setalert({
          title: "Published!",
          body: "your Business account has been published and is now live.",
          type: "success",
        });
      })
      .catch((e) => console.log("ERROR: ", e))
      .finally(() => setLoading(false));
  };

  const handleDeleteService = async () => {
    setLoading(true);
    setDeleteing(true);
    business
      .businessDelete(business_id)
      .then((res) => {
        console.log(res);
        alert.setalert(res);
      })
      .finally(() => {
        setLoading(false);
        setDeleteing(false);
      });
  };

  return business.stored_business?.last_saved ? (
    <div className="fixed bottom-5 right-5 sm:bottom-10 sm:right-10 flex flex-col md:flex-row  items-end space-x-10 max-w-xl xl:max-w-2xl">
      <div className="flex items-end space-x-4 pl-10 md:pl-0">
        <div
          onClick={() => setOpen(true)}
          className="font-semibold underline text-red-500 hover:text-red-700 cursor-pointer"
        >
          Delete
        </div>
        <div
          onClick={() => !loading && handlePublish()}
          className={`font-semibold  underline ${loading
              ? "text-gray-400 cursor-wait"
              : "text-blue-500 hover:text-blue-700 cursor-pointer"
            }`}
        >
          Publish
        </div>
      </div>
      <div className="flex items-start max-w-md  shadow-lg bg-white  p-4 rounded-lg ">
        <div className="flex-1 px-2 flex flex-col justify-start py-5 space-y-2">
          <a
            href={`${HUB_URL}/${business_id}`}
            target="_blank"
            className="text-md font-semibold underline cursor-pointer pb-2 text-xs md:text-lg"
          >
            Preview Your Business
          </a>
          {business.stored_business.last_saved && (
            <div className="text-xs overflow-clip inline-block">
              last update:{" "}
              {moment(business.stored_business.last_saved).format("MMM Do YY")}
            </div>
          )}
          {!url && (
            <div className="font-medium text-sm text-gray-700 flex">
              Status: <span className="font-light px-3"> draft</span>
            </div>
          )}
          {url && (
            <Link
              to={url}
              className="text-md font-semibold underline cursor-pointer pb-2 text-xs md:text-lg"
            >
              Live Preview
            </Link>
          )}
        </div>
        <div className="w-20 sm:w-[40%] h-auto aspect-square rounded-lg overflow-hidden">
          <img
            src={image}
            className="object-cover w-full h-full"
            alt="guide service preview image"
          />
        </div>
      </div>
      <DeleteConfirmationDialog
        item_type="Account"
        title="Business"
        open={open}
        setOpen={setOpen}
        callback={handleDeleteService}
        setLoading={() => null}
      />
    </div>
  ) : null;
};

export default PreviewBusinessProfile;
