import React from 'react'
import Modal from '../../../../../Components/Modal'
import DashboardFormField from '../../../DashboardComponents/DashboardFormField'
import Loader from '../../../../../assets/spinner.svg'
import { useState } from 'react'
import { UpdatesStatusTypes } from '../JobsMenu/data'


const CancelOrder = ({ setOpen, handleAction }) => {

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')

    const handleCancelTask = async () => {
        setLoading(true)
        await handleAction(UpdatesStatusTypes.CANCELED, {
            message
        }).then((res) => {
            console.log('REQUEST SENT: ', res)
        }).finally(() => {
            setLoading(false)
            setOpen(false)
        })
    }


    return (
        <Modal setOpen={setOpen}>
            <div className='w-full flex flex-col divide-y-2'>
                <div className='w-full py-3 text-center font-bold text-lg bg-red-500 text-white rounded-t-lg'>Cancel Order</div>
                <div className='p-4 text-gray-500 space-y-2'>
                    <div className='text-lg font-medium'> Canceling this order will result in the following, proceed if you understand. </div>
                    <div>1. You may have to forefeit payments made to you for this job.</div>
                    <div>2. This could affect your rating and ranking on Guide.</div>
                    <div>3. Cancelling this task will not require any action on your customer's part.</div>
                    <div>4. Customer is refunded.</div>
                    <DashboardFormField text={message} setText={setMessage} multiline name="message" placeholder="tell your customer your reason for cancelling this order" />
                    <div className='w-full pt-4 px-3'>
                        <div className='flex items-center justify-end space-x-4'>
                            <div onClick={() => !loading && setOpen(false)} className='py-2 px-4 bg-red-500 text-white font-medium rounded-md cursor-pointer'>Back </div>
                            <div onClick={() => !loading && handleCancelTask()} className='py-2 px-4 bg-green-500 text-white font-medium rounded-md cursor-pointer flex items-center space-x-2'>
                                {loading && <img src={Loader} className='w-4 h-4 animate-spin' />}
                                <span>I Understand, Cancel</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CancelOrder