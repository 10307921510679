import React from 'react'
import { Link } from 'react-router-dom'
import Innovative from '../../../assets/14_add_to_favorites_tr.svg'

const InnovativeTechSection = () => {
    return (
        <div className='max-w-5xl lg:w-[70%] m-2 justify-center flex items-center py-28'>
            <div className='2xl:w-[70%] flex flex-col w-full text-center 2xl:text-left 2xl:pr-20 items-center 2xl:items-start space-y-4'>
                <div className='font-bold text-2xl text-dark_textcolor'>Designed & Built Using The Best Innovative Techonology</div>
                <div className='font-light text-textdark text-lg pb-5'>Guide is the best place for your business, and its all because of the amaizing team behind the scenes. </div>
                <Link to="/signup" className='w-full lg:w-fit py-4 px-10 bg-primary-500 text-white font-bold rounded-full hover:cursor-pointer'>Sign Up</Link>
            </div>
            <div className='hidden 2xl:flex justify-center items-center'>
                <img src={Innovative} alt="back_drop_image" className='w-full h-auto' />
            </div>
        </div>
    )
}

export default InnovativeTechSection