import React, { useEffect, useState } from 'react'
import DashboardFormField from '../../../Pages/Dashboard/DashboardComponents/DashboardFormField'
import SelectImages from '../../SelectImages'
import { useOrder } from '../../UtilityComponents/PlaceOrderContext'

const GetTaskDetailsForm = () => {
    const order = useOrder()
    const [specifications, setSpecifications] = useState('')
    const [images, setImages] = useState([])



    useEffect(() => {
        if (order.descriptions) {
            setSpecifications(order.descriptions.specifications)
            setImages(order.descriptions.images)
        }

        return () => {

        }
    }, [])

    const handleSetImages = (images) => {
        setImages(images)
        order.setDescriptions({
            ...order.descriptions,
            images
        })
    }

    const handleSetSpecs = (specifications) => {
        setSpecifications(specifications)
        order.setDescriptions({
            ...order.descriptions,
            specifications
        })
    }



    return (
        <div className='py-5 space-y-4'>
            <div className='text-md font-medium'>Provide specific instructions, details, or any information this service provider may need for this task. <span className='font-semibold text-sm italic'>(This section is Optional)</span></div>
            <div>
                <DashboardFormField name="Specifications" text={specifications} setText={handleSetSpecs} placeholder="provide more information to service provider if needed" multiline={true} />
            </div>
            <div className='px-5'>
                <SelectImages images={images} setImages={handleSetImages} />
            </div>
        </div>
    )
}

export default GetTaskDetailsForm