import React, { createRef, useState } from 'react';
import {
    SearchIcon,
    CogIcon,
    ChatIcon
} from '@heroicons/react/outline';
import moment from 'moment';
import DashboardSliderOver from '../DashboardSliderOver';
import Settings from '../../Screens/Main/SubScreens/Settings';
import Chats from '../../Screens/Chats';
import { useChat } from '../../../../Components/UtilityComponents/ChatsContext';

const DashboardWrapper = (props) => {
    const { children, title } = props
    const [atTopOfScreen, setAtTopOfScreen] = React.useState(0)
    const [screen, setScreen] = React.useState(null)
    const [openSlider, setOpenSlider] = useState(false)
    const scrollRef = createRef()
    const chat = useChat()

    const doAction = (screen = null) => {
        setOpenSlider(!openSlider)
        setScreen(screen)
        // if(screen === 'chats'){
        //     chat.setHasUnreadMessage(false)
        // }
    }

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    })

    const handleScroll = (event) => {
        let scrollTop = scrollRef.current.scrollTop
        setAtTopOfScreen(scrollTop)
    }

    const showScreen = (screen) => {
        switch (screen) {
            case 'settings':
                return <Settings />
            case 'chats':
                return <Chats />
            default:
                return null
        }
    }


    return (
        <div className='w-full flex flex-col relative h-screen box-border overflow-y-auto scrollbar' ref={scrollRef} onScroll={() => handleScroll()}>
            {/* {atTopOfScreen > 40 && <div className='h-18 w-full'></div>} */}
            <div className={`ease_transition flex justify-end sm:justify-between items-center py-2 pr-10 pb-2 ${atTopOfScreen > 0 && 'fixed border-b-2 h-[16 + 2px] py-2 bg-primary-800 bg-gradient-to-b from-primary-800 to-transparent top-0 z-10 flex shadow-md w-full md:w-[95%] '}`}>
                <span className={`text-3xl px-5 hidden font-bold ease_transition ${atTopOfScreen > 0 ? ' text-very_light_textcolor' : ' text-dark_textcolor '} sm:flex`}>{title}</span>
                <div className='flex space-x-2 items-center'>
                    <div className={`ease_transition mr-2 text-textcolor ${atTopOfScreen > 0 && ' text-very_light_textcolor'}`}>{moment().format('llll')}</div>
                    {/* <span onClick={() => doAction()} className={`ease_transition flex w-14 h-14 rounded-xl text-textcolor bg-secondary-lightpink justify-center items-center ${atTopOfScreen > 0 && 'w-12 h-12'}`}><SearchIcon className={`guide-icon ${atTopOfScreen > 0 && 'w-6 h-6'}`} /></span> */}
                    <span onClick={() => doAction('chats')} className={`relative ease_transition cursor-pointer aspect-square flex w-12 h-12 rounded-xl text-textcolor bg-white justify-center items-center ${atTopOfScreen > 0 && 'w-12 h-12'}`}><ChatIcon className={`guide-icon ${atTopOfScreen > 0 && 'w-6 h-6'}`} />{chat.hasUnreadMessage? <div className='w-3 h-3 bg-red-500 rounded-full absolute bottom-3 right-3'/>: null}</span>
                    <span onClick={() => doAction('settings')} className={`ease_transition cursor-pointer flex aspect-square w-12 h-12 rounded-xl text-very_light_textcolor bg-secondary-lightpurple justify-center items-center ${atTopOfScreen > 0 && 'w-12 h-12'}`}><CogIcon className={`guide-icon ${atTopOfScreen > 0 && 'w-6 h-6'}`} /></span>
                </div>
            </div>
            <div className='flex flex-col xl:flex-row h-fit w-full'>
                {children}
            </div>
            <div className='w-full h-16 hidden md:flex' />
            <DashboardSliderOver bg={'#F0f0f0'} open={openSlider} setOpen={setOpenSlider} width={7} noPadX={true} >
                {screen && showScreen(screen)}
            </DashboardSliderOver>
        </div>
    )
}

export default DashboardWrapper