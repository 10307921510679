import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import AuthTemplate from '..'
import WorkFromHome from '../../../assets/Site-constructor.svg'
import CheckerBox from '../../Dashboard/DashboardComponents/CheckerBox'
import DashboardFormField from '../../Dashboard/DashboardComponents/DashboardFormField'
import Loader from '../../../assets/spinner.svg'
import { printErrorMessages, saveNewPassword } from '../../../functions/authFunctions'
import { useAlert } from '../../../Components/UtilityComponents/ErrorContext'
import jwtDecode from 'jwt-decode'
import { matchPassword, mInfo } from '../../../functions'
import { PassInfo } from '../SignUp'



const NewPassword = () => {

    const alert = useAlert()


    const [password, setPassword] = useState('')
    const [repassword, setRepassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [user, setUser] = useState(null);
    const [linkexpired, setLinkExpired] = useState(false);
    const [matchPass, setMatchPass] = useState({})


    useEffect(() => {
        const token = searchParams.get("token")
        if (token) {
            const u = jwtDecode(token)
            const parseToken = JSON.parse(u.email)
            setUser(parseToken)

            const minutesDifference = hasExpired(parseToken.time)
            if(minutesDifference > 5){
                setUser(null)
                setLinkExpired(true)
                return alert.setalert({ type: 'error', title: 'Link Expired', body: "Your password reset link expired, try sending another link." })
            }
        }
    }, [])

    useEffect(() => {

    })

    const hasExpired = (tokenTime) => {
        const date = new Date()
        const today = new Date(date.toLocaleString('en-US', {
            timeZone: 'Europe/Istanbul'
        }))

        const token = new Date(tokenTime)
        const diffMins = today.getMinutes() - token.getMinutes()
        return Math.abs(diffMins)
    }

    const handleResetPassword = () => {
        if (password !== repassword) {
            return alert.setalert({ type: 'error', title: 'Password Mismatch', body: "passwords do not match." })
        }

        if (linkexpired) {
            return alert.setalert({ type: 'error', title: 'Link Expired', body: "Your password reset link expired, try sending another link." })
        }

        if (!user) {
            return alert.setalert({ type: 'error', title: 'No Active Link', body: "please use the password reset link sent to your email to reset your password.." })
        }

        setLoading(true)
        saveNewPassword({ ...user, password }).then((res) => {
            console.log(res)
            printErrorMessages(res, alert)

        }).catch(err => {
            printErrorMessages(err, alert)
        })
            .finally(() => {
                setLoading(false)
            })
    }

    const onChangePassword = (e) => {
        matchPassword(e, setMatchPass)
        setPassword(e)
    }


    return (
        <AuthTemplate
            title="Making Your Dreams Reality"
            sub_title="growth happens faster when the jobs gets easier"
            vector_img={WorkFromHome}>
            <div className="w-full h-auto">
                <div className=" font-light p-5 text-xl md:text-5xl text-md  text-primary-500">Enter New Password.</div>
                <div className=" font-light p-5 text-md  text-textcolor">Enter a new password to continue.</div>
                <DashboardFormField name="Password" type="password" placeholder="password" password={true} text={password} setText={onChangePassword} />
                <DashboardFormField name="Re-Password" password={true} type="password" placeholder="password" text={repassword} setText={setRepassword} />


                {password.length > 0 && <div className="grid grid-cols-5 gap-1 px-5 items-center justify-between w-full">
                    <div className="rounded-l-full w-auto h-1" style={{ backgroundColor: matchPass?.password_regex_small_cap ? '#4BB543' : '#fca307' }}></div>
                    <div className=" w-auto h-1" style={{ backgroundColor: matchPass?.password_regex_numbers ? '#4BB543' : '#fca307' }}></div>
                    <div className="w-auto h-1" style={{ backgroundColor: matchPass?.password_regex_big_cap ? '#4BB543' : '#fca307' }}></div>
                    <div className="w-auto h-1" style={{ backgroundColor: matchPass?.password_regex_symb ? '#4BB543' : '#fca307' }}></div>
                    <div className="rounded-r-full w-auto h-1" style={{ backgroundColor: matchPass?.password_regex_range ? '#4BB543' : '#fca307' }}></div>
                </div>}
                {
                    (password.length > 0 && matchPass) &&
                    Object.keys(matchPass).map((m,i) => {
                        return <PassInfo key={i} mode={matchPass[m]} info={mInfo(m)} />
                    })
                }


                <div onClick={() => handleResetPassword()} className={`w-auto h-14 cursor-pointer flex justify-center items-center rounded-xl  mx-2 my-4 space-x-2  ${loading ? 'bg-gray-300' : 'bg-primary-500 hover:bg-purple-600'}`}>
                    {loading && <img src={Loader} className='w-4 h-4 animate-spin' />}
                    <span className="font-medium text-white cursor-pointer">Reset Password</span>
                </div>
                <div className="  w-full bg-red justify-center items-center text-center mt-5">
                    <div className=" text-xs sm:text-md justify-center flex">go back to <Link to="/login" className="px-2 font-semibold cursor-pointer text-primary-500">login</Link> here.</div>
                </div>
            </div>


        </AuthTemplate>
    )
}

export default NewPassword