import React from 'react'

const YouTubeVideoView = ({ src, start_at = 1 }) => {

    if (!src) return null
    let link = src.includes("v=") ? src.split('v=') : src?.split("/")
    link = Array.isArray(link) ? link[link.length - 1] : ''
    link = link.length > 0 ? link.split('?').length > 1 ? link : `${link}?start=${start_at}` : link

    return (
        <div className='w-full h-auto aspect-video'>
            <iframe width="100%" height="100%" className='w-full h-full aspect-video rounded-lg' src={`https://www.youtube.com/embed/${link}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    )
}

export default YouTubeVideoView