

import { useState } from "react";
import { convertFromRaw, convertToRaw, EditorState, Modifier } from "draft-js";
import EditorComponent from "../../../../Components/EditorComponent";
import { useEffect } from "react";

const DashboardRichTextField = ({text, setText}) => {
  const initialState = () => EditorState.createEmpty();
  const [editorState, setEditorState] = useState(initialState);

  const sendTextToEditor = (text) => {
    setEditorState(insertText(text, editorState));
  };

  const insertText = (text, editorValue) => {
    const currentContent = editorValue.getCurrentContent();
    const currentSelection = editorValue.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );

    const newEditorState = EditorState.push(
      editorValue,
      newContent,
      "insert-characters"
    );
    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  };

  useEffect(() => {
    if(typeof text === 'string'){
        sendTextToEditor(text)
    }else{
        setEditorState(EditorState.createWithContent(convertFromRaw(text)));
    }
  },[])

  useEffect(() => {
    setText(convertToRaw(editorState.getCurrentContent()))
  },[editorState])


  return (
    <div>
      <EditorComponent
        editorState={editorState}
        setEditorState={setEditorState}
      />
    </div>
  );
};
export default DashboardRichTextField;