import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, Modifier } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const EditorComponent = ({ editorState = EditorState.createEmpty(), setEditorState }) => {

    const onEditorStateChange = (editorState) => { setEditorState(editorState) }

    const sendTextToEditor = (text) => {
        setEditorState(insertText(text, editorState));
    };

    const insertText = (text, editorValue) => {
        const currentContent = editorValue.getCurrentContent();
        const currentSelection = editorValue.getSelection();

        const newContent = Modifier.replaceText(
            currentContent,
            currentSelection,
            text
        );

        const newEditorState = EditorState.push(
            editorValue,
            newContent,
            "insert-characters"
        );
        return EditorState.forceSelection(
            newEditorState,
            newContent.getSelectionAfter()
        );
    };

    useEffect(() => {

        // console.log(editorState)

    }, [editorState])

    useEffect(() => {
        sendTextToEditor(editorState)
    }, [])

    return typeof editorState !== 'string' ? (
        <div>
            <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                stripPastedStyles
                ariaLabel="draftEditor"
                toolbar={{
                    options: ['inline',  'list', 'textAlign', 'link', 'history'],
                }}
                hashtag={{
                    separator: ' ',
                    trigger: '#',
                }}
            />;
        </div>
    ) : null
}

export default EditorComponent