import { ArrowNarrowRightIcon, BookmarkAltIcon, ChatIcon, CurrencyEuroIcon, ExclamationCircleIcon, LightningBoltIcon, PhoneIcon, ShareIcon, TruckIcon, UserGroupIcon, ViewGridIcon, XIcon } from '@heroicons/react/outline'
import { LocationMarkerIcon, StarIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react'
import Ratings from 'react-ratings-declarative/build/ratings';
import { useParams } from 'react-router';
import { loadReviews, loadReviewsStars, loadServices, loadUserReview, post_service_ratings, updateServiceImpressions } from '../../../functions/service_functions.js';
import Loader from '../../../assets/spinner.svg'
import NoData from '../../../assets/no_content.svg'
import { connect } from 'react-redux';
import moment from 'moment';
import { PromptLoginRequired } from '../../../Components/UtilityComponents/PromptLoginRequired';
import RatingBars from '../../../Components/RatingBars';
import Modal from '../../../Components/Modal/index.js';
import ImpressionClickTrackerHOC from '../../../Components/ImpressionClickTrackerHOC/index.js';
import { useMemo } from 'react';
import { useChat } from '../../../Components/UtilityComponents/ChatsContext/index.js';
import PlaceServiceOrder from '../../../Components/PlaceServiceOrder/index.js';
import { useOrder } from '../../../Components/UtilityComponents/PlaceOrderContext/index.js';
import ImageSlider_v2 from '../../../Components/imageSlider_v2/index.js';

var geodist = require('geodist')


const ServiceProviderProfile = ({ user }) => {


    const messageToSeller = `Hello, i'm contacting you from Guide Services, i need to get your services. `

    const [pageLoading, setPageLoading] = useState(false)
    const [openImageSlider, setOpenImageSlider] = useState(false)
    const [pageError, setPageError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [userRating, setUserRating] = useState(null)
    const [confirmedLoggedIn, setConfirmedLoggedIn] = useState(false)
    const [reviewing, setReviewing] = useState(false)
    const [reviews, setReviews] = useState(null)
    const [stars, setStars] = useState(null)
    const [data, setData] = useState(null)
    const [rating, setRating] = useState(1)
    const [distance, setDistance] = useState(null)
    const [review, setReview] = useState('')
    const [moreMsgs, setMoreMsgs] = useState(false)
    const [openOrderModal, setOpenOrderModal] = useState(false)
    const [chatLoading, setChatLoading] = useState(false)

    const { id } = useParams()
    const chat = useChat()

    useMemo(() => {
        let mounted = true;

        if (mounted == true) {
            setPageLoading(true)
            loadServices(id).then((res) => {
                setData(res)
            }).then(async () => {
                loadReviews({ gid: id, size: 5, with_meta: true }).then((res) => {
                    const reviws = res.hits.hits.map((a) => a._source)
                    setReviews(reviws)
                })
            }).catch((e) => {
                setPageError(e)
            }).finally(() => {
                setPageLoading(false)
            })
        }
        return () => {
            mounted = false
        }
    }, [])

    useEffect(() => {
        loadUserReview(`${user?.uid}_${id}`).then(thisUserRatings => {
            setUserRating(thisUserRatings)
        })
    }, [data, reviews])

    useEffect(() => {
        loadReviewsStars({ gid: id }).then((res) => {
            setStars(res)
        })
    }, [data])

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const dist = geodist({ lat: position.coords.latitude, lon: position.coords.longitude }, {
                    lat: data?.location.latlon.lat, lon: data?.location.latlon.lng
                }, { exact: true, unit: 'km' })
                setDistance(dist)
            },
            () => {
                alert('Position could not be determined.');
            }
        );
    }, [data])

    function changeRating(newRating) {
        if (user?.uid) {
            setReviewing(true)
            setRating(newRating)
            setConfirmedLoggedIn(false)
        } else {
            setConfirmedLoggedIn(true)
        }
    }

    const handleSubmit = () => {
        setLoading(true)
        setTimeout(() => {
            const data = {
                author: { uid: user?.uid, display_name: user?.display_name, photoURL: user?.photo_url },
                datePublished: new Date(),
                reviewBody: review,
                ratingValue: rating,
                service_id: id
            }
            post_service_ratings(data).then((resp) => {
                setUserRating(data)
            }).catch((err) => {
                console.error(err)
            }).finally(() => {
                setReviewing(false)
                setLoading(false)
            })
        }, 1000)
    }

    const handleImpression = (type = 'view') => {
        updateServiceImpressions({
            id: id,
            type: type
        })
    }

    const thisUser = {
        photoURL: data?.user.photoURL,
        uid: data?.user.uid,
        author: data?.user.display_name
    }

    const handleSendMessage = async () => {
        setChatLoading(true)
        handleImpression('whatsapp')
        chat.startChat(thisUser).then(() => {
            setChatLoading(false)
        })
    }




    return !pageError ?
        !pageLoading ?
            data && (
                <ImpressionClickTrackerHOC>
                    <div className='w-full h-auto md:h-auto flex justify-center items-center '>
                        {/* <ShareFlier /> */}
                        {openOrderModal && <PlaceServiceOrder skillsets={data?.skillsets} service_id={id} onClose={setOpenOrderModal} />}
                        <div className=' max-w-screen-xl w-full md:h-full space-x-4 rounded-lg flex flex-col md:flex-row items-start'>
                            <div className='w-full md:w-[30%] flex flex-col space-y-1 justify-center items-center  rounded-xl py-10' >

                                <div className='w-full flex flex-col items-center justify-center h-auto py-5'>
                                    <img src={data.user.photoURL} alt="guide profile image" className=' ring-4 ring-primary-500/50 w-[60%] h-auto rounded-lg' />
                                </div>

                                <div className='flex flex-col  justify-center pb-5 items-center'>
                                    <div className='flex flex-col items-center'>
                                        <div className='text-lg font-semibold'>{data.user.display_name}</div>
                                        {
                                            !data.location.hide_on_listing &&
                                            <div className='text-xs  text-gray-400 flex items-center space-x-2'>
                                                <LocationMarkerIcon className='w-5 h-5' />
                                                <div className=' truncate max-w-xs'>{data?.location.address}</div>
                                            </div>
                                        }
                                    </div>

                                    <ActionRegion handleImpression={handleImpression} loading={chatLoading} handleSendMessage={handleSendMessage} setMoreMsgs={setMoreMsgs} data={data} user={user} setOpenOrderModal={setOpenOrderModal} moreMsgs={moreMsgs} />

                                    <div className='grid grid-cols-3 w-full mt-10'>
                                        <div className='flex flex-col items-center justify-center'>
                                            <div className='text-2xl font-medium'>{data?.skillsets.length}</div>
                                            <div className='text-xs text-gray-500'>Skills</div>
                                        </div>
                                        <div className='flex flex-col items-center justify-center'>
                                            <div className='text-2xl font-medium flex items-center space-x-2'>
                                                <span>{parseFloat(stars?.score).toFixed(1) | 0}</span>
                                                <StarIcon className='guide-icon w-5 h-5 text-yellow-500' />
                                            </div>
                                            <div className='text-xs text-gray-500'>Ratings</div>
                                        </div>
                                        <div className='flex flex-col items-center justify-center'>
                                            <div className='text-2xl font-medium flex items-center space-x-2'>
                                                <span>{parseFloat(distance).toFixed(2)}</span>
                                                <span className='font-light text-lg'>km</span>
                                            </div>
                                            <div className='text-xs text-gray-500'>Away</div>
                                        </div>
                                    </div>
                                </div>


                                <div className=' flex items-center justify-center space-x-10 w-full space-y-2'>
                                    <div className='flex flex-col items-center justify-center'>
                                        <ShareIcon className='w-5 h-5' />
                                        <div className='text-xs text-gray-500'>Share</div>
                                    </div>
                                    <div className='flex flex-col items-center justify-center'>
                                        <ExclamationCircleIcon className='w-5 h-5' />
                                        <div className='text-xs text-gray-500'>Report</div>
                                    </div>
                                </div>

                                <div className='w-full py-5 text-left flex justify-center items-center'>
                                    <div>
                                        <div className='text-lg font-semibold'>Rate this service</div>
                                        <div className='text-sm font-normal text-gray-400'>share your experience with this service</div>
                                        <div className='py-4 w-full flex justify-center'>
                                            {rating &&
                                                <Ratings
                                                    rating={rating}
                                                    widgetDimensions="30px"
                                                    widgetSpacings="10px"
                                                    widgetRatedColors="#F2C94C"
                                                    changeRating={changeRating}
                                                >
                                                    <Ratings.Widget widgetHoverColor="#8D29FF" />
                                                    <Ratings.Widget widgetHoverColor="#8D29FF" />
                                                    <Ratings.Widget widgetHoverColor="#8D29FF" />
                                                    <Ratings.Widget widgetHoverColor="#8D29FF" />
                                                    <Ratings.Widget widgetHoverColor="#8D29FF" />
                                                </Ratings>}
                                        </div>
                                        {confirmedLoggedIn && <PromptLoginRequired />}
                                        {
                                            reviewing ?
                                                <div className='space-y-3'>
                                                    <textarea onChange={(e) => setReview(e.target.value)} type="text" rows={5} multiline="" value={review} placeholder=" Write a review. (Optional)"
                                                        className='w-full bg-white p-2 rounded-md text-sm border-2 border-black/10' />
                                                    <div onClick={() => handleSubmit()} className=' p-2 cursor-pointer hover:bg-primary-800 bg-primary-500 rounded-md text-white font-semibold flex justify-center'>
                                                        {loading ? <img src={Loader} className='w-4 h-4 animate-spin' /> : 'submit'}
                                                    </div>
                                                </div>
                                                :
                                                userRating &&
                                                // Show current users rating
                                                <div className='p-2 px-4 rounded-lg bg-[#fff5f5] shadow-lg max-w-[90%]'>
                                                    <div className='flex items-center space-x-4'>
                                                        <img src={userRating.author?.photoURL} className="w-10 h-10 rounded-full object-cover" />
                                                        <div>
                                                            <div className='text-sm'>{userRating.author?.display_name}</div>
                                                            <div className='flex items-center space-x-2'>
                                                                {userRating &&
                                                                    <Ratings
                                                                        rating={userRating.ratingValue}
                                                                        widgetDimensions="12px"
                                                                        widgetSpacings="auto"
                                                                        widgetRatedColors="green"
                                                                    >
                                                                        <Ratings.Widget />
                                                                        <Ratings.Widget />
                                                                        <Ratings.Widget />
                                                                        <Ratings.Widget />
                                                                        <Ratings.Widget />
                                                                    </Ratings>}
                                                                <div className='text-xs'>{moment(userRating.datePublished).format('l')}</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='py-2'>{userRating.reviewBody}</div>
                                                </div>
                                        }


                                    </div>
                                </div>

                            </div>
                            <div className='flex-1 flex flex-col w-full md:w-[69%] rounded-xl overflow-y-auto scrollbar h-full md:p-2 '>
                                <div className='w-full h-56 sm:h-64 md:h-[30%] p-2'>
                                    {
                                        data.images.length > 0 ? <ServiceImages setOpen={setOpenImageSlider} images={data.images} />
                                            :
                                            <NoServiceImages />

                                    }
                                </div>
                                <div className='mt-14 text-left divide-y-2'>
                                    <div className='py-5 text-lg font-semibold'>About {data.user.display_name}</div>
                                    <div className='py-3 text-justify px-10'>
                                        {data.description.length > 0 ? data.description : <span className='text-gray-400'>Describe your business to your clients</span>}
                                        {data.description.length > 0 ? <div className='text-sm font-bold my-3 text-gray-500'>see more</div> : null}
                                    </div>
                                </div>

                                <div className='mt-14 text-left divide-y-2'>
                                    <div className='py-5 text-lg font-semibold'>Services</div>
                                    <div className='py-3 grid grid-cols-1 justify-center md:grid-cols-3 md:gap-10  md:px-10'>
                                        {
                                            data.skillsets.map((skill, indx) => {
                                                return <SkillSet key={indx} skill={skill} />
                                            })
                                        }
                                    </div>
                                </div>
                                {(stars?.score | false) &&
                                    <div className='mt-14 text-left divide-y-2'>
                                        <div className='py-5 text-lg font-semibold'>Ratings</div>
                                        <div className='flex flex-col items-start'>
                                            <div className='w-full px-10 border-b-2 py-10'>
                                                <div className='flex justify-start items-center space-x-5'>
                                                    <span className='text-3xl font-medium text-textcolor ml-1'>{parseFloat(stars?.score).toFixed(1)}</span>
                                                    <span>

                                                        <Ratings
                                                            rating={stars?.score}
                                                            widgetDimensions="25px"
                                                            widgetSpacings="2px"
                                                            widgetRatedColors="#F2C94C"
                                                        >
                                                            <Ratings.Widget />
                                                            <Ratings.Widget />
                                                            <Ratings.Widget />
                                                            <Ratings.Widget />
                                                            <Ratings.Widget />
                                                        </Ratings>
                                                    </span>
                                                </div>
                                                <div className='w-full text-left py-2 text-sm'>from {stars?.sum | 0} ratings</div>
                                                <RatingBars ratings={stars} />
                                            </div>
                                            <div className='py-3 w-full text-justify px-10 divide-y-2'>
                                                {
                                                    reviews?.map((rating, index) => {
                                                        return rating.reviewBody.length > 0 && <RatingItem key={index} rating={rating} />
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className='w-full h-32' />
                            </div>
                        </div>
                        <Modal topPad={0} open={openImageSlider} setOpen={setOpenImageSlider}>
                            <ImageSlider_v2 images={data?.images} />
                        </Modal>


                    </div>
                </ImpressionClickTrackerHOC>

            ) :
            (
                <div className='w-full md:w-[80%] 2xl:w-[50%] flex justify-center items-center pt-[200px]'>
                    <img src={Loader} className='w-20 h-20 animate-spin stroke-1' />
                </div>
            ) :
        (
            <div className='w-full md:w-[80%] 2xl:w-[50%] pt-[200px] flex flex-col justify-center items-center'>
                <div className=''>
                    <img src={NoData} className="w-auto" alt="guide no items image" />
                </div>
                <div className='flex flex-col items-center '>
                    <div className='font-semibold text-gray-400 py-2'>No Service Found</div>
                    <div className='font-light text-gray-400 py-2'>This service does not exist or it may have been deleted</div>
                </div>
            </div>
        )
}

export const RatingItem = ({ rating }) => {
    return (
        <div className='space-y-2  md:py-5'>
            <div className='flex justify-between items-center'>
                <Ratings
                    rating={rating.ratingValue}
                    widgetDimensions="18px"
                    widgetSpacings="auto"
                    widgetRatedColors="green"
                >
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                    <Ratings.Widget />
                </Ratings>
                <div className='text-xs'>{moment(rating.datePublished).fromNow()}</div>
            </div>
            <div className=' text-xs md:text-sm'>{rating.reviewBody}</div>
            <div className='pt-2 text-xs font-semibold'>By: {rating.author.display_name}</div>
        </div>
    )
}

export const SkillSet = ({ skill }) => {


    return skill.id ? (
        <div className='w-full h-auto justify-center flex flex-col rounded-lg items-center py-5 bg-white shadow-md'>
            <div className='w-[80%] aspect-square  rounded-lg bg-white mb-5'>
                <img src={skill.image} className='w-full h-full rounded-xl object-cover' />
            </div>
            <div className='flex flex-col items-center w-full space-y-2'>
                <div className='font-bold text-center px-4 capitalize'>{skill.id?.split('_').join(" ")}</div>
                {/* <div className='px-2 text-center text-sm text-gray-600'>{skill.description}</div> */}
                <div className='text-sm text-gray-400 font-medium'>{skill.rates.currency.symbol} {skill.rates.rates} / per hour</div>
                {skill.supplies?.length > 0 && <div className='flex flex-row items-center space-x-2 md:space-x-4 pt-5 w-full whitespace-nowrap overflow-x-scroll px-2 py-1 scrollbar-h-sm'>
                    {
                        skill.supplies.map((supply, indx) => {
                            return <div key={indx} className='text-xs py-1 px-2 bg-primary-500 text-white rounded-xl'>{supply.split('_').join(" ")}</div>
                        })
                    }
                </div>}
            </div>
        </div>
    ) : null
}

const ServiceImages = ({ images, setOpen }) => {


    return (
        <div className='h-full flex flex-col'>
            <div className='flex justify-between text-xs font-medium py-4 text-gray-500'>
                <div> - {images.length} images</div>
                <div>
                    <div onClick={() => setOpen(true)} className='flex items-center space-x-2 text-primary-500 cursor-pointer'>
                        <span>See all</span>
                        <ArrowNarrowRightIcon className='w-3 h-3' />
                    </div>
                </div>
            </div>
            <div className='hidden h-[95%] w-full md:flex space-x-2 '>
                {
                    images.slice(0, 3).map((img, indx) => {
                        return (
                            <div key={indx} className=' h-full flex justify-center items-center rounded-lg' style={{ width: indx === 0 ? "40%" : indx === 1 ? "35%" : "25%" }} >
                                <img src={img.src ? img.src : img} className={`h-full w-full object-cover rounded-lg`} />
                            </div>
                        )
                    })
                }
            </div>
            <div className='h-[95%] w-full flex justify-center md:hidden '>
                <div className='h-full w-[90%] flex justify-center items-center rounded-lg' >
                    <img src={images[0].src ? images[0].src : images[0]} className={`h-full w-full object-cover rounded-lg`} />
                </div>
            </div>
        </div>
    )
}

const NoServiceImages = () => {

    const images = [
        "https://affinityhealthclinic.ca/wp-content/uploads/2017/07/Image-Placeholder.jpg",
        "https://affinityhealthclinic.ca/wp-content/uploads/2017/07/Image-Placeholder.jpg",
        "https://affinityhealthclinic.ca/wp-content/uploads/2017/07/Image-Placeholder.jpg",
    ]

    return (
        <div className='h-full flex flex-col'>

            <div className='hidden h-[95%] w-full md:flex space-x-2 '>
                {
                    images.slice(0, 3).map((img, indx) => {
                        return (
                            <div key={indx} className='  h-full flex justify-center items-center rounded-lg' style={{ width: indx === 0 ? "40%" : indx === 1 ? "35%" : "25%" }} >
                                <img src={img.src} className={`h-full w-full object-cover rounded-lg`} />
                            </div>
                        )
                    })
                }
            </div>
            <div className='h-[95%] w-full flex justify-center md:hidden '>
                <div className='  h-full w-[90%] flex justify-center items-center rounded-lg' >
                    <img src={images[0].src} className={`h-full w-full object-cover rounded-lg`} />
                </div>
            </div>
        </div>
    )
}

const ActionRegion = ({
    handleImpression,
    handleSendMessage,
    setMoreMsgs,
    data,
    user,
    setOpenOrderModal,
    moreMsgs,
    loading,
}) => {

    const order = useOrder()

    useEffect(() => {

    }, [loading])

    const start_placing_order = () => {
        order.setActors({
            seller: {
                photoURL: data?.user.photoURL,
                uid: data?.user.uid,
                display_name: data?.user.display_name
            },
            customer: {
                uid: user?.uid,
                display_name: user?.display_name,
                photoURL: user?.photo_url
            }

        })
        setOpenOrderModal(true)

    }


    return (
        <div className='flex mt-10 items-center space-x-3 text-sm  font-medium'>
            <div onClick={() => start_placing_order()} className=' cursor-pointer flex items-center space-x-3 text-sm p-2 px-4 rounded-md text-white bg-green-600 font-medium'>
                {/* <a href={`https://wa.me/${data.user?.phone}?text=${messageToSeller}?lang=en`} onClick={() => handleImpression('whatsapp')} className=' cursor-pointer flex items-center space-x-3 text-sm p-2 px-4 rounded-md text-white bg-green-600 font-medium'> */}
                <BookmarkAltIcon className='guide-icon w-5 h-5' />
                <div>Book Provider</div>
                {/* </a> */}

            </div>
            <div className='p-1 px-2 rounded-md flex items-center space-x-4 text-gray-700 bg-gray-400 relative'>
                {loading && <img src={Loader} className='w-4 h-4 animate-spin' />}
                <ChatIcon className='guide-icon w-6 h-6' onClick={() => handleSendMessage() /*setMoreMsgs(!moreMsgs)*/} />
                {
                    moreMsgs &&
                    <div className='fixed inset-0 z-10 bg-black/75 flex justify-center font-sans items-center'>
                        <div>
                            <div onClick={() => setMoreMsgs(!moreMsgs)} className='flex items-center cursor-pointer'>
                                <XIcon className='w-5 h-5 stroke-1 text-white' />
                                <span className='text-white'>Close</span>
                            </div>
                            <div className={` right-0 flex items-center space-x-4 shadow-lg w-auto p-2 rounded-lg`}>
                                <a href={`tel:${data.user?.phone}`} onClick={() => handleImpression('phone')} className=' p-2 rounded-md space-y-4 flex flex-col  hover:scale-110 cursor-pointer ease_transition hover:shadow-md'>
                                    <div className='w-24 h-24 bg-green-600 rounded-full flex justify-center items-center p-2'><PhoneIcon className='w-16 h-16 text-white' /></div>
                                    <span className='font-bold text-white '>Phone</span>
                                </a>
                                {
                                    data.user?.insta &&
                                    <div onClick={() => handleImpression('insta')} className='p-2 rounded-md space-y-4 flex flex-col  hover:scale-110 cursor-pointer ease_transition hover:shadow-md'>
                                        <div className='w-24 h-24 bg-white rounded-full'><img src="https://media.istockphoto.com/vectors/camera-icon-simple-style-isolated-vector-illustration-on-white-vector-id1278996249?k=20&m=1278996249&s=612x612&w=0&h=HRyT-Qn1dnw6COrUUeHfEC9i3yiReycPPphZNn8TT1A=" className=' rounded-full w-full h-full object-contain' /></div>
                                        <span className='font-bold text-white '>Instagram</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user?.data?.user
    }
}


export default connect(mapStateToProps, null)(ServiceProviderProfile)