import { async } from '@firebase/util'
import { LoginIcon, XIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { exchangeCurrencies } from '../../functions/get_rates_api'
import FormChecker from '../../Pages/Dashboard/DashboardComponents/FormComponents/FormChecker'
import Payment from '../../Pages/Dashboard/Screens/Main/SubScreens/Settings/Forms/Billings/Payment'
import { TabItem } from '../Accordion'
import { useOrder } from '../UtilityComponents/PlaceOrderContext'
import { PromptLoginRequired } from '../UtilityComponents/PromptLoginRequired'
import GetTaskDateAndTime from './GetTaskDateAndTime'
import GetTaskDetailsForm from './GetTaskDetailsForm'
import GetTaskLocationForm from './GetTaskLocationForm'
import SelectPreferredCurrency from './SelectPreferredCurrency'
import Loader from '../../assets/spinner.svg'
import { v4 as uuidv4 } from 'uuid';
import { getUiniqueID, numberWithCommas } from '../../functions'
import { useAlert } from '../UtilityComponents/ErrorContext'
import { currencies } from '../../Pages/Dashboard/Screens/Services/AddServices/Forms/SkillSetsForm/RatesForm'
import SPaymentProcessor from '../SPaymentProcessor'

const PlaceServiceOrder = ({ location, skillsets, service_id, onClose = () => null }) => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [locationData, setLocationData] = useState(null)
    const [totalCost, setTotalCost] = useState(0)
    const [tab, setTab] = useState(0)
    const [page, setPage] = useState(1)
    const [selectedServices, setSelectedServices] = useState([])

    const goBack = () => {
        onClose(false)
    }

    useEffect(() => {
        console.log(location)
    }, [location])

    const setCloseTab = (v) => {
        if (tab === v) {
            setTab(null)
            return
        }

        setTab(v)
    }

    const changePage = async (mode, func = async () => null) => {
        await func().then(() => {
            if (mode === 0) {
                if (page > 0) {
                    setPage(page - 1)
                }
            } else {
                if (page <= 2) {
                    setPage(page + 1)
                }
            }
        })
    }

    const RenderPages = ({ page_index }) => {

        switch (page_index) {
            case 1:
                return (
                    <div className='w-full h-[80%] bg-white overflow-y-auto scrollbar '>
                        <TabItem open={tab === 0} setOpen={() => setCloseTab(0)} title={`Select a service`}>
                            <SelectService skillsets={skillsets} selectedServices={selectedServices} setSelectedServices={setSelectedServices} />
                        </TabItem>
                        <TabItem open={tab === 1} setOpen={() => setCloseTab(1)} title="Select Preferred Currency">
                            <SelectPreferredCurrency services={selectedServices} />
                        </TabItem>
                        <TabItem open={tab === 2} setOpen={() => setCloseTab(2)} title="Where do you need this service?">
                            <GetTaskLocationForm setLocation={setLocationData} location={locationData} />
                        </TabItem>
                        <TabItem open={tab === 3} setOpen={() => setCloseTab(3)} title="When do you need this service?">
                            <GetTaskDateAndTime />
                        </TabItem>
                        <TabItem open={tab === 4} setOpen={() => setCloseTab(4)} title="Provide extra information to the service provider">
                            <GetTaskDetailsForm />
                        </TabItem>
                    </div>
                )
            case 2:
                return <FinishOrder />
            default:
                return null
        }
    }

    return (
        <PromptLoginRequired>
            <div className='fixed inset-0 z-10 bg-black/75 flex justify-center font-sans items-start pt-[20vh]'>
                <div className='max-w-4xl w-full max-h-[75vh] mx-4 sm:mx-0 flex flex-col space-y-4 p-4 bg-white h-fit rounded-lg'>
                    <div className='w-full flex justify-end'>
                        <div onClick={() => goBack()} className='w-6 h-6 bg-gray-200 hover:bg-gray-300 rounded'>
                            <XIcon className='base-icon text-gray-400' />
                        </div>
                    </div>

                    <RenderPages page_index={page} />
                    <PageNav page={page} changePage={changePage} service_id={service_id} />
                </div>
            </div>
        </PromptLoginRequired>
    )
}

export default PlaceServiceOrder

const FinishOrder = () => {
    const order = useOrder()

    const place_order = async (trnx) => order.place_order(trnx.reference, trnx.status)

    return (
        <div>
            {
                order.currency.symbol === currencies[4].symbol ?
                    <Payment reference={order.reference} callback={place_order} currency={order.currency} total={order.totalCost.toFixed(2)} />
                    :
                    <SPaymentProcessor reference={order.reference} callback={place_order} currency={order.currency} total={order.totalCost.toFixed(2)} />
            }
        </div>
    )
}

const PageNav = ({ changePage, page, service_id }) => {
    const [loading, setLoading] = useState(false)
    const order = useOrder()
    const alert = useAlert()
    const { totalCost, reference, selectedServices, currency, selectedLocation, selectedDateAndTime } = order

    const incomplete = totalCost <= 0 || !reference || selectedServices.length <= 0 || !selectedLocation?.latlon || !selectedDateAndTime?.endTime

    const submit = async (indx) => {
        setLoading(true)
        if (incomplete) {
            alert.setalert({ title: 'Incomplete', body: 'select all relevant information to conitnue. check your.', type: 'error' })
            return
        }
        if (indx == 1) {
            return await order.save_order(service_id).then(() => {
                setLoading(false)
                changePage(indx)
            })
                .catch(e => alert.setalert({ type: 'error', body: "something went wrong, try again.", title: "!" }))
                .finally(() => setLoading(false))
        } else {
            changePage(indx)
        }

    }

    return (
        <div className='w-full py-5 flex justify-center space-x-2 items-center px-5'>
            <div onClick={() => page > 1 && submit(0)} className={`py-2 px-4 ${page > 1 ? 'bg-red-500 hover:bg-red-800 cursor-pointer' : 'bg-gray-300 cursor-not-allowed'} bg-red-500 text-white font-bold rounded-md`}>
                {(loading && page == 2) ? <img src={Loader} className='w-4 h-4 animate-spin' /> : 'Back'}
            </div>
            <div onClick={() => page < 2 && submit(1)} className={`py-2 px-4  ${(page < 2 || !incomplete || !loading) ? 'bg-primary-500 hover:bg-primary-800 cursor-pointer' : 'bg-gray-300 cursor-not-allowed'} text-white font-bold rounded-md`}>
                {(loading && page == 1) ? <div className='flex items-center space-x-3'><img src={Loader} className='w-4 h-4 animate-spin' />  <span>Proceed To Pay {order.currency.symbol} {numberWithCommas(order.totalCost.toFixed(2))}</span></div> : `Proceed To Payment ${order.currency.symbol} ${numberWithCommas(order.totalCost.toFixed(2))}`}
            </div>
        </div>
    )
}


const SelectService = ({ skillsets, selectedServices, setSelectedServices }) => {

    return (
        <div className='w-full py-5'>
            {
                skillsets.map((skill, index) => {
                    return <OrderSkillItem key={index} skill={skill} selectedServices={selectedServices} setSelectedServices={setSelectedServices} />
                })
            }
        </div>
    )
}

const OrderSkillItem = ({ skill, selectedServices, setSelectedServices }) => {

    const order = useOrder()

    useEffect(() => {
        order.setSelectedServices(selectedServices)
        order.setReference(getUiniqueID())
    }, [selectedServices])

    const updateSelected = (select) => {
        const data = {
            id: skill.id,
            rates: skill.rates
        }
        if (select === true) {
            setSelectedServices([...selectedServices, data])
        } else {
            setSelectedServices(removeService(data))
        }
    }

    const removeService = (data) => {
        const tmp = []
        if (selectedServices.length === 1) return []
        selectedServices.map((service) => {
            if (service.id !== data.id) {
                tmp.push(service)
            }
        })
        return tmp
    }

    return (
        <div className='w-full'>
            <FormChecker
                selected={selectedServices.some(n => n.id === skill.id)}
                setSelected={updateSelected}
                text={
                    <div className='flex flex-col space-y-3 items-start justify-between w-full'>
                        <div>
                            <div className='capitalize font-semibold text-primary-500'>{skill.id.split('_').join(' ')}</div>
                            <div>
                                {skill.description}
                            </div>
                        </div>
                        <div className='font-bold text-lg flex space-x-2'>
                            <span>Price:</span>
                            <span>
                                {skill.rates.currency.symbol}
                                {" " + parseFloat(skill.rates.rates).toFixed(2)}
                            </span>
                        </div>
                    </div>
                } />
        </div>
    )
}