import React from 'react'
import Download from '../../../assets/2-01.svg'
import PlayIcon from '../../../assets/Google Play.svg'


const DownloadApp = () => {
    return (
        <div className='w-full border-t-2 flex justify-center'>
            <div className='max-w-7xl lg:w-full justify-center flex items-center py-28 '>
                <div className='w-full 2xl:w-[80%] text-center flex flex-col items-center 2xl:items-start 2xl:text-left 2xl:pr-20 space-y-4'>
                    <div className='font-bold text-4xl text-dark_textcolor'>Download The Guide App Now!</div>
                    <div className='font-light text-textdark text-lg pb-5'>This is just half the thrill, download the Guide app from the play store now for even more amaizingness that Guide offers.</div>
                    <div className='w-full flex justify-center items-center lg:w-fit py-10 text-white font-bold rounded-full hover:cursor-pointer'>
                        
                        <a href="https://bit.ly/3rtM5WZ" className='flex w-52 lg:w-auto justify-start items-center'>
                            <img src={PlayIcon} alt="back_drop_image" className='w-full h-auto' />
                        </a>
                    </div>
                </div>
                <div className='hidden 2xl:flex justify-center items-center'>
                    <img src={Download} alt="back_drop_image" className='w-full h-auto' />
                </div>
            </div>
        </div>
    )
}

export default DownloadApp