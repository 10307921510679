import moment from 'moment'
import React from 'react'
import { PlusIcon } from '@heroicons/react/outline'
import { useEffect } from 'react'
import { useState } from 'react'
import BathtubIcon from '../../../../../assets/BathtubIcon'
import BedIcon from '../../../../../assets/BedIcon'
import { useHousingForm } from '../../../../../Components/UtilityComponents/HousingFormContext'
import { numberWithCommas } from '../../../../../functions'
import { currencySymbol } from '../utils'
import NoData from '../../../../../assets/no_content.svg'
import { useHousing } from '../../../../../Components/UtilityComponents/HousingContext'


const ApartmentList = ({ apartmentList, setApartmentList, loadUp }) => {

    const housing = useHousingForm()
    const houses = useHousing()
    const { openSlider, setOpenSlider} = houses

    const new_apt = () => {
        housing.clearHousing().then(() => {
            setOpenSlider(!openSlider)
        })
    }

    useEffect(() => {
        if (housing.changedDoc) {
            // console.log('UPDATING DOCUMENT', housing.changedDoc)
            loadUp()
        }
    }, [housing.changedDoc])

    return (
        <div className='p-5 w-full space-y-4'>
            {
                apartmentList ?
                    apartmentList?.length ?
                        apartmentList?.map((house, indx) => {
                            return <ApartmentListCard key={indx} data={house} setOpenSlider={setOpenSlider} housing={housing} lease_duration={''} />
                        })
                        :
                        <div className='w-full md:h-[45vh] text-sm md:text-base flex flex-col justify-center items-center'>
                            <img src={NoData} className="w-auto" alt="guide no items image" />
                            <div className='flex flex-col items-center -mt-10'>
                                <div className='font-semibold text-gray-400 py-2'>No Apartments Yet</div>
                                <div className='font-light text-gray-400 py-2'>your apartments show up here.</div>
                                <div className='font-light text-sm text-gray-400 py-2 italic'>refresh to see updates.</div>
                            </div>
                        </div>
                    :
                    <div className='space-y-4'>
                        {
                            [0, 0, 0, 0, 0].map((_, indx) => {
                                return <LoadingCard key={indx} />
                            })

                        }
                    </div>
            }
            <div onClick={() => new_apt()} className='w-10 md:w-16 h-10 md:h-16 rounded-full bg-primary-500 flex justify-center items-center fixed cursor-pointer hover:bg-primary-800 shadow-lg ring-white/10 ring-2 right-5 md:right-20 bottom-24 md:bottom-10'>
                <PlusIcon className='w-5 md:w-10 w-5 md:h-10 text-white' />
            </div>
        </div>
    )
}

export default ApartmentList

const DraftedCreatedUpdated = (data) => {

    if (data.updated_at) {
        return 'updated at ' + moment(data.updated_at).format('llll')
    }

    if (data.created_at) {
        return "published on " + moment(data.created_at).format('llll')
    }

    return 'drafted'
}

const ApartmentListCard = ({ data, setOpenSlider, housing, lease_duration }) => {

    let living_space = data.living_space.includes('Studio') ? 'Studio+1' : data.living_space[0]
    living_space = living_space.split('+')


    const loadForEdit = () => {
        if (!data.new_doc) return
        const id = data.doc
        housing.loadApartmentForEdit({ ...data.new_doc, id }).then(() => {
            setOpenSlider(true)
        })
    }

    return (
        <div onClick={() => loadForEdit()} className='flex flex-col md:flex-row h-auto space-y-2 md:space-y-0 md:h-80 hover:bg-gray-100 cursor-pointer item-start md:items-center text-start w-full bg-white shadow-md rounded-lg p-2 md:p-5'>
            <div className='md:h-full w-full md:w-auto aspect-video rounded-lg'>
                <img src={data?.images[0]} className='w-full h-full rounded-lg object-cover' />
            </div>
            <div className='w-full md:w-[70%] px-0 md:px-5 text-left space-y-8'>
                <div>
                    <div className='w-auto text-2xl font-semibold '>{data.title.translations.en_us}</div>
                    <div className='w-auto md:text-base font-light text-sm'>{DraftedCreatedUpdated(data)}</div>
                </div>
                <div className='space-y-3'>
                    {data.on_lease && <div>On Temporary lease until {lease_duration}</div>}
                    <div className='flex space-x-4'>
                        <div className='flex items-center space-x-2'>
                            <span>{living_space[0]}</span>
                            <BedIcon className="w-5 h-5" />
                        </div>
                        <div className='flex items-center space-x-2'>
                            <span>{living_space[1]}</span>
                            <BathtubIcon className="w-5 h-5" />
                        </div>
                    </div>
                    <div className='flex justify-end w-full'>
                        <div className='text-primary-500'>
                            <div className='text-lg font-semibold'>{currencySymbol(data.features.acceptedCurrency)} {numberWithCommas(parseFloat(data.price).toFixed(2))}</div>
                            <div className='text-xs font-light'>per month x {data.features.rents} months</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const LoadingCard = () => {
    return (
        <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 md:h-80 items-start md:items-center text-start w-full bg-white shadow-md rounded-lg p-2 md:p-5'>
            <div className='md:h-full w-full md:w-auto bg-gray-200 animate-pulse aspect-video rounded-lg' />
            <div className='flex flex-col space-y-3 w-full md:w-[69%] px-0 md:px-5'>
                <div className='w-[70%] bg-gray-200 animate-pulse h-4 rounded-sm' />
                <div className='w-[50%] bg-gray-200 animate-pulse h-2 rounded-sm' />
            </div>
        </div>
    )
}