import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { verify_payment } from '../../../functions/authFunctions/index.js';
import { timeDiffCalc } from '../../../functions/index.js';
import { create_service_order, place_service_order, update_tranx_history, uploadServiceImages } from '../../../functions/service_functions.js';
import { OrderTransactionStages, OrderTypes } from '../../../Pages/Dashboard/Screens/Jobs/JobsMenu/data.js';
import { currencies } from '../../../Pages/Dashboard/Screens/Services/AddServices/Forms/SkillSetsForm/RatesForm';
import { clear_order, save_order } from '../../../store/actions/orderActions/index.js';
import { useAuth } from '../AuthProvider/index.js';
import { useAlert } from '../ErrorContext/index.js';

const initOrder = {
    totalCost: 0,
    serviceID: null,
    setServiceID: () => null,
    setTotalCost: () => null,
    currency: currencies[0],
    setCurrency: () => null,
    selectedServices: [],
    selectedLocation: [],
    selectedDateAndTime: [],
    descriptions: '',
    setSelectedServices: () => null,
    setSelectedLocation: () => null,
    setSelectedDateAndTime: () => null,
    setDescriptions: () => null,
    actors: null,
    reference: null,
    setReference: () => null,
    setActors: () => null,
    place_order: () => null,
    save_order: () => null,
    clear_order: () => null
}

const PlaceOrderProvider = createContext(initOrder)

const PlaceOrderContext = ({ children, saveOrder, clearOrder, orderRef }) => {
    const auth = useAuth()
    const alert = useAlert()

    const [totalCost, setTotalCost] = useState(0)
    const [actors, setActors] = useState(null)
    const [reference, setReference] = useState('')
    const [serviceID, setServiceID] = useState(null)
    const [currency, setCurrency] = useState(currencies[0])
    const [selectedServices, setSelectedServices] = useState([])
    const [selectedLocation, setSelectedLocation] = useState([])
    const [selectedDateAndTime, setSelectedDateAndTime] = useState([])
    const [descriptions, setDescriptions] = useState('')


    useEffect(() => {
       
    }, [])

    useEffect(() => {
    }, [serviceID, reference])


    const check_payment_status = async (ref) => {
        await verify_payment(ref).then(async res => {

            if (res[0] == 200) {
                if (res[3].reference == orderRef.ref) {
                    if (res[3].status === 'success') {
                        const msg = await place_order(ref, res[3].status)
                        alert.setalert(msg)
                    }

                    if (res[3].status === 'failed') {
                        alert.setalert({ type: 'error', title: "Order Failed", body: 'Your order failed' })
                        await update_tranx_history({
                            data: ref,
                            status: res[3].status,
                            clearance: OrderTransactionStages.FAILED
                        })
                        clearOrder()
                    }
                }
            }
        })
    }

    const place_order = async (payment_ref, status) => {

        if (!orderRef) return

        const data = orderRef

        if (payment_ref) {
            data['payment_ref'] = payment_ref
        }

        if (status) {
            data['task_info']['payment']['status'] = status
        }


        return await place_service_order(data)
            .then(res => {
                return update_tranx_history({
                    data,
                    type:OrderTypes.SERVICE_ORDER,
                    clearance: OrderTransactionStages.PENDING
                }).then(() => {
                    clear_order()
                    return { title: 'Order Placed', body: 'your order was placed successfuly!', type: 'success' }
                })
            })

    }

    const save_order = async (service_id) => {
        const data = {
            ref: reference,
            services: selectedServices,
            task_info: {
                service_id,
                location: selectedLocation,
                ...descriptions,
                payment: {
                    currency,
                    amount: totalCost
                }
            },
            customer: actors.customer,
            seller: actors.seller,
            task_duration: selectedDateAndTime,
            time: new Date()
        }
        return await uploadServiceImages(descriptions.images, 'service_orders').then(images => {
            data.task_info.images = images
            return data
        }).then(async (data) => {
            await saveOrder(data)
            return await create_service_order(data)
        })
    }

    const clear_order = async () => {
        return await saveOrder(null)
    }

    const value = {
        totalCost,
        setTotalCost,
        currency,
        serviceID,
        setServiceID,
        setCurrency,
        selectedServices,
        setSelectedServices,
        selectedLocation,
        setSelectedLocation,
        selectedDateAndTime,
        setSelectedDateAndTime,
        descriptions,
        setDescriptions,
        actors,
        setActors,
        place_order,
        save_order,
        clear_order,
        reference,
        setReference
    }
    return (
        <PlaceOrderProvider.Provider value={value}>
            {children}
        </PlaceOrderProvider.Provider>
    );

}

export const useOrder = () => useContext(PlaceOrderProvider)



const mapStateToProps = (state) => {
    return {
        orderRef: state.order?.payload
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        saveOrder: (data) => dispatch(save_order(data)),
        clearOrder: () => dispatch(clear_order())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PlaceOrderContext) 