import { CheckIcon, HeartIcon, LocationMarkerIcon, PlusIcon, ShareIcon, StarIcon, ViewGridIcon, XIcon } from '@heroicons/react/outline'
import React from 'react'
import { createRef } from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import BathtubIcon from '../../../assets/BathtubIcon'
import BedIcon from '../../../assets/BedIcon'
import { APP_URL } from '../../../assets/url'
import ImageSlider from '../../../Components/ImageSlider'
import ImageSlider_v2 from '../../../Components/imageSlider_v2'
import MatterPortWalkThroughView from '../../../Components/MatterPortWalkThroughView'
import Modal from '../../../Components/Modal'
import ShareModal from '../../../Components/ShareModal'
import { numberWithCommas } from '../../../functions'
import { apartment_impression, get_apartment_unit } from '../../../functions/housing_functions'
import { updateGroupImpressions } from '../../../functions/service_functions.js'
import MapUI from '../../Dashboard/DashboardComponents/FormComponents/MapUI'
import { currencySymbol } from '../functions'

const ApartmentInfo = ({ distanceFromTopOfScreen }) => {

    const { id } = useParams()
    const sellerMsg = `Hello, i found your apartment on the Guide App, and i'd like to know if it is still available ${`www.guideutility.co/apartments/${id}`}`


    const [apartment, setApartment] = useState(null)
    const [atTopOfScreen, setAtTopOfScreen] = useState(0)
    const [openShare, setOpenShare] = useState(false)


    const handleImpression = async (type = 'view') => {
        const data = {
            id: apartment.id,
            type: type
        }
        console.log(apartment.groupID)
        if (apartment.groupID) await updateGroupImpressions({ id: apartment.groupID, type })
        await apartment_impression(data)
    }


    useEffect(() => {
        get_apartment_unit({ id }).then((result) => {
            setApartment(JSON.parse(result))
        })
    }, [])

    useEffect(() => {
        setAtTopOfScreen(distanceFromTopOfScreen)
    }, [distanceFromTopOfScreen])

    useEffect(() => {
        // console.log(atTopOfScreen)
    }, [atTopOfScreen])


    const currency = apartment && currencySymbol(apartment?.features.acceptedCurrency)
    const living_space = apartment?.living_space && apartment?.living_space[0]
    const shower = living_space?.split('+')[1]
    const rooms = living_space?.split('+')[0]

    return id ? (
        <div className='w-full h-full overflow-y-auto scrollbar relative' >
            <div className='w-full h-full px-2 lg:px-[10%] pt-10 text-left relative space-y-4  '>
                <div className='space-x-3 flex items-center justify-between '>

                    {apartment ?
                        <div className='text-xl md:text-2xl lg:text-4xl font-medium'>{apartment?.title.translations.en_us}</div>
                        : <div className='w-[60%] h-8 bg-gray-200 animate-pulse' />}

                    <div onClick={() => setOpenShare(true)} className='w-10 lg:w-20 h-10 lg:h-20 rounded-full text-black cursor-pointer hover:text-black/50  flex justify-center items-center'>
                        <ShareIcon className="w-5 md:w-7 h-5 md:h-7" />
                    </div>
                </div>
                <div className='w-full h-fit'>
                    {apartment ?
                        <ImageCards images={apartment.images} />
                        :
                        <ImageCardsLaoding />}
                </div>
                <div className='flex w-full items-start'>
                    <div className='w-full lg:w-[60%] space-y-4 py-4 divide-y-2'>
                        <LivingSpace bath={shower} rooms={rooms} available={apartment?.new_doc?.living_space.available_rooms} />
                        <TourBlock tourID={apartment?.new_doc?.tour} />
                        {apartment && <div className={`block lg:hidden bg-white p-2 divide-y-2`}>
                            {currency && <PricingBlock bills={apartment?.new_doc?.facilities.bills} currency={currency} price={apartment?.price} rent={apartment?.features.rents} commission={parseFloat(apartment?.price) * 1} deposit={(apartment?.new_doc ? apartment?.new_doc.pricing.securityDeposit : apartment.features.rents * parseInt(apartment?.features.deposit))} />}
                            <ContactBlock phone_number={apartment?.contact.phone} id={id} />
                        </div>}
                        <PeopleInRoomsBlock occupants={apartment?.new_doc?.living_space?.people_in_rooms} />
                        <DescriptionBlock description={apartment?.description.translations.en_us} />
                        <LocationBlock coords={apartment?.address.coord} address={apartment?.address.address_string} hide={apartment?.new_doc?.location.hide_on_listing} />
                        {apartment?.new_doc && <BillsBlock bills={apartment.new_doc?.facilities.bills} />}
                        <AmenitiesBlock amenities={apartment?.features} />
                    </div>
                    <div className='hidden lg:flex w-[40%] px-5 pr-0 py-10 relative'>
                        {apartment && <div className={`hidden lg:flex flex-col w-full bg-white border-2 border-gray-400 p-5 rounded-3xl divide-y-2 ${atTopOfScreen > 600 ? 'fixed w-[31%] top-32 right-[10%]' : 'relative'}`}>
                            {currency && <PricingBlock bills={apartment?.new_doc?.facilities.bills} currency={currency} price={apartment?.price} rent={apartment?.features.rents} commission={parseFloat(apartment?.price) * 1} deposit={parseFloat(apartment?.new_doc ? apartment?.new_doc.pricing.securityDeposit : apartment.features.rents * parseInt(apartment?.features.deposit))} />}

                            <ContactBlock handleImpression={handleImpression} phone_number={apartment?.contact.phone} id={id} />
                        </div>}
                    </div>
                </div>
            </div>
            <div className={`fixed ease_transition flex lg:hidden ${atTopOfScreen < 600 ? 'opacity-0' : 'opacity-100'} z-10 bottom-0 w-full h-16 justify-center text-lg text-white text-center font-semibold bg-primary-500`}> <a className='w-full h-full flex text-center justify-center items-center' href={`https://wa.me/${apartment?.contact.phone}?text=${sellerMsg}`}>Make an inquiry</a></div>
            <div className='w-full h-[200px]' />
            <ShareModal type="Apartment" open={openShare} setOpen={setOpenShare} url={`${APP_URL}/apartments/${id}`} message={`Check out this rental apartment on Guide. `} />
        </div>
    ) : (
        <div>
            no apartment
        </div>
    )
}

export default ApartmentInfo

const ImageCards = ({ images }) => {
    const [show_all, setShowAll] = useState(false)
    return (
        <div className='w-full h-full lg:max-h-[55vh]'>
                <Modal open={show_all} setOpen={setShowAll} topPad={0} showBackground="transparent">
                    <ImageSlider_v2 images={images}  />
                </Modal>
            <div className='hidden lg:grid w-full relative h-full grid-cols-2 gap-4'>
                <img loading='lazy' src={images[0]} className='w-full h-[55vh] rounded-l-2xl object-cover' />
                <div className='w-full h-full grid grid-cols-2 gap-4'>

                    <div className='w-full h-[55vh] grid grid-rows-2 gap-4'>
                        <img loading='lazy' src={images[3]} className='w-full h-full object-cover' />
                        <img loading='lazy' src={images[4]} className='w-full h-full object-cover' />
                    </div>

                    <div className='w-auto h-[55vh] grid grid-rows-2 gap-4'>
                        <img loading='lazy' src={images[5]} className='w-full h-full rounded-tr-2xl object-cover' />
                        <img loading='lazy' src={images[6]} className='w-full h-full rounded-br-2xl object-cover' />
                    </div>

                </div>
                <div onClick={() => setShowAll(!show_all)} className='absolute bottom-10 right-10 px-4 py-2 flex items-center text-gray-500 space-x-4 bg-white cursor-pointer border-2 border-gray-500 rounded-lg'>
                    <ViewGridIcon className='w-5 h-5' />
                    <span className='text-lg'>Show all photos</span>
                </div>
            </div>
            <div className='flex lg:hidden w-full aspect-video '>
                <ImageSlider_v2 images={images} />
            </div>
        </div>
    )
}

const ImageCardsLaoding = () => {
    return (
        <div className='w-full h-full lg:max-h-[55vh]'>
            <div className='w-full h-[55vh] hidden lg:grid grid-cols-2 gap-4'>
                <div className='w-full h-full bg-gray-200 animate-pulse rounded-l-2xl' />
                <div className='w-full h-full grid grid-cols-2 gap-4'>

                    <div className='w-full h-full grid grid-rows-2 gap-4'>
                        <div className='w-full h-full bg-gray-200 animate-pulse'></div>
                        <div className='w-full h-full bg-gray-200 animate-pulse'></div>
                    </div>

                    <div className='w-auto h-full grid grid-rows-2 gap-4'>
                        <div className='w-full h-full bg-gray-200 animate-pulse rounded-tr-2xl'></div>
                        <div className='w-full h-full bg-gray-200 animate-pulse rounded-br-2xl'></div>
                    </div>

                </div>
            </div>
            <div className='flex lg:hidden w-full aspect-video bg-gray-200 animate-pulse rounded-lg ' />
        </div>
    )
}

const LivingSpace = ({ rooms, bath, available }) => {
    return (rooms && bath) ? (
        <div className='space-y-4'>
            <div className='flex space-x-5 items-center'>
                <div className='flex space-x-2 items-center'>
                    <BedIcon className='w-5 h-5' />
                    <span>{rooms} Bedrooms</span>
                </div>

                <div className='flex space-x-2 items-center'>
                    <BathtubIcon className='w-5 h-5' />
                    <span>{bath} Bathrooms</span>
                </div>
            </div>
            {available && <div>
                <div className='text-md font-medium py-2'>Available Rooms</div>
                <AvailableSpace rooms={rooms} available={available} />
            </div>}

        </div>
    ) : (
        <div className='space-y-4'>
            <div className='flex space-x-5 items-center'>
                <div className='flex space-x-2 items-center'>
                    <div className='w-5 h-5 bg-gray-200 animate-pulse' />
                    <div className='w-16 h-2 bg-gray-200 animate-pulse' />
                </div>

                <div className='flex space-x-2 items-center'>
                    <div className='w-5 h-5 bg-gray-200 animate-pulse' />
                    <div className='w-16 h-2 bg-gray-200 animate-pulse' />
                </div>
            </div>
        </div>
    )
}

const AvailableSpace = ({ rooms = 5, available = 2 }) => {
    return (
        <div className='flex items-center space-x-4'>
            {
                [...Array(rooms - available)].map((_, indx) => {
                    return <div key={indx} className='w-5 h-5 rounded-md bg-primary-500 border-primary-500' />
                })
            }
            {
                [...Array(available)].map((_, indx) => {
                    return <div key={indx} className='w-5 h-5 rounded-md bg-primary-300 border-2 border-primary-500' />
                })
            }
        </div>
    )
}

const DescriptionBlock = ({ description }) => {
    return description ? (
        <div className='space-y-3 py-4'>
            <div className='text-lg font-medium'>Description</div>
            <div>{description}</div>
        </div>
    )
        :
        (
            <div className='space-y-3 py-4 w-full'>
                <div className='h-4 w-[50%] bg-gray-200 animate-pulse ' />
                <div className='h-2 w-[70&]  bg-gray-200 animate-pulse' />
                <div className='h-2 w-[68%]  bg-gray-200 animate-pulse' />
                <div className='h-2 w-[64%]  bg-gray-200 animate-pulse' />
            </div>
        )
}

const PeopleInRoomsBlock = ({ occupants }) => {
    if (occupants === 0) return null
    return occupants ? (
        <div className='space-y-3 py-4'>
            <div className='text-lg font-medium'>Current Occupants</div>
            <div>There are atleast {occupants} person(s) already living in this apartment.</div>
        </div>
    )
        :
        (
            <div className='space-y-3 py-4 w-full'>
                <div className='h-4 w-[50%] bg-gray-200 animate-pulse ' />
            </div>
        )
}

const LocationBlock = ({ coords, address, hide = false }) => {

    const [position, setPosition] = useState({ lat: 0.00, lng: 0.00 })

    useEffect(() => {
        setTimeout(() => {
            setPosition({
                lat: coords?.latitude,
                lng: coords?.longitude
            })
        }, 500);
    }, [coords, address])

    useEffect(() => {
        console.log(position)
    }, [position])

    return coords ? (
        <div className='space-y-3 py-4 w-full'>
            <div className='text-lg font-medium'>Location</div>
            {
                hide ?
                    <div className='font-medium '> The location information for this property is hidden. </div>
                    :
                    <div className='space-y-3'>
                        <div className='flex items-center space-x-3'>
                            <LocationMarkerIcon className='guide-icon' />
                            <span>{address}</span>
                        </div>
                        {coords && <MapUI setPosition={setPosition} position={position} container={{ width: '100%', height: '400px' }} />}
                    </div>
            }
        </div>
    ) : (
        (
            <div className='space-y-3 py-4 w-full'>
                <div className='h-4 w-[50%] bg-gray-200 animate-pulse ' />
                <div className='h-[500px] w-[70&]  bg-gray-200 animate-pulse' />

            </div>
        )
    )
}

const BillsBlock = ({ bills }) => {
    return bills ? (
        <div className='space-y-3 py-4'>
            <div className='text-lg font-medium'>Bills</div>
            <div className='grid grid-cols-3 gaps-5 space-y-5 justify-center'>
                {
                    Object.keys(bills).map((bill, i) => {
                        return <Option key={i} text={bill} available={bills[bill]} />
                    })
                }
            </div>
        </div>
    ) : null
}

const TourBlock = ({ tourID }) => {

    const [showTour, setShowTour] = useState(false)

    return tourID && tourID != 'None' ? (
        <div className='space-y-3 py-4'>
            <div className='text-lg font-medium'>3D Virtual Tour</div>
            <div className=''>You can get a real-time 3D walk-though of this apartment right in the browser.</div>
            <div onClick={() => setShowTour(!showTour)} className=' cursor-pointer hover:text-primary-500/70 rounded-full text-primary-500 font-bold'>
                Start Tour
            </div>
            <Modal topPad={10} width={14} open={showTour} setOpen={setShowTour}>
                <div className=''>
                    <MatterPortWalkThroughView port_id={tourID} />
                </div>
            </Modal>
        </div>
    ) : null
}

const AmenitiesBlock = ({ amenities }) => {

    const facilities = Object.keys(amenities ?? {})
    const contraband = ['acceptedCurrency', 'rents', 'deposit', 'good_for', 'minsToBusttop']
    return (
        <div className='space-y-3 py-4'>
            <div className='text-lg font-medium'>Amenities</div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gaps-5 space-y-5 justify-center'>
                {
                    facilities?.map((text, indx) => {
                        return !contraband.includes(text) && <Option key={indx} text={text.split('_').join(' ')} available={amenities[text]} />
                    })
                }
            </div>
        </div>
    )
}

const Option = ({ text, available = false }) => {
    return (
        <div className='text-base space-x-2 flex items-center'>
            <div>
                {available ?
                    <CheckIcon className='guide-icon text-green-600' />
                    :
                    <XIcon className='guide-icon text-red-600' />
                }
            </div>
            <div>{text}</div>
        </div>
    )
}

const PricingBlock = ({ price, rent, currency, deposit, bills = null }) => {

    const billsList = bills && Object.keys(bills)?.filter(b => bills[b] === true)
    const billsinfo = billsList?.length > 0 ? billsList?.length < 4 ? 'Some Bills Included' : "Bills Included" : "No Bills Included"
    const billsColor = billsList?.length > 0 ? billsList?.length < 4 ? 'text-orange-400' : "text-green-600" : "text-red-500"
    return (
        <div className='w-full flex flex-col items-center justify-center'>
            <div className='flex items-center space-x-2 text-center py-4 justify-center'>
                <div>
                    <div className='flex flex-col items-center font-semibold'>
                        <span className='text-blue-400'>Rent from</span>
                        <span className='text-xl lg:text-3xl text-primary-500'>{currency} {numberWithCommas(price)}</span>
                        <span className='text-sm lg:text-sm text-gray-400'>x{rent} months rent</span>
                    </div>
                </div>
                <div>
                    <PlusIcon className='w-5 h-5' />
                </div>
                <div className='flex flex-col items-center font-semibold'>
                    <span className='text-blue-400'>Deposit</span>
                    <span className='text-xl lg:text-3xl text-primary-500'>{currency} {numberWithCommas(deposit)}</span>
                    <span className='text-xs lg:text-sm text-gray-400'>one-off payment</span>
                </div>
            </div>
            {billsList &&
                <div className=' flex items-center space-x-3'>
                    <img src="https://static.thenounproject.com/png/394906-200.png"
                        className='w-16 h-16 object-contain object-center' />
                    <div className=' py-4'>
                        <div className={`text-lg font-semibold text-primary-800 ${billsColor}`}>{billsinfo}</div>
                        <div className='text-sm text-gray-400 font-medium'>{billsList.map((bill, i) => (<span className=' capitalize'>{`${bill} ${i < (billsList.length - 1) ? ', ' : ''}`}</span>))}</div>
                    </div>
                </div>
            }
        </div>
    )
}

const ContactBlock = ({ handleImpression, phone_number = '+90 544 367 8745', id }) => {
    const [show, setShow] = useState(true)
    const [number, setNumber] = useState('')

    useEffect(() => {
        setNumber(`${phone_number.slice(0, 7)}  ${"•".repeat(3)}  ${"•".repeat(4)}`)
    }, [])
    const sellerMsg = `Hello, i found your apartment on the Guide App, and i'd like to know if it is still available ${`www.guideutility.co/apartments/${id}`}`

    const ShowHide = async () => {
        const num = number.split(' ').join('')
        const hashed = "•".repeat(3) + ' ' + "•".repeat(4)
        if (show) {
            setNumber(phone_number)
            await handleImpression('phone')
        } else {
            setNumber(`${phone_number.slice(0, 7)} ${hashed}`)
        }
        setShow(!show)
    }
    return (
        <div className='text-center py-4 space-y-2'>
            <div className="py-2">
                <a href={`https://wa.me/${phone_number}?text=${sellerMsg}`} className='text-xl lg:text-4xl text-white px-2 lg:px-4 py-1 lg:py-2 rounded-full bg-primary-500'>Make an inquiry</a>
            </div>
            <div>Or Call The Landlord</div>
            <div className='flex items-center justify-center space-x-4'>
                <div>{number}</div>
                <div onClick={ShowHide} className='font-medium text-orange-500 hover:underline cursor-pointer'>Show number</div>
            </div>
        </div>
    )
}