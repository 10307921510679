import { CalendarIcon, ClipboardCheckIcon, ClipboardListIcon, StarIcon } from '@heroicons/react/outline'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { UpdatesStatusTypes } from '../../../../Pages/Dashboard/Screens/Jobs/JobsMenu/data'
import ReviewComponent from '../../../ReviewComponent'
import { Bubble } from '../TaskSenderBubble'

const TaskRecieverBubble = ({ message }) => {



    const [time, setTime] = useState('')

    useEffect(() => {
        (async () => {
            const date = new Date(message.time)
            setTime(`${(String(date.getHours()).padStart(2, '0'))}:${(String(date.getMinutes()).padStart(2, '0'))} ${date.getHours() > 12 ? 'PM' : 'AM'}`)
        })();
    }, [])

    switch (message.type) {
        case UpdatesStatusTypes.STARTED:
            return (
                <Bubble align="right">
                    <div className='bg-white border-r-8 border-green-500 p-4 pr-6 flex items-start rounded-2xl rounded-r-none text-green-500 space-x-4'>
                        <div className="p-4 bg-green-200 aspect-square rounded-full flex justify-center items-center">
                            <ClipboardListIcon className='w-7 h-7 text-green-500' />
                        </div>
                        <div className='flex flex-col space-x-1 text-left'>
                            <div className='text-lg font-semibold'>Request Accepted</div>
                            <div className=' self-start'>you accepted this user's request</div>
                        </div>
                        <div className='py-2 text-sm lowercase'>{time}</div>
                    </div>
                </Bubble>
            )
        case UpdatesStatusTypes.CANCELED:
            return (
                <Bubble align="right">
                    <div className='bg-white border-r-8 border-red-500 p-4 pr-6 flex items-start rounded-2xl rounded-r-none text-red-500 space-x-4'>
                        <div className="p-4 bg-red-200 aspect-square rounded-full flex justify-center items-center">
                            <ExclamationCircleIcon className='w-7 h-7 text-red-500' />
                        </div>
                        <div className='flex flex-col space-x-1 text-left'>
                            <div className='text-lg font-semibold'>Task Cancelled</div>
                            <div className=' self-start'>you cancelled this user's request</div>
                        </div>
                        <div className='py-2 text-sm lowercase'>{time}</div>
                    </div>
                </Bubble>
            )
        case UpdatesStatusTypes.REQUESTING_EXTENSION:
            return (
                <Bubble align="right">
                    <div className='bg-white border-r-8 border-orange-500 p-4 pr-6 flex items-start rounded-2xl rounded-r-none text-orange-500 space-x-4'>
                        <div className="p-4 bg-orange-200 aspect-square rounded-full flex justify-center items-center">
                            <CalendarIcon className='w-7 h-7 text-orange-500' />
                        </div>
                        <div className='flex flex-col space-x-1 text-left'>
                            <div className='text-lg font-semibold'>Deadline Extension Request</div>
                            <div className=' self-start'>you requested a {message?.message?.data.days} day(s) extension on the deadline for this task.</div>
                        </div>
                        <div className='py-2 text-sm lowercase'>{time}</div>
                    </div>
                </Bubble>
            )

        case UpdatesStatusTypes.COMPLETED:
            return (
                <Bubble align="right">
                    <div className='bg-white border-r-8 border-orange-500 p-4 pr-6 flex items-start rounded-2xl rounded-r-none text-orange-500 space-x-4'>
                        <div className="p-4 bg-orange-200 aspect-square rounded-full flex justify-center items-center">
                            <CalendarIcon className='w-7 h-7 text-orange-500' />
                        </div>
                        <div className='flex flex-col space-x-1 text-left'>
                            <div className='text-lg font-semibold'>Task Completed</div>
                            <div className=' self-start'>you completed this task.</div>
                        </div>
                        <div className='py-2 text-sm lowercase'>{time}</div>
                    </div>
                </Bubble>
            )

        case UpdatesStatusTypes.REQUESTING_REVIEW:
            return (
                <Bubble align="right">
                    <div className='bg-white border-r-8 border-yellow-500 p-4 pr-6 flex items-start rounded-2xl rounded-r-none text-yellow-500 space-x-4'>
                        <div className="p-4 bg-yellow-200 aspect-square rounded-full flex justify-center items-center">
                            <StarIcon className='w-7 h-7 text-yellow-500' />
                        </div>
                        <div className='flex flex-col space-x-1 text-left'>
                            <div className='text-lg font-semibold'>Review Request</div>
                            <div className=' self-start'>you requested a review from this user.</div>
                        </div>
                        <div className='py-2 text-sm lowercase'>{time}</div>
                    </div>
                </Bubble>
            )
        case UpdatesStatusTypes.REQUESTING_COMPLETED:
            return (
                <Bubble align="right">
                    <div className='bg-white border-r-8 border-yellow-500 p-4 pr-6 flex items-start rounded-2xl rounded-r-none text-yellow-500 space-x-4'>
                        <div className="p-4 bg-yellow-200 aspect-square rounded-full flex justify-center items-center">
                            <ClipboardCheckIcon className='w-7 h-7 text-yellow-500' />
                        </div>
                        <div className='flex flex-col space-x-1 text-left'>
                            <div className='text-lg font-semibold'>Marked as Completed</div>
                            <div className=' self-start'>You marked your task as completed.</div>
                            <div className=' self-start'>awaiting confirmation.</div>
                        </div>
                        <div className='py-2 text-sm lowercase'>{time}</div>
                    </div>
                </Bubble>
            )
        default:
            return null
    }


}

export default TaskRecieverBubble

