export const ViewMode = {
    BOARD: "board",
    CALENDAR: "calendar"
}

export const UpdatesStatusTypes = {
    PENDING: "PENDING", // 1
    STARTED: "STARTED", // 2
    APPROVAL_REJECTED: "APPROVAL_REJECTED",
    REQUESTING_EXTENSION: "REQUESTING_EXTENSION",
    EXTENSION_APPROVED: "EXTENSION_APPROVED",
    EXTENSION_REJECTED: "EXTENSION_REJECTED",
    OVERDUE: "OVERDUE",
    COMPLETED: "COMPLETED", // 3
    REQUESTING_COMPLETED: "REQUESTING_COMPLETED", // 3
    REJECT_COMPLETED: "REJECT_COMPLETED",
    DECLINED: "DECLINED", // 4
    FINISHED: "FINISHED", // 5
    CANCELED: "CANCELED",
    REQUESTING_REVIEW: "REQUESTING_REVIEW",
    GIVEN_REVIEW: "GIVEN_REVIEW"

}

export const OrderTransactionStages = {
    PENDING: "PENDING",
    REFUNDED: "REFUNDED",
    CLEARED: "CLEARED",
    FROZEN: "FROZEN",
    FAILED: "FAILED",
    PAID: "PAID"
}


export const OrderTypes = {
    SERVICE_ORDER: "SERVICE_ORDER",
    ADVERTISE_APARTMENT_ORDER: "ADVERTISE_APARTMENT_ORDER",
    BUSINESS_PLUGIN_ORDER: "BUSINESS_PLUGIN_ORDER"
}