import React, { useEffect, useState } from 'react'
import RatingBubble, { LoadingRatingBubble } from '../../../../Components/RatingBars/RatingBubble'
import { loadReviews } from '../../../../functions/service_functions.js'
import DashboardWrapper from '../../DashboardComponents/DashboardWrapper'
import { DropDownList_SM } from '../../DashboardComponents/DropDownList'
import DashboardReviewCard from '../Main/Cards/DashboardReviewCard'
import NOReviews from '../../../../assets/Group 597.svg'
import moment from 'moment'
import { useParams } from 'react-router'
import { ClipboardCopyIcon } from '@heroicons/react/outline'
import { APP_URL } from '../../../../assets/url'
import { useAlert } from '../../../../Components/UtilityComponents/ErrorContext'


const Review = () => {

    const { id } = useParams()



    const [data, setAllData] = useState(null)
    const [total, setTotal] = useState(null)
    const [filteredData, setFilteredData] = useState(null)

    useEffect(() => {
        loadReviews({ gid: id, with_meta: true }).then((res) => {
            console.log(res.hits.hits)

            setTotal(res.hits.total.value)
            const review = res.hits.hits.map(r => r._source)
            setAllData(review)
            setFilteredData(review)
            return review
        })
    }, [])

    const filterByTimeFrame = async (mins) => {
        if (!data) return
        const currentTime = new Date();
        const tmp = await data.filter((elem) => {
            const diffInMins = (currentTime - Date.parse(elem.datePublished)) / (1000 * 60);
            return diffInMins < mins
        })

        setFilteredData(tmp)

    }

    useEffect(() => {
     }, [total, data, filteredData])



    const intervals = [
        { id: 1, value: '30 seconds', unavailable: false, action: filterByTimeFrame, time: .03 },
        { id: 2, value: '30 minutes', unavailable: false, action: filterByTimeFrame, time: 30 },
        { id: 3, value: 'hour', unavailable: false, action: filterByTimeFrame, time: 60 },
        { id: 4, value: 'day', unavailable: true, action: filterByTimeFrame, time: 1440 },
        { id: 5, value: 'month', unavailable: false, action: filterByTimeFrame, time: 43200 },
    ]



    return (
        <DashboardWrapper title="Review">
            <div className='w-full flex flex-col h-fit xl:flex-row space-x-0 xl:space-x-3 p-5 '>
                <div className='xl:w-[30%] w-full bg-white mx-0 xl:mx-3 max-h-[100%] my-4 xl:my-0 min-h-80 rounded-xl'>
                    <DashboardReviewCard group={id} _data={{ data: data?.slice(0, 2), total: total }} />
                </div>
                <div className='flex flex-col flex-1 bg-white mx-0 xl:mx-3 min-h-80 rounded-xl max-h-[600px] p-5 relative'>
                    <div className='text-sm text-textcolor flex justify-start my-2 mb-5'>
                        Reviews ({total | 0})
                    </div>

                    <div className='w-full space-y-2 overflow-y-auto scrollbar-sm h-auto px-2'>
                        {
                            filteredData?
                            filteredData?.length > 0 ?
                                filteredData?.map((rating, index) => {
                                    return (
                                        <div className='w-auto '>
                                            <RatingBubble
                                                key={index}
                                                by={rating.author?.display_name}
                                                at={moment(rating?.datePublished).fromNow()}
                                                rating={rating.ratingValue}
                                                comment={rating.reviewBody} />
                                            {
                                                filteredData.length > 3 ?
                                                    index == 3 && <InviteLink id={id} />
                                                    :
                                                    index === filteredData.length - 1 && <InviteLink id={id} />

                                            }
                                        </div>
                                    )
                                })
                                :
                                <div className=' flex flex-col items-center justify-center space-y-3'>
                                    <img src={NOReviews} className=" w-24 h-24" alt='guide no reviews notice image' />
                                    <div className='text-gray-400'>
                                        <div className='text-lg font-medium text-gray-500 '>No Reviews Yet</div>
                                        <div className='text-xs '>Your customers’ review show up here</div>
                                    </div>
                                    <div className='pt-10'>
                                        <InviteLink id={id} />
                                    </div>
                                </div>
                                :
                                <div className='space-y-2'>
                                <LoadingRatingBubble />
                                <LoadingRatingBubble />
                                <LoadingRatingBubble />
                                <LoadingRatingBubble />
                            </div>
                        }

                    </div>
                    <DropDownList_SM list={intervals} />
                </div>
                {/* <div className='flex w-full items-center min-h-16 justify-start text-textcolor relative'> */}
                {/* </div> */}
                <div className='w-full h-32 flex xl:hidden' />
            </div>
        </DashboardWrapper>
    )
}

const InviteLink = ({ id }) => {
    const url = APP_URL
    const alert = useAlert()

    const copyUrlToClipboard = () => {
        navigator.clipboard.writeText(`${url}/services/${id}`).then(() => {
            alert.setalert({ type: 'success', title: "Coppied", body: 'copied!' })
        })
    }

    return (
        <div className='w-full bg-[#FFDE99] rounded-md h-auto m-2 p-4 text-left text-sm space-y-2'>
            <div className='text-gray-400 '>Getting Reviews from your regulars</div>
            <div className='font-sans text-base font-normal'>If your are looking to increase your reviews, you can ask your regular customers, family and friends to give you their reviews by sending them your personal business page link. </div>
            <div className='flex items-center w-full lg:w-[40%] border-2 border-gray-400 text-gray-400 font-normal rounded-lg bg-white'>
                <div className=' w-[70%] border-r-2 border-gray-400 p-2 '>
                    <div className='truncate px-4'>{`${url}/services/${id}`}</div>
                </div>
                <div onClick={() => copyUrlToClipboard()} className=' cursor-pointer hover:bg-primary-300 hover:text-primary-500 rounded-r-lg flex flex-1 items-center space-x-3 p-2 justify-center'>
                    <ClipboardCopyIcon className='w-4 h-5' />
                    <div>copy link</div>
                </div>
            </div>
        </div>
    )
}

export default Review