import { OfficeBuildingIcon, PresentationChartBarIcon, UserAddIcon, UserGroupIcon } from "@heroicons/react/outline";

export const account_types = [
    // {
    //     title:"Student",
    //     type:'student',
    //     memo: "i'm interested in using services provided on Guide.",
    //     logo: <UserGroupIcon className="w-10 h-10" />
    // },
    {
        title:"Housing Agent",
        type:'housing_agent',
        memo: "I want to rent out my apartments on Guide.",
        logo: <OfficeBuildingIcon  className="w-10 h-10" />
    },
    // {
    //     title:"Service Provider",
    //     type:'service_provider',
    //     memo: "I want to offer my services to students on Guide.",
    //     logo: <UserAddIcon  className="w-10 h-10" />
    // },
    {
        title:"Business Owner",
        type:'business_owner',
        memo: "I want to put my business on Guide.",
        logo: <PresentationChartBarIcon  className="w-10 h-10" /> 
    }
]

export const AccountTypes = {
    STUDENT: 'student',
    HOUSING_AGENT:'housing_agent',
    SERVICE_PROVIDER: 'service_provider',
    BUSINESS_OWNER:'business_owner'
} 