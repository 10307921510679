import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import MatterPortWalkThroughView from '../../../../../../Components/MatterPortWalkThroughView'
import DashboardFormField from '../../../../DashboardComponents/DashboardFormField'
import TipsCard from '../../../../DashboardComponents/FormComponents/TipsCard'

const TourForm = ({ housing }) => {
    const [tour, setTour] = useState('')
    const [showTour, setShowTour] = useState(false)
    const sample = 'x3Kiaj9SBBr'

    useEffect(() => {
        setTour(housing.stored_housing?.tour)

        return () => {
            setShowTour(false)
        }
    }, [])

    useEffect(() => {
        housing.updateTour((tour.length <= 0 || tour === sample) ? 'None' : tour)
    }, [tour])

    const handleShowExample = () => {
        if (!showTour && tour?.length > 10) {
            setShowTour(true)
            return
        }

        if (showTour) {
            setTour('')
        } else {
            setTour(sample)
        }
        setShowTour(!showTour)
    }

    return (
        <div className='flex h-auto items-start justify-start w-full'>
            <div className='py-5 w-full md:w-[60%] '>
                <div>
                    <div className='max-w-lg'>
                        <DashboardFormField rows={10} name="Matter Port Tour ID" placeholder="xxxxxxxxxx" maxLength={20} text={tour} setText={setTour} />
                    </div>
                    <div className='flex px-5 items-center justify-between max-w-lg'>
                        <div onClick={handleShowExample} className='text-blue-400 underline font-medium cursor-pointer'>{showTour ? 'hide tour' : tour?.length > 10 ? 'show Tour' : 'show example tour'}</div>
                        <div className=' flex justify-end '>{tour?.length} / <b> 20</b></div>
                    </div>
                </div>
                {(tour?.length > 1 && showTour) ?
                    <div className='w-[95%] pt-5'><MatterPortWalkThroughView port_id={tour} /></div>
                    :
                    <div>

                    </div>
                }

            </div>
            <div className='hidden md:w-[40%] h-full md:flex justify-start'>
                <TipsCard />
            </div>
        </div>
    )
}

export default TourForm