import { AUTH_SERVER_URL } from "../../../../assets/url"

const contraband = ['UNAUTHORIZED']



export const post_fetch = (url, data, server = null, options = {}) => {
    console.log(server ? server : AUTH_SERVER_URL, '==================')
    const headers = {
        'Content-Type': options.content_type ? options.content_type : 'application/json',
        'Access-Control-Allow-Credentials': options.allow_credentials ? options.allow_credentials : true
    }

    if (options.content_type === null) {
        delete headers['Content-Type']
    }

    if(server && !server.includes('http')){
        server = `http://${server}`
    }

    return new Promise(async (resolve, reject) => {
        return await fetch(`${server ? `${server}` : AUTH_SERVER_URL}/${url}`, {
            method: 'POST',
            credentials: options.credentials ? options.credentials : 'include',
            headers,
            body: options.data_type ? data : JSON.stringify(data)
        }).then(async response => {
            if (contraband.includes(response.statusText)) {
                return reject(response.statusText)
            }
            if(!response.ok){
                return reject({
                    status: response.status,
                    message: await response.json()?.error || null
                })
            }
            const resp = await response.json()
            if (resp?.error) {
                console.log('TT ERROR: ', resp)
                reject(resp)
            }
            resolve(resp)
        }).catch(err => reject(err))
    })
}

export const put_fetch = (url, data, server = null) => {
    return new Promise(async (resolve, reject) => {
        return await fetch(`${server ? server : AUTH_SERVER_URL}/${url}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true
            },
            body: JSON.stringify(data)
        }).then(async response => {
            if (contraband.includes(response.statusText)) {
                return reject(response.statusText)
            }
            const resp = await response.json()
            if (resp.error) {
                console.log('TT ERROR: ', resp)
                reject(resp)
            }
            resolve(resp)
        }).catch(err => reject(err))
    })
}

export const get_fetch = (url, other = null, server = null) => {
    return new Promise(async (resolve, reject) => {
        return await fetch(`${server ? server : AUTH_SERVER_URL}/${url}`, {
            method: 'GET',
            credentials: 'include',
            headers: other?.noHeader ? {} : {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true
            }
        }).then(async response => {
            if (contraband.includes(response.statusText)) {
                return reject(response.statusText)
            }
            const resp = await response.json()
            if (resp?.error) {
                // console.log('TT ERROR: ', resp)
                reject(resp)
            }
            resolve(resp)
        }).catch(err => reject(err))
    })
}

export const del_fetch = (url, server = null) => {
    return new Promise(async (resolve, reject) => {
        return await fetch(`${server ? server : AUTH_SERVER_URL}/${url}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true
            }
        }).then(async response => {
            if (contraband.includes(response.statusText)) {
                return reject(response.statusText)
            }
            const resp = await response.json()
            console.log('RESPONSE:', resp)

            if (resp.error) {
                console.log('TT ERROR: ', resp)
                reject(resp)
            }
            resolve(resp)
        }).catch(err => reject(err))
    })
}




export const handleSelectCourse = async (data) => {
    return await post_fetch('api/school/neu/eng_timetable/select_course', { selected_courses: data })
}

export const queryTimeTable = async (query) => {
    return await post_fetch('api/school/neu/eng_timetable/query', { query })
}

export const generateTimeTable = async (courseList) => {
    return await post_fetch('api/school/neu/eng_timetable/generate', { courseList })
}

export const normalizeTimetable = async (timetable) => {
    return await post_fetch('api/school/neu/eng_timetable/normalize_timetable', { timetable })
}

export const getWeatherReport = async () => {
    return await get_fetch('api/weather/report')
}

export const getTimetableData = async (uid) => {
    return await get_fetch('/api/neu/timetable?uid=' + uid)
}

export const saveTimetableData = async (data) => {
    return await post_fetch('api/neu/store_timetable', data)
}

export const sendEmailVerificationEmail = async (data) => {
    return await post_fetch('api/email/verify', data)
}