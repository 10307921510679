import { ChevronDownIcon, SortAscendingIcon } from '@heroicons/react/outline'
import React from 'react'
import { useState } from 'react'
import DropDownMenu from '../../../../../Components/DropDownMenu'

const DashboardApartmentsFilterBar = ({ setList, list, apartment }) => {
    const [openDropmenu, setOpenDropMenu] = useState(false)
    const [sortValue, setSortValue] = useState('Date Added')
    const [filter, setFilter] = useState('all')

    const actionList = [
        {
            id: 1,
            title: "Date Added",
            action: () => sortListByDate("Date Added", 'date'),
            disabled: false
        },
        {
            id: 1,
            title: "Price",
            action: () => sortListByDate("Unit Price", 'price'),
            disabled: false
        },
    ]

    const orderList = (value) => {
        if(value === 'all'){
            setList(apartment)
        }
        if(value === 'active'){
            setList(apartment.filter((v) => v.status === "available"))
        }
        if(value === 'inactive'){
            setList(apartment.filter((v) => v.status === "rented"))
        }
        setFilter(value)
    }

    const sortListByDate = async (title, by = 'date') => {
        
        if (by === 'date') {
            setList(await list.sort((a, b) => new Date(a.created_at) > new Date(b.created_at) ? -1 : 1))
        }

        if (by === 'price') {
            setList(await list.sort((a, b) => parseFloat(a.price) - parseFloat(b.price) ? -1 : 1))
        }

        setOpenDropMenu(false)

        setSortValue(title)
    }

    return (
        <div className='flex text-sm items-center justify-between px-5 md:text-lg'>
            <div className='flex items-center relative space-x-2 md:space-x-4 text-gray-400'>
                <SortAscendingIcon className='w-5 h-5' />
                <div className='font-bold text-gray-700'>{sortValue}</div>
                <ChevronDownIcon onClick={() => setOpenDropMenu(!openDropmenu)} className='w-5 h-5 cursor-pointer' />
                {openDropmenu && <div className='relative left-0 top-5'><DropDownMenu list={actionList} /></div>}
            </div>
            <div className='flex items-center space-x-2 md:space-x-4 font-bold text-gray-400'>
                <div onClick={() => orderList('all')} className={`cursor-pointer ${filter === 'all' && 'text-primary-800'}`}>All</div>
                <div onClick={() => orderList('active')} className={`cursor-pointer ${filter === 'active' && 'text-primary-800'}`}>Active</div>
                <div onClick={() => orderList('inactive')} className={`cursor-pointer ${filter === 'inactive' && 'text-primary-800'}`}>Inactive</div>
            </div>
        </div>
    )
}

export default DashboardApartmentsFilterBar