import { ArrowRightIcon } from '@heroicons/react/outline'
import React from 'react'
import { Link } from 'react-router-dom'
import NavBar from '../../../Components/NavBar'

const NotFoundPage = () => {
    return (
        <div className='w-full h-full'>
            <NavBar />
            <div className='w-full h-full flex flex-col justify-center items-center'>
                <div className='text-[100px] text-primary-500 font-extrabold'>404</div>
                <div className='text-lg text-primary-500 font-medium'>This page does not exist</div>
                <div className='text-sm text-gray-400'>This could be a broken link.</div>
                <Link className='flex items-center space-x-4' to="/">go to home <ArrowRightIcon className='w-5 h-5' /></Link>
            </div>
        </div>
    )
}

export default NotFoundPage