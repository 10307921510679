import { CalendarIcon, CheckCircleIcon, CheckIcon, CurrencyDollarIcon, DocumentTextIcon, IdentificationIcon, VideoCameraIcon } from '@heroicons/react/outline'
import React from 'react'
import { connect } from 'react-redux'
import { useAuth } from '../../../../../../../../../Components/UtilityComponents/AuthProvider'
import { useBilling } from '../../../../../../../../../Components/UtilityComponents/BillingContext'
import ActivateAccount from '../ActivateAccount'
import CollectPayment from '../CollectPayment'
import BillingOverView from '../Overview'
import ScheduleCall from '../ScheduleCall'
import UploadID from '../UploadID'
import { Stages } from '../UploadID/stages'


const PaginateBilling = ({ stage, current_user }) => {
    const auth = useAuth()

    const user = current_user?.user

    switch (stage) {
        case Stages.INTRODUCTION:
            return <BillingOverView />
        case Stages.REGISTRATION_FEE:
            return <CollectPayment user={user} />
        case Stages.UPLOAD_ID:
            return <UploadID user={user} />
        case Stages.SCHEDULE_CALL:
            return <ScheduleCall user={user} />
        case Stages.ACTIVATED_ACCOUNT:
            return <ActivateAccount />
        default:
            return <ActivateAccount />
    }
}

const StartPayment = ({current_user}) => {
    const billing = useBilling()
    console.log(billing.stage)
    return (
        <div className='p-0 md:p-4  rounded-md flex justify-center items-center w-full h-full'>
            <div className='flex h-full'>
                <div className='flex flex-col py-5 md:w-[50%]'>
                    <div className='text-center w-full space-y-3 mb-7 px-5 hidden flex-col md:flex'>
                        <div className='text-lg font-bold'>Submit Documents</div>
                        <div className='text-sm'>To use a business class account, we need to verify your identity. We do this to protect you and your clients.</div>
                    </div>
                    <div>
                        <div className='space-y-4'>
                           
                            <StepCard icon={{ icon: DocumentTextIcon }} title="Overview" tag="read and understand important information" active={billing.stage === Stages.INTRODUCTION}  />
                            <StepCard icon={{ icon: CurrencyDollarIcon }} title="Registration Fee" tag="Pay a $30 registration fee" active={billing.stage === Stages.REGISTRATION_FEE}  />
                            <StepCard icon={{ icon: IdentificationIcon }} title="Photo ID" tag="Upload your photo ID" active={billing.stage === Stages.UPLOAD_ID} />
                            <StepCard icon={{ icon: CalendarIcon }} title="Schedule Video Call" tag="choose a date and time for a video call with Guide representatives" active={billing.stage === Stages.SCHEDULE_CALL}  />
                            <StepCard icon={{ icon: CheckCircleIcon }} title="Account Activation" tag="Your Business account is activated and ready for use." active={billing.stage === Stages.ACTIVATED_ACCOUNT}  />

                        </div>
                    </div>
                </div>
                <div className='flex flex-col justify-start py-5 flex-1 h-full bg-primary-200'>
                    <PaginateBilling stage={billing.stage} current_user={current_user} />
                </div>
            </div>
        </div>
    )
}

const StepCard = ({ title, tag, icon, active }) => {
    return (
        <div className={`flex space-x-2 items-center py-3 px-3 border-0 md:border-[1px] border-dashed ${active && 'bg-primary-200 md:border-r-0'} `}>
            <icon.icon className='w-5 md:w-10 h-5 md:h-10 stroke-1' />
            <div className='flex-col hidden md:flex'>
                <span className='font-semibold'>{title}</span>
                <span className='text-xs text-gray-400'>{tag}</span>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    console.log(state.user?.data)
    return {
        current_user: state.user?.data
    }
}

export default connect(mapStateToProps)(StartPayment)