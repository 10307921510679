import { ChevronLeftIcon, ChevronRightIcon, ClipboardCheckIcon, ClipboardListIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import EditBlogPost, { BlogBlockTypes } from '..'
import { useBlogDrafts } from '../../../../../../Components/UtilityComponents/BlogContext'
import { useAlert } from '../../../../../../Components/UtilityComponents/ErrorContext'
import { delete_blog_post, publish_blog_post, update_blog_post } from '../../../../../../functions/blog_functions'
import { deleteServiceImages, getCoordHash, uploadServiceImages } from '../../../../../../functions/service_functions.js'
import { blogCategories } from '../data'
import DeleteBlogPostDialog from '../NoticeModals/DeletePost'
import PublishBlogPostDialog from '../NoticeModals/PublishPost'
import SetupBlog from '../SetupBlog'
import Loader from '../../../../../../assets/spinner.svg'
import moment from 'moment'

const Blog = ({ user, gid, editData, setCloseModal, reloadRecents }) => {
    const [page, setpage] = useState(0)
    const [id, setId] = useState(null)
    const [draftID, setDraftID] = useState(null)
    const [post, setPost] = useState([])
    const [title, setTitle] = useState('')
    const [setupHasError, setSetuphasError] = useState(false)
    const [category, setcategory] = useState(blogCategories[0])
    const [cover_photo, setCoverPhoto] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [open, setOpen] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [address, setAddress] = useState(null)
    const [last_saved, setLastSaved] = useState(null)

    const alert = useAlert()
    const blogDraft = useBlogDrafts()

    useEffect(() => {
        if (editData) {
            if (editData.draftID) {
                setDraftID(editData.draftID)
                setId(editData.id)
                setPost(editData.post)
                setTitle(editData.title)
                setcategory(editData.category)
                setCoverPhoto(editData.cover)
                setAddress(editData.address)
                setLastSaved(editData.last_saved)
            } else {
                setId(editData.id)
                setPost(editData.post)
                setTitle(editData.title)
                setcategory(blogCategories.filter(i => i.value === editData.category)[0])
                setCoverPhoto(editData.cover)
                setAddress(editData.address)
            }
        } else {
            setId(null)
            setPost([])
            setTitle('')
            setcategory(blogCategories[0])
            setCoverPhoto([])
            setAddress(null)
            blogDraft.draftBlog(new Date().toISOString(), {
                post,
                title,
                category,
                group_id: gid.id,
                cover_photo,
                address
            }).then((draft_id) => {
                setDraftID(draft_id)
            })
        }
    }, [])

    useEffect(() => {
        console.log('draft ID changed', draftID)
    },[draftID])

    const pageChange = (value) => {

        if (value === 'draft') {
            const data = {
                post,
                title,
                group_id: gid.id,
                address,
                category: category,
                cover: cover_photo
            }

            blogDraft.updateDraft(draftID, data)
            setLastSaved(new Date())
            return
        }
        setSetuphasError(false)

        if (page === 0 && (title === '' || cover_photo.length <= 0)) {
            alert.setalert({ type: 'error', body: 'provide all fields to continue', title: 'Missing Fields' })
            setSetuphasError(true)
            return
        }

        if (page > 1 || (page <= 0 && value === -1)) return
        setpage(page + value)
    }


    const saveDraft = () => {
        pageChange('draft')
    }

    const publishPost = async () => {
        if (!(post.length > 0)) {
            alert.setalert({ type: 'error', body: 'no blocks to publish', title: 'No publishable blocks' })
            return
        }

        const coord = {
            name: address?.address,
            position: {
                lon: address?.coords.longitude,
                lat: address.coords.latitude
            }
        }

        const data = {
            uid: user?.user.uid,
            user: {
                uid: user?.user.uid,
                name: user?.user.display_name,
                photo: user?.user.photo_url
            },
            post,
            title,
            group_id: gid.id,
            category: category.value
        }

        if (!coord.name) {
            alert.setalert({ type: 'error', body: 'your location was not set, go back to the previous page!', title: 'Location Unset!' })
            return
        }


        const tmp_cover_photo = Array.isArray(cover_photo) ? cover_photo : [cover_photo]

        return await uploadServiceImages(tmp_cover_photo, `student-blog/${data.title}-${user?.user.uid}`).then(cover => {
            data['cover'] = cover[0]
        }).then(async () => {

            const pos = await getCoordHash(coord)
            data['pos'] = pos.position

            return await Promise.all(post.map(async (item, indx) => {
                if (item.type === BlogBlockTypes.IMAGE_BLOCK) {
                    if (Array.isArray(item.value)) {
                        const imageUrl = await uploadServiceImages(item.value, `student-blog/${data.title}-${user?.user.uid}-${indx}`)
                        return { ...item, value: imageUrl[0] }
                    } else {
                        return item
                    }
                } else {
                    return item
                }
            }))
        }).then(async (result) => {

            data['post'] = result

            if (id !== null) {
                data['id'] = id
                return await update_blog_post(data).then(() => {
                    alert.setalert({ type: 'success', body: 'published!', title: 'Done!' })
                }).catch(() => {
                    alert.setalert({ type: 'error', body: 'something went wrong, please try again!', title: 'Network Error!' })
                })
            } else {
                data['created_at'] = new Date().toISOString()
                return await publish_blog_post(data).then(id => {
                    setId(id)
                    alert.setalert({ type: 'success', body: 'published!', title: 'Done!' })
                    return id
                }).catch(() => {
                    alert.setalert({ type: 'error', body: 'something went wrong, please try again!', title: 'Network Error!' })
                })
            }
        }).catch(() => {
            alert.setalert({ type: 'error', body: 'something went wrong, please try again!', title: 'Network Error!' })
        }).finally(() => {
            reloadRecents()
        })
    }

    const deleteBlogPost = async () => {
        if (draftID) {
            return blogDraft.deleteDraft(draftID).then(() => {
                alert.setalert({ type: 'success', body: 'deleted!', title: 'Done!' })
                setCloseModal(false)
            })
        }
        const tmp_cover_photo = Array.isArray(cover_photo) ? cover_photo : [cover_photo]
        return await deleteServiceImages(tmp_cover_photo).then(async () => {
            return await Promise.all(post.map(async (item, indx) => {
                if (item.type === BlogBlockTypes.IMAGE_BLOCK) {
                    if (!Array.isArray(item.value)) {
                        return await deleteServiceImages([item.value])
                    }
                }
            }))
        }).then(async () => {
            return await delete_blog_post(id)
        }).then(() => {
            setCloseModal(false)
            alert.setalert({ type: 'success', body: 'deleted!', title: 'Done!' })
        }).catch(() => {
            alert.setalert({ type: 'error', body: 'something went wrong, please try again!', title: 'Network Error!' })
        }).finally(() => {
            reloadRecents()
        })
    }

    return (
        <div className='space-y-2'>
            <NavBar last_saved={last_saved} draft={blogDraft} action={pageChange} last_page={page === 1} first_page={page === 0} openPublish={setOpen} id={id} />
            {
                page === 0 ?
                    <SetupBlog id={id} draftID={draftID} deleteBlogPost={setOpenDelete} address={address} setAddress={setAddress} title={title} setupHasError={setupHasError} setTitle={setTitle} category={category} setcategory={setcategory} cover_photo={cover_photo} setCoverPhoto={setCoverPhoto} />
                    : page === 1 ?
                        <EditBlogPost saveDraft={saveDraft} post={post} setPost={setPost} />
                        : null
            }
            <PublishBlogPostDialog
                open={open}
                setOpen={setOpen}
                setLoading={setLoading}
                loading={loading}
                callback={publishPost}
                title={title}
            />

            <DeleteBlogPostDialog
                open={openDelete}
                setOpen={setOpenDelete}
                setLoading={setLoadingDelete}
                loading={loadingDelete}
                callback={deleteBlogPost}
                title={title}
            />

        </div>
    )
}


const NavBar = ({ draft, action, openPublish, last_page, first_page, id, last_saved }) => {

    useEffect(() => { }, [last_saved])

    return (
        <div className='w-full flex items-center justify-between px-5 -mt-5'>
            {!first_page ?
                <div className='flex items-center cursor-pointer text-sm py-1 px-2 font-bold rounded-md text-primary-500' onClick={() => action(-1)}>
                    <ChevronLeftIcon className='guide-icon text-primary-500' />
                    <span>Prev</span>
                </div> : <div />}
            {
                id &&
                <Link to={`/blog/${id}`} className='cursor-pointer text-base font-semibold text-primary-500 hover:underline'>View Post</Link>
            }
            <div className='flex items-center space-x-4'>
                <div className='flex items-center cursor-pointer text-sm py-1 px-2 space-x-2 font-light rounded-md text-gray-500' onClick={() => action('draft')}>
                    {
                        draft.savingDraft &&
                        <img src={Loader} className="w-5 h-5 aspect-square animate-spin" />

                    }
                    <span>{draft.savingDraft ? 'saving...' : last_saved ? `last saved ${moment(last_saved).fromNow()}` : 'Save Draft'}</span>
                </div>
                {
                    !last_page ?
                        <div className='flex items-center cursor-pointer text-sm py-1 px-2 font-bold rounded-md text-primary-500' onClick={() => action(1)}>
                            <span>Next</span>
                            <ChevronRightIcon className='guide-icon text-primary-500' />
                        </div>
                        :
                        <div onClick={() => openPublish(true)} className='py-1 px-2 text-sm cursor-pointer text-white bg-primary-500 rounded-lg flex justify-center items-center font-semibold space-x-2'>
                            <ClipboardCheckIcon className='w-5 h-5 text-white' />
                            <div>{id ? 'Publish update' : 'Publish Post'}</div>
                        </div>
                }

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user?.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog)
