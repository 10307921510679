import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import FormChecker from '../../../../DashboardComponents/FormComponents/FormChecker'
import TipsCard from '../../../../DashboardComponents/FormComponents/TipsCard'

const acceptedCurrency = {
    euro: false,
    lira: false,
    pounds: false,
    usd: false
}


const facility = {
    elevator: false,
    hasAC: false,
    hasCleaners: false,
    hasGym: false,
    hasHeater: false,
    has_wifi: false,
    isDorm: false,
    laundry: false,
    oven: false,
    security: false,
    sharingAllowed: false,
    closeToMarket: false
}

const securityFacilities = {
    cctv_camera: false,
    smoke_detector: false,
    fire_extinguisher: false
}

export const bill = {
    gas: false,
    water: false,
    internet: false,
    electricity: false
}

const FacilitiesForm = ({ housing }) => {
    const [facilities, setFacilities] = useState(null)
    const [securityFac, setSecFacilities] = useState(null)
    const [bills, setBills] = useState(null)
    const [racker, setRack] = useState(null)

    useEffect(() => {

        if (housing?.facilities && !Array.isArray(housing?.facilities) && housing?.facilities.facilities !== null) {
            console.log(housing.facilities? housing.facilities: facilities )
            
            setFacilities(housing.facilities.facilities? housing.facilities.facilities: facilities )
            setSecFacilities(housing.facilities.securityFacilities? housing.facilities.securityFacilities: securityFac )
            setBills(housing.facilities.bills? housing.facilities.bills: bills )

        } else {
            setFacilities(facility)
            setSecFacilities(securityFacilities)
            setBills(bill)
            setRack([])
        }

    }, [])

    useEffect(() => {

        housing.updateFacilities({
            facilities,
            securityFacilities: securityFac,
            bills
        })
    }, [racker])


    return (
        <div className='w-full flex items-start'>
            <div className='py-2 w-full lg:w-[60%] grid grid-cols-1 pb-20 lg:pb-0 md:grid-cols-2'>
                <div>
                    <div className='text-lg font-semibold text-gray-400 py-4'>Facilities</div>
                    <div>
                        {
                            Object.keys(facilities ?? {}).map((i, indx) => {
                                return <Item key={indx} item={i} facilities={facilities} setFacilities={setFacilities} setTmp={setRack} />
                            })
                        }
                    </div>
                </div>
                <div className='space-y-4'>
                    <div>
                        <div className='text-lg font-semibold text-gray-400 py-4'>Safety Facilities</div>
                        <div>
                            {
                                Object.keys(securityFac ?? {}).map((i, indx) => {
                                    return <Item key={indx} item={i} facilities={securityFac} setFacilities={setSecFacilities} setTmp={setRack} />
                                })
                            }
                        </div>
                    </div>

                    <div>
                        <div className='text-lg font-semibold text-gray-400 py-4'>Bills Included In Rent</div>
                        <div>
                            {
                                Object.keys(bills ?? {}).map((i, indx) => {
                                    return <Item key={indx} item={i} facilities={bills} setFacilities={setBills} setTmp={setRack} />
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='hidden lg:block'>
                <TipsCard />
            </div>
        </div>
    )
}

export default FacilitiesForm

const Item = ({ item, facilities, setFacilities, setTmp }) => {
    const [check, setCheck] = useState(false)

    useEffect(() => {
        setCheck(facilities[item])
    }, [])

    useEffect(() => {
        const fac = facilities
        fac[item] = check
        setFacilities(fac)
    }, [check])

    const change = (value) => {
        setCheck(value)
        setTmp({ item: item })
    }

    return (
        <div>
            <FormChecker selected={check} setSelected={change} text={(<div className='capitalize'>{item.split('_').join(' ')}</div>)} />
        </div>
    )
}