import React from 'react'
import { useState, useEffect } from 'react'
import Housing from '../..'
import NumberField from '../../../../../../Components/NumberField'
import { DashboardFormDropDownList } from '../../../../DashboardComponents/DashboardFormFieldDropList'
import { currencies } from '../../../Services/AddServices/Forms/SkillSetsForm/RatesForm'

const PricingForm = ({ housing }) => {

    const [currency, setCurrency] = useState(currencies[0])
    const [rent, setRent] = useState(0)
    const [securityDeposit, setSecurityDeposit] = useState(0)
    const [all, setAll] = useState(null)
    const [num_of_rents, setNumOfRents] = useState(1)


    useEffect(() => {
        if (housing.pricing) {
            if (Array.isArray(housing.pricing)) return

            setCurrency(housing.pricing?.currency)
            setRent(housing.pricing?.rent)
            setSecurityDeposit(housing.pricing?.securityDeposit)
            setNumOfRents(housing.pricing?.num_of_rents)
        }
    }, [])


    useEffect(() => {
        setAll(
            {
                currency,
                rent,
                securityDeposit,
                num_of_rents
            }
        )

    }, [currency, rent, securityDeposit, num_of_rents])

    useEffect(() => {
        housing.updatePricing(all)
    }, [all])


    return (
        <div className='max-w-lg space-y-10 pt-[6vh] pb-20 md:pb-0'>
            <div className='flex flex-col flex-1 '>
                <div className='flex justify-between items-center text-textcolor'>
                    <span className='font-medium text-md '>Prefered Currency</span>
                </div>
                <DashboardFormDropDownList name="Prefered Currency" selectedInterval={currency} setSelectedInterval={setCurrency} list={currencies} />
                <div className='text-xs md:text-sm md:px-5 md:-mt-3'>Currency you wish to rent your apartment in</div>
            </div>

            <div className='flex space-y-4 md:space-y-0 flex-col md:flex-row items-center md:pl-2 px-0 md:space-x-4'>
                <div className='flex w-full flex-col flex-1'>
                    <div className='w-full py-2 px-4 md:my-2 my-5 border-2 border-gray-300 rounded-xl flex bg-white flex-col text-sm text-textcolor'>
                        <div className='flex justify-between items-center text-textcolor'>
                            <span className='font-medium text-md '>Monthly Rent *</span>
                        </div>
                        <div className='w-auto flex justify-between items-start overflow-hidden'>
                            <div className='flex items-center space-x-2'>
                                <span>{currency.symbol}</span>
                                <input onChange={(e) => setRent(e.target.value)} placeholder={`0.00`} value={`${rent}`} className='pt-1 bg-none h-full outline-none w-auto' />
                            </div>
                            <div className='hidden md:block text-sm md:text-base'>per month</div>
                            <div className='md:hidden block text-sm md:text-base'>/mth</div>
                        </div>
                    </div>
                    <div className='md:text-sm md:px-5 -mt-3 text-gray-400 text-xs'>amount to be paid to landlord by tenant for this apartment per month. </div>
                </div>
                <div className='text-textcolor'>
                    <NumberField title="Number Of Rents" activeState={num_of_rents} setActiveState={setNumOfRents} />
                </div>

            </div>

            <div className='flex flex-col flex-1'>
                <div className='w-auto py-2 px-4 m-2 my-5 border-2 border-gray-300 rounded-xl flex bg-white flex-col text-sm text-textcolor'>
                    <div className='flex justify-between items-center text-textcolor'>
                        <span className='font-medium text-md '>Security Deposit *</span>
                    </div>
                    <div className='w-full flex justify-between items-start overflow-hidden'>
                        <div className='flex items-center space-x-2'>
                            <span>{currency.symbol}</span>
                            <input onChange={(e) => setSecurityDeposit(e.target.value)} placeholder={`0.00`} value={`${securityDeposit}`} className='pt-1 bg-none h-full outline-none w-auto' />
                        </div>
                    </div>
                </div>
                <div className='text-sm px-5 -mt-3'>Amount generally should not exceed 150% (${rent * 1.5}) of base rent. </div>
            </div>

        </div>
    )
}

export default PricingForm