import { EyeIcon, OfficeBuildingIcon, PencilIcon } from '@heroicons/react/outline';
import React, { useEffect, useState, useMemo } from 'react'
import DashboardSliderOver from '../../../../DashboardComponents/DashboardSliderOver';
import { normalizeTimetable } from '../../functions';
import EditTimetable from '../../Screens/EditTimetable';
import ViewTimetable from '../../Screens/ViewTimetable';
import NoLectureDayCard from '../NoLectureDayCard';


export const CourseCard = (props) => {
    const { title, venue, time } = props
    return (
        <div className='flex'>
            <div className='w-[30%] border-r-2 py-3 px-2'>
                <div className=''>
                    <div>{time.split('-')[0]}</div>
                    <div>{time.split('-')[1]}</div>
                </div>
            </div>
            <div className='w-[70%] py-3 px-2'>
                <div className=''>
                    <div className='font-semibold'>{title}</div>
                    <div className='flex items-center space-x-2'>
                        <OfficeBuildingIcon className='guide-icon w-5 h-5' />
                        <div>{venue}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const DailyViewTimetable = (props) => {
    const { table } = props
    const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']


    const [daysOfThisWeek, setDaysOfThisWeek] = useState(null)
    const [openViewTT, setOpenViewTT] = useState(false)
    const [showLectures, setShowLectures] = useState(false)
    const [my_courses, setMyCourses] = useState(null)
    const [slideFunc, setSlideFunc] = useState(false)
    const today = new Date();
    const week_day = today.getDay()

    useEffect(() => {
        const dd = String(today.getDate()).padStart(2, '0');
        const dy = WEEKDAYS[week_day]
        console.log(table)
        extrapolateDates(parseInt(dd), parseInt(week_day)).then((res) => setDaysOfThisWeek(res))
    }, [])

    useMemo(() => {
        (async () => {
            const _weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
            const hasLecturesToday = await table?.some(i => i.day.toLowerCase().includes(_weekdays[week_day].toLowerCase()))
            const _todays_day = _weekdays[week_day].toLowerCase()
            const today_courses = await table.filter((course, indx) => course.day.toLowerCase().includes(_todays_day))
            setShowLectures(![0, 6].includes(week_day) && hasLecturesToday)
            normalizeTimetable(today_courses).then((res) => setMyCourses(res))
        })();
    }, [table])

    const openSlider = (func) => {
        setOpenViewTT(true)
        setSlideFunc(func)
    }

    const extrapolateDates = async (date, day) => {
        const today = new Date();
        let dates = []
        let next_count = day
        let prev_count = day


        await WEEKDAYS.map((_, indx) => {
            let nextDay = new Date(today)
            nextDay = nextDay.setDate(nextDay.getDate())
            nextDay = new Date(nextDay)
            let prevDay = new Date(today)


            nextDay = nextDay.setDate((nextDay.getDate() - 1) + next_count)
            nextDay = new Date(nextDay)
            dates[next_count] = String(nextDay.getDate()).padStart(2, '0');
            next_count += 1

            prevDay = prevDay.setDate(prevDay.getDate() - (day - prev_count))
            prevDay = new Date(prevDay)
            dates[prev_count] = String(prevDay.getDate()).padStart(2, '0');
            prev_count -= 1

        })

        return dates

    }

    const CalenderDate = ({ day, date, isToday, index }) => {
        const _weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        const hasLectures = table && table?.some(i => i.day.toLowerCase().includes(_weekdays[index].toLowerCase()))

        return (
            <div className={`w-full flex flex-col justify-center items-center space-y-2 py-2 rounded-xl ${isToday && 'bg-primary-100'}`}>
                <div className='flex-1 flex justify-center items-center uppercase font-bold text-xs lg:text-md'>{day}</div>
                <div className='text-xs lg:text-md'>{date}</div>
                {hasLectures && <div className=' w-1 h-1 bg-red-700 rounded-full'></div>}
            </div>
        )
    }



    const CoursePeriod = (props) => {
        return (
            <div className='p-2 bg-white w-full rounded-xl'>
                <div className='flex'>
                    <div className='w-[30%] border-r-2 border-b-2 px-2'>
                        <div className=''>Time</div>
                    </div>
                    <div className='w-[70%] border-b-2 px-2'>
                        <div className='flex justify-between items-center'>
                            <div>Lecture</div>
                            <div className='flex items-center space-x-4'>
                                <div onClick={() => openSlider('view')} className='p-2 rounded-full hover:bg-primary-300 cursor-pointer'><EyeIcon className='guide-icon w-5 h-5' /></div>
                                <div onClick={() => openSlider('edit')} className='p-2 rounded-full hover:bg-primary-300 cursor-pointer'><PencilIcon className='guide-icon w-5 h-5' /></div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    my_courses?.map((course, indx) => {
                        return <CourseCard key={indx} time={course.time} venue={course.venue} title={course.title} />
                    })
                }
                {/* <CourseCard {...props} /> */}
            </div>
        )
    }



    return daysOfThisWeek && (
        <div className='py-5 px-2 text-left space-y-2 bg-gray-200 rounded-3xl '>
            <div className='flex items-center'>
                {
                    WEEKDAYS.map((day, indx) => {
                        const isToday = indx === week_day
                        return <CalenderDate key={indx} day={day} date={daysOfThisWeek[indx]} isToday={isToday} index={indx} />
                    })
                }

            </div>
            {
                my_courses?.length > 0 ?
                    <div>
                        <CoursePeriod />
                    </div>
                    : <NoLectureDayCard openTimetable={openSlider} />
            }
            {/* <CoursePeriod /> */}
            <DashboardSliderOver open={openViewTT} setOpen={setOpenViewTT} width={2}>
                {
                    slideFunc == 'view' ?
                        <ViewTimetable {...props} my_courses={my_courses} />
                        :
                        <EditTimetable setViewTT={setSlideFunc} />

                }

            </DashboardSliderOver>
        </div>
    )
}





export default DailyViewTimetable