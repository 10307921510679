import {
  DotsVerticalIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/outline";
import React, { useEffect } from "react";
import { useState } from "react";


const PollInputs = ({ title, setTitle, pollAnswer, setPollAnswers }) => {
  
  useEffect(() => {}, [pollAnswer, title]);


  const updateAnswer = (text, id) => {
    const polls = [];
    pollAnswer.map((p, i) => {
      if (id === i) {
        polls.push({
          ...p,
          text: text,
        });
      } else {
        polls.push(p);
      }
    });
    setPollAnswers(polls);
  };

  const updateMessage = (text, id) => {
    const polls = [];
    pollAnswer.map((p, i) => {
      if (id === i) {
        polls.push({
          ...p,
          message: text,
        });
      } else {
        polls.push(p);
      }
    });
    setPollAnswers(polls);
  };

  const removeAnswer = (id) => {
    setPollAnswers(pollAnswer.filter((i) => i.id !== id));
  };

  return (
    <div className="w-full space-y-4 bg-primary-300 h-full rounded-xl mb-10 p-2 md:p-5 min-h-[50px]">
      <div className="w-full">
        <span className="font-bold py-2 text-gray-500">Question</span>
        <div className="w-full p-2">
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-1 px-4 rounded-lg outline-none text-textcolor"
            placeholder="Enter Question"
          />
        </div>
      </div>
      <div className="w-full bg-white rounded-xl p-2 md:p-5 ">
        <span className="font-bold py-2 text-gray-500">Answers</span>
        <div className="w-full p-2 space-y-2 md:space-y-4">
          {pollAnswer.map((answer, indx) => {
            return (
              <PollItem
                key={indx}
                answer={{ ...answer, id: indx }}
                removeAnswer={removeAnswer}
                updateAnswer={updateAnswer}
                updateMessage={updateMessage}
              />
            );
          })}
          <EmptyPollItem setPoll={setPollAnswers} polls={pollAnswer} />
        </div>
      </div>
    </div>
  );
};

export default PollInputs;

const PollItem = ({
  answer,
  updateMessage = () => null,
  removeAnswer = () => null,
  updateAnswer = () => null,
}) => {
  const [_answer, setAnswer] = useState("");
  const [_message, setMessage] = useState("");


  useState(() => {
    setMessage(answer.message);
    setAnswer(answer.text);
  }, []);

  useEffect(() => {
    updateAnswer(_answer, answer.id);
  }, [_answer]);
  useEffect(() => {
    updateMessage(_message, answer.id);
  }, [_message]);

  return (
    <div className="w-full rounded-xl border-2 divide-y-2">
      <div className="flex w-full divide-x-2 p-0 justify-between items-center text-gray-500">
        <div className="flex h-10 items-center  px-2 w-16 md:w-[10%] space-x-2">
          <DotsVerticalIcon className="w-4 h-4" />
          <div className="w-7 h-7 text-sm bg-primary-500 rounded-full flex justify-center items-center text-white font-medium">
            {answer.id}
          </div>
        </div>
        <div className="flex h-10 items-center px-2  w-[80%]">
          <input
            value={_answer}
            className="w-full outline-none text-textcolor"
            placeholder="Enter answer here"
            onChange={(e) => setAnswer(e.target.value, answer.id)}
          />
        </div>
        <div className="h-10 items-center px-2 w-16 md:w-[10%] flex justify-center">
          <TrashIcon
            onClick={() => removeAnswer(answer.id + 1)}
            className="w-5 h-5 cursor-pointer hover:text-red-400 "
          />
        </div>
      </div>
      <div className="w-full min-h-24 md:h-16">
        <textarea
          value={answer.message}
          onChange={(e) => setMessage(e.target.value, answer.id)}
          className="w-full text-sm md:text-base h-full p-2 md:p-4 placeholder-gray-400/70 text-textcolor outline-none rounded-xl"
          placeholder="Do you want to respond when when a user answers this poll?, perharps with a thank you message, if not leave this blank."
        />
      </div>
    </div>
  );
};

const EmptyPollItem = ({ setPoll, polls }) => {
  return (
    <div
      onClick={() => setPoll([...polls, { id: polls.length + 1 }])}
      className="flex w-full rounded-xl border-2 border-dashed text-textcolor bg-gray-100 hover:bg-gray-200 cursor-pointer space-x-2 font-bold p-2 md:p-4 justify-center items-center"
    >
      <PlusIcon className="w-5 h-4 text-primary-500 stroke-2" />
      <div>Add Question</div>
    </div>
  );
};
