import React, { useEffect, useMemo, useState } from "react";
import {
  TemplateIcon,
  MenuAlt2Icon,
  AnnotationIcon,
  ColorSwatchIcon,
  SparklesIcon,
  ClipboardListIcon,
  BadgeCheckIcon,
  LogoutIcon,
  TableIcon,
  ChatIcon,
  OfficeBuildingIcon,
  InboxInIcon,
  HandIcon,
  ChevronUpIcon,
  ArrowRightIcon,
} from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../Components/UtilityComponents/AuthProvider";
import AccountType, {
  useAccounts,
} from "../../../../Components/UtilityComponents/AccountType";
import { AccountTypes } from "../../Screens/SelectAccountTypePage/accounts";
import { ChevronDownIcon } from "@heroicons/react/solid";

const DashLink = ({
  to,
  className,
  activeClassName = "",
  inactiveClassName = "",
  active = false,
  children,
  ...rest
}) => {
  let location = useLocation();
  let isActive = location.pathname.includes(to) || active;

  let allClasses =
    className + (isActive ? ` ${activeClassName}` : ` ${inactiveClassName}`);
  return (
    <Link className={allClasses} to={to} {...rest}>
      {children}
    </Link>
  );
};

const sideBarData = [
  {
    title: "Dashboard",
    icon: TemplateIcon,
    to: "main",
  },
  {
    title: "TimeTable",
    icon: TableIcon,
    to: "timetable",
  },
  {
    title: "Job Openings",
    icon: ColorSwatchIcon,
    to: "jobs",
  },
  {
    title: "Business",
    icon: SparklesIcon,
    to: "business",
  },
  {
    title: "Services",
    icon: ClipboardListIcon,
    to: "services",
    submenu: {
      title: "Services",
      menus: [
        {
          title: "Jobs",
          icon: InboxInIcon,
          to: "jobs",
          submenus: [
            {
              title: "Jobs",
              icon: InboxInIcon,
              to: "jobs",
            },
            {
              title: "Another",
              icon: HandIcon,
              to: "other",
            },
          ],
        },
        {
          title: "Another",
          icon: HandIcon,
          to: "other",
        },
      ],
    },
  },
  {
    title: "Log Out",
    icon: LogoutIcon,
    to: "logout",
  },
  {
    title: "Housing",
    icon: OfficeBuildingIcon,
    to: "housing",
  },
];




export default function Sidebar(props) {
  let location = useLocation();

  const { user, business } = props;
  const [openSideBar, setOpenSideBar] = useState(false);
  const [menus, setMenus] = useState([]);
  const [active_menus, setActiveMenus] = useState(location.pathname.split("/")[2]);
  const [userGroups, setUserGroups] = useState(null);
  const accounts = useAccounts();

  const activeAccnts = [
    AccountTypes.HOUSING_AGENT,
    AccountTypes.SERVICE_PROVIDER,
    AccountTypes.STUDENT,
    AccountTypes.BUSINESS_OWNER,
  ];

  let business_plugins = null




  const loadmenus = async (userGroups, setMenus) => {

    // Add Plugin Nav to Menu
    const bID = userGroups.filter(i => i.type === AccountTypes.BUSINESS_OWNER)[0].id
    try {
      if (business.plugin) {
        business_plugins = {
          title: "Plugins",
          menus: business.plugin.filter(p => p.active).map((plugin) => {
            return ({
              title: plugin['pretty-name'],
              to: `business/${bID}/plugin/${plugin.name}`,
              submenus: Object.keys(plugin.resources['business-end']?.pages).map((submenu, i) => {
                return {
                  title: submenu,
                  to: `business/${bID}/plugin/${plugin.name}?page=${plugin.resources['business-end'].pages[submenu]}`,
                }
              })
            })
          })
        }
      }
    } catch (e) {
      console.error(`Error: ${e}`)
    }


    const tmp = [];
    await userGroups?.forEach((group, i) => {
      if (activeAccnts.includes(group.type)) {
        switch (group.type) {
          case AccountTypes.STUDENT:
            if (!tmp.some((i) => i.title === sideBarData[1].title)) {
              tmp.push(sideBarData[1]);
            }
            return;
          case AccountTypes.SERVICE_PROVIDER:
            if (!tmp.some((i) => i.title === sideBarData[4].title)) {
              tmp.push(sideBarData[4]);
            }
            return;
          case AccountTypes.BUSINESS_OWNER:
            if (!tmp.some((i) => i.title === sideBarData[3].title)) {
              sideBarData[3] = { ...sideBarData[3], submenu: business_plugins }
              tmp.push(sideBarData[3]);
            }
            return;
          case AccountTypes.HOUSING_AGENT:
            if (!tmp.some((i) => i.title === sideBarData[6].title)) {
              tmp.push(sideBarData[6]);
            }
            return;
          default:
            console.log(group.type);
        }
      }
    });
    return tmp;
  };

  useEffect(() => {
    loadmenus(user?.groupIDs, setMenus).then((menuList) => {
      setMenus([...menuList, sideBarData[5]]);
    });

    return () => {
      setMenus([]);
    };
  }, [user?.groupIDs, business]);

  return (
    <div
      className={`transform absolute ${openSideBar ? "w-80" : "w-20"
        } transition-all duration-500 ease-in-out bg-primary-300 dark:bg-dark-300 inset-y-0 m-0 flex flex-col text-gray-400 -translate-x-full md:-translate-x-0 md:relative`}
    >
      <div className="bg-primary-800 h-16 flex px-5 justify-start items-center">
        <span onClick={() => setOpenSideBar(!openSideBar)}>
          <MenuAlt2Icon className="guide-icon text-white" />
        </span>
      </div>
      <div className="flex flex-1 flex-row">
        <div
          className={`flex flex-col h-full ${openSideBar && "border-r-[0.5px] border-gray-300"
            } px-5`}
        >
          {menus.map((item, indx) => {
            return (
              <SidebarIcon
                to={item.to}
                key={indx}
                sidebar_open={openSideBar}
                icon={<item.icon className="guide-icon w-5 h-5" />}
                title={item.title}
                active={
                  location.pathname.includes(item.to) ||
                  item.to === active_menus
                }
                setActiveMenu={setActiveMenus}
              />
            );
          })}
        </div>
        <div className="px-5">
          {active_menus && (
            <Transition
              show={openSideBar}
              enter="delay-300 transition transform duration-300 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition transform duration-100 delay-100 ease-in"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="flex flex-col space-y-1 mt-5">
                <div className=" w-60  text-left capitalize text-lg text-black font-bold mb-5">
                  {active_menus}
                </div>
                {menus
                  .filter((i) => i.to === active_menus)
                  .map((item) => {
                    return item.submenu?.menus?.map((sm) => {
                      return <DropSubMenu openSideBar={openSideBar} sm={sm} />;
                    });
                  })}
              </div>
            </Transition>
          )}
        </div>
      </div>
      <SidebarUser user={user} sidebar_open={openSideBar} />
    </div>
  );
}

const DropSubMenu = ({ sm, openSideBar }) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);

  return (
    <div className="w-[12rem]">
      <div className="flex justify-between items-center">
        <DashLink
          to={sm.to}
          activeClassName={`text-primary-500 font-bold`}
          className="flex py-2 flex-row justify-start items-center space-x-4 "
        >
          {sm.icon && <sm.icon className="w-6 h-5" />}
          <span className="text-left">{sm.title}</span>
        </DashLink>
        {sm.submenus && (
          <div
            onClick={() => setOpenSubMenu(!openSubMenu)}
            className=" cursor-pointer "
          >
            {openSubMenu ? (
              <ChevronUpIcon className="w-5 h-5" />
            ) : (
              <ChevronDownIcon className="w-5 h-5" />
            )}
          </div>
        )}
      </div>

      {openSubMenu && sm.submenus && (
        <div>
          <div className=" border-l-2 space-y-2 border-gray-300 ml-3 pl-10">
            {sm.submenus.map((menu) => {
              return (
                <Link to={menu.to} className="flex cursor-pointer items-center justify-start space-x-4">
                  {menu.icon && <menu.icon className="w-6 h-5" />}
                  <span>{menu.title}</span>
                </Link>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export const BottomBar = ({ user }) => {
  const [openSideBar, setOpenSideBar] = useState(false);
  const [menus, setMenus] = useState([]);
  const [userGroups, setUserGroups] = useState(null);
  const accounts = useAccounts();

  const loadmenus = async (userGroups, setMenus) => {
    const activeAccnts = [
      AccountTypes.HOUSING_AGENT,
      AccountTypes.SERVICE_PROVIDER,
      AccountTypes.STUDENT,
      AccountTypes.BUSINESS_OWNER,
    ];
    const tmp = [];
    await userGroups?.forEach((group, i) => {
      if (activeAccnts.includes(group.type)) {
        switch (group.type) {
          case AccountTypes.STUDENT:
            if (!tmp.some((i) => i.title === sideBarData[1].title)) {
              tmp.push(sideBarData[1]);
            }
            return;
          case AccountTypes.SERVICE_PROVIDER:
            if (!tmp.some((i) => i.title === sideBarData[4].title)) {
              tmp.push(sideBarData[4]);
            }
            return;
          case AccountTypes.BUSINESS_OWNER:
            if (!tmp.some((i) => i.title === sideBarData[3].title)) {
              tmp.push(sideBarData[3]);
            }
            return;
          case AccountTypes.HOUSING_AGENT:
            if (!tmp.some((i) => i.title === sideBarData[6].title)) {
              tmp.push(sideBarData[6]);
            }
            return;
          default:
            console.log(group.type);
        }
      }
    });
    return tmp;
  };

  useEffect(() => {
    loadmenus(user?.groupIDs, setMenus).then((menuList) => {
      setMenus([...menuList, sideBarData[5]]);
    });

    return () => {
      setMenus([]);
    };
  }, [user?.groupIDs]);

  const [open, setOpen] = React.useState(false);
  return (
    <div
      className={` z-10 transform transition-all duration-500 ease-in-out bg-primary-800 h-fit
        fixed bottom-0 md:translate-y-full md:hidden inset-x-0 ${open && "rounded-t-3xl"
        } `}
    >
      <Transition
        show={open}
        enter="delay-300 transition transform duration-300 ease-out"
        enterFrom="h-0 opacity-0"
        enterTo="h-fit opcaity-100"
        className={`grid grid-cols-3 space-y-2 pt-10 `}
      >
        {menus.map((item, indx) => {
          return (
            <SideBarSmallIcon
              key={indx}
              to={item.to}
              icon={<item.icon className="guide-icon" />}
              title={item.title}
            />
          );
        })}
      </Transition>
      <div className="flex w-full h-14  items-center px-5 justify-between">
        <div onClick={() => setOpen(!open)}>
          <MenuAlt2Icon className="guide-icon text-white" />
        </div>
        <div className="flex items-center">
          <div className="w-11 h-11 p-2 rounded-xl bg-primary-100 relative">
            <img
              src={user?.photo_url}
              alt=""
              className="w-fit  h-fit object-cover rounded-xl aspect-square "
            />
            <span
              className={`block h-5 w-5 text-textcolor bg-white rounded-md absolute bottom-0 right-0`}
            >
              <BadgeCheckIcon className="w-5 h-5 " />
            </span>
          </div>
          <div className="hidden flex-col items-start mx-2 text-light_textcolor text-md sm:flex">
            <span className="font-bold text-left">{user?.display_name}</span>
            <span className="font-light text-sm">Owner</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const SidebarIcon = ({
  to,
  icon,
  title,
  active = false,
  sidebar_open,
  setActiveMenu,
}) => {
  const auth = useAuth();
  return (
    <div className="w-full flex flex-row items-center justify-start">
      <DashLink
        to={to !== "logout" && to}
        onClick={() => {
          setActiveMenu(to);
          to === "logout" && auth.signout(true);
        }}
        className={`${active ? "sidebar_icon_active" : "sidebar_icon_inactive"
          }`}
        activeClassName={`${active
          ? "sidebar_icon_active bg-primary-500 text-white"
          : "sidebar_icon_inactive bg-primary-500 text-white"
          }`}
        active={active}
      >
        <span className="flex justify-center items-center">{icon}</span>
      </DashLink>
    </div>
  );
};

const SideBarSmallIcon = ({ icon, title, to }) => {
  const auth = useAuth();

  return (
    <DashLink
      onClick={() => to === "logout" && auth.signout(true)}
      to={to !== "logout" && to}
      className="mx-5"
      activeClassName="rounded-xl bg-primary-500"
    >
      <div className="mx-auto w-16 h-16 flex flex-col rounded-xl justify-center items-center">
        <span className="text-primary-100">{icon}</span>
      </div>
      <span className="text-xs text-primary-100 font-500">{title}</span>
    </DashLink>
  );
};

const SidebarUser = ({ sidebar_open, user }) => {
  return (
    <div
      className={`${sidebar_open ? "w-[90%]" : "w-[80%]"} mx-auto rounded-xl flex items-center bg-white p-2 mb-5 mt-5 relative`}
    >
      <div className={`flex h-12 w-12 relative`}>
        <img
          src={user?.photo_url}
          alt=""
          className="w-fit  object-cover h-fit rounded-xl aspect-square "
        />
        <span
          className={`block h-5 w-5 text-textcolor bg-white rounded-md absolute bottom-0 right-0  ${sidebar_open ? "md:hidden" : "md:block"
            } `}
        >
          <BadgeCheckIcon className="w-5 h-5 " />
        </span>
      </div>
      <Transition
        show={sidebar_open}
        enter="delay-200 transition transform duration-300 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition transform duration-100 delay-0"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={`px-2 flex flex-col text-dark_textcolor `}
      >
        <span className="font-bold text-sm text-left">{user?.display_name}</span>
        <div className="text-light_textcolor text-sm flex flex-row space-x-2">
          <span>Owner</span>
          <span className="hidden md:block h-5 w-5 text-textcolor bg-white rounded-md">
            <BadgeCheckIcon className="w-5 h-5" />
          </span>
        </div>
      </Transition>
    </div>
  );
};
