import { CheckIcon } from '@heroicons/react/outline'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { useServiceForm } from '../../../../../../../Components/UtilityComponents/ServiceFormContext'
import { pages } from '../../../../Main/SubScreens/Settings/Forms/data'
import draftImage from '../../../../../../../assets/carry.svg'
import PreviewServiceProfile from '../../PreviewServiceProfile'
import { connect } from 'react-redux'



const DraftForm = ({current_user}) => {

    const services = useServiceForm()

    const service_id = current_user.user?.groupIDs.filter(i => i.type === "service_provider")[0]?.id
    
    const [loading, setLoading] = useState(false)

    useEffect(() => {

    }, [loading])

    return !loading ? (
        <div className='w-full h-full relative'>
            <div className='flex items-center'>
                <div className='flex flex-col h-auto pt-10 pl-5 space-y-5 items-start justify-start w-full'>
                    {
                        pages.map((page, index) => {
                            return <DraftItem key={index} page={page} services={services} />
                        })
                    }
                </div>
                <img src={draftImage} alt="guide draft image" className="hidden fixed lg:flex w-[30%] right-[3vw] top-[28vh] mt-10 h-auto" />
            </div>
            <PreviewServiceProfile services={services} loading={loading} setLoading={setLoading} service_id={service_id} />
            <div className='flex sm:hidden w-full h-[200px]' />
        </div>
    ) : (
        <div className='w-full  h-[70vh] flex justify-center items-center'>
            <div>
                <img src="https://storage.googleapis.com/iframe_content/first%20time%20buyers/first-time-buyer-report_dashboard_optimised/images/loader.gif"
                className='w-20 h-20' />
                <span className='text-gray-400'>publishing...</span>
            </div>
        </div>
    )
}

const DraftItem = ({ page, services }) => {
    const { stored_services } = services

    let [not_edited, setNotEdited] = useState(false)
    console.log(stored_services[page.title],page.title)

    useEffect(() => {
        let data = stored_services ? stored_services[page.title]:[]
        if (Array.isArray(data) && data.length <= 0) {
            setNotEdited(true)
        }

        if (page.title === 'description' && data === '') {
            setNotEdited(true)
        }
    }, [stored_services])




    return (
        <div onClick={() => services.goToPage(page)} className=' cursor-pointer flex space-x-4 items-start '>
            <div>
                {
                    not_edited ?
                        <ExclamationCircleIcon className='guide-icon w-6 h-6 text-primary-500' />
                        :
                        <CheckIcon className='guide-icon w-6 h-6 text-primary-500' />
                }
            </div>
            <div >
                <div className='capitalize font-semibold underline'>{page.title}</div>
                {not_edited && <div className='p-3 hover:bg-primary-800 bg-primary-500 rounded-lg text-white text-xs m-4'>add {page.title} </div>}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      current_user: state.user?.data
    }
  }
  
  
  export default connect(mapStateToProps, null)(DraftForm)