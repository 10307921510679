import React from 'react'
import { useContext } from 'react'
import { createContext } from 'react'
import { connect } from 'react-redux'
import { add_apartments, delete_apartments, update_apartment } from '../../../functions/housing_functions/index.js'
import { deleteServiceImages, getCoordHash, uploadServiceImages } from '../../../functions/service_functions.js'
import { Housingpages } from '../../../Pages/Dashboard/Screens/Main/SubScreens/Settings/Forms/data'
import { store_housing } from '../../../store/actions/housingActions'
import { _storage } from '../../../utils'
import { useHousing } from '../HousingContext/index.js'


const { firebase } = _storage

const acceptedCurrency = {
    euro: false,
    usd: false,
    pounds: false,
    lira: false
}

const page = {
    id: 0,
    title: 'draft',
    progress: 0,
    pageTitle: 'Finish your listing to start getting clients',
    pageDescription: 'you can always edit your listing after you publish it. Advertise your listing after Publish to get it in front of your tenants.'
}
const pages = Housingpages
const init = {
    id: null,
    last_saved: null,
    location: null,
    images: null,
    currentPage: null,
    facilities: null,
    stored_housing: null,
    living_space: null,
    pricing: null,
    title: null,
    requirements: null,
    description: null,
    review: null,
    changedDoc: null,
    groupId: null,
    advert_status: null,
    tour: null,
    acceptedAgreement: null,
    setId: () => null,
    goToNextPage: () => null,
    goToPrevPage: () => null,
    goToDraft: () => null,
    goToPage: () => null,
    updateFacilities: () => null,
    updateLocation: () => null,
    updatelastSaved: () => null,
    updateImages: () => null,
    clearHousing: () => null,
    updateLivingSpace: () => null,
    updateTitle: () => null,
    updatePricing: () => null,
    updateRequirements: () => null,
    updateDescription: () => null,
    updateReview: () => null,
    publishApartment: () => null,
    loadApartmentForEdit: () => null,
    deleteApartment: () => null,
    saveProgress: () => null,
    setGroupID: () => null,
    setAdvertStatus: () => null,
    updateTour: () => null,
    setAcceptedAgreement: () => null
}

const HousingFormContext = createContext(init)


const HousingForm = ({ children, stored_housing, user, draftHousing }) => {
    let [currentPage, setCurrentPage] = React.useState(stored_housing?.currentPage ? stored_housing.currentPage : pages[0]);
    let [last_saved, setLastSaved] = React.useState(stored_housing?.last_saved ? stored_housing.last_saved : new Date());
    let [location, setLocation] = React.useState(stored_housing?.location ? stored_housing.location : []);
    let [images, setImages] = React.useState(stored_housing?.images ? stored_housing.images : []);
    let [facilities, setFacilities] = React.useState(stored_housing?.facilities ? stored_housing.facilities : []);
    let [id, setId] = React.useState(stored_housing?.id ? stored_housing.id : null);
    let [living_space, setLivingSpace] = React.useState(stored_housing?.living_space ?? [])
    let [pricing, setPricing] = React.useState(stored_housing?.pricing ?? [])
    let [title, setTitle] = React.useState(stored_housing?.title ?? [])
    let [requirements, setRequirements] = React.useState(stored_housing?.requirements ?? [])
    let [description, setDescription] = React.useState(stored_housing?.description ?? [])
    let [review, setReview] = React.useState(stored_housing?.review ?? [])
    let [changedDoc, setChangedDoc] = React.useState(null)
    let [groupId, setGroupID] = React.useState(null)
    let [advert_status, setAdvertStatus] = React.useState(stored_housing?.advert_status ?? null)
    let [tour, setTour] = React.useState(stored_housing?.tour ?? [])
    let [acceptedAgreement, setAcceptedAgreement] = React.useState(stored_housing?.review.length > 0 ? stored_housing.review[0]?.agreed : false);

    const updateTitle = (value) => setTitle(value)
    const updatePricing = (value) => setPricing(value)
    const updateLocation = (value) => setLocation(value)
    const updatelastSaved = async (value) => setLastSaved(value)
    const updateImages = (value) => setImages(value)
    const updateDescription = (value) => setDescription(value)
    const updateReview = (value) => setReview(value)
    const updateRequirements = (value) => setRequirements(value)
    const updateLivingSpace = (value) => setLivingSpace(value)
    const updateFacilities = (value) => setFacilities(value)
    const updateTour = (value) => setTour(value)

    const houses = useHousing()

    const goToNextPage = async (pageIndex) => {
        if (pageIndex < pages.length) {

            await saveProgress()
            setCurrentPage(pages[pageIndex])
        }
    }

    const goToPrevPage = async (pageIndex) => {
        if (pageIndex >= 0) {

            await saveProgress()
            setCurrentPage(pages[pageIndex])
        }
    }

    const loadApartmentForEdit = async (data) => {

        setId(data.id)
        setAdvertStatus(data.advert_status)
        updateTitle(data.title)
        updatePricing(data.pricing)
        updateLocation(data.location)
        updatelastSaved(data.last_saved)
        updateImages(data.images)
        updateDescription(data.description)
        updateReview(data.review)
        updateRequirements(data.requirements)
        updateLivingSpace(data.living_space)
        updateFacilities(data.facilities)
        updateTour(data.tour)
        draftHousing(data)
        return 'ok'
    }

    const goToDraft = async () => {
        saveProgress().then(async () => {
            updatelastSaved(new Date()).then(() => {
                setCurrentPage(page)
            })
        })
    }

    const goToPage = async (page) => {
        setCurrentPage(page)
    }

    const deleteApartment = async () => {

        if (id !== null && stored_housing.id) {
            return await deleteServiceImages(images).then(() => {
                return delete_apartments(id).then((res) => {
                    clearHousing()
                    setChangedDoc(id)
                    houses.loadUp()
                    return { type: 'success', title: 'deleted!', body: 'apartment deleted successfully' }
                })
            })
        } else {
            clearHousing()
        }
    }


    const publishApartment = async (groupID) => {
        const path = new Date().toISOString()


        return await saveProgress().then(async (saved) => {
            const cur = saved.pricing?.currency?.value === 'pound' ? 'pounds' : saved.pricing?.currency?.value
            acceptedCurrency[cur] = true
            const feature = {
                acceptedCurrency,
                minsToBusttop: saved.location.mins_to_busstop,
                rents: saved.pricing.num_of_rents,
                ...saved.facilities.facilities,

            }

            const coord = {
                name: saved.location?.address,
                position: {
                    lon: saved.location?.latlon.lat,
                    lat: saved.location?.latlon.lng
                }
            }

            const address = {
                address_string: saved.location?.address,
                area: saved.location?.address.split(',')[1],
                coord: {
                    latitude: saved.location?.latlon.lat,
                    longitude: saved.location?.latlon.lng
                }
            }

            const contact = {
                phone: user?.user?.phone_number
            }

            const parent_category_id = [
                {
                    id: 'housing',
                    title: {
                        translation: {
                            en_us: "Housing"
                        }
                    },
                    sub_categories: [
                        {
                            id: 'apartments',
                            title: {
                                translation: {
                                    en_us: "Apartments"
                                }
                            },
                            icon: "company-building"
                        }
                    ]
                }
            ]

            const price = saved.pricing?.rent

            const title = {
                translations: {
                    en_us: saved.title
                }
            }

            const _user = {
                uid: user?.user.uid,
                display_name: user?.user.display_name,
                photoURL: user?.user.photo_url,
                email: user?.user.email,
                phone: user?.user.phone_number
            }


            if (contact.phone?.length <= 0) {
                return { type: 'error', title: 'No Contact Information', body: 'setup your \"Phone Number\" in the \"Profile Settings\", and make sure this number has an active whatsapp account. ' }
            }
            const beds = saved.living_space.bedrooms
            const livinspace = [
                `${beds}+${saved.living_space.bathrooms}`
            ]

            saved['currentPage'] = null


            const data = {
                id: saved.id,
                groupID,
                features: feature,
                address: address,
                contact: contact,
                price: parseFloat(price),
                parent_category_id: parent_category_id,
                sub_category_id: parent_category_id[0].sub_categories,
                description: { translations: { en_us: saved.description } },
                services: [],
                title,
                public_opinion: [],
                living_space: livinspace,
                meta: [],
                user: {
                    uid: user?.user?.uid,
                    stats: user?.user.attributes?.apartment_stats ?? {
                        available_apartments: 0,
                        rented_apartments: 0,
                        pending_apartments: 0
                    }
                },
                new_doc: {
                    ...saved,
                    gid: groupId,
                    user: _user
                }
            }

            return await uploadServiceImages(saved.images, path).then(async (images) => {
                return await getCoordHash(coord).then(_pos => {
                    console.log(_pos,images)
                    data['new_doc']['images'] = images
                    data['images'] = images
                    data['pos'] = _pos.position
                    if (data.id !== null && data.id !== undefined) {
                        data['method'] = 'all'
                        return update_apartment(data).then((res) => {
                            setChangedDoc(data.id)
                            houses.loadUp()
                            return { type: 'success', title: 'Apartment Updated', body: "Your apartment has been updated successfully!" }
                        })
                    } else {
                        return add_apartments(data).then((res) => {
                            setChangedDoc('new')
                            setId(res.id)
                            houses.loadUp()
                            return { type: 'success', title: 'Apartment Published', body: "Your apartment has been published" }
                        })
                    }
                })
            })
        })
    }

    const saveProgress = async () => {
        const data = {
            id,
            location,
            images,
            facilities,
            tour,
            living_space,
            pricing,
            title: title,
            currentPage: pages[0],
            requirements,
            description,
            review,
            last_saved
        }
        draftHousing(data)
        return data
    }

    const clearHousing = async () => {
        const data = {
            id: null,
            location: [],
            images: [],
            tour: [],
            facilities: [],
            living_space: [],
            pricing: [],
            title: [],
            currentPage: pages[0],
            requirements: [],
            description: [],
            review: [],
            last_saved: null
        }
        await loadApartmentForEdit(data).then(() => {
            draftHousing(data)
            return data
        })
    }

    const value = {
        id,
        last_saved,
        location,
        images,
        currentPage,
        stored_housing,
        facilities,
        tour,
        living_space,
        pricing,
        title,
        currentPage,
        requirements,
        description,
        review,
        last_saved,
        changedDoc,
        groupId,
        advert_status,
        acceptedAgreement,
        setId,
        goToNextPage,
        goToPrevPage,
        goToDraft,
        goToPage,
        clearHousing,
        updateLocation,
        updatelastSaved,
        updateImages,
        updateFacilities,
        updateLivingSpace,
        updateTitle,
        updatePricing,
        saveProgress,
        updateRequirements,
        updateDescription,
        publishApartment,
        updateReview,
        loadApartmentForEdit,
        deleteApartment,
        setGroupID,
        setAdvertStatus,
        setAcceptedAgreement,
        updateTour
    }
    return (
        <HousingFormContext.Provider value={value}>
            {children}
        </HousingFormContext.Provider>
    )
}

export const useHousingForm = () => useContext(HousingFormContext)

const mapStateToProps = (state) => {
    return {
        stored_housing: state.housing.payload,
        user: state.user?.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        draftHousing: (data) => dispatch(store_housing(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HousingForm)