/**
* Use the CSS tab above to style your Element's container.
*/
import React from 'react';
import { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useEffect } from 'react';
import { create_payment_intent } from '../../functions/payment';
import { useAlert } from '../UtilityComponents/ErrorContext';



const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
function SCardSection({ load_stripe, currency, amount, reference, callback }) {

  const stripe = useStripe();
  const elements = useElements();
  const alert = useAlert()


  const [stripeState, setStripe] = useState(null)
  const [cardHolderName, setCardHolderName] = useState('')
  useEffect(() => setStripe(load_stripe()), [])

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const price = parseInt(amount * 100)
    create_payment_intent({
      account_id: "acct_1MPvMzRkKrHJRvgJ",
      charges: (price * .08),
      amount: price,
      currency: currency.title.toLowerCase()
    }).then(async secret => {
      console.warn(secret)
      const { client_secret } = secret
      const result = await stripe.confirmCardPayment(client_secret.trim(), {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: cardHolderName,
          },
        }
      });

      if (result.error) {
        // Show error to your customer (for example, insufficient funds)
        alert.setalert({
          type:"error",
          title:"payment failed",
          body: result.error.message
        })
        console.log(result.error.message);
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          callback(client_secret, "success").then(() => {
            alert.setalert({
              type: 'success',
              title: "success",
              body: "payment successfull!"
            })
          })
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
        }
      }
    }).catch(() => {
      alert.setalert({
        type:"error",
        title:"payment failed",
        body: "failure connecting to payment server"
      })
    })

  };


  return (
    <form onSubmit={handleSubmit} className='max-w-lg w-full bg-white p-5 rounded-md space-y-2 flex flex-col shadow-sm '>
      {/* <CheckoutForm /> */}
      <label>
        Card details
      </label>
      <input onChange={(e) => setCardHolderName(e.target.value)} type="text" className="p-2 rounded-md bg-gray-200 outline-none" placeholder='Card Holder Name' />
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <button className='w-full py-1 mt-1 bg-primary-500 rounded-lg text-white' disabled={!stripe}>Submit</button>
    </form>
  )
};
export default SCardSection;