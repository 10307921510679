import { get_fetch } from "../Pages/Dashboard/Screens/Timetable/functions";

export const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const matchPassword = (password, setMatchPass) => {
    const password_regex_numbers = new RegExp("^(?=.*[0-9])");
    const password_regex_small_cap = new RegExp("(?=.*[a-z])");
    const password_regex_big_cap = new RegExp("(?=.*[A-Z])");
    const password_regex_symb = new RegExp("(?=.*[@#$%^&+=_.])")
    const password_regex_range = new RegExp("(?=\\S+).{8,}")
    setMatchPass({
        password_regex_numbers: password_regex_numbers.test(password),
        password_regex_small_cap: password_regex_small_cap.test(password),
        password_regex_big_cap: password_regex_big_cap.test(password),
        password_regex_symb: password_regex_symb.test(password),
        password_regex_range: password_regex_range.test(password)
    })
}

export const mInfo = (m) => {
    switch (m) {
        case 'password_regex_numbers':
            return 'must contain atleast 1 number'
        case 'password_regex_small_cap':
            return 'must contain lowercase letters'
        case 'password_regex_big_cap':
            return 'must contain uppercase letters'
        case 'password_regex_symb':
            return 'must contain a symbol eg [@,_,-,.,%,^,&,+,=,#] '
        case 'password_regex_range':
            return 'must be greater than 8 characters'
    }
}

export function timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    return {
        days,
        hours,
        minutes
    }
}


export function randomString(len) {
    return [...Array(5)].map((value) => (Math.random() * 1000000).toString(len).replace('.', '')).join('');
}

function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

function flipCase(str) {
    var flip = '';
    for (var i = 0; i < str.length; i++) {
        if (!isNumeric(str)) {
            if (Math.random() > .5) {

                flip += str.charAt(i).toUpperCase();
            } else {
                flip += str.charAt(i).toLowerCase();
            }
        } else {
            flip += str
        }
    }
    return flip;
}

export const getUiniqueID = (length = 20) => {
    const start = getRandomArbitrary(0, 25)
    const randStr = randomString(36)
    return flipCase(randStr.substring(start, start + length))
}


export const AdvertTypes = {
    APARTMENT_ADS: "APARTMENT_ADS"
}

export const AdvertStatus = {
    active: 'active',
    inactive: 'inactive',
    paused: 'paused',
    expired: 'expired'
}

export const guide_seller_profile = () => {
    return {
        uid: 'guide_trcfdhh6hgd7s7l-gt',
        display_name: 'GUIDE',
        photoURL: "https://www.guideutility.co/static/media/logo.ca4f304df6b2b64c6b0e89a0a5aeb577.svg"
    }
}

export const load_test_impressions = (data) => {
    return get_fetch(`/api/account/data/impressions?account_id=${data.id}&start=${data.start}&end=${data.end}`)
}
