import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import React, { createContext, useEffect, useState } from 'react';
import { ReactComponent as ChatBot } from '../../assets/chatbot.svg';
import useSound from 'use-sound'
import PopIn from '../../assets/popsound.wav'
import PopOut from '../../assets/popsound.mp3'


let msgs = [
    {
        text: 'Hi There Zainab.'
    },
    {
        text: "My Name is Suzi, i'm a bot designed to provide you with help and keep you notified on Guide "
    }
]


const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

const bot = {
    addMessage: (msg) => null,
    deleteMessage: (msg) => null,
    popMessage: () => null
}

const SuziContext = createContext(bot)

const Assistant = ({ children }) => {

    const [messages, setMessages] = useState(null)
    const [showSuzi, setShowSuzi] = useState(false)

    useEffect(() => {
        if (messages && messages.length <= 0) {
            setShowSuzi(false)
        } else {
            setShowSuzi(true)

        }
        if (messages && messages.length > 0) {
            (async () => {
                await delay(10000)
                popMessage()
            })();
        }
        console.log(messages)

    }, [messages])

    let addMessage = async (msg) => {
        if (messages) {
            setMessages([...messages, msg])
        } else {
            setMessages([msg])
        }
    }

    let deleteMessage = async (indx = null) => {
        indx ? messages.pop(indx) : messages.pop()
        if (messages.length <= 0) {
            setShowSuzi(false)
        }
        setMessages(messages)
    }

    let popMessage = () => {
        setMessages([])
    }

    const value = { addMessage, deleteMessage, popMessage }

    return (
        <SuziContext.Provider value={value}>
            {children}
            <Transition
                show={showSuzi}
                enter="ease_transition"
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease_transition'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
                className='fixed z-50 bottom-10 right-10 flex justify-start space-x-4'>
                {messages && <div className=' aspect-square w-20 h-20 p-3 rounded-full bg-[#E2FFF7]'>
                    <ChatBot className='w-full h-full aspect-square' />
                </div>}
                <div className=' w-[70%] sm:w-[400px] flex flex-col justify-end sm:justify-center space-y-2 items-end sm:items-center'>
                    {
                        messages?.map((msg, indx) => {
                            return <ChatBubble key={indx} indx={indx} msg={msg.text} timeout={msg.timeout} remove={deleteMessage} />
                        })
                    }
                </div>
            </Transition>
        </SuziContext.Provider>
    )
}

export const useSuzi = () => React.useContext(SuziContext)

export default Assistant


const ChatBubble = ({ msg, remove, indx, timeout }) => {
    const [show, setShow] = useState(false)

    const [playin] = useSound(
        PopIn,
        { volume: 0.2 }
    )

    const [playout] = useSound(
        PopOut,
        { volume: 0.2 }
    )

    useEffect(() => {
        let fade = setTimeout(() => {
            toggleShow()
        }, 1000)

        return () => clearTimeout(fade)
    }, [msgs])

    useEffect(() => {
        !show ? playin() : playout();
    }, [show])

    const toggleShow = async () => {
        setShow(!show)
    }

    const hideBubble = async () => {
        await setShow(false)
    }

    return (
        <Transition
            as='div'
            className='w-full'
            show={show}
            enter="ease_transition"
            enterFrom="translate-y-4 opacity-0"
            enterTo='translate-y-0 opacity-100'
            leave='ease_transition'
            leaveFrom='translate-y-0 opacity-100'
            leaveTo='-translate-y-4 opacity-0'
        >
            <div className='w-full relative flex items-start py-2 px-4 min-h-fit border-purple-400 border-2 bg-primary-500 shadow-lg rounded-xl '>
                <span className='text-left text-xs md:text-sm text-white'>{msg}</span>
                <span onClick={() => {
                    hideBubble().then(() => {
                        setTimeout(() => {
                            remove(indx)
                        }, 1000)
                    })


                }} className='w-5 h-5 absolute -top-2 p-1 -right-2 bg-red-500 rounded-full text-white'><XIcon className='w-full h-full' /></span>
            </div>
        </Transition>
    )
}