import { CalendarIcon, ChatAlt2Icon, LockOpenIcon, MailIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import DateAndTimePicker from '../../../../../../../../../Components/DateTimePicker'
import { useAuth } from '../../../../../../../../../Components/UtilityComponents/AuthProvider'
import CheckerBox from '../../../../../../../DashboardComponents/CheckerBox'
import Loader from '../../../../../../../../../assets/spinner.svg'
import moment from 'moment'
import { getAllCallScheduleds, sendScheduledVideoCallConfirmationEmail, updateBuisnessUserProfileProgress } from '../../../../../../../../../functions/authFunctions'
import { useBilling } from '../../../../../../../../../Components/UtilityComponents/BillingContext'
import { Stages } from '../UploadID/stages'
import { useAlert } from '../../../../../../../../../Components/UtilityComponents/ErrorContext'


const ScheduleCall = ({ user }) => {

    const [loading, setLoading] = useState(false)
    const [agreed, setAgreed] = useState(false)
    const [time, setTime] = useState(new Date())
    const [callSchedules, setCallSchedules] = useState(null)

    const alert = useAlert()
    const billing = useBilling()
    const endTime = new Date(time)
    endTime.setHours(time?.getHours() + 2 || 0)
    endTime.setMinutes(time?.getMinutes() || 0)

    const notifyParties = () => {
        setLoading(true)
        sendScheduledVideoCallConfirmationEmail({
            user:{
                uid: user.uid,
                name: user.display_name,
                email: user.email
            },
            schedule: time.toISOString(), 
            schedule_end: endTime.toISOString(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            action_link:'#'
        })
        .then(res => {
            console.log(res)
            alert.setalert({ type:'success', title:'Done!', body:`Video call Schedules for ${moment(time).format('MMMM Do YYYY, h:mm A')}`})

            updateBuisnessUserProfileProgress({
                uid: user.uid,
                progress: Stages.ACTIVATED_ACCOUNT,
                eventCalendarLink: res,
                callScheduledTime :time
            }).then(() => billing.setStage(Stages.ACTIVATED_ACCOUNT))
            
        })
        .catch((err) => {
            alert.setalert({ type:'error', title:'Error!', body:`something went wrong, try again.`})
        })
        .finally(() => setLoading(false))
    }


    useEffect(() => {
        getAllCallScheduleds().then(resp => {
            const res = JSON.parse(resp)
            console.log('CALL SCHEDULES === >',res)
            if(!Array.isArray(res)){
                alert.setalert({
                    title:"Failed",
                    body:"Could not load call schedules",
                    type:'error'
                })
                return null
            }
            setCallSchedules(res)
        })
    },[])

    useEffect(() => null,[callSchedules])

    return (
        <div className='flex flex-col justify-center text-center space-y-2'>
            <div className='text-2xl font-bold'>Almost Done!</div>
            <div className='text-gray-500 text-sm'>Hey There, Just one more step to go. To complete your business account opening process and become a bonafide Enterprenuer on Guide, we would like to have a video call with you to validate and activate your new account.</div>
            <div className='w-60% aspect-video py-5 space-y-5'>
                <div className='text-gray-500 text-left px-5 font-medium mb-5 mt-10 text-lg'>So What's Next?</div>

                {/* 

                <div className='flex items-start space-x-4  bg-white shadow-md mx-5 p-5 rounded-xl'>
                    <div className='w-14 h-14 aspect-square rounded-full bg-primary-500 flex justify-center items-center'>
                        <ChatAlt2Icon className='w-7 h-7 text-white' />
                    </div>
                    <div className='flex flex-col text-left text-gray-500'>
                        <span className='font-medium'>Include Your Whatsapp Number</span>
                        <span className='text-xs '>Your email should include your whatsapp number </span>
                    </div>
                </div> */}

                <div className='flex items-start space-x-4  bg-white shadow-md mx-5 p-5 rounded-xl'>
                    <div className='w-14 h-14 aspect-square rounded-full bg-primary-500 flex justify-center items-center'>
                        <CalendarIcon className='w-7 h-7 text-white' />
                    </div>
                    <div className='flex flex-col text-left text-gray-500'>
                        <span className='font-medium'>Choose a convineient time</span>
                        <span className='text-xs '>specify a date and time you will be available for a video chat with us. </span>
                        <div className='pt-3'>
                            <DateAndTimePicker time={time} setTime={setTime} allDates={callSchedules} />
                        </div>
                    </div>
                </div>

                <div className='flex items-start space-x-4  bg-white shadow-md mx-5 p-5 rounded-xl'>
                    <div className='w-14 h-14 aspect-square rounded-full bg-primary-500 flex justify-center items-center'>
                        <MailIcon className='w-7 h-7 text-white' />
                    </div>
                    <div className='flex flex-col text-left text-gray-500'>
                        <span className='font-medium'>Email Confirmation</span>
                        <span className='text-xs '>you will recieve your schedule in an email from Guide, confirming the call time.</span>
                    </div>
                </div>

                <div className='flex items-start space-x-4  bg-white shadow-md mx-5 p-5 rounded-xl'>
                    <div className='w-14 h-14 aspect-square rounded-full bg-primary-500 flex justify-center items-center'>
                        <LockOpenIcon className='w-7 h-7 text-white' />
                    </div>
                    <div className='flex flex-col text-left text-gray-500'>
                        <span className='font-medium'>Account Verified</span>
                        <span className='text-xs '> after you've been checked out, your account will be automatically activated. </span>
                    </div>
                </div>

                <div className='pt-20 space-y-5 px-5'>
                    <div className='flex space-x-4 items-start text-left'>
                        <CheckerBox setValue={setAgreed} value={agreed} />
                        <span>Ok, I understand. I will be available for a video call on <b>{moment(time).format('MMMM Do YYYY, h:mm A')}</b></span>
                    </div>
                    <div onClick={() => !(loading && agreed) && notifyParties()} className={`w-full py-2 flex justify-center text-white ${(loading || !agreed) ? 'bg-gray-300 cursor-not-allowed' : 'bg-primary-500 hover:bg-primary-800'} rounded-md text-center font-bold cursor-pointer `}>
                        {loading ? <img src={Loader} className='w-4 h-4 animate-spin' /> : "Confirm"}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduleCall