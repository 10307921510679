import React, { useEffect, useState } from 'react'
import LocationForm from './Forms/LocationForm'
import DescriptionForm from './Forms/DescriptionForm'
import ImagesForm from './Forms/ImagesForm'
import SkillSetsForm from './Forms/SkillSetsForm'
import { useServiceForm } from '../../../../../Components/UtilityComponents/ServiceFormContext'
import DraftForm from './Forms/DraftForm'
import ReviewForm from './Forms/ReviewForm'
import Vehicle from './Forms/Vehicles'

const AddServices = () => {
    const services = useServiceForm()
    const [page, setPage] = useState('location')

    useEffect(() => {
        setPage(services.currentPage)
    }, [services.currentPage])


    return (
        <div className='h-full w-full'>
            <ActiveForm page={page} services={services} />
        </div>
    )
}

const ActiveForm = ({ page, services }) => {
    switch (page.title) {
        case 'location':
            return <LocationForm services={services} />
        case 'description':
            return <DescriptionForm services={services} />
        case 'images':
            return <ImagesForm services={services} />
        case 'skillsets':
            return <SkillSetsForm services={services} />
        case 'review':
            return <ReviewForm services={services} />
        case 'vehicles':
            return <Vehicle services={services} />
        default:
            return <DraftForm />

    }
}

export default AddServices