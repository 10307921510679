import React from 'react'
import { useServiceForm } from '../../../../../Components/UtilityComponents/ServiceFormContext'
import FormProgressBar from '../FormProgressBar'
import FormPageNavigation from './FormPageNavigation'
import SaveAndExit from './SaveAndExit'
import TitleAndDescription from './TitleAndDescription'

const DashboardFormWrapper = ({ children }, props) => {

    const services = useServiceForm()

    return (
        <div className='w-full h-[100vh] pt-5 px-5 relative'>
            {services.currentPage.id !== 0 && <FormProgressBar progress={services.currentPage.progress} />}
            <div className='w-full  overflow-y-auto px-2 max-h-[90%] scrollbar'>
                <div className='w-full flex flex-col space-y-4 md:spacey-0 md:flex-row justify-between items-start my-5'>
                    <TitleAndDescription services={services} />
                    {services.currentPage.id !== 0 && <SaveAndExit services={services} />}
                </div>
                {children}
            </div>
            <div className='absolute bottom-10 w-full px-10'>
                {services.currentPage.id !== 0 && <FormPageNavigation services={services} />}
            </div>
        </div>
    )
}

export default DashboardFormWrapper