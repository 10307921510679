import React, { useEffect, useState } from 'react'
import FormChecker from '../../../../../../DashboardComponents/FormComponents/FormChecker'

const JobSupplies = ({supplies, selectedSupplies, setSupplies}) => {

    return (
        <div className='px-10 py-5'>
            <div className='py-5 border-b border-[#D6D6FF] px-5 my-2 text-lg font-bold'>What supplies are you bringing to this job</div>
            <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4'>
                {
                    supplies?.map((supply, indx) => {
                        return <SupplyItem id={supply.id} key={indx} title={supply.title} text={supply.description} setSupplies={setSupplies} selectedSupplies={selectedSupplies}  />
                    })
                }
            </div>
        </div>
    )
}

const SupplyItem = ({ id, title, text, setSupplies, selectedSupplies }) => {
    const [selected, setSelected] = useState(false)
    useEffect(() => {
        if(selectedSupplies?.includes(id)){
            setSelected(true)
        }
    },[])

    useEffect(() => {
        setSelected(selectedSupplies?.includes(id))
    },[selectedSupplies])

    const selectSupply = (value) => {
        if(value === true){
            selectedSupplies ? setSupplies([...selectedSupplies, id]) : setSupplies([id])
        }else{
            if(selectedSupplies?.includes(id)){
                const tmp = []
                selectedSupplies.map((val,_) => {
                    if(val !== id){
                        tmp.push(val)
                    }
                })
                setSupplies(tmp)
            }
        }
        setSelected(value)
    }

    return (
        <div className=''>
            <FormChecker selected={selected} setSelected={selectSupply} text={<div className='text-md font-semibold capitalize'>{title}</div>} />
            <div className='pl-16 text-sm -mt-4'>
                {text}
            </div>
        </div>
    )
}

export default JobSupplies