import React, { useEffect, useState } from 'react'
import DashboardFormField from '../../../../../DashboardComponents/DashboardFormField'
import TipsCard from '../../../../../DashboardComponents/FormComponents/TipsCard'

const DescriptionForm = ({services}) => {

    const [description, setDescription] = useState('')

    useEffect(() => {
        setDescription(services.stored_services.description)
    },[])

    useEffect(() => {
        services.updateDescription(description)
    },[description])

    return (
        <div className='flex h-auto items-start justify-start w-full'>
            <div className='py-5 w-full md:w-[60%] '>
                <div>
                    <div className='text-sm max-w-lg px-3'>The first rule of business is presesntation. Tell you clients about yourself, your experiences and advantages you bring over other simillar services. </div>
                    <div className='max-w-lg'>
                        <DashboardFormField rows={10} multiline={true} name="Description" placeholder="describe your business in a few words" maxLength={500} text={description} setText={setDescription} />
                    </div>
                    <div className='max-w-lg flex justify-end px-5'>{description.length} / <b> 500</b></div>
                </div>
            </div>
            <div className='hidden md:w-[40%] h-full md:flex justify-start'>
                <TipsCard />
            </div>
        </div>
    )
}

export default DescriptionForm