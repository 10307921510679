import React from 'react'
import IntroductionSection from '../IntroSection';
import HousingSection from '../HousingSection';
import InnovativeTechSection from '../InnovativeTechSection';
import AppFeaturesSection from '../AppFeaturesSection';
import ClientReviewSection from '../ClientReviewSection';
import FQA from '../FQA';
import DownloadApp from '../DownloadApp';
import Footer from '../../../Components/Footer';
import HousingSearch from '../HousingSearch';

const HomePage = () => {
    return (
        <div className='w-full'>

            <div className='w-full h-auto mx-auto flex flex-col items-center'>
                <IntroductionSection />
                {/* <HousingSearch /> */}
                <AppFeaturesSection />
                <HousingSection />
                <InnovativeTechSection />
                <ClientReviewSection />
                {/* <FQA /> */}
                <DownloadApp />
            </div>
            {/* <Footer /> */}
        </div>

    )
}

export default HomePage