import { del_fetch, get_fetch, post_fetch, put_fetch } from "../../Pages/Dashboard/Screens/Timetable/functions"

export const publish_blog_post = async (data) => {
    return await post_fetch('api/blog', data)
}

export const update_blog_post = async (data) => {
    return await put_fetch('api/blog', data)
}


export const delete_blog_post = async (id) => {
    return await del_fetch('api/blog?id=' + id)
}

export const load_user_blogs_posts = async (uid) => {
    return await get_fetch('api/user/blog?uid=' + uid)
}

export const fetch_blog = async (doc) => {
    return await get_fetch(`api/blog?id=${doc}`)
}

export const fetch_simillar_blogs = async (category, limit) => {
    return await get_fetch(`api/blogs/simillar?category=${category}&limit=${limit}`)
}

export const update_blog_reaction = async (data) => {
    return await post_fetch('api/blog/reaction', data)
}

export const update_blog_impression = async (data) => {
    return await post_fetch('api/blog/impression', data)
}

export const fetch_blog_reactions = async (id) => {
    return await get_fetch('api/blogs/reactions?item_id=' + id)
}

export const fetch_nearby_blog_posts = async (data) => {
    return await post_fetch('api/blogs/get_nearby_posts', data)
}
