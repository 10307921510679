import React, { useEffect, useState } from 'react'
import { getWeatherReport } from '../../functions'
import moment from 'moment'

const WeatherCard = () => {

    const [weather, setWeather] = useState(null)

    const now = new Date()
    useEffect(() => {
        let mounted = false

        if (mounted) {
            getWeatherReport().then((res) => {
                setWeather(res)
            })
        }

        return () => {
            mounted = false
        }
    }, [])


    return weather && (
        <div className='w-full flex justify-center'>
            <div className='w-[95%] lg:w-[85%] px-5 self-center space-y-7 flex flex-col bg-white py-4 shadow-sm rounded-3xl '>
                <div className='w-full flex items-center justify-center space-x-2'>
                    <img src={weather.image}
                        className='w-20 h-20 object-cover' />
                    <div className='text-textcolor text-3xl font-extralight'>{weather.current_temp}</div>
                </div>
                <ListWeatherHours forecast={weather['5_hour_forecast']} />
                <div>
                    <div className='text-xl font-semibold text-primary-500'>{weather.clouds}</div>
                    <div className='text-sm font-light text-primary-500'>Nicosia, {moment(now).format('LL')}.</div>
                </div>
            </div>
        </div>
    )
}

const ListWeatherHours = ({ forecast }) => {
    return (
        <div className='flex flex-col lg:flex-row w-auto items-center overflow-x-scroll scrollbar scrollbar-h-sm'>
            {
                forecast.map((cast, indx) => {
                    return <ForecastCard key={indx} data={cast} />
                })
            }
        </div>
    )
}


const ForecastCard = ({ data }) => {
    return (
        <div className='flex-1 w-full lg:w-auto flex flex-col items-center p-5 lg:text-xs bg-primary-300 my-2 lg:my-0 mx-2 lg:mx-1 rounded-lg space-y-4'>
            <div>{data.time}</div>
            <div><img src={data.image} className='w-10 h-10 object-cover' /></div>
            <div>{data.temp}</div>
        </div>
    )
}
export default WeatherCard