import React, { createContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { load_user_blogs_posts } from "../../../functions/blog_functions";
import { AccountTypes } from "../../../Pages/Dashboard/Screens/SelectAccountTypePage/accounts";
import { save_blog_draft } from "../../../store/actions/blogActions";
import { useAuth } from "../AuthProvider";


const initial_account = {
    blogs: null,
    savingDraft: null,
    setBlogs: () => null,
    draftBlog: (id) => null,
    getBlog: (id) => null,
    deleteDraft: (id) => null,
    updateDraft: (id) => null,
    getBlogs: () => null
}

const BlogDraftContext = createContext(initial_account)

function BlogContext({ children, user, drafted_blogs, updateDraftDrafts }) {

    const [blogs, setBlogs] = useState(null)
    const [draftBlogs, setDraftBlogs] = useState(null)
    const [savingDraft, setSavingDraft] = useState(false)

    const auth = useAuth()
    const uid = user.uid ? user.uid : user?.user.uid
    let _user = user.uid ? user : user.user
    const GROUP_ID = _user?.groupIDs?.filter(i => i.type === AccountTypes.STUDENT)[0]


    useEffect(() => {
        // updateDraftDrafts([])
        load_user_blogs_posts(uid).then((res) => {
            setBlogs([...drafted_blogs.filter(i => i.group_id === GROUP_ID.id), ...res])
            setDraftBlogs(drafted_blogs.filter(i => i.group_id === GROUP_ID.id))
        })
    }, [])

    useEffect(() => {
        setBlogs(blogs)
    }, [blogs, savingDraft])

    const getBlogs = async () => blogs


    const draftBlog = async (id, data) => {
        setSavingDraft(true)
        setTimeout(() => {
            setBlogs([{ ...data, draftID: id , last_saved: new Date()}, ...blogs])
            setDraftBlogs([{ ...data, draftID: id , last_saved: new Date()}, ...draftBlogs])
            updateDraftDrafts([{ ...data, draftID: id, last_saved: new Date() }, ...draftBlogs])
            setSavingDraft(false)
        }, 2000)
        return id
    }


    const getBlog = (id) => {
        return blogs.filter(b => b.draftID === id)[0] ?? null
    }


    const deleteDraft = async (id) => {
        if (blogs.some(b => b.draftID === id)) {
            const tmp = []
            blogs.map((b) => {
                if (b.draftID !== id) {
                    tmp.push(b)
                }
            })
            setBlogs(tmp)
            setDraftBlogs(tmp.filter(i => i.draftID))
            updateDraftDrafts(tmp.filter(i => i.draftID))
        }
    }


    const updateDraft = (id, blog) => {
        setSavingDraft(true)
        setTimeout(() => {
            const data = blog
            if (draftBlogs.some(b => b.draftID === id)) {
                const tmp = []
                draftBlogs.map((b) => {
                    if (b.draftID === id) {
                        tmp.push({ ...data, draftID: id, last_saved: new Date() })
                    } else {
                        tmp.push(b)
                    }
                })
                console.log('==>>',[...tmp, ...blogs.filter(i => i.draftID !== id)])
                setBlogs([...tmp, ...blogs.filter(i => i.draftID !== id)])
                setDraftBlogs(tmp)
                updateDraftDrafts(tmp)
            }
            setSavingDraft(false)
        }, 2000)

    }


    const value = {
        blogs,
        savingDraft,
        setBlogs,
        draftBlog,
        getBlog,
        deleteDraft,
        updateDraft,
        getBlogs
    }

    return (
        <BlogDraftContext.Provider value={value}>
            {children}
        </BlogDraftContext.Provider>
    )
}

export const useBlogDrafts = () => {
    return React.useContext(BlogDraftContext);
}

const mapStateToProps = (state) => {
    return {
        user: state.user?.data,
        drafted_blogs: state.blogs?.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateDraftDrafts: (data) => dispatch(save_blog_draft(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogContext) 