import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import DashboardFormField from '../../../Pages/Dashboard/DashboardComponents/DashboardFormField'
import FormChecker from '../../../Pages/Dashboard/DashboardComponents/FormComponents/FormChecker'
import MapUI from '../../../Pages/Dashboard/DashboardComponents/FormComponents/MapUI'
import SearchAddressModal from '../../../Pages/Dashboard/DashboardComponents/FormComponents/MapUI/SearchAddressModal'
import { useOrder } from '../../UtilityComponents/PlaceOrderContext'

const GetTaskLocationForm = () => {

    const order = useOrder()
    const setLocation = order.setSelectedLocation
    const location = order.selectedLocation

    const [search, setSearch] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [position, setPosition] = useState(null)
    const [address, setAddress] = useState(null)
    const [apartment, setApartment] = useState('')
    const [door, setDoor] = useState('')
    const [useHomeAddress, setUseHomeAddress] = useState(false)

    useEffect(() => {
        if (location) {
            setSearch(location?.address)
            setPosition(location.latlon)
            setDoor(location.door)
            setApartment(location.apartment)
        }

        return () => {

        }
    }, [])


    useEffect(() => {
        setLocation({
            ...location,
            address,
            apartment,
            door
        })
    }, [address, apartment, door])

    useEffect(() => {
        if (position) {
            setLocation({
                ...location,
                latlon: position,
            })
        }
    }, [position])

    const searchPlaces = async (addr) => {
        setAddress(addr.address)
        setPosition(addr.latlon)
        setSearch(addr.address)
    }

    const closeOpened = (e) => {
        setIsOpen(e)
    }


    return (
        <div className='flex h-auto items-start max-w-lg justify-start w-full'>
            <SearchAddressModal home={null} handleAction={searchPlaces} address={search} open={isOpen} setOpen={closeOpened} />
            <div className='py-5 w-full'>
                <div>
                    <div className='text-sm px-3'>For a more percise location Information, you can zoom in and move the Map pin around to set exactly your location. </div>
                    <div onClick={() => !useHomeAddress && setIsOpen(true)} className='max-w-lg'>
                        <DashboardFormField disabled={useHomeAddress} name="Location" placeholder="enter your address" text={search} setText={setSearch} />
                    </div>
                </div>
                <MapUI setPosition={setPosition} position={position} />
                {/* <FormChecker selected={useHomeAddress} setSelected={setUseHomeAddress} text="Use my home address" /> */}

                <div className='w-full grid grid-cols-1 lg:grid-cols-2'>
                    <DashboardFormField name="Apartment Name" placeholder="Hillton Apartment" text={apartment} setText={setApartment} />
                    <DashboardFormField placeholder="Door 3" name="Door Number" text={door} setText={setDoor} />
                </div>

            </div>
        </div>
    )
}


export default GetTaskLocationForm