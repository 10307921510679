import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate } from "react-router";
import { Stages } from '../../../Pages/Dashboard/Screens/Main/SubScreens/Settings/Forms/Billings/UploadID/stages';
import { store_user_data } from '../../../store/actions/authActions';
import { useAuth } from '../AuthProvider';


const initial_billings = {
  stage: null,
  setStage: () => null,
  updateStage: (data) => null
}

const BillingContextProvider = createContext(initial_billings)

const BillingContext = ({ children, user, storeUser }) => {
  let auth = useAuth();
  let location = useLocation();

  const [stage, setStage] = useState(Stages.INTRODUCTION)

  useEffect(() => {
    if (!user.user) {
      loadBillingInfo().then((thisUser) => {
        setStage(thisUser.business_account_activation_cleared_stage)
      })
    } else {
      setStage(user.user.business_account_activation_cleared_stage ? user.user?.business_account_activation_cleared_stage : Stages.INTRODUCTION)
    }
  }, [])

  useEffect(() => {
    // console.log(stage)
  }, [stage])

  const loadBillingInfo = async () => {
    return await auth.getuser(auth.user.uid)
  }

  const updateStage = (data) => {
    storeUser({ user: { ...user.user, business_account_activation_cleared_stage: data } })
    setStage(data)
  }


  const value = { stage, setStage, updateStage }

  return (
    <BillingContextProvider.Provider value={value}>
      {children}
    </BillingContextProvider.Provider>
  );

}

export const useBilling = () => useContext(BillingContextProvider)

const mapStateToProps = (state) => {
  return {
    user: state.user?.data
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    storeUser: (data) => dispatch(store_user_data(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingContext) 