import { CashIcon, CurrencyPoundIcon, GlobeIcon, HashtagIcon, HomeIcon, MapIcon, PlusIcon, SortDescendingIcon, StatusOnlineIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import ArmsChairIcon from '../../../assets/ArmsChairIcon'
import BedIcon from '../../../assets/BedIcon'
import { useSuzi } from '../../../Components/Assistant'
import { doFilter, sterilizeInt } from '../functions'
import BlockSelector from './FilterComponents/BlockSelectors'
import { ThumbSlider } from './FilterComponents/ThumSlider'
import { TwoThumbsRangeSlider } from './FilterComponents/TwoThumbSlider'

export const currencyList = [
    {
        title: "$",
        name: "usd"
    },
    {
        title: "£",
        name: "pounds"
    },
    {
        title: "₺",
        name: "lira"
    },
    {
        title: "€",
        name: "euro"
    }
]

const houseFacilities = [

    {
        title: "Has Aircondition",
        name: "hasAC"
    },
    {
        title: "Has Heater",
        name: "hasHeater"
    },
    {
        title: "Has Cleaners",
        name: "hasCleaners"
    },
    {
        title: "Close To Market",
        name: "closeToMarket"
    },
    {
        title: "Has Gym",
        name: "hasGym"
    },
    {
        title: "Has Wi-Fi",
        name: "has_wifi"
    },
    {
        title: "Dorm",
        name: "isDorm"
    },
    {
        title: "Sharable",
        name: "sharingAllowed"
    }
]

const placeFeatures = [
    {
        title: "Parking",
        name: "Parking"
    },
    {
        title: "Accepts Cash",
        name: "accepts_cash"
    },
    {
        title: "Accepts Credit Card",
        name: "accepts_credit_card"
    },
    {
        title: "Does Delivery",
        name: "does_delivery"
    },
    {
        title: "Happy Hour",
        name: "happy_hour"
    },
    {
        title: "Has Wi-Fi",
        name: "has_wifi"
    },
    {
        title: "Can Book Reservation",
        name: "makes_reservation"
    },
    {
        title: "Live Music",
        name: "live_music"
    },
    {
        title: "Not A Chain",
        name: "not_a_chain"
    },
    {
        title: "Outdoor Dining",
        name: "outdoor_dining"
    },
    {
        title: "Take Outs",
        name: "take_outs"
    }
]

const placeCategories = [
    {
        title: "Market",
        name: "markets"
    },
    {
        title: "Restaurant",
        name: "restaurants"
    },
    {
        title: "Beach",
        name: "beach"
    },
    {
        title: "Hotel",
        name: "hotel"
    },
    {
        title: "Transport",
        name: "transport"
    },
    {
        title: "Utilities",
        name: "utilities"
    },
    {
        title: "Religion",
        name: "religion"
    }

]



const ApartmentFilter = ({ location, setFilter, full = false, setLoading, setOpenFilter }) => {
    const suzi = useSuzi()

    const [currencySelector, setCurrencySelector] = useState([])
    const [areaSelector, setAreaSelector] = useState([])
    const [sortBySelector, setSortBySelector] = useState([])
    const [bedroomSelector, setBedroomSelector] = useState([])
    const [bathroomSelector, setBathroomSelector] = useState([])
    const [minsToBusStopSelector, setMinsToBusStopSelector] = useState([5])
    const [furnishedSelector, setFurnishedSelector] = useState([])
    const [rentSelector, setRentSelector] = useState([])
    const [facilitiesSelector, setFacilitiesSelector] = useState([])
    const [radius, setRadius] = useState([5])
    const [priceRange, setPriceRange] = useState([50, 350])



    const resetFilters = () => {
        setBedroomSelector([])
        setBathroomSelector([])
        setFurnishedSelector("Any")
        setFacilitiesSelector([])
        setSortBySelector("Distance")
        setAreaSelector([])
        setMinsToBusStopSelector([5])
        setRentSelector([])
        setRadius([5])
        setPriceRange([50, 350])
    }

    const handleOnFilter = () => {
        setLoading(true)
        if (radius[0] <= 0) {
            suzi.addMessage({
                text: "Hey..., I can't filter items for you over a 0 meter distance now can I?, Distance must be greater than 0",
                timeout: 3000
            })
            return
        }
        const data = {
            option: "Apartments",
            min_rent: priceRange[0],
            max_rent: priceRange[1],
            bedrooms: sterilizeInt(bedroomSelector),
            bathrooms: sterilizeInt(bathroomSelector),
            furnished: furnishedSelector,
            facilities: facilitiesSelector,
            minsToBus: minsToBusStopSelector,
            sortBy: sortBySelector,
            currency: currencySelector,
            area: areaSelector,
            rent: rentSelector,
            distance: radius[0] * 1000,
            position: {
                longitude: location.longitude,
                latitude: location.latitude
            }
        }

        doFilter(data).then((res) => {
            setFilter(res?.v)
            setOpenFilter(false)
        }).finally(() => {
            setLoading(false)
        })
    }


    return (
        <div className={`fixed ${full ? 'w-auto pr-5' : 'w-[20%]'}  p-5 pr-0 border-2 `}>
            <div className='p-5 text-lg font-medium text-gray-500'>Filter Your Results</div>
            <div className='h-[60vh]  overflow-y-auto scrollbar-sm'>

                <BlockSelector
                    icon={<SortDescendingIcon className="guide-icon text-black stroke-1" />}
                    items={["Distance", "Price", "Minutes To Busstop"]}
                    title="Sort By"
                    multiSelect={true}
                    selected={sortBySelector}
                    setSelector={setSortBySelector}

                />

                <div className='w-full p-5 py-8'>
                    <div className='w-full flex items-center space-x-4 pb-6'>
                        <MapIcon className="guide-icon stroke-1" />
                        <div className=''>Distance / Km</div>
                    </div>
                    <ThumbSlider values={radius} setValues={setRadius} />
                </div>

                <div className='w-full p-5 py-8'>
                    <div className='w-full flex items-center space-x-4 pb-6'>
                        <CashIcon className="guide-icon stroke-1" />
                        <div className='flex'>Price <div className='text-sm px-2 text-gray-400'>(£/month)</div></div>
                    </div>
                    <TwoThumbsRangeSlider values={priceRange} setValues={setPriceRange} MIN={50} MAX={1000} />
                </div>

                <div className='w-full p-5 py-8'>
                    <div className='w-full flex items-center space-x-4 pb-6'>
                        <HashtagIcon className="guide-icon stroke-1" />
                        <div className=''>Minutes To Busstop</div>
                    </div>
                    <ThumbSlider values={minsToBusStopSelector} setValues={setMinsToBusStopSelector} />
                </div>


                <BlockSelector
                    icon={<CurrencyPoundIcon className="guide-icon text-black stroke-1" />}
                    items={currencyList}
                    title="Currency"
                    multiSelect={true}
                    selected={currencySelector}
                    setSelector={setCurrencySelector}

                />

                <BlockSelector
                    icon={<BedIcon className="guide-icon text-black stroke-1" />}
                    items={["Studio", "1", "2", "3", "4+"]}
                    title="BedRooms"
                    multiSelect={true}
                    selected={bedroomSelector}
                    setSelector={setBedroomSelector}

                />

                <BlockSelector
                    icon={<BedIcon className="guide-icon text-black stroke-1" />}
                    items={["Any", "1", "2", "3", "4+"]}
                    title="Bathrroms"
                    multiSelect={true}
                    selected={bathroomSelector}
                    setSelector={setBathroomSelector}

                />

                <BlockSelector
                    icon={<GlobeIcon className="guide-icon text-black stroke-1" />}
                    items={["Lefkosia", "Deraboyu", "Kucukaymacli", "Girne", "Gonyeli"]}
                    title="Area"
                    multiSelect={true}
                    selected={areaSelector}
                    setSelector={setAreaSelector}

                />
                <BlockSelector
                    icon={<HomeIcon className="guide-icon text-black stroke-1" />}
                    items={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                    title="Rent/month"
                    multiSelect={true}
                    selected={rentSelector}
                    setSelector={setRentSelector}

                />

                <BlockSelector
                    icon={<StatusOnlineIcon className="guide-icon stroke-1" />}
                    items={houseFacilities}
                    title="Facilities"
                    multiSelect={true}
                    selected={facilitiesSelector}
                    setSelector={setFacilitiesSelector}

                />
                <BlockSelector
                    icon={<ArmsChairIcon className="guide-icon stroke-1" />}
                    items={["Any", "Furnished", "Unfurnished"]}
                    title="Furnishing"
                    multiSelect={true}
                    selected={furnishedSelector}
                    setSelector={setFurnishedSelector}

                />
            </div>
            <div className='flex items-center'>
                <div onClick={() => handleOnFilter()} className='cursor-pointer w-auto text-center flex-1 ml-0 mx-3 py-3 my-4 font-bold text-white rounded-md bg-primary-500'>
                    <div>Search</div>
                </div>
                <div onClick={() => resetFilters()} className='cursor-pointer w-auto text-center flex-1 ml-0 mx-3 py-3 my-4 font-bold text-white rounded-md bg-red-500'>
                    <div>Reset Filter</div>
                </div>
            </div>
        </div>
    )
}


export default ApartmentFilter