import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { CheckIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { pages as ServicePages } from '../../../../Screens/Main/SubScreens/Settings/Forms/data'
import { Housingpages, Businesspages } from '../../../../Screens/Main/SubScreens/Settings/Forms/data'

const FormPageNavigation = ({ services, by = 'services' }) => {

    let pages = null

    if(by === 'services'){
        pages = ServicePages
    }

    if(by === 'housing'){
        pages = Housingpages
    }

    if(by === 'business'){
        pages = Businesspages
    }

    const isLastPage = services.currentPage.id === pages.length
    const isFirstPage = services.currentPage.id === 1
    const [agreedToTerms, setAgreedToTerms ] = useState(false)

    useEffect(() => {
        setAgreedToTerms(services.review)
    },[services.acceptedAgreement])

    function onTabKeyDown(e) {
        if (e.key === "Tab") {
            services.goToNextPage((services.currentPage.id))
        }
    }

    useEffect(() => {
    
    document.addEventListener("keydown", onTabKeyDown) 

    return () => document.removeEventListener('keydown', onTabKeyDown);
}, [])

    return (
        <div className='flex items-center justify-between md:mb-10 mb-24 px-2 my-5'>
            <div onClick={() => services.goToPrevPage((services.currentPage.id) - 2)} className={` cursor-pointer font-medium hover:text-primary-500`}>
                {
                    !isFirstPage &&
                    <div className='flex items-center space-x-4'>
                        <ChevronLeftIcon className='md:guide-icon p-1 rounded-full md:p-0 md:bg-transparent   md:shadow-none w-10 h-10 bg-white shadow-md' />
                        <div className='hidden md:block'>Go Back</div>
                    </div>
                }
            </div>
            <div onClick={() => services.goToNextPage((services.currentPage.id))} className=' rounded-full cursor-pointer font-medium hover:text-primary-500'>
                {
                    !isLastPage &&
                    <div className='flex justify-center items-center space-x-4 '>
                        <div className='hidden md:block'>Save & Continue</div>
                        <ChevronRightIcon className='md:guide-icon p-1 rounded-full md:p-0 md:bg-transparent md:shadow-none w-10 h-10 bg-white shadow-md' />
                    </div>
                }

                {
                    isLastPage &&
                    <div className={`flex justify-center p-2 px-4 ${services.acceptedAgreement?'bg-primary-500 text-white':'bg-gray-400 text-gray-800'} hover:bg-primary-800 rounded-md items-center md:space-x-4 `}>
                        <div onClick={() => services.acceptedAgreement && services.goToDraft()} className='text-xs md:text-base'>
                            <span className='hidden md:block'>Save & Finish</span>
                            <CheckIcon className='w-5 h-5 block md:hidden' />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default FormPageNavigation