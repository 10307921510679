import { Transition } from '@headlessui/react'
import { CalendarIcon, CheckIcon, ClipboardIcon, ClipboardListIcon, StarIcon, XIcon } from '@heroicons/react/outline'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { UpdatesStatusTypes } from '../../../../Pages/Dashboard/Screens/Jobs/JobsMenu/data'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import ReviewComponent from '../../../ReviewComponent'
import Loader from '../../../../assets/spinner.svg'
import { data } from 'autoprefixer'
import { chat_item_update } from '../../../../functions/chats_functions'
import { update_service_order_status } from '../../../../functions/service_functions.js'


const TaskSenderBubble = ({ message }) => {

    const [show, setShow] = useState(false)
    const [time, setTime] = useState('')


    useEffect(() => {
        (async () => {
            setShow(true)
            const date = new Date(message.time)
            setTime(`${(String(date.getHours()).padStart(2, '0'))}:${(String(date.getMinutes()).padStart(2, '0'))} ${date.getHours() > 12 ? 'PM' : 'AM'}`)
        })();
    }, [])


    switch (message.type) {
        case UpdatesStatusTypes.STARTED:
            return (
                <Bubble align="left">
                    <div className='bg-green-500 p-4 pr-6 flex items-center rounded-2xl text-white space-x-4'>
                        <div className="p-4 bg-green-200 aspect-square rounded-full flex justify-center items-center">
                            <ClipboardListIcon className='w-7 h-7 text-green-500' />
                        </div>
                        <div className='flex flex-col space-x-1 text-left'>
                            <div className='text-lg font-semibold'>Task Accepted</div>
                            <div className=' self-start'>{message.author} accepted your task</div>
                        </div>
                        <div className='py-2 text-sm lowercase'>{time}</div>
                    </div>
                </Bubble>
            )


        case UpdatesStatusTypes.COMPLETED:
            return (
                <Bubble align="left">
                    <div className='bg-green-500 p-4 pr-6 flex items-center rounded-2xl text-white space-x-4'>
                        <div className="p-4 bg-green-200 aspect-square rounded-full flex justify-center items-center">
                            <ClipboardListIcon className='w-7 h-7 text-green-500' />
                        </div>
                        <div className='flex flex-col space-x-1 text-left'>
                            <div className='text-lg font-semibold'>Task Completed</div>
                            <div className=' self-start'>{message.author} completed your task</div>
                        </div>
                        <div className='py-2 text-sm lowercase'>{time}</div>
                    </div>
                </Bubble>
            )

        case UpdatesStatusTypes.CANCELED:
            return (
                <Bubble align="left">
                    <div className='bg-red-500 p-4 pr-6 flex items-center rounded-2xl text-white space-x-4'>
                        <div className="p-4 bg-red-200 aspect-square rounded-full flex justify-center items-center">
                            <ExclamationCircleIcon className='w-7 h-7 text-red-500' />
                        </div>
                        <div className='flex flex-col space-x-1 text-left'>
                            <div className='text-lg font-semibold'>Task Cancelled</div>
                            <div className=' self-start'>{message.author} calcelled your task</div>
                        </div>
                        <div className='py-2 text-sm lowercase'>{time}</div>
                    </div>
                </Bubble>
            )

        case UpdatesStatusTypes.REQUESTING_REVIEW:
            return (
                <Bubble align="left">
                    <div className='bg-white border-l-8 border-orange-500 p-4 pr-6 flex items-start rounded-2xl rounded-l-none text-orange-500 space-x-4'>
                        <div className="p-4 bg-orange-200 aspect-square rounded-full flex justify-center items-center">
                            <StarIcon className='w-7 h-7 text-orange-500' />
                        </div>
                        <div className='flex flex-col space-x-1 text-left'>
                            <div className='text-lg font-semibold'>Request for a review</div>
                            <div className=' self-start'>{message?.message?.data?.message}</div>
                            <div>
                                <ReviewComponent _data={message} />
                            </div>
                        </div>
                        <div className='py-2 text-sm lowercase'>{time}</div>
                    </div>
                </Bubble>
            )

        case UpdatesStatusTypes.REQUESTING_EXTENSION:
            return (
                <Bubble align="left">
                    <div className='bg-white border-l-8 border-orange-500 p-4 pr-6 flex items-start rounded-2xl rounded-l-none text-orange-500 space-x-4'>
                        <div className="p-4 bg-orange-200 aspect-square rounded-full flex justify-center items-center">
                            <CalendarIcon className='w-7 h-7 text-orange-500' />
                        </div>
                        <div className='flex flex-col space-x-1 text-left'>
                            <div className='text-lg font-semibold'>Request for deadline extension</div>

                            <div className=' self-start'> {message.author} is requesting a {message?.message?.data.days} day(s) extension on the deadline for your task because {message?.message?.data.reason}</div>
                            <div>
                                <DeadlineAction data={message} />
                            </div>
                        </div>
                        <div className='py-2 text-sm lowercase'>{time}</div>
                    </div>
                </Bubble>
            )

        case UpdatesStatusTypes.REQUESTING_COMPLETED:
            return (
                <Bubble align="left">
                    <div className='bg-white border-l-8 border-orange-500 p-4 pr-6 flex items-start rounded-2xl rounded-l-none text-orange-500 space-x-4'>
                        <div className="p-4 bg-orange-200 aspect-square rounded-full flex justify-center items-center">
                            <CalendarIcon className='w-7 h-7 text-orange-500' />
                        </div>
                        <div className='flex flex-col space-x-1 text-left'>
                            <div className='text-lg font-semibold'>Task Marked as Completed</div>

                            <div className=' self-start'>{message.author} Marked your task as Completed</div>
                            <div>
                                <CompletedAction data={message} />
                            </div>
                        </div>
                        <div className='py-2 text-sm lowercase'>{time}</div>
                    </div>
                </Bubble>
            )
        default:
            return null
    }

}

export default TaskSenderBubble

export const Bubble = ({ children, align }) => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(true)
    }, [])

    return (
        <>
            <Transition
                show={show}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className={`w-full py-2 px-3 flex items-start ${align === 'left' ? "justify-start" : "justify-end"}  space-x-4`} >
                    <div className='max-w-[90%] text-left'>
                        {children}
                    </div>
                </div>
            </Transition>
        </>
    )
}

const DeadlineAction = ({ data }) => {
    const [loading, setLoading] = useState(false)
    const [actionStatus, setActionStatus] = useState(null)


    useEffect(() => {
        if (data.action) {
            setActionStatus(data.action)
        }
    }, [])

    useEffect(() => { }, [actionStatus])

    const updateMessage = (action) => {


        let newEndTime = new Date(data?.message?.task_duration?.endTime)
        newEndTime = new Date(newEndTime.setDate(newEndTime.getDate() + parseInt(data?.message.data.days)))

        setLoading(true)
        const _data = {
            ...data,
            action
        }

        chat_item_update(_data).then(res => {
            setActionStatus(res.action)
            const service_order_data = {
                status: action,
                doc: data?.message?.task_id,
                customer: data?.message?.customer,
                seller: data?.message?.seller,
                time: new Date(),
                data: {
                    endTime: newEndTime.toISOString()
                }
            }

            update_service_order_status(service_order_data).then(() => {

            })
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div className='w-full pt-4 px-3'>

            {
                actionStatus ?

                    actionStatus === UpdatesStatusTypes.EXTENSION_APPROVED ?
                        <div className='flex items-center space-x-4 text-green-500'>
                            <div className="p-2 bg-green-500 aspect-square rounded-full flex justify-center items-center">
                                <CheckIcon className='w-5 h-5 text-white' />
                            </div>
                            <div> you approved the deadline extension.</div>
                        </div>
                        :
                        actionStatus === UpdatesStatusTypes.EXTENSION_REJECTED ?
                            <div className='flex items-center space-x-4 text-red-500'>
                                <div className="p-2 bg-red-500 aspect-square rounded-full flex justify-center items-center">
                                    <XIcon className='w-5 h-5 text-white' />
                                </div>
                                <div> you declined the deadline extension.</div>
                            </div>
                            : null

                    :
                    <div className='flex items-center space-x-4'>

                        <div onClick={() => updateMessage(UpdatesStatusTypes.EXTENSION_REJECTED)} className='py-2 px-4 bg-red-500 text-white font-medium rounded-md cursor-pointer'>Decline </div>
                        <div onClick={() => updateMessage(UpdatesStatusTypes.EXTENSION_APPROVED)} className='py-2 px-4 bg-green-500 text-white font-medium rounded-md cursor-pointer flex items-center space-x-2'>
                            {loading && <img src={Loader} className='w-4 h-4 animate-spin' />}
                            <span>Approve</span>
                        </div>
                    </div>
            }
        </div>
    )
}


const CompletedAction = ({ data }) => {
    const [loading, setLoading] = useState(false)
    const [actionStatus, setActionStatus] = useState(null)


    useEffect(() => {
        if (data.action) {
            setActionStatus(data.action)
        }
    }, [])

    useEffect(() => { }, [actionStatus])

    const updateMessage = (action) => {

        setLoading(true)
        const _data = {
            ...data,
            action
        }

        chat_item_update(_data).then(res => {
            setActionStatus(res.action)
            const service_order_data = {
                status: action,
                doc: data?.message?.task_id,
                customer: data?.message?.customer,
                seller: data?.message?.seller,
                time: new Date(),
                data: {
                    message: data?.message?.data?.message
                }
            }

            update_service_order_status(service_order_data).then(() => {

            })
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div className='w-full pt-4 px-3'>

            {
                actionStatus ?

                    actionStatus === UpdatesStatusTypes.COMPLETED ?
                        <div className='flex items-center space-x-4 text-green-500'>
                            <div className="p-2 bg-green-500 aspect-square rounded-full flex justify-center items-center">
                                <CheckIcon className='w-5 h-5 text-white' />
                            </div>
                            <div> you approved the task completion.</div>
                        </div>
                        :
                        actionStatus === UpdatesStatusTypes.REJECT_COMPLETED ?
                            <div className='flex items-center space-x-4 text-red-500'>
                                <div className="p-2 bg-red-500 aspect-square rounded-full flex justify-center items-center">
                                    <XIcon className='w-5 h-5 text-white' />
                                </div>
                                <div> you declined completion of this task.</div>
                            </div>
                            : null

                    :
                    <div className='flex items-center space-x-4'>

                        <div onClick={() => updateMessage(UpdatesStatusTypes.REJECT_COMPLETED)} className='py-2 px-4 bg-red-500 text-white font-medium rounded-md cursor-pointer'>Decline </div>
                        <div onClick={() => updateMessage(UpdatesStatusTypes.COMPLETED)} className='py-2 px-4 bg-green-500 text-white font-medium rounded-md cursor-pointer flex items-center space-x-2'>
                            {loading && <img src={Loader} className='w-4 h-4 animate-spin' />}
                            <span>Approve</span>
                        </div>
                    </div>
            }
        </div>
    )
}