import React, { useEffect, useState } from 'react'
import Loader from '../../../../../../assets/spinner.svg'
import { BlogBlockTypes } from '..'
import DashboardBlogFormField from '../../../../DashboardComponents/DashboardBlogFormField'
import draftToHtml from 'draftjs-to-html'
import parse from 'html-react-parser'


const BlogParagraph = ({ id, setParagraph, text, cancelBlock, saveDraft }) => {

    const [editing, setEditing] = useState(true)
    const [loading, setLoading] = useState(false)


    const saveParagraph = async () => {
        setLoading(true)
        await setTimeout(() => {
            setEditing(!editing)
            setLoading(false)
            saveDraft()
            // addParagraph(id, 'paragraph', paragraph)
        }, 2000)
    }

    useEffect(() => {
        if (text) {
            setParagraph(text)
            setEditing(false)
        }
    }, [])

    const setText = (value) => {
        setParagraph(id, value, BlogBlockTypes.PARAGRAPH_BLOCK)
    }

    const cancel = () => {
        setEditing(false)
        cancelBlock(id)
    }


    return (
        <div>
            {
                editing ?
                    <div>
                        <DashboardBlogFormField multiline={true} maxLength={1000} text={text} setText={setText} rows={7} />
                        {/* <div className=' flex flex-col md:flex-row text-xs md:text-sm items-start md:items-center space-x-1 px-5'>
                            <div className={`${text?.length > 980 ? 'text-red-500 font-bold' : text?.length > 950 ? 'text-orange-500 font-medium' : text?.length > 900 ? 'text-yellow-500 font-medium' : ''}`}> you have written {text?.length} of 1000 characters</div>
                            <div>, {text?.split(' ').length} words</div>
                            <div> and {text?.split('.').length} sentences in this paragraph.</div>
                        </div> */}
                        <div className='flex items-center justify-end space-x-4 py-2 px-5'>
                            <div onClick={() => cancel()} className='py-2 px-4 bg-red-500 text-white font-medium rounded-md cursor-pointer'>Cancel </div>
                            <div onClick={() => saveParagraph()} className={`py-2 px-4 ${loading ? 'bg-gray-300' : 'bg-primary-500'} text-white font-medium rounded-md cursor-pointer flex items-center space-x-2`}>
                                {loading && <img src={Loader} className='w-4 h-4 animate-spin' />}
                                <span>Save</span>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='p-2 md:p-5 py-0'>
                        <div onClick={() => setEditing(!editing)} className='p-2 md:p-5 py-1 ease_transition rounded-xl border-dashed border-primary-500 border-opacity-0 hover:border-opacity-50 border-4'>
                            <div className='text-base py-2 md:py-5 font-normal text-justify leading-relaxed text-gray-500'>{typeof text === 'string' ? text : <PrintHTML  text={text}/>}</div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default BlogParagraph


export const PrintHTML = ({text}) => {


    const markup = draftToHtml(
        text,
         {
            trigger: '#',
            separator: ' ',
        },
        true,
        true
    );
    return parse(markup)
}