import React, { useState } from "react";
import { useEffect } from "react";
import ChatRecieverBubble from "./ChatRecieverBubble";
import ChatSenderBubble from "./ChatSenderBubble";
import ScrollToBottom, {
  useSticky,
  useScrollToBottom,
} from "react-scroll-to-bottom";
import TaskSenderBubble from "./TaskSenderBubble";
import TaskRecieverBubble from "./TaskRecieverBubble";
import { useRef } from "react";
import ImageSlider_v2 from "../../imageSlider_v2";
import Modal from "../../Modal";
import { CheckIcon, ClockIcon, PlusIcon } from "@heroicons/react/outline";
import { useChat } from "../../UtilityComponents/ChatsContext";
import { fileSizeformatBytes } from "../../../utils/functions";
import { TrashIcon } from "@heroicons/react/solid";
import { useAlert } from "../../UtilityComponents/ErrorContext";

const ChatWidgetBody = ({
  chats,
  room,
  sender,
  fullHeight = false,
  files = [],
  setFiles,
}) => {
  const [sticky] = useSticky();
  const scroll = useScrollToBottom();
  const divRef = useRef(null);
  const [imageSlider, setImageSlider] = useState(null);
  const [done, setDone] = useState(true);
  const alert = useAlert();

  const MAX_FILE_SIZE = 2000;
  const fileSize = files.reduce((a, b) => (a += b.file.size), 0);

  const socketRef = useChat();
  const socket = socketRef.socket;

  useEffect(() => {
    !sticky && scroll();
    divRef.current.scrollIntoView({ behavior: "smooth" });
  }, [chats]);

  useEffect(() => {
    socket.on("uploading", (data) => {
      console.log("UPLOAD STATUS ===>", data);
      setDone(data.status === "done");
    });
  }, [socket]);

  useEffect(() => { }, [done, files]);

  const SelectFile = async (e) => {
    return await Promise.all(
      [...e.target.files].map((file) => ({
        file: file,
        mimeType: file.type,
        name: file.name,
      }))
    ).then((_files) => {
      const _fileSize = files.reduce((a, b) => (a += b.file.size), 0);
      if ((fileSize + _fileSize) / 1024 > MAX_FILE_SIZE) {
        return alert.setalert({
          title: "Limit Exceeded",
          body: "max file size has exceeded limit",
          type: "error",
        });
      } else {
        setFiles([...files, ..._files]);
      }
    });
    // setCurrentFiles([...currentFiles, { src: URL.createObjectURL(newFile), file: newFile }])
  };

  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  return (
    <ScrollToBottom
      className={`w-full relative ${fullHeight
          ? "h-full overflow-y-auto scrollbar-sm"
          : "max-h-[50vh] h-full overflow-y-auto scrollbar-sm"
        } `}
    >
      {files?.length > 0 && (
        <div className="w-full p-5 flex flex-col flex-1 h-full bg-secondary-pink absolute z-10">
          <div
            // ref={imgRef}
            // onDragEnter={onDragEnter}
            // onDragLeave={onDragLeave}
            // onDrop={onDrop}
            className="w-auto flex hover:bg-[#D9D9D9]/50 cursor-pointer flex-col justify-center items-center bg-[#D9D9D9] border-2 border-dashed rounded-xl flex-1 border-[#8F8F8F] relative"
          >
            <PlusIcon className="guide-icon" />
            <div className="mt-4">Add More</div>
            <div className="mt-4 text-xs text-gray-500">
              {fileSizeformatBytes(fileSize)} of 2MB
            </div>
            <input
              type="file"
              className="absolute top-0 w-full h-full opacity-0"
              accept="image/png, image/gif, image/jpeg"
              onChange={(e) => SelectFile(e)}
            />
          </div>
          <div className=" max-h-[70%] min-h-[30%] overflow-x-hidden scrollbar overflow-y-auto">
            <div className="mt-2 grid gap-2 h-full  grid-cols-3  w-full">
              {files?.map((file, i) => {
                return (
                  <FileView
                    key={i}
                    removeFile={removeFile}
                    index={i}
                    data={file}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}

      {chats?.map((chat, indx) => {
        if (chat.room === room) {
          if (sender.uid !== chat?.uid) {
            if (chat?.message?.task_id) {
              return <TaskSenderBubble key={indx} message={chat} />;
            } else {
              return (
                <ChatSenderBubble
                  setSlider={setImageSlider}
                  key={indx}
                  message={chat}
                />
              );
            }
          } else {
            if (chat.message.task_id) {
              return <TaskRecieverBubble key={indx} message={chat} />;
            } else {
              return (
                <ChatRecieverBubble
                  setSlider={setImageSlider}
                  key={indx}
                  message={chat}
                />
              );
            }
          }
        }
      })}
      <div ref={divRef} />
      {imageSlider?.length > 0 && (
        <Modal
          showBackground={false}
          width={14}
          topPad={0}
          open={true}
          setOpen={() => setImageSlider(null)}
        >
            <ImageSlider_v2 images={imageSlider} />
        </Modal>
      )}
      <div className="w-full flex px-2 py-1 justify-end items-end">
        {!done ? (
          <ClockIcon className="w-5 h-5 animate-spin" />
        ) : (
          <CheckIcon className="w-5 h-5" />
        )}
      </div>
    </ScrollToBottom>
  );
};

export default ChatWidgetBody;

const FileView = ({ data, index, removeFile }) => {
  console.log(data);
  return (
    <div className="bg-white mx-2 flex flex-col flex-1 h-[20vh] w-full aspect-square rounded-2xl overflow-hidden shadow-lg">
      <div className="h-[70%] w-full">
        <img
          src={URL.createObjectURL(data.file)}
          className={`w-full h-full flex-1   object-cover`}
        />
      </div>
      <div className="w-full h-[30%] flex items-center justify-between">
        <div className="w-auto text-sm">
          <div className="p-2 font-medium text-gray-500 px-5">
            {data.file?.name.split(".")[0].substring(0, 10)}
            {data.file?.name.split(".")[1]}
          </div>
          <div className="p-2 text-xs px-5 text-gray-400">
            {fileSizeformatBytes(data.file?.size)}
          </div>
        </div>
        <div
          onClick={() => removeFile(index)}
          className="px-5 cursor-pointer text-red-500"
        >
          <TrashIcon className="w-5 h-5" />
        </div>
      </div>
    </div>
  );
};
