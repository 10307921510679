import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { authReducer } from './reducers/authReducers';
import { themeReducer } from './reducers/themeReducers';
import { timetableReducer } from './reducers/timetableReducer';
import { servicesReducer } from './reducers/servicesReducer';
import { accountsReducer } from './reducers/accountsReducer';
import { orderReducer } from './reducers/orderReducer';
import { housingReducer } from './reducers/housingReducer';
import { blogReducer } from './reducers/blogReducers';
import { messagingReducer } from './reducers/messagingReducer';
import { businessReducer } from './reducers/businessReducer';



const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user', 'theme', 'timetable', 'services', 'accounts', 'order','blogs', 'messaging','business']
}

const RootReducer = combineReducers({
    user: authReducer,
    theme: themeReducer,
    timetable: timetableReducer,
    services: servicesReducer,
    accounts: accountsReducer,
    housing: housingReducer,
    order: orderReducer,
    blogs: blogReducer,
    messaging: messagingReducer,
    business: businessReducer
})

export default persistReducer(persistConfig, RootReducer)