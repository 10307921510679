import React, { useEffect, useState } from 'react'
import { normalizeTimetable } from '../../functions'
import TimetableView from './TimetableView'
import html2canvas from 'html2canvas'
import { jsPDF } from "jspdf";
import Logo from '../../../../../../assets/logo.svg'
import Loader from '../../../../../../assets/spinner.svg'


export const printDocument = (id, setLoading) => {
    setLoading(true)
    const input = document.getElementById(id);

    html2canvas(input)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'JPEG', 0, 0);

            pdf.save("download.pdf");
        }).finally(() => {
            setTimeout(() => {
                setLoading(false)
            },1000)
        })
}

const ViewTimetable = (props) => {
    const { table } = props
    const [timetable, setTimetable] = useState(null)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        normalizeTimetable(table).then(res => {
            setTimetable(res)
        })

    }, [])

    


    return (
        <div className='h-full w-full p-5 flex flex-col justify-center items-center' >
            <div className='w-full flex items-center justify-between self-end'>
                <img src={Logo} className="w-20 object-cover" />
                {!loading ? <div onClick={() => printDocument('timetable', setLoading)} className={`bg-primary-500 cursor-pointer text-white py-2 px-4 rounded flex justify-center items-center`}>
                    {loading ? <img src={Loader} className='w-4 h-4 animate-spin' /> : "Download Pdf"}

                </div>:null
                }
            </div>
            <TimetableView ttable={timetable} />

        </div>
    )
}

export default ViewTimetable