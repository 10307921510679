import { MinusIcon } from '@heroicons/react/outline'
import React from 'react'
import { useState, useEffect } from 'react'
import ChatWidgetBody from './ChatWidgetBody'
import ChatWidgetHeader from './ChatWidgetHeader'
import ChatWidgetInput from './ChatWidgetInput'

const ChatWidget = ({ socket, room, sender, recipient, online, chatMessages, sendMessage, show = null }) => {

    const [currentMessage, setCurrentMessage] = useState("");
    const [messageList, setMessageList] = useState([]);

    const onSendMessage = () => {
        sendMessage({
            room,
            sender,
            message: currentMessage
        }).then((messageData) => {
            setMessageList((list) => list && [...list, messageData]);
            setCurrentMessage("");
        })
    }

    useEffect(() => {
        return () => {
            setMessageList([])
            setCurrentMessage("")
        }
    }, [])

    useEffect(() => {
        setMessageList(chatMessages)
    }, [chatMessages])


    useEffect(() => {
        socket.on("receive_message", (data) => {
            setMessageList((list) => !messageList.some(n => n.date === list.date && n.id === list.id) && [...list, data]);
        });
    }, [socket]);

    return (
        <div className='flex bg-white flex-col max-w-md overflow-x-hidden w-full  shadow-lg  ring-black/10 ring-1 rounded-lg'>
            <div className='w-full'>
                <ChatWidgetHeader close={show} recipient={recipient} />
            </div>
            <div className='w-full'>
                <ChatWidgetBody chats={messageList} room={room} sender={sender} />
            </div>
            <div className='w-full'>
                <ChatWidgetInput onSend={onSendMessage} room={{room}} user={sender} socket={socket} message={currentMessage} setMessage={setCurrentMessage} />
            </div>
        </div>
    )
}

export default ChatWidget