import * as actions from '../../actionTypes/timetableActionTypes'


export const timetableReducer = (state = [], action) => {
    switch(action.type){
        case actions.STORE_TIMETABLE:
            return { ...state, type: action.type, data: action.payload, error: action.error }
        case actions.UPDATE_TIMETABLE:
            return { ...state, type: action.type, error: action.error }
        case actions.CLEAR_TIMETABLE:      
            return { ...state, type: action.type, error: action.error }
        default:
            return state
    }
}