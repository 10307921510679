export const currencySymbol = (currency) => {
    const optn = ['pounds', 'euro', 'usd', 'lira']
    const counter = [];
    optn.map((val) => {
        
        switch(val){
            case 'pounds':
                if(currency.pounds){
                    counter.push('£');
                }
                break
            case 'euro':
                if(currency.euro){
                    counter.push('€');
                }
                break
            case 'usd':
                if(currency.usd){
                    counter.push('$');
                }
                break
            case 'lira':
                if(currency.lira){
                    counter.push('₺');
                }
                break
            default:
                counter.push('NAN')
                break
        }

    })
    if(counter.length > 1){
        return '£'
    }else{
        return counter[0]
    }

}