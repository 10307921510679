import React from 'react'
import { currencies } from '../../Screens/Services/AddServices/Forms/SkillSetsForm/RatesForm'

const DashboardCurrencyField = ({ name = "", required = false, currency = currencies[0], value = 0, setValue, subText = null, placeholder = `0.00`, detailText = null }) => {
    return (
        <div className='flex flex-col w-auto'>
            <div className='w-auto py-2 px-4 m-2 my-5 border-2 border-gray-300 rounded-xl flex bg-white flex-col text-sm text-textcolor'>
                <div className='flex justify-between items-center text-textcolor'>
                    <span className='font-medium text-sm'>{name} {required && '*'}</span>
                </div>
                <div className='w-auto flex justify-between items-start overflow-hidden'>
                    <div className='flex items-center space-x-2 font-semibold text-lg'>
                        <span className=''>{currency.symbol}</span>
                        <input onChange={(e) => setValue(e.target.value)} required={required} placeholder={placeholder} value={`${value}`} className=' bg-none h-full outline-none w-full' />
                    </div>
                    {subText && <div className='text-sm md:text-base'>{subText}</div>}
                </div>
            </div>
            {detailText && <div className='text-sm md:px-5 -mt-3'>{detailText} </div>}
        </div>
    )
}

export default DashboardCurrencyField