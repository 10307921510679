import { SearchIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const ServiceSearch = ({ categories }) => {

    const [search, setSearch] = useState('')
    const [searching, setSearching] = useState(false)

    const handleSearch =(e) => {
        setSearch(e.target.value)        
        setSearching(true)
    }

    const handleSelectSearchItem =(cat) => {
        setSearch(cat.id)
        setSearching(false)
    }   

    return (
        <div className='w-auto mt-10 md:mt-0 h-auto md:h-[70vh] relative'>
            <img src="https://cdn.pixabay.com/photo/2020/04/20/04/02/brick-5066282_960_720.jpg" className='w-full h-full object-cover blur-sm' />
            <div className='w-full h-full absolute sm:p-10 md:p-0 top-0 left-0 flex justify-center items-center'>
                <div className='max-w-2xl w-full p-4 shadow-lg ring-1 ring-black/10 rounded-md bg-white py-10'>
                    <div className='flex flex-col justify-center items-center space-y-4'>
                        <div className='text-2xl font-semibold'>Get Students To Help You Out.</div>
                        <div className='w-32 h-1 bg-primary-500'></div>
                        <div className='text-lg'>Finding help you need, where you need it, when you need it.</div>
                    </div>
                    <div>
                        <div className='flex items-center space-x-2 border-2 pl-5 rounded mt-10 relative'>
                            <SearchIcon className='w-5 h-5' />
                            <input onChange={(e) => handleSearch(e) } className='w-[75%] p-2 outline-none' placeholder='I need help with...' value={search.split('_').join(" ")} />
                            <Link to={`/services/list/${search.split(" ").join("_")}`} className='w-[20%] h-full p-2 flex text-center justify-center items-center bg-primary-500 font-semibold cursor-pointer text-white rounded-r'>Search</Link>
                            {(search.length > 0 && searching) &&
                                <div className='flex flex-col text-left absolute top-[100%] ring-1 ring-black/10 bg-white py-5 rounded-xl shadow-lg w-full left-0 divide-y-[1px]'>
                                    {
                                        categories?.filter(i => i.title.toLowerCase().includes(search.toLowerCase())).map((cat, i) => {
                                            return <div onClick={() => handleSelectSearchItem(cat)} key={i} className="py-3 px-5 w-full hover:bg-primary-300">{cat.title}</div>
                                        })
                                    }
                                </div>}
                        </div>
                        <div className='flex items-center flex-wrap space-x-4'>
                            {
                                categories.slice(0, 6).map((cat, i) => {
                                    return <div onClick={() => setSearch(cat.id)} key={i} className='p-1 px-2 cursor-pointer rounded-md bg-primary-500 hover:bg-primary-800 text-white text-sm my-2'>{cat.title.toLowerCase()}</div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceSearch