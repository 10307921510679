import { ArrowsExpandIcon, CameraIcon } from '@heroicons/react/outline'
import React from 'react'
import { useState } from 'react'

const MatterPortWalkThroughView = ({ port_id }) => {
    const [fullScreen, setFullScreen] = useState(false)
    return (
        <div className={`${fullScreen ? 'fixed inset-0 z-50 w-[100vw] h-full bg-gray-500/75 flex justify-center items-center ' : 'relative w-full h-auto md:aspect-video'}`}>
            <iframe width="100%" height="100%" className={`w-full h-full object-cover object-center ${!fullScreen && 'rounded-lg'}`} src={`https://my.matterport.com/show/?m=${port_id}`} frameborder="0" allowfullscreen={true} allow="xr-spatial-tracking"></iframe>
            {
                !fullScreen ?
                    <ArrowsExpandIcon onClick={() => setFullScreen(!fullScreen)} className='cursor-pointer text-white w-10 h-10 absolute top-10 right-10' />
                    :
                    <CameraIcon onClick={() => setFullScreen(!fullScreen)} className='cursor-pointer text-white w-10 h-10 absolute top-10 right-10' />
            }
        </div>
    )
}
{/* <MatterPortWalkThroughView port_id='xKXUkpn2hD2'/> */ }

export default MatterPortWalkThroughView