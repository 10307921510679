import {
  CloudUploadIcon,
  PencilIcon,
  TrashIcon,
  UploadIcon,
} from "@heroicons/react/outline";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import ShowPassport from "../../../../../../../../../assets/show_pspt.png";
import Loader from "../../../../../../../../../assets/spinner.svg";
import { useBilling } from "../../../../../../../../../Components/UtilityComponents/BillingContext";
import { useAlert } from "../../../../../../../../../Components/UtilityComponents/ErrorContext";
import {
  extract_ID_information,
  updateBuisnessUserProfile,
} from "../../../../../../../../../functions/authFunctions";
import { store_user_data } from "../../../../../../../../../store/actions/authActions";
import { uploadImage } from "../../../../../../../../../utils/functions";
import { Stages } from "./stages";

const UploadID = ({ user, current_user, storeUser }) => {
  const alert = useAlert();
  const billing = useBilling();
  const contrabandLabels = ["mrz"];
  const passportFields = ["mrz"];
  const imageFileTypes = ["image/png", "image/jpeg"];
  const [loading, setLoading] = useState(false);
  const [IDDoc, setIDDoc] = useState([]);
  const [selfieFiles, setSelfieFiles] = useState([]);
  const [IDCard, setIdCard] = useState(null);

  const addImageToForm = async (image) => {
    const form = new FormData();
    form.append("image", image);
    return form;
  };

  const handleUploadImages = async () => {
    setLoading(true);
    const file = IDDoc[0].file;
    const selfie = selfieFiles[0].file;

    await addImageToForm(file)
      .then(async (data) => {
        await extract_ID_information(data)
          .then((details) => {
            console.log(details)
            if (details.message.toLowerCase() === "success") {
              if (
                details.result[0].prediction.some((i) =>
                  passportFields.includes(i.label.toLowerCase())
                )
              ) {
                setIdCard(details.result[0].prediction);
              } else {
                throw alert.setalert({
                  type: "error",
                  title: "INVALID ID",
                  body: "Please make sure you have uploaded a Passport Document ID,also make sure to take photo of passport in an even lighting condition.",
                });
              }
            }
          })
          .catch((err) => console.error("ERROR: ", err));
      })
      .then(() => {
        if (IDCard) {
          uploadImage(selfie, `ID/${user.uid}_selfie`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const parseID = () => {
    const tmp = [];
    IDCard.forEach((id, indx) => {
      tmp.push({
        label: id.label,
        value: id.ocr_text,
      });
    });
    return tmp;
  };

  const handleUpdateUserAccount = () => {
    console.log("start");
    setLoading(true);
    updateBuisnessUserProfile({
      uid: user.uid,
      progress: Stages.SCHEDULE_CALL,
      user_id: parseID(),
    })
      .catch((err) => console.error("ERROR UPDATING BUISNESS USER", err))
      .then(() => {
        billing.updateStage(Stages.SCHEDULE_CALL);
      })
      .finally(() => setLoading(false));
  };

  const docImgRef = useRef(null);
  const selfieImgRef = useRef(null);

  const onDragEnter = (imgRef) => {
    imgRef.current.classList.remove("bg-[#D9D9D9]");
    imgRef.current.classList.add("bg-[#CBC9C9]");
  };

  const onDragLeave = (imgRef) => {
    imgRef.current.classList.remove("bg-[#CBC9C9]");
    imgRef.current.classList.add("bg-[#D9D9D9]");
  };

  const onDrop = (imgRef) => {
    imgRef.current.classList.remove("bg-[#CBC9C9]");
    imgRef.current.classList.add("bg-[#D9D9D9]");
  };

  const onIDDropFiles = (e) => {
    const newFiles = e.target.files;
    console.log("FILESIZE in BYTES: ", newFiles[0].type);

    if (newFiles.length > 1) {
      alert.setalert({
        type: "error",
        title: "Max files exceeded",
        body: "expects maximum of 1 image",
      });
      return;
    }

    if (newFiles[0].size > 500000) {
      alert.setalert({
        type: "error",
        title: "Max files exceeded",
        body: "maximum acceptable file size is 500KB",
      });
      return;
    }

    if (!imageFileTypes.includes(newFiles[0].type)) {
      alert.setalert({
        type: "error",
        title: "Wrong File Type",
        body: "file must be a PNG file or a JPEG file.",
      });
      return;
    }

    const updatedFiles = [...IDDoc];
    Array.from(newFiles).map((f, i) => {
      updatedFiles.push({ src: URL.createObjectURL(f), file: f });
    });
    setIDDoc(updatedFiles);
  };

  const onSelfieDropFiles = (e) => {
    const newFiles = e.target.files;
    if (newFiles.length > 1) {
      alert.setalert({ type: "error", title: "Max files exceeded", body: "" });
      return;
    }

    if (newFiles[0].size > 500000) {
      alert.setalert({
        type: "error",
        title: "Max files exceeded",
        body: "maximum acceptable file size is 500KB",
      });
      return;
    }

    if (!imageFileTypes.includes(newFiles[0].type)) {
      alert.setalert({
        type: "error",
        title: "Wrong File Type",
        body: "file must be a PNG file or a JPEG file.",
      });
      return;
    }

    const updatedFiles = [...selfieFiles];
    Array.from(newFiles).map((f, i) => {
      updatedFiles.push({ src: URL.createObjectURL(f), file: f });
    });
    setSelfieFiles(updatedFiles);
  };

  const deleteImage = (index, images, setImages, all = true) => {
    if (all) {
      setSelfieFiles([]);
      setIDDoc([]);
    }
    const newImages = [];
    setImages(newImages);
  };

  const changeImage = (e, index, images, setImages) => {
    const newFile = e.target.files[0];
    const newImages = [];
    images.map((img, indx) => {
      if (indx === index) {
        newImages.push({ src: URL.createObjectURL(newFile), file: newFile });
      } else {
        newImages.push(img);
      }
    });
    setImages(newImages);
  };

  return (
    <div className="flex flex-col items-center overflow-y-auto scrollbar">
      <div className=" flex flex-col items-center text-center px-4">
        <div className="text-lg font-bold mb-5">Verify Identity</div>
        <ul className="text-left list-decimal space-y-4">
          <li className="text-sm flex flex-col space-y-1">
            <span className="font-semibold">
              Step 1: Identification Document
            </span>
            <span>upload a photo of passport ID.</span>
          </li>
          <li className="text-sm flex flex-col space-y-1">
            <span className="font-semibold">Step 2: Selfie With Proof</span>
            <span>
              take a selfie with your passport ID so it's clearly visible and
              does not cover your face. We compare your face in the selfie to
              the document photo. Make sure to upload a bright photo where all
              text are readable.
            </span>
          </li>
          <li className="text-sm flex flex-col space-y-1">
            <span className="font-semibold">Step 3</span>
            <span>Upload documents</span>
          </li>

          <li className="text-sm flex flex-col space-y-1">
            <span className="font-semibold">Step 4</span>
            <span>Verify Passport Information.</span>
          </li>
        </ul>
      </div>
      <img src={ShowPassport} className="w-[60%] aspect-auto my-5 rounded-lg" />

      <div className="w-[80%] space-y-4">
        {!IDCard && (
          <div
            ref={docImgRef}
            onDragEnter={(e) => onDragEnter(docImgRef)}
            onDragLeave={(e) => onDragLeave(docImgRef)}
            onDrop={(e) => onDrop(docImgRef)}
            className="w-full flex flex-col justify-center items-center bg-[#D9D9D9] border-2 border-dashed rounded-xl aspect-video relative border-[#8F8F8F] "
          >
            {IDDoc.length <= 0 ? (
              <div className="flex flex-col justify-center items-center">
                <CloudUploadIcon className="w-10 h-10 aspect-square stroke-1 " />
                <div className="text-primary-500 text-sm mb-5">
                  upload Identification document
                </div>
                <span className="text-xs">.jpg or .png</span>
                <span className="text-xs">should be no more than 500KB</span>
                <input
                  type="file"
                  className=" absolute top-0 w-full h-full opacity-0"
                  onChange={onIDDropFiles}
                  multiple
                />
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                {IDDoc.map((img, indx) => {
                  return (
                    <div
                      key={indx}
                      className="relative w-full h-full rounded-xl"
                    >
                      <img
                        src={img.src}
                        className="w-full h-full object-cover rounded-xl"
                      />
                      <div className="flex items-center space-x-2 absolute top-3 right-3">
                        <div className="p-2 bg-white shadow-md rounded-full cursor-pointer hover:bg-green-400 hover:text-white">
                          <PencilIcon className="guide-icon w-5 h-5 relative overflow-hidden" />
                          <input
                            type="file"
                            className="absolute top-0 w-full h-full opacity-0"
                            onChange={(e) =>
                              changeImage(e, indx, IDDoc, setIDDoc)
                            }
                          />{" "}
                        </div>
                        <div
                          onClick={() =>
                            deleteImage(indx, IDDoc, setIDDoc, true)
                          }
                          className="p-2 bg-white shadow-md rounded-full cursor-pointer hover:bg-red-400 hover:text-white"
                        >
                          <TrashIcon className="guide-icon w-5 h-5 relative overflow-hidden" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}

        {IDDoc.length > 0 && !IDCard && (
          <div
            ref={selfieImgRef}
            onDragEnter={(e) => onDragEnter(selfieImgRef)}
            onDragLeave={(e) => onDragLeave(selfieImgRef)}
            onDrop={(e) => onDrop(selfieImgRef)}
            className={`w-full flex flex-col justify-center items-center bg-[#D9D9D9] border-2 border-dashed rounded-xl ${
              selfieFiles.length <= 0 && "aspect-video"
            } relative border-[#8F8F8F] `}
          >
            {selfieFiles.length <= 0 ? (
              <div className="flex text-center flex-col p-4 md:p-0 justify-center items-center">
                <CloudUploadIcon className="w-10 h-10 aspect-square stroke-1 " />
                <div className="text-primary-500 text-sm mb-5">
                  upload selfie image
                </div>
                <span className="text-xs">.jpg or .png</span>
                <span className="text-xs">should be no more than 500KB</span>
                <input
                  type="file"
                  className=" absolute top-0 w-full h-full opacity-0"
                  onChange={onSelfieDropFiles}
                />
              </div>
            ) : (
              <div className="relative w-full h-full rounded-xl">
                <img
                  src={selfieFiles[0].src}
                  className="w-full h-full object-cover rounded-xl"
                />
                <div className="flex items-center space-x-2 absolute top-3 right-3">
                  <div className="p-2 bg-white shadow-md rounded-full cursor-pointer hover:bg-green-400 hover:text-white">
                    <PencilIcon className="guide-icon w-5 h-5 relative overflow-hidden" />
                    <input
                      type="file"
                      className="absolute top-0 w-full h-full opacity-0"
                      onChange={(e) =>
                        changeImage(e, 0, selfieFiles, setSelfieFiles)
                      }
                    />{" "}
                  </div>
                  <div
                    onClick={() => deleteImage(0, selfieFiles, setSelfieFiles)}
                    className="p-2 bg-white shadow-md rounded-full cursor-pointer hover:bg-red-400 hover:text-white"
                  >
                    <TrashIcon className="guide-icon w-5 h-5 relative overflow-hidden" />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {IDDoc.length > 0 && selfieFiles.length > 0 && !IDCard && (
          <div
            onClick={() => !loading && handleUploadImages()}
            className={`w-full py-2 flex justify-center text-white ${
              loading ? "bg-gray-300 cursor-wait" : "bg-primary-500"
            } rounded-md text-center font-bold cursor-pointer hover:bg-primary-800`}
          >
            {loading ? (
              <img src={Loader} className="w-4 h-4 animate-spin" />
            ) : (
              "Upload Documents"
            )}
          </div>
        )}
        {IDCard && (
          <div className="space-y-4">
            <div className="flex flex-col space-y-4">
              {IDCard.map((item, indx) => {
                return (
                  !contrabandLabels?.includes(item.label.toLowerCase()) && (
                    <div
                      key={indx}
                      className="flex flex-col space-y-1 py-2 border-2 border-gray-300 rounded-xl px-5 text-gray-500"
                    >
                      <span className="font-semibold text-sm">
                        {item.label.split("_").join(" ")}
                      </span>
                      <span className="font-light ">{item.ocr_text}</span>
                    </div>
                  )
                );
              })}
            </div>
            <div className="flex space-x-2 items-center">
              <div
                onClick={() => !loading && setIdCard(null)}
                className={`w-full py-2 flex justify-center text-white ${
                  loading ? "bg-red-400 cursor-not-allowed" : "bg-red-700"
                } rounded-md text-center font-bold cursor-pointer hover:bg-primary-800`}
              >
                Cancel
              </div>
              <div
                onClick={() => !loading && handleUpdateUserAccount()}
                className={`w-full py-2 flex justify-center text-white ${
                  loading ? "bg-gray-300 cursor-wait" : "bg-primary-500"
                } rounded-md text-center font-bold cursor-pointer hover:bg-primary-800`}
              >
                {loading ? (
                  <img src={Loader} className="w-4 h-4 animate-spin" />
                ) : (
                  "Confirm"
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    current_user: state.user?.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeUser: (data) => dispatch(store_user_data(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadID);
