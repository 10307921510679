export const blogCategories = [
    {
        id: 0,
        title: 'Student Life',
        value: 'student_life'
    },
    {
        id:1,
        title:"Food",
        value:"food"
    },    
    {
        id:2,
        title:"Travel",
        value:"travel"
    },    
    {
        id:3,
        title:"Health and fitness",
        value:"health_and_fitness"
    },    
    {
        id:4,
        title:"Lifestyle",
        value:"lifestyle"
    },    
    {
        id:5,
        title:"Fashion and beauty",
        value:"fashion_and_beauty"
    },    
    {
        id:6,
        title:"Photography",
        value:"photography"
    },    
    {
        id:7,
        title:"Personal",
        value:"personal"
    },
    {
        id:8,
        title:"DIY craft ",
        value:"diy_craft "
    },    
    {
        id:9,
        title:"Parenting",
        value:"parenting"
    },    
    {
        id:10,
        title:"Music",
        value:"music"
    },    
    {
        id:11,
        title:"Business",
        value:"business"
    },    
    {
        id:12,
        title:"Art and design",
        value:"art_and_design"
    },    
    {
        id:13,
        title:"Book and writing",
        value:"book_and_writing"
    },
    {
        id:14,
        title:"Personal finance",
        value:"personal_finance"
    },    
    {
        id:15,
        title:"Interior design",
        value:"interior_design"
    },    
    {
        id:16,
        title:"Sports",
        value:"sports"
    },    
    {
        id:17,
        title:"News",
        value:"news"
    },    
    {
        id:18,
        title:"Movie",
        value:"movie"
    },    
    {
        id:19,
        title:"Religion",
        value:"religion"
    },    
    {
        id:20,
        title:"Political",
        value:"Political"
    } 
]


