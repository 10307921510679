import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DashbaordSelectDateRange from "../../../../DashboardComponents/DashbaordSelectDateRange";
import { DashboardFormDropDownList } from "../../../../DashboardComponents/DashboardFormFieldDropList";
import DashboardFormLabeledRight from "../../../../DashboardComponents/DashboardFormLabeledRight";
import FormChecker from "../../../../DashboardComponents/FormComponents/FormChecker";
import {
  apartment_type,
  available_bedrooms,
  bathrooms,
  bedrooms,
  lease_duration,
  people_in_rooms,
} from "./data";

const LivingSpaceForm = ({ housing }) => {
  const today = new Date();

  const [squareFeet, setSquareFeet] = useState(0);
  const [type, setType] = useState(apartment_type[0]);
  const [bed, setBed] = useState(bedrooms[0]);
  const [bath, setBath] = useState(bathrooms[0]);
  const [leaseDuration, setLeaseDuration] = useState(lease_duration[0]);
  const [sharingAllowed, setSharingAllowed] = useState(false);
  const [peopleInRooms, setPeopleInRooms] = useState(people_in_rooms[0]);
  const [availableRooms, setAvailableRooms] = useState(available_bedrooms[0]);
  const [availableForShortTerm, setAvailableForShortTerm] = useState(false);
  const [availableFrom, setAvailableFrom] = useState(today);
  const [availableUntil, setAvailableUntil] = useState(today);

  useEffect(() => {
    if (housing.living_space && !Array.isArray(housing.living_space)) {
      setSquareFeet(housing.living_space.square_feet);
      setBed(
        bedrooms.filter((i) => i.value === housing.living_space.bedrooms)[0]
      );
      setBath(
        bathrooms.filter((i) => i.value === housing.living_space.bathrooms)[0]
      );
      console.log(housing.living_space.bathrooms);
      housing.living_space.leasing &&
        setLeaseDuration(
          lease_duration.filter(
            (i) => i.value === housing.living_space.leasing?.leaseDuration
          )[0]
        );
      setSharingAllowed(housing.living_space.sharingAllowed);
      setPeopleInRooms(
        people_in_rooms.filter(
          (i) => i.value === housing.living_space.people_in_rooms
        )[0]
      );
      setAvailableRooms(
        available_bedrooms.filter(
          (i) => i.value === housing.living_space.available_rooms
        )[0]
      );
      setAvailableForShortTerm(housing.living_space.leasing !== false);
      setAvailableFrom(housing.living_space.duration?.availableFrom);
      setAvailableUntil(housing.living_space.duration?.availableUntil);
    }
  }, []);

  useEffect(() => {
    housing.updateLivingSpace({
      square_feet: squareFeet,
      bedrooms: bed.value,
      bathrooms: bath.value,
      property_type: type,
      leasing: availableForShortTerm
        ? {
            leaseDuration: leaseDuration?.value,
          }
        : false,
      people_in_rooms: peopleInRooms.value,
      available_rooms: availableRooms.value,
      duration: {
        availableFrom,
        availableUntil,
      },
    });

    // console.log(
    //     {
    //         square_feet: squareFeet,
    //         bedrooms: bed.value,
    //         bathrooms: bath.value,
    //         leasing: availableForShortTerm ? {
    //             leaseDuration: leaseDuration?.value
    //         } : false,
    //         people_in_rooms: peopleInRooms.value,
    //         available_rooms: availableRooms.value,
    //         duration: {
    //             availableFrom,
    //             availableUntil
    //         }
    //     }
    // )
  }, [
    squareFeet,
    type,
    bed,
    bath,
    availableFrom,
    availableUntil,
    leaseDuration,
    sharingAllowed,
    peopleInRooms,
    availableRooms,
    availableForShortTerm,
  ]);

  return (
    <div className="w-full lg:max-w-2xl space-y-5">
      <div className="flex flex-col md:flex-row items-center md:space-x-4">
        <DashboardFormDropDownList
          name="Bedrooms *"
          selectedInterval={bed}
          setSelectedInterval={setBed}
          list={bedrooms}
        />
        <DashboardFormDropDownList
          name="Bathrooms *"
          selectedInterval={bath}
          setSelectedInterval={setBath}
          list={bathrooms}
        />
        <DashboardFormLabeledRight
          setText={setSquareFeet}
          text={squareFeet}
          title="Square feet"
          label="ft"
        />
      </div>

      <div>
        <div className="">
          <DashboardFormDropDownList
            name="Property Type *"
            selectedInterval={type}
            setSelectedInterval={setType}
            list={apartment_type}
          />
          <div className="md:flex-row flex-col text-xs items-center space-x-2 md:text-sm px-5 md:-mt-2 ">
            <span className="font-medium">Description ({type.title}): </span>
            <span>{type.description}</span>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-5 items-center space-x-0 md:space-x-4">
        <div className="col-span-2">
          <DashboardFormDropDownList
            name="Available Rooms *"
            selectedInterval={availableRooms}
            setSelectedInterval={setAvailableRooms}
            list={available_bedrooms}
          />
        </div>
        <div className="col-span-3">
          <DashboardFormDropDownList
            name="How many people live here already?"
            selectedInterval={peopleInRooms}
            setSelectedInterval={setPeopleInRooms}
            list={people_in_rooms}
          />
        </div>
      </div>

      <div className="space-y-7 w-full">
        <FormChecker
          text="I’m only leasing this place out for temporarily, i live here and i have my things in the apartment."
          selected={availableForShortTerm}
          setSelected={setAvailableForShortTerm}
        />
        <DashbaordSelectDateRange
          startTime={availableFrom}
          setStartTime={setAvailableFrom}
          endTime={availableUntil}
          setEndTime={setAvailableUntil}
          disabled={!availableForShortTerm}
          starttitle="Available From"
          endtitle="Available Until"
        />
        <div className="w-full md:w-[60%]">
          <DashboardFormDropDownList
            disabled={!availableForShortTerm}
            name="Lease Duration"
            selectedInterval={leaseDuration}
            setSelectedInterval={setLeaseDuration}
            list={lease_duration}
          />
        </div>
      </div>
      <FormChecker
        text="Room sharing is not allowed in this apartment.."
        selected={sharingAllowed}
        setSelected={setSharingAllowed}
      />
    </div>
  );
};

export default LivingSpaceForm;
