import React, { useEffect, useState } from 'react'
import { numberWithCommas } from '../../../functions'
import { exchangeCurrencies } from '../../../functions/get_rates_api'
import { DashboardFormDropDownList } from '../../../Pages/Dashboard/DashboardComponents/DashboardFormFieldDropList'
import { currencies } from '../../../Pages/Dashboard/Screens/Services/AddServices/Forms/SkillSetsForm/RatesForm'
import { useOrder } from '../../UtilityComponents/PlaceOrderContext'

const SelectPreferredCurrency = ({ services }) => {
    const [priceRates, setPriceRates] = useState({})
    const [exchangeRates, setExchangeRates] = useState({})
    const [total, setTotal] = useState(0)
    const [priceGroups, setpriceGroups] = useState([])
    const [selectedCurrency, setSelectedCurrency] = useState(currencies[0])

    const EXCHANGE_RATES = 0.4
    const order = useOrder()
    // const EXCHANGE_RATES = process.env.REACT_APP_EXCHANGE_RATES || 0.4

    useEffect(() => {
        const groupByCategory = services.reduce((group, service) => {
            const category = service.rates.currency.title;
            group[category] = group[category] ?? [];
            group[category].push(service);
            return group;
        }, {});
        parsePrice(groupByCategory)
        console.log(groupByCategory)

        return () => {
            setPriceRates({})
            setExchangeRates({})
            setTotal(0)
            setpriceGroups([])
            setSelectedCurrency({})
        }
    }, [])

    const applyRates = (amount, skip = false) => {
        console.log(amount, skip)
        if (skip === true) {
            return amount
        }
        return (amount * (1 + EXCHANGE_RATES))
    }


    const parsePrice = async (groups) => {
        const tmp = await Object.keys(groups).map(async (g) => {
            let total = 0
            const priceArr = await groups[g].length > 1 ?
                groups[g].reduce(async (item, obj) => {
                    total += (parseFloat(obj.rates.rates) || 0)
                    return total + parseFloat(groups[g][0].rates.rates)
                }) :
                parseFloat(groups[g][0].rates.rates)

            return { sym: g, price: await priceArr }
        })
        const result = await Promise.all(tmp).then(res => res)
        setpriceGroups(result)
    }

    useEffect(() => {
        if (priceGroups.length > 0) {
            let PriceRange = async () => {
                const result = {}
                if (priceGroups.length > 1) {
                    return priceGroups.reduce(async (prev, next) => {
                        result[prev.sym] = await exchangeCurrencies({ from: prev.sym, to: selectedCurrency.title, amount: parseFloat(prev.price) })
                        result[next.sym] = await exchangeCurrencies({ from: next.sym, to: selectedCurrency.title, amount: parseFloat(next.price) })
                        const sum = applyRates(result[prev.sym].result, selectedCurrency.title.toLowerCase() === result[prev.sym]?.query.from.toLowerCase()) + applyRates(result[next.sym].result, selectedCurrency.title.toLowerCase() === result[next.sym]?.query.from.toLowerCase())
                        return { data: result, sum }
                    })
                } else {
                    result[priceGroups[0].sym] = await exchangeCurrencies({ from: priceGroups[0].sym, to: selectedCurrency.title, amount: parseFloat(priceGroups[0].price) })
                    return { data: result, sum: applyRates(result[priceGroups[0].sym].result, selectedCurrency.title.toLowerCase() === result[priceGroups[0].sym]?.query.from.toLowerCase()) }
                }
            }

            PriceRange().then((res) => {
                console.log(res?.data)
                setPriceRates(res?.data)
                setTotal(res?.sum)
                order.setTotalCost(parseFloat(res?.sum))
                order.setCurrency(selectedCurrency)
            })
        }

    }, [priceGroups, selectedCurrency])


    useEffect(() => {
    }, [priceRates, total])

    return (
        <div>
            <div className='flex flex-col flex-1'>
                <DashboardFormDropDownList name="Prefered Currency" selectedInterval={selectedCurrency} setSelectedInterval={setSelectedCurrency} list={currencies} />
                <div className='text-sm px-5 -mt-3'>Currency you wish to pay in</div>
            </div>
            <div className='py-5'>
                <div className='grid grid-cols-3 w-full gap-1 justify-center items-center space-x-4 py-1 font-bold text-lg text-primary-500'>
                    <div>Prices</div>
                    <div> Exchange Prices</div>
                    <div>Exchange Rates</div>
                </div>
                {
                    priceGroups?.map((price, indx) => {

                        return (
                            <div key={indx} className='grid grid-cols-3 w-full gap-1 justify-center items-center space-x-4'>
                                <div className='flex items-center space-x-2 text-gray-400'>
                                    <div className='font-bold'>{price?.sym}:</div>
                                    <div>{numberWithCommas(parseFloat(price?.price).toFixed(2))}</div>
                                </div>

                                <div className='flex items-center space-x-2 text-gray-400'>
                                    <div className='font-bold'>{selectedCurrency.title}:</div>
                                    <div>{priceRates && numberWithCommas(applyRates(priceRates[price?.sym]?.result, price.sym.toLowerCase() === selectedCurrency.title.toLowerCase())?.toFixed(2))}</div>
                                </div>

                                <div className='flex items-center space-x-2 text-gray-400'>
                                    <div className='font-bold'>({selectedCurrency.title})</div>
                                    <div>{priceRates && numberWithCommas(applyRates(priceRates[price?.sym]?.info.rate, price.sym.toLowerCase() === selectedCurrency.title.toLowerCase())?.toFixed(3))}</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className='text-lg font-extrabold font-poppins px-4 py-2 bg-primary-500 text-white rounded-lg'>You Pay {selectedCurrency.symbol} {numberWithCommas(total.toFixed(2))}</div>

        </div>
    )
}

export default SelectPreferredCurrency