import { STORE_HOUSING, UPDATE_HOUSING } from "../../actionTypes/housingActionTypes"

export const store_housing = (data) => {
    return (dispatch) => {
        dispatch({
            type: STORE_HOUSING,
            payload: data,
            error: null
        })
    }
}

export const update_housing = (data) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_HOUSING,
            payload: data,
            error: null
        })
    }
}
