import { Dialog } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'
import Loader from '../../../../../../../assets/spinner.svg'

const DeleteBlogPostDialog = ({ open, setOpen, callback, loading, setLoading, title = '' }) => {

    const handleConfirmation = () => {
        setLoading(true)
        callback().finally(() => {
            setLoading(false)
            setOpen(!open)
        })
    }

    const handleCancel = () => {
        setOpen(!open)
        setLoading(false)
    }


    return open && (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            className="fixed z-50 inset-0 p-4 pt-[25vh] overflow-y-auto"
        >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500/75" />
            <div className='relative bg-white max-w-xl mx-auto rounded-xl shadow-2xl ring-1 ring-black/5 divide-y divide-gray-100'>
                <div className='flex flex-col text-center items-center space-y-2 h-32 rounded-t-xl px-4 py-2'>
                    <div className='flex items-center space-x-2 py-2 '>
                        <div className='font-semibold text-lg'>Delete {title}</div>
                    </div>
                    <div className='text-sm font-medium px-10 '>your post will be permanently deleted for everyone on guide mobile and web apps.</div>
                </div>
                <div className='w-full bg-white p-4 rounded-b-xl'>
                    <div className='w-full pt-10 flex items-center space-x-4 justify-between px-10'>
                        <div onClick={() => handleCancel()} className={`py-2 px-4  rounded-md text-red-500 font-semibold cursor-pointer`}>Cancel</div>
                        <div onClick={() => handleConfirmation()} className={`py-2 px-4 ${loading ? 'bg-gray-300' : 'bg-red-500'} text-white font-medium rounded-full cursor-pointer flex items-center space-x-2`}>
                            {loading && <img src={Loader} className='w-4 h-4 animate-spin' />}
                            <span>Delete</span>
                        </div>
                    </div>
                </div>
            </div>

        </Dialog>
    )
}

export default DeleteBlogPostDialog