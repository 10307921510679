import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DashboardFormField from "../../../../DashboardComponents/DashboardFormField";
import TipsCard from "../../../../DashboardComponents/FormComponents/TipsCard";

const TitleForm = ({ business }) => {
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (business.stored_business?.title) {
      setTitle(business.stored_business?.title);
    }
  }, []);

  useEffect(() => {
    console.log(business.stored_business?.title, " => TITLE");
    business.updateTitle(title);
  }, [title]);

  return (
    <div className="flex h-auto items-start justify-start w-full">
      <div className="py-5 w-full md:w-[60%] ">
        <div>
          <div className="max-w-lg">
            <DashboardFormField
              rows={10}
              name="title"
              placeholder="e.g Beauty Hair Saloon"
              maxLength={100}
              text={title}
              setText={setTitle}
            />
          </div>
          <div className="max-w-lg flex justify-end px-5">
            {title.length} / <b> 100</b>
          </div>
        </div>
      </div>
      <div className="hidden md:w-[40%] h-full md:flex justify-start">
        {/* <TipsCard /> */}
      </div>
    </div>
  );
};

export default TitleForm;
