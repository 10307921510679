import { CashIcon, ExclamationCircleIcon, EyeIcon, HeartIcon, LinkIcon, MailIcon, PhoneIcon } from '@heroicons/react/outline';
import React from 'react'
import { useNavigate } from 'react-router';
import ArmsChairIcon from '../../../assets/ArmsChairIcon';
import BathtubIcon from '../../../assets/BathtubIcon';
import BedIcon from '../../../assets/BedIcon';
import WhatsappIcon from '../../../assets/WhatsappIcon';
import { useSuzi } from '../../../Components/Assistant';
import ImageSlider from '../../../Components/ImageSlider';
import ImpressionClickTrackerHOC from '../../../Components/ImpressionClickTrackerHOC';
import { apartment_impression } from '../../../functions/housing_functions';
import { updateGroupImpressions } from '../../../functions/service_functions.js';
import { currencySymbol, listOfAcceptedCurrencies } from '../functions';

const ApartmentCard = (props) => {


    const { apartment } = props
    const suzi = useSuzi()
    const navigate = useNavigate()
    if (!apartment) { return null }

    const sellerMsg = `Hello, i found your apartment on the Guide App, and i'd like to know if it is still available ${apartment.link}`
    let phone = apartment.contact?.phone.split(")").join('')
    phone = apartment.contact?.phone.split("(").join('')

    const currency = apartment && apartment.features?.acceptedCurrency;
    const currencySym = currency && currencySymbol(currency)
    const listOfCurrencies = currency && listOfAcceptedCurrencies(currency, currencySym)
    const living_space = apartment?.living_space && apartment?.living_space[0]
    const shower = living_space?.split('+')[1]
    const rooms = living_space?.split('+')[0]


    const callOnSuzi = async () => {
        await suzi.addMessage({ text: "Hello there, \n I'm sorry, this functionality will be avaialble on Guide soon. stay tuned? 😉", timeout: 5000 })

    }

    const handleImpression = async (type = 'view') => {
        const data = {
            id: apartment.id,
            type: type
        }
        if(apartment.groupID) await updateGroupImpressions({ id: apartment.groupID, type })
        await apartment_impression(data)
    }

    const handleClickApartment = async (type) => {
        await handleImpression(type).then(() => {
            navigate(`/apartments/${apartment.id}`)
        })
    }

    return (
        <ImpressionClickTrackerHOC onImpression={handleImpression}>
            <div className='w-auto  my-10 mx-5 border-2 rounded-sm overflow-hidden'>
                <div className='w-full flex flex-col lg:flex-row items-start'>
                    <div className='w-full lg:w-[40%] max-h-[300px] overflow-hidden bg-black'>
                        {apartment?.images && <ImageSlider images={apartment.images} />}
                    </div>
                    <div className='h-full flex-1 flex flex-col items-start justify-start py-5 px-5 lg:px-10'>
                        <div className='w-full flex flex-col space-y-2 items-start'>
                            <div className='text-2xl font-semibold flex items-center space-x-4'><div>{currencySym}{apartment?.price}</div> <div className='font-light text-md'>x</div> <div className='font-light text-lg'> {apartment?.features?.rents} Rent(s)</div></div>
                            <div className='w-full flex justify-start'>
                                {listOfCurrencies?.length > 1 && <div className='text-sm font-light flex space-x-2'>  this Landlord also accepts({listOfCurrencies.map((i, indx) => i !== currencySym && <div key={indx}> {i}{indx !== listOfCurrencies.length - 1 ? ',' : null}</div>)})</div>}
                            </div>
                        </div>
                        <div className='flex items-center space-x-10 mt-5'>
                            <div className='items-center space-x-4 hidden lg:flex'><BedIcon className="w-5 h-5 stroke-1" /> <div className='text-sm lg:text-md'>{rooms} Bed(s)</div></div>
                            <div className='flex lg:hidden items-center space-x-4'><BedIcon className="w-5 h-5 stroke-1" /> <div className='text-sm lg:text-md'>{rooms} </div></div>
                            <div className='items-center space-x-4 hidden lg:flex'><BathtubIcon className="w-5 h-5 stroke-1" /> <div className='text-sm lg:text-md'>{shower} Shower(s)</div></div>
                            <div className='flex lg:hidden items-center space-x-4'><BathtubIcon className="w-5 h-5 stroke-1" /> <div className='text-sm lg:text-md'>{shower}</div></div>
                            {apartment?.features?.deposit && <div className='items-center space-x-4 hidden lg:flex'><CashIcon className="w-5 h-5 stroke-1" /> <div className='text-sm lg:text-md'>{apartment?.features.deposit} Deposit(s)</div></div>}
                            {apartment?.features?.deposit && <div className='flex lg:hidden items-center space-x-4'><CashIcon className="w-5 h-5 stroke-1" /> <div className='text-sm lg:text-md'>{apartment?.features.deposit}</div></div>}
                        </div>
                        <div className='text-lg font-medium my-2 text-left'>{apartment?.title?.translations.en_us}</div>
                        <div className='text-md font-light '>{apartment?.address?.area}</div>
                    </div>
                </div>
                <div className='w-full p-5 py-2 flex items-center space-x-5 justify-center lg:justify-end border-t-2'>
                    {apartment.contact?.phone && <a href={`tel:${apartment.contact?.phone}`} onClick={() => handleImpression('phone')} className='flex items-center space-x-2 hover:text-primary-500 cursor-pointer'>
                        <PhoneIcon className='w-6 h-6 stroke-1' />
                        <div className='text-lg'>Call</div>
                    </a>}
                    {apartment.contact?.phone && <a href={`https://wa.me/${phone}?text=${sellerMsg}`} onClick={() => handleImpression('whatsapp')} className='flex items-center space-x-2 hover:text-primary-500 cursor-pointer'>
                        <WhatsappIcon className='w-6 h-6 stroke-1' />
                        <div className='text-lg'>Chat</div>
                    </a>}
                    {
                        !apartment.link ?
                            <div onClick={() => handleClickApartment('click')} className='flex items-center space-x-2 hover:text-primary-500 cursor-pointer'>
                                <EyeIcon className='w-6 h-6 stroke-1' />
                                <div className='text-lg'>View</div>
                            </div>
                            :
                            <div onClick={() => callOnSuzi()} className='flex items-center space-x-2 hover:text-primary-500 cursor-pointer'>
                                <ExclamationCircleIcon className='w-6 h-6 stroke-1' />
                                <div className='text-lg'>Not </div>
                            </div>
                    }
                </div>
            </div>
        </ImpressionClickTrackerHOC>
    )
}

export default ApartmentCard