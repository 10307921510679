import React, { useState } from 'react'
import Modal from '../../../../../Components/Modal'
import DashboardFormField from '../../../DashboardComponents/DashboardFormField'
import { UpdatesStatusTypes } from '../JobsMenu/data'
import Loader from '../../../../../assets/spinner.svg'


const RequestReview = ({ open, setOpen, handleAction }) => {

    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')

    const handleRequestReview = async () => {
        setLoading(true)
        await handleAction(UpdatesStatusTypes.REQUESTING_REVIEW, {
            message
        }).then((res) => {
            console.log('REQUEST SENT: ', res)
        }).finally(() => {
            setLoading(false)
            setOpen(false)
        })
    }

    return (
        <Modal setOpen={setOpen}>
            <div className='w-full flex flex-col divide-y-2'>
                <div className='w-full py-3 text-center font-bold text-lg bg-primary-500 text-white rounded-t-lg'>Request a review</div>
                <div className='p-4 text-gray-500 space-y-2'>
                    <div className=''>You've done and awesome job, and now you can ask your customer to rate your performance. </div>
                    <div className=''>A good rating help's Guide's algorithim rank you higher and show your services to more customers, it also helps customers trust your services more.</div>
                    <DashboardFormField text={message} setText={setMessage} multiline name="message" placeholder="ask your customer for a review, ask nicely :)" />
                    <div className='w-full pt-4 px-3'>
                        <div className='flex items-center justify-end space-x-4'>
                            <div onClick={() => !loading && setOpen(false)} className='py-2 px-4 bg-red-500 text-white font-medium rounded-md cursor-pointer'>Cancel </div>
                            <div onClick={() => !loading && handleRequestReview()} className='py-2 px-4 bg-green-500 text-white font-medium rounded-md cursor-pointer flex items-center space-x-2'>
                                {loading && <img src={Loader} className='w-4 h-4 animate-spin' />}
                                <span>Send</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default RequestReview