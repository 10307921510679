import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Components/UtilityComponents/AuthProvider";
import { useAlert } from "../../../Components/UtilityComponents/ErrorContext";
import Loader from "../../../assets/spinner.svg";
import { useNavigate } from "react-router-dom";
import { sendEmailVerificationEmail } from "../../Dashboard/Screens/Timetable/functions";
import { LogoutIcon } from "@heroicons/react/outline";
import { connect } from "react-redux";
import { useSuzi } from "../../../Components/Assistant";
const vemail = require("../../../assets/vemail.gif");

const VerifyEmail = ({ current_user }) => {
  const user = useAuth();
  const alert = useAlert();
  const suzi = useSuzi()
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingContinue, setLoadingContinue] = useState(false);
  const [sender, setSender] = useState(null);

  useEffect(() => {
    if (sender?.email_verified) {
      navigate("/dashboard");
    }
  }, [sender]);


  useEffect(() => {
    setSender(current_user?.user);
  }, [current_user.user]);

  const CofirmEmailVerification = () => {
    setLoadingContinue(true);
    user
      .getuser(sender.uid)
      .then((res) => {
        const ver = res?.user;
        if (ver.email_verified === true) {
          setSender(ver.user);

          if (window.isNative) {
            // Give logut and login instruction
            suzi.addMessage({
                text: (
                    <div>
                        <div className="font-semibold text-lg">Congratulations!, Your Account is verified.</div><br/>
                        <div>You must now go back to the <b>login</b> page to log into the Guide mobile app.</div><br/>
                        <div>Use the button below, or you can sign out from the bottom Menu at any time.</div><br/>
                        <div className="w-auto py-2 text-center rounded-lg cursor-pointer hover:bg-gray-100 bg-white text-primary-500 font-bold" onClick={() => user.signout()}>Go To Login</div>
                    </div>
                ),
                timeout:10000
            })
            return;
          }

          navigate("/dashboard");
        } else {
          alert.setalert({
            type: "error",
            title: "Not Verified",
            body: "Email unverified, please verify using the link in the email sent to you and try again",
          });
        }
      })
      .catch(() => {
        alert.setalert({
          type: "error",
          title: "failed",
          body: "Failed to get verification status, please try again",
        });
      })
      .finally(() => {
        setLoadingContinue(false);
      });
  };

  const sendEmailVerification = () => {
    (async () => {
      setLoading(true);
      await sendEmailVerificationEmail({
        uid: sender.uid,
        email: sender.email,
        name: sender.display_name,
      });
    })()
      .then((res) => {
        alert.setalert({
          title: "Verification Email Sent",
          body: `A new verification email has been sent to your email @${sender.email}`,
          type: "success",
        });
      })
      .catch((e) => {
        alert.setalert({
          title: "Verification Email Not Sent",
          body: `An error occured while send verification email : ${JSON.stringify(
            e
          )}`,
          type: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="fixed inset-0 bg-black/75 flex justify-center font-sans items-center ">
      <div
        onClick={() => user.signout(true)}
        className=" cursor-pointer absolute top-20 left-10 flex items-center text-xl text-white space-x-4"
      >
        <LogoutIcon className="w-10 h-10 stroke-1 " />
        <div>Sign Out</div>
      </div>
      <div className="max-w-xl flex-col mx-2 xs:mx-4 sm:mx-8 md:mx-0 sm:flex-row flex flex-1 bg-white h-fit w-auto rounded-lg">
        <div className="w-full sm:w-[40%]">
          <img
            src={vemail}
            className="w-full h-full object-contain rounded-lg"
          />
        </div>
        <div className="w-full sm:w-[60%] flex flex-col items-center justify-center p-4">
          <div className="flex flex-col space-y-2 text-left">
            <span className="text-lg font-bold">
              Hi {sender?.display_name},
            </span>
            <span className="text-sm">Welcome to the Guide Family</span>
            <span className="text-xs">
              Check your Email @{sender?.email} for your email verification Link{" "}
            </span>
            <span className="text-xs">
              and click on the link to verify your email
            </span>
          </div>
          <div className="mt-5 w-full">
            <div
              onClick={() => sendEmailVerification()}
              className={`w-full ${
                loading
                  ? "cursor-wait bg-gray-400 text-gray-500"
                  : "hover:bg-green-800 cursor-pointer bg-green-600 text-white"
              } px-4 text-sm font-bold  py-2 rounded-lg `}
            >
              {!loading && <span>Re-send Email Verification</span>}
              {loading && (
                <div className="flex justify-center items-center space-x-4">
                  {" "}
                  <span>Loading</span>{" "}
                  <img src={Loader} className="w-4 h-4 animate-spin" />
                </div>
              )}
            </div>
          </div>
          <div className="mt-5 w-full">
            <div
              onClick={() => CofirmEmailVerification()}
              className={`w-full ${
                loadingContinue
                  ? "cursor-wait bg-gray-400 text-gray-500"
                  : "hover:bg-green-800 cursor-pointer bg-white border-2 border-primary-500 text-primary-500"
              } px-4 text-sm font-bold  py-2 rounded-lg `}
            >
              {!loadingContinue && <span>Continue</span>}
              {loadingContinue && (
                <div className="flex justify-center items-center space-x-4">
                  {" "}
                  <span>Loading</span>{" "}
                  <img src={Loader} className="w-4 h-4 animate-spin" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    current_user: state.user?.data,
  };
};

export default connect(mapStateToProps, null)(VerifyEmail);
