import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import DeleteConfirmationDialog from '../../../../../../Components/Alerts/DeleteConfirmationDialog'
import { Housingpages as pages } from '../../../Main/SubScreens/Settings/Forms/data'
import { useAuth } from '../../../../../../Components/UtilityComponents/AuthProvider'
import { useAlert } from '../../../../../../Components/UtilityComponents/ErrorContext'
import { advertise_apartment } from '../../../../../../functions/housing_functions'
import { AdvertStatus, AdvertTypes } from '../../../../../../functions'
import Modal from '../../../../../../Components/Modal'
import { BillingPrefix, Stages } from '../../../Main/SubScreens/Settings/Forms/Billings/UploadID/stages'
import CollectPayment from '../../../Main/SubScreens/Settings/Forms/Billings/CollectPayment'
import { useHousing } from '../../../../../../Components/UtilityComponents/HousingContext'
import { connect } from 'react-redux'

const HousingPreviewPublish = ({ housing, loading, setLoading, house_id, current_user, groupID }) => {

    const [preview, setPreview] = useState(null)
    const [url, setUrl] = useState(null)
    const [ad_ref, setAdRef] = useState(null)
    const [image, setImage] = useState(null)
    const [open, setOpen] = useState(false)
    const [openAdvertModal, setOpenAdvertModal] = useState(false)
    const [lastSaved, setLastSaved] = useState(null)
    const [housingStatus, setHousingStatus] = useState(null)

    const auth = useAuth()
    const alert = useAlert()
    const house = useHousing()

    const is_registered = current_user.user.business_account_activation_cleared_stage === Stages.ACTIVATED_ACCOUNT


    useEffect(() => {
        setPreview(housing)
        if (housing.stored_housing?.images?.length > 0) {
            setImage(housing.stored_housing.images[0].src ? housing.stored_housing.images[0].src : housing.stored_housing.images[0])
        } else {
            setImage('https://media.istockphoto.com/vectors/linear-sketch-of-an-interior-hand-drawn-vector-illustration-of-a-vector-id674555508?k=20&m=674555508&s=612x612&w=0&h=zaIn7zfr917HcVQEOB4sordnlArT-lAJGgi6NltR0bo=')
        }
    }, [housing])

    useEffect(() => {
        setLastSaved(housing?.last_saved)
        setAdRef(BillingPrefix.HOUSING_ADVERTISMENT)
        setHousingStatus(housing.advert_status?.status ?? null)
    }, [])

    useEffect(() => {

    }, [loading, url, lastSaved])

    useEffect(() => {
        setHousingStatus(housing?.advert_status?.status)
    }, [housing.advert_status])

    const handlePublish = () => {
        const { stored_housing } = housing
        let cheksout = true
        pages.forEach(page => {
            let data = stored_housing ? stored_housing[page.title] : []
            if (Array.isArray(data) && data.length <= 0) {
                alert.setalert({ title: "Incomplete Setup", body: 'you must provide all information to be able to publish.', type: 'error' })
                cheksout = false
                return false
            }
            if (page.title === 'description' && data === '') {
                alert.setalert({ title: "Incomplete Setup", body: 'you must provide all information to be able to publish.', type: 'error' })
                cheksout = false
                return false
            }
            return true
        })

        if (cheksout === false) return

        setLoading(true)
        housing.publishApartment(groupID).then((res) => {
            if (!res || res === undefined) {
                alert.setalert({ type: 'error', body: 'Something went wrong, try again.', title: 'Network Error' })
                return
            }

            if (res?.type && res?.type === 'error') {
                alert.setalert(res)
                setLoading(false)
                return
            }
            setLoading(false)
            setUrl(`/housing/${house_id}`)
            if (housing.id) {
                alert.setalert({ title: "Updated!", body: 'your apartment was updated successfully!', type: 'success' })
            } else {
                alert.setalert({ title: "Published!", body: 'your apartment was listed successfully!', type: 'success' })
                if (!is_registered) {
                    setTimeout(() => {
                        alert.setalert({ title: 'Warning!', body: 'Hello there, \n \n your account has not been verified, to help keep guide safe for you and your tenants, you must verifiy your account to be able to advertise your apartment on Guide. check the settings menu', type: 'warning' })
                    }, 3000)
                }
            }
        })
            .catch((e) => console.log('ERROR: ', e))
            .finally(() => setLoading(false))
    }

    const handleDeleteService = async () => {
        setLoading(true)
        housing.deleteApartment(house_id).then(() => {
            setLoading(false)
            setLastSaved(null)
        })
    }

    const handleAdvertiseApartment = async (status) => {


        setLoading(true)

        const created_date = new Date()
        const expire_date = new Date()
        expire_date.setDate(created_date.getDay() + 32)

        const data = {
            apartment_id: house_id,
            uid: auth?.user.uid ? auth?.user.uid : auth?.user.user.uid,
            created_date: created_date.toISOString(),
            status,
            type: AdvertTypes.APARTMENT_ADS,
            expire_date: expire_date.toISOString()
        }

        advertise_apartment(data).then((res) => {
            const result = JSON.parse(res)
            house.loadUp().then(() => {
                housing.setAdvertStatus({
                    advert_id: result[1],
                    updated_at: result[0],
                    status: result[2]
                })
                setHousingStatus(result[2])
            })
        }).catch((err) => {
            console.warn('ERROR', err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleOpenAdvertiseModal = () => {

        if (housing.advert_status) {
            setLoading(true)
            setTimeout(() => {
                if (housingStatus === AdvertStatus.active) {
                    return handleAdvertiseApartment(AdvertStatus.paused).then(() => setLoading(false))
                } else if (housingStatus === AdvertStatus.paused) {
                    return handleAdvertiseApartment(AdvertStatus.active).then(() => setLoading(false))
                }
                setLoading(false)
            }, 2000)
            return
        }

        if (!is_registered) {
            alert.setalert({ title: 'Security Error', body: 'you need a verified account to advertise on Guide.', type: 'error' })
            if (!is_registered) {
                setTimeout(() => {
                    alert.setalert({ title: 'Warning!', body: 'Hello there, \n \n your account has not been verified, to help keep guide safe for you and your tenants, you must verifiy your account to be able to advertise your apartment on Guide. check the settings menu', type: 'warning' })
                }, 3000)
            }
            return null
        }
        setOpenAdvertModal(true)
    }

    const advertBtnColor = housingStatus ? housingStatus === AdvertStatus.active ? 'bg-orange-500' : 'bg-orange-500' : 'bg-green-500'
    const advertBtnColorHover = housingStatus ? housingStatus === AdvertStatus.active ? 'bg-orange-700' : 'bg-orange-700' : 'bg-green-700'
    const advertTextColorHover = housingStatus ? housingStatus === AdvertStatus.active ? 'text-green-700' : 'text-orange-700' : 'text-gray-400'

    return lastSaved ? (
        <div className='fixed bottom-5 right-5 sm:bottom-10 sm:right-10 flex flex-col md:flex-row  items-end space-x-10 max-w-xl xl:max-w-2xl'>
            <div className='flex items-end space-x-4 pl-10 md:pl-0'>

                <div onClick={() => setOpen(true)} className='font-semibold underline text-red-500 hover:text-red-700 cursor-pointer'>Delete</div>
                <div onClick={() => !loading && handlePublish()} className={`font-semibold  underline ${loading ? 'text-gray-400 cursor-wait' : 'text-blue-500 hover:text-blue-700 cursor-pointer'}`}>{housing.id ? 'Update' : 'Publish'}</div>
            </div>
            <div className='flex flex-col  max-w-md bg-white rounded-xl '>
                <div className='flex items-start w-full shadow-lg p-4 py-2 '>
                    <div className='flex-1 px-2 flex flex-col justify-start py-5 space-y-2'>
                        <Link to={`/apartments/${house_id}`} className='text-md font-semibold underline cursor-pointer pb-2 text-xs md:text-lg'>Preview Your Apartment</Link>
                        {lastSaved && <div className='text-xs overflow-clip inline-block'>last update:  {moment(lastSaved).format("MMM Do YY")}</div>}
                        {!url && <div className='font-medium text-sm text-gray-700 flex'>Status:  {housingStatus ? <span className={`px-3 ${advertTextColorHover} font-semibold`}>
                            {housingStatus === 'active' ? 'advertising...' : housingStatus}
                        </span> : <span className='font-light px-3'>  draft</span>}</div>}
                        {url && <Link to={url} className='text-md font-semibold underline cursor-pointer pb-2 text-xs md:text-lg'>Live Preview</Link>}
                    </div>
                    <div className='w-20 sm:w-[40%] h-auto aspect-square rounded-lg overflow-hidden'>
                        <img src={image} className="object-cover w-full h-full" alt="guide service preview image" />
                    </div>
                </div>
                {(house_id) &&
                    <div onClick={handleOpenAdvertiseModal} className={`w-full py-3 ${advertBtnColor} text-white font-bold text-center rounded-b-xl cursor-pointer hover:${advertBtnColorHover}`}>
                        {
                            housingStatus ?
                                housingStatus === AdvertStatus.active ?
                                    "Pause Advertising"
                                    :
                                    "Resume Advertising"
                                :
                                "Advertise"
                        }

                    </div>}
            </div>
            <DeleteConfirmationDialog
                item_type="Profile"
                title='Housing'
                open={open}
                setOpen={setOpen}
                callback={handleDeleteService}
                setLoading={setLoading}
            />

            {
                openAdvertModal ?
                    <Modal open={openAdvertModal} setOpen={setOpenAdvertModal} >
                        <CollectPayment user={auth.user.uid ? auth.user : auth.user.user} fee={process.env.REACT_APP_APARTMENT_ADVERTISMENT_FEE ?? 10} prefix={ad_ref} callback={() => handleAdvertiseApartment(AdvertStatus.active)} />
                    </Modal>
                    : null
            }



        </div>
    ) : null
}

const mapStateToProps = (state) => {
    return {
        current_user: state.user?.data
    }
}


export default connect(mapStateToProps, null)(HousingPreviewPublish)