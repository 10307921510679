import React, { useEffect, useState } from 'react'
import NumberField from '../../../../../../Components/NumberField'
import DashboardFormField from '../../../../DashboardComponents/DashboardFormField'
import FormChecker from '../../../../DashboardComponents/FormComponents/FormChecker'
import MapUI from '../../../../DashboardComponents/FormComponents/MapUI'
import SearchAddressModal from '../../../../DashboardComponents/FormComponents/MapUI/SearchAddressModal'
import TipsCard from '../../../../DashboardComponents/FormComponents/TipsCard'

const HousingLocationForm = ({ housing }) => {

    const [search, setSearch] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const [position, setPosition] = useState(null)
    const [address, setAddress] = useState([])
    const [hideOnListing, setHideOnListing] = useState(false)
    const [mins_to_busstop, setMinsToBusstop] = useState(5)


    useEffect(() => {
        if (!Array.isArray(housing?.location)) {
            setAddress(housing?.location)
            setPosition(housing?.location?.latlon)
            setSearch(housing?.location?.address)
            setHideOnListing(housing?.location?.hide_on_listing)
            setMinsToBusstop(housing?.location?.mins_to_busstop)
        }
        console.log(housing?.location?.mins_to_busstop,'Mins ')
    }, [])


    useEffect(() => {
        setIsOpen(isOpen)
    }, [isOpen])

    useEffect(() => {
        if (position) {
            housing.updateLocation({
                ...address,
                latlon: position,
                hide_on_listing: hideOnListing,
                mins_to_busstop:mins_to_busstop
            })
        }
        console.log(mins_to_busstop,'Mins ')

    }, [address, position, hideOnListing, mins_to_busstop])


    const searchPlaces = async (addr) => {
        setSearch(addr.address)
        setPosition(addr.latlon)
        setAddress(addr)
    }

    const closeOpened = (e) => {
        setIsOpen(e)
    }

    return (
        <div className='flex h-auto items-start justify-start w-full'>
            <SearchAddressModal home={null} handleAction={searchPlaces} address={search} open={isOpen} setOpen={closeOpened} />
            <div className='py-5 w-full md:w-[60%]'>
                <div>
                    <div className='text-sm max-w-lg px-3'>For a more percise location Information, you can zoom in and move the Map pin around to set exactly your location. </div>
                    <div onClick={() => setIsOpen(true)} className='max-w-lg'>
                        <DashboardFormField name="Location" placeholder="enter your address" text={search} setText={setSearch} />
                    </div>
                </div>
                <MapUI setPosition={setPosition} position={position} />
                <FormChecker selected={hideOnListing} setSelected={setHideOnListing} text="Hide Property address on listing" />
                <div className='text-textcolor flex items-center space-x-4'>
                    <NumberField title="" activeState={mins_to_busstop} setActiveState={setMinsToBusstop} />
                    <div> minutes to the nearest busstop </div>
                </div>
            </div>
            <div className='hidden md:w-[40%] h-full md:flex justify-start'>
                <TipsCard />
            </div>
        </div>
    )
}

export default HousingLocationForm