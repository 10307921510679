import { del_fetch, get_fetch, post_fetch, put_fetch } from "../../Pages/Dashboard/Screens/Timetable/functions"

export const load_user_apartments = async (uid) => {
    return await get_fetch(`api/user/houses?uid=${uid}`)
}

export const add_apartments = async (data) => {
    return await post_fetch('api/user/houses', data)
}

export const update_apartment = async (data) => {
    return await put_fetch('api/user/update_house', data)
}

export const delete_apartments = async (id) => {
    return await del_fetch('api/user/delete_house/' + id)
}

export const get_apartment_unit = async (data) => {
    return await post_fetch('api/user/get_house', { data: { ...data, method: 1 } })
}

export const advertise_apartment = async (data) => {
    return await post_fetch('api/apartment/advertise', data)
}


export const apartment_impression = async (data) => {
    return await post_fetch('api/houses/impressions', data)
}


