import { CheckIcon } from "@heroicons/react/outline";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { Businesspages as pages } from "../../../Main/SubScreens/Settings/Forms/data";
import draftImage from "../../../../../../assets/Site-constructor.svg";
import { connect } from "react-redux";
import { useBusinessForm } from "../../../../../../Components/UtilityComponents/BusinessFormContext";
import { AccountTypes } from "../../../SelectAccountTypePage/accounts";
import PreviewBusinessProfile from "../../PreviewBusinessProfile";
import { defaultHours } from "../../../../DashboardComponents/OpenHours";

const DraftForm = ({ current_user }) => {
  const business = useBusinessForm();

  const business_id = current_user.user?.groupIDs.filter(
    (i) => i.type === AccountTypes.BUSINESS_OWNER
  )[0]?.id;

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleteing] = useState(false);

  useEffect(() => {}, [loading]);

  return !loading ? (
    <div className="w-full h-full relative">
      <div className="flex items-center">
        <div className="flex flex-col h-auto pt-10 pl-5 space-y-5 items-start justify-start w-full">
          {pages.map((page, index) => {
            return <DraftItem key={index} page={page} business={business} />;
          })}
          <div className="w-full h-32" />
        </div>
        <img
          src={draftImage}
          alt="guide draft image"
          className="hidden fixed lg:flex w-[30%] right-[3vw] top-[28vh] mt-10 h-auto"
        />
      </div>
      <PreviewBusinessProfile
        business={business}
        loading={loading}
        deleting={deleting}
        setDeleteing={setDeleteing}
        setLoading={setLoading}
        business_id={business_id}
      />
      <div className="flex sm:hidden w-full h-[200px]" />
    </div>
  ) : (
    <div className="w-full  h-[70vh] flex justify-center items-center">
      <div>
        <img
          src="https://storage.googleapis.com/iframe_content/first%20time%20buyers/first-time-buyer-report_dashboard_optimised/images/loader.gif"
          className="w-20 h-20"
        />
        <span className="text-gray-400">
          {deleting ? "unpublishing..." : "publishing..."}
        </span>
      </div>
    </div>
  );
};

const DraftItem = ({ page, business }) => {
  const { stored_business } = business;
  let [not_edited, setNotEdited] = useState(false);

  useEffect(() => {
    let data = stored_business ? stored_business[page.title] : [];
    if (Array.isArray(data) && data.length <= 0) {
      setNotEdited(true);
    }

    if (data === null) {
      setNotEdited(true);
    }

    if (
      page.title === "open_hours" &&
      Array.isArray(stored_business.openHours) &&
      stored_business.openHours.length == 0
    ) {
      setNotEdited(true);
    }

    if (
      page.title === "location" &&
      (JSON.stringify(stored_business.location) === JSON.stringify([]) ||
        stored_business.location?.addresses?.length <= 0)
    ) {
      setNotEdited(true);
    }

    if (page.title === "description" && data === "") {
      setNotEdited(true);
    }

    if (page.title === "title" && data === "") {
      setNotEdited(true);
    }
    console.log(stored_business);
  }, [stored_business, stored_business.last_saved]);

  return (
    <div
      onClick={() => business.goToPage(page)}
      className=" cursor-pointer flex space-x-4 items-start "
    >
      <div>
        {not_edited ? (
          <ExclamationCircleIcon className="guide-icon w-6 h-6 text-primary-500" />
        ) : (
          <CheckIcon className="guide-icon w-6 h-6 text-primary-500" />
        )}
      </div>
      <div className="min-w-[300px]">
        <div className="capitalize font-semibold underline">
          {page.title.split("_").join(" ")}
        </div>
        {not_edited && (
          <div className="p-3 w-auto inline-block hover:bg-primary-800 bg-primary-500 rounded-lg text-white text-xs m-4">
            {" "}
            add {page.title.split("_").join(" ")}{" "}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    current_user: state.user?.data,
  };
};

export default connect(mapStateToProps, null)(DraftForm);
