import { UPDATE_ACCOUNT } from "../../actionTypes/acountActionTypes"

const initialState = {
    action: UPDATE_ACCOUNT,
    data: null,
    error: null
}

export const accountsReducer = ( state=initialState, action) => {
    switch(action.type){
        case UPDATE_ACCOUNT:
            return {...state, action: action.type, data: action.payload, error: action.error }
        default:
            return state
    }
}