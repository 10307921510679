import React, { useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';

const DateAndTimePicker = ({ setTime, allDates, time, handleDsiabledDates = null, disabled = false }) => {
    const today = new Date()

    const getDisabled = ({ activeStartDate, date, view }) => {
        if (!allDates) return date < today
        const hasEvent = allDates?.some(i => {
            return new Date(i.start.dateTime).toLocaleDateString() === date.toLocaleDateString()
        })
        const condition = hasEvent || date.toLocaleDateString() === today.toLocaleDateString() || date.getDay() === 0 || date.getDay() === 6
        if (condition) return true
    }

    console.log(today.getDay())


    return (
        <div>
            <DateTimePicker tileDisabled={({ activeStartDate, date, view }) => handleDsiabledDates ? handleDsiabledDates({ activeStartDate, date, view }) : getDisabled({ activeStartDate, date, view })} showNeighboringMonth={true} disabled={disabled} onChange={setTime} value={time} />
        </div>
    );
}

export default DateAndTimePicker