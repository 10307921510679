import { CHAT_SERVER_URL } from "../../assets/url"
import { get_fetch, post_fetch } from "../../Pages/Dashboard/Screens/Timetable/functions"

export const get_chats_list = (uid) => {
    return get_fetch(`/get_chat_list/${uid}`, null, CHAT_SERVER_URL)
}

export const load_chat_messages = async (room) => {
    return await fetch(`${CHAT_SERVER_URL}/load_chats/${room}`).then(async (response) => {
        const resp = await response.json()
        if (resp) {
            return resp
        }
    })
}

export const chat_item_update = async (data) => {
    console.log(data)
    return await fetch(`${CHAT_SERVER_URL}/chat/update`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    }).then(async (response) => {
        const resp = await response.json()
        if (resp) {
            return resp
        }
    })
}


export const cancel_chat_requests = async (sender, reciever, room, type = 'cancel') => {
    return await  fetch(`${CHAT_SERVER_URL}/cancel_chat_request?sender=${sender}&reciever=${reciever}&room=${room}&type=${type}`, {
        method: 'GET'
    })  
}
