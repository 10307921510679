import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Businesspages as pages } from "../../../Pages/Dashboard/Screens/Main/SubScreens/Settings/Forms/data";
import { store_business } from "../../../store/actions/businessActions";
import { AccountTypes } from "../../../Pages/Dashboard/Screens/SelectAccountTypePage/accounts";
import {
  deleteServiceImages,
  save_thumbnail,
  uploadServiceImages,
} from "../../../functions/service_functions.js";
import { getCoordHash } from "../../../functions/service_functions.js";
import {
  add_business,
  deleteBusiness,
  load_business,
} from "../../../functions/business_functions";
import { defaultHours } from "../../../Pages/Dashboard/DashboardComponents/OpenHours";

const page = {
  id: 0,
  title: "draft",
  progress: 0,
  pageTitle: "Finish your listing to publish your business",
  pageDescription: "you can always edit your listing after you publish it",
};

const inital_user = {
  currentPage: page,
  progress: null,
  title: [],
  images: [],
  category: [],
  phone: [],
  plugin: [],
  website: [],
  facilities: [],
  description: [],
  review: [],
  location: {},
  openHours: null,
  updateOpenHours: () => null,
  updateLocation: () => null,
  setAcceptedAgreement: () => null,
  setReview: () => null,
  updateDescription: () => null,
  updateFacilities: () => null,
  updateWebsite: () => null,
  updatePhone: () => null,
  updateCategory: () => null,
  updateImages: () => null,
  updateTitle: () => null,
  goToNextPage: () => null,
  goToPrevPage: () => null,
  goToDraft: () => null,
  goToPage: () => null,
  publishBusiness: () => null,
  saveProgress: () => null,
  businessDelete: () => null,
  updateLastSaved: () => null,
  clearBusiness: () => null,
  init_business: () => null,
};

const BusinessFormContext = React.createContext(inital_user);

function AuthProvider({ children, storeBusiness, stored_business, user }) {
  const GROUP_ID = user?.user.groupIDs?.filter(
    (gid) => gid.type === AccountTypes.BUSINESS_OWNER
  )[0]?.id;

  const uid = user?.user.uid;

  let [currentPage, setCurrentPage] = useState(
    stored_business?.currentPage ? stored_business.currentPage : pages[0]
  );
  let [images, setImages] = useState(
    stored_business?.images ? stored_business.images : []
  );
  let [category, setCategory] = useState(
    stored_business?.category ? stored_business.category : []
  );
  let [progress, setProgress] = useState(
    stored_business?.progress ? stored_business.progress : 0
  );
  let [phone, setPhone] = useState(
    stored_business?.phone ? stored_business.phone : []
  );
  let [website, setWebsite] = useState(
    stored_business?.website ? stored_business.website : []
  );
  let [plugin, setPlugin] = useState(
    stored_business?.plugin ? stored_business.plugin : []
  );
  let [description, setDescription] = React.useState(
    stored_business?.description ?? []
  );
  let [review, setReview] = React.useState(stored_business?.review ?? []);
  let [location, setLocation] = React.useState(stored_business?.location ?? {});

  let [facilities, setFacilities] = useState(
    stored_business?.facilities ? stored_business.facilities : []
  );
  let [title, setTitle] = useState(stored_business?.title ?? []);

  let [thumbnail, setThumbnail] = useState(null);

  let [openHours, setOpenHours] = useState(stored_business?.openHours ?? null);
  let [reviewInfo, setReviewInfo] = useState(null);

  let [acceptedAgreement, setAcceptedAgreement] = React.useState(
    stored_business?.review?.length > 0
      ? stored_business.review[0]?.agreed
      : false
  );

  const updateTitle = (value) => setTitle(value);
  const updateImages = (value) => setImages(value);
  const updateCategory = (value) => setCategory(value);
  const updatePhone = (value) => setPhone(value);
  const updateWebsite = (value) => setWebsite(value);
  const updateFacilities = (value) => setFacilities(value);
  const updateDescription = (value) => setDescription(value);
  const updateReview = (value) => setReview(value);
  const updateLocation = (value) => setLocation(value);
  const updatePlugin = (value) => setPlugin(value);
  const updateOpenHours = (value) => setOpenHours(value);
  const updateReviewInfo = (value) => setReviewInfo(value);
  const updateThumbnail = (value) => setThumbnail(value);
  const updateLastSaved = () =>
    storeBusiness({ ...stored_business, last_saved: new Date() });

  useEffect(() => {
    init_business();
  }, []);

  useEffect(() => {}, [openHours]);

  const goToNextPage = async (pageIndex) => {
    if (pageIndex < pages.length) {
      await saveProgress();
      await setCurrentPage(pages[pageIndex]);
    }
  };

  const init_business = () => {
    console.log("INITIALIZING BUSINESS!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    load_business(GROUP_ID)
      .then((resp) => {
        const result = JSON.parse(resp);

        result["images"] = result.images
          ? result.images.map((i) => i.imageURL)
          : [];
        result.title && updateTitle(result.title);
        result.images && updateImages(result.images);
        result.category && updateCategory(result.category);
        result.phone && updatePhone(result.phone);
        result.website && updateWebsite(result.website);
        result.facilities && updateFacilities(result.facilities);
        result.description && updateDescription(result.description);
        result.review && updateReview(result.review);
        result.location && updateLocation(result.location);
        result.openHours && updateOpenHours(result.openHours);
        result.review_info && updateReviewInfo(result.review_info);
        result.thumbnail && updateThumbnail(result.thumbnail);
        result.plugin && setPlugin(result.plugin);
        return result;
      })
      .then((result) => {
        const datum = {
          review: result.review,
          category: result.category,
          facilities: result.facilities,
          phone: result.phone,
          openHours: result.openHours,
          website: result.website,
          location: result.location,
          description: result.description,
          images: result.images,
          title: result.title,
          plugin: result.plugin,
          last_saved: new Date(),
        };

        console.log(result);

        if (result.plugin?.length > 0) {
          datum["plugin"] = result.plugin;
        } else {
          datum["plugin"] = [];
        }

        storeBusiness(datum);
      })
      .catch(() => {
        clearBusiness({ plugin: true });
      });
  };

  const goToPrevPage = async (pageIndex) => {
    if (pageIndex >= 0) {
      await saveProgress();
      await setCurrentPage(pages[pageIndex]);
    }
  };

  const goToPage = async (page) => {
    await setCurrentPage(page);
  };

  const parseInput = async () => {
    return await saveProgress();
  };

  const publishBusiness = async (id) => {
    // const path = new Date().toISOString()
    const path = "Business";
    const default_review_info = {
      ones: 0,
      twos: 0,
      threes: 0,
      fours: 0,
      fives: 0,
      score: 0,
      sum: 0,
      total: 0,
    };

    return await parseInput().then(async (data) => {
      return await uploadServiceImages(data.images, path)
        .then(async (imageUrls) => {
          //   console.log(data.location, imageUrls,'-------------');
          const formData = new FormData();
          formData.append("id", JSON.stringify(id));
          formData.append("uid", JSON.stringify(uid));
          formData.append("group_id", JSON.stringify(GROUP_ID));

          if (!data.reviewInfo) {
            formData.append("review_info", JSON.stringify(default_review_info));
          } else {
            formData.append("review_info", JSON.stringify(data.reviewInfo));
          }

          // delete reviewInfo from data object before iteration, to avoid key_errors !
          delete data.reviewInfo;

          if (typeof data.images[0] === "object") {
            let thumbnailForm = new FormData();
            let thumbnail = data.images[0].file;
            thumbnailForm.append("image", data.images[0].file);
            thumbnail = await save_thumbnail(thumbnailForm);
            formData.append("thumbnail", JSON.stringify(thumbnail.thumbnail));
          } else {
            formData.append("thumbnail", JSON.stringify(data.thumbnail));
          }

          Object.keys(data).map((key, i) => {
            if (key === "images") {
              formData.append("images", JSON.stringify(imageUrls));
            } else {
              formData.append(key, JSON.stringify(data[key]));
            }
          });

          return await add_business(formData);
        })
        .catch((e) => {
          if (e.status) {
            if (e.status === 413) {
              return {
                type: "error",
                body: "thumbnail image too Large",
                title: "thumbnail image max size 1MB"
              };
            }
          }
          return {
            type: "error",
            body: String(e),
            title: "Error Adding Business",
          };
        });
    });
  };

  const saveProgress = async () => {
    return (async () => {
      const data = {
        ...stored_business,
        review,
        category,
        facilities,
        phone,
        openHours,
        website,
        location,
        description,
        images,
        title,
        plugin,
        last_saved: new Date(),
      };

      await storeBusiness(data);
      return { ...data, reviewInfo, thumbnail };
    })();
  };

  const clearBusiness = async (opts) => {
    let options = opts?.options;

    return (async () => {
      const data = {
        ...stored_business,
        review: [],
        category: [],
        facilities: [],
        phone: [],
        openHours: [],
        website: [],
        location: [],
        description: "",
        images: [],
        title: [],
        // plugin: [],
        last_saved: new Date(),
      };

      if (options?.plugin === true) {
        data["plugin"] = [];
      }
      try {
        delete data.last_saved;
        await storeBusiness(data);
        console.log(
          "------------ CLEARED BUSINESS------",
          Object.keys(stored_business)
        );
        return { ...data, thumbnail: null };
      } catch (e) {}
    })();
  };

  const goToDraft = async () => {
    saveProgress().then(async () => {
      setTimeout(async () => {
        await storeBusiness({
          ...stored_business,
          review,
          category,
          facilities,
          phone,
          website,
          description,
          openHours,
          images,
          title,
          location,
          last_saved: new Date(),
          currentPage: page,
        });
      }, 500);
      await setCurrentPage(page);
    });
  };

  const businessDelete = async (id) => {
    return await deleteBusiness(id)
      .then(async () => {
        deleteServiceImages([...stored_business.images, thumbnail]);
      })
      .then(async () => clearBusiness())
      .then(() => {
        return {
          type: "success",
          body: `Your business account deleted successfully`,
          title: "Delete Successfull!!",
        };
      })
      .catch((e) => {
        if (
          JSON.stringify(e.error)
            .toLowerCase()
            .includes(String("NotFoundError").toLowerCase())
        ) {
          return clearBusiness().then(() => ({
            type: "success",
            body: `Your business account deleted successfully`,
            title: "Delete Successfull!!",
          }));
        }
        return {
          type: "error",
          body: `Your business account was not deleted. Please try again, code: ${e.error}`,
          title: "Delete Failed!!",
        };
      });
  };

  let value = {
    currentPage,
    progress,
    title,
    images,
    category,
    phone,
    website,
    facilities,
    description,
    review,
    acceptedAgreement,
    location,
    openHours,
    stored_business,
    plugin,
    updateLocation,
    setAcceptedAgreement,
    updateReview,
    updateDescription,
    updateFacilities,
    updateWebsite,
    updatePhone,
    updateCategory,
    updateImages,
    updateOpenHours,
    updateTitle,
    goToNextPage,
    goToPrevPage,
    goToDraft,
    goToPage,
    publishBusiness,
    businessDelete,
    saveProgress,
    updateLastSaved,
    clearBusiness,
    setPlugin,
    init_business,
  };

  return (
    <BusinessFormContext.Provider value={value}>
      {children}
    </BusinessFormContext.Provider>
  );
}

export const useBusinessForm = () => {
  return React.useContext(BusinessFormContext);
};

const mapStateToProps = (state) => {
  return {
    stored_business: state.business.payload,
    user: state.user?.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeBusiness: (data) => dispatch(store_business(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);
