import { Listbox, Transition } from '@headlessui/react'
import { SelectorIcon } from '@heroicons/react/outline'
import React, { Fragment, useState } from 'react'

export const DropDownList_SM = ({ list }) => {

  const [selectedInterval, setSelectedInterval] = useState(list ? list[0] : null)

  return (
    <div className='absolute top-2 right-2 text-xs w-32 text-textcolor'>
      <Listbox

        value={selectedInterval} onChange={setSelectedInterval}>
        <Listbox.Button
          className="relative flex justify-between items-center w-full py-2 px-3  text-left  rounded-lg cursor-default focus:outline-none 
                  focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 
                  focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-xs"
        >
          <span className="block truncate">{selectedInterval.value}</span>
          <span className='ml-auto'>
            <SelectorIcon className='w-5 h-5' />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 
                  ring-black ring-opacity-5 focus:outline-none sm:text-xs text-textcolor"
          >
            {list?.map((i) => (
              <Listbox.Option
                key={i.id}
                value={i}
                disabled={i.unavailable}
                className={({ active, disabled }) =>
                  `cursor-default select-none relative py-2 px-3 pr-4 ${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'
                  } ${disabled && 'text-light_textcolor'}`}
              >
                <div onClick={() => !i.unavailable && i.action(i.time)} className='w-full h-full'>{i.value}</div>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  )
}

