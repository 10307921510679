import React, { useEffect, useState } from 'react'
import { BlogBlockTypes } from '..'
import Loader from '../../../../../../assets/spinner.svg'
import SelectImages from '../../../../../../Components/SelectImages'

const ImageBlock = ({ id, addImage, url, setUrl, cancelBlock, saveDraft }) => {
    const [images, setImages] = useState([])
    const [editing, setEditing] = useState(true)
    const [loading, setLoading] = useState(false)


    const saveParagraph = () => {
        setLoading(true)
        setTimeout(() => {
            setEditing(!editing)
            setLoading(false)
            addImage(id, 'images', images)
            saveDraft()
        }, 2000)
    }

    useEffect(() => {
        if (url !== '') {
            setEditing(false)
            if(Array.isArray(url)){
                setImages(url)
            }
        }
    }, [])

    useEffect(() => {
        if (images.length > 0) {
            setUrl(id, images, BlogBlockTypes.IMAGE_BLOCK)
        } else {
            setUrl(id, url.length > 0 ? url : '', BlogBlockTypes.IMAGE_BLOCK)
        }
    }, [images])

    return (
        <div className='w-full'>
            {
                editing ?
                    <div className='w-full'>
                        <div className='w-full flex justify-center items-center'>
                            <div className='w-full md:max-w-lg my-5'>
                                <SelectImages setImages={setImages} images={images} single={true} />
                            </div>
                        </div>
                        <div className='flex items-center justify-end space-x-4 px-5'>
                            <div onClick={() => cancelBlock(id)} className='py-2 px-4 bg-red-500 text-white font-medium rounded-md cursor-pointer'>Cancel </div>
                            <div onClick={() => saveParagraph()} className={`py-2 px-4 ${loading ? 'bg-gray-300' : 'bg-primary-500'} text-white font-medium rounded-md cursor-pointer flex items-center space-x-2`}>
                                {loading && <img src={Loader} className='w-4 h-4 animate-spin' />}
                                <span>Save</span>
                            </div>
                        </div>
                    </div>
                    :
                    <div className=' p-2 md:p-5 py-0 w-full h-auto '>
                        <div onClick={() => setEditing(!editing)} className=' p-2 md:p-5 ease_transition rounded-xl border-dashed border-primary-500 w-full h-auto border-opacity-0 hover:border-opacity-50 border-4'>
                            {Array.isArray(url) ?
                                <img src={url[0].src} className="w-full h-full rounded-lg object-center object-cover" />
                                :
                                <img src={url} className="w-full h-full rounded-lg object-center object-cover" />}
                        </div>
                    </div>
            }
        </div>
    )
}

export default ImageBlock