import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import React, { useEffect } from 'react';
import './style.css';


export default function TimePicker( { activeState, setTime, time, option=null } ) {


    const [_on, setOn] = React.useState(activeState);
    const _hours = [12,1,2,3,4,5,6,7,8,9,10,11];
    const _ap = ['AM','PM'];
    const _minutes = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38];
    // const { minutes, hours, period } = time
    console.log(time)
    const am_pm = time?.period;
    const [showDrop, setShowDrop] = React.useState(false);

    const updateTime = (type, value) => {
        let tmp = time;
        if(!setTime) return
        switch(type){
            case 'minutes':
                tmp['minutes'] = value;
                setTime(option,tmp);
                break;
            case 'period':
                tmp['period'] = value; 
                setTime(option,tmp);
                break;
            case 'hours':
                tmp['hours'] = value;
                setTime(option,tmp);
                break;
            default:
                break;      
        }       
    }

    return (
        <div className=' flex items-center relative text-xs sm:text-base bg-gray-100 text-textcolor justify-between px-4 py-2 border-2 border-gray-300 rounded-xl ' >
            <div className='show-time' onClick={() => setShowDrop(!showDrop)}>{time.hours}:{time.minutes < 10? "0"+time.minutes: time.minutes} {time.period} {showDrop? <ChevronUpIcon className='guide-icon w-4 h-4 sm:w-6 sm:h-6 ml-2 sm:ml-4' />: <ChevronDownIcon className=' w-4 h-4 sm:w-6 sm:h-6 ml-2 sm:ml-4' />}</div>
            {
                showDrop &&
                <div className='flex items-start max-h-32 sm:max-h-64 overflow-hidden z-20 absolute top-10 -left-1 w-full bg-white shadow-md rounded-xl p-2 justify-center'>
                <div className='relative max-h-32 mb-2 sm:max-h-64 overflow-y-auto w-full scrollbar-sm text-center cursor-pointer'>
                    {
                        _hours.map((i,_) => {
                            return (
                                <div className='hover:bg-primary-500 hover:text-white' key={i} onClick={() => updateTime('hours',i)}>{i}</div>
                            )
                        })
                    }
                </div>
                <div className='relative max-h-32 mb-2 sm:max-h-64 overflow-y-auto w-full scrollbar-sm text-center cursor-pointer'>
                {
                        [60, ...(new Array(59))].map((_,i) => {
                            return (
                                <div className='hover:bg-primary-500 hover:text-white' key={i} onClick={() => updateTime('minutes',i)}>{ i}</div>
                            )
                        })
                    }
                </div>
                <div className='relative max-h-32 mb-2 sm:max-h-64 overflow-y-auto w-full scrollbar-sm text-center cursor-pointer'>
                {
                        _ap.map((i,_) => {
                            return (
                                <div className='hover:bg-primary-500 hover:text-white' key={i} onClick={() => updateTime('period',i)}>{i}</div>
                            )
                        })
                    }
                </div>
            </div>}
        </div>
    )
}
