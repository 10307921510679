import React, { useEffect, useState } from 'react'
import { useHousingForm } from '../../../../../../Components/UtilityComponents/HousingFormContext'
import DescriptionForm from '../DescriptionForm'
import FacilitiesForm from '../FacilitiesForm'
import HousingDraftForm from '../HousingDraftForm'
import ImagesForm from '../ImagesForm'
import LivingSpaceForm from '../LivingSpaceForm'
import HousingLocationForm from '../LocationForm'
import PricingForm from '../PricingForm'
import RequirementsForm from '../RequirementsForm'
import ReviewForm from '../ReviewForm'
import TitleForm from '../TitleForm'
import TourForm from '../TourForm'


const ShowActiveHousingForm = (props) => {
    const housing = useHousingForm()
    const [page, setPage] = useState('location')

    useEffect(() => {
        setPage(housing.currentPage)
    }, [housing.currentPage])


    return (
        <div className='h-full w-full'>
            <ActiveForm page={page} housing={housing} {...props} />
            <div className='w-full h-24 block md:hidden' />
        </div>
    )
}

const ActiveForm = ({ page, housing, groupID }) => {


    switch (page.title) {
        case 'location':
            return <HousingLocationForm housing={housing}  />
        case 'images':
            return <ImagesForm housing={housing}  />
        case 'facilities':
            return <FacilitiesForm housing={housing}  />
        case 'living_space':
            return <LivingSpaceForm housing={housing}  />
        case 'pricing':
            return <PricingForm housing={housing}  />
        case 'title':
            return <TitleForm housing={housing}  />
        case 'description':
            return <DescriptionForm housing={housing}  />
        case 'requirements':
            return <RequirementsForm housing={housing}  />
        case 'review':
            return <ReviewForm housing={housing}  />
        case 'tour':
            return <TourForm housing={housing}  />
        default:
            return <HousingDraftForm groupID={groupID} />

    }
}

export default ShowActiveHousingForm