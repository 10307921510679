import { ChevronLeftIcon, MinusIcon } from '@heroicons/react/outline'
import React from 'react'

const ChatWidgetHeader = ({ flat = false, recipient, goBack = null, close = null, online=false }) => {
    return (
        <div className={`flex w-full items-center space-x-3 bg-primary-500 border-b-2 border-white text-white px-4 py-2 ${!flat && 'rounded-t-lg'}`}>
            {goBack && <div className='h-full flex items-center cursor-pointer' onClick={goBack}><ChevronLeftIcon className='w-10 h-10' /></div>}
            <div className='w-14 aspect-square'>
                <img src={recipient?.photoURL} className='w-full h-full rounded-full object-cover' />
            </div>
            <div className='flex w-[80%] flex-col space-y-0 text-left mr-auto'>
                <span className='font-semibold'>{recipient?.author}</span>
                <span className='text-sm'>{online?"online":"offline"}</span>
            </div>
            {
                close &&
                <div onClick={() => close && close(false)} className='w-10 h-10 relative right-0 flex justify-center items-center'>
                    <MinusIcon className='w-5 h-5' />
                </div>
            }
        </div>
    )
}

export default ChatWidgetHeader