import React from 'react'
import AreaGraph from '../../../../../Components/Charts/AreaGraph'
import { useEffect } from 'react'
import { useState } from 'react'
import { load_test_impressions } from '../../../../../functions'
import { ArrowNarrowRightIcon, ArrowNarrowUpIcon, ArrowSmDownIcon, TrendingDownIcon } from '@heroicons/react/outline'
import { ArrowSmUpIcon, TrendingUpIcon } from '@heroicons/react/solid'
import NoImages from '../../../../../assets/no_content.svg'
import Spinner from '../../../../../assets/spinner.svg'

const default_trends = {
  chat_replies: {
    trend: false,
    percentage: 0
  },
  chat_recieved: {
    trend: false,
    percentage: 0
  },
  view_impressions: {
    trend: false,
    percentage: 0
  },
  click_impressions: {
    trend: false,
    percentage: 0
  }
}

const ShowGraph = ({ GROUP_ID, setClicks, setViews, setPhone, setMapViews }) => {

  const today = new Date()
  const endDate = new Date()

  const [data, setData] = useState(null)
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeChart, setActiveChart] = useState(0)
  const [period, setPeriod] = useState(7)
  const [viewLength, setViewLength] = useState(0)
  const [clickLength, setClickLength] = useState(0)
  const [messageStats, setMessageStats] = useState({
    replies: 0,
    recieved: 0
  })

  const [trends, setTrends] = useState(default_trends)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = (period = 7, startPeriod = 0) => {
    setLoading(true)
    setShow(false)
    endDate.setDate(today.getDate() - period)
    if (startPeriod > 0) today.setDate(today.getDate() + startPeriod)
    load_test_impressions({
      id: GROUP_ID, start: today.toISOString(), end: endDate.toISOString()
    }).then((res) => {
      setData(res)

      let _viewLength = 0;
      if (res.view) {
        _viewLength = Object.keys(res.view).reduce((a, b) => {
          a += res.view[b].length
          return a
        }, 0)
      }

      let _clickLength = 0;
      if (res.click) {
        _clickLength = Object.keys(res.click).reduce((a, b) => {
          a += res.click[b].length
          return a
        }, 0)
      }

      let _messageStats = 0;
      if (res.sent_chat) {
        _messageStats = {
          ...messageStats,
          recieved: Object.keys(res.sent_chat).reduce((a, b) => {
            a += res.sent_chat[b].length
            return a
          }, 0)
        }
      }

      let _phoneLength = 0;
      if (res.phone) {
        _phoneLength = Object.keys(res.phone).reduce((a, b) => {
          a += res.phone[b].length
          return a
        }, 0)
      }

      let _mapLength = 0;
      if (res.map_view) {
        _mapLength = Object.keys(res.map_view).reduce((a, b) => {
          a += res.map_view[b].length
          return a
        }, 0)
      }


      const _trends = {
        ...trends,
        chat_recieved: res.last_period_meta_data.sent_chat ? {
          trend: _messageStats.recieved > res.last_period_meta_data.sent_chat,
          percentage: parseFloat((res.last_period_meta_data.sent_chat / _messageStats.recieved) * 100).toFixed(1)
        } : default_trends.chat_recieved,
        view_impressions: res.last_period_meta_data.view ? {
          trend: _viewLength > res.last_period_meta_data.view,
          percentage: parseFloat((res.last_period_meta_data.view / _viewLength) * 100).toFixed(1)
        } : default_trends.view_impressions,
        click_impressions: res.last_period_meta_data.click ? {
          trend: _clickLength > res.last_period_meta_data.click,
          percentage: parseFloat((res.last_period_meta_data.click / _clickLength) * 100).toFixed(1)
        } : default_trends.click_impressions
      }

      setViewLength(_viewLength)

      setMessageStats(_messageStats)

      setClickLength(_clickLength)


      setClicks(_clickLength)
      setViews(_viewLength)
      setPhone(_phoneLength)
      setMapViews(_mapLength)

      setTrends(_trends)
      const d = []
      
      if(res.click){
        d.push(Object.keys(res.click))
      }

      if(res.view){
        d.push(Object.keys(res.view))
      }

      if(res.sent_chat){
        d.push(Object.keys(res.sent_chat))
      }

      let dates = new Set(d)
      dates = Array.from(dates)
      console.log(dates)
      if (dates.length >= 1) {
        setShow(true)
      }

    }).finally(() => {
      setTimeout(() => {
        setLoading(false)
      }, 1000)

    })
  }

  useEffect(() => {

  }, [data])

  const loadPeriod = (a, b) => {
    setPeriod(a)
    loadData(a, b)
  }

  const changeChart = (index) => {
    setActiveChart(index)
  }

  return (
    <div className='h-full w-full hidden md:block p-5 pt-0 bg-white rounded-lg'>
      <div className='flex flex-col items-start px-5 pt-3'>
        <span className='text-md text-textcolor font-medium'>Performance & Metrics</span>
        <span className='text-xs text-light_textcolor'>A visual report on your business performance on Guide</span>
      </div>
      {
        loading ?
          <div className='w-full my-10 flex justify-center items-center'>
            <img src={Spinner} className=" w-12 h-12 animate-spin" alt='guide no reviews notice image' />
          </div>
          :
          show ?
            <div>
              <div className='flex flex-row items-end justify-between w-full p-5 px-0 mb-10 border-b-[1px] border-gray-200'>
                <div className='flex w-full flex-row items-center space-x-4'>
                  <div onClick={() => changeChart(0)} className={`flex flex-col w-[30%] items-start p-3 rounded-md hover:bg-gray-100 cursor-pointer ${activeChart === 0 && "bg-primary-200 border-b-4 border-primary-500"}`}>
                    <span className='font-bold text-primary-500'>Impressions</span>
                    <div className='flex flex-row space-10 items-center justify-between w-full mb-1'>
                      <span className='text-sm text-gray-400 font-medium'>{viewLength} views</span>
                      <div className={`text-xs font-medium flex-row space-10 items-center flex justify-center ${trends.view_impressions.percentage === 0 ? "bg-gray-200 text-gray-600" : trends.view_impressions.trend ? "bg-green-200 text-green-600" : "bg-red-200 text-red-600"} p-[2px] rounded-md`}>
                        {trends.view_impressions.percentage === 0 ? <ArrowNarrowRightIcon className='w-5 h-5' /> : trends.view_impressions.trend ? <TrendingUpIcon className='w-5 h-5' /> : <TrendingDownIcon className='w-5 h-5' />}
                        <span>{trends.view_impressions.percentage}%</span>
                      </div>
                    </div>
                    <div className='flex flex-row space-10 items-center justify-between w-full'>
                      <span className='text-sm text-gray-400 font-medium'>{clickLength} Visits</span>
                      <div className={`text-xs font-medium flex-row space-10 items-center flex justify-center ${trends.click_impressions.percentage === 0 ? "bg-gray-200 text-gray-600" : trends.click_impressions.trend ? "bg-green-200 text-green-600" : "bg-red-200 text-red-600"} p-[2px] rounded-md`}>
                        {trends.click_impressions.percentage === 0 ? <ArrowNarrowRightIcon className='w-5 h-5' /> : trends.click_impressions.trend ? <TrendingUpIcon className='w-5 h-5' /> : <TrendingDownIcon className='w-5 h-5' />}
                        <span>{trends.click_impressions.percentage}%</span></div>
                    </div>
                  </div>
                  <div onClick={() => changeChart(1)} className={`flex flex-col w-[30%] hover:bg-gray-100 items-start p-3 cursor-pointer rounded-md ${activeChart === 1 && "bg-primary-200 border-b-4 border-primary-500"}`}>
                    <span className='font-bold text-primary-500'>Customer Leads</span>
                    <div className='flex flex-row space-10 items-center justify-between w-full mb-1'>
                      <span className='text-sm text-gray-400 font-medium'>{messageStats.recieved} Messages</span>
                      <div className={`text-xs font-medium flex-row space-10 items-center flex justify-center bg-gray-200 text-gray-600 p-[2px] rounded-md ${trends.chat_recieved.percentage === 0 ? "bg-gray-200 text-gray-600" : trends.chat_replies.trend ? "bg-green-200 text-green-600" : "bg-red-200 text-red-600"}`}>
                        {trends.chat_recieved.percentage === 0 ? <ArrowNarrowRightIcon className='w-5 h-5' /> : trends.chat_recieved.trend ? <TrendingUpIcon className='w-5 h-5' /> : <TrendingDownIcon className='w-5 h-5' />}
                        <span>{trends.chat_recieved.percentage}%</span>
                      </div>
                    </div>
                    <div className='flex flex-row space-10 items-center justify-between w-full'>
                      <span className='text-sm text-gray-400 font-medium line-through'>{messageStats.replies} Response Rate</span>
                      <div className={`text-xs font-medium flex-row space-10 items-center flex justify-center bg-gray-200 text-gray-600 p-[2px] rounded-md ${trends.chat_replies.percentage === 0 ? "bg-gray-200 text-gray-600" : trends.chat_replies.trend ? "bg-green-200 text-green-600" : "bg-red-200 text-red-600"}`}>
                        {trends.chat_replies.percentage === 0 ? <ArrowNarrowRightIcon className='w-5 h-5' /> : trends.chat_replies.trend ? <TrendingUpIcon className='w-5 h-5' /> : <TrendingDownIcon className='w-5 h-5' />}
                        <span>{trends.chat_replies.percentage}%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex flex-row justify-between items-center w-[30%] bg-primary-200 h-10 p-1 rounded-md'>
                  <div onClick={() => loadPeriod(7, 0)} className={`cursor-pointer w-[33%] font-medium text-primary-500 h-full flex justify-center items-center ${period === 7 && "rounded-md shadow-sm bg-white"}`}>Weekly</div>
                  <div onClick={() => loadPeriod(31, 0)} className={`cursor-pointer w-[33%] rounded-md h-full text-gray-400 flex justify-center items-center ${period === 31 && "rounded-md shadow-sm bg-white"}`}>Monthly</div>
                  <div onClick={() => loadPeriod(365, 0)} className={`cursor-pointer w-[33%] rounded-md h-full text-gray-400 flex justify-center items-center ${period === 365 && "rounded-md shadow-sm bg-white"}`}>Yearly</div>
                </div>
              </div>
              <div>
                <AreaGraph data={data} index={activeChart} />
              </div>

            </div>
            :
            <div className=' flex flex-col w-full items-center justify-center space-y-3 pb-5'>
              <img src={NoImages} className=" w-32 h-32" alt='guide no reviews notice image' />
              <div>
                <div className='text-lg font-medium text-gray-500'>Not Enough Data</div>
                <div className='text-xs text-gray-400'>Your business have not yet gotten enough metrics to generate a report.</div>
              </div>
            </div>

      }

    </div>
  )
}

export default ShowGraph