
export const MODE = process.env.REACT_APP_MODE
export const REACT_GA_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID

export const AUTH_SERVER_URL = MODE === 'DEV' ? 'http://localhost:5000' : MODE === 'PROD' ? 'https://auth.guideutility.co'
    : MODE === 'PROD_V2' ? 'https://auth.guideutility.co' : ''

export const GEO_SERVER_URL = MODE === 'DEV' ? 'http://localhost:5030' :  'https://geo.guideutility.co'


export const CHAT_SERVER_URL = MODE === 'DEV' ? 'http://localhost:5031' : 'https://chat.guideutility.co'
   
export const APP_URL = MODE === 'DEV' ? 'http://localhost:3000' :'https://guideutility.co'
   

export const PLUGIN_SERVER_URL = MODE === 'DEV' ? 'http://localhost:3453' :"https://plugin.guideutility.co"
export const HUB_URL = MODE === 'DEV' ? 'http://localhost:3000' :"https://hub.guideutility.co"

