import * as actions from '../../actionTypes/timetableActionTypes'

export const store_timetable = (table) => {
    return (dispatch) => {
        dispatch({
            type: actions.STORE_TIMETABLE,
            payload: table.length > 0? table : null,
            error: null
        })
    }
}

export const update_timetable = (table) => {
    return (dispatch) => {
        dispatch({
            type: actions.UPDATE_TIMETABLE,
            payload: table,
            error: null
        })
    }
}

export const clear_timetable = () => {
    return (dispatch) => {
        dispatch({
            type: actions.CLEAR_TIMETABLE,
            payload: null,
            error: null
        })
    }
}