import { STORE_SERVICE, UPDATE_SERVICE } from "../../actionTypes/serviceActionTypes"

export const store_services = (data) => {
    return (dispatch) => {
        dispatch({
            type: STORE_SERVICE,
            payload: data,
            error: null
        })
    }
}

export const update_services = (data) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_SERVICE,
            payload: data,
            error: null
        })
    }
}