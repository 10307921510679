import React, { useEffect, useState } from 'react'
import { StarIcon } from '@heroicons/react/solid'
import { loadAllServices, loadReviewsStars, updateServiceImpressions } from '../../../functions/service_functions.js'
import { getAllRatings } from '../../Dashboard/Screens/Main/Cards/DashboardReviewCard/index.js'
import ImpressionClickTrackerHOC from '../../../Components/ImpressionClickTrackerHOC/index.js'


const FindHelpers = () => {

    const [services, setServices] = useState(null)

    useEffect(() => {
        loadAllServices(null).then(res => {
            setServices(res)
        })
    }, [])

    useEffect(() => {
        // console.log('sss', services)
    }, [services])



    return (
        <div className='w-full flex flex-col items-center'>
            <div className='text-2xl font-bold py-10'>Featured Helpers In Your Area</div>
            <div className='grid space-y-4 grid-cols-1 md:grid-cols-3 md:space-y-0 w-full px-2 md:px-0 md:w-[70%] sm:gap-10 '>
                {
                    services ?
                        services?.map((service, indx) => {
                            return <HelperCard key={indx} service={service} />
                        })
                        :
                        [0, 0, 0].map((_, i) => {
                            return <HelperCardLoadingCard key={i} />
                        })
                }

            </div>
        </div>
    )
}


export const HelperCard = ({ service }) => {

    const [ratinP, setratinP] = useState(0)

    const ratingPerc = async (ratings) => {
        const all_ratings = await loadReviewsStars({ gid: service.id })
        setratinP(parseInt(((all_ratings.fours + all_ratings.fives) / all_ratings.sum) * 100))
    }

    useEffect(() => {
        ratingPerc(service.ratings)
    }, [])

    useEffect(() => {

    }, [ratinP])

    const handleImpression = () => {
        updateServiceImpressions({
            id: service.id,
            type: 'view'
        })
    }

    return (
        <ImpressionClickTrackerHOC disableClick={true} onImpression={handleImpression}>
            <div className='w-full bg-white rounded-lg p-5 shadow-md ring-1 ring-black/10 text-left space-y-4'>
                <div className='flex space-x-4 justify-start items-center'>
                    <div className='w-20 aspect-square'>
                        <img src={service.user.photoURL} className='w-full h-full object-cover rounded-md' />
                    </div>
                    <div>
                        <div className='text-lg font-semibold'>{service.user.display_name}</div>
                        <div className='flex items-center space-x-2'>
                            <StarIcon className='w-5 h-5 text-yellow-500' />
                            <div>{ratinP | 0}% Positive Reviews</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='text-lg font-bold'>Services</div>
                    {
                        service.skillsets.map((skill, indx) => {
                            return (
                                <div key={indx} className=' font-medium text-sm flex items-center justify-between'>
                                    <div className=' capitalize'>{skill.id.split('_').join(" ")}</div>
                                    <div>{skill.rates.currency.symbol} {parseFloat(skill.rates.rates).toFixed(2)}</div>
                                </div>
                            )
                        })
                    }

                </div>
                <div className='w-full'>
                    <div className='font-semibold'>I'm the right person for the job:</div>
                    <div className='text-sm'>{service.description.length > 100 ? service.description.slice(0, 100) + '...' : service.description}</div>
                </div>
            </div>
        </ImpressionClickTrackerHOC>
    )
}

const HelperCardLoadingCard = () => {
    return (
        <div className='w-full text-left space-y-4'>
            <div className='flex space-x-4 justify-start items-center'>
                <div className='w-20 aspect-square'>
                    <div className='w-full h-full object-cover bg-gray-300 animate-pulse rounded-md' />
                </div>
                <div className='space-y-2'>
                    <div className='w-52 h-2 bg-gray-300 animate-pulse font-semibold' />
                    <div className='flex items-center space-x-2'>
                        <div className='w-5 h-5 bg-gray-300 animate-pulse text-yellow-500' />
                        <div className='w-32 h-2 bg-gray-300 animate-pulse' />
                    </div>
                </div>
            </div>
            <div className='space-y-2'>
                <div className='w-full h-2 bg-gray-300 animate-pulse' />
                <div className='w-full h-2 bg-gray-300 animate-pulse' />
                <div className='w-full h-2 bg-gray-300 animate-pulse' />
                <div className='w-full h-2 bg-gray-300 animate-pulse' />
            </div>

        </div>
    )
}


export default FindHelpers