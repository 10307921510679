import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import React from 'react'

function NumberField({ title, activeState, setActiveState, none_zero = false }) {

    const updateCount = (btn) => {
        if (btn === 'up') {
            setActiveState(activeState + 1)
        } else {
            if ((none_zero && activeState === 1)) return
            if (!none_zero && activeState === 0) return
            setActiveState(activeState - 1)
        }
    }

    return (
        <div className="flex w-fit flex-col items-center space-y-2">
            <div className="text-base font-medium">{title}</div>
            <div className="flex flex-col justify-between items-center">
                <div className="bg-primary-500 py-2 px-4 rounded-t-md" onClick={() => updateCount('up')}>
                    <ChevronUpIcon className='w-5 h-5 text-white' />
                </div>
                {activeState}
                <div className="bg-primary-500 py-2 px-4 rounded-b-md" onClick={() => updateCount('down')}>
                    <ChevronDownIcon className='w-5 h-5 text-white' />
                </div>
            </div>
        </div>
    )
}

export default NumberField
