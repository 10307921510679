import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { CHAT_SERVER_URL } from "../../../../assets/url";
import ChatWidgetBody from "../../../../Components/ChatWidget/ChatWidgetBody";
import ChatWidgetHeader from "../../../../Components/ChatWidget/ChatWidgetHeader";
import ChatWidgetInput from "../../../../Components/ChatWidget/ChatWidgetInput";
import { useChat } from "../../../../Components/UtilityComponents/ChatsContext";
import { load_chat_messages } from "../../../../functions/chats_functions";
import useSound from "use-sound";
import PopIn from "../../../../assets/popsound.wav";
import ChatRequests from "./ChatRequestsList";
import { task_icons } from "./constants";

const Chats = ({ user }) => {
  const [chats, setChats] = useState(null);
  const [chatRecipient, setChatReciepient] = useState(null);
  const [messages, setMessages] = useState([]);
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");
  const [roomData, setRoomData] = useState(null);
  const [chatListPage, setChatListPage] = useState(0);
  const [typingUsers, setTypingUsers] = useState([]);

  const [playin] = useSound(PopIn, { volume: 0.2 });

  const socketRef = useChat();
  const socket = socketRef.socket;

  useEffect(() => {
    fetch(`${CHAT_SERVER_URL}/get_chat_list/${user.uid}`, {
      method: "GET",
    })
      .then(async (result) => {
        setChats(await result.json());
      })
      .then(() => {});

    return () => {
      setMessages([]);
      setFiles([]);
      setChats([]);
      setChatReciepient([]);
      setMessage("");
      setRoomData([]);
      socket.off("receive_message");
      socket.off("typing_message");
      socket.off("updated_chat_list");
      socketRef.notifications_channel.resumeUserNotificationChannel();
      roomData && socketRef.left_room({ ...roomData, uid: user.uid });
    };
  }, []);

  useEffect(() => {
    socket.on("receive_message", async (data) => {
      playin();
      setMessages((list) =>
        !messages.some((n) => n.id === data.id) ? [...list, data] : list
      );
    });

    socket.on("typing_message", (data) => {
      setTypingUsers(data);
    });

    socket.on("updated_chat_list", async (list) => {
      if (list[user.uid]) {
        console.log("UPDATED CHAT LIST", list[user.uid]);
        setChats(list[user.uid]);
      }
    });
  }, [socket]);

  useEffect(() => {}, [messages, typingUsers, chatRecipient]);

  useEffect(() => {
    console.log(chats);
    chats &&
      socketRef.setHasUnreadMessage(chats?.some((user) => user.counter > 0));
  }, [chats]);

  const onSendMessage = () => {
    const { room, sender } = roomData;
    const msg = message;
    socketRef
      .sendMessage({
        room,
        sender,
        message: msg,
        files,
      })
      .then((messageData) => {
        if (files) {
          messageData["images"] = files.map((file) => ({
            original: URL.createObjectURL(file.file),
            thumbnail: URL.createObjectURL(file.file),
          }));
        }
        setMessages((list) => list && [...list, messageData]);
        setMessage("");
        setFiles([]);
        setChats([
          ...chats.filter((i) => i.room === roomData.room),
          ...chats.filter((i) => i.room !== roomData.room),
        ]);
      })
      .then(() => {
        socket.emit("push_notification", {
          ...chats.find((user) => user.room === roomData?.room),
          lastmessage: msg,
          from: user.uid,
        });
      });
  };

  useEffect(() => {}, [chatRecipient]);

  const chatSender = {
    photoURL: user.photoURL,
    uid: user.uid,
    author: user.display_name,
  };

  const getRoomInfo = (room) => {
    const info = chats.find((user) => user.room === room && user.counter > 0);
    return info ? info : false;
  };

  const getOnineStatus = (uid) => {
    const onlineUser = socketRef.onlineUsers.find(
      (user) => user.userID === uid
    );
    return onlineUser ? true : false;
  };

  const getTypingStatus = (uid) => {
    const typingUser = typingUsers.find(
      (user) => user.userID === uid && user.typing === true
    );
    return typingUser ? true : false;
  };

  const clearCounter = async (room, uid, chat) => {
    console.log("CLEARING COUNTER");
    const chatInterest = await chats.find((user) => user.room === room);
    await socket.emit("reset_chat_list_counter", {
      user: uid,
      room: chat.room,
      chat: chatInterest,
    });
    const tmp = [];
    if (!chatInterest) return;
    chatInterest["counter"] = 0;
    chatInterest["unread"] = false;
    chats.map((i, indx) => {
      if (i.room == room) {
        tmp.push(chatInterest);
      } else {
        tmp.push(i);
      }
    });
    setChats(tmp);
    return null;
  };

  return (
    <div className="flex items-start space-x-2 lg:divide-x-2 h-[100vh] overflow-y-hidden text-sm md:text-base">
      <div
        className={`  ${
          roomData ? "hidden lg:block lg:w-[35%]" : "block w-full"
        } `}
      >
        <div className="flex items-center divide-x-2 divide-white w-full">
          <div
            onClick={() => setChatListPage(0)}
            className={` cursor-pointer ${
              chatListPage == 0
                ? "bg-primary-800"
                : "bg-primary-500 hover:bg-primary-800"
            } w-[50%] text-center py-4 text-white font-semibold`}
          >
            Chats
          </div>
          <div
            onClick={() => setChatListPage(1)}
            className={` cursor-pointer ${
              chatListPage == 1
                ? "bg-primary-800"
                : "bg-primary-500 hover:bg-primary-800"
            } w-[50%] text-center py-4 text-white font-semibold`}
          >
            Requests
          </div>
        </div>

        <div className="py-4 text-xl font-bold px-5">
          {chatListPage === 0 ? "Chats" : "Chat Requests"}
        </div>

        {chatListPage === 0 ? (
          <div className="divide-y-2 h-[80vh] overflow-y-auto space-y-4 px-2">
            {chats ? (
              chats.length > 0 ? (
                chats?.map((chat, indx) => {
                  return (
                    <ChatItem
                      key={indx}
                      clearCounter={clearCounter}
                      room={chat.room}
                      typingUsers={typingUsers}
                      socketRef={socketRef}
                      getInfo={getRoomInfo}
                      allOnlineUsers={socketRef.onlineUsers}
                      getTypingStatus={getTypingStatus}
                      onlineStatus={getOnineStatus}
                      setChatReciepient={setChatReciepient}
                      chat={chat}
                      uid={user.uid}
                      setMessages={setMessages}
                      setRoomData={setRoomData}
                      roomData={roomData}
                      sender={chatSender}
                      socket={socket}
                    />
                  );
                })
              ) : (
                <div className="w-full text-center text-lg text-gray-400 pt-[25vh]">
                  No Chats
                </div>
              )
            ) : (
              [0, 0, 0, 0, 0].map((_, i) => {
                return <ChatItemLoading key={i} />;
              })
            )}
          </div>
        ) : chatListPage === 1 ? (
          <div>
            <ChatRequests
              roomData={roomData}
              setChatReciepient={setChatReciepient}
              user={user}
              setMessages={setMessages}
              setRoomData={setRoomData}
              sender={chatSender}
              socket={socket}
            />
          </div>
        ) : null}
      </div>
      {roomData && (
        <div className="w-full lg:w-[65%] h-full inset-0 p-0 md:p-2 flex flex-col">
          <div className="h-[90%] w-full overflow-y-hidden rounded-lg">
            <div className="w-full flex lg:hidden">
              {chatRecipient && (
                <ChatWidgetHeader
                  flat={true}
                  recipient={chatRecipient}
                  online={getOnineStatus(chatRecipient?.uid)}
                  goBack={() => {
                    socketRef.notifications_channel
                      .resumeUserNotificationChannel()
                      .then(() => {
                        socketRef.left_room({ ...roomData, uid: user.uid });
                        setRoomData(null);
                      });
                  }}
                />
              )}
            </div>
            <div className="w-full h-[90%] lg:h-full ">
              {messages && (
                <ChatWidgetBody
                  fullHeight={true}
                  chats={messages}
                  room={roomData.room}
                  sender={user}
                  files={files}
                  setFiles={setFiles}
                />
              )}
              
            </div>
          </div>
          <div className="w-full">
            <ChatWidgetInput
              onSend={onSendMessage}
              room={{ room: roomData.room }}
              user={user}
              socket={socket}
              setMessage={setMessage}
              message={message}
              files={files}
              setFiles={setFiles}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user?.data?.user,
  };
};
export default connect(mapStateToProps)(Chats);

const ChatItem = ({
  chat,
  socketRef,
  room,
  clearCounter,
  allOnlineUsers,
  getInfo,
  typingUsers,
  getTypingStatus,
  onlineStatus,
  uid,
  setChatReciepient,
  sender,
  setMessages,
  setRoomData,
  roomData,
  socket,
}) => {
  const [user, setUser] = useState(null);
  const [info, setInfo] = useState(false);
  const [online_Status, setOnlineStatus] = useState(false);
  const [typing_Status, setTypingStatus] = useState(false);
  const [lastMessage, setlastmessage] = useState("");

  useEffect(() => {
    (async () => {
      if (roomData?.room === room) {
        await clearCounter(room, uid, chat);
      }
      setUser(chat.sender?.uid === uid ? chat.reciever : chat.sender);
    })();
    return () => {
      setUser(null);
    };
  }, [chat]);

  const loadMessages = (dat) => {
    setChatReciepient(dat);
    socketRef.notifications_channel
      .muteUserNotificationChannel(user.uid)
      .then(() => {
        clearCounter(chat.room, uid, chat).then(() => {
          socketRef.enter_room({ ...chat, uid });
          setInfo(false);
          load_chat_messages(chat.room).then((res) => {
            socket.emit("join_room", chat);
            setMessages(res);
            setRoomData({
              room: chat.room,
              sender: sender,
            });
          });
        });
      });
  };

  const checkOnline = () => {
    const status = onlineStatus(user?.uid);
    setInfo(getInfo(room));
    setOnlineStatus(status);
  };

  const checkTyping = () => {
    const status = getTypingStatus(user?.uid);
    setTypingStatus(status);
  };

  useEffect(() => {
    checkOnline();
    checkTyping();
  }, [user, allOnlineUsers, typingUsers]);

  useEffect(() => {
    setlastmessage(
      typeof info.lastmessage === "object"
        ? `${task_icons[info.lastmessage.type]} ORDER ${info.lastmessage.type
            .split("_")
            .join(" ")}`
        : info.lastmessage
    );
  }, [info, chat]);

  useEffect(() => {
    console.log(lastMessage, info.lastmessage, "++++++");
  }, [lastMessage]);

  return (
    user ? (
      <div
        onClick={() => loadMessages(user)}
        className={`flex ${
          roomData && roomData?.room === chat.room
            ? "bg-primary-500"
            : "bg-white"
        } w-full px-5 rounded-3xl shadow-lg items-center space-x-4 py-4 cursor-pointer`}
      >
        <div className="">
          <img
            src={user.photoURL}
            className="w-20 aspect-square  rounded-full object-cover"
          />
        </div>
        <div className="w-[90%] flex items-center space-x-2">
          <div className="w-[90%] flex flex-col">
            <div
              className={`${
                roomData && roomData?.room === chat.room
                  ? "text-white"
                  : "text-black/70"
              } font-semibold`}
            >
              {user.author}
            </div>
            <div
              className={` ${
                roomData && roomData?.room === chat.room
                  ? "text-white"
                  : "text-black/70"
              } ${typing_Status ? "italic" : ""}`}
            >
              {typing_Status
                ? "typing..."
                : info
                ? lastMessage
                : online_Status
                ? "online"
                : "offline"}
            </div>
          </div>
          {info && roomData?.room !== chat.room && (
            <div className="text-sm w-6 h-6 font-bold flex justify-center items-center aspect-square rounded-full text-white bg-primary-500">
              {info.counter}
            </div>
          )}
        </div>
      </div>
    ): (<div></div>)
  );
};

const ChatItemLoading = () => {
  return (
    <div className="flex bg-white w-full px-5 rounded-3xl shadow-lg items-center space-x-4 py-4 cursor-pointer">
      <div className="w-20 aspect-square  rounded-full bg-gray-200 animate-pulse" />
      <div className="w-[90%] flex items-center space-x-2">
        <div className="w-[90%] flex space-y-2 flex-col">
          <div className="font-semibold w-[60%] h-3 bg-gray-200 animate-pulse" />
          <div className="w-[40%] h-2 bg-gray-200 animate-pulse" />
        </div>
      </div>
    </div>
  );
};
