import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  BarElement,
  LinearScale,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  LinearScale,
  CategoryScale,
  BarElement,
  Filler,
  Tooltip,
  Legend
);

const AreaGraph = (props) => {
  const [_data, setData] = useState(null);
  const { data, index } = props;

  useEffect(() => {
    loadUp(index);
  }, [data, index]);

  useEffect(() => {}, [_data]);

  const test = {
    labels: [
      "Thing 1",
      "Thing 2",
      "Thing 3",
      "Thing 4",
      "Thing 5",
      "Thing 6",
      "Thing 7",
    ],
    datasets: [
      {
        label: "# of Views",
        data: [2, 9, 3, 5, 2, 3, 12],
        backgroundColor: "#8d29ffd9",
        pointBorderColor: "#8d29ffd9",
        borderColor: "#8d29ffd9",
        pointBorderWidth: 5,
        borderWidth: 3,
        tension: 0.4,
      },
      {
        label: "# of Clicks",
        data: [7, 3.2, 6.3, 9, 12, 1.5, 6.3],
        backgroundColor: "#fb8f8f",
        pointBorderColor: "#fb8f8f",
        borderColor: "#fb8f8f",
        pointBorderWidth: 5,
        borderWidth: 3,
        tension: 0.4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        align: "end",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: true,
      },
      y: {
        min: 0,
        stacked: true,
        ticks: {
          stepSize: 10,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  const loadUp = (index) => {
    let tmp_data = {
      labels: [],
      datasets: [],
    };
    if (data && Object.keys(data).length > 0) {
      console.log(data);

      if (index === 0) {
        let uniquedates = null;
        let tmp_unique_dates = [];

        if (data.click) {
          tmp_unique_dates = [...tmp_unique_dates, ...Object.keys(data.click)];
        }
        console.log("1", tmp_unique_dates);

        if (data.view) {
          tmp_unique_dates = [...tmp_unique_dates, ...Object.keys(data.view)];
        }

        console.log("2", tmp_unique_dates);

        uniquedates = Array.from(new Set(tmp_unique_dates));

        console.log("3", uniquedates);

        tmp_data["labels"] = uniquedates
          .sort((a, b) => (a > b ? 1 : -1))
          .map((i) => moment(i).format("ll").split(",")[0]);

        tmp_data["datasets"] = [];

        if (data.view) {
          tmp_data["datasets"].push({
            label: "Views",
            data: Object.keys(data.view)
              .reverse()
              .map((i) => data.view[i].length),
            backgroundColor: "#8d29ff",
            pointBorderColor: "#8d29ff",
            borderColor: "#8d29ff",
            pointBorderWidth: 5,
            borderWidth: 0,
            borderRadius: 5,
            tension: 0.4,
          });
        }

        if (data.click) {
          tmp_data["datasets"].push({
            label: "Page Visits",
            data: Object.keys(data.click)
              .reverse()
              .map((i) => data.click[i].length),
            backgroundColor: "#c291fd69",
            pointBorderColor: "#c291fd69",
            borderColor: "#c291fd69",
            pointBorderWidth: 5,
            borderWidth: 0,
            borderRadius: 5,
            tension: 0.4,
          });
        }

        console.log(tmp_data);
        setData(tmp_data);
      } else {
        let uniquedates = [];

        if (data.sent_chat && data.sent_chat != undefined) {
          uniquedates = new Set([...Object.keys(data.sent_chat)]);
          uniquedates = Array.from(uniquedates);

          tmp_data["labels"] = uniquedates
            .sort((a, b) => (a > b ? 1 : -1))
            .map((i) => moment(i).format("ll").split(",")[0]);

          tmp_data["datasets"] = [
            {
              label: "Views",
              data: Object.keys(data.sent_chat)
                .reverse()
                .map((i) => data.sent_chat[i].length),
              backgroundColor: "#fb8f8f",
              pointBorderColor: "#fb8f8f",
              borderColor: "#fb8f8f",
              pointBorderWidth: 5,
              borderWidth: 3,
              tension: 0.4,
            },
          ];
          console.log(tmp_data);
          setData(tmp_data);
        }
      }
    }
  };

  if (index == 0) {
    return _data ? <Bar height={75} data={_data} options={options} /> : null;
  } else {
    return <Line height={75} data={_data} options={options} />;
  }
};

export default AreaGraph;
