import React from 'react'
import Ratings from 'react-ratings-declarative/build/ratings'

const FiveStar = ({ value, count, color="#F2C94C" }) => {
    return (
        <div className='flex text-sm space-x-2'>
            <Ratings
                rating={value}
                widgetDimensions="16px"
                widgetSpacings="auto"
                widgetRatedColors={color}
            >
                <Ratings.Widget />
                <Ratings.Widget />
                <Ratings.Widget />
                <Ratings.Widget />
                <Ratings.Widget />
            </Ratings>
            <div>({value})</div>
        </div>
    )
}

export default FiveStar