import { ChartBarIcon, CursorClickIcon, EyeIcon, PhoneIcon } from '@heroicons/react/outline'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import WhatsappIcon from '../../../../assets/WhatsappIcon/index.js'
import { abbreviateNumber, fetchUserGroup } from '../../../../functions/service_functions.js'
import SocialsViewCard from '../../Screens/Main/Cards/SocialsViewCard/index.js'


const intervals = [
    { id: 1, value: '30 seconds', unavailable: false },
    { id: 2, value: '30 minutes', unavailable: false },
    { id: 3, value: 'hour', unavailable: false },
    { id: 4, value: 'day', unavailable: true },
    { id: 5, value: 'month', unavailable: false },
]

const DashboardGroupMetricsView = ({ gid, type, showSocial = false }) => {

    const [impression, setImpression] = useState(0)
    const [phone, setPhone] = useState(0)
    const [clicks, setClicks] = useState(0)
    const [whastapp, setWhatsapp] = useState(0)
    const [insta, setInsta] = useState(0)

    useEffect(() => {
        fetchUserGroup(gid).then((info) => {
            setImpression(info.impressions.view | 0)
            setPhone(info.impressions.phone | 0)
            setClicks(info.impressions.click | 0)
            setWhatsapp(info.impressions.whatsapp | 0)
            setInsta(info.impressions.insta | 0)
        })
    }, [])

    return (
        <div className='w-full space-y-4'>
            <div className='w-full h-fit'>
                <SocialsViewCard title={{ head: 'Analytics', sub: `your ${type} account performance` }} showList={false} list={intervals}  >
                    <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6'>
                        <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                            <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center font-medium text-gray-600'>
                                {abbreviateNumber(impression)}
                            </div>
                            <div className='flex flex-col items-start justify-center text-textcolor'>
                                <div className='font-bold text-sm xl:text-normal flex space-x-2 items-center'>
                                    <ChartBarIcon className='guide-icon w-5 h-5 text-textcolor' />
                                    <span>Impressions</span>
                                </div>
                                <span className='text-xs xl:text-small'>The estimated number of times your {type} were displayed onscreen.</span>
                            </div>
                        </div>
                        <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                            <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center'>
                                {abbreviateNumber(clicks)}
                            </div>
                            <div className='flex flex-col items-start justify-center text-textcolor'>
                                <span className='font-bold text-sm xl:text-normal flex space-x-2 items-center'>
                                    <CursorClickIcon className='guide-icon w-5 h-5 text-textcolor' />
                                    <span>Clicks</span>
                                </span>
                                <span className='text-xs xl:text-small text-left'>The estimated number of times your {type} were clicked.</span>
                            </div>
                        </div>
                    </div>
                </SocialsViewCard>
            </div>
            {
                showSocial &&
                <div>
                    <SocialsViewCard showList={false} title={{ head: 'Socials', sub: 'social metrics' }} list={intervals} >
                        <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4'>
                            <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                                <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center'>
                                    {abbreviateNumber(whastapp)}
                                </div>
                                <div className='flex flex-col items-start justify-center text-textcolor'>
                                    <span className='font-bold text-sm xl:text-normal flex space-x-2 items-center'>
                                        <WhatsappIcon className='guide-icon w-4 h-4 text-textcolor' />
                                        <span>Whatsapp</span>
                                    </span>
                                    <span className='text-xs xl:text-small text-left'>The estimated number of times your whatsapp contact was clicked.</span>
                                </div>
                            </div>
                            <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                                <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center'>
                                    {abbreviateNumber(phone)}
                                </div>
                                <div className='flex flex-col items-start justify-center text-textcolor'>
                                    <span className='font-bold text-sm xl:text-normal flex space-x-2 items-center'>
                                        <PhoneIcon className='guide-icon w-5 h-5 text-textcolor' />
                                        <span>Phone</span>
                                    </span>
                                    <span className='text-xs xl:text-small text-left'>The estimated number of times your phone contact was clicked.</span>
                                </div>
                            </div>
                            <div className='flex space-x-2 w-full md:w-fit justify-start items-center'>
                                <div className='flex w-16 h-16 aspect-square rounded-xl border-2 border-gray-300 justify-center items-center'>
                                    {abbreviateNumber(insta)}
                                </div>
                                <div className='flex flex-col items-start justify-center text-textcolor'>
                                    <span className='font-bold text-sm xl:text-normal flex space-x-2 items-center'>
                                        <PhoneIcon className='guide-icon w-5 h-5 text-textcolor' />
                                        <span>Instagram</span>
                                    </span>
                                    <span className='text-xs xl:text-small text-left'>The estimated number of instagram contact clicks.</span>
                                </div>
                            </div>
                        </div>
                    </SocialsViewCard>
                </div>}
        </div>
    )
}

export default DashboardGroupMetricsView