import { del_fetch, get_fetch, post_fetch } from "../../Pages/Dashboard/Screens/Timetable/functions"

export const update_business = async (data) => {
    return await post_fetch('api/user/update_business', data)
}

export const add_business = async (data) => {
    return await post_fetch('api/user/business', data, null, {data_type:'form', content_type:null})
}

export const load_business = async (id) => {
    return await get_fetch(`api/user/business?id=${id}`)
}

export const deleteBusiness = async (id) => {
    return await del_fetch(`api/user/business?id=${id}`)
}