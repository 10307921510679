import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../../../Components/UtilityComponents/AuthProvider'
import { useSuzi } from '../../../../../../Components/Assistant'
import { useAlert } from '../../../../../../Components/UtilityComponents/ErrorContext'
import DeleteConfirmationDialog from '../../../../../../Components/Alerts/DeleteConfirmationDialog'
import { pages } from '../../../Main/SubScreens/Settings/Forms/data'

const PreviewServiceProfile = ({ services, loading, setLoading, service_id }) => {

    const [preview, setPreview] = useState(null)
    const [url, setUrl] = useState(null)
    const [image, setImage] = useState(null)
    const [open, setOpen] = useState(false)

    const auth = useAuth()
    const alert = useAlert()


    useEffect(() => {
        setPreview(services)
        if (services.stored_services?.images?.length > 0) {
            setImage(services.stored_services.images[0].src ? services.stored_services.images[0].src : services.stored_services.images[0])
        } else {
            setImage('https://media.istockphoto.com/vectors/linear-sketch-of-an-interior-hand-drawn-vector-illustration-of-a-vector-id674555508?k=20&m=674555508&s=612x612&w=0&h=zaIn7zfr917HcVQEOB4sordnlArT-lAJGgi6NltR0bo=')
        }
    }, [services])

    useEffect(() => {

    }, [loading, url])

    const handlePublish = () => {
        const {stored_services} = services
        let cheksout = true
        pages.forEach(page => {
            let data = stored_services ? stored_services[page.title] : []
            if (Array.isArray(data) && data.length <= 0) {
                alert.setalert({ title: "Incomplete Setup", body: 'you must provide all information to be able to publish.', type: 'error' })
                cheksout = false
                return false
            }
            if (page.title === 'description' && data === '') {
                alert.setalert({ title: "Incomplete Setup", body: 'you must provide all information to be able to publish.', type: 'error' })
                cheksout = false
                return false
            }
            return true
        })

        if(cheksout === false) return

        setLoading(true)
        services.publishServices().then((res) => {
            if(!res || res === undefined){
                alert.setalert({type:'error', body:'Something went wrong, try again.', title:'Network Error'})   
                return 
            }

            if(res?.type && res?.type === 'error' ){
                alert.setalert(res)
                setLoading(false)
                return
            }
            setLoading(false)
            setUrl(`/services/${service_id}`)
            alert.setalert({ title: "Published!", body: 'your service account has been published and is now live.', type: 'success' })
        })
        .catch((e) => console.log('ERROR: ', e))
        .finally(() => setLoading(false))
    }

    const handleDeleteService = async () => {
        services.serviceDelete(service_id)
    }

    return services.stored_services?.last_saved ? (
        <div className='fixed bottom-5 right-5 sm:bottom-10 sm:right-10 flex flex-col md:flex-row  items-end space-x-10 max-w-xl xl:max-w-2xl'>
            <div className='flex items-end space-x-4 pl-10 md:pl-0'>

                <div onClick={() => setOpen(true)} className='font-semibold underline text-red-500 hover:text-red-700 cursor-pointer'>Delete</div>
                <div onClick={() => !loading && handlePublish()} className={`font-semibold  underline ${loading ? 'text-gray-400 cursor-wait' : 'text-blue-500 hover:text-blue-700 cursor-pointer'}`}>Publish</div>
            </div>
            <div className='flex items-start max-w-md  shadow-lg bg-white  p-4 rounded-lg '>
                <div className='flex-1 px-2 flex flex-col justify-start py-5 space-y-2'>
                    <Link to={`/draft_services/${service_id}`} className='text-md font-semibold underline cursor-pointer pb-2 text-xs md:text-lg'>Preview Your Profile</Link>
                    {services.stored_services.last_saved && <div className='text-xs overflow-clip inline-block'>last update:  {moment(services.stored_services.last_saved).format("MMM Do YY")}</div>}
                   { !url && <div className='font-medium text-sm text-gray-700 flex'>Status:  <span className='font-light px-3'>  draft</span></div>}
                   {url && <Link to={url} className='text-md font-semibold underline cursor-pointer pb-2 text-xs md:text-lg'>Live Preview</Link>}
                </div>
                <div className='w-20 sm:w-[40%] h-auto aspect-square rounded-lg overflow-hidden'>
                    <img src={image} className="object-cover w-full h-full" alt="guide service preview image" />
                </div>
            </div>
            <DeleteConfirmationDialog
                item_type="Profile"
                title='Service'
                open={open}
                setOpen={setOpen}
                callback={handleDeleteService}
                setLoading={() => null}
            />
        </div>
    ) : null
}

export default PreviewServiceProfile