import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import DashboardFormField from '../../../../DashboardComponents/DashboardFormField'
import TipsCard from '../../../../DashboardComponents/FormComponents/TipsCard'

const TitleForm = ({housing}) => {
    const [title, setTitle] = useState('')

    useEffect(() => {
        setTitle(housing.stored_housing?.title)
    },[])

    useEffect(() => {
        housing.updateTitle(title)
    },[title])

    return (
        <div className='flex h-auto items-start justify-start w-full'>
            <div className='py-5 w-full md:w-[60%] '>
                <div>
                    <div className='max-w-lg'>
                        <DashboardFormField rows={10} name="title" placeholder="e.g 2+1 apartment in Estate 1" maxLength={100} text={title} setText={setTitle} />
                    </div>
                    <div className='max-w-lg flex justify-end px-5'>{title.length} / <b> 100</b></div>
                </div>
            </div>
            <div className='hidden md:w-[40%] h-full md:flex justify-start'>
                <TipsCard />
            </div>
        </div>
    )
}

export default TitleForm