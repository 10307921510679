import { DotsHorizontalIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import DashboardMenuDropList from '../../Pages/Dashboard/DashboardComponents/DashboardMenuDropList'
import NoImages from '../../assets/Group 590.svg'



const PhotosGridCard = ({ imgs = [], doAction }) => {
    const [openSlider, setOpenSlider] = useState(false)


    const menuOptions = [
        {
            id: 1,
            option: 'edit',
            action: () => doAction()
        },
        // {
        //     id: 2,
        //     option: 'delete',
        //     action: () => doAction()
        // }
    ]
    return (
        <div className='flex flex-col bg-secondary-white rounded-xl p-5 m-3'>
            <div className='flex justify-between relative items-center text-sm text-textcolor py-3 px-5'>
                <span className='text-lg font-medium'>Photos</span>
                <span className='absolute top-0 right-0 w-fit'>
                    <DashboardMenuDropList options={menuOptions} button={<DotsHorizontalIcon className='guide-icon' />} />
                </span>
            </div>
            {
                imgs?.length > 0 ?
                    <div className='grid grid-cols-3 gap-4 p-5'>
                        {imgs.map((image, indx) => {
                            return (
                                <span key={indx} className='w-[1/3] aspect-square flex justify-center items-center'>
                                    <img
                                        className='object-cover w-full h-full rounded-xl'
                                        src={image} alt="guide apartment" />
                                </span>
                            )
                        })}
                    </div>
                    :
                    <div className=' flex flex-col w-full items-center justify-center space-y-3 pb-5'>
                        <img src={NoImages} className=" w-24 h-24" alt='guide no reviews notice image' />
                        <div>
                            <div className='text-lg font-medium text-gray-500'>No Photos</div>
                            <div className='text-xs text-gray-400'>Add photos of your business</div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default PhotosGridCard