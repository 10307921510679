export const BlogReactions = {
    LIKE_POST:'LIKE_POST',
    DISLIKE_POST:'DISLIKE_POST',
    POST_COMMENT:'POST_COMMENT',
    SHARE_POST:'SHARE_POST'
}

export const BlogImpressions = {
    POST_VIEW:'POST_VIEW',
    POST_CLICKS:'POST_CLICKS'
}