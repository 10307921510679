import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import Logo from '../../assets/logo.svg'


const Footer = () => {
    const location = useLocation()
    const [hide, setHide] = useState(false)

    useEffect(() => {
        if(location?.pathname == '/apartments'){
            setHide(true)
          }
    },[location?.pathname])

    return !hide && (
        <div className='flex flex-col w-full relative z-30 justify-between py-20 items-center bg-primary-800 h-24px lg:h-[200px]'>

            <div className='hidden 2xl:flex w-32 '>
                <img src={Logo} alt="back_drop_image" className='w-full h-auto -mt-20' />
            </div>
        {/* make year dynamic */}
            <div className='text-white font-light text-sm text-center'>© 2023 Guide, All rights reserved</div>

        </div>
    )
}

export default Footer