import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router"
import { useAuth } from "../../../Components/UtilityComponents/AuthProvider"
import { load_user, printErrorMessages, signup } from "../../../functions/authFunctions";
import { connect } from "react-redux";
import { remove_user_data, store_user_data } from "../../../store/actions/authActions";
import { useSuzi } from "../../../Components/Assistant";
import WorkFromHome from '../../../assets/work _abroad.svg'
import AuthTemplate from "..";
import { Link } from 'react-router-dom'
import DashboardFormField from "../../Dashboard/DashboardComponents/DashboardFormField";
import Loader from '../../../assets/spinner.svg'
import { CheckIcon, ChevronRightIcon, XIcon } from "@heroicons/react/outline";
import { useAlert } from "../../../Components/UtilityComponents/ErrorContext";
import { sendEmailVerificationEmail } from "../../Dashboard/Screens/Timetable/functions";
import { matchPassword, mInfo } from "../../../functions";


const LoginPage = (props) => {




    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [matchPass, setMatchPass] = useState({})

    const location = useLocation()
    const navigate = useNavigate()
    const auth = useAuth()
    const suzi = useSuzi()
    const alert = useAlert()


    useEffect(() => {

        return () => {
            setEmail('')
            setName('')
            setPassword('')
            setLoading(false)
            setMatchPass({})
        }
    }, [])


    const from = location.state?.from?.pathname || "/";

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const signout = () => auth.signout(() => {
        console.log('The call back function where the user state is removed from storage')
    })

    const iCantLogin = () => {
        suzi.addMessage({
            text: (
                <div>
                    Hey there, <br />
                    please note that Guide is still in Beta.
                    This means that we are still developing the platform, and so,
                    if you catch any errors or
                    glitches, please inform us immediately and we will fix it.
                    For more information see the links below.
                    <div className="flex flex-col py-2 space-y-2">
                        <a href="https://www.lifewire.com/what-is-beta-software-2625812" target="_blank" className="w-auto py-2 text-center rounded-lg cursor-pointer hover:bg-gray-100 bg-white text-primary-500 font-bold">What is a Beta Software?</a>
                    </div>
                </div>
            ),
            timeout: 10000
        })
    }


    const handleSignup = async (data, from) => {



        // Virtual assistant response START
        // suzi.addMessage({ text: "Hello there, \n I'm sorry, this functionality is not yet avaialble on Guide. would care for any other of our delights? 😉",timeout: 5000 })
        // await delay(8000)
        // suzi.addMessage({
        //     text:
        //         (<div>
        //             <div className=" font-medium pb-5">Perharps you'd like to try out one of our services. I could help you if you want to;</div>
        //             <div className=" underline hover:text-blue-900 flex items-center space-x-4 cursor-pointer"> <ChevronRightIcon className="text-white w-5 h-5" />  Rent An Apartment</div>
        //             <div className=" underline hover:text-blue-900 flex items-center space-x-4 cursor-pointer"> <ChevronRightIcon className="text-white w-5 h-5" />  Get your NEU course timetable</div>
        //             <div className=" underline hover:text-blue-900 flex items-center space-x-4 cursor-pointer"> <ChevronRightIcon className="text-white w-5 h-5" />  Get current news in the TRNC</div>
        //             <div className=" underline hover:text-blue-900 flex items-center space-x-4 cursor-pointer"> <ChevronRightIcon className="text-white w-5 h-5" />  or perharps you're looking to apply to study in Cyprus?</div>
        //         </div>),
        //     timeout: 5000
        // })

        // return
        // Virtual assistant response END

        setLoading(true)
        if (email.length <= 0 || password.length <= 0) {
            setLoading(false)
            return
        }
        return await signup({ display_name: name, email, password }).then(res => {
            console.log(res)
            if (!res.access_token && res.description) {
                signout()
            }
            return res
        }).then(async (loggedInUser) => {
            const user = await auth.getuser(loggedInUser.sub)
            await props.storeUser(user)
            user && suzi.addMessage({
                text: `Welcome ${user.user?.display_name}`
            })
            try {
                await sendEmailVerificationEmail({
                    uid: user.user?.uid,
                    email: user.user?.email,
                    name: user.user?.display_name
                })
            } catch (e) {
                console.log(e)
            }
            return user
        }).then((user) => {
            auth.signin({ uid: user.user?.uid })
        }).then(() => {
            if (from) {
                navigate(from, { replace: true })
            }
            setLoading(false)
        }).catch((e) => {
            console.error(e)
            printErrorMessages(e, alert)
            setLoading(false)
            signout()
        })

    }



    const onChangePassword = (e) => {
        matchPassword(e, setMatchPass)
        setPassword(e)
    }




    return (
        <AuthTemplate
            title="Making Your Dreams Reality"
            sub_title="growth happens faster when the jobs gets easier"
            vector_img={WorkFromHome}
        >
            <div className="w-full h-auto">
                <div className=" font-light p-5 text-xl md:text-5xl text-primary-500">Sign Up</div>
                <DashboardFormField name="Full Name" type="text" placeholder="John Harry Doe" text={name} setText={setName} />
                <DashboardFormField name="Email" type="email" placeholder="johndoe@mail.com" text={email} setText={setEmail} />
                <DashboardFormField name="Password" password={true} placeholder="**********" text={password} setText={onChangePassword} />
                {password.length > 0 && <div className="grid grid-cols-5 gap-1 px-5 items-center justify-between w-full">
                    <div className="rounded-l-full w-auto h-1" style={{ backgroundColor: matchPass?.password_regex_small_cap ? '#4BB543' : '#fca307' }}></div>
                    <div className=" w-auto h-1" style={{ backgroundColor: matchPass?.password_regex_numbers ? '#4BB543' : '#fca307' }}></div>
                    <div className="w-auto h-1" style={{ backgroundColor: matchPass?.password_regex_big_cap ? '#4BB543' : '#fca307' }}></div>
                    <div className="w-auto h-1" style={{ backgroundColor: matchPass?.password_regex_symb ? '#4BB543' : '#fca307' }}></div>
                    <div className="rounded-r-full w-auto h-1" style={{ backgroundColor: matchPass?.password_regex_range ? '#4BB543' : '#fca307' }}></div>
                </div>}
                {
                    (password.length > 0 && matchPass) &&
                    Object.keys(matchPass).map((m, i) => {
                        return <PassInfo key={i} mode={matchPass[m]} info={mInfo(m)} />
                    })
                }

                <div onClick={() => handleSignup()} className={`w-auto h-14 cursor-pointer flex justify-center items-center rounded-xl  mx-2 my-4 space-x-2  ${loading ? 'bg-gray-300' : 'bg-primary-500 hover:bg-purple-600'}`}>
                    {loading && <img src={Loader} className='w-4 h-4 animate-spin' />}
                    <span className="font-medium text-white cursor-pointer">Sign Up</span>
                </div>
                <div className="  w-full bg-red justify-center items-center text-center mt-5">
                    <div className=" text-xs sm:text-md justify-center flex">have an account? <Link to="/login" className="px-2 font-semibold cursor-pointer text-primary-500">sign in</Link></div>
                </div>

                <div onClick={() => iCantLogin()} className="font-bold hover:underline cursor-pointer w-full bg-red justify-center items-center text-center mt-5">
                    <div className=" text-xs sm:text-md justify-center flex">Beta v1.02 Note</div>
                </div>

            </div>

        </AuthTemplate>
    )
}

export const PassInfo = ({ mode, info }) => {
    return (
        <div className="flex items-center space-x-4 text-xs my-1 px-5">
            {mode ? <CheckIcon className="w-4 h-4 text-green-600" /> : <XIcon className="w-4 h-4 text-red-500" />}
            <div>{info}</div>
        </div>
    )
}





const mapStateToProps = (state) => {
    return {
        user: state.user?.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        storeUser: (data) => dispatch(store_user_data(data)),
        removeUser: () => dispatch(remove_user_data())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)