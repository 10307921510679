import React, { useEffect, useState } from 'react'
import Ratings from 'react-ratings-declarative/build/ratings'
import { connect } from 'react-redux'
import { PromptLoginRequired } from '../UtilityComponents/PromptLoginRequired'
import Loader from '../../assets/spinner.svg'
import { post_service_ratings, update_service_order_status } from '../../functions/service_functions.js'
import moment from 'moment'
import { chat_item_update } from '../../functions/chats_functions'
import { UpdatesStatusTypes } from '../../Pages/Dashboard/Screens/Jobs/JobsMenu/data'

const ReviewComponent = ({ user, _data }) => {

    const [loading, setLoading] = useState(false)
    const [userRating, setUserRating] = useState(null)
    const [confirmedLoggedIn, setConfirmedLoggedIn] = useState(false)
    const [reviewing, setReviewing] = useState(false)
    const [rating, setRating] = useState(1)
    const [review, setReview] = useState('')
    const [actionStatus, setActionStatus] = useState(null)

    console.log(_data, '_DATA:')

    function changeRating(newRating) {
        if (user?.uid) {
            setReviewing(true)
            setRating(newRating)
        }
    }

    useEffect(() => {
        console.log(userRating)
    }, [userRating])

    useEffect(() => {
        if (_data?.action) {
            setActionStatus(_data.action.status)
            setUserRating(_data.action)
            setRating(_data.action.rating)
        }
    }, [])

    const handleSubmit = () => {
        setLoading(true)
        setTimeout(() => {
            const data = {
                author: { uid: user?.uid, display_name: user?.display_name, photoURL: user?.photo_url },
                datePublished: new Date(),
                reviewBody: review,
                ratingValue: rating,
                service_id: _data?.message?.service_id
            }
            post_service_ratings(data).then((resp) => {
                const chat_data = {
                    ..._data,
                    action: {
                        status: UpdatesStatusTypes.GIVEN_REVIEW,
                        rating,
                        ...data
                    }
                }
                chat_item_update(chat_data).then(() => {
                    setUserRating(chat_data.action)
                })
            }).then(() => {
                const service_order_data = {
                        status: UpdatesStatusTypes.GIVEN_REVIEW,
                        doc: _data?.message?.task_id,
                        customer: _data?.message?.customer,
                        seller: _data?.message?.seller,
                        time: new Date(),
                        data: {
                            rating,
                            ...data
                        }   
                    }

                update_service_order_status(service_order_data).then(() => {

                })
            }).catch((err) => {
                console.error(err)
            }).finally(() => {
                setReviewing(false)
                setLoading(false)
            })
        }, 1000)
    }


    return (
        <div>
            <div className='w-full text-center p-5 m-4 bg-gray-100 rounded-2xl'>
                <div className='text-lg font-semibold'>Rate this service</div>
                <div className='text-sm font-normal text-gray-400'>share your experience with this service</div>
                <div className='py-4 w-full flex justify-center'>
                    {rating &&
                        <Ratings
                            rating={rating}
                            widgetDimensions="30px"
                            widgetSpacings="10px"
                            widgetRatedColors="#F2C94C"
                            changeRating={changeRating}
                        >
                            <Ratings.Widget widgetHoverColor="#8D29FF" />
                            <Ratings.Widget widgetHoverColor="#8D29FF" />
                            <Ratings.Widget widgetHoverColor="#8D29FF" />
                            <Ratings.Widget widgetHoverColor="#8D29FF" />
                            <Ratings.Widget widgetHoverColor="#8D29FF" />
                        </Ratings>}
                </div>
                {confirmedLoggedIn && <PromptLoginRequired />}
                {
                    reviewing ?
                        <div className='space-y-3'>
                            <textarea onChange={(e) => setReview(e.target.value)} type="text" rows={5} multiline="" value={review} placeholder=" Write a review. (Optional)"
                                className='w-full bg-white p-2 rounded-md text-sm border-2 border-black/10' />
                            <div onClick={() => handleSubmit()} className=' p-2 cursor-pointer hover:bg-primary-800 bg-primary-500 rounded-md text-white font-semibold flex justify-center'>
                                {loading ? <img src={Loader} className='w-4 h-4 animate-spin' /> : 'submit'}
                            </div>
                        </div>
                        :
                        (userRating && userRating.status === UpdatesStatusTypes.GIVEN_REVIEW) &&
                        // Show current users rating
                        <div className='p-2 text-left px-4 rounded-lg bg-[#fff5f5] shadow-lg max-w-[90%]'>
                            <div className='flex items-center space-x-4'>
                                <img src={userRating.author.photoURL} className="w-10 h-10 rounded-full object-cover" />
                                <div>
                                    <div className='text-sm'>{userRating.author.display_name}</div>
                                    <div className='flex items-center space-x-2'>
                                        {userRating &&
                                            <Ratings
                                                rating={userRating.ratingValue}
                                                widgetDimensions="12px"
                                                widgetSpacings="auto"
                                                widgetRatedColors="green"
                                            >
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                                <Ratings.Widget />
                                            </Ratings>}
                                        <div className='text-xs'>{moment(userRating.datePublished).format('l')}</div>
                                    </div>

                                </div>
                            </div>
                            <div className='py-2'>{userRating.reviewBody}</div>
                        </div>
                }


            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user?.data?.user
    }
}


export default connect(mapStateToProps, null)(ReviewComponent)