import { pages } from "../../../Pages/Dashboard/Screens/Main/SubScreens/Settings/Forms/data"
import { STORE_SERVICE, UPDATE_SERVICE } from "../../actionTypes/serviceActionTypes"

const _state = {
    services: [],
    currentPage: pages[0],
    images: [],
    location: [],
    skillsets: [],
    description: [],
    review: [],
    last_saved: null
}

export const servicesReducer = (state = _state, action) => {
    switch (action.type) {
        case UPDATE_SERVICE:
            return { ...state, type: action.type, payload: action.payload, error: action.error }
        case STORE_SERVICE:
            return { ...state, type: action.type, payload: action.payload, error: action.error }
        default:
            return state
    }
}