import { PlusIcon } from '@heroicons/react/solid'
import React from 'react'

const NoTimeTableCard = ({openEdit}) => {
    return (
        <div className=' text-left '>
            <div className='text-textcolor font-medium text-lg py-5'>Timetable</div>
            <div className='w-full h-[500px] flex justify-center items-center border-4 border-dashed border-gray-400 rounded-2xl'>
                <div className='flex flex-col items-center'>
                    
                    <div onClick={() => openEdit(true)} className='border-2 border-dashed border-black cursor-pointer rounded-full p-4'>
                        <PlusIcon className='guide-icon' />
                    </div>
                    <div className='py-4'>select your courses</div>
                </div>
            </div>
        </div>
    )
}

export default NoTimeTableCard