import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import React from 'react'


const DashboardFormField = (props) => {

    const [showpassword, setShowPassword] = React.useState(false)


    let { name, type = "text", disabled = false, setText, text, placeholder = "type your text here...", multiline = false, password = false, rows = 3, maxLength = 200, error=false } = props;
    placeholder = password ? '********' : placeholder

    return (
        <div className={` ${disabled && 'bg-gray-100'} ${error && 'border-red-500'} w-auto py-2 px-4 m-2 my-5 border-2 border-gray-300 rounded-xl flex bg-white flex-col text-sm text-textcolor`}>
            <div className='flex justify-between items-center text-textcolor'>
                <span className='font-medium text-md '>{name}</span>
                <span onClick={() => setShowPassword(!showpassword)} className='w-5 h-5 flex items-center justify-center '>
                    {password && (!showpassword ? <EyeIcon className='w-[90%] h-[90%]' /> : <EyeOffIcon className='w-[90%] h-[90%]' />)}
                </span>
            </div>

            <span className='w-full'>
                {!multiline && <input disabled={disabled} onChange={(e) => setText(e.target.value)} maxLength={maxLength} type={password ? !showpassword ? 'password' : 'text' : type} placeholder={placeholder} value={text} {...props} className='pt-1  bg-none outline-none w-full' />}
                {multiline && <textarea disabled={disabled} maxLength={maxLength} rows={rows} onChange={(e) => setText(e.target.value)} type="text" placeholder={placeholder} value={text} {...props} className='pt-1 outline-none w-full bg-none ' />}
            </span>

        </div>
    )
}

export default DashboardFormField


