import { ChevronLeftIcon, SearchIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'
import Plugins from '../../../../../../../../functions/plugins'
import { connect } from 'react-redux'
import { useAlert } from '../../../../../../../../Components/UtilityComponents/ErrorContext'
import { store_business } from '../../../../../../../../store/actions/businessActions'
import NoItems from '../../../../../../../../assets/Group 590.svg'

const plugins = new Plugins()

const PluginManagement = ({ user, business, storeBusiness }) => {

  const [showMarket, setShowMarket] = useState(false)
  const [loading, setLoading] = useState(false)
  const alert = useAlert()

  const installed_plugins = business?.plugin
  console.log(user.user)
  const groupID = user?.user?.groupIDs

  const uninstallPlugin = (data) => {
    setLoading(`${data.name}-uninstall`)
    plugins.uninstallPlugin(data).then((res) => {
      console.log(res)
      storeBusiness({
        ...business,
        plugin: res.result
      })
      alert.setalert({
        type: 'success',
        title: "Plugin Uninsalled",
        body: "Plugin was uninsalled successfully"
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  const togglePlugin = (data) => {
    setLoading(`${data.name}-activate`)
    plugins.togglePlugin(data).then((res) => {
      console.log(res)
      storeBusiness({
        ...business,
        plugin: res.result
      })
      alert.setalert({
        type: 'success',
        title: `Plugin ${data.active ? "deactivated" : "activated"}`,
        body: `Plugin ${data.active ? "deactivated" : "activated"} successfully`
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  return showMarket ?
    <MarketPlace setShowMarket={setShowMarket} user={user} business={business} alert={alert} storeBusiness={storeBusiness} installed_plugins={installed_plugins} />
    :
    (
      <div className='w-full h-full flex flex-1 flex-col'>
        <div className='w-full justify-between flex items-center p-10'>
          <div><span className='text-3xl font-light'>Plugins</span></div>
          <div onClick={() => setShowMarket(true)} className='p-4 py-2 cursor-pointer bg-primary-500 rounded-lg font-medium text-white'><span>Visit Marketplace</span></div>
        </div>
        <div className='w-full m-10 bg-white rounded-lg flex items-center justify-start'>

        </div>
        <div className='w-full space-y-4'>
          {
            installed_plugins?.length > 0 ?
              installed_plugins?.map((item) => {
                return (
                  <div className='flex mx-5 items-center bg-white rounded-lg p-4 justify-between'>
                    <div className='flex items-center space-x-4'>
                      <div className='w-24 aspect-square rounded-md overflow-hidden'>
                        <img src={item.icon} className='w-full h-full object-cover' />
                      </div>
                      <div className='space-y-1'>
                        <span className='font-medium text-lg'>{item['pretty-name']}</span>
                        <div className='flex items-center space-x-2'>
                          {
                            item['supported_account_types'].map((n, i) => {
                              return groupID?.some(v => v.type === n) ? <span className='p-1 text-xs bg-primary-500/20 rounded-full text-primary-500 font-medium px-2' key={i}>{n.split("_")[0]}</span> : null
                            })
                          }
                        </div>
                      </div>
                    </div>
                    <div className='flex text-xs items-center space-x-4 font-medium text-white'>
                      <div onClick={() => togglePlugin(item)} className={`${loading === `${item.name}-activate` ? "bg-gray-400" : item.active ? "bg-orange-400" : "bg-green-600"} py-2 px-4 rounded-md cursor-pointer`}>
                        {
                          loading === `${item.name}-activate` ?
                            <span>wait...</span>
                            :
                            <span>{item.active ? "Deactivate" : "Activate"}</span>
                        }
                      </div>
                      <div onClick={() => uninstallPlugin({ id: item.account_id, name: item.name })} className={`${loading === `${item.name}-uninstall` ? "bg-gray-400" : "bg-red-600"} py-2 px-4 rounded-md cursor-pointer`}>
                        {
                          loading === `${item.name}-uninstall` ?
                            <span>wait...</span>
                            :
                            <span>Uninstall</span>
                        }
                      </div>
                    </div>
                  </div>
                )
              })
              :
              <div className='w-full h-full justify-center items-center text-textcolor flex flex-col'>
                <img src={NoItems} className="w-24 h-auto mb-5" />
                <span className='text-lg font-semibold'>No Plugins Installed</span>
                <div className='max-w-md text-center flex flex-col'>
                  <span className='text-base '>Install a plugin to start generating revenue on Guide. </span>
                  <span className='text-sm font-medium '>Find a plugin for your business in the <b>Marketplace</b>. </span>
                  <span className='text-sm font-medium '> And then come back to this page to activate it. </span>
                </div>
              </div>
          }

        </div>

      </div >
    )
}


const MarketPlace = ({ setShowMarket, user: usr, business, alert, storeBusiness, installed_plugins }) => {

  const { user } = usr
  const account_groups = user.groupIDs


  const [pluginData, setPluginData] = useState(null)


  const tabs = [
    {
      title: "Beta Testing",
    },
    {
      title: "Featured",
    },
    {
      title: "Popular",
    },
    {
      title: "Recommended",
    },
    {
      title: "Favourites",
    }
  ]

  useEffect(() => {
    plugins.getMarketPlaceData().then((res) => {
      console.log(res.plugins)
      setPluginData(res.plugins)
    })
  }, [])

  const handleInstallPlugin = async (data) => {
    const { plugin, account } = data
    return plugins.installPlugin({ plugin, account, user, business }).then((res) => {
      storeBusiness({
        ...business,
        plugin: res.result
      })
      alert.setalert({
        type: 'success',
        title: "Plugin Installed",
        body: "Plugin was insalled successfully"
      })
    }).catch(e => {
      alert.setalert({
        type: 'error',
        title: "Failed To Install Plugin",
        body: "Plugin was not Installed, try again"
      })
      throw new Error(`Plugin was not installed : ${e}`)
    })

  }


  return (
    <div className='w-full h-full text-sm flex flex-1 flex-col p-5'>
      <div className='w-full flex items-center space-x-4 '>
        <div onClick={() => setShowMarket(false)} className=''>
          <ChevronLeftIcon className='guide-icon' />
        </div>
        <div><span className='text-2xl font-light'>Plugin Market Place</span></div>
      </div>
      <div className='flex my-10 border-2 px-5 bg-white rounded-lg items-center justify-between'>
        <div className='w-full space-x-5 flex items-center justify-start '>
          {
            tabs.map((tab) => {
              return (
                <div className='p-2'>
                  {tab.title}
                </div>
              )
            })
          }
        </div>
        <div className=' clear-left h-auto my-1 rounded-md w-64 flex items-center '>
          <input className='w-full h-full outline-none ' placeholder='Search...' />
          <SearchIcon className='w-5 h-5' />
        </div>



      </div>

      <div className='w-full grid grid-cols-2 gap-2 '>
        {
          pluginData?.map((item, i) => {
            return (
              <PluginItem key={i} item={item} account_groups={account_groups} handleInstallPlugin={handleInstallPlugin} installed_plugins={installed_plugins} />
            )
          })
        }
      </div>

    </div>
  )
}

const PluginItem = ({ item, account_groups, handleInstallPlugin, installed_plugins }) => {

  const [selectedAccounts, setSelectedAccounts] = useState([])
  const [showDrop, setShowDrop] = useState(false)
  const [loading, setLoading] = useState(false)
  const [installed, setInstalled] = useState(false)


  useEffect(() => {
    setInstalled(installed_plugins?.some(i => item.name === i.name))
  }, [])


  useEffect(() => {
    console.log(selectedAccounts)
  }, [selectedAccounts, loading])

  return (
    <div className='p-5 bg-white border-2 space-y-2 rounded-md'>
      <div className='w-full flex items-start space-x-4'>
        <div className='min-w-24 h-24 rounded-lg aspect-square overflow-hidden '>
          <img src={item.icon} className='w-full h-full object-cover' />
        </div>
        <div className='w-[70%]'>
          <div className='font-medium text-base'>{item.marketplace['pretty-name']}</div>

          <div className='py-2 text-textcolor'>
            <p>{item.marketplace.description.text?.slice(0, 100)}...</p>
          </div>
          <span className=' italic text-xs'>By: {item.marketplace.author}</span>
        </div>
      </div>
      <div>
        <div className='flex items-center space-x-2 pb-2 text-textcolor'>
          <span className='font-medium'>Supported Accounts: </span>
          {
            item.marketplace.supported_account_types.map((typ, i) => {
              return <span className=' capitalize font-body' key={i}>{typ.split("_")[0]} {i !== item.marketplace.supported_account_types.length - 1 && ','} </span>
            })
          }
        </div>
        <div className='w-full flex justify-end border-t-2 pt-2'>
          <div className='w-fit h-fit relative'>
            {
              installed ?
                <div className={`text-gray-400 italic rounded-md cursor-pointer font-medium px-4 py-2`}>
                  <span>Installed</span>
                </div>
                :
                <div onClick={() => setShowDrop(!showDrop)} className={`${loading ? "bg-gray-400" : "bg-green-600"} rounded-md cursor-pointer text-white font-medium px-4 py-2`}>
                  {
                    loading ?
                      <span>wait...</span>
                      :
                      <span>Install</span>
                  }
                </div>
            }
            {showDrop &&
              <div className='min-w-[14rem] divide-y-2 justify-center flex flex-col items-center bg-white overflow-hidden absolute top-10 right-0 border-2 rounded-md shadow-md'>
                {
                  item.marketplace.supported_account_types?.map((group, i) => {
                    return account_groups.map(o => o.type).includes(group) && (
                      <div onClick={() => {
                        setLoading(true)
                        handleInstallPlugin({
                          account: account_groups.filter(o => o.type === group)[0],
                          plugin: item
                        }).then(res => setInstalled(!installed)).finally(() => {
                          setLoading(false)
                          setShowDrop(!showDrop)
                        })
                      }} className='flex items-center w-full text-left px-4 py-2 space-x-4 font-sans justify-center cursor-pointer hover:bg-primary-300'>
                        <span className='text-black'>Install on {group.split("_")[0]} account</span>
                      </div>
                    )
                  })
                }
              </div>}

          </div>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    user: state.user?.data,
    business: state.business.payload
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeBusiness: (data) => dispatch(store_business(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PluginManagement)