import React, { createContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { update_accounts } from "../../../store/actions/accountActions";
import { store_user_data } from "../../../store/actions/authActions";
import { useAuth } from "../AuthProvider";


const initial_account = {
  activeAccount: null,
  updateActiveAccount: (data) => null
}

const AccountTypeContext = createContext(initial_account)

function AccountType({ children, user, storeUser,updateAccountSettings }) {

  const [activeAccount, setActiveAccount] = useState(null)  
  const auth = useAuth()


  useEffect(() => {

  },[user])
  const fetchFreshUser = async () => auth.getuser(user.uid? user.uid : user?.user.uid)

    let updateActiveAccount =(data) => {
      fetchFreshUser().then((_user) => {
        if(!_user?.user.groupIDs?.some((gid) => gid?.type === data.type && gid?.id === data.id )){
          storeUser({...user, user:{..._user.user, groupIDs: [..._user?.user.groupIDs, data ]}})
        }else{
          storeUser({...user, user:{..._user.user, groupIDs:  [data] }})
        }
        setActiveAccount(data)
        updateAccountSettings(data)
      })

  }

  const value = {activeAccount, updateActiveAccount  }

  return (
    <AccountTypeContext.Provider value={value}>
      {children}
    </AccountTypeContext.Provider>
  )
}

export const useAccounts = () => {
  return React.useContext(AccountTypeContext);
}

const mapStateToProps = (state) => {
  return {
      user: state.user?.data
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      storeUser: (data) => dispatch(store_user_data(data)),
      updateAccountSettings:(data) => dispatch(update_accounts(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)( AccountType ) 