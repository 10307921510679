import { Housingpages as pages } from "../../../Pages/Dashboard/Screens/Main/SubScreens/Settings/Forms/data"
import { STORE_HOUSING, UPDATE_HOUSING } from "../../actionTypes/housingActionTypes"

const _state = {
    location: [],
    images: [],
    facilities: [],
    living_space: [],
    pricing: [],
    title: [],
    currentPage: pages[0],
    requirements: [],
    description: [],
    review: [],
    last_saved: null
}

export const housingReducer = (state = _state, action) => {
    switch (action.type) {
        case UPDATE_HOUSING:
            return { ...state, type: action.type, payload: action.payload, error: action.error }
        case STORE_HOUSING:
            return { ...state, type: action.type, payload: action.payload, error: action.error }
        default:
            return state
    }
}