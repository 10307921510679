import React, { useState } from 'react'
import Modal from '../../../../../Components/Modal'
import NumberField from '../../../../../Components/NumberField'
import DashboardFormField from '../../../DashboardComponents/DashboardFormField'
import { UpdatesStatusTypes } from '../JobsMenu/data'
import Loader from '../../../../../assets/spinner.svg'

const RequestExtension = ({ setOpen, handleAction }) => {

    const [loading, setLoading] = useState(false)
    const [days, setDays] = useState(1)
    const [reason, setReason] = useState('')

    const handleRequestExtension = async () => {
        setLoading(true)
        await handleAction(UpdatesStatusTypes.REQUESTING_EXTENSION, {
            days,
            reason
        }).then((res) => {
            console.log('REQUEST SENT: ', res)
        }).finally(() => {
            setLoading(false)
            setOpen(false)
        })
    }

    return (
        <Modal setOpen={setOpen}>
            <div className='w-full flex flex-col divide-y-2'>
                <div className='w-full py-3 text-center font-bold text-lg bg-primary-500 text-white rounded-t-lg'>Request an extension</div>
                <div className='p-4 text-gray-500 space-y-2'>
                    <div className=''> need more time?. Ask your customer to allow you more time for this task </div>
                    <div> How many more days do you need for this task? </div>
                    <div className='flex items-start justify-between'>
                        <div className='w-[10%]'>
                            <NumberField title="Days" activeState={days} setActiveState={setDays} />
                        </div>
                        <div className='w-[88%]'>
                            <DashboardFormField text={reason} setText={setReason} multiline name="reasons" placeholder="ask your customer for more time" />
                        </div>
                    </div>
                    <div className='w-full pt-4 px-3'>
                        <div className='flex items-center justify-end space-x-4'>
                            <div onClick={() => setOpen(false)} className='py-2 px-4 bg-red-500 text-white font-medium rounded-md cursor-pointer'>Cancel </div>
                            <div onClick={() => handleRequestExtension()} className='py-2 px-4 bg-green-500 text-white font-medium rounded-md cursor-pointer flex items-center space-x-2'>
                                {loading && <img src={Loader} className='w-4 h-4 animate-spin' />}
                                <span>Send</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default RequestExtension