import React, { useState } from 'react'



const BlockSelector = ({ icon, title, items, setSelector, selected = [], multiSelect = false }) => {

    const selectItem = (item) => {
        if (multiSelect) {
            !selected.includes(item) ? setSelector([...selected, item]) : setSelector(selected.filter(i => i !== item))
        } else {
            setSelector(item)
        }
    }

    return (
        <div>
            <div className='w-full p-5 py-8'>
                <div className='w-full flex items-center space-x-4 py-2'>
                    {icon}
                    <div className=''>{title}</div>
                </div>
                <div className='w-full flex items-center justify-start flex-wrap mt-5 space-x-2'>
                    {
                        items.map((item, indx) => {
                            const isSelected = selected.includes(item)
                            return (
                                <div
                                    key={indx}
                                    onClick={() => selectItem(item)}
                                    className={  `cursor-pointer px-4 py-1 my-2 rounded-lg border-2 border-primary-500  ${isSelected ? 'text-white bg-primary-500' : 'text-gray-500 bg-white'}`}
                                >
                                    <div className='font-light text-md'>{item.title? item.title: item}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </div>
    )
}

export default BlockSelector