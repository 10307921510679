import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { load_user_apartments } from '../../../../functions/housing_functions'
import DashboardSliderOver from '../../DashboardComponents/DashboardSliderOver'
import DashboardWrapper from '../../DashboardComponents/DashboardWrapper'
import HousingDashboardFormWrapper from '../../DashboardComponents/FormComponents/HousingDashboardFormWrapper'
import WelcomeSVG from '../../../../assets/3-01.svg';
import ShowActiveHousingForm from './Forms/ShowActiveHousingForm'
import { AccountTypes } from '../SelectAccountTypePage/accounts'
import WelcomeCard from '../Main/Cards/WelcomeCard'
import SocialsViewCard from '../Main/Cards/SocialsViewCard'
import { ChartBarIcon, CursorClickIcon, PhoneIcon } from '@heroicons/react/outline'
import WhatsappIcon from '../../../../assets/WhatsappIcon'
import { abbreviateNumber } from '../../../../functions/service_functions.js'
import { intervals } from '../Services'
import { useHousing } from '../../../../Components/UtilityComponents/HousingContext'
import RecentApartments from './RecentApartments'
import { useHousingForm } from '../../../../Components/UtilityComponents/HousingFormContext'
import DashboardGroupMetricsView from '../../DashboardComponents/DashboardGroupMetricsView'



const Housing = ({ userx }) => {
    const { user } = userx
    const [impression, setImpression] = useState(0)
    const [phone, setPhone] = useState(0)
    const [clicks, setClicks] = useState(0)
    const [whastapp, setWhatsapp] = useState(0)
    const [insta, setInsta] = useState(0)


    // useEffect(() => loadUp(), [])
    const houses = useHousing()
    const housing = useHousingForm()

    const { apartmentList, apartments, setOpenSlider, openSlider } = houses

    const GROUP_ID = userx?.user.groupIDs?.filter((gid) => gid.type === AccountTypes.HOUSING_AGENT)[0]?.id
    
    let user_name = userx?.user?.display_name?.split(' ')
    if (user_name) {
        user_name = user_name && user_name?.length > 1 ? user_name[1] : user_name[0]
    } else {
        user_name = 'User'
    }

    const new_apt = () => {
        housing.clearHousing().then(() => {
            setOpenSlider(!openSlider)
        })
    }
    
    useEffect(() => {
        housing.setGroupID(GROUP_ID)
    }, [apartmentList, openSlider])

    return GROUP_ID ? (
        <DashboardWrapper title="Housing">
            <div className='flex w-auto xl:min-w-[65%] m-5 mx-3 space-y-5 flex-col'>
                <WelcomeCard
                    user={user_name}
                    action_text="Create Listing"
                    action={new_apt}
                    desc="The name of the business is 'money making', roll up your sleeves, let's get to work. Customers are waiting."
                    bg="bg-white"
                    image={WelcomeSVG}
                />
                <div className='flex space-y-2 flex-col md:flex-row space-x-0 md:space-x-4 md:space-y-0'>
                    {/* <InfoCard link="/dashboard/jobs" caption="Pending Jobs" num={userJobs?.pendingJobs?.filter(i => i.customer?.uid !== user.uid).length} desc="these are jobs you haven't accepted yet" primary_color="bg-darkblue_1" secondary_color="bg-darkblue_2" />
                    <InfoCard link="/dashboard/jobs" caption="Jobs In Progress" num={userJobs?.ongoingJobs?.filter(i => i.customer?.uid !== user.uid).length} desc="jobs you are currently working on" primary_color="bg-lightblue_1" secondary_color="bg-lightblue_2" />
                    <InfoCard link="/dashboard/jobs" caption="Closed Jobs" num={userJobs?.closedJobs?.filter(i => i.customer?.uid !== user.uid).length} desc="cancelled or completed jobs" primary_color="bg-lightred_1" secondary_color="bg-lightred_2" /> */}
                </div>
                <div className='h-[1px] w-auto m-2 bg-gray-300' />
                <div className='sub-title max-h-10'>Performance & Metrics</div>
                <div className='w-full h-fit'>
                    <DashboardGroupMetricsView gid={GROUP_ID} type="Apartments" showSocial />
                </div>
                    
                <div className='h-0 lg:h-[100px] w-auto m-2' />
            </div>
            <div className='w-full lg:max-w-[35%]'>
                <RecentApartments apartments={apartmentList} />
            </div>

                <DashboardSliderOver bg={'#F0f0f0'} open={openSlider} setOpen={setOpenSlider} width={7} >
                    <HousingDashboardFormWrapper >
                        <ShowActiveHousingForm groupID={GROUP_ID} />
                    </HousingDashboardFormWrapper>
                </DashboardSliderOver>
        </DashboardWrapper>
    ) : (
        <DashboardWrapper title="Housing">
            <div className='w-full flex flex-col space-y-4 justify-center items-center py-10'>
                <div>You need a HOUSING AGENT ACCOUNT  to put up apartments.</div>
                <div className='p-2 bg-primary-500 text-white rounded-lg cursor-pointer'>See how to create a housing agent account</div>
            </div>
        </DashboardWrapper>
    )
}

const mapStateToProps = (state) => {
    return {
        userx: state.user?.data
    }
}


export default connect(mapStateToProps)(Housing)