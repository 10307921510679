import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { loadServicesById } from '../../../functions/service_functions.js'
import NoData from '../../../assets/no_content.svg'
import Loader from '../../../assets/spinner.svg'
import ServiceProviderItem from './ServiceProviderItem/index.js'
import ServiceListFilter from './ServiceListFilter/index.js'
import DashboardSliderOver from '../../Dashboard/DashboardComponents/DashboardSliderOver/index.js'
import { AdjustmentsIcon } from '@heroicons/react/outline'


const ServiceProvidersList = () => {

    const { category } = useParams()
    const [list, setList] = useState(null)
    const [coords, setCoords] = useState(null)
    const [loading, setLoading] = useState(null)
    const [openFilter, setOpenFilter] = useState(false)


    useEffect(() => {
        setLoading(true)
        navigator.geolocation.getCurrentPosition((position) => {
            const { coords } = position
            setCoords(position.coords)
            loadServicesById({
                longitude: coords.longitude,
                latitude: coords.latitude,
                radius: 1000,
                filters: {
                    "service_type": [category],
                }
            }).then((services) => {
                setList(services?.v)
            }).finally(() => {
                setLoading(false)
            })
        })
    }, [])

    useEffect(() => {
        console.log(list)
    }, [list])



    const handleLoadMore = () => {

    }

    return (
        <div className='w-full h-[100vh] overflow-y-auto scrollbar flex justify-center pt-10 relative'>
            <div className='w-full lg:w-[80%] 2xl:w-[60%] flex items-start md:space-x-4 px-2 lg:px-0 '>
                <div className='w-full lg:w-[70%] flex flex-col items-center'>
                    {
                        !loading ?
                            list?.length > 0 ?
                                <div className='space-y-4 w-full '>
                                    {
                                        list?.map((service, indx) => {
                                            return <ServiceProviderItem service={service} category={category} key={indx} />
                                        })
                                    }
                                </div>
                                :
                                <div className='w-full md:w-[80%] 2xl:w-[50%] pt-[200px] flex flex-col justify-center items-center'>
                                    <div className=''>
                                        <img src={NoData} className="w-auto" alt="guide no items image" />
                                    </div>
                                    <div className='flex flex-col items-center '>
                                        <div className='font-semibold text-gray-400 py-2'>No Services in your area.</div>
                                        <div className='font-light text-gray-400 py-2'>broaden your search parameters and filter</div>
                                    </div>
                                </div>
                            :
                            <div className='w-full md:w-[80%] 2xl:w-[50%] flex justify-center items-center pt-[200px]'>
                                <img src={Loader} className='w-20 h-20 animate-spin stroke-1' />
                            </div>
                    }
                    {/* {(!loading && list?.length > 0) && <div className='py-10'>
            <div onClick={() => handleLoadMore()} className=' cursor-pointer w-auto py-5 mx-5 my-10 flex justify-center items-center bg-primary-500 rounded '>
              {loadingMore && <img src={Loader} className='w-4 h-4 animate-spin' />}
              {!loadingMore && <div className='font-medium text-lg text-white'>Load More</div>}
            </div>
          </div>} */}
                    <div className='w-full h-32' />
                </div>
                {!openFilter && <div onClick={() => setOpenFilter(true)} className=' w-20 h-20 flex 2xl:hidden justify-center items-center rounded-l-full bg-primary-500 fixed right-0 top-[20%]'>
                    <AdjustmentsIcon className='text-white guide-icon' />
                </div>}
                <div className='hidden md:flex'>
                    <ServiceListFilter location={coords} doFilter={loadServicesById} service={category} setFilter={setList} setLoading={setLoading} />
                </div>
                <DashboardSliderOver open={openFilter} setOpen={setOpenFilter} width={5}>
                    <ServiceListFilter location={coords} doFilter={loadServicesById} service={category} setFilter={setList} setLoading={setLoading} loading={loading} setOpenFilter={setOpenFilter} full={true} />
                </DashboardSliderOver>
            </div>
        </div>
    )
}

export default ServiceProvidersList