import React, { useState, useEffect } from "react";
import { ChatItem } from "..";
import { CHAT_SERVER_URL } from "../../../../../assets/url";
import { useChat } from "../../../../../Components/UtilityComponents/ChatsContext";
import Loader from "../../../../../assets/spinner.svg";
import { useAuth } from "../../../../../Components/UtilityComponents/AuthProvider";
import { cancel_chat_requests } from "../../../../../functions/chats_functions";
import { useSuzi } from "../../../../../Components/Assistant";
import { useAlert } from "../../../../../Components/UtilityComponents/ErrorContext";

const ChatRequests = ({
  setChatReciepient,
  setMessages,
  user,
  setRoomData,
  sender,
  socket,
  roomData,
}) => {
  const [chatRequests, setChatRequests] = useState(null);
  const [loadingChats, setLoadingChats] = useState(null);
  const suzi = useSuzi();
  const alert = useAlert();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      init();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const init = () => {
    setLoadingChats(true);
    fetch(`${CHAT_SERVER_URL}/get_chat_request_list/${user.uid}`, {
      method: "GET",
    })
      .then(async (result) => {
        setChatRequests(await result.json());
      })
      .catch((e) => {
        alert.setalert({
          type: "error",
          title: "Failed!",
          body: "Something went wrong, try again",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setLoadingChats(false);
        }, 500);
      });
  };

  useEffect(() => {
    socket.on("request_replied", (data) => {
      suzi.addMessage({
        text: data.message,
        timeout: 3000,
      });
      init();
    });
  }, [socket]);

  useEffect(() => {}, [chatRequests]);

  return (
    <div>
      <div
        className={`  ${ roomData ? "hidden lg:block w-full text-sm" : "block w-full" } `}
      >
        {/* searchbar */}
        <div className="divide-y-2 w-full">
          {loadingChats ? (
            <div className="w-full flex text-center justify-center items-center text-lg text-gray-400 pt-[25vh]">
              <img src={Loader} className="w-10 h-10 animate-spin" />
            </div>
          ) : chatRequests?.length > 0 ? (
            chatRequests?.map((chat, indx) => {
              // return <div />
              return (
                <RequestItem
                  key={indx}
                  socket={socket}
                  setChatReciepient={setChatReciepient}
                  chat={chat[0]}
                  user={user}
                  setChatRequests={setChatRequests}
                  chatRequests={chatRequests}
                  init={init}
                />
              );
            })
          ) : (
            <div className="w-full flex justify-center items-center text-center text-lg text-gray-400 pt-[25vh]">
              <span>No Requests</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatRequests;

const RequestItem = ({
  setChatReciepient,
  chat,
  socket,
  user,
  chatRequests,
  setChatRequests,
  init,
}) => {
  const chatRef = useChat();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setChatReciepient(user);
  }, [chat]);

  if (!chat.sender) {
    return null;
  }

  const userID = auth.user?.uid ? auth.user.uid : auth.user?.user.uid
  console.log('----------------', chat.sender, auth.user)
  const request =
    chat.sender?.uid !== userID ? chat.sender : chat.reciever;

  const acceptInvite = () => {
    setLoading("accept");
    chatRef
      .joinChat({
        // the reciever's details
        uid: request.uid,
        author: request.author,
        photoURL: request.photoURL,
      })
      .then(async () => {
        const tmp = [];
        init();
        console.log(tmp, "TMP");
        setLoading(false);
        socket.emit("replied_request", {
          sender: chat.sender,
          reciever: chat.reciever,
          room: chat.room,
          type: "accept",
          replied_by: userID,
        });
      });
  };

  const cancelRequest = (type) => {
    setLoading(type);
    cancel_chat_requests(chat.sender.uid, chat.reciever.uid, chat.room, type)
      .then(async (res) => {
        socket.emit("replied_request", {
          sender: chat.sender,
          reciever: chat.reciever,
          room: chat.room,
          type,
          replied_by: userID,
        });
        const tmp = [];
        init();
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="flex w-full items-center space-x-4 py-4">
      <div className="">
        <img
          src={request.photoURL}
          className="w-20 aspect-square  rounded-full object-cover"
        />
      </div>
      <div className="w-[90%] flex items-center space-x-2">
        <div className="w-full flex space-y-1 flex-col">
          <div className="font-semibold">{request.author}</div>
          {chat.sender.uid !== userID ? (
            <div className="flex items-center space-x-4">
              <div
                onClick={acceptInvite}
                className={`px-4 py-2 cursor-pointer rounded-md  text-white font-medium  flex items-center space-x-2 ${
                  loading === "accept"
                    ? "bg-gray-400 "
                    : "bg-green-500 hover:bg-green-300"
                }`}
              >
                {loading === "accept" && (
                  <img src={Loader} className="w-4 h-4 animate-spin" />
                )}
                <span>Accept</span>
              </div>
              <div
                onClick={() => cancelRequest("reject")}
                className={`px-4 py-2 rounded-md bg-red-500 cursor-pointer text-white font-medium hover:bg-red-300 flex items-center space-x-2 ${
                  loading === "reject"
                    ? "bg-gray-400 "
                    : "bg-red-500 hover:bg-red-300"
                }`}
              >
                {loading === "reject" && (
                  <img src={Loader} className="w-4 h-4 animate-spin" />
                )}
                <span>Reject</span>
              </div>
            </div>
          ) : (
            <div
              onClick={() => cancelRequest("cancel")}
              className="flex items-center space-x-4"
            >
              {loading && <img src={Loader} className="w-4 h-4 animate-spin" />}
              <div
                className={`px-4 py-2 rounded-md bg-red-500 cursor-pointer text-white font-medium hover:bg-red-300 flex items-center space-x-2 ${
                  loading === "cancel"
                    ? "bg-gray-400 "
                    : "bg-red-500 hover:bg-red-300"
                }`}
              >
                {loading === "cancel" && (
                  <img src={Loader} className="w-4 h-4 animate-spin" />
                )}
                <span>Cancel Request</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
